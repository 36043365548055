import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Button, Text, DragAndDropFIles } from '@components/ui';
import { Formik, Form } from 'formik';
import { Textarea, Select } from '@components/ui/form';
import { addAppealRequest } from '@modules/appeals';

import { useDispatch } from 'react-redux';

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 8px;
    width: 45%;
  }

  &-overlay {
    overflow-y: scroll;
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }

  @media screen and (max-width: 767px) {
    &-overlay {
      // top: 39px !important;
    }
    &-content {
      width: 100%;

      height: 100vh;
      border: none;
      border-radius: 0;
      overflow-x: scroll;
    }
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const Description = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  margin-top: 24px;
`;

const Submit = styled(Button).attrs({ htmlType: 'submit' })`
  @media (max-width: 767px) {
    width: 100%;
  }
`;

interface Props {
  isCreateAppealOpen: boolean;
  setIsCreateAppealOpen: Dispatch<SetStateAction<boolean>>;
}

const AppealWithoutOrderForm: React.FC<Props> = ({
  isCreateAppealOpen,
  setIsCreateAppealOpen,
}) => {
  const options = [{ label: 'Прочее', value: 'Other', code: 'Other' }];
  const initialOption = { label: 'Прочее', value: 'Other', code: 'Other' };
  const [files, setFiles] = React.useState<File[]>([]);

  const dispatch = useDispatch();
  const initialValues = {
    subject: { label: '', value: 'Other', code: 'Other' },
    message: '',
  };
  return (
    <StyledPopup
      onClose={() => setIsCreateAppealOpen(false)}
      open={isCreateAppealOpen}
    >
      <Wrapper>
        <Title>Создание обращения</Title>{' '}
        <Description>
          Для создания обращения на возврат или обмен перейдите в конкретный
          заказ, по которому необходим возврат или обмен, нажмите кнопку
          “Создать обращение” и выберите соответствующую тему.
        </Description>
        <Formik
          initialValues={initialValues}
          onSubmit={(data) => {
            dispatch(
              addAppealRequest({
                text: data.message,
                subject: data.subject.value,
                files,
              })
            );
            setIsCreateAppealOpen(false);
          }}
        >
          {({ setFieldValue, handleChange, handleBlur }) => (
            <StyledForm>
              <Select
                isSearchable={false}
                onChange={(data: any) => {
                  setFieldValue('subject', data);
                }}
                name="subject"
                label="Тема обращения"
                options={options}
                defaultValue={initialOption}
              />
              <Textarea
                onChange={handleChange}
                onBlur={handleBlur}
                name="message"
                marginTop={15}
                style={{ width: '100%', resize: 'none' }}
                rows={10}
                label="Текст обращения"
              />
              <DragAndDropFIles
                onChange={(files) => {
                  setFiles(files);
                }}
              />
              <div>
                <Submit>Отправить</Submit>
              </div>
            </StyledForm>
          )}
        </Formik>
      </Wrapper>
    </StyledPopup>
  );
};

export default AppealWithoutOrderForm;
