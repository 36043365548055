import React from 'react';
import styled from 'styled-components';
import arrowPath from '@assets/media/train-tickets/arrow-breadcrumbs.svg';
import { Text as BaseText } from '@components/ui';
import { useMediaQuery } from 'react-responsive';

interface Props {
  fromCityName: string;
  toCityName: string;
  direction: 'forward' | 'backward';
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  & > * {
    margin-right: 3px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const Text = styled(BaseText)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const Img = styled.img.attrs({ src: arrowPath })`
  width: 24px;
  height: 24px;
`;

export default function Direction(props: Props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { fromCityName, toCityName, direction } = props;
  return (
    <Wrapper>
      <Text>{direction === 'forward' ? 'Туда' : 'Обратно'}: {fromCityName}</Text>
      {isMobile ? ` — ` : <Img />}
      <Text>{toCityName}</Text>
    </Wrapper>
  );
}
