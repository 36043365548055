import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const TechInfoIcon = styled.img.attrs({ src: techInfoIcon })`
  margin-right: 5px;
  margin-top: 1px;
`;

const Title = styled.span`
  display: inline;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  color: #E73C3E;
  @media (max-width: 767px) {
    display: none;
  }
`;

const TechLanding: React.FC = () => {
  return (
    <>
      {' '}
      <br />
      <Tippy
        content={`Остановка, которая заложена в график рейса из-за возможной технологической необходимости. Произойдет посадка самолета в промежуточном аэропорту, при которой пассажиры и багаж остаются на борту самолета.`}
        theme="light"
        maxWidth={231}
      >
        <Flex>
          <TechInfoIcon />
          <Title>
            тех. остановка
          </Title>{' '}
        </Flex>
      </Tippy>
    </>
  );
};

export default TechLanding;
