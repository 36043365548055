import { call, put, select } from 'typed-redux-saga';
import * as Manager from './manager';
import { CreateTrainBookingPayload } from '@modules/trainBooking/dto/BookTrainTicketDto';
import { NavigationService } from '@services/index';
import {
  setTrainSearchRequestState,
  TrainSearchRequestStatus,
} from '@modules/trainTickets';
import { clearTrainTicket, setTrainTicket } from '@modules/trainBooking/duck';
import { setAuthPopup } from '@modules/booking';
import { getUserStatusState, simpleSignUpSaga } from '@modules/user';
import moment from 'moment/moment';
import { CustomRailwayError } from '@modules/main';

export function* trainBookWorkers(data: CreateTrainBookingPayload) {
  try {
    yield* put(
      setTrainSearchRequestState({
        status: TrainSearchRequestStatus.tryToCreateOrder,
      }),
    );
    yield* put(clearTrainTicket());
    const result = yield* call(Manager.bookTrainTicket, data);
    yield* put(setTrainTicket(result));
    yield* call(NavigationService.navigate, `/orders/${result.tariff.orderId}`);
    yield* put(
      setTrainSearchRequestState({ status: TrainSearchRequestStatus.success }),
    );
  } catch (e: unknown) {
    if (e instanceof Error) {
      const customError = e as CustomRailwayError;
      const errorMessage =
        customError.response?.data?.message ||
        e.message ||
        'Error booking ticket';
      yield* put(
        setTrainSearchRequestState({
          errors: { code: 0, message: errorMessage },
          status: TrainSearchRequestStatus.failure,
        }),
      );
    }
    console.log(e);
  }
}

export function* trainSignUp(data: CreateTrainBookingPayload) {
  const urlParams = new URLSearchParams(window.location.search);
  const agentId = urlParams.get('agentId');

  if (agentId) {
    const authSuccessful = yield* call(simpleSignUpSaga, {
      firstName: data.customers[0].firstName,
      lastName: data.customers[0].lastName,
      patronymic: data.customers[0].middleName,
      birthDate: moment(data.customers[0].birthday).format('YYYY-MM-DD'),
      gender: data.customers[0].sex,
      agentId: agentId,
      phone: data.contactPhone,
      email: data.contactEmail,
    });
    if (authSuccessful) {
      yield* call(trainBookWorkers, data);
    } else {
      const { error } = yield* select(getUserStatusState);
      if (error) {
        yield* put(setAuthPopup(true));
      }
    }
  } else {
    const authSuccessful = yield* call(simpleSignUpSaga, {
      firstName: data.customers[0].firstName,
      lastName: data.customers[0].lastName,
      patronymic: data.customers[0].middleName,
      birthDate: moment(data.customers[0].birthday).format('YYYY-MM-DD'),
      gender: data.customers[0].sex,
      phone: data.contactPhone,
      email: data.contactEmail,
    });
    if (authSuccessful) {
      yield* call(trainBookWorkers, data);
    } else {
      const { error } = yield* select(getUserStatusState);
      if (error) {
        yield* put(setAuthPopup(true));
      }
    }
  }
}
