import { createAction, createReducer } from 'redux-act';
import { bookingPurify } from './shared';
import { PassengerItemEntity } from '../types';

export interface UpdatePassengerPayload {
  value: string;
  passengerIndex: number;
}

//Actions
export const updatePassengers = createAction<PassengerItemEntity[]>(
  '@booking/updatePassenger'
);

export const setPassengerName = createAction<UpdatePassengerPayload>(
  '@booking/setPassengerName'
);
export const setPassengerSurname = createAction<UpdatePassengerPayload>(
  '@booking/setPassengerName'
);
export const setPassengerSecondName = createAction<UpdatePassengerPayload>(
  '@booking/setPassengerName'
);

export const updatePassengerByIndex = createAction<{
  index: number;
  data: PassengerItemEntity;
}>('boiking/updatePassengerByIndex');

//Reducer
const reducer = createReducer<PassengerItemEntity[]>({}, []);

reducer.on(bookingPurify, () => []);

reducer.on(updatePassengers, (_, payload) => [...payload]);

reducer.on(updatePassengerByIndex, (state, { index, data }) => {
  state[index] = { ...state[index], ...data };
  return [...state];
});

reducer.on(setPassengerName, (state, { value, passengerIndex }) => {
  state[passengerIndex] = { ...state[passengerIndex], name: value };
  return [...state];
});

reducer.on(setPassengerSurname, (state, { value, passengerIndex }) => {
  state[passengerIndex] = { ...state[passengerIndex], surname: value };
  return [...state];
});

reducer.on(setPassengerSecondName, (state, { value, passengerIndex }) => {
  state[passengerIndex] = { ...state[passengerIndex], secondName: value };
  return [...state];
});

export default reducer;
