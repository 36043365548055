import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ButtonCustomProps } from './types';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  margin: auto;

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 2px solid lightgray;
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  ${({ theme }) => `
    ${theme.max('1124px')} {
      width: 16px;
      height: 18px;
      margin: auto;

      border-top: 1px solid grey;
      border-right: 1px solid grey;
      border-bottom: 1px solid grey;
      border-left: 1px solid lightgray;
    }
  `}
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  border-radius: 4px;
`;

const OutlinedButton = styled.button<ButtonCustomProps>`
  ${({
    backgroundColor,
    color,
    isLoading,
    isMobile,
    selected,
    template,
    theme: { borderRadius, colors, fonts },
  }) => {
    let mainColor = color || colors.blackGray;
    let background = selected ? '#EDF1FE' : backgroundColor || 'transparent';
    let fontColor = colors.blackGray;
    let borderColor = selected ? '#4872F2' : colors.lightGray;
    const minWidth = isMobile ? '84px' : '100px';
    const fontSize = isMobile ? '14px' : '16px';

    if (template === 'gray') {
      mainColor = '#C4C4C4';
      background = 'transparent';
      fontColor = '#3C3C3C';
      borderColor = '#C4C4C4';
    }

    if (template === 'blue') {
      mainColor = '#4872F2';
      background = 'transparent';
      fontColor = '#4872F2';
      borderColor = '#4872F2';
    }

    return `
            min-width: ${minWidth};
            font-family: ${fonts.regular};
            border-radius: ${borderRadius};
            background: ${selected ? background : 'transparent'};
            color: ${fontColor};
            border: 1px solid ${selected ? `${colors.blue}` : borderColor};
            outline: none;
            font-style: normal;
            font-weight: normal;
            font-size: ${fontSize};
            line-height: 18px;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;

            ${isLoading ? 'pointer-events: none;' : ''}

            &:disabled {
                cursor: not-allowed;
                background: transparent;
                color: ${colors.gray};
                border: 1px solid ${colors.gray};
            }
            &:active {
                color: ${mainColor};
                border: 1px solid ${mainColor};
                background-color: ${background};
            }
            &:hover{
              border: 1px solid ${colors.blue};
            }
        `;
  }}
`;

export default function OutlinedButtonComponent({
  children,
  ...props
}: any & {
  isLoading?: boolean;
  isSuccess?: boolean;
}) {
  return (
    <OutlinedButton {...props}>
      {children}
      {props.isLoading && (
        <Layer>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </Layer>
      )}
    </OutlinedButton>
  );
}
