import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  getDetailOrder,
  ancillaryServicesSelector,
  AncillaryServices,
  ItemsEntity,
  AddictionalServicesState,
  AddictionalServiceItem,
} from '@modules/orders';
import { Text } from '@components/ui';
import luggageIcon from '@assets/media/booking/luggageIcon.svg';
import seatsIcon from '@assets/media/booking/seatsIcon.svg';
import mealIcon from '@assets/media/booking/mealIcon.svg';
import infoModalImg3 from '@assets/media/booking/InfoModalImg3.svg';
import arrowSrc from '@assets/media/order-detail/arrow1.svg';
import { ReactComponent as Cellphone } from '@assets/media/addictional-services/cellphone.svg';
import { ReactComponent as Refund } from '@assets/media/addictional-services/return.svg';
import { ReactComponent as PrintService } from '@assets/media/addictional-services/print-service.svg';

import pasArrow from '@assets/media/order-detail/pasArrow.svg';
import { Helper, Hooks } from '@utils';

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px 0px #0000000d;
  border-radius: 10px;
  margin-top: 25px;
  @media (max-width: 767px) {
    padding: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const RowSB = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 767px) {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 8px;
  background-color: #f2f2f2;
  margin: 20px 0 20px 0;
`;

const Image3 = styled.img.attrs({ src: infoModalImg3 })`
  transition: transform 0.2s ease-in-out;
  margin-right: 7px;
`;

const Icon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-left: 5px;
  cursor: pointer;
  ${({ reverse }) => (!reverse ? '' : 'transform: rotate(180deg);')} @media (
    max-width: 767px) {
    width: 24px;
    height: 24px;
  }
`;

const BlockWithPadding = styled.div`
  padding: 14px 0 0 0;
`;

const PositionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const PositionHeadRight = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 5px;
  }
`;

const Content = styled.div`
  width: 100%;

  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const PositionsList = styled.div`
  & > div {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const PassengersList = styled.div`
  margin-top: 10px;

  & > div {
    margin-bottom: 12px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

const Position = styled.div<{ open: boolean }>`
  ${({ open }) => {
    if (!open) {
      return `
        ${PositionHeadRight} {
          svg {
            transform: rotate(180deg);
          }
        }
        ${PassengersList} {
          display: none;
        }
      `;
    }
  }}
`;

const PositionName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Passenger = styled.div``;

const PassengerName = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    padding-left: 9px;
  }
`;

const AncillaryList = styled.div`
  margin-top: 3px;
`;
const Ancillary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 5px 0;
  @media (max-width: 767px) {
    padding-left: 9px;
  }
`;

const AncillaryName = styled(Text)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
  color: #737373;
`;
const AncillaryPrice = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const TriangleIcon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-top: 15px;
  cursor: pointer;
  transform: ${({ reverse }) => (reverse ? 'rotate (0deg)' : 'rotate(180deg)')};
  margin-left: 10px;
  margin-bottom: 12px;
`;

const SubText = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;
`;

const LuggageIcon = styled.img.attrs({ src: luggageIcon })`
  margin-right: 7px;
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
`;

const MealIcon = styled.img.attrs({ src: mealIcon })`
  margin-right: 7px;
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
`;
const SeatsIcon = styled.img.attrs({ src: seatsIcon })`
  margin-right: 7px;
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
`;

interface Props {
  additionalItems?: AddictionalServicesState;
}

const AncillaryBookingDetails: React.FC<Props> = ({ additionalItems }) => {
  const [open, setOpen] = React.useState<string[]>([]);
  const items = useSelector(ancillaryServicesSelector);
  const [openSegmentIndexes, setOpenSegmentIndexes] = React.useState(
    {} as { [key: string]: boolean },
  );

  const isBookingSecondPage =
    window.location.pathname.includes('/bookingDetails');
  //TODO ВЫНЕСТИ ДОП УСЛУГИ В ОТДЕЛЬНЫЙ КОМПОНЕНТ
  const { isMobile } = Hooks.useMediaSizes();

  const toggleOpen = (i: string) => {
    if (open.includes(i)) {
      setOpen(open.filter((x: any) => x !== i));
    } else {
      const newOpen = [...open];
      newOpen.push(i);
      setOpen(newOpen);
    }
  };

  const totalLuggagePrice = items.luggage
    ?.map((x) => x?.items?.reduce((acc, x) => acc + x.price, 0))
    .flat()
    ?.reduce((acc, x) => acc + x, 0);

  const totalMealPrice = items.meal
    ?.map((x) => x?.items?.reduce((acc, x) => acc + x.price, 0))
    .flat()
    ?.reduce((acc, x) => acc + x, 0);

  const totalSeatsPrice = items.seats
    ?.map((x) => x?.items?.reduce((acc, y) => acc + y.price, 0))
    .flat()
    //@ts-ignore
    ?.reduce((acc, z) => acc + z, 0);

  function declOfNum(number?: number, titles?: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles && number
      ? number.toString() +
          titles[
            number % 100 > 4 && number % 100 < 20
              ? 2
              : cases[number % 10 < 5 ? number % 10 : 5]
          ]
      : '';
  }

  const itemsArr = (items: any, additionalItems: any) => {
    const result = [];
    if (items.meal.length !== 0) {
      result.push('Питание на борту');
    }
    if (items.luggage.length !== 0) {
      result.push('Дополнительный багаж');
    }
    if (items.seats.length !== 0) {
      result.push('Место в самолете');
    }
    if (additionalItems.find((x: any) => x.type === 'Payment_Receipt')) {
      result.push('Справка о стоимости заказа');
    }
    if (additionalItems.find((x: any) => x.type === 'SMS_Service')) {
      result.push('SMS-информирование');
    }
    if (additionalItems.find((x: any) => x.type === 'Return_Guarantee')) {
      result.push('Гарантия возврата билета');
    }
    return result;
  };

  const additionalTotal =
    !isBookingSecondPage && additionalItems
      ? additionalItems
          ?.filter((x) => x.isPaid)
          .reduce((acc, x) => acc + x.amount, 0)
      : 0;

  return (
    <Wrapper>
      <RowSB>
        <Row>
          <Title>
            {' '}
            {!isBookingSecondPage
              ? 'Забронированные услуги'
              : 'Добавленные услуги'}{' '}
          </Title>
        </Row>
        <Icon
          onClick={() => toggleOpen('ancillary')}
          reverse={open.includes('ancillary')}
          src={pasArrow}
          alt="arrow"
        />
      </RowSB>

      {!open.includes('ancillary') && (
        <>
          <RowSB style={{ marginTop: 20 }}>
            <SubText>
              {declOfNum(
                Object.values(items).filter(
                  (x) => Array.isArray(x) && x.length !== 0,
                )?.length +
                  (!isBookingSecondPage && additionalItems
                    ? additionalItems?.filter((x) => x.isPaid).length
                    : 0),
                [' услуга ', ' услуги ', ' услуг '],
              )}{' '}
            </SubText>
          </RowSB>
          <RowSB style={{ marginTop: 20 }}>
            <Row>
              {itemsArr(
                items,
                !isBookingSecondPage && additionalItems
                  ? additionalItems?.filter((x) => x.isPaid)
                  : [],
              )?.map((item, i) => (
                <SubText
                  key={i}
                  style={{
                    fontWeight: 600,
                    fontSize: isMobile ? 12 : 16,
                    color: '#3C3C3C',
                  }}
                >
                  {item +
                    (i !==
                    itemsArr(
                      items,
                      !isBookingSecondPage && additionalItems
                        ? additionalItems?.filter((x) => x.isPaid)
                        : [],
                    ).length -
                      1
                      ? ','
                      : ' ')}
                  &nbsp;
                </SubText>
              ))}
            </Row>
            <Price>{Helper.formatPrice(items.total + additionalTotal)}</Price>
          </RowSB>
        </>
      )}

      {open.includes('ancillary') && (
        <>
          <BlockWithPadding>
            {items.luggage.length > 0 && (
              <Content>
                <RowSB
                  style={{ marginBottom: isMobile ? 10 : 20, width: '100%' }}
                >
                  <Row>
                    <LuggageIcon />
                    <Head>Дополнительный багаж</Head>
                  </Row>
                  <Price>{Helper.formatPrice(totalLuggagePrice)} </Price>
                </RowSB>
                <PositionsList>
                  {Object.entries(items.mergedLuggage).map((x: any, key) => (
                    <Position
                      key={key}
                      open={openSegmentIndexes[`luggage-${x[0]}-${key}`]}
                    >
                      <PositionHead
                        onClick={() => {
                          const k = `luggage-${x[0]}-${key}`;
                          setOpenSegmentIndexes((s) => ({
                            ...s,
                            [k]: s[k] ? !s[k] : true,
                          }));
                        }}
                      >
                        <PositionHeadRight>
                          <PositionName>{x[0]}</PositionName>
                          <TriangleIcon
                            reverse={
                              openSegmentIndexes[`luggage-${x[0]}-${key}`]
                            }
                            src={arrowSrc}
                          />
                        </PositionHeadRight>
                        <Price>
                          {Helper.formatPrice(
                            x[1].reduce(
                              (acc: number, x: AncillaryServices) =>
                                acc + x.price,
                              0,
                            ),
                          )}
                        </Price>
                      </PositionHead>
                      <PassengersList>
                        {x[1].map((y: AncillaryServices, key: number) => (
                          <Passenger key={key}>
                            <PassengerName>{y.passangerName}</PassengerName>
                            <AncillaryList>
                              <Ancillary>
                                <AncillaryName>
                                  Багаж {y.value} кг{' '}
                                  {y.emDcode && `(№ услуги: ${y.emDcode})`}{' '}
                                </AncillaryName>
                                <AncillaryPrice>
                                  {Helper.formatPrice(y.price)}
                                </AncillaryPrice>
                              </Ancillary>
                            </AncillaryList>
                          </Passenger>
                        ))}
                      </PassengersList>
                    </Position>
                  ))}
                </PositionsList>
              </Content>
            )}
            {items.meal.length > 0 && (
              <Content>
                <Head>
                  <RowSB
                    style={{ marginBottom: isMobile ? 10 : 20, width: '100%' }}
                  >
                    <Row>
                      <MealIcon />
                      <Head>Питание в самолете</Head>
                    </Row>
                    <div>
                      <Price>{Helper.formatPrice(totalMealPrice)} </Price>
                    </div>
                  </RowSB>
                </Head>
                <PositionsList>
                  {Object.entries(items.mergedMeals).map((x: any, key) => (
                    <Position
                      key={key}
                      open={openSegmentIndexes[`food-${x[0]}-${key}`]}
                    >
                      <PositionHead
                        onClick={() => {
                          const k = `food-${x[0]}-${key}`;
                          setOpenSegmentIndexes((s) => ({
                            ...s,
                            [k]: s[k] ? !s[k] : true,
                          }));
                        }}
                      >
                        <PositionHeadRight>
                          <PositionName>{x[0]}</PositionName>
                          <TriangleIcon
                            reverse={openSegmentIndexes[`food-${x[0]}-${key}`]}
                            src={arrowSrc}
                          />
                        </PositionHeadRight>
                        <Price>
                          {Helper.formatPrice(
                            x[1].reduce(
                              (acc: number, x: AncillaryServices) =>
                                acc + x.price,
                              0,
                            ),
                          )}
                        </Price>
                      </PositionHead>
                      <PassengersList>
                        {x[1].map((y: AncillaryServices, key: number) => (
                          <Passenger key={key}>
                            <PassengerName>{y.passangerName}</PassengerName>
                            <AncillaryList>
                              <Ancillary>
                                <AncillaryName>
                                  {y.name}{' '}
                                  {y.emDcode && `(№ услуги: ${y.emDcode})`}{' '}
                                </AncillaryName>
                                <AncillaryPrice>
                                  {Helper.formatPrice(y.price)}
                                </AncillaryPrice>
                              </Ancillary>
                            </AncillaryList>
                          </Passenger>
                        ))}
                      </PassengersList>
                    </Position>
                  ))}
                </PositionsList>
              </Content>
            )}
            {items.seats && items.seats.length > 0 && totalSeatsPrice && (
              <Content>
                <RowSB
                  style={{ marginBottom: isMobile ? 10 : 20, width: '100%' }}
                >
                  <Row>
                    <SeatsIcon />
                    <Head>Места в самолете</Head>
                  </Row>
                  <Price>{Helper.formatPrice(totalSeatsPrice)} </Price>
                </RowSB>
                <PositionsList>
                  {Object.entries(items.mergedSeats).map((x: any, key) => (
                    <Position
                      key={key}
                      open={openSegmentIndexes[`seats-${x[0]}-${key}`]}
                    >
                      <PositionHead
                        onClick={() => {
                          const k = `seats-${x[0]}-${key}`;
                          setOpenSegmentIndexes((s) => ({
                            ...s,
                            [k]: s[k] ? !s[k] : true,
                          }));
                        }}
                      >
                        <PositionHeadRight>
                          <PositionName>{x[0]}</PositionName>
                          <TriangleIcon
                            reverse={openSegmentIndexes[`seats-${x[0]}-${key}`]}
                            src={arrowSrc}
                          />
                        </PositionHeadRight>
                        <Price>
                          {Helper.formatPrice(
                            x[1].reduce(
                              (acc: number, x: ItemsEntity) => acc + x.price,
                              0,
                            ),
                          )}
                        </Price>
                      </PositionHead>
                      <PassengersList>
                        {x[1].map((y: ItemsEntity, key: number) => (
                          <Passenger key={key}>
                            <PassengerName>{y.passangerName}</PassengerName>
                            <AncillaryList>
                              <Ancillary>
                                <AncillaryName>
                                  Место {y.seatNumber}
                                  {y.emDcode && `(№ услуги: ${y.emDcode})`}{' '}
                                </AncillaryName>
                                <AncillaryPrice>
                                  {Helper.formatPrice(y.price)}
                                </AncillaryPrice>
                              </Ancillary>
                            </AncillaryList>
                          </Passenger>
                        ))}
                      </PassengersList>
                    </Position>
                  ))}
                </PositionsList>
              </Content>
            )}
            {!isBookingSecondPage &&
              additionalItems
                ?.filter((x) => x.isPaid)
                .map((x, index) => (
                  <Content key={index}>
                    <Head>
                      <RowSB
                        style={{
                          marginBottom: isMobile ? 10 : 20,
                          width: '100%',
                        }}
                      >
                        <Row>
                          {x.type === 'Payment_Receipt' && (
                            <>
                              <PrintService />
                              <Head style={{ marginLeft: 5 }}>
                                Справка о стоимости заказа
                              </Head>
                            </>
                          )}
                          {x.type === 'SMS_Service' && (
                            <>
                              <Cellphone />
                              <Head style={{ marginLeft: 5 }}>
                                SMS-информирование
                              </Head>
                            </>
                          )}
                          {x.type === 'Return_Guarantee' && (
                            <>
                              <Refund />
                              <Head style={{ marginLeft: 5 }}>
                                Гарантия возврата билета
                              </Head>
                            </>
                          )}
                        </Row>
                        <div>
                          <Price>{Helper.formatPrice(x.amount)} </Price>
                        </div>
                      </RowSB>
                    </Head>
                  </Content>
                ))}
          </BlockWithPadding>
        </>
      )}
    </Wrapper>
  );
};

export default AncillaryBookingDetails;
