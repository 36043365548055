import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { HttpError } from '@utils';

export const setLKLoadingStatus = createAction<boolean>(
  '@personal-area/setLoadingStatus'
);

//TODO move to user module
export const handleHttpErrors = createAction<HttpError | unknown>(
  '@personal-area/handleFetchError'
);

export const setUserError = createAction<string>('@personal-area/setUserError');

const isLoading = createReducer({}, true);
isLoading.on(setLKLoadingStatus, (_, payload) => payload);

const error = createReducer<string>({}, '');
error.on(setUserError, (_, payload) => payload);

export default combineReducers({
  isLoading,
  error,
});
