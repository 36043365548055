import { createAction, createReducer } from 'redux-act';
import {
  GetOrdersListPayload,
  OrdersListState,
  GetOrdersListNextPayload,
} from '../types';
import { OrdersListInitialState } from '../constants';

export const getOrdersList = createAction<GetOrdersListPayload>(
  '@orders/getList'
);

export const getOrdersListNext = createAction<GetOrdersListNextPayload>(
  '@orders/getListNext'
);

export const setOrdersList = createAction<OrdersListState>('@orders/setList');

const orderList = createReducer({}, OrdersListInitialState);
orderList.on(setOrdersList, (_, payload) => ({ ...payload, isLoading: false }));
orderList.on(getOrdersListNext, (state) => ({ ...state, isLoading: true }));

export default orderList;
