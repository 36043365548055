import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { getPassengerIconPath } from '@components/booking/PassengersBlock/utils';
import { PassengerAgeCategory } from '@modules/booking';

interface Props {
  isAuthorized: boolean;
  ageCategory: PassengerAgeCategory;
  ageCategoryDescription: string;
  index: number;
}

const Header = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    ${Text} {
      font-weight: bold;
    }

    @media (max-width: 780px) {
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      ${Text} {
        font-weight: 600;
      }
    }
  `,
  Container: styled.div<{ isAuthorized: boolean }>`
    display: flex;
    align-items: center;
    margin-top: ${(props) => (props.isAuthorized ? '25px' : '15px')};
  `,
  PassengerText: styled.span`
    margin-left: 8px;
    margin-right: 8px;
  `,
};

const PassengerIcon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const PassengerHeader: React.FC<Props> = (props) => {
  const { isAuthorized, ageCategory, index, ageCategoryDescription } = props;
  return (
    <Header.Wrapper>
      <Text size="normal" color="black">
        <Header.Container isAuthorized={isAuthorized}>
          <PassengerIcon src={getPassengerIconPath(ageCategory)} alt="" />
          <Header.PassengerText>
            Пассажир {`${index + 1}, ${ageCategoryDescription.toLowerCase()}`}
          </Header.PassengerText>
        </Header.Container>
      </Text>
    </Header.Wrapper>
  );
};
export default PassengerHeader;
