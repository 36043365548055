import React from 'react';
import { TimeRange } from '@components/ui';
import PlaneLogo from '../../../assets/images/plane';
import { ReactComponent as BusLogo } from '../../../assets/media/buses/busesFilter.svg';

import { ReactComponent as TrainLogo } from '../../../assets/media/header/train.svg';
import styled from 'styled-components';
import { TimeRangeWithPlaneProps } from './types';

const TopWrapper = styled.div`
  margin-bottom: 12px;
`;

const TextWrapper = styled.div`
  & > svg {
    margin: 0 8px;
  }

  display: inline-flex;
  align-items: center;
`;

const Text = styled.span`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.blackGray};
`;

export const TimeRangeWithPlane: React.FC<TimeRangeWithPlaneProps> = ({
  from,
  to,
  type,
  ...props
}) => {
  return (
    <div className="range-time-with-plane">
      <TopWrapper>
        <TextWrapper>
          <Text>{from.city}</Text>
          {type === 'avia' && <PlaneLogo width={24} />}
          {type === 'train' && <TrainLogo />}
          {type === 'bus' && <BusLogo />}

          <Text>{to.city}</Text>
        </TextWrapper>
      </TopWrapper>
      <TimeRange {...props} />
    </div>
  );
};

export default TimeRangeWithPlane;
