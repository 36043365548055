import React from 'react';
import styled from 'styled-components';
import { BlockWithMaxWidth, Title, GrayText, Paragraph } from './shared';
import { ReactComponent as AboutUsIcon } from '@assets/media/main-page/about-us-banner.svg';
import { ReactComponent as AboutUsIconTrain } from '@assets/media/main-page/about-us-banner-train.svg';

interface Props {
  type: 'train' | 'avia' | 'bus';
}

const Container = styled.div`
  background: #fffded;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 45px;
  height: 350px;
  overflow: hidden;

  & > svg {
    align-self: flex-end;
  }

  @media (min-width: 768px) and (max-width: 1169px) {
    position: relative;
    & > svg {
      width: 379.18px;
      height: 206.63px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    & > :first-child {
      z-index: 2;
    }
  }

  @media (max-width: 767px) {
    height: auto;
    display: block;
    padding-left: 0;
    border-radius: 16px;

    & > :first-child {
      margin-bottom: 24px;
    }

    & > svg {
      width: 100%;
      height: auto;
    }
  }
`;

const InfoContainer = styled.div`
  width: 474px;

  & > span {
    display: block;
  }

  ${GrayText} {
    margin-bottom: 8px;
  }

  ${Title} {
    margin-bottom: 24px;
  }

  ${Paragraph} {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: auto;
    padding: 30px 21px 0 21px;
    ${GrayText} {
      margin-bottom: 5px;
    }
  }
`;

export const AboutUsBanner: React.FC<Props> = ({ type }) => {
  const isAvia = type === 'avia';
  const isTrain = type === 'train';
  return (
    <BlockWithMaxWidth>
      <Container>
        <InfoContainer>
          <GrayText>О нашем сервисе</GrayText>
          <Title>
            {isTrain && 'Поиск и бронирование ж/д билетов'}
            {isAvia && 'Поиск и бронирование авиабилетов'}
          </Title>
          <Paragraph>
            {isTrain &&
              'Выбирайте и покупайте билеты на поезда России и СНГ прямо на сайте Fun&Sun. Больше не надо ходить в кассу, покупка билетов произойдет за пару кликов.'}
            {isAvia &&
              'Наш сервис — это быстрый поиск и легкое бронирование авиабилетов в любую точку мира. Теперь вам не нужно переходить на сторонние сайты авиакомпаний.'}
          </Paragraph>
          <Paragraph>
            {isTrain &&
              'Мы предоставим широкий выбор и предложим самые выгодные варианты для бронирования, покажем расписание поездов, наличие мест и маршрут следования.'}
            {isAvia &&
              'Мы предоставим широкий выбор авиабилетов, сравним цены и предложим самые выгодные варианты для бронирования.'}
          </Paragraph>
        </InfoContainer>
        {isTrain && <AboutUsIconTrain />}
        {isAvia && <AboutUsIcon />}
      </Container>
    </BlockWithMaxWidth>
  );
};
