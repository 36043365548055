import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import { ItemProps } from './types';
import { ReactComponent as Cellphone } from '@assets/media/addictional-services/print-service.svg';
import { ReactComponent as DownloadIcon } from '@assets/media/shared/download.svg';
import { ReactComponent as Paid } from './assets/media/paid.svg';
import { ReactComponent as RecommendIcon } from '@assets/media/addictional-services/for-work.svg';
import { getDetailOrder } from '@modules/orders';
import { useSelector } from 'react-redux';
import ExpirationTime from './ExpirationTime';

import { useModalState } from './context';

//TODO

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  position: relative;
  @media (max-width: 767px) {
    padding: 10px 14px;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-left: 7px;
  @media (max-width: 1121px) {
    position: initial;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4872f2;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Description = styled(Text)`
  display: block;

  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

const Rule = styled(Text)`
  color: #4872f2;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
  @media (max-width: 767px) {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #3c3c3c;
    margin-top: 15px;
  }
`;

const AddedIcon = styled(Paid)`
  position: absolute;
  right: -111px;
  @media (max-width: 767px) {
    position: absolute;
    right: -4px;
    top: -12px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Action = styled(Button).attrs({ htmlType: 'button' })`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;
  min-width: 144px;
  display: block;
  margin-top: 15px;
`;

const DownloadButton = styled(Button).attrs({ htmlType: 'button' })`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  min-width: 144px;
  margin-top: 15px;
  height: 32px;
  & > :first-child {
    margin-right: 3px;
  }
`;

const Label = styled(RecommendIcon)`
  position: absolute;
  right: -157px;
  top: -3px;
  @media (max-width: 1121px) {
    top: -12px;
    right: -4px;
  }
`;

export default function (props: ItemProps) {
  const { showModal, handleActionButtonClick } = useModalState();
  const { id, availableActions } = useSelector(getDetailOrder);

  return (
    <Wrapper>
      <Content>
        <Head>
          <Row>
            <Cellphone style={{ width: 30, height: 30 }} />
            <Name>
              Справка о стоимости заказа{' '}
              {props.isPaid ? <AddedIcon /> : <Label />}
            </Name>
          </Row>
          <Price data-cy={'orderDetailsPrintServicePrice'}>
            {Helper.formatPrice(props.amount)}
          </Price>
        </Head>
        <Description>
          Справка может понадобиться бухгалтерии с вашего места работы для
          компенсации оплаты авиаперелета.
        </Description>
        {props.expires && <ExpirationTime value={props.expires} />}
        <Rule onClick={() => showModal(props.type)}>
          Подробнее <span style={{ color: '#4872F2' }}>об условиях</span>{' '}
        </Rule>
        {/*{isMobile && props.isPaid && <AddedIcon />}*/}
        {!props.isPaid && (
          <Action
            data-cy={'additionalServicePrintButtonToggle'}
            onClick={() => handleActionButtonClick(props)}
            isLoading={props.isLoading}
            color={props.checked ? 'gray' : undefined}
          >
            {!props.checked ? 'Добавить услугу' : 'Убрать услугу'}
          </Action>
        )}
        {props.isPaid &&
          (availableActions || []).includes('PAYMENT_RECEIPT') && (
            <DownloadButton
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_PRINT_CHECK}?orderId=${id}&format=1`;
              }}
              isLoading={props.isLoading}
              color={props.checked ? 'gray' : undefined}
            >
              <DownloadIcon /> Скачать справку
            </DownloadButton>
          )}
      </Content>
    </Wrapper>
  );
}
