import React from 'react';
import AviaButton from './AviaButton';
import Popup from './Popup';

const Template = () => {
  const [isOpen, setOpen] = React.useState(false);
  const handleButtonOpen: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AviaButton onClick={handleButtonOpen} />
      <Popup open={isOpen} onClose={handleOnClose} />
    </>
  );
};

export default Template;
