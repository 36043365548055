import React from 'react';
import styled from 'styled-components';
import {
  CAR_PLACE_GENDER_TYPES_SHORT,
  CarPlace,
  FreePlacesByCompartment,
} from '@modules/trainTickets';
import { parseInt } from 'lodash';
import { getCarPlaceGenderTypeByPlaceNumber } from '@components/train/TrainSearch/utils';

interface Props {
  freePlaces: FreePlacesByCompartment[];
  carType: string;
  chosenSeats: CarPlace[];

  onClick(el: Element, placeNumber: string): void;

  onMouseEnter(el: Element, placeNumber: string): void;

  onMouseLeave(): void;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  font-size: 12px;
  padding: 10px;
  border: 1px solid #cad6ff;
  border-radius: 4px;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`;

const CompContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 75px;
`;

const Square = styled.button<{ background: string; borderColor: string }>`
  width: 30px;
  height: 30px;
  background: ${({ background }) => background};
  border-radius: 6px;
  border: 1px solid ${({ borderColor }) => borderColor};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export const CustomCarScheme = (props: Props) => {
  const isChosen = (placeNumber: string) => {
    return props.chosenSeats
      ?.map((x) => parseInt(x.number) || 0)
      .includes(parseInt(placeNumber));
  };

  return (
    <Container>
      {!!props.freePlaces.length &&
        props.freePlaces.map((comp, index) => {
          const genderType = getCarPlaceGenderTypeByPlaceNumber(
            comp.places[0].number,
          );
          const genderText =
            genderType !== null
              ? `${CAR_PLACE_GENDER_TYPES_SHORT[genderType]}`
              : '';
          return (
            <CompContainer key={index}>
              <span>
                {props.carType} {comp.compartmentNumber}{' '}
                {!!genderText && <BoldText>{`(${genderText})`}</BoldText>}
              </span>
              <ButtonsContainer>
                {comp.places.map((place, index) => {
                  return (
                    <Square
                      key={index}
                      onMouseEnter={(event) => {
                        props.onMouseEnter(event.currentTarget, place.number);
                      }}
                      onMouseLeave={(event) => {
                        props.onMouseLeave();
                      }}
                      onClick={(event) => {
                        props.onClick(event.currentTarget, place.number);
                      }}
                      background={
                        isChosen(place.number) ? '#F57269' : '#CA96DB'
                      }
                      borderColor={
                        place.prices[0].hasNonRefundableTariff
                          ? '#E73C3E'
                          : 'transparent'
                      }
                    >
                      {parseInt(place.number)}
                    </Square>
                  );
                })}
              </ButtonsContainer>
            </CompContainer>
          );
        })}
    </Container>
  );
};

export default CustomCarScheme;
