import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { BaseModalProps } from './types';
import { Text } from '@components/ui';
import { getSelectedFare } from '@modules/booking';
import { useSelector } from 'react-redux';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
      overflow: scroll;
    }
  }

  &-content {
    width: 680px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px 20px 40px 40px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      padding: 20px 10px 20px 20px;
      box-sizing: border-box;
      border-radius: 0;
    }
  }
`;

const OverflowContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding-right: 20px;

  @media (max-width: 767px) {
    max-height: 90vh;
    padding-right: 10px;
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TextContainer = styled.p`
  margin-bottom: 25px;

  white-space: pre-line;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 46px;
  outline: none;

  @media (max-width: 767px) {
    right: 25px;
    top: 25px;
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
  display: block;
  margin-right: 8px;
`;

const HealthDeclorationModal: React.FC<BaseModalProps> = (props) => {
  const fare = useSelector(getSelectedFare);

  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Cross onClick={props.onClose} />
      <PopupHead>
        <Title>
          <TitleText>
            Заявление о состоянии здоровья в связи с COVID-19
          </TitleText>
        </Title>
      </PopupHead>
      <MobileHead>
        <MobileTitle>
          Заявление о состоянии здоровья <br /> в связи с COVID-19
        </MobileTitle>{' '}
      </MobileHead>
      <OverflowContainer>
        <TextContainer>
          <Text>{fare.healthDeclarationText}</Text>
        </TextContainer>
      </OverflowContainer>
    </StyledPopup>
  );
};

export default HealthDeclorationModal;
