import { extractFirstNumber } from '@modules/main/utilities';
import { isNumber } from 'lodash';
import { FareFamily } from '@modules/orders/types';
import { FareFamilyEntity } from '@modules/booking';

export function setPage(page?: number) {
  return page ? page : 1;
}

export function setPageSize(pageSize?: number) {
  return pageSize ? pageSize : 0;
}

export const getMinBaggageDescription = (
  fareFamilies: FareFamilyEntity[] | FareFamily[],
): string | null => {
  let minBaggageValue: number | null = null;
  let minBaggageDescription: string | null = null;

  for (const fareFamily of fareFamilies) {
    for (const feature of fareFamily.features || []) {
      if (feature.type === 'Baggage') {
        // @ts-ignore
        // TODO: бэку надо синхронизировать интерфейсы FareFamilyEntity[] | FareFamily[], добавить short описание
        const description = feature.shortDescriptionRus || feature.descriptionRus;
         if (description.toLowerCase().includes('платный')  || description === 'Не включен') {
          return description;
        } else {
          const value = extractFirstNumber(description);
          if (isNumber(value)) {
            if (minBaggageValue === null || value < minBaggageValue) {
              minBaggageValue = value;
              minBaggageDescription = description;
            }
          }
        }
      }
    }
  }
  return minBaggageDescription;
};
