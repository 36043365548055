import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export interface Props {
  address: string | null;
  geometry: number[];
}

const MapContainer = ({ address, geometry }: Props) => (
  <YMaps>
    <Map
      width="100%"
      height="100%"
      defaultState={{
        center: geometry,
        zoom: 15,
        controls: ['zoomControl', 'fullscreenControl'],
      }}
      modules={['control.ZoomControl', 'control.FullscreenControl']}
    >
      <Placemark
        modules={['geoObject.addon.balloon']}
        defaultGeometry={geometry}
        properties={{
          balloonContentBody: address,
        }}
      />
    </Map>
  </YMaps>
);

export default MapContainer;
