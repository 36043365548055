import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { InfoBlock } from '@components/common';
import Text, { Header } from '@components/ui/Typography';
import { useSelector } from 'react-redux';
import AviaPassengerList from './AviaPassengersList';
import styled from 'styled-components';
import { ApplicationState } from '@modules/index';
import { BaseDescriptionEntity } from '@modules/booking';
import TrainPassengerList from '@components/booking/PassengersBlock/TrainPassengersList';
import { getCountryList } from '../../../utils/helpers';
import ReactCountryFlag from 'react-country-flag';
import { CountryListItemType } from '@components/booking/PassengersBlock/types';
import { COUNTRIES_WITHOUT_FLAGS } from '@modules/trainBooking';
import PassengerEditWarning from './PassengerEditWarning';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';

const emojiSupport = require('detect-emoji-support');

const Body = styled.div`
  margin-top: 15px;
  ${({ theme }) => `
      ${theme.max('767px')} {
        margin-top: 10px;
      }
  `}
`;

interface Props {
  isAvia?: boolean;
  frequentFlyerAirlines?: BaseDescriptionEntity[];
  setIsShowError?: Dispatch<SetStateAction<boolean>>;
}

const Description = styled(Text)<{ isAvia?: boolean }>`
  margin-bottom: 32px;
  @media (max-width: 767px) {
    display: ${({ isAvia }) => isAvia && 'none'};
  }
`;

const Wrapper = styled(InfoBlock)`
  @media (max-width: 1023px) {
    padding: 20px 20px;
  }
  @media (max-width: 767px) {
    padding: 14px;
    width: 100%;
    margin-top: 30px;
  }
  @media (min-width: 767px) {
    margin-top: 30px;
  }
`;

const PassengersBlock: React.FC<Props> = (props: Props) => {
  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType
  );
  const booking = useSelector((state: ApplicationState) => state.booking);
  const [countriesList, setCountriesList] = useState<CountryListItemType[]>([]);

  const isAvia = searchPanelType === 'avia';
  const ticket = booking.ticket;
  const flights = ticket.flights;

  const oneOfFlightsIsCharter = flights
    ?.map((flight: any) =>
      flight?.segments?.find(
        (segment: any) => segment?.flight_Type === FLIGHT_TYPES.CHARTER,
      )
    )
    .filter(Boolean);

  useEffect(() => {
    const getCountry = async () => {
      let countries = await getCountryList();
      // if Avia, remove countries with codes from COUNTRIES_WITHOUT_FLAGS
      if (isAvia) {
        countries = countries.filter(
          (country) => !COUNTRIES_WITHOUT_FLAGS.includes(country.value)
        );
      }

      if (countries.length) {
        setCountriesList(
          countries.map((country) => ({
            value: country.value,
            label: country.label,
            image: COUNTRIES_WITHOUT_FLAGS.includes(country.value) ? null : (
              <ReactCountryFlag
                countryCode={country.value}
                svg
                style={{
                  marginRight: 5,
                  width: '24px',
                  height: '18px',
                  borderRadius: '30%',
                }}
              />
            ),
          }))
        );
      }
    };
    getCountry();
  }, []);

  return (
    <Wrapper>
      <Header size="h4">Пассажиры</Header>
      <Body id="passengers">
        <Description color="black" size="normal" isAvia={isAvia}>
          {isAvia ? (
            'Внимательно внесите данные пассажиров. После оплаты корректировка данных платная. Авиакомпания может отказать в посадке, если данные не совпадут с документами.'
          ) : (
            <PassengerEditWarning />
          )}
        </Description>
        {oneOfFlightsIsCharter?.length !== 0 && isAvia && (
          <Text style={{ marginTop: 15, fontWeight: 'bold', display: 'block' }}>
            <span style={{ color: '#F25F61' }}>Внимание!</span> При бронировании
            чартерного перелета поле “Отчество” заполнять не нужно.
          </Text>
        )}

        {isAvia ? (
          <AviaPassengerList
            countriesList={countriesList}
            onChange={(data, index) => {}}
            setIsShowError={props.setIsShowError}
          />
        ) : (
          <TrainPassengerList
            countriesList={countriesList}
            onChange={(data, index) => {}}
          />
        )}
      </Body>
    </Wrapper>
  );
};

export default PassengersBlock;
