import {
  CAR_PLACE_GENDER_TYPES,
  CarPlace,
  FreePlacesByCompartment,
} from '@modules/trainTickets';

export const getTicketCost = (
  place: CarPlace[],
  withBedClothes: boolean | null,
  totalPriceIsPriceForOneSeat: boolean,
  maxSeats: number,
  bedsheetServiceCost: number,
  onlyChildInTrip: boolean,
) => {
  let cost = 0;
  if (totalPriceIsPriceForOneSeat) {
    const tariff = place[0].prices.find((x) =>
      onlyChildInTrip ? x.tariffType === 'Child' : x.tariffType === 'Full',
    )!;
    if (maxSeats > 1) {
      cost = tariff?.maxPrice.totalPrice || 0;
    } else {
      cost = tariff?.minPrice.totalPrice || 0;
    }
    return cost;
  } else {
    place.forEach((x) => {
      const fullTariffPrice = x.prices.find((x) =>
        onlyChildInTrip ? x.tariffType === 'Child' : x.tariffType === 'Full',
      );
      cost += fullTariffPrice?.maxPrice.totalPrice || 0;
      if (withBedClothes === false && fullTariffPrice) {
        cost -= bedsheetServiceCost;
      }
    });
  }
  return cost;
};

// все места в купе свободны
export const allPlacesInCompartmentIsFree = (
  freePlacesByCompartment: FreePlacesByCompartment,
) => {
  return freePlacesByCompartment?.places?.length === 4;
};

export const getCompartmentByCompartmentNumber = (
  freePlacesByCompartments: FreePlacesByCompartment[],
  compartmentNumber: number,
) => {
  return freePlacesByCompartments.find(
    (x, index) => parseInt(x.compartmentNumber) === compartmentNumber,
  );
};

// найти номер купе по номеру места
export const getCompartmentByPlaceNumber = (
  freePlacesByCompartments: FreePlacesByCompartment[],
  placeNumber: number,
): FreePlacesByCompartment | null => {
  let compartmentNumber: string;
  let compartment: FreePlacesByCompartment | null = null;
  freePlacesByCompartments.forEach((comp, index) => {
    const place = comp.places.find((x) => parseInt(x.number) === placeNumber);
    if (place) {
      compartmentNumber = comp.compartmentNumber;
    }
  });
  compartment =
    freePlacesByCompartments.find(
      (x) => x.compartmentNumber === compartmentNumber,
    ) || null;
  return compartment;
};

export const getCarPlaceGenderTypeByPlaceNumber = (
  placeNumber: string,
): keyof typeof CAR_PLACE_GENDER_TYPES | null => {
  const match = placeNumber.replace(/\d/g, '');
  switch (match) {
    case 'Ж':
      return 'female';
    case 'М':
      return 'male';
    case 'Ц':
      return 'canChose';
    case 'С':
      return 'mixed';
    default:
      return null;
  }
};

export const getCarPlaceGenderCharByGenderType = (
  genderChar: keyof typeof CAR_PLACE_GENDER_TYPES,
): 'Ж' | 'М' | 'Ц' | 'С' | null => {
  switch (genderChar) {
    case 'female':
      return 'Ж';
    case 'male':
      return 'М';
    case 'canChose':
      return 'С';
    case 'mixed':
      return 'Ц';
    default:
      return null;
  }
};

export function makeSVGEl(tag: string, attrs: any) {
  const el = document.createElementNS('http://www.w3.org/2000/svg', tag);
  for (const k in attrs) {
    el.setAttribute(k, attrs[k]);
  }
  return el;
}
