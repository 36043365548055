import { GetTrainRouteDto } from '@modules/trainTickets/dto/GetTrainRouteDto';
import { RoutePoint } from '@modules/busTickets/types';

export function normalizeTicketsList(args: any): any {
  return {
    ...args,
    busesPerRoutes: args.busesPerRoutes.map((x: any) => {
      return {
        ...x,
        buses: x.buses.map((x: any, index: number) => ({ ...x, index })),
      };
    }),
  };
}

export const transformRaceToTrainRouteDto = (race: any): GetTrainRouteDto => {
  const routePoints = race.routePoints;

  const originStation = {
    name: routePoints[0].name,
    address: routePoints[0].address,
  };

  const arrivalStation = {
    name: routePoints[routePoints.length - 1].name,
    address: routePoints[routePoints.length - 1].address,
  };

  const routes = routePoints.map((point: RoutePoint) => {
    return {
      arrivalDateTime: point.arrivalDateTime,
      arrivalTime: point.arrivalDateTime
        ? new Date(point.arrivalDateTime).toLocaleTimeString()
        : null,
      clarification: null,
      departureDateTime: point.departureDateTime,
      departureTime: point.departureDateTime
        ? new Date(point.departureDateTime).toLocaleTimeString()
        : null,
      distanceFromInitialStation: point.distance,
      localArrivalDateTime: null,
      localDepartureTime: null,
      routeStopType: 0, // Укажите нужный тип остановки
      station: {
        name: point.name,
        address: point.address,
      },
      stopDuration: point.stopDurationInMinutes || 0,
    };
  });

  return {
    originStation,
    arrivalStation,
    routes: [routes],
  } as unknown as GetTrainRouteDto;
};
