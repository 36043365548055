import React from 'react';
import { Table } from './table';
import { Month } from './month';
import { BaseCalendarWrapper } from './styles';
import { MonthListItem } from './context';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';

interface BaseCalendarProps extends React.HTMLAttributes<HTMLDivElement> {
  month: {
    list: MonthListItem;
    index: number;
  };
}

export const BaseCalendar: React.FC<BaseCalendarProps> = ({
  month,
  ...props
}) => {
  let daysLimit: number = 0;
  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType
  );
  if (searchPanelType === 'train') {
    daysLimit = 119;
  } else {
    daysLimit = 0;
  }
  return (
    <BaseCalendarWrapper {...props}>
      <Month {...month} />
      <Table index={month.index} daysLimit={daysLimit} />
    </BaseCalendarWrapper>
  );
};
