import {
  AboutUsBanner,
  WhatYouShouldKnow,
  WhyChooseUsBanner,
  SpecialOffers,
  BlockWithMaxWidth,
  SupportForOrderBanner,
  // OnlyBetweenUs,
  // SubcribeUsBanner,
  AdditionalServicesBanner,
  WhatYouShouldKnowAgent,
  FrequentlyAskedQuestionsBanner,
} from '@components/mainPage';
import UsefulInformation from '@components/mainPage/UsefulInformation';
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { promotionsRequest } from '@modules/main';
import { ApplicationState } from '@modules/index';

const Container = styled.div`
  margin: 60px 0 96px 0;

  & > :first-child {
    margin-bottom: 116px;
  }

  & > div {
    margin-bottom: 120px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1169px) {
    & > div {
      margin-bottom: 80px;
    }
    & > :first-child {
      margin-bottom: 80px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
    & > :first-child {
      margin-bottom: 60px;
    }
    & > div {
      margin-bottom: 60px;
    }
  }
`;

const Flex = styled(BlockWithMaxWidth)`
  display: flex;

  & > :first-child {
    margin-right: 28px;
  }

  & > div {
    width: 50%;
  }

  @media (max-width: 767px) {
    display: block;
    padding: 0;
    & > div {
      width: auto;
    }
    & > :first-child {
      margin-right: 0;
      margin-bottom: 35px;
    }

    & > :last-child {
      margin: 0 20px;
    }
  }
`;

const TuiPage = () => {
  const user = useSelector((x:ApplicationState) => x.user);
  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType,
  );
  const isAvia = searchPanelType === 'avia';
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(promotionsRequest());
  }, []);

  return (
    <Container>
      <AboutUsBanner type={searchPanelType} />
      {!user?.data?.agentId ? (
        <WhatYouShouldKnow type={searchPanelType} />
      ) : (
        <WhatYouShouldKnowAgent />
      )}

      <Flex>
        <WhyChooseUsBanner type={searchPanelType} />
        <SupportForOrderBanner type={searchPanelType} />
      </Flex>

      {isAvia && <FrequentlyAskedQuestionsBanner />}
      {isAvia && <SpecialOffers />}
      {isAvia && <AdditionalServicesBanner />}
      {user?.data?.agentId && <UsefulInformation />}
      {/* <LastSection>
        <SubcribeUsBanner />
        <OnlyBetweenUs />
      </LastSection> */}
    </Container>
  );
};

export default TuiPage;
