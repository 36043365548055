import { AviaPassengerEntity, DocumentTypes } from '@modules/user';
import moment from 'moment';
import {
  NATIONAL_PASSPORT,
  RUSSIAN_PASSPORT,
  BIRTH_CERTIFICATE,
  INTERNATIONAL_PASSPORT,
  INTERNATIONAL_PASSPORT1,
} from './constants';

export function createFormInitialValues(args: AviaPassengerEntity) {
  return {
    ...args,
    birthDate: args.birthDate ? new Date(args.birthDate) : null,
    documents: args.documents.map((x) => ({
      ...x,
      issueDate: x.issueDate ? new Date(x.issueDate) : null,
      citizenship: x.citizenship || 'Россия',
    })),
  };
}

export function convertResultToAPIType(
  args: ReturnType<typeof createFormInitialValues>
): AviaPassengerEntity {
  return {
    ...args,
    birthDate: moment(args.birthDate).format(),
    documents: args.documents.map((x) => ({
      ...x,
      issueDate: x.issueDate
        ? moment(x.issueDate as any).format()
        : x.issueDate,
      id: '00000000-0000-0000-0000-000000000000',
    })),
  };
}

export function formatDocumentValue(type: DocumentTypes) {
  switch (type) {
    case DocumentTypes.InternationalPassport:
      return INTERNATIONAL_PASSPORT;
    case DocumentTypes.BirthCertificate:
      return BIRTH_CERTIFICATE;
    case DocumentTypes.NationalPassport:
      return NATIONAL_PASSPORT;
    case DocumentTypes.Other:
      return INTERNATIONAL_PASSPORT1;
    case DocumentTypes.RussianFederationPassport:
    default:
      return RUSSIAN_PASSPORT;
  }
}

export function getNumberInputMask(type: DocumentTypes) {
  const ru = /[A-Za-zА-Яа-я0-9]/;
  const eng = /[A-Za-z]/;
  const digit = /[0-9]/;

  switch (type) {
    case DocumentTypes.RussianFederationPassport:
      return '9999-999999';
    case DocumentTypes.InternationalPassport:
      return '99 999999';
    case DocumentTypes.BirthCertificate:
      return [
        eng,
        eng,
        '-',
        ru,
        ru,
        ' ',
        digit,
        digit,
        digit,
        digit,
        digit,
        digit,
      ];
    default:
      return '';
  }
}

export function getPlaceholder(type: DocumentTypes, cityzien: string) {
  if (cityzien !== 'RU') {
    return '';
  }
  switch (type) {
    case DocumentTypes.RussianFederationPassport:
      return '2221-999999';
    case DocumentTypes.InternationalPassport:
      return '88 999222';
    default:
      return '';
  }
}

export const isCitizenshipVisible = (type: DocumentTypes) =>
  type !== DocumentTypes.RussianFederationPassport;
