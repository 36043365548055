import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import newSeatBlock from '@assets/media/addictional-services/newSeatsBlock.png';
import newMealBlock from '@assets/media/addictional-services/newMealsBlock.png';
import newBaggageBlock from '@assets/media/addictional-services/newBaggageBlock.png';
import newBaggageCircle from '@assets/media/addictional-services/newBaggageCircle.svg';
import newMealsCircle from '@assets/media/addictional-services/newMealsCircle.svg';
import newSeatsCircle from '@assets/media/addictional-services/newSeatsCircle.svg';

interface Props {
  text: string;
  type: string;
  onClick: () => void;
  dataCy?: string;
}

interface LinkProps {
  isSeats: boolean;
  isMeals: boolean;
}

const Container = styled.div<LinkProps>`
  background: ${({ isSeats, isMeals }) =>
    isSeats ? '#EDF4FF' : isMeals ? '#FFFBE3' : '#F5EDFF'};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 14px 29px 14px 24px;
  width: 378px;
  height: 218px;
  position: relative;
  & > div {
    margin-bottom: 14px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: auto;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #3c3c3c;
`;

const StyledButton = styled(Button).attrs({ htmlType: 'button' })`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 16px;
  background: #ffe100;
  color: #3c3c3c;
`;

const StyledText = styled.div`
  width: 223px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #3c3c3c;
  text-align: left;
  margin: 10px 0;
`;

const NewSeatBlock = styled.img.attrs({ src: newSeatBlock })`
  position: absolute;
  right: -50px;
  bottom: 0px;
  z-index: 100;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const NewMealsBlock = styled.img.attrs({ src: newMealBlock })`
  position: absolute;
  right: -10px;
  bottom: -10px;
  z-index: 100;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const NewBaggageBlock = styled.img.attrs({ src: newBaggageBlock })`
  position: absolute;
  right: -85px;
  bottom: 10px;
  z-index: 100;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const NewBaggageCircle = styled.img.attrs({ src: newBaggageCircle })`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const NewMealsCircle = styled.img.attrs({ src: newMealsCircle })`
  position: absolute;
  right: 0;
  top: 0;
`;

const NewSeatsCircle = styled.img.attrs({ src: newSeatsCircle })`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export default function OfferBlock(props: Props) {
  const { text, dataCy, type } = props;

  return (
    <Container isSeats={type === 'seat'} isMeals={type === 'meal'}>
      <Heading>
        {props.type === 'meal'
          ? 'Питание в самолете'
          : props.type === 'seat'
          ? 'Летайте с комфортом!'
          : 'Дополнительный багаж'}
      </Heading>
      <Flex>
        {props.type === 'meal' ? (
          <NewMealsBlock />
        ) : props.type === 'seat' ? (
          <NewSeatBlock />
        ) : (
          <NewBaggageBlock />
        )}
        {props.type === 'meal' ? (
          <NewMealsCircle />
        ) : props.type === 'seat' ? (
          <NewSeatsCircle />
        ) : (
          <NewBaggageCircle />
        )}
        <StyledText>{text}</StyledText>
      </Flex>
      <StyledButton data-cy={dataCy || ''} onClick={props.onClick}>
        {props.type === 'meal'
          ? 'Добавить питание'
          : props.type === 'seat'
          ? 'Выбрать место'
          : 'Добавить багаж'}
      </StyledButton>
    </Container>
  );
}
