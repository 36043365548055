import React from 'react';
import styled from 'styled-components';
import { useTicketProps } from '../useTicketProps';
import { NavLink } from 'react-router-dom';
import { Helper, Api } from '@utils';
import { ReactComponent as LuggageIcon } from '@assets/media/flight-search/luggage-round-icon.svg';
import { Text as BaseText } from '@components/ui';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FlexSpaceBetween = styled(Flex)`
  justify-content: space-between;
`;

const Container = styled(FlexSpaceBetween)`
  padding: 0 14px;
  padding-top: 9px;
  border-top: 1px solid #f2f2f2;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BaggageWrapper = styled(Flex)`
  & > :first-child {
    margin-right: 5px;
  }
`;

const Button = styled(NavLink)`
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background: #4872f2;
  border-radius: 4px;
  padding: 7px 10.5px;
  min-width: 139px;
  box-sizing: border-box;
  text-align: center;
`;

const Text = styled(BaseText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

export default function MobileHead() {
  const { prices, groupIndex, searchId, flights, baggage } = useTicketProps();

  return (
    <Container>
      <BaggageWrapper>
        <LuggageIcon />
        <Text>
          {baggage && baggage.value > 0 ? `Багаж до ${baggage.value} кг` : 'Багаж не включен'}
        </Text>
      </BaggageWrapper>
      <Button
        to={`/booking/${searchId}/?${Api.createQueryParams({
          group: groupIndex,
          flights: flights.map((flight) => flight.index),
        })}`}
      >
        {' '}
        Купить за {Helper.formatPrice(prices[0])}
      </Button>
    </Container>
  );
}
