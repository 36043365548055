import React from 'react';
import { DoubleView } from './double-view';

import {
  CalendarProvider,
  ProviderProps,
  CalendarChangeHandler,
} from './context';

/** Кастомный календарь для выбора дат отправления и прибытия*/
export const Calendar = (props: ProviderProps) => (
  <CalendarProvider {...props}>
    <DoubleView />
  </CalendarProvider>
);
export type CalendarProps = ProviderProps;
export type CalendarChangeEventHandler = CalendarChangeHandler;
