import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Field, Button, Text, ButtonLoader } from '@components/ui';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  forgotPasswordRequest,
  getUserStatusState,
  signIn,
} from '@modules/user';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BottomAuthOrTinkoffPart from '@components/authModals/SignIn/BottomAuthOrTinkoffPart';

const { Header } = Text;

const StyledHeader = styled(Header)`
  text-align: center;
  margin-bottom: 32px;
`;

const Container = styled(InfoBlock)`
  width: 468px;
  align-self: center;
  @media (max-width: 767px) {
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 20px;
    width: 100%;
    box-shadow: none;
    margin-top: 30px;
    height: calc(100vh - 54px);
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 32px;
  display: grid;
`;

interface MessageProps {
  success?: boolean;
  failure?: boolean;
}

const Message = styled(Text)<MessageProps>`
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }

  ${(props) => (props.success ? 'color: #4872f2;' : '')}
  ${(props) => (props.failure ? 'color: red;' : '')}
    & > span {
    color: #4872f2;
  }
`;

const TimerText = styled.span<{ reverse: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  font-family: Open Sans;
  font-style: normal;

  & > * {
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & > :first-child {
    font-weight: 600;
    color: #4872f2;
  }

  & > :last-child {
    font-weight: 400;
    color: #737373;
    border: none;
    outline: none;
    background: transparent;
  }

  ${({ reverse }) =>
    reverse
      ? `& > :last-child {
    font-weight: 600;
    color: #4872f2;
    cursor: pointer;
  }

  & > :first-child {
    font-weight: 600;
    color: #737373;
    border: none;
    outline: none;
    background: transparent;
  }`
      : ''}
`;

const Timer = ({ onClick }: { onClick: () => void }) => {
  const [state, setState] = React.useState(59);
  const [startTimer, setStart] = React.useState(true);

  React.useEffect(() => {
    let id: NodeJS.Timeout = 0 as any;
    if (startTimer) {
      id = setInterval(() => {
        let clear = false;
        setState((s) => {
          const result = s - 1;
          if (s === 0) {
            clear = true;
          }
          return result >= 0 ? result : 0;
        });
        if (clear) {
          clearInterval(id);
          setStart(false);
        }
      }, 1000);
    }

    return () => clearInterval(id);
  }, [startTimer]);

  const handleClick = () => {
    if (state === 0) {
      onClick();
      setState(59);
      setStart(true);
    }
  };

  return (
    <TimerText reverse={state === 0}>
      <strong>00:{state < 10 ? `0${state}` : state}</strong>{' '}
      <button onClick={handleClick} type="button">
        Выслать письмо повторно
      </button>
    </TimerText>
  );
};

function generateTextOnSuccess(mail: string) {
  return (
    <>
      На вашу электронную почту <span>{mail}</span> было отправлено письмо с
      новым паролем. Введите его для авторизации.
    </>
  );
}

const COMMON_TEXT =
  'Введите адрес электронной почты на который вы регистрировали профиль, а мы пришлем вам новый пароль.';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const ForgetPassword: React.FC = () => {
  const initialValues = {
    email: '',
  };

  const [email, setEmail] = React.useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { forgetPassword, isFetching, error } = useSelector(getUserStatusState);
  const onSubmit = (data: any) => {
    if (!forgetPassword) {
      setEmail(data.email);
      dispatch(forgotPasswordRequest(data.email));
    }
  };

  const onLogin = (data: any) => {
    dispatch(
      signIn({
        login: email,
        password: data.password,
        remember: true,
        resolve: () => {
          history.push('/profile/contacts');
        },
      })
    );
  };

  return (
    <Wrapper>
      <Container>
        <StyledHeader>Сброс пароля</StyledHeader>
        <Message failure={error !== null}>
          {forgetPassword
            ? generateTextOnSuccess(email)
            : error === null
            ? COMMON_TEXT
            : error.message}
        </Message>
        {!forgetPassword && (
          <Formik
            initialValues={initialValues}
            validationSchema={yup.object().shape({
              email: yup.string().required('Заполните'),
            })}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              submitCount,
            }) => (
              <Form>
                <Row>
                  <StyledField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    error={
                      errors.email && submitCount > 0 ? errors.email : undefined
                    }
                    placeholder="Email"
                  />
                </Row>
                <Row>
                  <Button
                    disabled={isFetching}
                    htmlType="submit"
                    color="yellow"
                  >
                    <ButtonLoader isLoading={isFetching}>
                      Сбросить пароль
                    </ButtonLoader>
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        )}
        {forgetPassword && (
          <Formik
            initialValues={{ password: null }}
            validationSchema={yup.object().shape({
              password: yup.string().required('Необходим').nullable(),
            })}
            onSubmit={onLogin}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              submitCount,
            }) => (
              <Form>
                <Row>
                  <StyledField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    error={
                      errors.password && submitCount > 0
                        ? errors.password
                        : undefined
                    }
                    placeholder="Введите пароль из письма"
                  />
                </Row>
                <Row style={{ textAlign: 'center' }}>
                  <Timer
                    onClick={() => {
                      dispatch(forgotPasswordRequest(email));
                    }}
                  />
                </Row>

                <Row>
                  <Button
                    disabled={isFetching}
                    htmlType="submit"
                    color="yellow"
                  >
                    <ButtonLoader isLoading={isFetching}>
                      Войти в личный кабинет
                    </ButtonLoader>
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        )}
        <BottomAuthOrTinkoffPart onSignUp={() => history.push('/sign-up')} />
      </Container>
    </Wrapper>
  );
};

export default ForgetPassword;
