import React from 'react';
import styled from 'styled-components';
import { Button } from '@components/ui';
import { LastBlockProps } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AgentPayModal } from '@components/common/Modals';

import { Helper } from '@utils';
import { getDetailOrder } from '@modules/orders/selectors';
import {
  issueTicketsRequest,
  issueOrderPositionRequest,
} from '@modules/orders';
import { getAgentOrderBillRequest } from '@modules/booking';
import { getAquiringCommission } from '@modules/agents/agent-finance/duck';
import { ApplicationState } from '@modules/index';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    font-size: 16px;
    line-height: 20px;
    width: 220px;
  }

  @media (max-width: 767px) {
    margin-top: 35px;
    display: block;
    & > button {
      margin-bottom: 10px;
      font-size: 14px;
      padding: 8px 16px;
      width: 100%;
    }
  }
`;
const LeftSide = styled.div`
  display: flex;

  & > button {
    font-size: 16px;
    line-height: 20px;
    margin-right: 40px;
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

const PaymentButton = styled(Button).attrs({ type: 'primary' })`
  width: 220px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const LastBlock: React.FC<LastBlockProps> = ({ onBuy, onCancel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);

  const { availableActions, invoice, amountExtraPayment, orderNumber, id } =
    useSelector(getDetailOrder);
  const agentDoPaymentProps = useSelector(
    (x: ApplicationState) =>
      x.orders.orderDetail.data.availableCreditPaymentMethods
  );
  const user = useSelector((x: ApplicationState) => x.user);

  const [isLoading, setLoading] = React.useState(false);
  const [agentModal, setAgentModal] = React.useState(false);

  const totalAmount = useSelector(
    (x: ApplicationState) => x.orders.orderDetail.activeCalculations.totalAmount
  );

  const extraPayment = totalAmount > 0;

  const canPay =
    availableActions.includes('PAYMENT') ||
    availableActions.includes('EXTRAPAYMENT');

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (agentDoPaymentProps) {
      dispatch(getAquiringCommission({ AgentId: user?.data?.agentId }));

      setAgentModal(true);
    } else {
      history.push(`/redirect-payment?orderId=${orderNumber}`);
    }
  };

  return (
    <Wrapper>
      {agentDoPaymentProps && (
        <AgentPayModal
          open={agentModal}
          {...agentDoPaymentProps}
          onClose={() => setAgentModal(false)}
          setAgentModal={setAgentModal}
          onPay={() => {
            setAgentModal(false);
            history.push(`/redirect-payment?orderId=${orderNumber}`);
          }}
          onExtract={(byCredit) => {
            dispatch(
              issueTicketsRequest({
                orderId: id,
                typePaid: byCredit ? 'credit' : 'bill',
                userId: user?.data?.agentId,
              })
            );
            setAgentModal(false);
          }}
          onCreateInvoice={(type) => {
            setAgentModal(false);
            dispatch(getAgentOrderBillRequest({ type: type, orderId: id }));
          }}
        />
      )}
      <LeftSide />
      {availableActions.includes('PAYMENT') &&
        !extraPayment &&
        order?.data?.salesChannel !== 'Собственная розница' && (
          <PaymentButton
            isLoading={isLoading}
            color="yellow"
            onClick={handleClick}
          >
            {user?.data?.agentId ? 'Оплатить заказ' : 'Оплатить заказ'}
          </PaymentButton>
        )}
      {availableActions.includes('PAYMENT') &&
        !extraPayment &&
        order?.data?.salesChannel === 'Собственная розница' && (
          <PaymentButton
            isLoading={isLoading}
            color="yellow"
            onClick={() =>
              dispatch(
                issueOrderPositionRequest({
                  orderNumber: order?.data.orderNumber,
                  orderPositionId: order?.data?.orderPositions[0].id,
                })
              )
            }
          >
            {'Выписать заказ'}
          </PaymentButton>
        )}
      {extraPayment && (
        <PaymentButton
          isLoading={isLoading}
          color="yellow"
          onClick={handleClick}
        >
          Доплатить {Helper.formatPrice(totalAmount)}
        </PaymentButton>
      )}
    </Wrapper>
  );
};

export default LastBlock;
