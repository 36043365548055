import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { searchFormDates } from '@modules/main';
import Breadcrumbs from '@components/train/TrainSearch/Breadcrumbs';
import { TicketListSkeleton } from '@components/flightsSearch';
import FilterBuses from '@components/bus/BusSearch/FilterListBuses';
import { TRAIN_SEARCH_STEPS, trainPurify } from '@modules/trainTickets';
import { changeSearchPanelType, getUiState } from '@modules/ui';
import { useCurrentTrainFilter } from '@modules/trainTickets/utils';
import Booking from '@components/bus/Booking';
import { TicketsList } from '@components/bus/BusSearch/TicketList';
import {
  RailwayFullscreenLoader,
  RailwayLoader,
} from '@components/common/loader';
import { ApplicationState } from '@modules/index';
import { busSearchRequest } from '@modules/busTickets/duck';
import { Bus, BUS_SEARCH_STEPS } from '@modules/busTickets';
import { busGetOrderDetails } from '@modules/busBooking/duck';
import { OrderDetailsPreparedData } from '@modules/busBooking/dto/BookBusTicketDto';
import BookingSecondStep from '@components/bus/Booking/BookingSecondStep';

export interface BreadCrumbItem {
  uiStatus: 'enabled' | 'selected' | 'disabled';
  text: string;

  onClick?(): void;
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;

  @media (max-width: 1169px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  @media screen and (max-width: 1142px) {
    margin-top: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled.div`
  width: 100%;
`;

const BusSearch: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isBusSearch = useSelector(getUiState).searchPanelType === 'bus';
  const dates = useSelector(searchFormDates);
  const [selectedBuses, setSelectedBuses] = useState<Bus[] | null>(null);

  const busTickets = useSelector((state: ApplicationState) => state.busTickets);
  const ticketsList = busTickets.list;
  const filteredTicketsList = busTickets.filteredList;
  const busSearch = busTickets.busSearch;

  const activeFilter = useCurrentTrainFilter();

  const [chosenTrainPanelIsOpen, setChosenTrainPanelIsOpen] =
    useState<boolean>(false);
  const [chosenBackwardTrainPanelIsOpen, setChosenBackwardTrainPanelIsOpen] =
    useState<boolean>(false);

  let filterCounter = 0;
  if (activeFilter) {
    Object.values(activeFilter?.isDefault).forEach((value) => {
      return !value ? (filterCounter = filterCounter + 1) : filterCounter;
    });
  }
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch();
  const location = useLocation();

  const handleSelectBus = (bus: Bus): void => {
    const busses = [...(selectedBuses || []), bus];

    // if (busses.length !== 2) {
    //   setSelectedBuses(busses);
    //   return;
    // }

    const passengersCount = filteredTicketsList.passengersInfo.reduce(
      (acc: number, passenger: any) => acc + passenger.count,
      0,
    );

    const placeDataPrepare: OrderDetailsPreparedData = {
      forward: {
        from: busses[0].fromStation.guid,
        to: busses[0].toStation.guid,
        provider: busses[0].provider,
        departureDate: busses[0].departureDateTime,
        raceId: busses[0].raceId,
      },
      backward: null,
    };

    if (busses?.length > 1) {
      placeDataPrepare.backward = {
        from: busses[1].fromStation.guid,
        to: busses[1].toStation.guid,
        provider: busses[1].provider,
        departureDate: busses[1].departureDateTime,
        raceId: busses[1].raceId,
      };
    }

    setSelectedBuses(
      busses.map((bus) => ({
        ...bus,
        ticketsCount: passengersCount,
        passengersInfo: filteredTicketsList?.passengersInfo,
      })),
    );
    dispatch(busGetOrderDetails(placeDataPrepare));
  };

  // const busesForwardHasRoundTripTariff = trainsHasRoundTripTariff(
  //   ticketsList?.busesPerRoutes[0]?.buses
  // );
  // if (
  //   ticketsList?.busesPerRoutes.length > 1 &&
  //   ticketsList.busesPerRoutes[1] &&
  //   ticketsList.busesPerRoutes[1].buses?.length > 0
  // ) {
  //   busesBackwardHasRoundTripTariff = trainsHasRoundTripTariff(
  //     ticketsList?.busesPerRoutes[1]?.buses
  //   );
  // }

  // const hasRoundTrip =
  // busesForwardHasRoundTripTariff && trainsBackwardHasRoundTripTariff;

  React.useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(location.search));
    const From = params.get('From');
    const To = params.get('To');
    const DepartureDate = params.get('DepartureDate');
    const DepartureDateBack = params.get('DepartureDateBack');
    const Adults = parseInt(params.get('Adults') || '0');
    const Children = parseInt(params.get('Children') || '0');
    const Infants = parseInt(params.get('Infants') || '0');
    if (From && To && DepartureDate) {
      dispatch(
        busSearchRequest({
          Adults,
          Children,
          DepartureDate,
          DepartureDateBack,
          From,
          Infants,
          To,
        }),
      );
    }
  }, [location]);

  React.useEffect(
    () => () => {
      dispatch(trainPurify());
    },
    [],
  );

  useEffect(() => {
    if (!isBusSearch) {
      dispatch(changeSearchPanelType('bus'));
    }
  }, []);

  const isStepForward =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.TICKET_FORWARD;
  const isStepBackward =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.TICKET_BACKWARD;
  const isStepBooking =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.BOOKING;
  const isSecondStepBooking =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.BOOKING_SECOND_STEP;

  const needFilter = isStepForward || isStepBackward;
  const loadingTitle = useMemo(() => {
    switch (busSearch.currentSearchStep) {
      case TRAIN_SEARCH_STEPS.TICKET_FORWARD:
        return 'Подбираем билеты';
      case TRAIN_SEARCH_STEPS.TICKET_BACKWARD:
        return 'Подбираем обратные билеты';
      case TRAIN_SEARCH_STEPS.BOOKING:
        return 'Подготавливаем бронирование';
      default:
        return 'Загрузка...';
    }
  }, [busSearch.currentSearchStep]);

  const breadcrumbItems: BreadCrumbItem[] = [];

  // Логика для добавления крошек в зависимости от текущего шага и наличия даты в `dates.to`
  if (isStepBooking || isSecondStepBooking) {
    // Шаги Тариф и пассажиры, Услуги и оплата
    breadcrumbItems.push({
      uiStatus: isSecondStepBooking ? 'disabled' : 'enabled',
      text: 'Тариф и пассажиры',
      onClick: () => {
        console.log('tariff and passengers');
      },
    });
    breadcrumbItems.push({
      uiStatus: isSecondStepBooking ? 'enabled' : 'disabled',
      text: 'Услуги и оплата',
      onClick: () => {
        console.log('services and payment');
      },
    });
  } else {
    // Шаги Автобус туда, Автобус обратно, бронирование
    breadcrumbItems.push({
      uiStatus: isStepForward ? 'selected' : 'enabled',
      text: isMobile ? 'Автобус туда' : 'Выбор автобуса туда',
      onClick: () => {
        setChosenTrainPanelIsOpen(true);
      },
    });

    if (dates.to) {
      breadcrumbItems.push({
        uiStatus: isStepBackward ? 'selected' : 'enabled',
        text: isMobile ? 'Автобус обратно' : 'Выбор автобуса обратно',
        onClick: () => {
          setChosenBackwardTrainPanelIsOpen(true);
        },
      });
    }

    breadcrumbItems.push({
      uiStatus: 'disabled',
      text: 'Бронирование',
      onClick: () => {
        console.log('booking');
      },
    });
  }

  return (
    <Container>
      <Body>
        {/* {busSearch.status === 'failure' && (
          <RequestError step={busSearch.currentSearchStep} type={'train'} />
        )} */}
        {/* {busSearch.status !== 'failure' && ( */}
        <React.Fragment>
          <Breadcrumbs items={breadcrumbItems} />
          <Content ref={ref}>
            {busSearch.status === 'initial' && <TicketListSkeleton />}
            {busSearch.status === 'loading' && (
              <RailwayLoader title={loadingTitle} />
            )}
            {busSearch.status === 'tryToCreateOrder' && (
              <RailwayFullscreenLoader title={'Создаём бронирование'} />
            )}
            {busSearch.status === 'success' && (
              <React.Fragment>
                {!isStepBooking && <FilterBuses />}
                <LeftSide>
                  {isStepForward && (
                    <TicketsList
                      buses={filteredTicketsList?.busesPerRoutes[0]?.buses}
                      searchStep={'forward'}
                      setSelectedBus={handleSelectBus}
                    />
                  )}
                  {isStepBackward && (
                    <TicketsList
                      buses={filteredTicketsList?.busesPerRoutes[1]?.buses}
                      searchStep={'backward'}
                      setSelectedBus={handleSelectBus}
                    />
                  )}
                  {isStepBooking && (
                    <Booking selectedBuses={selectedBuses as Bus[]} />
                  )}
                  {isSecondStepBooking && (
                    <BookingSecondStep selectedBuses={selectedBuses as Bus[]} />
                  )}
                </LeftSide>
              </React.Fragment>
            )}
          </Content>
        </React.Fragment>
      </Body>
    </Container>
  );
};

export default BusSearch;
