import React from 'react';
import styled from 'styled-components';
import Filters from '../../FilterList';

const StyledFilters = styled(Filters)`
  padding: 20px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  & .category-container {
    padding: 20px;
  }
`;

const Body: React.FC = () => {
  return <StyledFilters />;
};

export default Body;
//
