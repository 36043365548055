import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TrainFilterPopup from '@components/train/TrainSearch/Filter/TrainFilterPopup';
import {
  closeTrainSearchFilter,
  trainTicketsSearchSelector,
} from '@modules/trainTickets';

const TrainMobileTemplate = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(trainTicketsSearchSelector).filterIsOpen;

  const closeFilter = () => {
    dispatch(closeTrainSearchFilter());
  };

  return (
    <>
      <TrainFilterPopup onClose={closeFilter} open={isOpen} />
    </>
  );
};

export default TrainMobileTemplate;
