import { InsuranceEntity, InsuranceProductEntity } from '@modules/orders';
import alphastrahPath from '@assets/media/insurances/alphastrah.png';
import insurionPath from '@assets/media/insurances/insurion.png';
import _ from 'lodash';

export function getTotalSum(args: InsuranceEntity[]) {
  return _(args)
    .filter(
      (x) =>
        x.paymentStatus.code === 'Paid' ||
        x.paymentStatus.code === 'PaidByCredit'
    )
    .reduce(
      (acc, x) =>
        acc +
        _(x.products)
          .filter(
            (x) =>
              x.paymentStatus.code === 'Paid' ||
              x.paymentStatus.code === 'PaidByCredit'
          )
          .reduce(
            (acc, x) => acc + x.policies.reduce((acc, x) => acc + x.price, 0),
            0
          ),
      0
    );
}

export function getProductTotalSum(args: InsuranceProductEntity) {
  return args.policies.reduce((acc, x) => acc + x.price, 0);
}

function getCompany(productType: string) {
  switch (productType) {
    case 'Задержка рейса':
    case 'Гарантия хорошей погоды':
    case 'Гарантия хорошей погоды для туристов':
    case 'Страховка от задержки рейса':
      return { name: 'Insurion', logo: insurionPath };
    default:
      return { name: 'АльфаСтрахование', logo: alphastrahPath };
  }
}

// export function getCompany(productType: string) {
//   switch (productType) {
//     case 'Задержка рейса':
//     case 'Гарантия хорошей погоды':
//     case 'Гарантия хорошей погоды для туристов':
//     case 'Страховка от задержки рейса':
//       return insurionPath;
//     default:
//       return alphastrahPath;
//   }
// }

export function getProductCompanies(items: InsuranceEntity[]) {
  return _(items)
    .map((x) => x.products.map((x) => getCompany(x.name || '')))
    .flatten()
    .uniqBy('name')
    .value();
  // return _.uniq(items.map(x => ))
}
