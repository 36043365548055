import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import oldIconPath from '@assets/media/shared/old-segment-plane.svg';
import newIconPath from '@assets/media/shared/new-segment-plane.svg';
import moment from 'moment';
import { useRefund } from '../useRefund';
import womanPasIcon from '@assets/media/womanPasIcon.svg';
import manPasIcon from '@assets/media/manPasIcon.svg';
import { getAppealDetail } from '@modules/appeals';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  padding: 24px 24px 20px 24px;
  background: #ecffe8;
  color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid #21a038;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 12px;
`;

const SubTitle = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const Segment = styled.div`
  padding: 15px 0;
  display: flex;
  align-items: flex-start;

  & > :first-child {
    margin-right: 11px;
  }
`;

const SegmentHead = styled.div`
  width: 100%;

  & > :first-child {
    display: block;
    margin-bottom: 8px;
  }
`;

const GridBlock = styled.div`
  display: grid;
  grid-template-columns: 253px 1fr 85px;
`;

const SegmentBigText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
`;

// const PositionName = styled(SegmentBigText)`
//   display: block;
//   margin-bottom: 15px;
// `;

const SegmentText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const OldSegmentsWrapper = styled.div`
  background: #f2f2f2;
  border: 1px solid #dcdcdc;
  border-color: #dcdcdc;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;

  & > div {
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    border-bottom: none;
  }

  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

const NewSegmentsWrapper = styled(OldSegmentsWrapper)`
  background: #edf1fe;
  border-color: #4872f2;

  & > :first-child {
    color: #4872f2;
  }
`;

const SegmentsWrapperList = styled.div`
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Img = styled.img`
  width: 34px;
  height: 34px;
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 22px;
  margin-left: 5px;
  width: 20px;
  vertical-align: bottom;

  & > svg {
    position: absolute;
  }
`;

const NewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;

  @media (max-width: 789px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 790px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const WomanPasIcon = styled.img.attrs({ src: womanPasIcon })`
  width: 34px;
  height: 34px;
`;

const ManPasIcon = styled.img.attrs({ src: manPasIcon })`
  width: 34px;
  height: 34px;
`;

const WellIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#21A038" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1 7.0304L9.17298 17.8232L2.5005 12.0004L4.28326 10.0298L9.14876 14.2758L18.2916 5.24524L20.1 7.0304Z"
      fill="#ECFFE8"
    />
  </svg>
);

export default function Notification() {
  const _context = useRefund();
  const appeal = useSelector(getAppealDetail);
  const toTime = (date: string): string => moment(date).format('DD.MM.YYYY');

  return (
    <Wrapper>
      <Title>
        {_context.subjectCode === 'ConsentToChangePassengers'
          ? 'Данные пассажиров изменены'
          : 'Полетные данные изменены'}

        <IconContainer>
          <WellIcon />
        </IconContainer>
      </Title>
      <SubTitle>
        {_context.subjectCode === 'ConsentToChangePassengers'
          ? 'Вы подтвердили изменение пассажиров по вашему перелету'
          : 'Вы подтвердили изменение полетных данных по вашему перелету'}
      </SubTitle>
      <SegmentsWrapperList>
        <OldSegmentsWrapper>
          <SegmentBigText>Старые данные:</SegmentBigText>
          {_context.subjectCode !== 'ConsentToChangePassengers' && (
            <>
              {(_context.segmentEvents || []).map((x, key) => {
                const data = transformData(x, 'oldValue');

                return (
                  <Segment key={key}>
                    <Img src={oldIconPath} />
                    <SegmentHead>
                      <SegmentBigText>
                        {x.departureCity.name} - {x.arrivalCity.name} (
                        {x.departureCity.code} - {x.arrivalCity.code})
                      </SegmentBigText>
                      <GridBlock>
                        <SegmentText>
                          {data.departureDate} - {data.arrivalDate}
                        </SegmentText>
                        <SegmentText> {data.company}</SegmentText>
                        <SegmentText>{data.flightNumber}</SegmentText>
                      </GridBlock>
                    </SegmentHead>
                  </Segment>
                );
              })}
            </>
          )}
          {_context.subjectCode === 'ConsentToChangePassengers' && (
            <>
              {(appeal.passengerEvents || []).map((x: any, key: any) => {
                return (
                  <Segment key={key}>
                    {x.fields.find((y: any) => y.description === 'Пол')
                      .oldValue === 'Male' ? (
                      <ManPasIcon />
                    ) : (
                      <WomanPasIcon />
                    )}
                    <SegmentHead>
                      <SegmentBigText>
                        {x.passengerDataFields.map((pasDataField: any) =>
                          pasDataField.description === 'Номер билета'
                            ? 'билет ' + pasDataField.oldValue + ' '
                            : pasDataField.oldValue + ' '
                        )}
                      </SegmentBigText>
                      <NewGrid>
                        {x.fields.map((field: any, i: number) => (
                          <>
                            <SegmentText>
                              <span
                                style={{
                                  color: '#737373',
                                }}
                              >
                                {field.description}:&nbsp;
                              </span>
                              {field.type === 'datetime'
                                ? toTime(field.oldValue)
                                : field.oldValue}
                              &nbsp;
                            </SegmentText>
                          </>
                        ))}
                      </NewGrid>
                    </SegmentHead>
                  </Segment>
                );
              })}
            </>
          )}
        </OldSegmentsWrapper>
        <NewSegmentsWrapper>
          <SegmentBigText>Новые данные:</SegmentBigText>
          {_context.subjectCode !== 'ConsentToChangePassengers' && (
            <>
              {' '}
              {(_context.segmentEvents || []).map((x, key) => {
                const data = transformData(x, 'newValue');

                return (
                  <Segment key={key}>
                    <Img src={newIconPath} />
                    <SegmentHead>
                      <SegmentBigText>
                        {x.departureCity.name} - {x.arrivalCity.name} (
                        {x.departureCity.code} - {x.arrivalCity.code})
                      </SegmentBigText>
                      <GridBlock>
                        <SegmentText>
                          {data.departureDate} - {data.arrivalDate}
                        </SegmentText>
                        <SegmentText> {data.company}</SegmentText>
                        <SegmentText>{data.flightNumber}</SegmentText>
                      </GridBlock>
                    </SegmentHead>
                  </Segment>
                );
              })}
            </>
          )}
          {_context.subjectCode === 'ConsentToChangePassengers' && (
            <>
              {' '}
              {(appeal.passengerEvents || []).map((x: any, key: number) => {
                const data = transformData(x, 'newValue');

                return (
                  <Segment key={key}>
                    {x?.fields?.find((y: any) => y.description === 'Пол')
                      .oldValue === 'Male' ? (
                      <ManPasIcon />
                    ) : (
                      <WomanPasIcon />
                    )}
                    <SegmentHead>
                      <SegmentBigText>
                        {x.passengerDataFields.map((pasDataField: any) =>
                          pasDataField.description === 'Номер билета'
                            ? 'билет ' + pasDataField.newValue + ' '
                            : pasDataField.newValue + ' '
                        )}
                      </SegmentBigText>
                      <NewGrid>
                        {x.fields.map((field: any, i: number) => (
                          <>
                            <SegmentText>
                              <span
                                style={{
                                  color: '#737373',
                                }}
                              >
                                {field.description}:&nbsp;
                              </span>
                              {field.type === 'datetime'
                                ? toTime(field.newValue)
                                : field.newValue}
                              &nbsp;
                            </SegmentText>
                          </>
                        ))}
                      </NewGrid>
                    </SegmentHead>
                  </Segment>
                );
              })}
            </>
          )}
        </NewSegmentsWrapper>
      </SegmentsWrapperList>
    </Wrapper>
  );
}

function transformData(data: any, valueType: 'newValue' | 'oldValue') {
  const departureDate = data.fields.find(
    (x: any) => x.description === 'Дата отправления'
  );
  const arrivalDate = data.fields.find(
    (x: any) => x.description === 'Дата прибытия'
  );
  const company = data.fields.find(
    (x: any) => x.description === 'Маркетинговый перевозчик'
  );
  const flightNumber = data.fields.find(
    (x: any) => x.description === 'Номер рейса'
  );
  return {
    departureDate: departureDate
      ? moment(departureDate[valueType]).format('DD MMMM HH:mm')
      : null,
    arrivalDate: arrivalDate
      ? moment(arrivalDate[valueType]).format('DD MMMM HH:mm')
      : null,
    company: company ? company[valueType] : null,
    flightNumber: flightNumber ? flightNumber[valueType] : null,
  };
}
