import styled from 'styled-components';
import * as Styled from './Default.style';
import { Text } from '@components/ui';

export const Container = styled.div``;

export const Title = styled(Styled.Title)`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 30px;
`;

export const Description = styled(Text)`
  display: block;
  font-size: 16px;
  margin-bottom: 30px;
  max-width: 570px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;
