import React from 'react';

import DesktopTrainTemplate from './Desktop/TemplateTrain';
import MobileTrainTemplate from './Mobile/TrainMobileTemplate';
import { useMediaQuery } from 'react-responsive';

const FilterTrain = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return isMobile ? <MobileTrainTemplate /> : <DesktopTrainTemplate />;
};

export default FilterTrain;
