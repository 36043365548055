import React from 'react';
import * as Styled from './styles/Refund.style';
import { Select, SelectWithCheckboxes } from '@components/ui/form';
import { Formik } from 'formik';
import { RefundFormData } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { addRefundReason, getRefundAmountState } from '@modules/appeals';
import { RefundHeader, RefundLoader } from './static/Refund';
import useCreateFormRefund from './model/useCreateFormRefund';

export enum RefundTypeCode {
  AVIA = 'Avia',
  AVIA_EMDS = 'AviaEmds',
}

const CreateFormRefund: React.FC = () => {
  const { onSubmit, state, innerDispatch, initialReason, refundReasons } =
    useCreateFormRefund();
  const refundAmount = useSelector(getRefundAmountState);
  const { refundTickets, refundEmds } = refundAmount.data;
  const initialRefundType =
    (refundTickets?.length && RefundTypeCode.AVIA) ||
    (refundEmds?.length && RefundTypeCode.AVIA_EMDS) ||
    undefined;
  const dispatch = useDispatch();
  const isLoading = refundAmount.isLoading;

  const initialValues: RefundFormData = {
    refundReason: initialReason,
    refundType: initialRefundType,
    tickets: [],
    services: [],
  };

  return (
    <div style={{ width: '100%' }}>
      <RefundHeader isTrainOrder={false} />
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          const refundedItems = [...state.tickets, ...state.services];
          const isDisableSubmit =
            isLoading ||
            isSubmitting ||
            !refundedItems.length ||
            !refundedItems.find((item: any) => item.checked);

          return (
            <Styled.Form>
              {isLoading ? (
                <RefundLoader />
              ) : (
                <>
                  <Styled.SelectWrapper>
                    <Select
                      isSearchable={false}
                      onChange={(data: any) => {
                        dispatch(addRefundReason(data));
                        innerDispatch({
                          type: `resetPositions`,
                        });
                        innerDispatch({
                          type: `setRefundType`,
                          payload: data,
                        });
                      }}
                      defaultValue={initialReason}
                      name="reason"
                      label="Выберите причину возврата"
                      options={refundReasons}
                    />
                  </Styled.SelectWrapper>
                  {state.refundType === RefundTypeCode.AVIA && (
                    <Styled.AncillaryServicesBlock>
                      <SelectWithCheckboxes
                        label={'Выберите билет'}
                        items={state.tickets}
                        onSelect={(data, action) => {
                          switch (action) {
                            case 'select':
                              innerDispatch({
                                type: `addTicket`,
                                payload: data.value,
                              });
                              break;
                            case 'remove':
                              innerDispatch({
                                type: `removeTicket`,
                                payload: data.value,
                              });
                              break;
                          }
                        }}
                        onSelectAll={() => {
                          innerDispatch({
                            type: `addAllTicket`,
                          });
                        }}
                        selectAllText={'Выбрать все билеты'}
                      />
                    </Styled.AncillaryServicesBlock>
                  )}
                  {state.refundType === RefundTypeCode.AVIA_EMDS && (
                    <Styled.AncillaryServicesBlock>
                      <SelectWithCheckboxes
                        label={'Выберите услугу'}
                        items={state.services}
                        onSelect={(data, action) => {
                          switch (action) {
                            case 'select':
                              innerDispatch({
                                type: `addService`,
                                payload: data.value,
                              });
                              break;
                            case 'remove':
                              innerDispatch({
                                type: `removeService`,
                                payload: data.value,
                              });
                              break;
                          }
                        }}
                        onSelectAll={() => {
                          innerDispatch({
                            type: `addAllService`,
                          });
                        }}
                        selectAllText={'Выбрать все услуги'}
                      />
                    </Styled.AncillaryServicesBlock>
                  )}
                </>
              )}

              <Styled.LastBlock>
                <div></div>
                <Styled.Submit disabled={isDisableSubmit}>
                  Получить расчет на возврат
                </Styled.Submit>
              </Styled.LastBlock>
            </Styled.Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateFormRefund;
