import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui/Typography';
import { TariffsList } from '@components/train/Tariffs/TariffsList';
import { Checkbox } from '@components/ui';
import { Helper } from '@utils';
import Tippy from '@tippyjs/react';
import { ReactComponent as InfoIcon } from '@assets/media/info-icon.svg';
import { TrainTariff } from '@components/train/Panels/TariffsPanel';

interface Props {
  hasNonRefundableTariff: boolean | undefined;
  onlyNonRefundableTariff: boolean | undefined;
  tariffsList: TrainTariff[];
  diffTariffPrice: number;
  handleChangeTariff: (tariffType: string) => void;
  handleChangeNonRefundableTariff: () => void;
  nonRefundIsChecked: boolean;
  routeTitle: string;
}

const TariffContainer = styled.div`
  margin: 35px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Item = styled.div`
  //margin-top: 35px;
  //margin-bottom: 20px;
`;

const NonRefundContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BlueText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: rgba(72, 114, 242, 1);
  font-weight: 600;
`;

export const TariffsContainerComponent = (props: Props) => {
  const {
    hasNonRefundableTariff,
    tariffsList,
    diffTariffPrice,
    nonRefundIsChecked,
    routeTitle,
    onlyNonRefundableTariff,
  } = props;

  // если при монтировании onlyNonRefundableTariff, то надо программно нажать чекбокс
  useEffect(() => {
    if (onlyNonRefundableTariff) {
      props.handleChangeNonRefundableTariff();
    }
  }, []);

  return (
    <TariffContainer>
      {(hasNonRefundableTariff || tariffsList.length > 0) && (
        <Item>
          <Text fontWeight={'600'}>
            Выбор тарифа:{' '}
            <Text fontWeight={'600'} color={'blue'}>
              {routeTitle}
            </Text>
          </Text>
          {tariffsList.length > 0 && (
            <TariffsList
              handleChangeTariff={props.handleChangeTariff}
              tariffs={tariffsList}
            />
          )}
        </Item>
      )}
      {hasNonRefundableTariff && (
        <NonRefundContainer>
          <Checkbox
            disabled={onlyNonRefundableTariff}
            onChange={props.handleChangeNonRefundableTariff}
            type={'squar'}
            label={'Невозвратный билет'}
            checked={onlyNonRefundableTariff || nonRefundIsChecked}
          />
          {diffTariffPrice > 0 && (
            <>
              <Text fontWeight={'600'}> — дешевле на</Text>
              <BlueText>{Helper.formatPrice(diffTariffPrice)}</BlueText>
              <Tippy
                theme={'light'}
                content={
                  'При возврате электронных билетов, оформленных по невозвратному тарифу, возвращается стоимость сервисных услуг или белья с удержанием суммы сбора за оформление возврата денег. В случае, если сумма выплаты меньше установленного сбора, сбор за операцию оформления возврата не взимается.'
                }
              >
                <InfoIcon />
              </Tippy>
            </>
          )}
          {onlyNonRefundableTariff && (
            <Tippy
              theme={'light'}
              content={
                'Выбранные билет(ы) можно купить только по невозвратному тарифу'
              }
            >
              <InfoIcon />
            </Tippy>
          )}
        </NonRefundContainer>
      )}
    </TariffContainer>
  );
};
