import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useLogSessionId = (): string => {
  const [logSessionId, setLogSessionId] = useState<string>('');

  useEffect(() => {
    let logSessionId = sessionStorage.getItem('logSessionId');
    if (!logSessionId) {
      logSessionId = uuidv4();
      sessionStorage.setItem('logSessionId', logSessionId);
    }
    setLogSessionId(logSessionId);
  }, []);

  return logSessionId;
};

export default useLogSessionId;