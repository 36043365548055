import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Field, Checkbox, PasswordField } from '@components/ui/form';
import Text, { Header } from '@components/ui/Typography';
import { Button } from '@components/ui';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { getBookingTicketState, signIn, setAuthPopup } from '@modules/booking';
import { getUserStatusState } from '@modules/user';
import Head from '@components/tui-page/Header';
import { useMediaQuery } from 'react-responsive';
import { AUTH_MODAL_STATUSES, openAuthModal } from '@modules/ui';
import BottomAuthOrTinkoffPart from '@components/authModals/SignIn/BottomAuthOrTinkoffPart';

//TODO сделать переиспользуемый компонент

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 8px;
    width: auto;
    padding: 0;
  }

  @media (max-width: 767px) {
    &-overlay {
      // top: 39px !important;
    }

    &-content {
      height: 100vh;
      width: 100%;
      border: none;
      border-radius: 0;
      overflow-x: scroll;
    }
  }
`;

const SocialRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledHeader = styled(Header)`
  text-align: center;
  margin-bottom: 32px;
  font-weight: 600;
  @media (max-width: 767px) {
    margin-top: 60px;
  }
`;

const Container = styled(InfoBlock)`
  box-shadow: none;
  border: none;
  max-width: 468px;
  align-self: center;
  background: #ffffff;
  @media (max-width: 767px) {
    margin-bottom: 78px;
  }
  @media (min-width: 767px) {
    width: 468px;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledPassword = styled(PasswordField)`
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 32px;
  display: grid;
`;

const ForgetPasswordRow = styled.div`
  text-align: right;
  margin-bottom: 4px;
`;

const PasswordRow = styled(Row)`
  margin-bottom: 15px;
`;

const Link = styled(Text)`
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }
`;

const SignUp: React.FC = () => {
  const dispatch = useDispatch();
  const { authPopup } = useSelector(getBookingTicketState);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { error } = useSelector(getUserStatusState);
  const [initialValues, setFields] = React.useState({
    login: '',
    password: '',
    remember: false,
  });

  React.useEffect(() => {
    if (authPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [authPopup]);

  const onSubmit = (data: typeof initialValues) => {
    setFields(data);
    dispatch(signIn(data));
  };

  return (
    <StyledPopup
      open={authPopup}
      onClose={() => {
        dispatch(setAuthPopup(false));
      }}
    >
      {isMobile && <Head staticPosition closeButton />}
      <Container>
        <StyledHeader>Авторизация</StyledHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            login: yup.string().required('Заполните'),
            password: yup.string().required('Заполните'),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            submitCount,
            values,
          }) => (
            <Form>
              <Row>
                <StyledField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="login"
                  value={values.login}
                  error={
                    errors.login && submitCount > 0 ? errors.login : undefined
                  }
                  placeholder="Email"
                />
              </Row>
              <PasswordRow>
                <StyledPassword
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                  value={values.password}
                  error={
                    errors.password && submitCount > 0
                      ? errors.password
                      : undefined
                  }
                  placeholder="Пароль"
                />
              </PasswordRow>
              {error &&
                error.message !==
                  'Пользователь с таким Email уже существует' && (
                  <Text color="red">{error.message}</Text>
                )}
              <ForgetPasswordRow>
                <Link
                  onClick={() => {
                    dispatch(setAuthPopup(false));
                    dispatch(openAuthModal(AUTH_MODAL_STATUSES.RESET_PASSWORD));
                  }}
                >
                  Я забыл пароль
                </Link>
              </ForgetPasswordRow>
              <Row>
                <Checkbox
                  type="squar"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="remember"
                  label="Запомнить меня на этом устройстве"
                />
              </Row>
              <Row>
                <Button type="primary" htmlType="submit" color="yellow">
                  Войти
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
        <BottomAuthOrTinkoffPart
          onSignUp={() => {
            dispatch(openAuthModal(AUTH_MODAL_STATUSES.Registration));
            setAuthPopup(false);
          }}
        />
      </Container>
    </StyledPopup>
  );
};

export default SignUp;
