import styled from 'styled-components';
import { Text } from '@components/ui';

export * from './Default.style';

export const Description = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const Strong = styled.span`
  font-weight: 600;
`;

export const SelectWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 64px;
`;
