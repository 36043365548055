import React, { ReactElement, useState } from 'react';
import { Props, PlaceOption } from './interfaces';
import { Wrapper, Dropdown, Label, Option } from './styles';
import { ReactComponent as ArrowIcon } from '@assets/media/shared/arrow-up.svg';

const PlaceSelect = ({ label, options, onChange, id }: Props): ReactElement => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleDropdown = (): void => {
    setIsVisible(!isVisible);
  };

  const handleOnChange = (option: PlaceOption): void => {
    toggleDropdown();
    onChange(id, option);
  };

  const onClickOutsideListener = () => {
    setIsVisible(false);
    document.removeEventListener('click', onClickOutsideListener);
  };

  const renderOption = ({ text, value }: PlaceOption): ReactElement => (
    <Option key={value} onClick={() => handleOnChange({ text, value })}>
      {text}
    </Option>
  );

  return (
    <Wrapper>
      <Label isOpen={isVisible} onClick={toggleDropdown}>
        {label}
        <ArrowIcon />
      </Label>
      {isVisible && (
        <Dropdown
          onMouseLeave={() => {
            document.addEventListener('click', onClickOutsideListener);
          }}
        >
          {options.map(renderOption)}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default PlaceSelect;
