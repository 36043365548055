import { createAction, createReducer } from 'redux-act';
import {
  IState,
  AirCheckAvailabilityPayload,
  BookFlightPayload,
  SignInPayload,
} from '../types';
import { bookingPurify } from './shared';
import { checkAvailabilityNormalizer } from '../utils';

export const INITIAL_STATE: IState = {
  initialized: false,
  authPopup: false,
  loading: false,
  insuranceNotification: null,
  isMobile: false,
  chosenScreen: 1,
  bookingLoader: false,
} as any;

//Actions
export const selectFlightRequest = createAction<AirCheckAvailabilityPayload>(
  '@booking/selectFlightRequest',
);

export const setInitializeState = createAction<boolean>(
  '@booking/setInitializeState',
);
export const selectFlightSuccess = createAction<
  ReturnType<typeof checkAvailabilityNormalizer>
>('booking/selectFlightSuccess');
export const selectFlightFailure = createAction('booking/selectFlightFailure');

// ANCHOR экшен для добавления сервис(старховка и тд)
export const setAdditionalServices = createAction<{
  key: 'insurance' | 'return' | 'notification';
  value: boolean;
}>('@booking/setAdditionalServices');

export const setIsMobile = createAction<boolean>('booking/isMobilePage');
export const setAncillaryDetailsSegment = createAction<string>(
  'booking/setAncillaryDetailsSegment',
);
export const setChosenScreen = createAction<number>('booking/setChosenScreen');

export const setStatus = createAction<boolean>('booking/setStatus');

export const showInsuranceNotification = createAction(
  '@booking/showInsuranceNotification',
);
export const hideInsuranceNotification = createAction(
  '@booking/hideInsuranceNotification',
);

export const getAgentOrderBillRequest = createAction<{
  type: 'createBill' | 'credit';
  orderId?: string;
}>('order/getAgentOrderBillRequest');

export const getAgentOrderBillFail = createAction<boolean>(
  'order/getAgentOrderBillFail',
);

//ANCHOR экшен для запроса на бронирования текущего перелета
export const bookFlightRequest =
  createAction<BookFlightPayload>('@booking/create');

export const setAuthPopup = createAction<boolean>('@booking/signInPopup');
export const signIn = createAction<SignInPayload>('@booking/signIn');

export const bookingLoader = createAction<boolean>('@booking/bookingLoader');

//Reducer
const reducer = createReducer<IState>({}, INITIAL_STATE);

reducer.on(bookingPurify, () => INITIAL_STATE);

reducer.on(bookFlightRequest, (state, payload) => ({
  ...state,
  tempData: payload,
}));

reducer.on(setInitializeState, (state, payload) => ({
  ...state,
  initialized: payload,
}));

reducer.on(setStatus, (state, payload) => {
  return { ...state, loading: payload };
});

reducer.on(bookingLoader, (state, payload) => {
  return { ...state, bookingLoader: payload };
});

reducer.on(setStatus, (state, payload) => {
  return { ...state, loading: payload };
});

reducer.on(setAuthPopup, (state, payload) => {
  return { ...state, authPopup: payload };
});

reducer.on(showInsuranceNotification, (s) => {
  return {
    ...s,
    insuranceNotification: true,
  };
});
reducer.on(hideInsuranceNotification, (s) => ({
  ...s,
  insuranceNotification: false,
}));

reducer.on(setIsMobile, (s, payload) => ({
  ...s,
  isMobile: payload,
}));

reducer.on(setAncillaryDetailsSegment, (s, payload) => ({
  ...s,
  ancillaryDetailsSegment: payload,
}));

reducer.on(setChosenScreen, (s, payload) => ({
  ...s,
  chosenScreen: payload,
}));

reducer.on(selectFlightSuccess, (state, payload) => {
  return {
    ...state,
    flights: payload.flights,
    prices: payload.fares[0].prices,
    loading: false,
    id: payload.responseId,
    upt: '',
    frequentFlyerAirlines: (payload as any).frequentFlyerAirlines,
    flightSearchParameters: (payload as any).flightSearchParameters,
    priceChanged: payload.priceChanged,
    warnings: (payload as any).warnings,
    searchRequest: (payload as any).searchRequest,
    isInternationalFlight: payload.isInternationalFlight,
    isDomesticPassportFlight: payload.isDomesticPassportFlight,
  };
});

export default reducer;
