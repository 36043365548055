import {
  OrderDetailState,
  OrderRefundAmountPositionsType,
  OrdersListState,
  RefundState,
} from './types';
import {
  GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
  GetRefundAmountDTO,
} from '@modules/appeals';

export const DetailInitialState: OrderDetailState = {
  id: '',
  customerId: '',
  amountExtraPayment: 0,
  orderStatus: { code: '', description: '' },
  paymentStatus: { code: '', description: '' },
  orderNumber: 0,
  createdDate: '',
  departureDate: '',
  arrivalDate: '',
  cancellationTime: '',
  backOfficeId: 0,
  amount: 0,
  amountTickets: 0,
  annulatedAviaOrderPositions: [],
  totalDetalization: {
    total: 0,
    agentTotal: 0,
    positionList: [],
  },
  orderAmount: {
    description: '',
    detalization: [],
    priceType: 1,
    quantity: null,
    value: 0,
  },
  isTrainOrder: false,
  isBusOrder: false,
  documents: [],
  orderPositions: [],
  railwayOrderPositions: [],
  busOrderPositions: [],
  customer: {
    email: '',
    externalId: null,
    firstName: '',
    id: '',
    lastName: '',
    phone: '',
    agreementToMailing: false,
  },
  contactsInfo: {
    contactName: '',
    contactEmail: '',
    contactPhone: '',
  },
  insurances: [],
  availableActions: [],
  invoice: {
    items: [],
    total: 0,
  },
  notifications: [],
  additionalServices: [],
  supportTickets: [],
  salesChannelType: '',
  availableCreditPaymentMethods: null,
  acquiringCommissions: [],
  isLowcoster: false,
  salesChannel: '',
  availableShipmentAllowed: null,
};

export const OrdersListInitialState: OrdersListState = {
  customerId: '',
  pageCount: 0,
  orders: [],
  currPage: 1,
  isLoading: false,
};

export const OrderRefundAmountInitialState: RefundState = {
  data: {} as GetRefundAmountDTO,
  isLoading: false,
  error: false,
};
export const OrderRefundAmountPositions = [] as OrderRefundAmountPositionsType;

export const OrderRefunded = {
  isRefunded: false,
  data: [] as GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket[],
};

export const AMOUNT_COMMISSION_AGENT = 17;
export const AGENT_PRICE_TYPES = [7, 8, 9, 12, 16, AMOUNT_COMMISSION_AGENT];
