import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 14px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin-top: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
  height: 1px;
  width: 100%;
  background: #dcdcdc;
`;

export const Icon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  transform: ${({ reverse }) =>
    !reverse ? 'rotate (0deg)' : 'rotate(180deg)'};

  @media (max-width: 767px) {
    width: 24px;
    height: 24px;
  }
`;
