import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Button } from '@components/ui';

import {
  getCalculationByTicketNumberRequest,
  getSupportTicketCalculation,
  supportTicketCalculationPurify,
} from '@modules/appeals';
import { TuiLoader } from '@components/common/loader';

import FailureOrderIcon from '../../assets/images/FailureOrder';

import { useHistory, RouteComponentProps } from 'react-router-dom';
import { Helper, Hooks } from '@utils';

const Wrapper = styled.div``;

const Content = styled.div`
  padding: 28px 20px;
  height: calc(100vh - 55px);
  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  @media (min-width: 767px) {
    margin-bottom: 40px;
  }
`;

const Header = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3c3c;
  @media (min-width: 767px) {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
  }
`;

const FailureIcon = styled(FailureOrderIcon)`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  @media (min-width: 767px) {
    width: 46px;
    height: 46px;
    margin-left: 20px;
  }
`;

const Gray = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  @media (min-width: 767px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #737373;
  }
`;

const Black = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  font-weight: bold;
  @media (min-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
    color: #3c3c3c;
  }
`;

const List = styled.div`
  margin-bottom: 35px;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (min-width: 767px) {
    display: none;
  }
`;

const Row = styled.div`
  & > :first-child {
    margin-right: 5px;
  }
`;

const ReturnButton = styled(Button).attrs({
  type: 'primary',
  color: 'yellow',
})`
  width: 100%;
  @media (min-width: 767px) {
    width: auto;
    text-align: center;
  }
`;

const DesktopHeadWrapper = styled.div`
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 49px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const DesktopHead = styled.div`
  padding: 50px 0;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;

  & > :first-child {
    display: block;
    margin-bottom: 45px;
  }
`;

const DestopList = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  row-gap: 25px;
`;

const PaymentSuccess: React.FC<RouteComponentProps<{ number: string }>> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  Hooks.useBlockBodyScroll();
  const data = useSelector(getSupportTicketCalculation);

  React.useEffect(() => {
    dispatch(
      getCalculationByTicketNumberRequest(parseInt(match.params.number))
    );
    return () => {
      dispatch(supportTicketCalculationPurify());
    };
  }, []);

  const total = data ? data.total : 0;
  const supprotTicketId = data ? data.supportTicketNumber : 0;

  const navigateTo = () => history.push('/orders');

  return (
    <Wrapper>
      {!data && <TuiLoader />}
      <DesktopHeadWrapper>
        <DesktopHead>
          <Header>{Helper.formatPrice(total)}</Header>
          <DestopList>
            <Gray>Номер обращения</Gray>
            <Black>{supprotTicketId} </Black>
            <Gray>Описание</Gray>
            <Black>Не получилось оплатить</Black>
          </DestopList>
        </DesktopHead>
      </DesktopHeadWrapper>
      <Content>
        <Head>
          <Header>Не получилось оплатить</Header> <FailureIcon />
        </Head>
        <List>
          <Row>
            <Gray>Сумма доплаты:</Gray>
            <Black>{Helper.formatPrice(total)}</Black>
          </Row>
          <Row>
            <Gray>№ обращения:</Gray>
            <Black>{supprotTicketId} </Black>
          </Row>
        </List>
        <ReturnButton onClick={navigateTo}>Вернуться к заказам</ReturnButton>
      </Content>
    </Wrapper>
  );
};

export default PaymentSuccess;
