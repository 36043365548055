import styled from 'styled-components';
import { Text } from '@app/components/ui';

export const StrongText = styled(Text)<{ tariffType?: 'charter' | 'regular' }>`
  font-weight: 600;
  margin-top: 15px;
  font-size: ${({ tariffType }) =>
    tariffType === 'regular' ? '16px' : '14px'};
`;

export const TextContainer = styled.p`
  margin-bottom: 25px;
`;

export const TextContainerSmall = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
`;

export const MarkedList = styled.ul`
  padding-left: 24px;
  margin-bottom: 0px;
`;

export const MarkedListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 15px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;
