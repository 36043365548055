import React from 'react';
import styled from 'styled-components';
import ArrowIcon from '../../../../assets/images/left-right-arrow';

const Icon = styled(ArrowIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 4;
  left: 50%;
  top: 50%;
  transform: translate(-57%, -44%);
  & path,
  rect {
    pointer-events: none;
  }
  & path {
    transition: fill 0.2s ease-in-out;
    fill: ${({ theme: { colors } }) => colors.lightGray};
  }
  &.active {
    cursor: pointer;
    & path {
      fill: ${({ theme: { colors } }) => colors.main};
    }
  }

  @media (max-width: 767px) {
    transform: rotate(90deg);
  }
`;

const Arrow: React.FC<{ className?: string; onClick?: () => void }> = (
  props
) => <Icon {...props} />;

export default Arrow;
