import React from 'react';
import styled from 'styled-components';
import { BlockProps } from './types';
import { Header } from '@components/ui/Typography';

const Wrapper = styled.div``;
const Head = styled.div`
  margin-bottom: 40px;
`;

type BodyProps = { columns: number };
const Body = styled.div<BodyProps>`
  & > div {
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    column-gap: 15px;
    margin-bottom: 32px;

    @media (max-width: 1024px) {
      row-gap: 20px;
      grid-template-columns: 1fr;
      margin-bottom: 20px;
    }
    ${({ theme }) => `
  
      ${theme.max('767px')} {
        margin-bottom: 30px;
        grid-template-columns: 1fr;
      }
  `}
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Block: React.FC<BlockProps & React.PropsWithChildren> = ({
  label,
  columns,
  children,
  labelColor,
}) => {
  return (
    <Wrapper>
      <Head>
        {label && (
          <Header size="h3" color={labelColor}>
            {label}
          </Header>
        )}
      </Head>
      <Body columns={columns}>{children}</Body>
    </Wrapper>
  );
};

export default Block;
