import React from 'react';
import Text from '@components/ui/Typography';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
  isOpen: boolean;
}

interface ArrowProps {
  open?: boolean;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 60px 1fr;
  margin-top: 15px;
`;

const DetailButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${Text} {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const Arrow = styled.button<ArrowProps>`
  position: relative;
  outline: none;
  border: none;
  height: 19px;
  width: 18px;
  min-width: 18px;
  padding: 0;
  background: transparent;
  margin-left: 5px;
  pointer-events: none;
  transition: transform 0.2s;
  ${({ open }) => (open ? 'transform: rotate(180deg);' : '')}
  &:before,
  &:after {
    content: '';
    background: #4872f2;
    width: 10px;
    height: 1px;
    position: absolute;
    top: 50%;
  }
  &:before {
    transform: rotate(45deg) translateY(-50%);
    left: 0;
  }
  &:after {
    transform: rotate(-45deg) translateY(-50%);
    right: 0;
  }
`;

const DetailButtonWrapper: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <div />
      <div />
      <DetailButton onClick={props.onClick}>
        <Text>{props.isOpen ? 'Скрыть' : 'Открыть'} детали</Text>{' '}
        <Arrow open={props.isOpen} />
      </DetailButton>
    </Wrapper>
  );
};

export default DetailButtonWrapper;
