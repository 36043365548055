export const AGENT_LOADING_STATE = {
  billByOrderId: false,
  creditHistory: false,
  getBillByOrderOrTTE: false,
  getAgentReport: false,
  paymentHistory: false,
};

export const AGENT_USERS_LOADING_STATE = {
  createUserInSamo: false,
};

export const AGENT_LOADING_ERRORS = {
  billByOrderId: '',
  creditHistory: '',
  getBillByOrderOrTTE: '',
  getAgentReport: '',
  paymentHistory: '',
};
