import { createAction, createReducer } from 'redux-act';
import {
  TicketClass,
  Dates,
  CitySearchDTO,
  SetDestionationPayload,
  SetDestionationBusPayload,
} from './types';
import {
  simpleSearchFormInitialState,
  citySuggestionInitialState,
  simpleSearchFormInitialStateForTrain,
  simpleSearchFormBusInitialState,
} from './constants';
import { combineReducers } from 'redux';

// Cities list

// Actions

export const getCityList = createAction<string>('@@citySuggestion/getCityList');

export const getCityListCancel = createAction(
  '@@citySuggestion/getCityListCancel',
);

export const getCityListSuccess = createAction<CitySearchDTO>(
  '@@citySuggestion/getCityListSuccess',
);

export const clearCityList = createAction('@@citySuggestion/clearCityList');

export const initializeSimpleSearch = createAction('@@city/initialize');

//Reducer

export const citySuggestions = createReducer({}, citySuggestionInitialState);

citySuggestions.on(getCityListSuccess, (state, payload) => {
  const { data } = payload;
  return {
    ...state,
    list: {
      items: data,
      length: data.length,
    },
  };
});

citySuggestions.on(clearCityList, (state) => {
  return {
    ...state,
    list: {
      items: [],
      length: 0,
    },
  };
});

////////////////////////

/** Set quantity of passengers for ticket search form */
export const setPassengers = createAction<{
  adults?: number;
  children?: number;
  infants?: number;
  source: 'train' | 'avia' | 'bus';
}>('@@simpleSearch/setPassengers');

/** Set ticket class for search form */
export const setTicketClass = createAction<TicketClass>(
  '@@simpleSearch/setTicketClass',
);

/** Set date "from" and "to" for search from */
export const setDate = createAction<Dates>('@@simpleSearch/setDate');

/** Set return state */
export const setReturn = createAction<boolean>('@@simpleSearch/setReturn');

/** Set destination where from and where to*/
export const setDestination = createAction<SetDestionationPayload>(
  '@@simpleSearch/setDestination',
);

export const setDestinationBus = createAction<SetDestionationBusPayload>(
  '@@simpleSearch/setDestinationBus',
);

export const search = createAction<{ url: string; saleChannel?: string }>(
  '@@simpleSearch/search',
);

export const clearSimpleSearch = createAction('@@simpleSearch/clearSearch');
export const clearSimpleSearchTrain = createAction(
  '@@simpleSearch/clearSearchTrain',
);

export const updateSearchFormState = createAction<string>(
  '@@simpleSearch/updateState',
);

//Reducer

export const simpleSearchForm = createReducer({}, simpleSearchFormInitialState);
export const simpleSearchFormBus = createReducer(
  {},
  simpleSearchFormBusInitialState,
);

simpleSearchForm.on(clearSimpleSearch, () => simpleSearchFormInitialState);
simpleSearchForm.on(
  clearSimpleSearchTrain,
  () => simpleSearchFormInitialStateForTrain,
);

simpleSearchForm.on(setPassengers, (state, payload) => {
  let MAX = 9;
  const isAvia = payload.source === 'avia';
  const isTrain = payload.source === 'train';
  if (!isAvia) {
    MAX = 4;
  }
  const adults = payload.adults ?? state.passengers.adults.count;

  const children =
    payload.children !== undefined
      ? payload.children
      : state.passengers.children.count;

  let infants =
    payload.infants !== undefined
      ? payload.infants
      : state.passengers.infants.count;

  if (infants > adults) {
    infants = adults;
  }

  const childrenMin = 0;
  const infantsMin = 0;
  let infantsMax = null;
  let childrenMax = null;

  // взрослы может быть не больше, чем максимальное кол-во пассажиров с билетом за вычетом детей с билетами
  const adultsMax = MAX - children;
  // минимум взрослых - кол-во младенцев
  let adultsMin = infants || 1;
  if (isTrain) {
    adultsMin = 0;
  }

  if (isAvia) {
    childrenMax =
      MAX - (adults + infants) > adults * 2
        ? adults * 2
        : MAX - (adults + infants);
    infantsMax =
      MAX - (adults + children) > adults ? adults : MAX - (adults + children);
  } else {
    // детей может быть не больше, чем максимальное кол-во пассажиров за вычетом взрослых
    childrenMax = MAX - adults;
    // младенцев может быть не больше, чем взрослых
    infantsMax = adults;
  }

  return {
    ...state,
    passengers: {
      adults: {
        count: adults,
        max: adultsMax,
        min: adultsMin,
      },
      children: {
        count: children,
        max: childrenMax,
        min: childrenMin,
      },
      infants: {
        count: infants,
        max: infantsMax,
        min: infantsMin,
      },
    },
  };
});

simpleSearchForm.on(setTicketClass, (state, payload) => {
  return {
    ...state,
    ticketClass: payload,
  };
});

simpleSearchForm.on(setDate, (state, dates) => {
  return {
    ...state,
    dates,
  };
});

simpleSearchForm.on(setReturn, (state, returnTicket) => {
  return { ...state, returnTicket };
});

simpleSearchForm.on(setDestination, (state, payload) => {
  return { ...state, ...payload };
});

simpleSearchFormBus.on(setDestinationBus, (state, payload) => {
  return { ...state, ...payload };
});

export const simpleSearchReducer = combineReducers({
  simpleSearchForm,
  simpleSearchFormBus,
  citySuggestions,
});
