import React from 'react';
import styled from 'styled-components';
import { Header } from '@components/ui/Typography';
import Popup from 'reactjs-popup';
import { SmsNotificationsProps } from './types';

const StyledPopup = styled(Popup)`
  &-content {
    max-width: 530px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
  }
`;

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  max-width: 493px;
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  outline: none;
  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const SmsNotificationCoditions: React.FC<SmsNotificationsProps> = ({
  open,
  onClose,
}) => {
  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <StyledPopup open={open} onClose={onClose}>
      <PopupHead>
        <Header style={{ fontSize: 20 }}>
          Правила и условия предоставления услуги
        </Header>
        <Cross
          href="/sde"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
      </PopupHead>
      <Body>
        <span style={{ fontWeight: 600 }}> Услуга смс-информирование </span>
        подразумевает оперативное уведомление об изменениях в заказе, перелете,
        обращениях, возврате денежных средств при аннулировании бронирования
        путем отправки короткого смс-сообщения. <br />
      </Body>
      <br />
      <Body>
        СМС-сообщение направляется на контактный номер, указанных при оформлении
        заказа.
        <br />
      </Body>
      <br />
      <Body>
        <span style={{ fontWeight: 600 }}>Услуга считается оказанной </span> с
        момента направления первого смс-сообщения на указанный пассажиром при
        оформлении заказа номер телефона.
        <br />
      </Body>
      <br />
      <Body>
        Компания не несет ответственности за некорректно указанный номер
        телефона при оформлении заказа, а также за проблемы со связью мобильных
        операторов.
      </Body>
    </StyledPopup>
  );
};

export default SmsNotificationCoditions;
