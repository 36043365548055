import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';

import successOrderIconNew from '../../assets/images/successOrderIconNew.svg';
import failOrderIconNew from '../../assets/images/failOrderIconNew.svg';

import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
// import { Helper, Hooks } from '@utils';
import { Helper, Hooks } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  confirmPayment2Request,
  confirmPayment2Success,
  createPaymentTinkoffRequest,
  getDetailOrder,
} from '@modules/orders';
import { ApplicationState } from '@modules/index';
import { UserCookie } from '@services/UserCookies';
import NotifyLoader from '@components/common/NotifyLoader';

const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  padding: 28px 20px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px 0px #0000000d;
  width: 767px;
  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  // @media (min-width: 768px) {
  //   margin-bottom: 40px;
  // }
`;

const Header = styled.div<LinkProps>`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ isActive }) => (isActive ? '#D40E14' : '#3C3C3C')};
`;

const Gray = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  // @media (min-width: 767px) {
  //   font-weight: 600;
  //   font-size: 18px;
  //   line-height: 22px;
  //   color: #737373;
  // }
`;

const Black = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  font-weight: bold;
  // @media (min-width: 767px) {
  //   font-size: 18px;
  //   line-height: 22px;
  //   font-weight: normal;
  //   color: #3c3c3c;
  // }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
  }
  margin-top: 20px;
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  & > :first-child {
    margin-right: 5px;
  }
`;

const ReturnButton = styled(Button).attrs({
  type: 'primary',
  color: 'yellow',
})`
  width: 230px;
  @media (min-width: 767px) {
    width: auto;
    text-align: center;
  }
`;

const Flex = styled.div`
  margin-top: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface LinkProps {
  isActive: boolean;
}

const OrderPaymentStatusNew: React.FC<
  RouteComponentProps<{ number: string }>
> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = React.useState<boolean>();
  const { isMobile } = Hooks.useMediaSizes();

  const [orderPosIds, setOrderPosIds] = React.useState([]);

  const notifyPaymentCheck = useSelector(
    (state: ApplicationState) => state.orders.orderDetail?.notifyPaymentCheck,
  );
  const notifyPaymentInProccess = useSelector(
    (state: ApplicationState) =>
      state.orders.orderDetail?.notifyPaymentInProccess,
  );

  const { orderNumber } = useParams<{ orderNumber: string }>();
  const params = new URLSearchParams(
    decodeURIComponent(history.location.search),
  );
  const amount = parseInt(params.get('amount') || '0');
  const paymentNumber = params.get('paymentNumber') || '';

  React.useEffect(() => {
    if (match.path.includes('SuccessNew')) {
      setIsSuccess(true);
      dispatch(
        confirmPayment2Request({
          number: paymentNumber,
          orderNumber: orderNumber,
          token: UserCookie.token || '',
        }),
      );
    } else {
      setIsSuccess(false);
    }
  }, []);

  React.useEffect(() => {
    if (notifyPaymentCheck) {
      history.push(`/orders/${orderNumber}`);
      dispatch(confirmPayment2Success(false));
    }
  }, [notifyPaymentCheck]);

  Hooks.useBlockBodyScroll();

  const [text] = React.useState({
    success: {
      description: 'Ваш платёж принят, дождитесь обработки',
      status: 'Оплата прошла успешно',
      icon: successOrderIconNew,
    },
    failure: {
      description: 'Не удалось оплатить заказ',
      status: 'Не получилось оплатить',
      icon: failOrderIconNew,
    },
  });
  //
  React.useEffect(() => {
    if (window.parent && !notifyPaymentCheck) {
      window.parent.postMessage('537', '*');
    } else if (window.parent && notifyPaymentCheck) {
      window.parent.postMessage(notifyPaymentCheck, '*');
    }
  }, [notifyPaymentCheck]);

  React.useEffect(() => {
    if (window.parent && notifyPaymentInProccess) {
      window.parent.postMessage('notifyPaymentInProccess', '*');
    }
  }, [notifyPaymentInProccess]);

  React.useEffect(() => {
    if (notifyPaymentCheck) {
      history.push(`/orders/${orderNumber}`);
      dispatch(confirmPayment2Success(false));
    }
  }, [notifyPaymentCheck]);

  return (
    <Wrapper id="test">
      {notifyPaymentInProccess && <NotifyLoader />}

      <Content>
        <Head>
          {isSuccess ? (
            <img src={text.success.icon} style={{ marginRight: 7 }} />
          ) : (
            <img src={text.failure.icon} style={{ marginRight: 7 }} />
          )}
          <Header isActive={!isSuccess}>
            {isSuccess ? text.success.description : text.failure.description}
          </Header>
        </Head>

        {isSuccess && (
          <Row>
            <Gray style={{ fontSize: 14 }}>
              Обработка платежа может занять несколько минут, пожалуйста,
              дождитесь завершения.
            </Gray>
          </Row>
        )}
        <List>
          <Row>
            <Gray>Стоимость заказа:</Gray>
            <Black>{Helper.formatPrice(amount)}</Black>
          </Row>
          <Row>
            <Gray>Номер заказа:</Gray>
            <Black>{orderNumber} </Black>
          </Row>
          <Row>
            <Gray>Описание:</Gray>
            <Black>
              {' '}
              {isSuccess ? 'Ожидает обработки' : 'Оплата не прошла'}
            </Black>
          </Row>
        </List>
        {!isSuccess && (
          <Flex>
            <ReturnButton
              style={{ width: 250 }}
              onClick={() => {
                if (!isMobile) {
                  window.top?.location.reload();
                }
                if (isMobile) {
                  window.parent.postMessage('error', '*');
                }
              }}
            >
              Попробовать еще раз
            </ReturnButton>
          </Flex>
        )}
      </Content>
    </Wrapper>
  );
};

export default OrderPaymentStatusNew;
