import React, { useState } from 'react';
import * as Styled from './styles/Info.style';
import { Helper } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { Button } from '@components/ui';
import {
  addAppealFailure,
  APPEAL_STEP,
  GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
  getAddAppealState,
  getRefundAmountState,
  setRefunded,
  setRefundStep,
} from '@modules/appeals';
import { useProps } from './useProps';
import { createRefundRequest } from '@modules/appeals/Manager';
import RefundAgreement from './components/RefundAgreement';
import NotifyLoader from '../../NotifyLoader';
import { PositionAwailableReason } from '@app/components/order/Detail/OrderPositions/Item/types';

type PositionType =
  | PositionAwailableReason.FULL_REFUND
  | PositionAwailableReason.PARTIAL_REFUND;

const infoFieldsTicket = [
  { label: 'Пассажир 1, взрослый:', value: 'fullName' },
  { label: 'Номер билета:', value: 'number' },
  { label: 'Стоимость билета:', value: 'fullPrice' },
  { label: 'Сумма удержаний за возврат:', value: 'totalHoldSum' },
];

const infoFieldsEmd = [
  { label: 'Пассажир 1, взрослый:', value: 'fullName' },
  { label: 'Название доп. услуги:', value: 'emdName' },
  { label: 'Стоимость доп. услуги:', value: 'fullPrice' },
  { label: 'Тип доп. услуги:', value: 'emdType' },
  { label: 'Номер EMD:', value: 'number' },
  { label: 'Сумма удержаний за возврат:', value: 'totalHoldSum' },
];

const tableFields = [
  { label: 'Сбор авиакомпании за возврат', value: 'fee' },
  { label: 'Сбор агента за возврат', value: 'tteCaTax' },
  { label: 'Удержание по тарифу', value: 'equivAmount' },
  { label: 'Удержание такс', value: 'totalTaxAmount' },
  { label: 'Такса СА', value: 'ttePrTax' },
];

const pickInfoFields = (position?: PositionType | null | string) => {
  switch (position) {
    case PositionAwailableReason.FULL_REFUND:
    case PositionAwailableReason.FULL_REFUND_SPLIT:
      return infoFieldsTicket;
    case PositionAwailableReason.PARTIAL_REFUND:
      return infoFieldsEmd;
    default:
      return [];
  }
};

const CreateFormRefundInfo: React.FC = () => {
  const { orderPosition } = useProps();
  const { STEP } = useSelector(getAddAppealState);
  const isInfoModal = STEP === APPEAL_STEP.REFUND_INFO;
  const [isAgree, setIsAgree] = useState(isInfoModal);
  const [isLoading, setIsLoading] = useState(false);
  const refundAmount = useSelector(getRefundAmountState);
  const refundPositions = useSelector(
    (state: ApplicationState) => state.appeals.refundPositions,
  );
  const prevRefundedPositions = useSelector(
    (state: ApplicationState) => state.appeals.refunded.data,
  );
  const isFewPositions = refundPositions.length > 1;
  const totalRefund = refundPositions.reduce(
    // @ts-ignore
    (acc, position) => (acc += position.totalRefundSum),
    0,
  );
  const refunded = useSelector(
    (state: ApplicationState) => state.appeals.refunded,
  );

  const orderNumber = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.data?.orderNumber,
  );
  const dispatch = useDispatch();

  const handleConfirm = async () => {
    // @ts-ignore
    const payloadPositions = Object.groupBy(
      refundPositions,
      (position: any) => {
        return position.reasonCode.includes('Full Refund') ? 'tickets' : 'emds';
      },
    );
    const payload = {
      orderPositionId: orderPosition.id,
      ticketsNumbers: payloadPositions.tickets?.map(
        (ticket: any) => ticket.number,
      ),
      emdsNumbers: payloadPositions.emds?.map((ticket: any) => ticket.number),
    };
    try {
      setIsLoading(true);
      await createRefundRequest(payload);
      dispatch(setRefundStep(APPEAL_STEP.REFUND_SUCCESS));
      dispatch(
        setRefunded({
          isRefunded: true,
          data: [...prevRefundedPositions, ...refundPositions],
        }),
      );
    } catch {
      dispatch(addAppealFailure());
    } finally {
      setIsLoading(false);
    }
  };

  const handleDecline = () => {
    dispatch(setRefundStep(APPEAL_STEP.REFUND_DECLINE));
  };

  const pickDisplayedData = () => {
    switch (STEP) {
      case APPEAL_STEP.REFUND_INFO:
        return refunded.data;
      case APPEAL_STEP.REFUND_CONFIRM:
        return refundPositions;
    }
  };

  if (isLoading) return <NotifyLoader title={'Идёт обработка возврата'} />;

  return (
    <Styled.Container>
      <Styled.Title>Расчёт на возврат</Styled.Title>
      <Styled.SubTitle>Информация:</Styled.SubTitle>
      {refundAmount.data.timeLimit ? (
        <Styled.InfoItem style={{ marginTop: 8 }}>
          <Styled.InfoLabel>Время на подтверждение расчета:</Styled.InfoLabel>
          <Styled.InfoValueStrong>
            {refundAmount.data.timeLimit}мин
          </Styled.InfoValueStrong>
        </Styled.InfoItem>
      ) : null}
      {pickDisplayedData()!.map(
        (
          position: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
          index: number,
        ) => {
          const infoFields = pickInfoFields(position.reasonCode);
          return (
            <Styled.Position
              key={index}
              isFewPositions={isFewPositions}
              isBorder={isFewPositions && index !== refundPositions.length - 1}
            >
              <Styled.Info>
                {infoFields.map((info, index) => {
                  const value =
                    position[
                      info.value as keyof GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket
                    ];
                  const displayValue =
                    typeof value === 'number'
                      ? Helper.formatPrice(Math.abs(value))
                      : value;
                  return (
                    <Styled.InfoItem key={index}>
                      <Styled.InfoLabel>{info.label}</Styled.InfoLabel>
                      <Styled.InfoValue>{displayValue}</Styled.InfoValue>
                    </Styled.InfoItem>
                  );
                })}
              </Styled.Info>
              <Styled.Table>
                <Styled.TableHeader>
                  <Styled.TableRow>
                    <Styled.TableTitle>Наименование</Styled.TableTitle>
                    <Styled.TableTitle>Цена</Styled.TableTitle>
                  </Styled.TableRow>
                </Styled.TableHeader>
                <Styled.TableBody>
                  {tableFields.map((field, index) => {
                    const value = position[
                      field.value as keyof GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket
                    ] as number;
                    return (
                      <Styled.TableRow key={index}>
                        <Styled.TableData>{field.label}</Styled.TableData>
                        <Styled.TableData>
                          <Styled.Cost>
                            {Helper.formatPrice(Math.abs(value))}
                          </Styled.Cost>
                        </Styled.TableData>
                      </Styled.TableRow>
                    );
                  })}
                </Styled.TableBody>
                <Styled.TableFooter>
                  <Styled.TableRow>
                    <Styled.TableDataFooter>Итого:</Styled.TableDataFooter>
                    <Styled.TableDataFooter>
                      <Styled.Cost>
                        {/* @ts-ignore */}
                        {Helper.formatPrice(position?.totalHoldSum)}
                      </Styled.Cost>
                    </Styled.TableDataFooter>
                  </Styled.TableRow>
                </Styled.TableFooter>
              </Styled.Table>
              {isFewPositions && (
                <Styled.InfoItem style={{ marginTop: 16 }}>
                  <Styled.InfoLabel>Сумма возврата по услуге:</Styled.InfoLabel>
                  <Styled.InfoValueStrong>
                    {Helper.formatPrice(
                      // TODO после того как все суммы приведут к одному виду, снять отсюду игнор и переписать типы
                      // @ts-ignore
                      Math.abs(position?.totalRefundSum),
                    )}
                  </Styled.InfoValueStrong>
                </Styled.InfoItem>
              )}
            </Styled.Position>
          );
        },
      )}

      <Styled.Total>
        Итоговая сумма возврата:{' '}
        <Styled.InfoValueStrong>
          <Styled.Cost>{Helper.formatPrice(totalRefund)}</Styled.Cost>
        </Styled.InfoValueStrong>
      </Styled.Total>

      <Styled.CheckBoxWrapper>
        <RefundAgreement
          isAgree={isAgree}
          isDisable={isInfoModal}
          setIsAgree={setIsAgree}
          orderNumber={orderNumber}
          totalRefund={Helper.formatPrice(totalRefund)}
        />
      </Styled.CheckBoxWrapper>
      {STEP === APPEAL_STEP.REFUND_CONFIRM && (
        <Styled.ButtonGroup>
          <Button
            onClick={handleConfirm}
            disabled={!isAgree}
            type={'primary'}
            template={'blue'}
          >
            Я подтверждаю расчет
          </Button>
          <Button
            onClick={handleDecline}
            type={'outlined'}
            template={'blue'}
            style={{ paddingInline: '34px' }}
          >
            Я отклоняю расчет
          </Button>
        </Styled.ButtonGroup>
      )}
    </Styled.Container>
  );
};

export default CreateFormRefundInfo;
