import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { HeadProps } from './types';

const Wrapper = styled.div`
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Text)`
  font-weight: 600;
  color: black;
  text-transform: uppercase;
`;

const Head: React.FC<HeadProps> = ({ value }) => {
  return (
    <Wrapper>
      <Title>{value}</Title>
    </Wrapper>
  );
};

export default Head;
