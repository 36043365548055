import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';

import { PopupProps, PopupActions } from 'reactjs-popup/dist/types';
import { Text, Button } from '@components/ui';
import { ReactComponent as InfoIcon } from '@assets/media/addictional-services/info.svg';
import { ReactComponent as MealIcon } from '@assets/media/addictional-services/meal.svg';
import { ReactComponent as LuggageIcon } from '@assets/media/addictional-services/luggage.svg';
import {
  AncillaryServicesState,
  PassengerItemEntity,
  AncillaryServiceType,
  getBookingFaresState,
} from '@modules/booking';
import { Checkbox } from '@components/ui/form';
import { Helper } from '@utils';
import _ from 'lodash';

import blueInfoIcon from '@assets/media/agents/blueInfoIcon.svg';
import redInfoIcon from '@assets/media/shared/redInfoIcon.svg';

import { ApplicationState } from '@modules/index';
import PassengerSelectButton from '@components/booking/AddictionalServices/AviaServices/PassengerSelectButton';
import { newAncillaryServicesSelector } from '@modules/orders/selectors';
import { addAncillaryNewSaga } from '@modules/orders/sagas';
import { addAncillaryNew } from '@modules/orders';

const { formatPrice } = Helper;

const StyledPopup = styled(Popup)`
  position: relative;

  &-overlay {
    overflow: scroll;

    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }
  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    // height: calc(var(--vh, 1vh) * 95);
    width: 100%;
    max-width: 630px;
    box-sizing: border-box;
    overflow: hidden;
    height: fit-content;
    @media (min-height: 878px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      display: block;
      overflow: scroll;
      height: calc(var(--vh, 1vh) * 100);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0;
      padding-top: 44px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
  }
  ${Text} {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;

    ${Text} {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    & > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const Desctiption = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-top: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px;
    display: none;
  }
`;

const ButtonBlock = styled.div`
  & > button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  margin-bottom: 25px;

  @media (max-width: 1023px) {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
    display: none;
  }
`;

const Body = styled.div`
  flex-grow: 1;
`;

const PassengerBlockWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
    overflow: hidden;
  }
`;

const PassengersBlock = styled.div`
  & > button {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    & > button {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;

const CheckboxContainerDescription = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 11px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const CheckboxContainer = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  margin-bottom: 20px;

  @media (max-width: 767px) {
    padding: 0 20px;
    margin-bottom: 25px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalPrice = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  & > strong {
    color: #4872f2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const PrimaryButton = styled(Button).attrs({ type: 'primary' })`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20.5px;
  display: block;
  height: 40px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const OutlinedButton = styled(Button).attrs({ type: 'outlined' })`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20.5px;
  height: 40px;
  margin-left: 20px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  & > svg {
    flex-shrink: 0;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const AbsoluteBlock = styled.div`
  position: relative;
  width: 520px;
  height: 40px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;

const DesktopInfo = styled(InfoContainer)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileInfo = styled(InfoContainer)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const InfoText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  padding-left: 8px;

  @media (max-width: 767px) {
    font-weight: 400;
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  outline: none;
  z-index: 100;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    position: fixed;
  }
`;

const RouteName = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    padding: 30px 20px 10px 20px;
    margin-bottom: 0;
  }
`;

const StickyFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-around;
  padding: 0 10px;
  z-index: 100000;
  box-shadow: 0px -4px 12px 0px #757a8830;

  @media (min-width: 767px) {
    display: none;
  }
`;

const ButtonNext = styled.div`
  width: 95%;
  height: 46px;
  border-radius: 8px;
  background-color: #4872f2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 20px;
`;

const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  margin-bottom: 30px;
  margin-left: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Icon = styled.img.attrs({ src: blueInfoIcon })`
  margin-left: 7px;
  width: 18px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const IconRed = styled.img.attrs({ src: redInfoIcon })`
  margin-right: 7px;
  width: 25px;
  height: 25px;
`;

const RedHeading = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #f25f61;
`;

const RedContainer = styled.div`
  background-color: #ffe2e3;
  border-radius: 4px;
  font-family: Open Sans;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  padding: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

interface BaseModalProps
  extends Omit<
    PopupProps & React.RefAttributes<PopupActions>,
    'children' | 'open'
  > {
  data?: any;
  setIsServiceModalOpen: any;
  isServiceModalOpen: boolean;
  chosenServiceName: string;
}

export default function ({
  data,
  setIsServiceModalOpen,
  isServiceModalOpen,
  chosenServiceName,
  ...props
}: BaseModalProps) {
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);

  const newAncillarySelector = useSelector(newAncillaryServicesSelector);

  const [segmentId, setSegment] = React.useState('');

  const [passengerId, setPassengerId] = React.useState('');

  const [passangerItems, setPassengerItems] = React.useState<any>([]);

  const [chosenItems, setChosenItems] = React.useState<any>([]);

  const [price, setPrice] = React.useState<any>([]);

  const dispatch = useDispatch();

  const {
    TITLE,
    DESCRIPTION,
    CHECKBOX_CONTAINER_DESCRIPTION,
    TOTAL_PRICE,
    ICON,
    RUS_TYPE,
    NOT_SELECTED,
    SELECTED,
  } = React.useMemo(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    switch (chosenServiceName) {
      case 'Luggage':
        return {
          TITLE: 'Дополнительный багаж',
          DESCRIPTION: `Оформите багаж заранее, в аэропорту эта услуга займет время и может стоить дороже, чем на сайте.`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'Выберите багаж:',
          TOTAL_PRICE: 'Стоимость всего багажа:',
          RUS_TYPE: 'багаж',
          NOT_SELECTED: 'не выбран',
          SELECTED: `${width > 767 ? 'багаж ' : ''}выбран`,
          ICON: <LuggageIcon />,
        };
      case 'Meal':
        return {
          TITLE: 'Насладитесь вашим полетом',
          DESCRIPTION: `Выберите блюдо, подходящее Вашим вкусовым предпочтениям, и наслаждайтесь полетом в комфортных условиях.`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'Выберите питание:',
          TOTAL_PRICE: 'Стоимость всех блюд:',
          RUS_TYPE: 'питание',
          NOT_SELECTED: 'не выбрано',
          SELECTED: `${width > 767 ? 'питание ' : ''}выбрано`,
          ICON: <MealIcon />,
        };
      default:
        return {
          TITLE: 'TITLE',
          DESCRIPTION: `DESCRIPTION`,
          CHECKBOX_CONTAINER_DESCRIPTION: 'CHECKBOX_CONTAINER_DESCRIPTION:',
          TOTAL_PRICE: 'TOTAL_PRICE:',
          RUS_TYPE: 'RUS_TYPE',
          NOT_SELECTED: 'NOT_SELECTED',
          SELECTED: 'SELECTED',
          ICON: null,
        };
    }
  }, [data?.type]);

  const sementsList = newAncillarySelector?.segments?.map((x) => x.id);

  function findNextNotFilledSegment() {
    if (segmentId && sementsList) {
      const index = sementsList.indexOf(segmentId);

      return sementsList[index + 1];
    }
  }

  function findPreviousSegment() {
    if (segmentId && sementsList) {
      const index = sementsList.indexOf(segmentId);

      return sementsList[index - 1];
    }
  }

  const nextSegmentId = findNextNotFilledSegment();
  const previousSegment = findPreviousSegment();

  function getPassengerTypeDescription(str?: string) {
    switch (str) {
      case 'Adult':
        return 'Взрослый';
      case 'Child':
        return 'Ребенок';
      default:
        return 'Младенец';
    }
  }

  const getPassangerName = (id: string) => {
    const passanger = order.data.orderPositions[0].passengers.find(
      (x) => x.passengerId === id
    );
    return {
      name: passanger?.firstName + ' ' + passanger?.lastName,
      ageCategory: getPassengerTypeDescription(passanger?.passengerType),
      isChosen: id === passengerId,
    };
  };

  React.useEffect(() => {
    if (data[0].passengerId) {
      setPassengerId(data[0].passengerId);
      if (chosenServiceName === 'Meal') {
        setSegment(newAncillarySelector.availableSegmentsMealsIds[0]);
      }
      if (chosenServiceName === 'Luggage') {
        setSegment(newAncillarySelector.availableSegmentsLuggageIds[0]);
      }
    }
  }, [data]);

  React.useEffect(() => {
    setPassengerItems(
      data[0].items.filter((x: any) => x.aviaSegmentIds.includes(segmentId))
    );
  }, [segmentId]);

  React.useEffect(() => {
    setPrice(_(chosenItems).groupBy('aviaPassengerId').value());
  }, [chosenItems]);

  const Submit = (chosenItems: any) => {
    dispatch(
      addAncillaryNew({
        orderPositionId: order.data.orderPositions[0].id,
        ancillaryServices: chosenItems.map((x: any) => ({
          rfic: x.rfic,
          rfisc: x.rfisc,
          passengerId: x.aviaPassengerId,
          segmentIds: x.aviaSegmentIds,
        })),
      })
    );
  };

  return data ? (
    <StyledPopup
      onClose={() => setIsServiceModalOpen(false)}
      open={isServiceModalOpen}
    >
      <Cross
        href="/sde"
        onClick={(e) => {
          e.preventDefault();
          setIsServiceModalOpen(false);
        }}
      />

      <Body>
        <Head id="HeadModal">
          {ICON}
          <Text>{TITLE}</Text>
        </Head>
        <Desctiption>{DESCRIPTION}</Desctiption>
        <RouteName>
          Маршрут:{' '}
          <strong>
            {segmentId &&
              newAncillarySelector?.groupedSegments[segmentId]?.departureCity
                .name +
                ' - ' +
                newAncillarySelector?.groupedSegments[segmentId]?.arrivalCity
                  .name}
          </strong>
        </RouteName>

        <PassengerBlockWrapper>
          <PassengersBlock>
            {data.map((x: any, key: number) => {
              const title = getPassangerName(x.passengerId);

              return (
                <PassengerSelectButton
                  key={`${key}-${title}`}
                  {...x}
                  number={key + 1}
                  cost={price[x.passengerId]?.reduce(
                    (acc: number, y: any) =>
                      acc + y.ancillaryServiceAmount.value,
                    0
                  )}
                  selected={x.passengerId === passengerId}
                  title={
                    getPassangerName(x.passengerId).name !== ''
                      ? SELECTED
                      : NOT_SELECTED
                  }
                  ageCategoryDescription={
                    getPassangerName(x.passengerId).ageCategory
                  }
                  onClick={() => {
                    setPassengerId(x.passengerId);
                    setPassengerItems(
                      x.items.filter((x: any) =>
                        x.aviaSegmentIds.includes(segmentId)
                      )
                    );
                  }}
                />
              );
            })}
          </PassengersBlock>
        </PassengerBlockWrapper>

        <CheckboxContainerDescription>
          {CHECKBOX_CONTAINER_DESCRIPTION}
        </CheckboxContainerDescription>
        <CheckboxContainer>
          {passangerItems?.map((item: any, key: number) => (
            <Row key={key}>
              <div>
                <Checkbox
                  label={item.name}
                  checked={chosenItems.includes(item)}
                  onChange={() => {
                    if (item.aviaPassengerId === passengerId) {
                      chosenItems.includes(item)
                        ? setChosenItems(
                            chosenItems.filter((x: any) => x !== item)
                          )
                        : ((item['chosenSegmentForItem'] = segmentId),
                          setChosenItems([...chosenItems, item]));
                    }
                  }}
                  type="squar"
                />
                <Text size="big" color="blue">
                  {' '}
                  {formatPrice(item.ancillaryServiceAmount.value)}
                </Text>
              </div>
            </Row>
          ))}
        </CheckboxContainer>

        {/* <TotalPrice>
          {TOTAL_PRICE}{' '}
          <strong>
            {Helper.formatPrice(
              chosenItems.reduce(
                (acc: number, x: any) => acc + x.ancillaryServiceAmount.value,
                0
              )
            )}
          </strong>
        </TotalPrice> */}
      </Body>
      <AbsoluteBlock>
        {nextSegmentId ? (
          <PrimaryButton
            onClick={() =>
              nextSegmentId
                ? setSegment(nextSegmentId)
                : setIsServiceModalOpen(false)
            }
          >
            Перейти к следующему маршруту
          </PrimaryButton>
        ) : null}
        {previousSegment ? (
          <PrimaryButton
            onClick={() => previousSegment && setSegment(previousSegment)}
          >
            Вернуться назад
          </PrimaryButton>
        ) : null}
        {chosenItems.length > 0 && (
          <OutlinedButton onClick={() => Submit(chosenItems)}>
            Закончить
          </OutlinedButton>
        )}
      </AbsoluteBlock>
      {/* <StickyFooter>
        {data.segments.length > 1 && !previousSegment && (
          <ButtonNext
            onClick={() =>
              nextSegmentId
                ? handleChangeSegment(nextSegmentId)
                : setIsServiceModalOpen(false)
            }
          >
            Перейти далее
          </ButtonNext>
        )}

        {data.segments.length === 1 && (
          <ButtonNext onClick={() => setIsServiceModalOpen(false)}>
            Закончить
          </ButtonNext>
        )}
        {data.segments.length > 1 && previousSegment && (
          <>
            <ButtonNext
              style={{
                width: 147,
                backgroundColor: 'white',
                color: '#4872F2',
                border: '1px solid #4872F2',
              }}
              onClick={() =>
                previousSegment && handleChangeSegment(previousSegment)
              }
            >
              Вернуться назад
            </ButtonNext>
            <ButtonNext
              style={{ width: 147 }}
              onClick={() =>
                nextSegmentId
                  ? handleChangeSegment(nextSegmentId)
                  : typeof props.onClose === 'function' && props.onClose()
              }
            >
              Перейти далее
            </ButtonNext>
          </>
        )}
      </StickyFooter>

      {/* <MobileCloseButton onClick={props.onClose}>
        Добавить {RUS_TYPE} за: {Helper.formatPrice(totalPrice)}
      </MobileCloseButton> */}
    </StyledPopup>
  ) : null;
}
