import React from 'react';
import ReactPopup from 'reactjs-popup';
import styled from 'styled-components';
import { PopupProps } from './types';
import Head from './Head';
import Body from './Body';
import Footer from './Footer';
import { PopupProvider } from './usePopup';
import { useSelector } from 'react-redux';
import { getFiltersCounter } from '@modules/main';
import { ApplicationState } from '@modules/index';

const StyledPopup = styled(ReactPopup)`
  &-content {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    border: none;
    border-radius: 0;
    overflow-x: scroll;
    position: relative;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const Popup: React.FC<PopupProps> = (props) => {
  const filtersCounter = useSelector(getFiltersCounter);
  const [prevFiltersCounter, setPrevFilterCounter] = React.useState(0);
  const {
    filter: { isDefault },
  } = useSelector((state: ApplicationState) => state.mainReducer.flightSearch);

  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
      setPrevFilterCounter(filtersCounter);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <PopupProvider onClose={props.onClose}>
        <Head />
        <Body />
        {(Object.values(isDefault).some((x) => x !== true) ||
          filtersCounter !== prevFiltersCounter) && <Footer />}
      </PopupProvider>
    </StyledPopup>
  );
};

export default Popup;
