import React from 'react';

import Head from '../Head';
import Body from './Body';
import MobileDetail from '../../MobileOrderPosition';
import { useOrderPosition } from '../../useItemProps';

const Description: React.FC = React.memo(() => {
  const props = useOrderPosition();
  return (
    <>
      {/* <Head /> */}
      <Body />
      {props.isOpen && !props.isMobile && <MobileDetail {...props} />}
    </>
  );
});

export default Description;
