import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22.7258C5.63314 22.7258 0.5 17.7351 0.5 11.6129C0.5 5.49074 5.63314 0.5 12 0.5C18.3669 0.5 23.5 5.49074 23.5 11.6129C23.5 17.7351 18.3669 22.7258 12 22.7258Z"
        fill="white"
        stroke="#4872F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6031 9.61872C16.5567 9.57366 16.5015 9.53791 16.4407 9.51351C16.38 9.48912 16.3149 9.47656 16.2491 9.47656C16.1833 9.47656 16.1182 9.48912 16.0575 9.51351C15.9967 9.53791 15.9416 9.57366 15.8951 9.61872L11.8349 13.5489L7.77468 9.61872C7.68079 9.52786 7.55346 9.47682 7.42068 9.47682C7.2879 9.47682 7.16057 9.52786 7.06668 9.61872C6.97279 9.70958 6.92005 9.83281 6.92005 9.9613C6.92005 10.0898 6.97279 10.213 7.06668 10.3039L11.4809 14.5757C11.5273 14.6208 11.5825 14.6565 11.6433 14.6809C11.704 14.7053 11.7691 14.7179 11.8349 14.7179C11.9007 14.7179 11.9658 14.7053 12.0265 14.6809C12.0873 14.6565 12.1424 14.6208 12.1889 14.5757L16.6031 10.3039C16.6497 10.2589 16.6866 10.2055 16.7118 10.1468C16.737 10.088 16.75 10.0249 16.75 9.9613C16.75 9.89765 16.737 9.83463 16.7118 9.77585C16.6866 9.71706 16.6497 9.66366 16.6031 9.61872Z"
        fill="#4872F2"
      />
    </svg>
  );
};

export default Logo;
