import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { YearSelectProps } from './types';
import { memoize } from 'lodash';

//TODO сделать переиспользуемый компонент

const Wrapper = styled.div`
  position: relative;
`;

const InnerYearContainer = styled.div`
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }
`;

const MenuArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;

  &:hover {
    background: #f2f2f2;
  }
`;

const Container = styled.div`
  padding: 7px 0;
  box-sizing: border-box;
  position: absolute;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  text-align: left;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  top: 100%;
  z-index: 1;

  & > ${InnerYearContainer} {
    display: block;
    padding: 7px 12px;
    cursor: pointer;

    &.selected {
      background: #f2f2f2;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled.svg`
  height: 7.65px;
  width: 8px;
  //   margin-left: 7px;
  fill: ${({ theme: { colors } }) => colors.main};

  &:hover {
    fill: ${({ theme: { colors } }) => colors.darkGray};
  }
`;

const DoubleArrow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`;

const SelectedYearContainer = styled.div`
  display: flex;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${({ theme: { colors } }) => colors.main};
`;

const ArrowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;

  &:hover {
    background: #f2f2f2;
  }
`;

const getYearList = memoize((year: number) => {
  let yearWIthoutRemain = year - (year % 10);
  return Array(11)
    .fill(0)
    .map(() => {
      const currentYear = yearWIthoutRemain;
      yearWIthoutRemain++;
      return currentYear;
    });
});

const MonthSelect: React.FC<YearSelectProps> = ({ year, onSelect }) => {
  const [isVisible, setVisiblity] = useState(false);
  const yearList = getYearList(year);

  const ref = useRef(null as null | HTMLDivElement);

  useEffect(() => {
    const handleCLickOutside = (e: MouseEvent) => {
      if (ref.current) {
        if (!ref.current.contains(e.target as any)) {
          setVisiblity(false);
        }
      }
    };
    window.addEventListener('mousedown', handleCLickOutside);
    return () => window.removeEventListener('mousedown', handleCLickOutside);
  }, [ref, setVisiblity]);

  const handleUp: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onSelect(year + 1);
  };

  const handleDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onSelect(year - 1);
  };

  const handleClick: React.MouseEventHandler<HTMLSpanElement> = () => {
    setVisiblity(!isVisible);
  };

  const handleListDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onSelect(year - 10);
  };

  const handleListUp: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onSelect(year + 10);
  };

  const handleSelect = (value: number) => {
    onSelect(value);
    setVisiblity(false);
  };

  return (
    <Wrapper ref={ref}>
      <InputContainer>
        <SelectedYearContainer onClick={handleClick}>
          <Text>{year}</Text>
        </SelectedYearContainer>
        <DoubleArrow>
          <MenuArrowContainer onClick={handleUp}>
            <Arrow>
              <path d="M3.25892 0.817974C3.65585 0.379862 4.34415 0.379862 4.74108 0.817974L7.68186 4.06388C8.2642 4.70664 7.80811 5.73529 6.94077 5.73529H1.05923C0.191888 5.73529 -0.2642 4.70664 0.318144 4.06388L3.25892 0.817974Z" />
            </Arrow>
          </MenuArrowContainer>
          <MenuArrowContainer onClick={handleDown}>
            <Arrow>
              <path d="M3.25892 5.18203C3.65585 5.62014 4.34415 5.62014 4.74108 5.18203L7.68186 1.93612C8.2642 1.29336 7.80811 0.264706 6.94077 0.264706H1.05923C0.191888 0.264706 -0.2642 1.29336 0.318144 1.93612L3.25892 5.18203Z" />
            </Arrow>
          </MenuArrowContainer>
        </DoubleArrow>
      </InputContainer>
      {isVisible && (
        <Container>
          <ArrowContainer onClick={handleListDown}>
            <Arrow>
              <path d="M3.25892 0.817974C3.65585 0.379862 4.34415 0.379862 4.74108 0.817974L7.68186 4.06388C8.2642 4.70664 7.80811 5.73529 6.94077 5.73529H1.05923C0.191888 5.73529 -0.2642 4.70664 0.318144 4.06388L3.25892 0.817974Z" />
            </Arrow>
          </ArrowContainer>
          {yearList.map((innerYear) => (
            <InnerYearContainer
              onClick={() => handleSelect(innerYear)}
              className={innerYear === year ? 'selected' : ''}
              key={innerYear}
            >
              <Text>{innerYear}</Text>
            </InnerYearContainer>
          ))}
          <ArrowContainer onClick={handleListUp}>
            <Arrow>
              <path d="M3.25892 5.18203C3.65585 5.62014 4.34415 5.62014 4.74108 5.18203L7.68186 1.93612C8.2642 1.29336 7.80811 0.264706 6.94077 0.264706H1.05923C0.191888 0.264706 -0.2642 1.29336 0.318144 1.93612L3.25892 5.18203Z" />
            </Arrow>
          </ArrowContainer>
        </Container>
      )}
    </Wrapper>
  );
};

export default MonthSelect;
