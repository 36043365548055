import React from 'react';

import styled from 'styled-components';
import {
  SeatMapPassenger,
  PassengerAgeCategory,
  removeSeatByPassengerId,
} from '@modules/booking';
import { Text } from '@components/ui';
import adultPath from '../assets/media/adult.svg';
import childPath from '../assets/media/child.svg';
import { ReactComponent as Cross } from '../assets/media/cross.svg';
import { useDispatch } from 'react-redux';
import { Hooks } from '@utils';

const PassengerSelect = styled.button<{ background: string }>`
  width: calc(100% - 32px);
  outline: none;
  padding: 15px;
  background: ${({ background }) => background};
  border: 1px solid
    ${({ background }) => (background === '#E3EAFF' ? '#4872F2' : '#c4c4c4')};
  box-sizing: content-box;
  border-radius: 6px;
  outline: none;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%), 0px 0px 5px rgb(0 0 0 / 5%);

  @media (max-width: 767px) {
    padding: 8px 8px;
    height: 42px;
    width: 97%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    border: ${({ background }) =>
      background === '#E3EAFF' ? 'none' : '1px solid #c4c4c4'};
  }
`;

const PassengerSelectLeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  & > img {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    height: 16px;
    justify-content: space-between;
    margin-bottom: 4px;
    & > img {
      max-height: 16px;
    }
  }
`;

const PassengerSelectRightSide = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  & > :first-child {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    height: 16px;
    margin-left: 16px;
  }
`;

const PassengerName = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const StyledCross = styled(Cross)`
  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileText = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

const PassengerSelectButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    SeatMapPassenger & { number: number; selected: boolean | string }
> = (props) => {
  const { isMobile } = Hooks.useMediaSizes();

  const iconPath =
    props.ageCategory === PassengerAgeCategory.Adult ? adultPath : childPath;

  const dispatch = useDispatch();
  return (
    <PassengerSelect
      {...props}
      data-cy={`passengerSelectButton_${props.number}`}
      background={props.selected ? '#E3EAFF' : '#ffffff'}
    >
      <PassengerSelectLeftSide>
        <LeftBlock>
          <img
            src={iconPath}
            alt=""
            style={{ marginRight: 5, height: isMobile ? 20 : 30 }}
          />
          <PassengerName>
            <MobileText>
              Пассажир {props.number},{' '}
              {props.ageCategoryDescription.toLocaleLowerCase()}
            </MobileText>
          </PassengerName>
        </LeftBlock>

        <DesktopText>
          Пассажир {props.number},{' '}
          {props.ageCategoryDescription.toLocaleLowerCase()}
        </DesktopText>
      </PassengerSelectLeftSide>

      <PassengerSelectRightSide>
        {!props.seat && <PassengerName>Не выбрано</PassengerName>}
        {props.seat && (
          <>
            <PassengerName>Кресло {props.seat.number}</PassengerName>
            <StyledCross
              onClick={() => dispatch(removeSeatByPassengerId(props.uid))}
            />
          </>
        )}
      </PassengerSelectRightSide>
    </PassengerSelect>
  );
};

export default PassengerSelectButton;
