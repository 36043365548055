import React from 'react';
import styled from 'styled-components';
import { RoundIcon } from '@components/loader';

const Wrapper = styled.div`
  width: 569px;
  height: 248px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Refund: React.FC = () => {
  return (
    <Wrapper>
      <RoundIcon />
    </Wrapper>
  );
};

export default Refund;
