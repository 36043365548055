import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import { TextContaner, TimeText } from './styles';
import { Helper } from '@utils';
import { PriceRangeProps, RangeChangeHandler } from './types';
import Range from './range';

const PriceRange: React.FC<PriceRangeProps> = ({
  min,
  max,
  onChange,
  values,
  ...props
}) => {
  const [valuesS, setValues] = useState(values);

  useEffect(() => {
    setValues(values);
  }, [values]);

  const formattedValues = useMemo(() => {
    return valuesS.map((val) => Helper.formatPrice(val));
  }, [valuesS]);

  const setValuesThrottled = useCallback(
    throttle((val) => {
      setValues(val);
    }, 20),
    [],
  );

  const handleChange: RangeChangeHandler = (vals) => {
    setValuesThrottled(vals);
  };
  return (
    <div>
      <TextContaner>
        <TimeText>от {formattedValues[0]}</TimeText>
        <TimeText>до {formattedValues[1]}</TimeText>
      </TextContaner>
      <Range
        {...props}
        onChange={handleChange}
        value={valuesS}
        min={min}
        max={max}
        allowCross={false}
        step={0.5}
      />
    </div>
  );
};

export default PriceRange;
