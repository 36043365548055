import React, { useMemo } from 'react';
import styled from 'styled-components';
import Chip from '@components/ui/Chip/Chip';
import { getTrainRoute, Train } from '@modules/trainTickets';
import { Helper } from '@utils';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { MainTicketInfo } from '@components/common/RouteDetail/MainTicketInfo';
import RailwayCarService from '@components/train/TrainSearch/Ticket/RailwayCarService';
import { BusForRouteDetail } from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { useMediaQuery } from 'react-responsive';

interface Props {
  train: Train;
  isFastest?: boolean;
  isCheapest?: boolean;
  noFeatures?: boolean;
  noServices?: boolean;
  noCarServices?: boolean;
  hasRoundTrip?: boolean;

  carsSelectList?(): React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function RailwayMainTicketInfo(props: Props) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { train, noServices, noFeatures } = props;
  const routeDetails = useSelector(
    (state: ApplicationState) => state.trainTickets.trainRoute
  );

  const carrierDisplayNames = useMemo(() => {
    if (train.carrierDisplayNames.length > 1) {
      return train.carrierDisplayNames.join(', ');
    } else {
      return train.carrierDisplayNames[0];
    }
  }, [train.carrierDisplayNames]);

  const fullRouteString = useMemo(() => {
    if (!train.initialStationName || !train.finalStationName) {
      return '';
    }
    return `${train.initialStationName} – ${train.finalStationName}`;
  }, [train]);

  const title = `${train.trainName ? train.trainName : ''} ${
    train.displayTrainNumber
  }
  ${train.carrierDisplayNames[0] ? ` / ${carrierDisplayNames}` : ''}
  ${fullRouteString ? ` / ${fullRouteString}` : ''}
  ${train.trainDescription}`;

  const tripDuration = Helper.formatDuration(
    moment.duration(train.tripDuration).asMinutes()
  );

  const featuresNodes = useMemo(() => {
    const result = [];
    if (props.isFastest) {
      result.push(<Chip bgColor={'green'} label={'Самый быстрый'} />);
    }
    if (props.isCheapest) {
      result.push(<Chip bgColor={'red'} label={'Самый дешёвый'} />);
    }
    if (train.hasTwoStoreyCars) {
      result.push(<Chip bgColor={'yellow'} label={'Двухэтажный'} />);
    }
    if (train.isBranded) {
      result.push(<Chip bgColor={'purple'} label={'Фирменный'} />);
    }
    if (train.hasDynamicPricingCars) {
      result.push(<Chip bgColor={'default'} label={'Эл. регистрация'} />);
    }
    if (props.hasRoundTrip) {
      result.push(<Chip bgColor={'red'} label={'-20% на обратный билет'} />);
    }
    return result;
  }, [train, props.isFastest, props.isCheapest, props.hasRoundTrip]);

  const carServicesNodes = useMemo(() => {
    return train.carServices.map((service) => (
      <RailwayCarService key={service} code={service} />
    ));
  }, [train.carServices]);

  return (
    <Wrapper>
      <MainTicketInfo
        routeType={'railway'}
        title={title}
        departureDateTimeUtc={train.departureDateTimeUtc}
        arrivalDateTimeUtc={train.arrivalDateTimeUtc}
        localDepartureDate={train.localDepartureDate}
        localArrivalDate={train.localArrivalDate}
        tripDuration={tripDuration}
        routeDetails={routeDetails}
        features={featuresNodes}
        noFeatures={noFeatures}
        noServices={noServices}
        services={carServicesNodes}
        fromStation={{
          code: train.fromStation.code,
          title: train.fromStation.name_ru,
          description: train.fromStation.description,
        }}
        toStation={{
          code: train.toStation.code,
          title: train.toStation.name_ru,
          description: train.toStation.description,
        }}
        additionalOptions={{
          isRailwayTransit: train.isTransit,
        }}
        getRoute={() => {
          dispatch(
            getTrainRoute({
              TrainNumber: train.trainNumber,
              Origin: train.fromStation.code,
              Destination: train.toStation.code,
              DepartureDate: train.localDepartureDate,
              Provider: '0',
            })
          );
        }}
      />
      {isMobile && props.carsSelectList && props.carsSelectList()}
    </Wrapper>
  );
}
