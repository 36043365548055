import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Heading = styled.h1`
  display: block;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'Open Sans';
  color: #3c3c3c;
  margin: 0 0 40px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1170px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
`;

export const Left = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 730px;
  }
`;

export const Right = styled.div<{ isSticky: boolean }>`
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    margin-left: 42px;
    width: 380px;
  }

  & > div {
    &:first-child {
      position: ${({ isSticky }) => (isSticky ? 'fixed' : 'initial')};
      top: ${({ isSticky }) => (isSticky ? '20px' : 'none')};
      box-sizing: border-box;

      @media (min-width: 768px) {
        max-width: 380px;
      }
    }
  }
`;
