import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { ReactComponent as Logo } from '@assets/media/shared/logo.svg';
import { ReactComponent as PersonOut } from '@assets/media/shared/personOut.svg';
import { ReactComponent as PersonIn } from '@assets/media/shared/personIn.svg';

import { ReactComponent as OutdoorIcon } from '@assets/media/header/headDoor.svg';
import { ReactComponent as Bars } from '@assets/media/header/bars.svg';
import roundedArrowPath from '@assets/media/header/rounded-arrow.png';
import agentAuthIcon from '@assets/media/header/agentAuthIcon.svg';

import { getUserState, logout } from '@modules/user';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { openAuthModal, AUTH_MODAL_STATUSES } from '@modules/ui';
import MobileMenu from './MobileMenu';
import HoverMenu from '@components/common/HoverMenu';
import { getAgentMainInformation } from '@modules/agents/agent-finance/duck';
import { setAuthPopup } from '@modules/booking';
import CrossIconSrc from '@assets/images/cross';

const StyledLogo = styled(Logo).attrs({ width: 68, height: 32 })`
  @media (max-width: 767px) {
    width: 57px;
    height: 27px;
  }
`;

const Wrapper = styled.div<{ staticPosition?: boolean }>`
  width: 100%;

  @media (max-width: 1024px) {
    position: ${({ staticPosition }) => (staticPosition ? 'unset' : 'sticky')};
    top: 0;
    z-index: 900;
    background: white;
    box-shadow: 0px 1px 0px #f2f2f2;
  }

  @media print {
    display: none;
  }
`;

const Container = styled.div`
  max-width: 1170px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0px;
  box-sizing: border-box;

  @media (max-width: 1210px) {
    padding: 25px 20px;
  }

  @media (max-width: 767px) {
    padding: 14px 22px;
  }
`;

const ButtonsList = styled.div`
  display: flex;

  & > a {
    margin-right: 30px;
  }

  & > :last-child {
    margin-right: 0;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 28px;
  }

  @media (max-width: 767px) {
    & > :first-child {
      margin-right: 10px;
    }
  }
`;

const ButtonListDesktopLaptop = styled(ButtonsList)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const ButtonItem = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;

  & > svg {
    margin-right: 10px;
  }
`;

const ButtonText = styled(Text)`
  font-size: 16px;
  line-height: 120%;
  color: #2e2e32;
  margin-top: 3px;
`;

const MobileDrawer = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  height: 24px;

  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #3c3c3c;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 3px;
    right: -19px;
    width: 14px;
    height: 14px;
    background-image: url('${roundedArrowPath}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: 1169px) {
    &:after {
      display: none;
    }
  }

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;

const AgentAuthIcon = styled.img.attrs({ src: agentAuthIcon })`
  margin-right: 7px;
`;

const AgentAuthButton = styled.div`
  background: #ffcd00;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px 10px;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #3c3c3c;
  margin-right: 40px;
  cursor: pointer;
  height: 27px;
`;

// const Flex = styled.div`
// dislay:flex;
// align-items:center;
// `

const Header: React.FC<{ staticPosition?: boolean; closeButton?: boolean }> = ({
  staticPosition,
  closeButton = false,
}) => {
  // const history = useHistory();
  const {
    status: { isAuthorized },
    data,
  } = useSelector(getUserState);
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuState] = React.useState(false);
  const [isMenuShown, setIsMenuShown] = React.useState(false);
  const location = useLocation();

  const onClickOutsideListener = () => {
    setIsMenuShown(false);
    document.removeEventListener('click', onClickOutsideListener);
  };

  React.useEffect(() => {
    data?.agentId && dispatch(getAgentMainInformation());
  }, [location.pathname]);

  const handleDrawerClick = () => {
    setMenuState((state) => !state);
  };

  return (
    <Wrapper
      staticPosition={staticPosition}
      onMouseLeave={() => setIsMenuShown(false)}
    >
      <Container>
        <RightSide>
          <NavLink to="/">
            <StyledLogo />
          </NavLink>

          <ExternalLink
            href={
              process.env.REACT_APP_MODE === 'test'
                ? 'https://stage.fstravel.com/ru'
                : 'https://fstravel.com/'
            }
          >
            Перейти на сайт туроператора
          </ExternalLink>
        </RightSide>
        <ButtonListDesktopLaptop>
          {isAuthorized && (
            <>
              <ButtonItem
                to="/profile/contacts"
                onMouseOver={() => setIsMenuShown(true)}
                onMouseLeave={() => {
                  document.addEventListener('click', onClickOutsideListener);
                }}
              >
                <PersonIn />{' '}
                <ButtonText>
                  Личный кабинет {!!data?.agentId && <>(агент)</>}
                </ButtonText>
              </ButtonItem>
              <ButtonItem
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(logout());
                }}
              >
                <OutdoorIcon height={24} width={24} />
                <ButtonText>Выход</ButtonText>
              </ButtonItem>
              {isMenuShown && <HoverMenu setIsMenuShown={setIsMenuShown} />}
            </>
          )}
          {!isAuthorized && (
            <>
              <AgentAuthButton
                onClick={() =>
                  window.location.replace('https://newb2b.fstravel.com/avia')
                }
              >
                <AgentAuthIcon /> Вход для агентов
              </AgentAuthButton>
              <ButtonItem
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
                }}
              >
                <PersonOut />
                <ButtonText>Войти в личный кабинет</ButtonText>
              </ButtonItem>
            </>
          )}
        </ButtonListDesktopLaptop>
        <MobileDrawer>
          {closeButton ? (
            <CrossIconSrc onClick={() => dispatch(setAuthPopup(false))} />
          ) : (
            <Bars onClick={handleDrawerClick} />
          )}
        </MobileDrawer>
      </Container>
      <MobileMenu
        open={isMenuOpen}
        onClose={() => {
          // без этого хака не закрывается меню при переходе на обращения или заказы
          setTimeout(() => {
            setMenuState(false);
          }, 500);
        }}
      />
    </Wrapper>
  );
};

export default Header;
