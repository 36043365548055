import React from 'react';
import styled from 'styled-components';


export default styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  
  @media (max-width: 1169px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
`;