import React, { ReactElement, useEffect, useState } from 'react';
import {
  Wrapper,
  Heading,
  Ticket,
  Passenger,
  Row,
  RowContainer,
  Route,
  Link,
} from './styles';
import { Props, Passenger as PassengerProps } from './interfaces';
import PlaceSelect from '@components/ui/form/PlaceSelect';
import {
  extractPassengers,
  getFreePlaces,
} from '@components/bus/Booking/components/Places/controller';
import { ReactComponent as PassengerIcon } from '@assets/media/booking/passenger.svg';
import { ReactComponent as ChildIcon } from '@assets/media/booking/child.svg';

import { findBestSeats } from './controller';
import { transformFreePlacesToOptions } from '@components/bus/Booking/controller';
import { Checkbox } from '@components/ui';
import InfoMessageForPlaces from './components/InfoMessageForPlaces';

const Places = ({
  setFieldValue,
  forward,
  backward,
  onChangePlace,
  values,
  ageInfo,
}: Props): ReactElement => {
  const [isAutoSelectPlacesForward, setIsAutoSelectPlacesForward] =
    useState<boolean>(false);
  const [isAutoSelectPlacesBackward, setIsAutoSelectPlacesBackward] =
    useState<boolean>(false);
  const autoSelectText = 'Автоматически выбрать места рядом для всех';

  const passengers = extractPassengers(values);

  const handleSetPlacesAuto = (): void => {
    if (isAutoSelectPlacesForward) {
      const availableSeatsForward = transformFreePlacesToOptions(
        forward?.freePlaces,
      )?.map((place) => place.value);
      const bestSeatsForward = findBestSeats(passengers, availableSeatsForward);
      passengers.forEach((passenger, index) => {
        const placeID = `${passenger.id}.places.forward`;
        const placeValue = bestSeatsForward?.[index] || '';

        setFieldValue(placeID, placeValue);
      });
    }

    if (isAutoSelectPlacesBackward) {
      const availableSeatsBackward = transformFreePlacesToOptions(
        backward?.freePlaces,
      )?.map((place) => place.value);
      const bestSeatsBackward = findBestSeats(
        passengers,
        availableSeatsBackward,
      );
      passengers.forEach((passenger, index) => {
        const placeID = `${passenger.id}.places.backward`;
        const placeValue = bestSeatsBackward?.[index] || '';
        setFieldValue(placeID, placeValue);
      });
    }
  };

  useEffect(handleSetPlacesAuto, [
    isAutoSelectPlacesForward,
    isAutoSelectPlacesBackward,
  ]);

  const renderPassenger =
    (direction: string) =>
    (
      { name, places, type, id }: PassengerProps,
      index: number,
    ): ReactElement => {
      const isForward = direction === 'forward';
      const options = transformFreePlacesToOptions(
        isForward ? forward.freePlaces : backward?.freePlaces,
      );
      const freePlaces = getFreePlaces(
        options,
        values,
        isForward ? 'forward' : 'backward',
      );

      return (
        <Row key={id}>
          <Passenger>
            {ageInfo[index] !== 'Ребенок' && <PassengerIcon />}
            {ageInfo[index] === 'Ребенок' && <ChildIcon />}
            <span>
              <span>{name}</span>
              <span>, {ageInfo[index]}</span>&nbsp;
              <b>
                {places?.[direction as keyof typeof places] &&
                  `- Место ${places?.[direction as keyof typeof places]}`}
              </b>
            </span>
          </Passenger>
          {freePlaces && (
            <PlaceSelect
              id={`${id}.places.${direction}`}
              label="Выбрать место"
              options={freePlaces}
              onChange={onChangePlace}
            />
          )}
        </Row>
      );
    };

  return (
    <Wrapper>
      <Heading>Выбор мест</Heading>
      <Ticket>
        <Route>
          Туда: <Link>{forward.direction}</Link>
        </Route>
        {!forward.isChosePlaceNoTAvailable && (
          <RowContainer>
            {passengers?.map(renderPassenger('forward'))}
          </RowContainer>
        )}
        <Checkbox
          type="squar"
          label={autoSelectText}
          checked={isAutoSelectPlacesForward}
          name={'autoSelectCheckboxForward'}
          onChange={(event) => {
            setIsAutoSelectPlacesForward(event.target.checked);
          }}
        />
        {forward.isChosePlaceNoTAvailable && <InfoMessageForPlaces />}
      </Ticket>

      {backward !== undefined && (
        <Ticket>
          <Route>
            Обратно: <Link>{backward.direction}</Link>
          </Route>
          {!backward.isChosePlaceNoTAvailable && (
            <RowContainer>
              {passengers?.map(renderPassenger('backward'))}
            </RowContainer>
          )}
          <Checkbox
            type="squar"
            label={autoSelectText}
            checked={isAutoSelectPlacesBackward}
            name={'autoSelectCheckboxBackward'}
            onChange={(event) => {
              setIsAutoSelectPlacesBackward(event.target.checked);
            }}
          />
          {backward.isChosePlaceNoTAvailable && <InfoMessageForPlaces />}
        </Ticket>
      )}
    </Wrapper>
  );
};

export default Places;
