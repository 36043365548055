import React from 'react';
import styled from 'styled-components';
import Notification from './Notification';
import { Helper } from '@utils';
import {
  AviaDefaultFilter,
  AviaDefaultFilterKeys,
} from '@modules/main';
import { Button } from '@components/ui';
import {
  TrainDefaultFilter,
  TrainDefaultFilterKeys,
} from '@modules/trainTickets';

interface Props {
  itemsCount: number;
  isDefaultFilter: AviaDefaultFilter | TrainDefaultFilter;
  resetFilterItem: (key: string) => void;
  resetFilter: () => void;
  source: 'avia' | 'train';
}

const ResetFilterList = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  & > div {
    margin-right: 24px;
  }

  & > :last-child {
    margin-right: 0;
  }

  flex-wrap: wrap;
`;

const FilterItem = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-right: 8px;
  color: #3c3c3c;
`;

const CancelButton = styled.button`
  background: #3c3c3c;
  width: 24px;
  height: 24px;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 1px;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const FilteredListEmpty: React.FC<Props> = (props) => {
  const { isDefaultFilter, itemsCount } = props;

  return (
    <Notification
      subTitle="Может, какой-то из них не так важен и можно его отменить?"
      title={`Мы нашли ${itemsCount} ${Helper.pluralWord(
        itemsCount,
        `${props.source === 'avia' ? 'рейс' : 'поезд'}`,
        `${props.source === 'avia' ? 'рейса' : 'поезда'}`,
        `${props.source === 'avia' ? 'рейсов' : 'поездов'}`
      )}, но ни один не соответствует заданным фильтрам`}
    >
      <div style={{ textAlign: 'center' }}>
        <ResetFilterList>
          {Object.entries(isDefaultFilter)
            .filter(([_, val]) => {
              return !val;
            })
            .map(([key, val]) => {
              return (
                <FilterItem key={key}>
                  <Text>
                    {transformKeyToString(
                      key as AviaDefaultFilterKeys | TrainDefaultFilterKeys
                    )}
                  </Text>{' '}
                  <CancelButton
                    onClick={() => {
                      props.resetFilterItem(key);
                    }}
                  />
                </FilterItem>
              );
            })}
        </ResetFilterList>
        <Button
          onClick={() => {
            props.resetFilter();
          }}
          color="yellow"
        >
          Сбросить все фильтры
        </Button>
      </div>
    </Notification>
  );
};

function transformKeyToString(
  key: AviaDefaultFilterKeys | TrainDefaultFilterKeys
) {
  switch (key) {
    // avia
    case 'airport':
      return 'Аэропорты';
    case 'prices':
      return 'Цена билета';
    case 'transfers':
      return 'Пересадки';
    case 'airlines':
      return 'Авиакомпании';
    case 'transferDuration':
      return 'Длительность пересадки';
    case 'flightDuration':
      return 'Время в пути';
    case 'time':
      return 'Время вылета и прибытия';
    case 'flightTypes':
      return 'Тип рейса';
    case 'baggage':
      return 'Багаж';
    // train
    case 'carTypes':
      return 'Тип вагона';
    case 'carriers':
      return 'Перевозчик';
    case 'times':
      return 'Время отправления и прибытия';
    case 'discounts':
      return 'Льготный проезд';
    case 'trainDurations':
      return 'Время в пути';
    case 'trainNames':
      return 'Название поезда';
    case 'trainServices':
      return 'Услуги';
    case 'trainTypes':
      return 'Тип поезда';
    default:
      return key;
  }
}

export default FilteredListEmpty;
