import _ from 'lodash';
import moment from 'moment';
import { ReducerStateRefund } from '../normalizer';
import { RefundReason, refundReasons } from '../static/Refund';
import { RefundSelectOption } from '../model/useCreateFormRefund';
import {
  GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
  GetRefundAmountDTO,
} from '@modules/appeals';
import { RefundTypeCode } from '../CreateFormRefund';
import { AncillaryServiceType } from '@app/modules/booking';
import { PositionAwailableReason } from '@app/components/order/Detail/OrderPositions/Item/types';
import { OrderDetailState, RefundState } from '@app/modules/orders';

export const initialSelect = { label: '', value: '', code: '' };

export const getInitialReason = () => {
  const params = window.location.search;
  const reason = params.includes('reason')
    ? params.slice(params.indexOf('?reason') + 8)
    : undefined;
  const initialReason = reason
    ? refundReasons.find((el) => el.value === reason)!
    : initialSelect;
  return initialReason;
};

export function reducer(state: any, action: any): any {
  const Stater = new StateUpdater();
  switch (action.type) {
    case 'setRefundType': {
      const refundType =
        action.payload.value === RefundReason.SERVICE
          ? RefundTypeCode.AVIA_EMDS
          : RefundTypeCode.AVIA;
      return Stater.consumeState(state).setRefundType(refundType).getValue();
    }
    case 'addTicket': {
      const SELECTED_TICKETS = state.tickets.map((ticket: any) => ({
        ...ticket,
        checked: ticket.value === action.payload ? true : ticket.checked,
      }));
      return Stater.consumeState(state).setTickets(SELECTED_TICKETS).getValue();
    }
    case 'removeTicket': {
      const SELECTED_TICKETS = state.tickets.map((ticket: any) => ({
        ...ticket,
        checked: ticket.value === action.payload ? false : ticket.checked,
      }));
      return Stater.consumeState(state).setTickets(SELECTED_TICKETS).getValue();
    }
    case 'addAllTicket': {
      const SELECTED_TICKETS = state.tickets.map((ticket: any) => ({
        ...ticket,
        checked: true,
      }));
      return Stater.consumeState(state).setTickets(SELECTED_TICKETS).getValue();
    }
    case 'updateTickets': {
      const SELECTED_TICKETS = action.payload;
      return Stater.consumeState(state).setTickets(SELECTED_TICKETS).getValue();
    }
    case 'addService': {
      const SELECTED_SERVICES = state.services.map((service: any) => ({
        ...service,
        checked: service.value === action.payload ? true : service.checked,
      }));
      return Stater.consumeState(state)
        .setServices(SELECTED_SERVICES)
        .getValue();
    }
    case 'removeService': {
      const SELECTED_SERVICES = state.services.map((service: any) => ({
        ...service,
        checked: service.value === action.payload ? false : service.checked,
      }));
      return Stater.consumeState(state)
        .setServices(SELECTED_SERVICES)
        .getValue();
    }
    case 'addAllService': {
      const SELECTED_SERVICES = state.services.map((service: any) => ({
        ...service,
        checked: true,
      }));
      return Stater.consumeState(state)
        .setServices(SELECTED_SERVICES)
        .getValue();
    }
    case 'updateEmds': {
      const SELECTED_SERVICES = action.payload;
      return Stater.consumeState(state)
        .setServices(SELECTED_SERVICES)
        .getValue();
    }
    case 'resetPositions': {
      const SELECTED_TICKETS = state.tickets.map((ticket: any) => ({
        ...ticket,
        checked: false,
      }));
      const SELECTED_SERVICES = state.services.map((service: any) => ({
        ...service,
        checked: false,
      }));
      return Stater.consumeState(state)
        .setTickets(SELECTED_TICKETS)
        .setServices(SELECTED_SERVICES)
        .getValue();
    }
    default:
      throw new Error();
  }
}

class StateUpdater {
  private state: ReducerStateRefund = null as any;

  consumeState(state: ReducerStateRefund) {
    this.state = { ...state };
    return this;
  }

  setRefundType(args: RefundTypeCode) {
    this.state.refundType = args;
    return this;
  }

  setTickets(args: string[]) {
    this.state.tickets = args;
    return this;
  }

  setServices(args: string[]) {
    this.state.services = args;
    return this;
  }

  updateTickets(args: string[]) {
    this.state.tickets = args;
    return this;
  }

  getValue() {
    return this.state;
  }
}

export const pickEmdsForTickets = (
  tickets: RefundSelectOption[],
  refundEmds?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket[]
    | null,
) => {
  const result: {
    data: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket;
  }[] = [];

  if (!refundEmds) return result;

  const documentNumbers = tickets.map(
    (ticket: {
      data: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket;
    }) => ticket.data.passengerDocumentNumber,
  );

  refundEmds.forEach(
    (emd: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket) => {
      if (documentNumbers.includes(emd.passengerDocumentNumber))
        result.push({ data: emd });
    },
  );

  return result;
};

export const filterRefundReasons = (isInvoluntry?: boolean) =>
  refundReasons.filter((reason) => {
    if (isInvoluntry) {
      return reason.value !== RefundReason.FREE;
    } else {
      return reason.value !== RefundReason.CANCEL;
    }
  });

export const pickDefaultReason = () =>
  refundReasons.filter(
    (reason) =>
      reason.value === RefundReason.OTHER ||
      reason.value === RefundReason.HEALTH,
  );

export function formatDate(dateString?: Date) {
  return moment(dateString).format('D MMM HH:mm');
}

export const pickAncillaryLabel = (
  orderDetail: OrderDetailState,
  type?: string | null,
) => {
  switch (type) {
    case 'ExtraBag':
      return `Дополнительный багаж до ${getMass(orderDetail, type)}кг`;
    case AncillaryServiceType.Meal:
      return 'Питание';
    default:
      return '';
  }
};

export const getMass = (orderDetail: OrderDetailState, type: string) => {
  const position = orderDetail.orderPositions[0].luggages[0].items.find(
    (luggage) => luggage.type === type,
  );
  return position?.value || 0;
};

export const pickAncillaryType = (name?: string | null) => {
  if (name?.includes('Baggage')) return 'Багаж';
  if (name?.includes(AncillaryServiceType.Meal)) return 'Питание';
  return '';
};

export const findPassanger = (
  refundAmount: RefundState,
  position: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
) => {
  const passangers = refundAmount.data.refundPassengers || [];

  return passangers.find(
    (passanger) =>
      passanger.documentNumber === position.passengerDocumentNumber,
  );
};

export const findAncillary = (
  refundAmount: RefundState,
  emd: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
) =>
  (refundAmount.data?.ancillaryServices &&
    refundAmount.data?.ancillaryServices.find(
      (service) => service.aviaSegmentIds?.[0] === emd.aviaSegmentIds?.[0],
    )) ||
  {};

export const findSegment = (
  refundAmount: RefundState,
  emd: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
) =>
  refundAmount.data?.aviaSegments.find(
    (segment) => segment.aviaSegmentId === emd.aviaSegmentIds?.[0],
  );

export const getPassangerFullName = (passanger?: {
  firstName?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
}) => {
  return `${passanger?.firstName || ''}${
    passanger?.firstName ? ' ' + passanger?.lastName || '' : ''
  }${passanger?.lastName ? passanger?.patronymic || '' : ''}`;
};

export const filterPositionsByReason = (
  position: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
  initialReason: { value: string },
) => {
  return (
    (initialReason.value === RefundReason.FREE &&
      (position.reasonCode === PositionAwailableReason.FULL_REFUND ||
        position.reasonCode === PositionAwailableReason.FULL_REFUND_SPLIT ||
        position.reasonCode === PositionAwailableReason.PARTIAL_REFUND)) ||
    (initialReason.value === RefundReason.CANCEL &&
      (position.reasonCode === PositionAwailableReason.FULL_REFUND ||
        position.reasonCode ===
          PositionAwailableReason.FULL_REFUND_INVOLUNTARY ||
        position.reasonCode ===
          PositionAwailableReason.PARTIAL_REFUND_INVOLUNTARY)) ||
    (initialReason.value === RefundReason.SERVICE &&
      position.reasonCode === PositionAwailableReason.PARTIAL_REFUND)
  );
};
