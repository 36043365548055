import React from 'react';
import styled from 'styled-components';
import { Button } from '@components/ui';
import { usePopup } from './usePopup';

const Wrapper = styled.div`
  min-height: 71px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  padding: 10px 20px 20px;
  align-self: flex-end;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)`
  width: 90%;
  margin: 0 auto;
`;

const Footer: React.FC = () => {
  const { onClose } = usePopup();
  return (
    <Wrapper>
      <StyledButton onClick={onClose}>Применить</StyledButton>
    </Wrapper>
  );
};

export default Footer;
