import React from 'react';

const Cross: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4022 14.9038C15.3325 14.9737 15.2498 15.0291 15.1587 15.0669C15.0676 15.1047 14.9699 15.1241 14.8712 15.1241C14.7726 15.1241 14.6749 15.1047 14.5838 15.0669C14.4927 15.0291 14.4099 14.9737 14.3402 14.9038L8.2499 8.81198L2.15958 14.9038C2.01875 15.0446 1.82774 15.1238 1.62858 15.1238C1.42942 15.1238 1.23841 15.0446 1.09758 14.9038C0.956749 14.763 0.877632 14.572 0.877632 14.3728C0.877632 14.1736 0.956749 13.9826 1.09758 13.8418L7.7189 7.22048C7.78857 7.15064 7.87133 7.09523 7.96245 7.05742C8.05357 7.01961 8.15125 7.00014 8.2499 7.00014C8.34855 7.00014 8.44623 7.01961 8.53735 7.05742C8.62847 7.09522 8.71123 7.15064 8.7809 7.22048L15.4022 13.8418C15.4721 13.9115 15.5275 13.9942 15.5653 14.0854C15.6031 14.1765 15.6226 14.2742 15.6226 14.3728C15.6226 14.4715 15.6031 14.5691 15.5653 14.6603C15.5275 14.7514 15.4721 14.8341 15.4022 14.9038Z"
        fill="#4872F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09778 0.22034C1.16745 0.150495 1.25021 0.0950809 1.34133 0.0572712C1.43245 0.0194615 1.53013 -5.70599e-08 1.62878 -6.56843e-08C1.72743 -7.43086e-08 1.82511 0.0194615 1.91623 0.0572712C2.00735 0.0950808 2.09011 0.150495 2.15978 0.22034L8.2501 6.31216L14.3404 0.220339C14.4813 0.0795092 14.6723 0.000391325 14.8714 0.000391309C15.0706 0.000391293 15.2616 0.0795091 15.4024 0.220339C15.5433 0.361169 15.6224 0.552176 15.6224 0.751339C15.6224 0.950503 15.5433 1.14151 15.4024 1.28234L8.7811 7.90366C8.71143 7.97351 8.62867 8.02892 8.53755 8.06673C8.44643 8.10454 8.34875 8.124 8.2501 8.124C8.15145 8.124 8.05377 8.10454 7.96265 8.06673C7.87153 8.02892 7.78877 7.97351 7.7191 7.90366L1.09778 1.28234C1.02794 1.21267 0.972522 1.12991 0.934713 1.03879C0.896903 0.947673 0.877441 0.849992 0.877441 0.751341C0.877441 0.65269 0.896903 0.555008 0.934713 0.46389C0.972522 0.372772 1.02794 0.290009 1.09778 0.22034Z"
        fill="#4872F2"
      />
    </svg>
  );
};

export default Cross;
