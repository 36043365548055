import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Train } from '@modules/trainTickets';
import RailwayRouteDetailItem from '@components/train/RouteDetail/RailwayRouteDetailItem';

interface Props {
  trainForward?: Train;
  trainBackward?: Train;
  forwardCarNumber: string;
  backwardCarNumber?: string;
  forwardChosenPlaces: string[];
  backwardChosenPlaces: string[];
  instance: 'order' | 'booking';
}

const Wrapper = styled(InfoBlock)<{ instance: 'booking' | 'order' }>`
  border: ${({ instance }) =>
    instance === 'order' ? '0' : '1px solid rgb(220, 220, 220)'};
  box-shadow: ${({ instance }) =>
    instance === 'order' ? 'none' : 'rgba(0, 0, 0, 0.05) 0px 5px 10px'};
  @media (min-width: 1025px) {
    padding-bottom: ${({ instance }) => (instance === 'order' ? '0' : '24px')};
  }
  @media (max-width: 1024px) {
    padding-top: ${({ instance }) => (instance === 'order' ? '0' : '24px')};
  }
  @media (max-width: 767px) {
    padding: 0px 14px 20px;
  }
`;

const Header = styled.h2`
  margin-top: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  @media (max-width: 767px) {
    padding-top: 20px;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 24px 20px;
  height: 1px;
  width: 100%;
  background: #dcdcdc;

  @media (max-width: 767px) {
    margin-block: 20px;
  }
`;

const RailwayRouteDetailBlock: React.FC<Props> = (props) => {
  const {
    trainBackward,
    trainForward,
    forwardCarNumber,
    backwardCarNumber,
    forwardChosenPlaces,
    backwardChosenPlaces,
    instance,
  } = props;

  const backwardRouteIsExist =
    !!trainBackward &&
    !!backwardCarNumber &&
    !!backwardChosenPlaces &&
    !!backwardChosenPlaces.length;

  return (
    <Wrapper instance={instance}>
      {instance === 'booking' && <Header>Детали поездки</Header>}
      {trainForward?.index !== null && (
        <RailwayRouteDetailItem
          carPlaces={forwardChosenPlaces}
          train={trainForward}
          carNumber={forwardCarNumber}
          direction={'forward'}
        />
      )}
      {backwardRouteIsExist && (
        <>
          <Divider />
          <RailwayRouteDetailItem
            carPlaces={backwardChosenPlaces}
            train={trainBackward}
            carNumber={backwardCarNumber}
            direction={'backward'}
          />
        </>
      )}
    </Wrapper>
  );
};
export default RailwayRouteDetailBlock;
