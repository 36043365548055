import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Text } from '@components/ui';
import AviaServices from '../AddictionalServices/AviaServices';
import { Enums } from '../AddictionalServices/types';
import {
  addictionalServicesSelector,
  getBookingAncillaryServicesState,
  getSelectedFare,
  getSeatMapState,
  findEmptyPlace,
  setActiveSegment,
} from '@modules/booking';
import { useSelector, useDispatch } from 'react-redux';
import {
  ServiceRulesModal as Modal,
  SeatMapNotificationModal,
} from '@components/common/Modals';
import { useModalState } from '../AddictionalServices/context';
import SeatMapService, {
  SeatMapModal,
} from '../AddictionalServices/SeatMapService';
import SeatMapDetailModal from '../AddictionalServices/SeatMapService/SeatMapDetailModal';

const Wrapper = styled(InfoBlock)`
  border: none;
  box-shadow: none;
  padding: 0;
  margin-top: 5px;
  width: 100%;
  margin-top: 30px;
  @media (min-width: 767px) {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
    margin-left: 5px;
  }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (min-width: 767px) {
    & > div {
      margin-bottom: 30px;
    }
  }
`;

export default function AviaServicesMobile() {
  const items = useSelector(addictionalServicesSelector);
  const services = useSelector(getBookingAncillaryServicesState);

  const seatMap = useSelector(getSeatMapState);
  const fare = useSelector(getSelectedFare);
  const dispatch = useDispatch();
  const { visibility, hideModal, showModal } = useModalState();
  const [notification, setNotification] = React.useState(false);

  const segmentsList = fare.seatMap.map((x) => x.flightNo);

  return (
    (!!services.length || seatMap.available) && (
      <Wrapper>
        <Title>Для комфортного полета</Title>
        <SubTitle>
          Насладитесь в полной мере Вашим перелётом с помощью выбора авиа услуг.{' '}
        </SubTitle>
        <List>
          <AviaServices />
          {fare.seatMap.length > 0 && <SeatMapService />}
        </List>
        <Modal
          open={
            visibility !== null &&
            visibility !== Enums.SeatMap &&
            visibility !== Enums.SeatMapDetails &&
            visibility !== Enums.Ancillary &&
            visibility !== Enums.AncillaryDetails
          }
          onClose={hideModal}
          type={visibility}
        />
        {/* <LuggageServiceModal open /> */}
        {seatMap.available && (
          <>
            <SeatMapModal
              open={visibility === Enums.SeatMap}
              onClose={() => {
                if (
                  seatMap.availableSeatCount > 0 &&
                  seatMap.selectedSeatCount > 0 &&
                  visibility !== Enums.SeatMapDetails &&
                  visibility !== Enums.Ancillary &&
                  visibility !== Enums.AncillaryDetails
                ) {
                  setNotification(true);
                }
                if (visibility !== Enums.SeatMapDetails) {
                  hideModal();
                  dispatch(setActiveSegment(' ' + segmentsList[0]));
                }
              }}
              fare={fare}
            />
            <SeatMapDetailModal
              open={visibility === Enums.SeatMapDetails}
              onClose={() => {
                if (
                  visibility !== Enums.SeatMap &&
                  visibility !== Enums.Ancillary &&
                  visibility !== Enums.AncillaryDetails
                ) {
                  setNotification(false);

                  hideModal();
                  showModal(Enums.SeatMap);
                }
              }}
              fare={fare}
            />
            <SeatMapNotificationModal
              data={seatMap.segmentsAccessibility}
              title="Выбраны не все места"
              subtitle="Не забудьте выбрать ваши места на перелетах"
              buttonsText={['Выбрать места', 'Закончить выбор мест']}
              onConfirm={() => {
                setNotification(false);
                dispatch(findEmptyPlace());
                showModal(Enums.SeatMap);
              }}
              onClose={() => {
                setNotification(false);
                dispatch(setActiveSegment(' ' + segmentsList[0]));
              }}
              open={notification}
            />
          </>
        )}
      </Wrapper>
    )
  );
}
