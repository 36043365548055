import { DefaultTheme, createGlobalStyle } from 'styled-components';

enum BREAKPOINTS {
  mobile = '375px',
  tablet = '768px',
  hd = '1024px',
  fullHD = '1920px',
}

export const MainTheme: DefaultTheme = {
  borderRadius: '4px',
  breakpoints: {
    mobile: BREAKPOINTS.mobile,
    tablet: BREAKPOINTS.tablet,
    hd: BREAKPOINTS.hd,
    fullHD: BREAKPOINTS.fullHD,
  },
  colors: {
    main: '#4872F2',
    gray: '#B9B9B9',
    lightGray: '#DCDCDC',
    darkGray: '#737373',
    blackGray: '#3C3C3C',
    white: '#FFFFFF',
    red: '#E73C3E',
    blue: '#4872F2',
    lightBlue: '#EDF1FE',
    yellow: '#FFE100',
    black: '#000000',
  },
  fonts: {
    bold: 'OpenSans-Bold',
    semiBold: 'OpenSans-SemiBold',
    regular: 'Open Sans',
  },
  max: (breakpoint) => `@media (max-width: ${breakpoint})`,
  min: (breakpoint) => `@media (min-width: ${breakpoint})`,
};

//TODO: допиши брейкпоинты по аналогии с useMedia/chrome dev tools

export const GlobalStyles = createGlobalStyle`
body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
strong  {
  font-weight: inherit;
}

input {
  margin: 0;
  padding: 0;
  outline: none;
  border-radius: none;
}
`;
