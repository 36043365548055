import { all, takeLatest, call, put, select } from 'typed-redux-saga';
import * as AgentsManager from './Manager';
import { Action } from 'redux-act';
import {
  getAgentUserRolesRequest,
  getAgentUserRolesSuccess,
  getAgentUsersRequest,
  getAgentUsersSuccess,
  addAgentUsersRequest,
  addAgentUsersSuccess,
  setRoleToUserRequest,
  setRoleToUserSuccess,
  setAgentUsersRequest,
  setAgentUsersSuccess,
  createUserInSamoRequest,
  getAgentLogRequest,
  getAgentLogSuccess,
  getUsefulInfoRequest,
} from '@modules/agents/agent-users/duck';
import {
  GeneratedAssignUsersToAgentRequest,
  GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserRoleRequest,
  GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserActiveRequest,
  GeneratedTuiOrderServiceApiRequestsAddSimpleUserRequest,
  // GeneratedTuiOrderServiceApiDomainModelUserResponsesSetUserActiveResponse,
} from './types';
import { getAgentSiginState } from '../agent-finance/selectors';
import { UserCookie } from '@services/UserCookies';

function* getAgentUserRolesSaga() {
  try {
    const result = yield* call(AgentsManager.getAgentUserRoles);
    yield put(getAgentUserRolesSuccess(result));
  } catch (err) {
    // console.log(err);
  }
}

function* getAgentUsersSaga(action: Action<{ AgentId?: string }>) {
  const { payload } = action;

  try {
    const result = yield* call(AgentsManager.getAgentUsers, payload.AgentId);
    yield put(getAgentUsersSuccess(result));
  } catch (err) {
    // console.log(err);
  }
}

function* addAgentUsersSaga(
  action: Action<GeneratedAssignUsersToAgentRequest>
) {
  try {
    const result = yield* call(AgentsManager.addAgentUser, action.payload);
    yield* put(addAgentUsersSuccess(result));
  } catch (err) {
    //
  }
}

function* createUserInSamoSaga(
  action: Action<GeneratedTuiOrderServiceApiRequestsAddSimpleUserRequest>
) {
  const { payload } = action;
  try {
    yield call(AgentsManager.createUserInSamo, payload);
  } catch (err) {
    //
  }
}

function* setRoleToUserSaga(
  action: Action<GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserRoleRequest>
) {
  const { payload } = action;
  try {
    yield* call(AgentsManager.setRoleToUser, payload);
    yield* put(setRoleToUserSuccess(true));
  } catch (err) {
    //
  }
}

function* setAgentUsersSaga(
  action: Action<GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserActiveRequest>
) {
  const { payload } = action;
  const agent = yield* select(getAgentSiginState);

  try {
    //@ts-ignore
    const result = yield* call<any>(AgentsManager.setAgentUsers, payload);
    yield* put(setAgentUsersSuccess(result));
    const update = yield* call(AgentsManager.getAgentUsers, agent?.agentId);
    yield* put(getAgentUsersSuccess(update));
  } catch (err) {
    //
  }
}

function* getAgentLogSaga(action: Action<{ AgentId?: string }>) {
  try {
    const response = yield* call(
      AgentsManager.getAgentLog,
      action.payload.AgentId
    );
    yield* put(getAgentLogSuccess(response));
  } catch (err) {
    //
  }
}

function* getUsefulInfoSaga() {
  try {
    yield* call(AgentsManager.getUsefulInfo, UserCookie.token);
  } catch (err) {
    //
  }
}

export default function* rootSaga() {
  yield* all([
    takeLatest(getAgentUserRolesRequest.getType(), getAgentUserRolesSaga),
    takeLatest(getAgentUsersRequest.getType(), getAgentUsersSaga),
    takeLatest(addAgentUsersRequest.getType(), addAgentUsersSaga),
    takeLatest(createUserInSamoRequest.getType(), createUserInSamoSaga),
    takeLatest(setRoleToUserRequest.getType(), setRoleToUserSaga),
    takeLatest(setAgentUsersRequest.getType(), setAgentUsersSaga),
    takeLatest(getAgentLogRequest.getType(), getAgentLogSaga),
    takeLatest(getUsefulInfoRequest.getType(), getUsefulInfoSaga),
  ]);
}
