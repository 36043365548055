import { TicketMessage } from './types/types';
import { TicketType } from '@modules/main';

export function getNormalizeMessages(messages: TicketMessage[]) {
  return messages.reverse().map(({ authorName, ...message }) => {
    return {
      id: message.id,
      text: message.text,
      date: message.createdDate,
      owner: message.isOwner,
      status: message.status.name,
      author: {
        name: authorName,
      },
      files: message.files,
    };
  });
}

export function getAppealType(ticketType: TicketType[]) {
  if (ticketType.length > 1) {
    return 'Комбо-билет';
  } else {
    if (ticketType[0] === 'AviationOrder') return 'Авиабилет';
    if (ticketType[0] === 'RailwayOrder') return 'ЖД билет';
    if (ticketType[0] === 'BusOrder') return 'Автобусный билет';
  }
}
