import React from 'react';
import styled from 'styled-components';

import FilterList from '../FilterTrainsList';
import { useDispatch } from 'react-redux';
import { resetTrainFilter } from '@modules/trainTickets';
import ResetButton from '@components/flightsSearch/Filter/Desktop/ResetButton';
import { useCurrentTrainFilter } from '@modules/trainTickets/utils';
import { useMediaQuery } from 'react-responsive';

const StyledFilterList = styled(FilterList)`
  width: 255px;
  flex-shrink: 0;
  margin-right: 26px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 14px;
    //height: calc(var(--vh, 1vh) * 100 - 312px);
    overflow-y: auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 14px;
    height: calc(var(--vh, 1vh) * 100 - 312px);
    overflow-y: auto;
  }
`;

const TemplateTrain = () => {
  const dispatch = useDispatch();
  const activeFilter = useCurrentTrainFilter();
  const needHideResetButton = useMediaQuery({ maxWidth: 1024 });
  const filterIsDefault = Object.values(activeFilter.isDefault).every(
    (value) => value
  );

  const onResetButtonClick = () => {
    dispatch(
      resetTrainFilter({
        value: null,
        type: activeFilter.filterDestinationType,
      })
    );
  };

  return (
    <StyledFilterList filter={activeFilter}>
      {!filterIsDefault && !needHideResetButton && (
        <ResetButton
          onClick={() => {
            onResetButtonClick();
          }}
        />
      )}
    </StyledFilterList>
  );
};

export default TemplateTrain;
