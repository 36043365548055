import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { Helper } from '@utils';

const List = styled.div`
  & > ${Text} {
    display: block;
    margin-bottom: 5px;
  }
  & > :nth-child(3n) {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Row = styled(Text)`
    color: #737373;
    ${Text} {
        color: #3C3C3C
    }
  }
`;

interface MobileAdditionalDescriptionProps {
  className?: string;
  airplane: string;
  terminalA: string;
  terminalB: string;
  airlane: string;
  operatingAirlane: string;
  duration: number;
  luggage: number | string;
  transfersCount: number;
}

const MobileAdditionalDescription: React.FC<
  MobileAdditionalDescriptionProps
> = (props) => {
  const {
    airplane,
    terminalA,
    terminalB,
    airlane,
    operatingAirlane,
    duration,
    luggage,
    transfersCount,
  } = props;

  return (
    <div className={props.className}>
      <List>
        <Row color="dark-gray">
          Рейс: <Text>{airplane}</Text>
        </Row>
        <Row color="dark-gray">
          Время в пути: <Text>{Helper.formatDuration(duration)}</Text>
        </Row>
        <Row color="dark-gray">
          Багаж:{' '}
          <Row>{typeof luggage === 'string' ? luggage : `до ${luggage}кг`}</Row>
        </Row>
        <Row>
          Авиакомпания: <Text>{airlane}</Text>
        </Row>
        <Row>
          Рейс выполняет: <Text>{operatingAirlane}</Text>
        </Row>
        <Row>
          Терминалы{' '}
          <Text>
            {' '}
            {terminalA} - вылет, {terminalB} - прилет{' '}
          </Text>
        </Row>

        {transfersCount !== 0 && (
          <Row color="dark-gray">
            Пересадки:{' '}
            <Text fontWeight="600" color="black">
              {transfersCount}
            </Text>
          </Row>
        )}
      </List>
    </div>
  );
};

export const MobileAdditionalDescriptionMini: React.FC<{
  luggage: string | number;
  duration: number;
}> = (props) => {
  const { duration, luggage } = props;

  return (
    <div>
      <List>
        <Row color="dark-gray">
          Время в пути: <Text>{Helper.formatDuration(duration)}</Text>
        </Row>
        <Row color="dark-gray">
          Багаж:{' '}
          <Row>{typeof luggage === 'string' ? luggage : `до ${luggage}кг`}</Row>
        </Row>
      </List>
    </div>
  );
};

export default MobileAdditionalDescription;
