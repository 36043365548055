import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import voucherPlane from '@assets/media/tariff-features/voucherPlane.svg';
import voucherInfo from '@assets/media/tariff-features/voucherInfo.svg';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';

import { Text, Button } from '@components/ui';
import { stickyFooterHide } from '@modules/orders';
import { useMediaQuery } from 'react-responsive';

const StyledPopup = styled(Popup)`
  position: absolute;
  &-content {
    position: absolute;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border-radius: 8px;
    width: 630px;
    padding: 20px;
    height: 600px;

    @media (max-width: 767px) {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      border-radius: 0;
      padding: 20px;
    }
  }

  &-overlay {
    z-index: 1001 !important;
  }
`;

const PopupHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 21px;
  top: 17px;
  outline: none;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const VoucherPlane = styled.img.attrs({ src: voucherPlane })`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

const VoucherInfo = styled.img.attrs({ src: voucherInfo })`
  margin-right: 7px;
  width: 20px;
  height: 20px;
`;

const BodyText = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const VoucherBlueBlock = styled.div`
  background: #edf1fe;
  padding: 20px 30px 20px 20px;
  border-radius: 8px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c3c3c;
  margin-top: 20px;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const StyledNavLink = styled(NavLink)`
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  text-decoration: none;
  color: #4460ef;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 20px;
  }
`;

const StyledButton = styled(Button)`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
`;

const Thumb = styled.div`
  width: 1px;
  background-color: ${({ theme: { colors } }) => colors.main};
`;
const Track = styled.div`
  height: 100%;
  top: 0;
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.lightGray};
`;

interface Props {
  isVoucherOpen: boolean;
  setIsVoucherOpen: Dispatch<SetStateAction<boolean>>;
}

const VoucherModal: React.FC<Props> = ({ isVoucherOpen, setIsVoucherOpen }) => {
  const ref = React.useRef(null as null | Scrollbars);
  const [refVisible, setRefVisible] = React.useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  React.useEffect(() => {
    if (isVoucherOpen) {
      document.body.style.overflow = 'hidden';
      dispatch(stickyFooterHide(true));
    }
    return () => {
      dispatch(stickyFooterHide(false));

      document.body.style.overflow = 'auto';
    };
  }, [isVoucherOpen]);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollToTop();
    }
  }, [ref.current, refVisible]);

  return (
    <StyledPopup
      open={isVoucherOpen}
      onClose={() => {
        setIsVoucherOpen(false);
        dispatch(stickyFooterHide(false));
        setRefVisible(false);
      }}
    >
      <Scrollbars
        ref={(sliderRef) => {
          ref.current = sliderRef;
          setRefVisible(true);
        }}
        hideTracksWhenNotNeeded
        renderThumbVertical={({ style, ...props }) => (
          <Thumb {...props} style={{ ...style, width: 2, right: 1 }} />
        )}
        renderTrackVertical={({ style, ...props }) => (
          <Track {...props} style={{ ...style, width: 1 }} />
        )}
      >
        <Cross onClick={() => setIsVoucherOpen(false)} />
        <PopupHead>
          <Title>
            <VoucherPlane />
            <TitleText>Возврат в виде ваучера</TitleText>
          </Title>
        </PopupHead>
        <BodyText>
          По правилам авиакомпании возврат билетов осуществляется только в виде
          ваучера. Как это работает:
        </BodyText>
        <VoucherBlueBlock>
          <InfoRow>
            <VoucherInfo /> Если вы совершаете возврат билетов, на ваше имя
            будет выписан ваучер, на который будет зачислена сумма, равная
            стоимости билета, за вычетом сервисного сбора.
          </InfoRow>
          <InfoRow>
            <VoucherInfo /> Cрок действия ваучера - 1 год с момента покупки
            билета, по которому был произведен возврат.
          </InfoRow>
          <InfoRow>
            <VoucherInfo />
            Eсли стоимость нового билета будет превышать лимит ваучера, нужно
            будет доплатить разницу.
          </InfoRow>
          <InfoRow>
            <VoucherInfo /> Ваучер может быть использован только на покупку
            билета той же авиакомпании, которая выдала ваучер.
          </InfoRow>
          <InfoRow>
            <VoucherInfo /> Ваучером может воспользоваться только тот человек,
            на чье имя он был выпущен. Также возможно будет переоформить билет
            на другого человека, при этом авиакомпания взимает штраф в размере
            350 дирхамов по текущему курсу.
          </InfoRow>
          <InfoRow style={{ marginBottom: 0 }}>
            <VoucherInfo />
            <Text>
              Воспользоваться ваучером можно, создав обращение по заказу в
              личном кабинете клиента на сайте{' '}
              <StyledNavLink to="/" style={{ marginLeft: 0 }}>
                avia-new.fstravel.com
              </StyledNavLink>{' '}
            </Text>
          </InfoRow>
        </VoucherBlueBlock>
        <StyledButton
          type="primary"
          onClick={() => {
            setIsVoucherOpen(false);
            dispatch(stickyFooterHide(false));
          }}
        >
          Продолжить бронирование
        </StyledButton>
      </Scrollbars>
    </StyledPopup>
  );
};

export default VoucherModal;
