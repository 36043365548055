import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import QRCode from "react-qr-code";
import { Text } from '@components/ui/Typography';
import { Helper } from '@utils';
import { getRailwayOrderReceipt } from '@modules/orders/Manager';
import { useParams } from 'react-router-dom';
import Button from '@components/ui/Button';

export interface RailwayReceiptData {
  receiptNum: number;
  paymentDate: string;
  orderNumber: number;
  orderCreatedDate: string;
  paymentDetails: PaymentDetail[];
  totalReceiptPrice: number;
  ownerEmail: string;
  shiftNum: string;
  customerEmail: string;
  kkT_Num: string;
  fN_Num: string;
  fD_Num: string;
  fp: string;
}

interface PaymentDetail {
  multiplier: number;
  price: number;
  multipliedPrice: number;
  priceDetalization: PriceDetalizationItem[];
}

interface PriceDetalizationItem {
  agentExtraCharge: number;
  extraCharge: number;
  tax: number;
  totalBasePrice: number;
  fareDetails: FareDetailItem[];
  supplierPenalty: number;
}

interface FareDetailItem {
  calculationMethod: string;
  calculationType: string;
  componentName: string;
  createdDate: string;
  id: string;
  modifiedDate: string;
  price: number;
  priceDetailId: string;
  vatRateType: string;
  priceDetail: PriceDetail[];
}

interface PriceDetail {
  orderItemBlankId: string;
  ticketNumber: string;
}

const MainWrapper = styled.div`
  max-width: 960px;
  padding: 0 20px;
  margin: auto;

  & * {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  max-width: 510px;
  margin: 0 auto;
  height: 17px;
`;

const MainContent = styled.div`
  max-width: 510px;
  margin: 0 auto;
  border: 1px solid #dcdcdc;
  border-top: none;
  border-bottom: none;
`;

const Footer = styled.div`
  max-width: 960px;
  margin: 30px auto 0 auto;
`;

const TextInBlue = styled.span`
  position: absolute;
  top: 50%;
  right: 30%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  font-weight: 600;
`;

const BlueContainer = styled.div`
  position: relative;
  height: 126px;
`;

const AzureContainer = styled.div`
  background: azure;
  font-size: 12px;
  color: #676565;
  padding: 20px;
  margin: 10px;
`;

const TableContainer = styled.div`
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoPrintRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media print {
    display: none;
  }
`;

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const RowItem = styled.div<{ muted?: boolean }>`
  color: ${(props) => (props.muted ? '#737373' : 'inherit')};
  font-size: ${(props) => (props.muted ? '12px' : 'inherit')};
`;

const createRow = (
  left: React.ReactNode,
  right: React.ReactNode,
  marginBot = 10,
  oneRow = false,
  muted = false
) => {
  return (
    <Row style={{ marginBottom: marginBot || 0, marginLeft: muted ? 20 : 0 }}>
      <RowItem muted={muted}>{left}</RowItem>
      {oneRow ? null : <RowItem muted={muted}>{right}</RowItem>}
    </Row>
  );
};

export const RailwayReceipt: React.FC = () => {
  const params: { orderId: string } = useParams();
  const [data, setData] = React.useState<RailwayReceiptData | null>(null);
  const [qrCodeText, setQrCodeText] = React.useState<string>('');
  const bigBlueText = (text: string): React.ReactNode => {
    return (
      <Text size={'big'} color={'#0069b3'} fontWeight={'600'}>
        {text}
      </Text>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRailwayOrderReceipt(params.orderId);
      setData(data);
      setQrCodeText(
        `t=${data.paymentDate}&s=${data.totalReceiptPrice}&fn=${data.fN_Num}&i=${data.fD_Num}&fp=${data.fp}&n=1`
      );
    };
    fetchData();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  if (!data) return null;

  return (
    <MainWrapper>
      <NoPrintRow>
        <RowItem style={{ textAlign: 'center', width: '100%' }}>
          {/* render print button*/}
          <Button onClick={handlePrint}>Печать</Button>
        </RowItem>
      </NoPrintRow>
      <Row style={{ margin: '60px 0 20px 0' }}>
        <RowItem style={{ textAlign: 'center', width: '100%' }}>
          <Text size={20} color={'#0069b3'} fontWeight={'600'}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://taxcom.ru/proverka-kontragentov/search/?query=7714775020&amp;utm_source=ofd&amp;utm_medium=receipt&amp;utm_campaign=receipt_ads&amp;utm_content=receipt_link"
            >
              ООО "ТТ-Трэвел"
            </a>
          </Text>
        </RowItem>
      </Row>
      <Header>{waveTopSvg()}</Header>
      <MainContent>
        <TableContainer style={{ background: '#f0f3f5' }}>
          {createRow(
            bigBlueText(`КАССОВЫЙ ЧЕК №: ${data.receiptNum}`),
            bigBlueText(moment(data.paymentDate).format('DD.MM.YYYY HH:mm'))
          )}
          {createRow('Смена:', data.shiftNum, 40)}
          {createRow(
            <span>
              ИНН 7714775020 109316, Регион 77, Москва, Волгоградский проспект,
              дом 42, корпус 9{' '}
              <a href="https://fstravel.com">https://fstravel.com</a>
            </span>,
            '',
            40,
            true
          )}
          <Row style={{ margin: '60px 0 20px 0' }}>
            <RowItem style={{ textAlign: 'center', width: '100%' }}>
              <Text size={24} color={'#0069b3'} fontWeight={'600'}>
                ПРИХОД
              </Text>
            </RowItem>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <RowItem style={{ textAlign: 'center', width: '100%' }}>
              <Text size={20} color={'#0069b3'} fontWeight={'600'}>
                Туристические услуги по заказу TTE{data.orderNumber} от{' '}
                {moment(data.orderCreatedDate).format('DD.MM.YYYY')}
              </Text>
            </RowItem>
          </Row>
          {data.paymentDetails.map((item, index) => {
            return (
              <div key={index}>
                {createRow(
                  `${item.multiplier} x ${Helper.formatPriceRailway(
                    item.price
                  )}`,
                  Helper.formatPriceRailway(item.multipliedPrice),
                  10
                )}
                {item.priceDetalization[0].fareDetails.map((fare, index) => {
                  return createRow(
                    `${fare.componentName}`,
                    Helper.formatPriceRailway(fare.price),
                    10,
                    false,
                    true
                  );
                })}
                {createRow(
                  `СБОРЫ`,
                  Helper.formatPriceRailway(item.priceDetalization[0].extraCharge),
                  10,
                  false,
                  true
                )}
                {createRow(
                  `СБОР ПОСТАВЩИКА`,
                  Helper.formatPriceRailway(item.priceDetalization[0].tax),
                  20,
                  false,
                  true
                )}
              </div>
            );
          })}
          {createRow('без НДС', '')}
          {createRow('Признак способа расчета', 'ПРЕДОПЛАТА 100%')}
          {createRow('Признак предмета расчета', 'УСЛУГА')}
          <hr style={{ margin: '20px 0' }} />
          {createRow(
            bigBlueText('ИТОГО:'),
            bigBlueText(`${Helper.formatPriceRailway(data.totalReceiptPrice)}`),
            10
          )}
        </TableContainer>
        {waveBottomSvg()}
        <TableContainer>
          {createRow(
            'БЕЗНАЛИЧНЫМИ',
            `${Helper.formatPriceRailway(data.totalReceiptPrice)}`
          )}
          {createRow(
            'ИТОГО без НДС:',
            `${Helper.formatPriceRailway(data.totalReceiptPrice)}`
          )}
          {createRow('ККТ для Интернет', 'Да')}
          <hr style={{ margin: '20px 0' }} />
          {createRow(
            'ЭЛ.АДР.ОТПРАВИТЕЛЯ:',
            <a href={`mailto:${data.ownerEmail}`}>{data.ownerEmail}</a>
          )}
          <hr style={{ margin: '20px 0' }} />
          {createRow(
            'САЙТ ФНС:',
            <a
              href="www.nalog.gov.ru
"
            >
              www.nalog.gov.ru
            </a>
          )}
          {createRow(
            'ЭЛ.АДР.ПОКУПАТЕЛЯ:',
            <a href={`mailto:${data.customerEmail}`}>{data.customerEmail}</a>
          )}
          {createRow('№ АВТ.:', 'KSR13.10-7-24')}
          {createRow('СНО:', 'ОСН')}
          {createRow('№ ККТ:', `${data.kkT_Num}`)}
          {createRow('№ ФН:', `${data.fN_Num}`)}
          {createRow('№ ФД:', `${data.fD_Num}`)}
          {createRow('ФП:', `${data.fp}`)}
        </TableContainer>
        <BlueContainer>
          {blueRibbonBlock()}
          <TextInBlue>СПАСИБО</TextInBlue>
        </BlueContainer>
        <QRCodeContainer>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '400px', width: '50%' }}
            value={qrCodeText}
            viewBox={`0 0 256 256`}
          />
        </QRCodeContainer>
      </MainContent>
      <Footer>
        <AzureContainer>
          <p>
            Наличие чека в базе оператора фискальных данных подтверждает, что
            продавец при расчетах применил контрольно-кассовую технику в
            соответствии с требованиями Федерального закона от 22.05.2003
            №54-ФЗ.
          </p>
          <p>
            Уважаемый покупатель, если Вы получили сообщение, содержащее ссылку
            на этот чек, это означает, что ваши контактные данные были указаны
            при совершении расчетов в ООО "ТТ-Трэвел".
          </p>
          <p>
            ОФД «Такском» в данном случае исполняет требования, описанные в{' '}
            <a
              rel="noreferrer"
              href="http://www.consultant.ru/document/cons_doc_LAW_42359/"
              target="_blank"
            >
              54-ФЗ (Статья 4.5, П. 5)
            </a>{' '}
            и является отправителем сообщений.
          </p>
          <p>
            В случаях, если у Вас есть вопросы, связанные с товаром или услугой,
            которые указаны в чеке, или Вы не хотите получать сообщения,
            содержащие ссылку на чек, пожалуйста, обратитесь в ООО "ТТ-Трэвел".
          </p>
        </AzureContainer>
      </Footer>
    </MainWrapper>
  );
};

const waveTopSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="17"
      width={'100%'}
      viewBox="0 0 508 17"
    >
      <path
        fill="#f0f3f5"
        fillRule="evenodd"
        d="M0 0l13.368 5.593L26.737 0l13.368 5.593L53.474 0l13.368 5.593L80.211 0l13.368 5.593L106.947 0l13.369 5.593L133.684 0l13.369 5.593L160.42 0l13.368 5.593L187.158 0l13.368 5.593L213.895 0l13.368 5.593L240.632 0 254 5.593 267.368 0l13.369 5.593L294.105 0l13.369 5.593L320.842 0l13.369 5.593L347.579 0l13.368 5.593L374.316 0l13.368 5.593L401.053 0l13.368 5.593L427.79 0l13.369 5.593L454.526 0l13.369 5.593L481.263 0l13.369 5.593L508 0v17.688H0z"
      ></path>
    </svg>
  );
};

const waveBottomSvg = () => {
  return (
    <div style={{ position: 'relative', top: '-10px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width={'100%'}
        viewBox="0 0 508 20"
      >
        <path
          fill="#ffffff"
          fillRule="evenodd"
          d="M0 0l13.368 8.198L26.737 0l13.368 8.198L53.474 0l13.368 8.198L80.211 0l13.368 8.198L106.947 0l13.369 8.198L133.684 0l13.369 8.198L160.42 0l13.368 8.198L187.158 0l13.368 8.198L213.895 0l13.368 8.198L240.632 0 254 8.198 267.368 0l13.369 8.198L294.105 0l13.369 8.198L320.842 0l13.369 8.198L347.579 0l13.368 8.198L374.316 0l13.368 8.198L401.053 0l13.368 8.198L427.79 0l13.369 8.198L454.526 0l13.369 8.198L481.263 0l13.369 8.198L508 0v19.5H0z"
        ></path>
      </svg>
    </div>
  );
};

const blueRibbonBlock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="126" viewBox="0 0 508 126">
      <path
        fill="#0069b3"
        fillRule="evenodd"
        d="M508 0v126l-13.368-6.71-13.369 6.71-13.368-6.71-13.369 6.71-13.368-6.71-13.369 6.71-13.368-6.71-13.368 6.71-13.369-6.71-13.368 6.71-13.369-6.71L347.58 126l-13.368-6.71-13.369 6.71-13.368-6.71-13.369 6.71-13.368-6.71-13.369 6.71L254 119.29 240.632 126l-13.369-6.71-13.368 6.71-13.369-6.71-13.368 6.71-13.369-6.71-13.368 6.71-13.368-6.71-13.369 6.71-13.368-6.71-13.369 6.71-13.368-6.71L80.21 126l-13.369-6.71L53.474 126l-13.369-6.71L26.737 126l-13.369-6.71L0 126V0l13.368 6.681L26.737 0l13.368 6.681L53.474 0l13.368 6.681L80.211 0l13.368 6.681L106.947 0l13.369 6.681L133.684 0l13.369 6.681L160.42 0l13.368 6.681L187.158 0l13.368 6.681L213.895 0l13.368 6.681L240.632 0 254 6.681 267.368 0l13.369 6.681L294.105 0l13.369 6.681L320.842 0l13.369 6.681L347.579 0l13.368 6.681L374.316 0l13.368 6.681L401.053 0l13.368 6.681L427.79 0l13.369 6.681L454.526 0l13.369 6.681L481.263 0l13.369 6.681z"
      ></path>
    </svg>
  );
};
