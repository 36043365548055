import { all, call, takeEvery } from 'typed-redux-saga';
import { postSessionLogHandler } from '@modules/logs/duck';
import { Action } from 'redux-act';
import { LogSessionHandleData, LogSessionPostData } from '@modules/logs/types';
import packageJson from '../../../package.json';
import { postLogService } from '@modules/logs/Manager';
import moment from 'moment/moment';

export function* postSessionLogHandlerSaga(
  action: Action<LogSessionHandleData>,
) {
  const updatedData = yield* call(() => {
    const logSessionId = sessionStorage.getItem('logSessionId') || '';
    const appVersion = packageJson.version;
    const isObject = typeof action.payload.value === 'object';
    const isBoolean = typeof action.payload.value === 'boolean';
    const createDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    if ((isObject || isBoolean) && action.payload.value !== null) {
      action.payload.value = JSON.stringify(action.payload.value);
    }
    const upd: LogSessionPostData = {
      ...action.payload,
      createDate,
      logSessionId,
      appVersion,
    };

    return upd;
  });

  yield* call(postLogService, updatedData);
}

export default function* logsSaga() {
  yield* all([takeEvery(postSessionLogHandler, postSessionLogHandlerSaga)]);
}
