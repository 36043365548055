import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import { TextContaner, TimeText } from './styles';
import { TimeRangeProps, RangeChangeHandler } from './types';

import Range from './range';

const TimeRange: React.FC<TimeRangeProps> = ({
  min,
  max,
  onChange,
  values,
  ...props
}) => {
  const [valuesS, setValues] = useState(values);

  useEffect(() => {
    setValues(values);
  }, [values]);

  const formattedValues = useMemo(() => {
    return valuesS.map((val) => getTravelTime(val));
  }, [valuesS]);

  const setValuesThrottled = useCallback(
    throttle((val) => {
      setValues(val);
    }, 20),
    []
  );

  const handleChange: RangeChangeHandler = (vals) => {
    setValuesThrottled(vals);
  };
  return (
    <div>
      <TextContaner>
        <TimeText>от {formattedValues[0]}</TimeText>
        <TimeText>до {formattedValues[1]}</TimeText>
      </TextContaner>
      <Range
        {...props}
        onChange={handleChange}
        value={valuesS}
        min={min}
        max={max}
        allowCross={false}
      />
    </div>
  );
};

function getTravelTime(diff: number) {
  let str = '';
  let minutes = diff;
  let days = 0;
  if (minutes >= 60 * 24) {
    days = Math.floor(minutes / (60 * 24));
    str += `${days} д `;
    minutes -= days * 60 * 24;
  }
  if (minutes >= 60 || days > 0) {
    const hours = Math.floor(minutes / 60);

    str += `${hours} ч `;
    minutes -= hours * 60;
  }
  str += `${minutes} мин`;
  return str;
}

export default TimeRange;
