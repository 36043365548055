import React, { useMemo } from 'react';

import Calendar from './Calendar';
import styled from 'styled-components';
import { CalendarChangeEventHandler } from '@components/calendar';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setDate, setReturn } from '@modules/simpleSearch';
import Icon from '../../../assets/images/calendar';
import { stickyFooterHide } from '@modules/orders';

import { useFocus } from './useFormFocus';
import { ApplicationState } from '@modules/index';

const Container = styled.div`
  @media (max-width: 1169px) {
    width: 49%;
  }
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }
`;

const CalendarIcon = styled(Icon)`
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 9px;
  right: 12px;
  fill: ${({ theme: { colors } }) => colors.blue};
  cursor: pointer;
  &:hover {
    fill: ${({ theme: { colors } }) => colors.darkGray};
  }
  & > path {
    pointer-events: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Cross = styled.span`
  position: absolute;
  bottom: 9px;
  right: 12px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 100%;
  }
  padding: 2px 0;
`;

const Button = styled.button.attrs({ type: 'button' })`
  display: block;
  position: relative;
  height: 58px;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 0 0 2px #4872f2;
  transition: 0.2s;

  &:focus {
    box-shadow: 0 0 0 2px #ff6d00;
    z-index: 2;
  }
  @media (max-width: 1169px) {
    width: 49%;
    border-left: none;
    border-radius: 4px;
  }
  @media (max-width: 767px) {
    height: 50px;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  appearance: none;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  padding: 31px 15px 5px 12px;
  box-sizing: border-box;
  pointer-events: none;
  width: 163px;
  @media (max-width: 1169px) {
    width: 100%;
    border-left: none;
    border-radius: 4px;
  }
  @media (max-width: 767px) {
    padding-top: 24px;
  }
`;

const Label = styled.label<{ focus: boolean }>`
  color: #b9b9b9;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  transform: translate(12px, 20px) scale(1);
  position: absolute;
  ${({ focus }) => (focus ? 'transform: translate(12px,9px) scale(.75);' : '')}
`;

const DatePicker: React.FC = () => {
  const dispatch = useDispatch();
  const ref = React.useRef(null as null | HTMLButtonElement);
  const simpleSearchForm = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm,
  );
  const dates = simpleSearchForm.dates;
  const from = dates.from;
  const to = dates.to;
  const returnTicket = simpleSearchForm.returnTicket;

  const { focus, setFocus } = useFocus();

  React.useEffect(() => {
    if (focus === 'backward' && ref.current !== null) {
      ref.current.focus();
    }
  }, [focus]);

  const handleChange: CalendarChangeEventHandler = (values) => {
    if (Array.isArray(values)) {
      dispatch(setDate({ from: values[0], to: values[1] }));
    }
    if (focus === 'backward') {
      dispatch(stickyFooterHide(false));
    }
  };

  const handleCrossClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    dispatch(setDate({ from, to: null }));
    dispatch(setReturn(true));
    dispatch(stickyFooterHide(false));
  };

  const handleTypeChange = (type: 'range' | 'default') => {
    if (type !== calendareType) {
      setReturn(type === 'default');
      if (type === 'default') {
        dispatch(setDate({ from, to: null }));
      }
    }
  };

  const calendareType = useMemo(
    () => (returnTicket ? 'range' : 'default'),
    [returnTicket],
  );

  const handleForward = () => {
    setFocus('forward');
    dispatch(stickyFooterHide(true));
  };

  const handleBack = () => {
    setFocus('backward');
    dispatch(stickyFooterHide(true));
  };

  /** Plug for a masked input */
  const { fromStr, toStr } = useMemo(() => {
    return {
      fromStr: from ? from.toISOString() : '',
      toStr: to ? to.toISOString() : '',
    };
  }, [from, to]);

  const handleClose = () => {
    dispatch(stickyFooterHide(false));
  };

  return (
    <Container>
      <ButtonContainer>
        <Button onClick={handleForward}>
          <Label focus={focus === 'forward' || from !== null}>Туда</Label>
          <Input value={formatDate(fromStr)} />
          <CalendarIcon />
        </Button>

        <Button
          data-cy="datePickerBackwardButton"
          onClick={handleBack}
          ref={ref}
        >
          <Label focus={focus === 'backward' || to !== null}>Обратно</Label>
          <Input value={formatDate(toStr)} />
          {!to ? (
            <CalendarIcon />
          ) : (
            <Cross className="tui-date-clear" onClick={handleCrossClick} />
          )}
        </Button>
      </ButtonContainer>

      <Calendar
        direction={focus as 'backward' | 'forward' | null}
        setDirection={setFocus}
        open={focus === 'forward' || focus === 'backward'}
        onClose={handleClose}
        onChange={handleChange}
        values={[from, to]}
        onTypeChange={handleTypeChange}
        type={calendareType}
      />
    </Container>
  );
};

function formatDate(str: string) {
  return str !== '' ? moment(str).format('D MMM') : str;
}

export default DatePicker;
