import React from 'react';
import styled from 'styled-components';
import { PositionProps } from './types';
import Product from './Product';

const Container = styled.div``;

const List = styled.div`
  & > div {
    margin: 15px 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  & > :first-child {
    margin-top: 0;
  }
`;

const Position: React.FC<PositionProps> = (props) => {
  return (
    <Container>
      <List>
        {props.products
          .filter(
            (x) =>
              x.paymentStatus.code === 'Paid' ||
              x.paymentStatus.code === 'PaidByCredit'
          )
          .map((x, key) => (
            <Product {...x} key={key} />
          ))}
      </List>
    </Container>
  );
};

export default Position;
