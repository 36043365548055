import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Text } from '@components/ui';
import SmsService from './SmsService';
import RefundService from './RefundService';
import AncillaryServices from './AncillaryServices';
import PrintService from './PrintService';
import { useModalState, Provider } from './context';
import {
  ServiceRulesModal as Modal,
  SmsServiceModal,
} from '@components/common/Modals';
import { AddictionalServicesProps, Enums, ItemProps } from './types';
import { Helper } from '@utils';
import { useSelector } from 'react-redux';
import {
  ancillaryServicesSelector,
  getDetailOrderContactInfo,
} from '@modules/orders';
import AnimatedNumber from 'animated-number-react';
import _ from 'lodash';
import { ApplicationState } from '@modules/index';
import AncillaryServicesNew from './AncillaryServicesNew';

const Wrapper = styled(InfoBlock)`
  background: #edf1fe;
  margin-top: 35px;

  & > div {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
`;

// const SubTitle = styled(Text)`
//   font-size: 14px;
//   line-height: 18px;
//   display: block;
//   margin-bottom: 25px;
// `;

const Sum = styled(Text)`
  margin-top: 25px;
  display: block;
  font-size: 16px;
  line-height: 20px;
  & > strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.main};
  }

  @media (max-width: 767px) {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const List = styled.div`
  margin-top: 20px;
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export default (props: AddictionalServicesProps) => (
  <Provider>
    <AddictionalServices {...props} />
  </Provider>
);

function AddictionalServices({
  items,
  showExtraServices,
}: AddictionalServicesProps) {
  const {
    visibility,
    hideModal,
    smsModalVisibility,
    hideSmsModal,
    addSmsService,
  } = useModalState();
  const ancillaryServices = useSelector(ancillaryServicesSelector);
  const user = useSelector(getDetailOrderContactInfo);

  const TOTAL_PRICE = React.useMemo(
    () =>
      _(items)
        .filter((x) => x.isPaid || x.checked)
        .reduce((acc, x) => acc + x.amount, 0) + ancillaryServices.total,
    [items, ancillaryServices],
  );

  const showPaidItems =
    items.filter((x) => x.isPaid).length > 0 ||
    ancillaryServices.luggage.length > 0 ||
    ancillaryServices.meal.length > 0 ||
    ancillaryServices.others.length > 0 ||
    ancillaryServices.seats.length > 0;

  const showNotPaidItems =
    showExtraServices && items.filter((x) => !x.isPaid).length > 0;

  if (!showPaidItems && !showNotPaidItems) return null;

  return (
    <Wrapper>
      <div>
        <Title>Дополнительные услуги</Title>
        <List>
          {/* {showPaidItems && (
            <>
              {items
                .filter((x) => x.isPaid)
                .map((x) => (
                  <React.Fragment key={x.type}>{getTemplate(x)}</React.Fragment>
                ))}
              <AncillaryServices />
            </>
          )} */}
          {showNotPaidItems && (
            <>
              {items
                .filter((x) => !x.isPaid)
                .map((x) => (
                  <React.Fragment key={x.type}>{getTemplate(x)}</React.Fragment>
                ))}
              {/* {order.data.paymentStatus.code === 'Paid' && ( */}
              <AncillaryServicesNew />
              {/* )} */}
            </>
          )}
        </List>
        {/* <Sum>
          Сумма выбранных услуг:{' '}
          <strong data-cy={'orderDetailsServicesTotalPrice'}>
            <AnimatedNumber
              duration={300}
              value={TOTAL_PRICE}
              formatValue={(value: number) => Helper.formatPrice(value)}
            />
          </strong>
        </Sum> */}
      </div>

      <Modal open={visibility !== null} onClose={hideModal} type={visibility} />
      <SmsServiceModal
        onSubmit={(data) => {
          addSmsService(data);
        }}
        open={smsModalVisibility}
        onClose={hideSmsModal}
        user={user}
      />
    </Wrapper>
  );
}

function getTemplate(x: ItemProps) {
  switch (x.type) {
    case Enums.SMS_Service:
      return <SmsService {...x} />;
    case Enums.Return_Guarantee:
      return <RefundService {...x} />;
    case Enums.Payment_Receipt:
      return <PrintService {...x} />;
    default:
      return null;
  }
}
