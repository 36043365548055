import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  onClick(): void;
}

const Wrapper = styled.div`
  text-align: left;
  margin-top: 24px;
`;
const Button = styled.button`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  border: none;
  outline: none;
  background: transparent;
  color: ${({ theme: { colors } }) => colors.main};
  cursor: pointer;
`;

const ResetButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <Wrapper>
      <Button onClick={props.onClick}>Сбросить все фильтры</Button>
    </Wrapper>
  );
};

export default ResetButton;
