import React from 'react';
import styled from 'styled-components';
import { FlightListProps } from './types';
import Item from './Item';
import { Text } from '@components/ui';

const Container = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1024px) {
    & {
      gap: 14px;
    }
  }
`;

const FlightsList: React.FC<FlightListProps> = ({ items }) => {
  return (
    <Container>
      {items.map((props, key) => (
        <React.Fragment key={key}>
          <Item {...props} />
          {props.stops.length > 0 && (
            <Text size={'small'} fontWeight={'600'}>
              Пересадки {props.stops.length}
            </Text>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default FlightsList;
