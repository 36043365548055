import React from 'react';
import Tippy from '@tippyjs/react/';
import {
  CAR_PLACE_GENDER_TYPES,
  CAR_SEAT_TYPES,
  CarPlace,
} from '@modules/trainTickets';
import { Helper } from '@utils';
import { getCarPlaceGenderTypeByPlaceNumber } from '@components/train/TrainSearch/utils';
import { revertConvertSeatPlaceWithALetter } from '@modules/trainTickets/utils';

interface Props {
  reference?: Element | null;
  seat?: CarPlace | null;
  visible: boolean;
  maxSeats: number;
  onlyChildInTrip: boolean;
}

export default function SeatTooltip(props: Props) {
  const { seat, reference, maxSeats, onlyChildInTrip } = props;
  let placeGenderName = null;
  let isNotRefundable = false;
  let diffPrice = 0;
  if (!seat) return null;
  const placeGenderType = getCarPlaceGenderTypeByPlaceNumber(seat.number);
  if (placeGenderType !== null) {
    placeGenderName = CAR_PLACE_GENDER_TYPES[placeGenderType].toLowerCase();
  }
  const isFourPlacesAtOnce = seat.placeReservationType === 'FourPlacesAtOnce';
  const isTwoPlacesAtOnce = seat.placeReservationType === 'TwoPlacesAtOnce';
  const baseTooltip = !isFourPlacesAtOnce && !isTwoPlacesAtOnce;
  const tariff = seat.prices.find((x) =>
    onlyChildInTrip ? x.tariffType === 'Child' : x.tariffType === 'Full',
  )!;
  let price = tariff?.maxPrice.totalPrice || 0;
  if (isFourPlacesAtOnce) {
    price =
      maxSeats > 1
        ? tariff?.maxPrice.totalPrice || 0
        : tariff?.minPrice.totalPrice || 0;
  }
  let priceString = Helper.formatPrice(price);
  if (isTwoPlacesAtOnce) {
    priceString = ``;
  }
  if (tariff?.hasNonRefundableTariff) {
    isNotRefundable = true;
    diffPrice = tariff.maxPrice.totalPrice - tariff.minPrice.totalPrice;
  }
  let seatNumber = seat.number;
  if (parseInt(seatNumber) > 2000) {
    seatNumber = revertConvertSeatPlaceWithALetter(parseInt(seatNumber));
  }

  return (
    <Tippy
      interactive
      visible={props.visible}
      appendTo={() => document.body}
      reference={reference}
      theme="light"
      content={
        <span>
          {baseTooltip && (
            <b>
              Место {seatNumber} –{' '}
              <span style={{ color: '#4872F2' }}>{priceString}</span>
            </b>
          )}
          {isFourPlacesAtOnce && (
            <b>
              Купе-переговорная выкупается только целиком за{' '}
              <span style={{ color: '#4872F2' }}>{priceString}</span>
            </b>
          )}
          {isTwoPlacesAtOnce && tariff && (
            <span>
              <b>
                Купе выкупается только целиком за{' '}
                <div style={{ color: '#4872F2' }}>
                  {Helper.formatPrice(tariff.minPrice.totalPrice)} –{' '}
                  {Helper.formatPrice(tariff.maxPrice.totalPrice)}
                </div>
              </b>
              <br />
              <div>
                <span style={{ color: '#4872F2' }}>
                  {Helper.formatPrice(tariff.minPrice.totalPrice)}
                </span>{' '}
                – для одного пассажира
              </div>
              <div>
                <span style={{ color: '#4872F2' }}>
                  {Helper.formatPrice(tariff.maxPrice.totalPrice)}
                </span>{' '}
                – для двух пассажиров с билетами
              </div>
            </span>
          )}
          <br></br>
          {!isFourPlacesAtOnce && !isTwoPlacesAtOnce && (
            <span>
              {CAR_SEAT_TYPES[seat.type]}
              <b>{placeGenderName ? `, ${placeGenderName}` : ``}</b>
            </span>
          )}
          {isNotRefundable && diffPrice > 0 && (
            <span>
              <br></br>
              <br></br>
              <span>
                Это место можно купить по невозвратному тарифу <b>дешевле на</b>{' '}
                <span style={{ color: '#4872F2', fontWeight: 600 }}>
                  {Helper.formatPrice(diffPrice)}
                </span>
              </span>
            </span>
          )}
        </span>
      }
    />
  );
}
