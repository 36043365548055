import { createAction, createReducer } from 'redux-act';
import {
  TrainRefundCompletePayload,
  TrainOrderRefundPayload,
  TrainRefundResponse,
  TrainOrderCancelPayload,
  TrainCancelOrderState,
} from '@modules/trainOrders/dto';
import { combineReducers } from 'redux';
import {
  RefundPopupStepType,
  RefundStatusType,
} from '@modules/trainOrders/types';

export const trainCancelOrderHandler = createAction<TrainOrderCancelPayload>(
  '@trainTickets/trainCancelOrderHandler'
);

export const cancelOrderPopupToggle = createAction<boolean>(
  '@trainTickets/cancelOrderPopupToggle'
);

export const setTrainCancelOrderProgress = createAction<
  'pending' | 'success' | 'error' | null
>('@trainTickets/setTrainCancelOrderProgress');

export const trainRefundCompleteHandler = createAction(
  '@trainTickets/trainRefundCompleteHandler'
);

export const trainRefundSelectPassengers = createAction<string[]>(
  '@trainTickets/trainRefundSelectPassengers'
);

export const trainRefundSelectSegments = createAction<string[]>(
  '@trainTickets/trainRefundSelectSegments'
);

export const trainRefundRequest = createAction<TrainOrderRefundPayload>(
  '@trainTickets/trainRefundRequest'
);
export const setTrainRefundRequestStatus = createAction<RefundStatusType>(
  '@trainTickets/setTrainRefundRequestStatus'
);

export const setTrainRefundCompleteRequestStatus =
  createAction<RefundStatusType>(
    '@trainTickets/setTrainRefundCompleteRequestStatus'
  );

export const setTrainRefundRequestData = createAction<TrainOrderRefundPayload>(
  '@trainTickets/setTrainRefundRequestData'
);

export const setTrainPassengerRefundCompleteHandler = createAction<string>(
  '@trainTickets/setTrainPassengerRefundCompleteHandler'
);

export const setTrainRefundCompleteRequestData =
  createAction<TrainRefundCompletePayload>(
    '@trainTickets/setTrainRefundCompleteRequestData'
  );

export const setTrainRefundPopupStep = createAction<RefundPopupStepType>(
  '@trainTickets/setTrainRefundPopupStep'
);

export const setTrainRefundPopupCompleteIsOpen = createAction<boolean>(
  '@trainTickets/setTrainRefundPopupCompleteIsOpen'
);

export const setTrainRefundPopupDeclineIsOpen = createAction<boolean>(
  '@trainTickets/setTrainRefundPopupDeclineIsOpen'
);

export const setTrainRefundResponseData = createAction<TrainRefundResponse>(
  '@trainTickets/setTrainRefundResponseData'
);

export const clearTrainRefund = createAction('@trainTickets/clearTrainRefund');

export const responseData = createReducer(
  {},
  null as unknown as TrainRefundResponse
);
export const requestData = createReducer(
  {},
  null as unknown as TrainOrderRefundPayload
);
export const requestCompleteData = createReducer(
  {},
  null as unknown as TrainRefundCompletePayload
);
export const requestStatus = createReducer<RefundStatusType>({}, 'initial');
export const requestCompleteStatus = createReducer<RefundStatusType>(
  {},
  'initial'
);
export const refundPopupStep = createReducer<RefundPopupStepType>({}, 'none');
export const refundPopupCompleteIsOpen = createReducer<boolean>({}, false);
export const refundPopupDeclineIsOpen = createReducer<boolean>({}, false);

export const selectedPassengers = createReducer<string[]>({}, []);
export const selectedSegments = createReducer<string[]>({}, []);

export const cancel = createReducer<TrainCancelOrderState>(
  {},
  {
    cancelPopupIsOpen: false,
    cancelOrderProgress: null,
  }
);

cancel.on(cancelOrderPopupToggle, (state, payload) => ({
  ...state,
  cancelPopupIsOpen: payload,
}));

cancel.on(setTrainCancelOrderProgress, (state, payload) => ({
  ...state,
  cancelOrderProgress: payload,
}));

selectedPassengers.on(trainRefundSelectPassengers, (state, payload) => payload);
selectedSegments.on(trainRefundSelectSegments, (state, payload) => payload);

refundPopupCompleteIsOpen.on(
  setTrainRefundPopupCompleteIsOpen,
  (state, payload) => payload
);
refundPopupDeclineIsOpen.on(
  setTrainRefundPopupDeclineIsOpen,
  (state, payload) => payload
);
refundPopupStep.on(setTrainRefundPopupStep, (state, payload) => payload);
requestStatus.on(setTrainRefundRequestStatus, (state, payload) => payload);
requestCompleteStatus.on(
  setTrainRefundCompleteRequestStatus,
  (state, payload) => payload
);
responseData.on(setTrainRefundResponseData, (state, payload) => payload);
requestData.on(setTrainRefundRequestData, (state, payload) => payload);
requestCompleteData.on(
  setTrainRefundCompleteRequestData,
  (state, payload) => payload
);

const refund = combineReducers({
  responseData,
  requestData,
  requestCompleteData,
  requestStatus,
  requestCompleteStatus,
  refundPopupStep,
  refundPopupCompleteIsOpen,
  refundPopupDeclineIsOpen,
  selectedPassengers,
  selectedSegments,
});
export default combineReducers({
  refund,
  cancel,
});
