import styled from 'styled-components';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

const DURATION = 400;

const Collapse: FC<{ children: React.ReactNode; active: boolean }> = ({
  children,
  active,
}) => {
  const nodeRef = useRef<null | HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const transitionStyles = (height: number, state: string) =>
    ({
      entering: { maxHeight: 0 },
      entered: { maxHeight: height },
      exiting: { maxHeight: height },
      exited: { maxHeight: 0 },
    }[state]);

  const countHeight = (node: null | HTMLDivElement) => {
    const newHeight = node ? node.scrollHeight : 0;
    setHeight(newHeight);
  };

  useEffect(() => {
    if (nodeRef.current) countHeight(nodeRef.current);
  }, [nodeRef.current]);

  return (
    <Transition in={active} timeout={0} nodeRef={nodeRef}>
      {(state) => (
        <CollapseWrapper
          ref={nodeRef}
          duration={DURATION}
          style={transitionStyles(height, state)}
        >
          {children}
        </CollapseWrapper>
      )}
    </Transition>
  );
};

const CollapseWrapper = styled.div<{
  duration: number;
}>`
  overflow: hidden;
  transition: ${(props) => `max-height ${props.duration}ms ease-in-out`};
`;

export default Collapse;
