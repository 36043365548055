import React from 'react';
import styled from 'styled-components';
import { SeatsMapProps } from './types';
import Deck from './Deck';
import { Text, Button } from '@components/ui';
import { SeatMapProvider } from './context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AirplaneName = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-left: 50px;
  & > button {
    margin-right: 13px;
  }
  & > :last-child {
    margin-right: 0px;
  }
`;

const SelectButton = styled(Button).attrs({ type: 'outlined' })`
  font-weight: 600;
`;

const SeatsMap: React.FC<SeatsMapProps> = (props) => {
  return (
    <SeatMapProvider {...props}>
      {/*@ts-ignore*/}
      {({ selectedDeckIndex, setSelectedDeckIndex }: any) => {
        return (
          <Wrapper>
            <AirplaneName>{props.airplaneName}</AirplaneName>
            {props.decks.length > 1 && (
              <ButtonContainer>
                {props.decks.map((x, key) => (
                  <SelectButton
                    key={key}
                    onClick={() => setSelectedDeckIndex(key)}
                    selected={key === selectedDeckIndex}
                  >
                    {x.type}
                  </SelectButton>
                ))}
              </ButtonContainer>
            )}
            <Deck
              rows={props.decks[selectedDeckIndex]?.rows}
              selectedPassengerType={props.selectedPassengerType}
              isDetail={props.isDetail}
              selectedSeatsDetail={props.selectedSeats}
              passengerId={props.passengerId}
              segmentId={props.segmentId}
            />
          </Wrapper>
        );
      }}
    </SeatMapProvider>
  );
};

export default SeatsMap;
