import { call, spawn } from 'typed-redux-saga';
import { AirCheckAvailabilityPayload } from '../../types';
import {
  checkAvailabilityRequestWorker,
  getAddictionalServicesRequestWorker,
} from '../workers';

import { Action } from 'redux-act';

/**
 * Используется когда пользователь находится в списке билетов и хочет начать бронирование билета
 * @param param0 - data required for booking page
 */
export function* checkAvailabilityRequestFlow({
  payload,
}: Action<AirCheckAvailabilityPayload>) {
  const response = yield* call(checkAvailabilityRequestWorker, payload);

  // TODO: разобраться с типами ответа
  // @ts-ignore
  if (response.responseId) {
    yield* spawn(
      getAddictionalServicesRequestWorker,
      // @ts-ignore
      response.responseId as unknown as string,
      // @ts-ignore
      response.isCharter
    );
  }
}
