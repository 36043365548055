import React from 'react';
import styled from 'styled-components';
import { Title, GrayText, BlockWithMaxWidth } from './shared';
import BaseText from '@components/ui/Typography';
import { ReactComponent as BackgroundSVG } from '@assets/media/main-page/FrequentlyAskedQuestionsBackground.svg';
import { ReactComponent as ArrowForQuestionSVG } from '@assets/media/main-page/ArrowForQuestions.svg';
import { ReactComponent as ArrowIcon } from '@assets/media/main-page/telegram-link-arrow.svg';
import { NavLink } from 'react-router-dom';


const Container = styled.div`
  background: #F5EDFF;
  border-radius: 24px;
  position: relative;
  padding: 45px;
  overflow: hidden;

  @media(max-width: 767px) {
    padding: 30px 20px;
  }
`;

const BackgroundIcon = styled(BackgroundSVG)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  @media(max-width: 767px) {
    width: 120.5px;
    height: 189.5px;
  }
`;

const Content = styled.div`
  z-index: 2;
  position: relative;

  ${GrayText} {
    display: block;
    margin-bottom: 8px;
  }

  ${Title} {
    display: block;
    margin-bottom: 24px;
  }
`;


const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 37.5px;
  max-width: 1056px;

  @media(max-width: 767px) {
    width: 100%;
    display: block;

    & > div {
      margin-bottom: 20px;
    }
  
    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

const Row = styled.div`
  width: 476px;

  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media(max-width: 767px) {
    width: 100%;
  }
`;

const ItemContentText = styled(BaseText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;

  & > strong, & > a {
    font-weight: 600;
    &:before {
      content: '«';
    } 
    &:after {
      content: '»';
    } 
  }

  & > a {
    color: #4872F2;
    text-decoration: none;
    cursor: pointer;
  }
`;

const ItemContentLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4872F2;
`;

const ItemContent = styled.div`
  padding-left: 15px;
  overflow: hidden;
  transition: max-height .2s ease-in-out;

  ${ItemContentText} {
    margin: 15px 0;
    display: block;
  }

  ${ItemContentLink} {
    margin-top: 15px;
    display: block;
  }

  @media(max-width: 767px) {
    padding-left: 0;
  }
`;

const ItemHead = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    margin-left: 6px;
    transition: transform 0.2s ease-in-out;
  }
`;

const Item = styled.div<{ active: boolean; }>`
  ${ItemContent} {
    max-height: 0;
  }

  ${({ active }) => active ? `
    ${ItemHead} {
      & > svg {
        transform: rotateX(180deg);
      }
    }
    ${ItemContent} {
      max-height: 300px;
      display: block;
    }
  ` : ''}
`;



const ItemDescription = styled(BaseText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  display: block;
  margin-left: 15px; 

  &:before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: black;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 1169px) {
    margin-left: 12px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-left: 11x;

    &:before {
      left: -10px;
    }
  }
`;


const DesktopText = styled.span`
  @media(max-width: 767px) {
    display: none;
  }
`;



const ShowMore = styled(NavLink)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;
  color: #4872f2;
  position: relative;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  right: -29px;
  top: 0;
  & > path {
    fill: #4872f2;
  }
`;




export default function FrequentlyAskedQuestionsBanner() {
  const [questions, setQuestions] = React.useState(new Array<boolean>(8).fill(false));

  const toggleQuestion = (index: number) => {
    const arr = [...questions];
    arr[index] = !arr[index];
    setQuestions(arr)
  }

  return (
    <BlockWithMaxWidth>
      <Container>
        <BackgroundIcon />
        <Content>
          <GrayText>Для вашего удобства</GrayText>
          <Title>Ответы на часто задаваемые вопросы</Title>
          <Flex>
            <Row>
              <Item active={questions[0]}>
                <ItemHead onClick={() => toggleQuestion(0)}>
                  <ItemDescription>Как зарегистрироваться на сайте?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    Нажмите кнопку <strong>Авторизация</strong> в правом верхнем углу страницы.
                    Выберите пункт <strong>Зарегистрироваться</strong>.
                    Введите адрес своей электронной почты и придумайте пароль.
                    После этого вам на почту придёт письмо с подтверждением создания учётной записи на нашем сайте.
                  </ItemContentText>
                  <ItemContentLink to='/questions/registration'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
              <Item active={questions[2]}>
                <ItemHead onClick={() => toggleQuestion(2)}>
                  <ItemDescription>Как войти в личный кабинет?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    Если вы уже зарегистрированы на нашем сайте - нажмите кнопку <strong>Авторизация</strong> в правом верхнем углу страницы.
                    Введите адрес электронной почты и пароль от вашей учетной записи.
                  </ItemContentText>
                  <ItemContentLink to='/questions/how-to-login'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
              <Item active={questions[4]}>
                <ItemHead onClick={() => toggleQuestion(4)}>
                  <ItemDescription>Что делать, если я забыл пароль?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    Если Вы забыли пароль от личного кабинета, то нажмите кнопку <strong>Авторизация</strong> в правом верхнем углу страницы.
                    Выберите пункт <strong>Я забыл пароль</strong>.
                    Введите вашу почту, и получите письмо с новым паролем.
                  </ItemContentText>
                  <ItemContentLink to='/questions/forget-password'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
              <Item active={questions[6]}>
                <ItemHead onClick={() => toggleQuestion(6)}>
                  <ItemDescription>Где <DesktopText>находится</DesktopText> информация по заказам?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    Список всех ваших заказов хранится в разделе <NavLink to='/orders'>Мои заказы</NavLink>.
                    Чтобы быстрее найти конкретный заказ воспользуйтесь фильтрами и сортировкой по заказам.
                  </ItemContentText>
                  <ItemContentLink to='/questions/where-is-information-about-orders'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
            </Row>
            <Row>
              <Item active={questions[1]}>
                <ItemHead onClick={() => toggleQuestion(1)}>
                  <ItemDescription>Как забронировать авиабилет?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    С помощью формы поиска билетов найдите лучший для вас вариант перелета.
                    Выберите тариф, доп. услуги и страхование.
                    Заполните контактные данные и информацию о пассажирах.
                    В завершении ознакомьтесь с условиями тарифа и оферты и переходите к оплате.
                  </ItemContentText>
                  <ItemContentLink to='/questions/how-to-book-ticket'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
              <Item active={questions[3]}>
                <ItemHead onClick={() => toggleQuestion(3)}>
                  <ItemDescription>Как вернуть авиабилет?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    Нажмите кнопку <strong>Служба поддержки</strong> в заказе.
                    Выберите тему обращения <strong>Возврат</strong> и заполните форму.
                    Следите за статусами и уведомлениями по обращению на вкладке <NavLink to='/tickets'>Обращения</NavLink> .
                  </ItemContentText>
                  <ItemContentLink to='/questions/how-to-exchange-or-refund-order'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
              <Item active={questions[5]}>
                <ItemHead onClick={() => toggleQuestion(5)}>
                  <ItemDescription>Как обменять авиабилет?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    Нажмите кнопку <strong>Служба поддержки</strong> в деталях заказа.
                    Выберите тему обращения <strong>Обмен</strong> и заполните форму.
                    Следите за статусами и уведомлениями по обращению на вкладке <NavLink to='/tickets'>Обращения</NavLink>.
                  </ItemContentText>
                  <ItemContentLink to='/questions/how-to-exchange-or-refund-order'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
              <Item active={questions[7]}>
                <ItemHead onClick={() => toggleQuestion(7)}>
                  <ItemDescription>Где <DesktopText>я могу</DesktopText> скачать билеты и документы?</ItemDescription>
                  <ArrowForQuestionSVG />
                </ItemHead>
                <ItemContent>
                  <ItemContentText>
                    После оплаты документы по заказу придут на указанную вами почту и{' '}
                    будут доступны в деталях заказа по кнопке <strong>Документы по заказу</strong>.
                  </ItemContentText>
                  <ItemContentLink to='/questions/how-to-print-ticket'>Читать полностью</ItemContentLink>
                </ItemContent>
              </Item>
            </Row>
          </Flex>
          <ShowMore to="/questions">
            Смотреть весь раздел <Arrow />
          </ShowMore>
        </Content>
      </Container>
    </BlockWithMaxWidth>
  );
}