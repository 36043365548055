import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #4872f2;
  border-radius: 50%;
`;

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <Wrapper>
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9094 3.37224L9.34001 17.7626L0.443372 9.99887L2.82039 7.37148L9.30772 13.0327L21.4982 0.992021L23.9094 3.37224Z"
          fill="white"
        />
      </svg>
    </Wrapper>
  );
};

export default Logo;
