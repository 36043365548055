import React, { ReactElement } from 'react';
import { ReactComponent as InfoIcon } from '@assets/media/shared/redInfoIcon.svg';
import { Props } from './interfaces';
import { Info as InfoContainer } from './styles';

const Info = ({ children }: Props): ReactElement => (
  <InfoContainer>
    <InfoIcon />
    <span>{children}</span>
  </InfoContainer>
);

export default Info;
