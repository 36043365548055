import React from 'react';
import styled from 'styled-components';
import { DescriptionProps } from './types';
import Text from '@components/ui/Typography';
import { Helper } from '@utils';

const Container = styled.div`
  margin-bottom: 7px;
  & > span {
    display: block;
    margin-bottom: 5px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Description: React.FC<DescriptionProps> = ({
  className,
  duration,
  transfersCount,
  luggage,
  transferInDuration,
}) => (
  <Container className={className}>
    <Text color="dark-gray" size="small">
      Время в пути:{' '}
      <Text size="small" fontWeight="600" color="black">
        {Helper.formatDuration(duration)}
        {transferInDuration && (
          <>
            ,{' '}
            <Text size="small" fontWeight="600" color="blue">
              {transfersCount > 0
                ? `${transfersCount} ${Helper.pluralWord(
                    transfersCount,
                    'пересадка',
                    'пересадки',
                    'пересадок'
                  )}`
                : 'Прямой'}
            </Text>
          </>
        )}
      </Text>
    </Text>
    <Text color="dark-gray" size="small">
      Багаж:{' '}
      <Text size="small" fontWeight="600" color="black">
        {typeof luggage === 'string' ? luggage : `до ${luggage}кг`}
      </Text>
    </Text>
    {transfersCount !== 0 && !transferInDuration && (
      <Text color="dark-gray" size="small">
        Пересадки:{' '}
        <Text size="small" fontWeight="600" color="black">
          {transfersCount}
        </Text>
      </Text>
    )}
  </Container>
);

export default Description;
