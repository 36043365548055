import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import { ItemProps } from './types';
import { ReactComponent as Refund } from '@assets/media/addictional-services/return.svg';
import { ReactComponent as RecommendIcon } from '@assets/media/addictional-services/your-protection.svg';
import { ReactComponent as Paid } from './assets/media/paid.svg';
import { useModalState } from './context';
import ExpirationTime from './ExpirationTime';

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  position: relative;
  @media (max-width: 767px) {
    padding: 10px 14px;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-left: 7px;
  @media (max-width: 1121px) {
    position: initial;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4872f2;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Description = styled(Text)`
  display: block;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

const Rule = styled(Text)`
  color: #4872f2;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
  display: block;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 18px;
    margin-top: 10px;
  }
`;

const AddedIcon = styled(Paid)`
  position: absolute;
  right: -111px;
  @media (max-width: 767px) {
    position: absolute;
    right: -4px;
    top: -12px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Action = styled(Button).attrs({ htmlType: 'button' })`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;
  min-width: 144px;
  margin-top: 15px;
  display: block;
`;

const Label = styled(RecommendIcon)`
  position: absolute;
  right: -125px;
  top: -3px;
  @media (max-width: 1121px) {
    top: -12px;
    right: -4px;
  }
`;

export default function (props: ItemProps) {
  const { showModal, handleActionButtonClick } = useModalState();

  return (
    <Wrapper>
      <Content>
        <Head>
          <Row>
            <Refund style={{ width: 30, height: 30 }} />
            <Name>
              Гарантия возврата билета{' '}
              {props.isPaid ? <AddedIcon /> : <Label />}
            </Name>
          </Row>
          <Price data-cy={'orderDetailsRefundSrvicePrice'}>
            {Helper.formatPrice(props.amount)}
          </Price>
        </Head>
        <Description>
          В случае, если поездка не может состояться из-за внезапной болезни
          пассажира, мы вернем Вам деньги за билет без оплаты сервисного сбора.
        </Description>
        <Rule onClick={() => showModal(props.type)}>
          Правила и условия предоставления услуги
        </Rule>
        {props.expires && <ExpirationTime value={props.expires} />}
        {!props.isPaid && (
          <Action
            data-cy={'additionalServiceRefundButtonToggle'}
            onClick={() => handleActionButtonClick(props)}
            isLoading={props.isLoading}
            color={props.checked ? 'gray' : undefined}
          >
            {!props.checked ? 'Добавить услугу' : 'Убрать услугу'}
          </Action>
        )}
      </Content>
    </Wrapper>
  );
}
