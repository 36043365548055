import {
  clearTrainRefund,
  setTrainPassengerRefundCompleteHandler,
  setTrainRefundCompleteRequestStatus,
  setTrainRefundPopupCompleteIsOpen,
  setTrainRefundPopupDeclineIsOpen,
  setTrainRefundPopupStep,
  setTrainRefundRequestStatus,
  setTrainRefundResponseData,
  trainRefundCompleteHandler,
  trainRefundRequest,
  trainRefundSelectPassengers,
  trainRefundSelectSegments,
  trainCancelOrderHandler,
  setTrainCancelOrderProgress,
} from '@modules/trainOrders/duck';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import {
  getTrainRefund,
  postTrainRefundComplete,
  trainCancelOrder,
} from '@modules/trainOrders/manager';
import { ApplicationState } from '@modules/index';
import {
  TrainRefundCompleteBlank,
  TrainRefundCompletePayload,
  TrainRefundResponse,
} from '@modules/trainOrders/dto';

export function* getTrainRefundSaga(
  action: ReturnType<typeof trainRefundRequest>
) {
  try {
    yield* call(clearTrainRefundSaga);
    yield* put(setTrainRefundRequestStatus('pending'));
    yield* put(setTrainRefundPopupStep('select'));
    const response = yield* call(getTrainRefund, action.payload);
    yield* put(setTrainRefundResponseData(response));
    yield* put(setTrainRefundRequestStatus('success'));
  } catch (e) {
    yield* put(setTrainRefundRequestStatus('error'));
  }
}

export function* clearTrainRefundSaga() {
  try {
    yield* put(setTrainRefundCompleteRequestStatus('initial'));
    yield* put(setTrainRefundRequestStatus('initial'));
    yield* put(setTrainRefundPopupStep('none'));
    yield* put(
      setTrainRefundResponseData(null as unknown as TrainRefundResponse)
    );
    yield* put(setTrainRefundPopupCompleteIsOpen(false));
    yield* put(setTrainRefundPopupDeclineIsOpen(false));
    yield* put(trainRefundSelectPassengers([]));
    yield* put(trainRefundSelectSegments([]));
  } catch (e) {
    yield* put(setTrainRefundRequestStatus('error'));
  }
}

export function* setTrainRefundCompleteSaga(
  action: ReturnType<typeof setTrainPassengerRefundCompleteHandler>
) {
  try {
    console.log('setTrainRefundCompleteSaga', action.payload);
  } catch (e) {
    yield* put(setTrainRefundRequestStatus('error'));
  }
}

export function* refundCompleteRequestSaga() {
  try {
    yield* put(setTrainRefundPopupStep('confirm'));
    yield* put(setTrainRefundCompleteRequestStatus('pending'));
    const orderDetails = yield* select(
      (state: ApplicationState) => state.orders.orderDetail.data
    );
    const selectedPassengers = yield* select(
      (state: ApplicationState) => state.trainOrders.refund.selectedPassengers
    );
    const selectedSegments = yield* select(
      (state: ApplicationState) => state.trainOrders.refund.selectedSegments
    );
    const refundResponse = yield* select(
      (state: ApplicationState) => state.trainOrders.refund.responseData
    );

    const blanks: TrainRefundCompleteBlank[] = selectedSegments.map(
      (segmentId) => {
        const refundedOrderItemBlanks: number[] = [];
        const segment = refundResponse.segments.find(
          (segment) => segment.orderItemId === parseInt(segmentId)
        );
        if (segment) {
          if (selectedPassengers.includes(segment.validationDocumentNumber)) {
            const ticketInfos = segment.refundTicketInfos.map(
              (ticketInfo) => ticketInfo.orderItemBlankId
            );
            refundedOrderItemBlanks.push(...ticketInfos);
          }
        }

        return {
          refundedOrderItem: parseInt(segmentId),
          refundedOrderItemBlanks: refundedOrderItemBlanks,
        };
      }
    );

    const result: TrainRefundCompletePayload = {
      blanks: blanks,
      orderNumber: orderDetails.orderNumber,
    };
    yield* call(postTrainRefundComplete, result);
    yield* put(setTrainRefundCompleteRequestStatus('success'));
    window.location.reload();
  } catch (e) {
    yield* put(setTrainRefundRequestStatus('error'));
  }
}

export function* trainCancelOrderSaga(
  action: ReturnType<typeof trainCancelOrderHandler>
) {
  try {
    yield* put(setTrainCancelOrderProgress('pending'));
    yield* call(trainCancelOrder, action.payload);
    window.location.reload();
  } catch (e) {
    yield* put(setTrainCancelOrderProgress('error'));
  }
}

export default function* trainOrderFlow() {
  yield* takeLatest(trainRefundRequest, getTrainRefundSaga);
  yield* takeLatest(
    setTrainPassengerRefundCompleteHandler,
    setTrainRefundCompleteSaga
  );
  yield* takeLatest(trainRefundCompleteHandler, refundCompleteRequestSaga);
  yield* takeLatest(clearTrainRefund, clearTrainRefundSaga);
  yield* takeLatest(trainCancelOrderHandler, trainCancelOrderSaga);
}
