import React from 'react';
import styled from 'styled-components';
import { SimpleSearch } from '@components/flightsSearch';
import { Text } from '@components/ui';
import {
  getUiState,
  setMobileHeadVisibility,
  changeSearchPanelType,
} from '@modules/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import TicketPlaneIcon from '@app/assets/media/header/ticketIcons/TicketPlane';
import TicketTrainIcon from '@app/assets/media/header/ticketIcons/TicketTrain';
import TicketBusIcon from '@app/assets/media/header/ticketIcons/TicketBus';

interface Props {
  isOnlyTrain?: boolean;
  isOnlyBus?: boolean;
}

const Wrapper = styled.div<{ mainPage?: boolean; top?: boolean | null }>`
  background: #edf1fe;
  transition: transform 0.3s ease-in-out;
  padding-block: 50px;

  @media (max-width: 1169px) {
    padding: 30px 20px;
  }

  @media (max-width: 767px) {
    padding: 30px 5%;
    top: 54px;
    ${({ top }) => (top ? 'transform: translateY(-55px);' : '')}
  }
  top: 0;

  z-index: 999;
  ${({ mainPage }) => (mainPage ? 'position: sticky' : '')};
`;

const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;

const StyledSimpleSearch = styled(SimpleSearch)`
  @media (max-width: 1169px) {
    width: 100%;
    box-sizing: border-box;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2e2e32;
  margin-bottom: 20px;
  display: block;

  @media (min-width: 768px) and (max-width: 1169px) {
    display: none;
  }

  @media (max-width: 1169px) {
    margin-bottom: 24px;
  }
`;

// const DifficultSearch = styled(Text)`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 18px;
//   color: #4872f2;
//   display: block;
//   margin-top: 16px;

//   @media (max-width: 1169px) {
//     display: none;
//   }
// `;

const ButtonsWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  margin-top: clamp(30px, 7vw, 46px);
  margin-bottom: clamp(20px, calc(35px - 1.3vw), 30px);
  height: 56px;
  gap: 15px;

  @media (max-width: 622px) {
    height: 78px;
  }
`;

const Button = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background-color 0.3s, filter 1s ease;
  background-color: 0.3s, color 0.3s;
  box-sizing: border-box;
  padding: 15px 20px;
  gap: 7px;

  font-family: Open Sans;
  font-weight: 600;
  font-size: 16px;

  border: 2px solid;
  border-radius: 10px;
  border-color: transparent;
  background-color: transparent;
  color: ${(props) => (props.active ? '#486AD4' : '#FFFFFF')};
  cursor: pointer;

  & > svg {
    filter: ${(props) => !props.active && 'invert(100%) brightness(10000%)'};
  }

  @media (max-width: 622px) {
    flex-direction: column;
    max-width: clamp(106px, 29vw, 180px);
    width: 100%;
    padding: 12px 0px;
    justify-content: space-between;
    gap: unset;

    & > span {
      line-height: 18px;
      font-size: clamp(12px, 4vw, 16px);
    }
  }

  ${(props) =>
    props.active
      ? `
    border-color: #4872F2; 
    background-color: #FFFFFF;
    pointer-events: none;
    `
      : `
    background-color: #839FF550;
    
    &:hover {
      background-color: #839FF580;
    `}
`;

const CompositeSearchBlock: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShrinkLabels = useMediaQuery({ maxWidth: 383 });
  const { isMobileHeadVisible, searchPanelType } = useSelector(getUiState);

  const handleClickAvia = React.useCallback(() => {
    dispatch(changeSearchPanelType('avia'));
  }, [dispatch]);
  const handleClickTrain = React.useCallback(() => {
    dispatch(changeSearchPanelType('train'));
  }, [dispatch]);
  const handleClickBus = React.useCallback(() => {
    dispatch(changeSearchPanelType('bus'));
  }, [dispatch]);

  React.useEffect(
    () => () => {
      dispatch(setMobileHeadVisibility(true));
    },
    []
  );

  const pickTitle = () => {
    if (isMobile) return 'Билеты в любую точку мира';
    return 'Путешествуйте вместе с нами!';
  };

  return (
    <Wrapper
      mainPage={false}
      // mainPage={location.pathname.includes('/search')}
      top={!isMobileHeadVisible}
    >
      <Container>
        <Title>{pickTitle()}</Title>
        {props.isOnlyTrain && (
          <ButtonsWrapper>
            <Button
              active={searchPanelType === 'train'}
              onClick={handleClickTrain}
            >
              <TicketBusIcon />
              <span>Ж/Д билеты</span>
            </Button>
          </ButtonsWrapper>
        )}
        {props.isOnlyBus && (
          <ButtonsWrapper>
            <Button active={searchPanelType === 'bus'} onClick={handleClickBus}>
              <TicketBusIcon />
              <span>Автобусы</span>
            </Button>
          </ButtonsWrapper>
        )}
        {!props.isOnlyTrain && !props.isOnlyBus && (
          <ButtonsWrapper>
            <Button
              active={searchPanelType === 'avia'}
              onClick={handleClickAvia}
            >
              <TicketPlaneIcon />
              <span>{isShrinkLabels ? 'Авиа' : 'Авиабилеты'}</span>
            </Button>
            <Button
              active={searchPanelType === 'train'}
              onClick={handleClickTrain}
            >
              <TicketTrainIcon />
              <span>{isShrinkLabels ? 'Ж/д' : 'Ж/д билеты'}</span>
            </Button>
            <Button active={searchPanelType === 'bus'} onClick={handleClickBus}>
              <TicketBusIcon />
              <span>{isShrinkLabels ? 'Автобус' : 'Автобусы'}</span>
            </Button>
          </ButtonsWrapper>
        )}
        <StyledSimpleSearch />
        {/* <DifficultSearch>Составить сложный маршрут</DifficultSearch> */}
      </Container>
    </Wrapper>
  );
};

export default CompositeSearchBlock;
