import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { NotificationSeatMapModalProps } from './types';
import { Text, Button } from '@components/ui';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }
  &-content {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      padding: 20px;
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 26px;
`;

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
`;

const BlueText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #4872f2;
  font-weight: 600;
`;

const ActionsBlock = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & > button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: normal;
`;

const Modal: React.FC<NotificationSeatMapModalProps> = ({
  onConfirm,
  title,
  subtitle,
  buttonsText,
  data,
  ...props
}) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Title>{title}</Title>
      <Question>
        {subtitle}{' '}
        {data
          .filter(
            (x) =>
              (x.haveEmptySelectedPlaces !== undefined &&
                x.haveEmptySelectedPlaces) ||
              (x.fulfilled !== undefined && !x.fulfilled)
          )
          .map((x, key, arr) => {
            if (key === 0) {
              return (
                <React.Fragment key={x.id}>
                  <BlueText>{x.name || x.route}</BlueText>
                </React.Fragment>
              );
            }
            if (key === arr.length - 1) {
              return (
                <React.Fragment key={x.id}>
                  {' '}
                  и <BlueText>{x.name || x.route}</BlueText>
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={x.id}>
                {' '}
                ,<BlueText>{x.name || x.route}</BlueText>
              </React.Fragment>
            );
          })}
      </Question>
      <ActionsBlock>
        <StyledButton onClick={onConfirm} type="primary">
          {buttonsText[0]}
        </StyledButton>
        <Button onClick={props.onClose} type="outlined">
          {buttonsText[1]}
        </Button>
      </ActionsBlock>
    </StyledPopup>
  );
};

export default Modal;
