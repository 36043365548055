import { createAction, createReducer } from 'redux-act';
import {
  BookingPlaces,
  BusBookingOrderCreation,
  BusBookingStore,
  BusOrder,
  BusOrderDetailsSet,
  OrderDetailsPreparedData,
} from './dto/BookBusTicketDto';
import { BUS_BOOKING_STATUS } from './constants';

export const busGetOrderDetails = createAction<OrderDetailsPreparedData>(
  '@busBooking/getFreePlaces',
);

export const busSetOrderDetails = createAction<BusOrderDetailsSet>(
  '@busBooking/setFreePlaces',
);

export const setBusBookingStatus = createAction<{
  status: keyof typeof BUS_BOOKING_STATUS;
}>('@busTickets/setBusBookingStatus');

export const setBusBookingOrderData = createAction<{
  orderData: BusOrder;
}>('@busBooking/setBusBookingOrderData');

export const bookBusRequest =
  createAction<BusBookingOrderCreation>('@busBooking/create');

export const setBookingPlace = createAction<BookingPlaces>(
  '@busBooking/setBookingPlace',
);

export const BusBookingState: BusBookingStore = {
  currentStatus: null,
  forward: null,
  backward: null,
  orderData: null,
  places: null,
};

const busBooking = createReducer({}, BusBookingState);

busBooking.on(setBusBookingStatus, (state, payload) => {
  return { ...state, currentStatus: payload.status };
});

busBooking.on(busSetOrderDetails, (state, payload) => {
  return { ...state, forward: payload.forward, backward: payload.backward };
});

busBooking.on(setBusBookingOrderData, (state, payload) => {
  return { ...state, orderData: payload.orderData };
});

busBooking.on(setBookingPlace, (state, payload) => {
  return { ...state, places: { ...(state.places || {}), ...payload } };
});

export default busBooking;
