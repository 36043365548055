import { OrderPosition } from './types/GetOrderByNumberDto';
import { createSelector } from 'reselect';
import { ApplicationState } from '@modules/index';
import {
  STATUS,
  Airline,
  OrderDetailState,
  SegmentGroup,
  Segment,
} from './types';
import _ from 'lodash';
import { DecksEntity } from '@modules/booking';
export const isOrdersLoading = (state: ApplicationState) =>
  state.orders.status === STATUS.loading;
export const getCurrentOrderId = (state: ApplicationState) =>
  state.orders.orderDetail.data.id;
export const getDetailOrder = (state: ApplicationState) =>
  state.orders.orderDetail.data;

export const getOrderDetailsCleaned = (state: ApplicationState) =>
  state.orders.orderDetail.orderDetailsCleaned;

const getCalculationData = (state: ApplicationState) =>
  state.orders.orderDetail.calculation;

const getNewAncillary = (state: ApplicationState) =>
  state.orders.orderDetail.newAncillary;

const getCalculationStatus = (state: ApplicationState) =>
  state.orders.orderDetail.calculationLoadStatus;

export const getAddictionalServicesState = (state: ApplicationState) =>
  state.orders.orderDetail.addictionalServices;

export const getAddictionalServicesStateNew = (state: ApplicationState) =>
  state.orders.orderDetail.data.additionalServices;

export const orderListStateSelector = (state: ApplicationState) =>
  state.orders.orderList;

export const orderListSelector = (state: ApplicationState) =>
  state.orders.orderList.orders;

export const getPrintedOrder = (state: ApplicationState) =>
  state.orders.orderDetail.printedOrder;

export const getCalculationSelector = createSelector(
  [getCalculationData, getCalculationStatus],
  (value, status) => ({ value, status }),
);

export const getDataForAddTicketBody = createSelector(
  getDetailOrder,
  ({ id, customerId, orderPositions }) => ({
    id,
    customerId,
    passengers: orderPositions[0].passengers,
  }),
);

export const getAddictionalServices = createSelector(
  [getDetailOrder, getAddictionalServicesState],
  (detailOrder, addictionalServices) => ({
    canShowExtraServices: detailOrder.availableActions.includes(
      'DISPLAY_ADDITIONAL_SERVICES',
    ),
    items: addictionalServices,
  }),
);

export const getAddictionalServicesDetails = createSelector(
  getDetailOrder,
  ({ additionalServices }) => ({
    items: additionalServices,
  }),
);

export const getAddictionalServicesNew = createSelector(
  [getDetailOrder, getAddictionalServicesStateNew],
  (detailOrder, addictionalServicesNew) => ({
    canShowExtraServices: detailOrder.availableActions.includes(
      'DISPLAY_ADDITIONAL_SERVICES',
    ),
    items: addictionalServicesNew,
  }),
);

export const getPosition = (state: OrderDetailState, index: number) => {
  return state.orderPositions[index];
};

export const getMainPosition = createSelector(
  getDetailOrder,
  ({ orderPositions, ...state }) => {
    return { ...state, ...orderPositions[0] };
  },
);

export const getSegmentGroups = createSelector(
  getMainPosition,
  ({ segmentGroups }) => {
    return segmentGroups;
  },
);

export const getOrderStatus = createSelector(
  getDetailOrder,
  ({ orderStatus }) => orderStatus,
);

export const getPaymentStatus = createSelector(
  getDetailOrder,
  (state) => state.paymentStatus,
);

export const getOrderId = createSelector(getDetailOrder, ({ id }) => id);

export const getFlightsInfo = (
  state: ApplicationState['orders']['orderDetail']['data']['orderPositions'][number],
  mutateTransferCount?: boolean,
) => ({
  ...state,
  transferCount: (state.segmentGroups || []).reduce(
    (acc, x) =>
      acc + x.segments.reduce((acc, x) => acc + x.stopOvers.length, 0),
    0,
  ),
  luggages: (state.tariff?.fareFamilies || []).reduce(
    (acc, x) => ({
      ...acc,
      [`${x.departureAirportCode}-${x.arrivalAirportCode}`]: '', //x.featuresDescriptionRus
    }),
    {} as { [key: string]: string | null },
  ),
  segmentGroups: (state.segmentGroups || []).map(
    ({ segments, ...flight }, key) => {
      const stopOvers: { duration: number; city: string }[] = [];
      segments.forEach((x) => {
        x.stopOvers.forEach((y) => {
          stopOvers.push({ duration: y.duration, city: x.arrivalCity.code });
        });
      });
      return {
        ...flight,
        flight_Type: state.flight_Type,
        segments,
        transferCount: segments.reduce((acc, x) => acc + x.stopOvers.length, 0),
        stopOvers,
        flightsCount: state.segmentGroups.length,
        index: key,
      };
    },
  ),
  airlines: getAirlinesList2(state.segmentGroups),
});

function getAirlinesList2(segmentGroupList: SegmentGroup[]) {
  const airlines = (segmentGroupList || []).reduce(
    (acc, x) => [
      ...acc,
      ...x.segments.map((segment) => ({
        ...segment.operatingAirline,
      })),
    ],
    [] as Airline[],
  );
  return _.unionBy(airlines, 'code');
}

export const getAirlinesList = createSelector(
  getSegmentGroups,
  (segmentGroups) => {
    const airlines = (segmentGroups || []).reduce(
      (acc, x) => [
        ...acc,
        ...x.segments.map((segment) => ({
          ...segment.operatingAirline,
        })),
      ],
      [] as Airline[],
    );
    return _.unionBy(airlines, 'code');
  },
);

export const getInfoBlockData = createSelector(
  [getDetailOrder, getSegmentGroups, getAirlinesList, getMainPosition],
  (state, segmentGroups, airlines, mainPosition) => ({
    orderId: state.id,
    orderNumber: state.orderNumber,
    createdDate: state.createdDate,
    orderStatus: state.orderStatus,
    amount: state.amount,
    amountExtraPayment: state.amountExtraPayment,
    luggages: (mainPosition.tariff?.fareFamilies || []).reduce(
      (acc, x) => ({
        ...acc,
        [`${x.departureAirportCode}-${x.arrivalAirportCode}`]: '', //x.featuresDescriptionRus
      }),
      {} as { [key: string]: string | null },
    ),
    bookingNumber: state.orderPositions[0]
      ? state.orderPositions[0].bookingCode
      : '',
    paymentStatus: state.paymentStatus,
    transferCount: (segmentGroups || []).reduce(
      (acc, x) =>
        acc + x.segments.reduce((acc, x) => acc + x.stopOvers.length, 0),
      0,
    ),
    segmentGroups: (segmentGroups || []).map(({ segments, ...flight }) => {
      return {
        ...flight,
        segments,
        transferCount: (segments || []).reduce(
          (acc, x) => acc + x.stopOvers.length,
          0,
        ),
      };
    }),
    airlines: airlines,
    expiredTime: state.cancellationTime,
    availableActions: state.availableActions,
    notifications: state.notifications,
    invoice: state.invoice.total,
    availableShipmentAllowed: state.availableShipmentAllowed,
    agentPrice: state.totalDetalization.positionList.find(
      (x) => x.positionName === 'Размер сбора субагента',
    ),
    agentPriceTotal: state.totalDetalization.positionList.find(
      (x) => x.positionName === 'Сумма оплаты для агента',
    ),
  }),
);

export const getTariffInfo = createSelector(
  getDetailOrder,
  (state) =>
    state.orderPositions[0]?.tariff.fareFamilies &&
    state.orderPositions[0].tariff.fareFamilies[0],
);

export const getDetailOrderContactInfo = createSelector(
  getDetailOrder,
  (state) => state.contactsInfo,
);

export const getRoutes = (
  state:
    | ApplicationState['orders']['orderDetail']['data']['orderPositions'][number]
    | OrderPosition,
) => {
  return state?.segmentGroups?.map((value) => ({
    name: `${value.departureCity?.code} - ${value.arrivalCity?.code}`,
    description: state.tariff.description,
  }));
};

export const getTotalTransferCount = (
  state: ApplicationState['orders']['orderDetail']['data']['orderPositions'][number],
) => {
  return state.segmentGroups.reduce((acc, { segments }) => {
    return acc + segments.reduce((acc, x) => acc + x.stopOvers.length, 0);
  }, 0);
};

export const ancillaryServicesSelector = createSelector(
  [getDetailOrder],
  (state) => {
    const meal = state.orderPositions
      .map((x) => {
        const passengers = x.passengers
          .map((x) => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? ' ' + x.patronymic : ''
            }`;

            const items = x?.meals?.items;
            return { name, items };
          })
          .filter((x) => x.items.length > 0);
        return passengers;
      })
      .filter((x) => !!x?.length)
      .flat();

    const fakeMeals = state.orderPositions
      .map((x) => {
        const passengers = x.passengers.map((x) => {
          const items = x?.meals?.items;

          const groupItems = _.groupBy(items, 'segmentFromTo');
          return Object.fromEntries(
            Object.entries(groupItems).map(([key, value]) =>
              // Modify key here
              [` ${key}`, value],
            ),
          );
        });
        return passengers;
      })
      .flat();

    const mergedMeals = _.mergeWith(
      {},
      ...fakeMeals,
      (objValue: any, srcValue: any) => (objValue || [])?.concat(srcValue),
    );

    const luggage = state.orderPositions
      .map((x) => {
        const passengers = x.passengers
          .map((x) => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? ' ' + x.patronymic : ''
            }`;
            const items = x?.luggage?.items;

            return { name, items };
          })
          .filter((x) => x.items.length > 0);
        return passengers;
      })
      .filter((x) => !!x?.length)
      .flat();

    const fakeLuggage = state.orderPositions
      .map((x) => {
        const passengers = x.passengers.map((x) => {
          const items = x?.luggage?.items;

          const groupItems = _.groupBy(items, 'segmentFromTo');

          return groupItems;
        });
        return passengers;
      })
      .flat();

    const mergedLuggage = _.mergeWith(
      {},
      ...fakeLuggage,
      (objValue: any, srcValue: any) => (objValue || []).concat(srcValue),
    );

    type SeatPassenger = {
      name: string;
      items: (typeof state.orderPositions)[number]['passengers'][number]['seatsList']['items'];
    };

    const seats = _(state.orderPositions)
      .map((x) => {
        const passengers = x.passengers
          .map((x) => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? ' ' + x.patronymic : ''
            }`;

            const items = x.seatsList?.items;
            return { name, items };
          })
          .filter((x) => x.items && x.items.length > 0);

        return passengers;
      })
      .filter((x) => !!x?.length)
      .flatten()
      .value() as unknown as SeatPassenger[];

    const fakeSeats = state.orderPositions
      .map((x) => {
        const passengers = x.passengers.map((x) => {
          const items = x?.seatsList?.items;

          const groupItems = _.groupBy(items, 'segmentFromTo');

          return groupItems;
        });
        return passengers;
      })
      .flat();

    const mergedSeats = _.mergeWith(
      {},
      ...fakeSeats,
      (objValue: any, srcValue: any) => (objValue || []).concat(srcValue),
    );

    const others = state.orderPositions
      .map((x) => {
        const passengers = x.passengers
          .map((x) => {
            const name = `${x.firstName} ${x.lastName}${
              x.patronymic ? ' ' + x.patronymic : ''
            }`;
            const items = x?.otherAncillaryServices?.items;
            return { name, items };
          })
          .filter((x) => x.items.length > 0);
        const positionName = createFromToString(x.segmentGroups);
        return { positionName, passengers };
      })
      .filter((x) => !!x?.passengers?.length);

    const SEATS_TOTAL = seats.reduce(
      (acc, x) =>
        acc +
        (x.items || [])
          .filter((x) => x.status.code !== 'Refunded')
          .reduce((acc, x) => acc + x.price, 0),

      0,
    );

    const MEAL_TOTAL = meal.reduce(
      (acc, x) =>
        acc +
        (x.items || [])
          .filter((x) => x.status.code !== 'Refunded')
          .reduce((acc, x) => acc + x.price, 0),

      0,
    );

    const LUGGAGE_TOTAL = luggage.reduce(
      (acc, x) =>
        acc +
        (x.items || [])
          .filter((x) => x.status.code !== 'Refunded')
          .reduce((acc, x) => acc + x.price, 0),

      0,
    );

    const OTHERS_TOTAL = others.reduce(
      (acc, x) =>
        acc +
        x.passengers.reduce(
          (acc, x) =>
            acc +
            x.items
              .filter((x) => x.status.code !== 'Refunded')
              .reduce((acc, x) => acc + x.price, 0),
          0,
        ),
      0,
    );

    return {
      meal,
      luggage,
      mergedLuggage,
      mergedMeals,
      mergedSeats,
      others,
      seats,
      total: MEAL_TOTAL + LUGGAGE_TOTAL + OTHERS_TOTAL + SEATS_TOTAL,
    };
  },
);

function createFromToString(segmentGroups: SegmentGroup[]) {
  return (segmentGroups || [])
    .map((value) => {
      if (value.segments.length === 1) {
        return [...value.segments, ...value.segments].map(extractCity);
      } else {
        return value.segments.map(extractCity);
      }
    })
    .map(_.flatten)
    .map((value, index, arr) => removeDuplicates(value, arr[index + 1]))
    .map((value) => value?.join(' - '))
    .join(' - ');
}

function extractCity(value: Segment, index: number, arr: Segment[]) {
  if (index !== 0 && index !== arr.length - 1)
    return [value.departureCity?.name, value.arrivalCity?.name];
  return index === 0 ? value.departureCity?.name : value.arrivalCity?.name;
}

function removeDuplicates(x1: string[], x2?: string[]) {
  if (!x2) {
    return x1;
  }
  if (x1[x1.length - 1] === x2[0]) {
    const x1Clone = [...x1];
    x1Clone.length = x1.length - 1;
    return x1Clone;
  }
}

export const newAncillaryServicesSelector = createSelector(
  [getDetailOrder, getNewAncillary],
  (state, newAncillary) => {
    const segments = state.orderPositions
      .map((x) => x.segmentGroups.map((y) => y.segments.map((z) => z)).flat())
      .flat();

    const groupedSegments = segments.reduce(
      (acc, x) => ({ ...acc, [x.id]: x }),
      {} as { [key: string]: (typeof segments)[0] },
    );

    const availableSegmentsMealsIds = newAncillary?.meals?.map((x: any) =>
      x.items
        ?.map((y: any) => y.aviaSegmentIds)
        ?.flat()
        ?.filter(
          (value: any, index: any, array: any) =>
            array?.indexOf(value) === index,
        ),
    );

    const newAvailableSegmentsMealsIds = availableSegmentsMealsIds
      ?.reduce((a: [], b: any) => a.concat(b), [])
      ?.filter(
        (value: any, index: any, array: any) => array?.indexOf(value) === index,
      );

    const availableSegmentsLuggageIds = newAncillary?.luggages?.map((x: any) =>
      x.items
        ?.map((y: any) => y.aviaSegmentIds)
        ?.flat()
        ?.filter(
          (value: any, index: any, array: any) =>
            array?.indexOf(value) === index,
        ),
    );

    const newAvailableSegmentsLuggageIds = availableSegmentsLuggageIds
      ?.reduce((a: [], b: any) => a.concat(b), [])
      ?.filter(
        (value: any, index: any, array: any) => array?.indexOf(value) === index,
      );

    const availableSegmentsSeatsIds = Object.keys(newAncillary?.seatMaps);

    return {
      groupedSegments,
      segments,
      availableSegmentsMealsIds: newAvailableSegmentsMealsIds,
      availableSegmentsLuggageIds: newAvailableSegmentsLuggageIds,
      availableSegmentsSeatsIds: availableSegmentsSeatsIds,
    };
  },
);
