import { Api } from '@utils';
import {
  TrainRefundCompletePayload,
  TrainRefundCompleteResponse,
  TrainOrderRefundPayload,
  TrainRefundResponse,
  TrainOrderCancelPayload,
} from '@modules/trainOrders/dto';

const { CONTENT_TYPE } = Api;

const TRAIN_ORDER_REFUND = process.env.REACT_APP_TRAIN_ORDER_REFUND as string;
const ORDER_SERVICE_HOST = process.env.REACT_APP_ORDER_SERVICE_HOST3 as string;
const TRAIN_ORDER_REFUND_CONFIRM = process.env
  .REACT_APP_TRAIN_ORDER_REFUND_CONFIRM as string;
const TRAIN_CANCEL_ORDER = process.env.REACT_APP_TRAIN_CANCEL_ORDER as string;

export const getTrainRefund = function (
  params: TrainOrderRefundPayload
): Promise<TrainRefundResponse> {
  const getTrainRefundLink = Api.createFetchUrl(
    ORDER_SERVICE_HOST,
    TRAIN_ORDER_REFUND
  );
  const url = getTrainRefundLink('', {
    orderNumber: params.orderId + '',
  });
  return Api.request(
    {
      url: url,
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json)
    .then((data: TrainRefundResponse) => data);
};

export const postTrainRefundComplete = function (
  params: TrainRefundCompletePayload
): Promise<TrainRefundCompleteResponse> {
  const postTrainRefundCompleteLink = Api.createFetchUrl(
    ORDER_SERVICE_HOST,
    TRAIN_ORDER_REFUND_CONFIRM
  );

  const url = postTrainRefundCompleteLink('', {});
  return Api.request(
    {
      url: url,
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json)
    .then((data: TrainRefundCompleteResponse) => data);
};

export const trainCancelOrder = function (params: TrainOrderCancelPayload) {
  const postTrainCancelOrderLink = Api.createFetchUrl(
    ORDER_SERVICE_HOST,
    TRAIN_CANCEL_ORDER
  );

  const url = postTrainCancelOrderLink('', {});
  return Api.request(
    {
      url: url,
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then((response) => {
      // Ответ пустой, поэтому возвращаем null или пустой объект
      if (response.status === 200) {
        return null;
      }
      return Api.json(response);
    })
    .then((data) => data);
};
