import { Api } from '@utils';
import { ORDER_SERVICE_HOST } from '@modules/constants';
import {
  GeneratedTuiOrderServiceApiDomainModelUserResponsesGetUserRolesResponse,
  GeneratedTuiOrderServiceApiDomainModelAgentResponsesGetAgentUsersResponse,
  GeneratedAssignUsersToAgentRequest,
  GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserRoleRequest,
  GeneratedTuiOrderServiceApiDomainModelUserResponsesSetUserActiveResponse,
  GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserActiveRequest,
  GeneratedAssignUsersToAgentResponse,
  GeneratedTuiOrderServiceApiRequestsAddSimpleUserRequest,
  GeneratedOrderHistoryModel,
} from './types';
import { handleStatuses } from '../../../utils/fetch/handlers';
import { CONTENT_TYPE } from '../../../utils/fetch';
import { trackPromise } from 'react-promise-tracker';

const AGENTS_HOST = process.env.REACT_APP_AGENTS_HOST as string;

const FILE_HOST = process.env.REACT_APP_FILE_HOST as string;

const LOG_HOST = process.env.REACT_APP_LOG_HOST as string;

const createUserFetchUrl = Api.createFetchUrl(
  ORDER_SERVICE_HOST,
  process.env.REACT_APP_ORDER_USER as string
);

const createAgentFetchUrl = Api.createFetchUrl(
  AGENTS_HOST,
  process.env.REACT_APP_AGENT_USER as string
);

const createLogFetchUrl = Api.createFetchUrl(
  LOG_HOST,
  process.env.REACT_APP_LOG as string
);

const createFileFetchUrl = Api.createFetchUrl(
  FILE_HOST,
  process.env.REACT_APP_USEFUL_PDF as string
);

const END_POINTS = {
  getUserRoles: process.env.REACT_APP_GET_USER_ROLE as string,
  getAgentUsers: process.env.REACT_APP_GET_USERS as string,
  addUserToAgent: process.env.REACT_APP_ADD_USER_TO_AGENT as string,
  setRoleToUser: process.env.REACT_APP_SET_ROLE_TO_USER as string,
  setUserActive: process.env.REACT_APP_SET_USER_ACTIVE as string,
  createUserInSamo: process.env.REACT_APP_CREATE_USER_IN_SAMO as string,
  agentLog: process.env.REACT_APP_GET_AGENT_LOG as string,
  usefulInfo: process.env.REACT_APP_USEFUL_PDF_LINK as string,
};

export function getAgentUserRoles(): Promise<GeneratedTuiOrderServiceApiDomainModelUserResponsesGetUserRolesResponse> {
  return Api.request({
    url: createUserFetchUrl(END_POINTS.getUserRoles),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export function getAgentUsers(
  AgentId?: string
): Promise<GeneratedTuiOrderServiceApiDomainModelAgentResponsesGetAgentUsersResponse> {
  return Api.request({
    url: createUserFetchUrl(END_POINTS.getAgentUsers, { AgentId: AgentId }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export function addAgentUser(
  data?: GeneratedAssignUsersToAgentRequest
): Promise<GeneratedAssignUsersToAgentResponse> {
  return Api.request({
    url: createAgentFetchUrl(END_POINTS.addUserToAgent),
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export const setRoleToUser = (
  data?: GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserRoleRequest
): Promise<any> =>
  trackPromise(
    Api.request({
      url: createUserFetchUrl(END_POINTS.setRoleToUser),
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    })
      .then(Api.statuses)
      .then(Api.json)
      .then((data) => data)
  );

export function setAgentUsers(
  data?: GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserActiveRequest
): Promise<GeneratedTuiOrderServiceApiDomainModelUserResponsesSetUserActiveResponse> {
  return Api.request({
    url: createUserFetchUrl(END_POINTS.setUserActive),
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export function createUserInSamo(
  data?: GeneratedTuiOrderServiceApiRequestsAddSimpleUserRequest
): Promise<any> {
  return Api.request({
    url: createUserFetchUrl(END_POINTS.createUserInSamo),
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export function getAgentLog(
  AgentId?: string
): Promise<GeneratedOrderHistoryModel> {
  return Api.request({
    url: createLogFetchUrl(END_POINTS.agentLog, { AgentId: AgentId }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export function getUsefulInfo(token: string | null) {
  return Api.request(
    {
      url: createFileFetchUrl(END_POINTS.usefulInfo),
      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
        Authorization: `Bearer ${token}`,
      },
    },
    'AUTH'
  )
    .then(handleStatuses())
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file.pdf';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}
