import React from 'react';
import styled from 'styled-components';
import { Text, Button, Checkbox } from '@components/ui';
import { useNotifications } from '../useNotification';
import { SegmentEvents, refreshOrder } from '@modules/orders';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import oldIconPath from '@assets/media/shared/old-segment-plane.svg';
import newIconPath from '@assets/media/shared/new-segment-plane.svg';
import womanPasIcon from '@assets/media/womanPasIcon.svg';
import manPasIcon from '@assets/media/manPasIcon.svg';

import { Manager } from '@modules/appeals';
import { useDispatch } from 'react-redux';
import ConfirmModal from './ConfirmCancelation';

interface ConfirmRulesProps {
  error?: string;
}

const Wrapper = styled.div`
  padding: 24px 24px 20px 24px;
  background: #ffe2e3;
  border: 1px solid #dcdcdc;
  color: ${({ theme }) => theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 12px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SubTitle = styled(Text)`
  display: block;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  & > br {
    display: none;
  }

  & > a {
    font-weight: 600;
    color: #4872f2;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;

    & > br {
      display: initial;
    }
  }
`;

const SegmentBasePart = styled.div`
  display: flex;
  align-items: flex-start;

  & > :first-child {
    margin-right: 11px;
  }

  @media (max-width: 767px) {
    & > :first-child {
      margin-right: 5px;
    }
  }
`;

const Segment = styled.div`
  padding: 15px 0;
`;

const SegmentHead = styled.div`
  width: 100%;

  & > :first-child {
    display: block;
    margin-bottom: 8px;
  }
`;

const GridBlock = styled.div`
  display: grid;
  grid-template-columns: 253px 1fr 85px;

  @media (max-width: 767px) {
    display: block;
  }

  & > * {
    display: none;
  }

  & > :first-child {
    display: block;
  }
`;

const SegmentBigText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`;

const PositionName = styled(SegmentBigText)`
  display: block;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const SegmentText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`;

const Actions = styled.div`
  margin-top: 25px;
  & > button {
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    & > button {
      margin-right: 0;
      margin-bottom: 15px;
      width: 100%;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 20px;
`;

const OldSegmentsWrapper = styled.div`
  background: #f2f2f2;
  border: 1px solid #dcdcdc;
  border-color: #dcdcdc;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 5px;

  & > div {
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    border-bottom: none;
  }

  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    padding: 15px;

    & > :first-child {
      margin-bottom: 0;
    }

    & > :last-child {
      padding-bottom: 0;
    }
  }
`;

const NewSegmentsWrapper = styled(OldSegmentsWrapper)`
  background: #edf1fe;
  border-color: #4872f2;

  & > :first-child {
    color: #4872f2;
  }
`;

const SegmentsWrapperList = styled.div`
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    & > div {
      margin-bottom: 15px;
    }
  }
`;

const Img = styled.img`
  width: 34px;
  height: 34px;

  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const ConfirmRules = styled.div<ConfirmRulesProps>`
  margin-top: 18px;
  display: flex;
  align-items: center;
  & > ${Text} {
    display: block;
    margin-left: 10px;
    ${({ error }) => (error ? 'color: red;' : '')}
  }
  @media (max-width: 767px) {
    align-items: flex-start;

    & > ${Text} {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileSegmentDescription = styled.div`
  margin-top: 15px;
  & > * {
    display: block;
    margin-bottom: 5px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const NewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;

  @media (max-width: 789px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (min-width: 790px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const WomanPasIcon = styled.img.attrs({ src: womanPasIcon })`
  width: 34px;
  height: 34px;
`;

const ManPasIcon = styled.img.attrs({ src: manPasIcon })`
  width: 34px;
  height: 34px;
`;

export default function Notification() {
  const _context = useNotifications();

  const dispatch = useDispatch();
  const [isConfirming, setConfirming] = React.useState(false);
  const [isCanceling, setCanceling] = React.useState(false);
  const [{ isRulesAccepted, acceptError }, setRulesState] = React.useState({
    isRulesAccepted: false,
    acceptError: undefined as string | undefined,
  });
  const [isModal, setModal] = React.useState(false);

  const toTime = (date: string): string => moment(date).format('DD.MM.YYYY');

  return (
    <>
      {((_context.notification.type === 'ChangeSegmentDates' &&
        _context?.notification?.segmentEvents?.length !== 0) ||
        (_context.notification.type === 'ChangesPassengers' &&
          _context?.notification?.passengersEvents?.length !== 0)) && (
        <Wrapper>
          <Title>
            {_context.notification.type === 'ChangesPassengers'
              ? 'Внимание! Изменения данных пассажиров'
              : 'Внимание! Изменение полетных данных'}
          </Title>
          <MobileTitle>
            {_context.notification.type === 'ChangesPassengers'
              ? 'Внимание, изменение пассажиров'
              : 'Внимание, изменение рейсов!'}
          </MobileTitle>
          <SubTitle>
            Уважаемый клиент, с вашими{' '}
            {_context.notification.type === 'ChangesPassengers'
              ? 'пассажирами'
              : 'рейсами'}{' '}
            произошли изменения, требующие вашего согласования.
            <br />
            <br /> Для дополнительной информации обратитесь в чат к
            операционисту по{' '}
            <NavLink
              to={`/tickets/${_context.notification.supportTicketNumber}`}
            >
              обращению № {_context.notification.supportTicketNumber}
            </NavLink>
          </SubTitle>
          {_context.notification.type !== 'ChangesPassengers' && (
            <PositionName>
              Позиция заказа: {_context.notification.route}
            </PositionName>
          )}

          <SegmentsWrapperList>
            {_context.notification.type !== 'ChangesPassengers' && (
              <OldSegmentsWrapper>
                <SegmentBigText>Старые данные:</SegmentBigText>
                {(_context.notification.segmentEvents || []).map((x, key) => {
                  const data = transformData(x, 'oldValue');

                  return (
                    <Segment key={key}>
                      <SegmentBasePart>
                        <Img src={oldIconPath} />
                        <SegmentHead>
                          <SegmentBigText>
                            {x.departureCity.name} - {x.arrivalCity.name}{' '}
                            <DesktopText>
                              ({x.departureCity.code} - {x.arrivalCity.code})
                            </DesktopText>
                          </SegmentBigText>
                          <GridBlock>
                            <SegmentText>
                              {data.departureDate} - {data.arrivalDate}
                            </SegmentText>
                            <SegmentText> {data.company}</SegmentText>
                            <SegmentText>{data.flightNumber}</SegmentText>
                          </GridBlock>
                        </SegmentHead>
                      </SegmentBasePart>
                      <MobileSegmentDescription>
                        <SegmentText>
                          <strong>Авиакомпания:</strong> {data.company}
                        </SegmentText>
                        <SegmentText>
                          <strong>Номер рейса:</strong> {data.flightNumber}
                        </SegmentText>
                      </MobileSegmentDescription>
                    </Segment>
                  );
                })}
              </OldSegmentsWrapper>
            )}

            {_context.notification.type === 'ChangesPassengers' && (
              <OldSegmentsWrapper>
                <SegmentBigText>Старые данные:</SegmentBigText>
                {(_context.notification.passengersEvents || []).map(
                  (x: any, key: number) => {
                    return (
                      <Segment key={key}>
                        <SegmentBasePart>
                          {x.fields.find((y: any) => y.description === 'Пол')
                            .oldValue === 'Male' ? (
                            <ManPasIcon />
                          ) : (
                            <WomanPasIcon />
                          )}
                          <SegmentHead>
                            <SegmentBigText>
                              {x.passengerDataFields.map((pasDataField: any) =>
                                pasDataField.description === 'Номер билета'
                                  ? 'билет ' + pasDataField.oldValue + ' '
                                  : pasDataField.oldValue + ' '
                              )}
                            </SegmentBigText>
                            <NewGrid>
                              {x.fields.map((field: any, i: number) => (
                                <>
                                  <SegmentText>
                                    <span
                                      style={{
                                        color: '#737373',
                                      }}
                                    >
                                      {field.description}:&nbsp;
                                    </span>
                                    {field.type === 'datetime'
                                      ? toTime(field.oldValue)
                                      : field.oldValue}
                                    &nbsp;
                                  </SegmentText>
                                </>
                              ))}
                            </NewGrid>
                          </SegmentHead>
                        </SegmentBasePart>
                      </Segment>
                    );
                  }
                )}
              </OldSegmentsWrapper>
            )}

            {_context.notification.type !== 'ChangesPassengers' && (
              <NewSegmentsWrapper>
                <SegmentBigText>Новые данные:</SegmentBigText>
                {(_context.notification.segmentEvents || []).map((x, key) => {
                  const data = transformData(x, 'newValue');

                  return (
                    <Segment key={key}>
                      <SegmentBasePart>
                        <Img src={newIconPath} />
                        <SegmentHead>
                          <SegmentBigText>
                            {x.departureCity.name} - {x.arrivalCity.name}{' '}
                            <DesktopText>
                              ({x.departureCity.code} - {x.arrivalCity.code})
                            </DesktopText>
                          </SegmentBigText>
                          <GridBlock>
                            <SegmentText>
                              {data.departureDate} - {data.arrivalDate}
                            </SegmentText>
                            <SegmentText> {data.company}</SegmentText>
                            <SegmentText>{data.flightNumber}</SegmentText>
                          </GridBlock>
                        </SegmentHead>
                      </SegmentBasePart>
                      <MobileSegmentDescription>
                        <SegmentText>
                          <strong>Авиакомпания:</strong> {data.company}
                        </SegmentText>
                        <SegmentText>
                          <strong>Номер рейса:</strong> {data.flightNumber}
                        </SegmentText>
                      </MobileSegmentDescription>
                    </Segment>
                  );
                })}
              </NewSegmentsWrapper>
            )}
            {_context.notification.type === 'ChangesPassengers' && (
              <NewSegmentsWrapper>
                <SegmentBigText>Новые данные:</SegmentBigText>
                {(_context.notification.passengersEvents || []).map(
                  (x: any, key: number) => {
                    return (
                      <Segment key={key}>
                        <SegmentBasePart>
                          {x.fields.find((y: any) => y.description === 'Пол')
                            .oldValue === 'Male' ? (
                            <ManPasIcon />
                          ) : (
                            <WomanPasIcon />
                          )}
                          <SegmentHead>
                            <SegmentBigText>
                              {x.passengerDataFields.map((pasDataField: any) =>
                                pasDataField.description === 'Номер билета'
                                  ? 'билет ' + pasDataField.newValue + ' '
                                  : pasDataField.newValue + ' '
                              )}
                            </SegmentBigText>
                            <NewGrid>
                              {x.fields.map((field: any, i: number) => (
                                <>
                                  <SegmentText>
                                    <span
                                      style={{
                                        color: '#737373',
                                      }}
                                    >
                                      {field.description}:&nbsp;
                                    </span>
                                    {field.type === 'datetime'
                                      ? toTime(field.newValue)
                                      : field.newValue}
                                    &nbsp;
                                  </SegmentText>
                                </>
                              ))}
                            </NewGrid>
                          </SegmentHead>
                        </SegmentBasePart>
                      </Segment>
                    );
                  }
                )}
              </NewSegmentsWrapper>
            )}
          </SegmentsWrapperList>

          {_context.notification.type !== 'ChangesPassengers' && (
            <ConfirmRules error={acceptError}>
              <Checkbox
                type="squar"
                error={acceptError}
                checked={isRulesAccepted}
                onChange={(e) => {
                  setRulesState((state) => ({
                    acceptError: undefined,
                    isRulesAccepted: !state.isRulesAccepted,
                  }));
                }}
              />
              <Text>
                Я ознакомлен и согласен с условиями{' '}
                <a
                  style={{ color: '#4872F2', textDecoration: 'none' }}
                  href="/docs/oferta.pdf"
                  target="_blank"
                >
                  Обмена и возврата билета
                </a>
              </Text>
            </ConfirmRules>
          )}
          {_context.notification.type !== 'ChangesPassengers' && (
            <Actions>
              <StyledButton
                isLoading={isConfirming}
                onClick={() => {
                  if (isRulesAccepted) {
                    setConfirming(true);
                    Manager.applySupportTicketCalculation(
                      _context.notification.calculationId
                    )
                      .then(() => {
                        setConfirming(false);
                        dispatch(refreshOrder());
                      })
                      .catch(() => {
                        setConfirming(false);
                        dispatch(refreshOrder());
                      });
                  } else {
                    setRulesState((state) => ({
                      ...state,
                      acceptError: 'ошибочка',
                    }));
                  }
                }}
              >
                Подтвердить изменения
              </StyledButton>
              <StyledButton
                isLoading={isCanceling}
                onClick={() => {
                  setModal(true);
                }}
              >
                Отклонить изменения
              </StyledButton>
            </Actions>
          )}

          <ConfirmModal
            open={isModal}
            onClose={() => setModal(false)}
            onConfirm={() => {
              setCanceling(true);
              setModal(false);
              Manager.declineSupportTicketCalculation(
                _context.notification.calculationId
              )
                .then(() => {
                  setCanceling(false);
                  dispatch(refreshOrder());
                })
                .catch(() => {
                  setCanceling(false);
                  dispatch(refreshOrder());
                });
            }}
          />
        </Wrapper>
      )}
    </>
  );
}

function transformData(
  data: SegmentEvents,
  valueType: 'newValue' | 'oldValue'
) {
  const departureDate = data.fields.find(
    (x) => x.description === 'Дата отправления'
  );
  const arrivalDate = data.fields.find(
    (x) => x.description === 'Дата прибытия'
  );
  const company = data.fields.find(
    (x) => x.description === 'Маркетинговый перевозчик'
  );
  const flightNumber = data.fields.find((x) => x.description === 'Номер рейса');
  return {
    departureDate: departureDate
      ? moment(departureDate[valueType]).format('DD MMM HH:mm')
      : null,
    arrivalDate: arrivalDate
      ? moment(arrivalDate[valueType]).format('DD MMM HH:mm')
      : null,
    company: company ? company[valueType] : null,
    flightNumber: flightNumber ? flightNumber[valueType] : null,
  };
}
