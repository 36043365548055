export enum FareFeatureStatus {
  // включено в тариф
  Included = 'Included',
  // недоступно
  NotOffered = 'NotOffered',
  // доступно за доп. плату
  AtCharge = 'AtCharge',
}

export enum PassengerAgeCategory {
  Adult = 'ADULT',
  Child = 'CHILD',
  Infant = 'INFANT',
}

export enum PassengerTrainAgeCategory {
  Adult = 'Adult',
  Child = 'Child',
  BabyWithoutPlace = 'BabyWithoutPlace',
}

export enum AncillaryServiceType {
  //багаж
  Luggage = 'Luggage',
  //еда
  Meal = 'Meal',
}

export enum AddictionalServiceTypes {
  //смс оповещение
  SMS_Service = 'SMS_Service',
  Return_Guarantee = 'Return_Guarantee',
  SeatMap = 'Seat_Map',
  Payment_Receipt = 'Payment_Receipt',
  SeatMapDetails = 'Seat_Map_Details',

  Ancillary = 'Ancillary',
  AncillaryDetails = 'AncillaryDetails',
}

export enum BookingWarnignTypes {
  //не все доп услуги забронились
  UnconfirmedAncillaryWarning = 'UnconfirmedAncillaryWarning',
  PriceChanged = 'PriceChanged',
}

export enum InsuranceLoadStatus {
  Success,
  Failure,
  Loading,
  Default,
}
