import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import closeIconBlue from '@assets/media/closeIconBlue.svg';
import { Button, Radio } from '@components/ui';
import { Text } from '@components/ui/Typography';
import { Helper } from '@utils';

interface Props {
  type: string;
  active: boolean; // состояние активности тарифа
  disabled: boolean; // состояние доступности тарифа
  name: string; // название тарифа
  value: string; // цена тарифа
  text: string; // краткое описание тарифа
  fullDescription: string; // детальное описание тарифа
  fullDiscountDescription: string; // детальное описание cкидки
  handleChangeTariff: (tariffType: string) => void;
}

const TariffWrapper = styled.div<{ active: boolean, disabled: boolean }>`
  padding: 13px 10px;
  font-family: Open Sans;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 8px;
  background: ${({ active, disabled }) => (disabled ? '#f0f0f0' : active ? '#FBFCFF' : '#FFFFFF')};
  border: 1px solid ${({ active }) => (active ? '#4872F2' : '#e0e0e0')};
`;

const Header = styled.div`
  display: flex;
  gap: 10px;
`;

const HeaderLeft = styled.div``;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Footer = styled.div``;

const ShowTariffDetails = styled(Text)`
  margin-left: auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }
`;

const StyledPopup = styled(Popup)`
  position: relative;

  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }

  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 24px;
    position: relative;
    box-sizing: border-box;
  }
`;

const PopupHeader = styled.h3`
  margin-bottom: 30px;
  margin-top: 0;
`;

const PopupCloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 14px;
  right: 14px;
  padding: 8px;
`;

const TariffName = styled.span`
  color: #4872f2;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
`;

const PopupFooter = styled.div`
  margin-top: 30px;
`;

const CloseIconBlue = styled.img.attrs({ src: closeIconBlue })``;

export const Tariff = (props: Props) => {
  const {
    active,
    name,
    value,
    text,
    fullDescription,
    type,
    fullDiscountDescription,
    disabled,
  } = props;
  const [openDescription, setOpenDescription] = React.useState(false);

  const handleCloseDescription = () => {
    setOpenDescription(false);
  };

  return (
    <TariffWrapper
      active={active}
      disabled={disabled}
      onClick={() => {
        !props.disabled && props.handleChangeTariff(type);
      }}
    >
      <Header>
        <HeaderLeft>
          <Radio disabled={disabled} checked={active} />
        </HeaderLeft>
        <HeaderRight>
          <div>
            <Text color={disabled ? 'gray' : 'black'} bold>{name}</Text>
          </div>
          <div>
            <Text color={disabled ? 'gray' : 'blue'} bold>
              {Number.isNaN(parseInt(value))
                ? value
                : Helper.formatPrice(parseInt(value))}
            </Text>
          </div>
        </HeaderRight>
      </Header>
      <Content>
        <Text size={'small'}>{text}</Text>
      </Content>
      <Footer>
        <ShowTariffDetails
          onClick={(e) => {
            e.stopPropagation();
            setOpenDescription(!openDescription);
          }}
        >
          Подробнее о тарифе
        </ShowTariffDetails>
      </Footer>
      <StyledPopup open={openDescription} onClose={handleCloseDescription}>
        <PopupHeader>
          Условия тарифа <TariffName>{name}</TariffName>{' '}
        </PopupHeader>
        <Text size={'big'}>{fullDescription}</Text>
        <br />
        <br />
        <Text fontWeight={'600'} size={'big'}>
          {fullDiscountDescription}
        </Text>
        <PopupFooter>
          <Button
            disabled={disabled}
            template={'blue'}
            onClick={() => {
              handleCloseDescription();
              !props.disabled && props.handleChangeTariff(type);
            }}
          >
            Выбрать тариф
          </Button>
        </PopupFooter>
        <PopupCloseButton onClick={handleCloseDescription}>
          <CloseIconBlue />
        </PopupCloseButton>
      </StyledPopup>
    </TariffWrapper>
  );
};
