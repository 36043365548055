import React, { ReactElement } from 'react';
import { FlightEntity, PriceItem } from '@modules/booking';
import FlightsList from '@components/booking/YourFlight/FlightsList';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { busToFlightEntity } from '@components/bus/Booking/controller';
import { BusForRouteDetail } from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import PassengerPrice from '@components/booking/PassengerPrice/PassengerPrice';
import { Separator } from '@components/common';
import { PassengerBusInfo } from '@modules/busTickets';

const Wrapper = styled.div`
  margin-top: 35px;
  padding: 20px 0;
  border-radius: 8px;
  border: 1px solid #dcdcdc;

  @media (min-width: 768px) {
    padding: 24px 0;
    margin-top: 0;
    min-width: 380px;
  }
`;

export const TopPart = styled.div`
  padding: 0 14px;

  @media (min-width: 768px) {
    padding: 0 24px;
  }
`;

const Title = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
    color: #3c3c3c;
  }
`;

export interface Props {
  forward: BusForRouteDetail;
  backward?: BusForRouteDetail;
  ageInfo: string[];
}

const YourTrip = ({ forward, backward, ageInfo }: Props): ReactElement => {
  const trip: FlightEntity[] = [busToFlightEntity(forward)];
  let title = `${forward.fromStation.name_ru} — ${forward.toStation.name_ru}`;

  const getTicketsData =
    (details: BusForRouteDetail) =>
    (age: string): PriceItem => ({
      description: `Билет, ${age}`,
      cost: details.price,
      type: 'old',
      quantity: 1,
      id: details.raceId + age,
    });

  const pricing = {
    total:
      forward.price * (forward.totalPlaceQuantity || 1) +
      (backward?.price || 0) * (backward?.totalPlaceQuantity || 1),
    priceItems: ageInfo.map(getTicketsData(forward)),
  };

  if (backward !== undefined) {
    title = `${title} — ${backward.toStation.name_ru}`;
    trip.push(busToFlightEntity(backward));

    pricing.priceItems = [
      ...(pricing.priceItems || []),
      ...ageInfo.map(getTicketsData(backward)),
    ];
  }

  return (
    <Wrapper>
      <TopPart>
        <Title fontWeight="600" size="big">
          Ваша поездка
        </Title>
        <FlightsList items={trip} />
        <Separator />
      </TopPart>
      <PassengerPrice
        priceType={'train'}
        noTimes
        title={title}
        total={pricing.total}
        subagentTotal={0}
        detailList={pricing.priceItems || []}
        isShowCommission={false}
        chosenPaymentMethod={'Российская карта'}
      />
    </Wrapper>
  );
};

export default YourTrip;
