import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { TITLE, DESCRIPTION, PASSENGERS, BTN_LINK } from './formData';
import {
  Wrapper,
  Heading,
  Description,
  FormContainer,
  Body,
  Header,
  FormHeading,
  LinkBtn,
  PassengerIcon,
  ChildIcon,
  HeaderLeft,
  PlacesContainer,
} from './styles';
import { renderFields } from './renderFields';
import { PassengersFormData } from '@components/bus/Booking/components/Passengers/interfaces';
import Info from '@components/bus/Booking/components/Info';
import {
  getCountries,
  setCountriesToFormData,
  filterDocumentTypes,
} from './controller';
import { CountryListItemType } from '@components/booking/PassengersBlock/types';
import { Props } from './interfaces';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@app/modules';
import { DocumentTypeIds } from '@app/utils/constants/doctypes';

const Passengers = ({
  setFieldValue,
  setFieldTouched,
  handleChange,
  values,
  passengersCount,
  errors,
  touched,
  ageInfo,
  backward,
  places,
}: Props): ReactElement => {
  const allowedDocumentTypes = useSelector(
    (state: ApplicationState) => state.trainBooking.allowedDocumentTypes,
  );

  const [countriesList, setCountriesList] = useState<CountryListItemType[]>([]);
  const [passengersFields, setPassengersFields] =
    useState<PassengersFormData[]>(PASSENGERS);

  const updatePassengersFields = (): void => {
    if (countriesList.length === 0) return;

    const newFields = passengersFields.map(
      setCountriesToFormData(countriesList),
    );
    setPassengersFields(newFields);
  };

  const onInit = (): void => {
    getCountries(setCountriesList);
  };

  useEffect(onInit, []);
  useEffect(updatePassengersFields, [countriesList]);

  const renderPassenger = (_: any, passengerCount: number): ReactElement => {
    const passengerKey = passengerCount + 1;
    const birthday = values[`passenger-${passengerKey}`].dob;
    const citizenship = values[`passenger-${passengerKey}`].citizenship;

    const setDocTypes = () => {
      switch (ageCategory) {
        case 'CHILD':
          return filterDocumentTypes(
            'Child',
            allowedDocumentTypes as DocumentTypeIds[],
            citizenship,
            birthday,
          );
        case 'INFANT': {
          return filterDocumentTypes(
            'Infant',
            allowedDocumentTypes as DocumentTypeIds[],
            citizenship,
            birthday,
          );
        }
        case 'ADULT':
        default: {
          return filterDocumentTypes(
            'Adult',
            allowedDocumentTypes as DocumentTypeIds[],
            citizenship,
            birthday,
          );
        }
      }
    };

    const setAgeCategory = () => {
      if (birthday === '') return 'ADULT';

      if (moment(birthday).isAfter(moment().subtract(14, 'years'))) {
        return 'CHILD';
      }

      if (moment(birthday).isAfter(moment().subtract(1, 'years'))) {
        return 'INFANT';
      }
    };

    const ageCategory = useMemo(setAgeCategory, [birthday]);
    const documentTypes = useMemo(setDocTypes, [ageCategory]);

    return (
      <FormContainer key={`passenger-${passengerKey}`}>
        <Header>
          <HeaderLeft>
            {ageInfo[passengerCount] !== 'Ребенок' && <PassengerIcon />}
            {ageInfo[passengerCount] === 'Ребенок' && <ChildIcon />}
            <FormHeading>
              Пассажир {passengerKey}
              {`, ${ageInfo[passengerCount]}`}
            </FormHeading>
          </HeaderLeft>
          <LinkBtn>{BTN_LINK}</LinkBtn>
        </Header>
        <Body>
          {passengersFields.map(
            renderFields({
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleChange,
              values,
              documentTypes,
              key: `passenger-${passengerKey}`,
            }),
          )}
        </Body>
        <PlacesContainer>
          <span>
            <b>
              Туда - Место {places.forward?.[passengerCount] || 'не выбрано'}
            </b>
          </span>
          {backward !== undefined && (
            <span>
              <b>
                Обратно - Место{' '}
                {places.backward?.[passengerCount] || 'не выбрано'}
              </b>
            </span>
          )}
        </PlacesContainer>
      </FormContainer>
    );
  };

  return (
    <Wrapper>
      <Heading>{TITLE}</Heading>
      <Description>{DESCRIPTION}</Description>
      <Info>
        <b>
          В одном заказе вы можете забронировать места только для 6 пассажиров!
        </b>
        <br /> При необходимости создайте второе бронирование для оставшихся
        пассажиров.
      </Info>

      {Array(passengersCount).fill('').map(renderPassenger)}
    </Wrapper>
  );
};

export default Passengers;
