import React from 'react';
import CreateFormFailure from './CreateFormFailure';
import CreateFormSuccess from './CreateFormSuccess';
import CreateFormDefault from './CreateFormDefault';
import CreateFormRefund from './CreateFormRefund';
import Loader from './Loader';
import { APPEAL_STEP, getAddAppealState } from '@modules/appeals';
import { useSelector } from 'react-redux';
import CreateFormRefundInfo from './CreateFormRefundInfo';
import {
  CreateFormRefundSuccess,
  CreateFormRefundDecline,
} from './CreateFormRefundResult';

const Router = () => {
  const { STEP } = useSelector(getAddAppealState);

  React.useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  switch (STEP) {
    case APPEAL_STEP.DEFAULT:
    case APPEAL_STEP.REFUND_COMMON:
      return <CreateFormDefault />;
    case APPEAL_STEP.REFUND:
    case APPEAL_STEP.REFUND_AUTO:
      return <CreateFormRefund />;
    case APPEAL_STEP.REFUND_CONFIRM:
    case APPEAL_STEP.REFUND_INFO:
      return <CreateFormRefundInfo />;
    case APPEAL_STEP.REFUND_SUCCESS:
      return <CreateFormRefundSuccess />;
    case APPEAL_STEP.REFUND_DECLINE:
      return <CreateFormRefundDecline />;
    case APPEAL_STEP.REQUEST:
      return <Loader />;
    case APPEAL_STEP.SUCCESS:
      return <CreateFormSuccess />;
    case APPEAL_STEP.FAILURE:
      return <CreateFormFailure />;
  }
};

export default Router;
