import React from 'react';
import { TicketListProps } from './types';
import FlightItem from '@components/booking/FlightDetail/Desktop/FlightItem';

const List: React.FC<TicketListProps> = ({ items, active, baggageDescr }) => {
  return items.map((ticket, key) => (
    <FlightItem
      active={active}
      flightIndex={key}
      length={items.length}
      key={key}
      baggageDescr={baggageDescr}
      separator={key !== items.length - 1}
      {...ticket}
    />
  ));
};

export default List;
