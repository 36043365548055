import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { useSteps, STEPS } from '../useSteps';
import { useRefund } from '../useRefund';
import { Manager } from '@modules/appeals';

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 10px;
  }
  ${({ theme }) => `
    ${theme.max('767px')} {
      flex-direction: column-reverse;
      & > button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  `}
`;

const Description = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  margin-top: 25px;
`;

const Wrapper = styled.div`
  width: 569px;
  padding: 40px;
  box-sizing: border-box;

  ${({ theme }) => `
    ${theme.max('767px')} {
      width: 100%;
      padding: 0;
    }
  `}

  ${Text} {
    display: block;
  }

  & > ${ButtonBlock} {
    margin-top: 40px;
  }
`;

const Refund: React.FC = () => {
  const [, setStep] = useSteps();
  const props = useRefund();

  const { id, onCancel } = props;

  return (
    <Wrapper>
      <Text size={18} bold>
        Отклонение новых данных
      </Text>
      <Description>
        {props.subjectCode !== 'ConsentToChangePassengers'
          ? 'Вы уверены, что хотите отклонить новые полетные данные по вашему перелету?'
          : 'Вы уверены, что хотите отклонить новые данные пассажиров по вашему перелету?.'}
      </Description>
      <ButtonBlock>
        <Button
          type="outlined"
          onClick={() => {
            setStep(STEPS.LOADING);
            Manager.declineSupportTicketCalculation(id)
              .then(() => {
                setStep(STEPS.HIDDEN, 'declined');
                onCancel();
              })
              .catch(() => setStep(STEPS.FAILURE));
          }}
        >
          Да, отклонить данные
        </Button>
        <Button
          type="outlined"
          onClick={() => {
            setStep(STEPS.HIDDEN);
          }}
        >
          Нет, вернуться к обращению
        </Button>
      </ButtonBlock>
    </Wrapper>
  );
};

export default Refund;
