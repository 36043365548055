import {
  ISupportTicketCalculationItemsEntity,
  SegmentEvents,
} from '@modules/orders';
import {
  SupportTicketCalculation,
  SupportTicketCalculationParameter,
  SupportTicketCalculationTax,
  CalculationType,
} from './GetCalculationByNumberDto';
import { RefundProcessType } from '@components/common/FormOfAppeal/CreateForm/static/Refund';
import { TicketType } from '@modules/main';
import { FlightType } from '@components/booking/FlightDetail/types';

export enum STATUS {
  loading = 'loading',
  failure = 'failure',
  success = 'success',
}

//

/** payload */
export type StatusPayload = STATUS | null;

export type AddAppealRequestPayload = {
  text?: string;
  passengers?: {
    aviaPassengerId: string;
    segments?: {
      aviaSegmentId: string;
      ancillaryServiceIds: string[];
    }[];
  }[];
  orderPositionId?: string;
  subject: string;
  insuranceIds?: string[];
  files?: File[];
};

export type GetAppealListPayload = {
  page?: number;
  pageSize?: number;
  statuses?: string[];
  subjects?: string[];
  dateSorting?: string;
  currPage?: number;
  number?: number | string;
};

/** State */

export interface AppealListState extends GetCustomerTicketsDTO {
  currPage: number;
  isLoading?: boolean;
}

export interface CalculationState extends SupportTicketCalculation {
  isLoading: boolean;
  error: null | string;
}

export interface SelectedPassenger {
  id: string;
  firstName: string;
  lastName: string;
  eTicketNumber: string;
  patronymic: string;
  passengerType: string;
}

export interface SelectedTicket {
  id: string;
  firstName: string;
  lastName: string;
  eTicketNumber: string;
  patronymic: string;
  userId: string;
  passengerType?: string;
  price: {
    basePrice: number;
    taxes: number;
    subAgentExtraCharge: number;
    agentExtraCharge: number;
    discount: number;
  };
}

export interface SelectedInsurance {
  agentExtraCharge: number;
  basePrice: number;
  id: string;
  name: string;
  passenger: SelectedPassenger;
  policyId: string;
  eTicketNumber?: string;
}

export interface SelectedAncillaryService {
  agentExtraCharge: number;
  ancillaryServicetId: string;
  basePrice: number;
  emd: string;
  name: string;
  type: string;
  passenger: SelectedPassenger;
}

//TODO add type to passengerEvents

export interface AppealDetailState extends GetTicketDTOBase {
  calculation: CalculationState | null;
  passengerEvents?: any;
}

export type Message = {
  id: string;
  text: string;
  date: string;
  owner: boolean;
  author: {
    name: string;
    icon?: string;
  };
  files: {
    fieldId: string;
    name: string;
  }[];
};

export type MessagesState = Message[];

export type AddMessageBody = {
  text: string;
  ticketId: string;
};

export interface AddTicketRequestBody {
  text?: string;
  passengers?: {
    aviaPassengerId: string;
    segments?: {
      aviaSegmentId: string;
      ancillaryServiceIds: string[];
    }[];
  }[];
  orderId?: string;
  orderPositionId?: string;
  subject: string;
  files?: File[];
}

export type Ticket = {
  id: string;
  number: 1;
  messagesCount: 0;
  orderId: string;
  createdDate: string;
  orderNumber: number;
  unreadMessagesNumber: number;
  transportType?: 'Avia' | 'Railway' | 'Bus';
  flight_Type: FlightType;
  status: {
    code: string;
    description: string;
  };
  subject: {
    code: string;
    description: string;
  };
  userId: string;
  hasUnreadCalculation: boolean;
  ticketTypes: TicketType[];
};

export interface GetCustomerTicketsDTO {
  pageCount: number;
  tickets: Ticket[] | null;
}

export type TicketMessage = {
  authorName: string;
  createdDate: string;
  id: string;
  lastName: null;
  status: { name: string; description: string };
  text: string;
  isOwner: boolean;
  files: {
    fieldId: string;
    name: string;
  }[];
};

export interface SupportTicketCalculationDto extends SupportTicketCalculation {}

export type SupportTicketCalculationState = SupportTicketCalculationDto | null;

export interface SupportTicketCalculationItems {
  supportTicketCalculationTax: SupportTicketCalculationTax;
  supportTicketCalculationParameter: SupportTicketCalculationParameter;
  aviaTicketId?: string;
  ancillaryServiceId?: string;
  insurancePolicyId?: string;
  aviaPassengerId?: string;
}

export interface FormatTableRowProps {
  type: {
    code: CalculationType | string;
    description: null | string;
  };
  supportTicketCalculationTax?:
    | SupportTicketCalculationTax
    | ISupportTicketCalculationItemsEntity['supportTicketCalculationTax'];
  supportTicketCalculationParameter?:
    | SupportTicketCalculationParameter
    | ISupportTicketCalculationItemsEntity['supportTicketCalculationParameter'];
}

export interface GetTicketDTOBase {
  id: string;
  number: 3;
  orderId: string;
  segmentId: null;
  passengers: null;
  passengerPriceTotal: number;
  flight_Type: FlightType;
  subject: {
    code: string;
    description: string;
  };
  status: {
    code: string;
    description: string;
  };
  calculations: SupportTicketCalculation[];
  text: string;
  userId: string;
  createdDate: string;
  orderNumber: number;
  segments: {
    departureCityCode: string;
    arrivalCityCode: string;
    arrivalCityName: string;
    departureCityName: string;
    departureDate: string;
    id: string;
  }[];
  fileInfos: {
    fieldId: string;
    name: string;
  }[];
  segmentEvents?: SegmentEvents[];
  selectedTickets: SelectedTicket[];
  selectedAncillaryServices?: SelectedAncillaryService[];
  selectedInsurances?: SelectedInsurance[];
  supportTicketCalculationItems: SupportTicketCalculationItems[];
  ticketTypes: TicketType[];
}

export interface GetTicketDTO extends GetTicketDTOBase {
  messages: Message[];
}

export interface GetCustomerTicketsParams {
  page: number;
  pageSize: number;
  subjects?: string[];
  statuses?: string[];
  dateSorting?: string;
  number?: number;
}

export type IDetailMessage = {
  authorName: string;
  createdDate: string;
  id: string;
  lastName: null;
  status: { name: string; description: string };
  text: string;
};

export interface GetDetailTicketDTO extends GetTicketDTOBase {
  messages: TicketMessage[];
}

export interface GetRefundAmountDTO {
  /** Статус ответа */
  status?: GeneratedTuiOrderServiceApiDomainModelAviaBusinessProjectionsAviaServiceResponseStatus;
  responseErrors?:
    | GeneratedTuiOrderServiceApiServicesOrderModelErrorServiceResponse[]
    | null;
  /** Тип поставщика GDS */
  airGdsType?: string | null;
  /**
   * Список сегментов рейса
   */
  aviaSegments: {
    aviaSegmentId: string;
    departureCityCode: string;
    arrivalCityCode: string;
    departureDateTime: Date;
  }[];
  /**
   * Сумма к возврату
   * @format double
   */
  totalRefundSum?: number;
  /**
   * Сумма возврата по кредитной карте
   * @format double
   */
  cardRefundSum?: number;
  /**
   * Сумма возврата по наличной оплате
   * @format double
   */
  cashRefundSum?: number;
  /** Флаг для отображения причины возврата - "Отмена/Перенос рейса" */
  involuntry?: boolean;
  /** Суммы возврата по каждому пассажиру */
  refundPassengers?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundAmountResponseRefundPassenger[]
    | null;
  /** Суммы возврата по каждой доп услуге. */
  refundEmds?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket[]
    | null;
  /** Суммы возврата по каждому билету. */
  refundTickets?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket[]
    | null;
  /** Суммы возврата по каждой возвращённой доп услуге. */
  refundedEmds?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket[]
    | null;
  /** Суммы возврата по каждому возвращённому билету. */
  refundedTickets?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket[]
    | null;
  /** Информация о доп услугах. */
  ancillaryServices?:
    | GeneratedTuiOrderServiceApiDomainModelOrderEntitiesAncillaryService[]
    | null;
  /**
   * Время на возврат мин.
   * @format int32
   */
  timeLimit?: number;
}

/** Дополнительная услуга */
export interface GeneratedTuiOrderServiceApiDomainModelOrderEntitiesAncillaryService {
  /**
   * Количество кг в одном багаде
   * @format int32
   */
  value?: number | null;
  /**
   * Количество багажных мест
   * @format int32
   */
  quantity?: number | null;
  /**
   * Цена
   * @format double
   */
  price?: number;
  /** Название */
  name?: string | null;
  /** Тип доп услуги */
  type?: string | null;
  /** GDS */
  gds?: string | null;
  /** Поставщик */
  carrier?: string | null;
  /** Признак чтоб допуслуга возвращаемая */
  isRefundable?: boolean;
  /** Признак чтоб допуслуга подлежит обмену */
  isEchangeable?: boolean;
  /**
   * Базовая цена
   * @format double
   */
  basePrice?: number;
  /**
   * Комиссия
   * @format double
   */
  commisson?: number;
  /**
   * Сбор агентства за доп. услугу
   * @format double
   */
  agentExtraCharge?: number;
  /**
   * Сбор суб агента за доп. услугу
   * @format double
   */
  subAgentExtraCharge?: number;
  /** RFIC */
  rfic?: string | null;
  /** RFISC */
  rfisc?: string | null;
  /**
   * Идентификатор пассажира
   * @format uuid
   */
  passengerId?: string;
  /** Идентификаторы авиа сегментов */
  aviaSegmentIds?: string[] | null;
  /** Номер места */
  seatNumber?: string | null;
  passengerDocumentNumber?: string | null;
  segmentFlightNumber?: string | null;
}

/**
 * Статус ответа
 * @format int32
 */
export enum GeneratedTuiOrderServiceApiDomainModelAviaBusinessProjectionsAviaServiceResponseStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface GeneratedTuiOrderServiceApiServicesOrderModelErrorServiceResponse {
  code?: string | null;
  description?: string | null;
}

export interface GeneratedTuiOrderServiceApiServicesOrderModelRefundAmountResponseRefundPassenger {
  /** Id пассажира в GDS */
  passengerGdsId?: string | null;
  /** Номер билета */
  ticketNumber?: string | null;
  /** Код авиакомпании */
  airlineCode?: string | null;
  /**
   * Сумма возврата
   * @format double
   */
  totalRefundSum?: number;
  /**
   * Дата возврата
   * @format date-time
   */
  refundDate?: string;
  /** Детализация возврата */
  detailRefundId?:
    | GeneratedTuiOrderServiceApiServicesOrderModelRefundAmountResponseDetailRefundId[]
    | null;
  firstName?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
  personType?: string | null;
  documentNumber?: string | null;
  birthDate?: string | null;
}

export interface GeneratedTuiOrderServiceApiServicesOrderModelRefundAmountResponseDetailRefundId {
  /** Код операции */
  refundCode?: string | null;
  /** Идентификатор операции */
  refundId?: string | null;
}

export interface GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket {
  /** id сегментов рейса */
  aviaSegmentIds: string[];
  /** Билеты для возврата */
  number?: string | null;
  /** Банк через который была оплата */
  bank?: string | null;
  /**
   * Штраф за возврат
   * @format double
   */
  penalty?: number;
  /** Валюта штрафа */
  penaltyCurrency?: string | null;
  /**
   * Тип штрафа: фиксированная сумма или процент
   * @format int32
   */
  penaltyValueType?: number;
  /**
   * Тип возврата
   * @format int32
   */
  passengerDocumentNumber?: string | null;
  refundType?: number;
  totalRefundSum?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  /** Id доп услуги/билета. */
  orderItemRefID?: string | null;
  /** Полная стоимость билета */
  fullPrice: number;
  equivAmount?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  totalTaxAmount?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  fee?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  /** Вариант возврата. */
  reasonCode?: string | null;
}

export interface GeneratedTuiOrderServiceApiServicesOrderModelAmount {
  /** Валюта. */
  curCode?: string | null;
  /**
   * Сумма.
   * @format double
   */
  value?: number;
}

export interface GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket {
  /** Билеты для возврата */
  number?: string | null;
  /** Банк через который была оплата */
  bank?: string | null;
  /**
   * Штраф за возврат
   * @format double
   */
  penalty?: number;
  /** Валюта штрафа */
  penaltyCurrency?: string | null;
  /**
   * Тип штрафа: фиксированная сумма или процент
   * @format int32
   */
  penaltyValueType?: number;
  /**
   * Тип возврата
   * @format int32
   */
  totalHoldSum?: number;
  refundType?: number;
  totalRefundSum?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  /** Id доп услуги/билета. */
  orderItemRefID?: string | null;
  equivAmount?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  totalTaxAmount?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  fee?: GeneratedTuiOrderServiceApiServicesOrderModelAmount;
  /** Вариант возврата. */
  reasonCode?: string | null;
  tteCaTax?: number;
  ttePrTax?: number;
  passengerDocumentNumber?: string | null;
  /** Статус билета/доп.услуги */
  statusCode: 'Refunded';
}

export enum APPEAL_STEP {
  DEFAULT = 'default',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILURE = 'failure',
  REFUND = 'refund',
  REFUND_COMMON = RefundProcessType.COMMON,
  REFUND_AUTO = RefundProcessType.AUTO,
  REFUND_CONFIRM = 'refundConfirm',
  REFUND_SUCCESS = 'refundSuccess',
  REFUND_DECLINE = 'refundDecline',
  REFUND_INFO = 'refundInfo',
}

export interface BaseDescription {
  id: string;
  name: string;
  description: string;
}

export interface AddAppealDTO {
  id: string;
  number: number;
  orderId: string;
  orderNumber: number;
  orderPositionId: string;
  aviaSegmentIds: string[];
  aviaPassengerIds: string[];
  ancillaryServiceIds: string[];
  bookingCode: string;
  createDate: string;
  flightType: string | null;
  passengers: string[];
  subject: { code: string; description: string };
  status: { code: string; description: string };
  text: string;
  messages: {
    id: string;
    text: string;
    authorName: string;
    isOwner: boolean;
    status: BaseDescription;
    createdDate: string;
  }[];
  calculations: SupportTicketCalculation;
  userId: string;
  createdDate: string;
}

export interface AddAppealState {
  STEP: APPEAL_STEP;
  response: AddAppealDTO | null;
}

export type AddAppealSuccessPayload = AddAppealDTO;

export interface AdditionalPaymentDTO {
  correlationId: string;
  paymentURL: string;
  isError: boolean;
  errorMessage: string;
}

export interface EventEntity {
  id: string;
  dateTime: string;
  eventType: string;
  eventName: string;
  value: string;
  pnr: string;
  positionId: string;
  actionType: string;
  description: string;
  description2: string;
  userLogin: string;
}

export interface AppealHistoryDto {
  event: EventEntity[];
}

export type AppealHistoryState = EventEntity[];

export interface AppealsListNextAction {
  statuses?: string[];
  subjects?: string[];
  dateSorting?: string;
}
