import React from 'react';
import * as Styled from '../styles/Refund.style';
import { RoundIcon } from '@components/loader';
import PassengerEditWarning from '@app/components/booking/PassengersBlock/PassengerEditWarning';

export enum RefundProcessType {
  COMMON = 'Common',
  AUTO = 'Auto',
}

export enum RefundReason {
  CANCEL = 'Cancel',
  HEALTH = 'Health',
  SERVICE = 'Service',
  FREE = 'Free',
  OTHER = 'Other',
}

export const refundReasons = [
  {
    label: 'Авиакомпания отменила/изменила рейс',
    value: RefundReason.CANCEL,
    code: RefundProcessType.AUTO,
  },
  {
    label: 'У меня изменились планы',
    value: RefundReason.FREE,
    code: RefundProcessType.AUTO,
  },
  {
    label:
      'Хочу вернуть дополнительную услугу (багаж, питание, место в самолете)',
    value: RefundReason.SERVICE,
    code: RefundProcessType.AUTO,
  },
  {
    label: 'Не могу лететь по состоянию здоровья (пассажиров или их близких)',
    value: RefundReason.HEALTH,
    code: RefundProcessType.COMMON,
  },
  {
    label: 'Другая причина',
    value: RefundReason.OTHER,
    code: RefundProcessType.COMMON,
  },
];

export const RefundHeader = ({ isTrainOrder }: { isTrainOrder?: boolean }) => (
  <Styled.TextBlock>
    <Styled.Title>Возврат по заказу</Styled.Title>
    <RefundDescription isTrainOrder={isTrainOrder} />
  </Styled.TextBlock>
);

const RefundDescription = ({ isTrainOrder }: { isTrainOrder?: boolean }) => {
  return isTrainOrder ? (
    <PassengerEditWarning isAppeal />
  ) : (
    <Styled.Description>
      Внимание, возврат на сайте осуществляется на общих условиях. <br />
      <Styled.Strong>
        При возврате билета авиа доп. услуги, привязанные к этому билету,
        возвращаются автоматически.
      </Styled.Strong>
    </Styled.Description>
  );
};

export const RefundLoader = () => (
  <Styled.LoaderWrapper>
    <RoundIcon />
  </Styled.LoaderWrapper>
);
