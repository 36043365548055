import moment from 'moment/moment';

import { Bus } from './types';

export const setGeneralBusesSort = (buses: Bus[], sortBy: string): Bus[] => {
  if (!buses.length) return [];
  switch (sortBy) {
    case 'TIME_DEPARTURE':
      return sortBusesByTimeDeparture(buses);
    case 'TIME_ARRIVAL':
      return sortBusesByTimeArrival(buses);
    case 'TIME':
      return sortBusesByTime(buses);
    case 'PRICE':
      return sortBusesByMinPrice(buses);

    default:
      return buses;
  }
};

export const sortBusesByTimeDeparture = (buses: Bus[]): Bus[] => {
  return [...buses].sort((a, b) => {
    return (
      moment(a.departureDateTime).unix() - moment(b.arrivalDateTime).unix()
    );
  });
};

export const sortBusesByTimeArrival = (buses: Bus[]): Bus[] => {
  return [...buses].sort((a, b) => {
    return moment(a.arrivalDateTime).unix() - moment(b.arrivalDateTime).unix();
  });
};

export const sortBusesByTime = (buses: Bus[]): Bus[] => {
  return [...buses].sort((a, b) => {
    return (
      moment.duration(a.durationInMinutes).asMinutes() -
      moment.duration(b.durationInMinutes).asMinutes()
    );
  });
};

export const sortBusesByMinPrice = (buses: Bus[]): Bus[] => {
  return [...buses].sort((a, b) => {
    return a.price - b.price;
  });
};
