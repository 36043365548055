import * as yup from 'yup';

export const userSchema = yup.object().shape({
  name: yup.string().required('Необходимо заполнить поле').nullable(),
  surname: yup.string().required('Необходимо заполнить поле').nullable(),
  patronymic: yup.string().required('Необходимо заполнить поле').nullable(),
  birthDate: yup.date().required('Необходимо заполнить поле').nullable(),
  phone: yup
    .string()
    .matches(
      /\+?7\s?\(?\d{3}\)?\s?\d{3}-?\d{2}-?\d{2}/,
      'Некоректный номер телефона'
    )
    .required(),
  // citizen: yup.string().required('Необходимо заполнить поле'),
});

export const AgentSchema = yup.object().shape({
  partnerId: yup.number().required('Необходимо заполнить поле'),
  partnerPass: yup.number().required('Необходимо заполнить поле'),
  salesChannel: yup.string().required('Необходимо заполнить поле'),
  userName: yup.string().required('Необходимо заполнить поле'),
  agentTitle: yup.string().required('Необходимо заполнить поле'),
});

export interface FORM {
  firstName: string;
  lastName: string;
  patronymic: string;
  birthDate: string | undefined;
  citizen: any;
  gender: string;
  personType: string;
  issueDate: string | undefined;
  //Child
  number: string;
  type: any;
}

//TODO: Move to ui
export const asyncSelectStyles = (
  isTabletOrMobile: boolean,
  isMobile: boolean
) => ({
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    visibility: 'hidden',
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Open Sans',
    color: '#3C3C3C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    paddingLeft: 10,
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    padingLeft: 10,
    width: isTabletOrMobile && !isMobile ? '335px' : '100%',
  }),
  control: (provided: any, { isFocused, ...state }: any) => {
    const border = isFocused
      ? '1px solid #3C3C3C !important'
      : '1px solid #DCDCDC';

    const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
    const boxShadow = 'none';
    return {
      ...provided,
      minHeight: 42,
      border,
      borderColor,
      boxShadow,
    };
  },
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: 100,
  }),
});
