import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { NavigationProps } from './types';

const Wrapper = styled.nav`
  padding-bottom: 18px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  & > a {
    margin-right: 30px;
    @media (max-width: 767px) {
      margin-right: 19px;
    }
  }
  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
`;

const activeStyles = css`
  color: #4872f2;
`;

const Link = styled(NavLink).attrs({ exact: true })`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  text-decoration: none;
  position: relative;
  transition: color 0.2s ease-in-out;
  min-width: fit-content;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: transparent;
    bottom: -19px;
    left: 0;
    right: 0;
    transition: background-color 0.2s ease-in-out;
    @media (max-width: 767px) {
      bottom: -11px;
    }
  }
  &:hover {
    ${activeStyles}
  }
  &.active {
    ${activeStyles}
  }
`;

export const Counter = styled.span`
  margin-left: 5px;
  display: flex;
  width: 16px;
  height: 16px;
  color: white;
  background-color: #4872f2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
`;

const Navigation: React.FC<NavigationProps> = ({ items, ...props }) => {
  return (
    <Wrapper {...props}>
      {items.map((item, key) => (
        <Link key={key} to={item.path}>
          {item.name}&nbsp;
          {item.counterForNotification !== undefined && (
            <Counter>{item.counterForNotification}</Counter>
          )}
        </Link>
      ))}
    </Wrapper>
  );
};

export default Navigation;
