import React from 'react';
import { ArrowWrapper, ArrowButton, ArrowButtonIcon } from './styles';

interface ArrowProps {
  reverse?: boolean;
  dataCy?: string;
  onClick?(): void;
}

export const Arrow: React.FC<ArrowProps> = React.memo(
  ({ reverse = false, onClick, dataCy }) => {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      typeof onClick === 'function' && onClick();
    };
    return (
      <ArrowWrapper>
        <ArrowButton data-cy={dataCy} onClick={handleClick}>
          <ArrowButtonIcon className={reverse ? 'reverse' : ''} />
        </ArrowButton>
      </ArrowWrapper>
    );
  }
);
