import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { ReactComponent as TelegramLineIcon } from '@assets/media/main-page/telegram-line.svg';
import { ReactComponent as ArrowIcon } from '@assets/media/main-page/telegram-link-arrow.svg';

const Wrapper = styled.div`
  background: linear-gradient(
      117.26deg,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.5) 83.12%
    ),
    #537ff5;
  background-blend-mode: soft-light, normal;
  border-radius: 28px;
  padding: 45px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1169px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
    border-radius: 16px;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  color: #ffffff;
  @media (max-width: 1169px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const Description = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
  & > span {
    display: block;
  }

  ${Title} {
    margin-bottom: 24px;
  }

  ${Description} {
    display: block;
    margin-bottom: 84px;
  }

  @media (max-width: 767px) {
    ${Title} {
      margin-bottom: 20px;
    }

    ${Description} {
      margin-bottom: 26px;
    }
  }
`;

const Link = styled.a`
  font-family: Open Sans;
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  position: relative;

  &:hover {
    color: #edf1fe;
    & > svg > path {
      fill: #edf1fe;
    }
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  right: -29px;
  top: 0;
`;

const Background = styled(TelegramLineIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

export default () => (
  <Wrapper>
    <Background />
    <Container>
      <Title>Только между нами!</Title>
      <Description>
        Секретные предложения ежедневно в нашем телеграм-канале
      </Description>
      <Link href="https://teleg.run/FUNSUN_online_bot">
        Присоединиться <Arrow />
      </Link>
    </Container>
  </Wrapper>
);
