import {
  BusSearch,
  BusSearchDto,
  City,
  CityBus,
  StationSearchDto,
  TrainStationSearch,
} from '@modules/simpleSearch/types';

/**
 * Адаптер для перевода DTO ЖД станции в DTO авиа
 * @param data
 */
export const trainStationDtoToAviaCityDto = (
  data: StationSearchDto,
): City[] => {
  return data.map((x, key) => trainStationToAviaCity(x));
};

export const trainStationToAviaCity = (station: TrainStationSearch): City => {
  const nameRus: string[] = [];
  if (station.region) {
    nameRus.push(station.region.name_ru);
  } else if (station.city) {
    nameRus.push(station.city.name_ru);
  }
  if (station.country) {
    nameRus.push(station.country.name_ru);
  }

  return {
    id: station.id,
    nameEng: station.name_en,
    nameRus: station.name_ru,
    country: {
      id: station.country?.id + '' || station.id,
      isoCode: station.code,
      nameEng: station.country?.name_ru || '',
      nameRus: nameRus.join(', '),
      isVisible: true,
    },
    iataCode: station.code,
    isVisible: station.isVisible,
  };
};
