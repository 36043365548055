import React, { useEffect } from 'react';
import CityFields from '@components/flightsSearch/SimpleSearch/CityFields';
import { Button, Text } from '@components/ui';
import DatePicker from './DatePicker';
import PassengerPicker from './PassengersPicker';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Helper } from '@utils';
import { useFormik } from 'formik';
import { StyledInput } from '@components/ui/form/Field/styles';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Pencil from '../../../assets/images/pencil';
import { ApplicationState } from '@modules/index';
import { useFocus } from './useFormFocus';
import { useCommonSearchData } from './useCommonSearchData';

const Wrapper = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & > button {
    margin-left: 12px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;

  & ${StyledInput} {
    margin: 1px 0;
    border-radius: 0;
  }

  & ${StyledInput}[name="to"] {
    border-radius: 0;

    & ~ span {
      left: 20px;
    }
  }

  & ${StyledInput}[name="from"] {
    border-radius: 4px 0 0 4px;
  }

  & ${StyledInput}[name="passengers"] {
    border-radius: 0 4px 4px 0;
    @media (max-width: 1169px) {
      border-radius: 5px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1160px) {
    // margin-left: 15px;
  }

  @media (max-width: 1169px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SecondLineTablet = styled.div`
  display: inherit;
  @media (max-width: 1169px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const SearchButton = styled(Button)`
  width: 171px;
  font-weight: 600;
  font-size: 16px;
  height: 60px;

  margin-left: 10px;
  white-space: nowrap;

  @media (max-width: 1169px) {
    width: 23.5%;
    margin-left: 0;
  }

  @media (max-width: 767px) {
    height: 46px;
    width: 100%;
    margin-top: 24px;
  }
`;

const Form = styled.form``;

const FakeMobileInput = styled.div`
  width: 100%;
  height: 54px;
  background: #ffffff;
  border-radius: 5px;
  padding: 8px 12px;
  box-sizing: border-box;
  box-shadow: 0 0 0 2px #4872f2;
`;

const FakeMobileInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FakeDate = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #737373;
`;

const FakeRoute = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  max-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchForm: React.FC<{ className?: string }> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [withFakeInput, setWithFakeInput] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const ref = React.useRef(null as null | HTMLDivElement);
  const {
    data,
    dataBus,
    searchPanelType,
    passengers,
    from,
    to,
    dates,
    ticketClass,
    adultsCount,
    childrenCount,
    infantsCount,
    allPassengersIsZero,
    dummyAdultNumber
  } = useCommonSearchData();
  const { setFocus, focus } = useFocus();
  const isFocusActive = React.useMemo(() => !!focus, [focus]);

  useEffect(() => {
    if (
      (isMobile && location.pathname.includes('/search')) ||
      (isMobile && location.pathname.includes('/booking'))
    ) {
      setWithFakeInput(true);
    } else if (isMobile && location.pathname === '/') {
      setWithFakeInput(false);
    }
  }, [isMobile, location.pathname]);

  useEffect(() => {
    if (!isMobile) {
      const handleClickOutside = (e: MouseEvent) => {
        if (ref.current) {
          if (!ref.current.contains(e.target as any)) {
            setFocus(null);
          }
        }
      };

      if (!isFocusActive) {
        document.removeEventListener('mousedown', handleClickOutside);
      } else {
        document.addEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isFocusActive]);

  useEffect(() => {
    if (searchPanelType !== 'bus') {
      if (from !== null) {
        formik.setFieldValue('from', from);
        delete formik.errors['from'];
        formik.setErrors({ ...formik.errors });
      }
      if (to !== null) {
        formik.setFieldValue('to', to);
        delete formik.errors['to'];
        formik.setErrors({ ...formik.errors });
      }
    } else {
      if (dataBus.fromBus !== null) {
        formik.setFieldValue('from', dataBus.fromBus);
        delete formik.errors['from'];
        formik.setErrors({ ...formik.errors });
      }
      if (dataBus.toBus !== null) {
        formik.setFieldValue('to', dataBus.toBus);
        delete formik.errors['to'];
        formik.setErrors({ ...formik.errors });
      }
    }
  }, [data, dataBus]);

  const formik = useFormik({
    initialValues: {
      from:
        searchPanelType !== 'bus'
          ? typeof from === 'object'
            ? from.nameRus
            : from
          : typeof dataBus.fromBus === 'object'
            ? dataBus.fromBus.nameRu
            : dataBus.fromBus,
      to:
        searchPanelType !== 'bus'
          ? typeof to === 'object'
            ? to.nameRus
            : to
          : typeof dataBus.toBus === 'object'
            ? dataBus.toBus.nameRu
            : dataBus.toBus,
    },
    validate: ({ from, to }) => {
      const errors = {} as any;

      if (from === '') {
        errors.from = 'Укажите город';
      }
      if (to === '') {
        errors.to = 'Укажите город';
      }
      return errors;
    },
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: () => {
      if (searchPanelType === 'avia') {
        history.push(
          `/search/${Helper.createSearchParams({
            fromCode: (data.from as any).iataCode,
            ToCode: (data.to as any).iataCode,
            forwardDate: dates.from || moment(),
            backwardDate: dates.to,
            adults: adultsCount,
            children: childrenCount,
            infants: infantsCount,
            flightClass: ticketClass.id,
          })}`,
        );
      } else if (searchPanelType === 'bus') {
        const url = new URL('bus-search', 'http://localhost:3001');
        url.searchParams.append('From', (dataBus.fromBus as any).guid);
        url.searchParams.append('To', (dataBus.toBus as any).guid);
        url.searchParams.append(
          'DepartureDate',
          dates.from
            ? dates.from.format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        );
        // дата обратного билета
        if (dates.to) {
          url.searchParams.append(
            'DepartureDateBack',
            dates.to.format('YYYY-MM-DD'),
          );
        }
        url.searchParams.append('Adults', adultsCount.toString());
        url.searchParams.append('Children', childrenCount.toString());
        url.searchParams.append('Infants', infantsCount.toString());
        history.push(`/bus-search?${url.searchParams.toString()}`);
      } else {
        const url = new URL('train-search', 'http://localhost:3001');
        url.searchParams.append('From', (data.from as any).iataCode);
        url.searchParams.append('To', (data.to as any).iataCode);
        url.searchParams.append(
          'DepartureDate',
          dates.from ? dates.from.format('YYYY-MM-DD') : '',
        );
        // дата обратного билета
        if (dates.to) {
          url.searchParams.append(
            'DepartureDateBack',
            dates.to.format('YYYY-MM-DD'),
          );
        }
        url.searchParams.append(
          'Adults',
          allPassengersIsZero
            ? dummyAdultNumber.toString()
            : adultsCount.toString(),
        );
        url.searchParams.append('Children', childrenCount.toString());
        url.searchParams.append('Infants', infantsCount.toString());
        history.push(`/train-search?${url.searchParams.toString()}`);
      }
    },
  });

  const fakeDates = `${moment(dates.from).format('D MMMM')} ${
    dates?.to ? ' - ' + moment(dates.to).format('D MMMM') : ''
  }`;
  const fakeRoute =
    !from && !to
      ? 'Поиск авиабилетов'
      : `${(data as any).from?.nameRus} - ${(data as any).to?.nameRus}`;
  //
  return (
    <Form
      className={props.className}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      {withFakeInput ? (
        <FakeMobileInput onClick={() => setWithFakeInput(false)}>
          <FakeMobileInputWrapper>
            <Column>
              {fakeDates !== 'Invalid date ' && (
                <FakeDate>{fakeDates}</FakeDate>
              )}
              <FakeRoute>{fakeRoute}</FakeRoute>
            </Column>
            <Pencil />
          </FakeMobileInputWrapper>
        </FakeMobileInput>
      ) : (
        <Wrapper>
          <InputWrapper ref={ref}>
            <InputContainer>
              <CityFields onBlur={formik.handleBlur} errors={formik.errors} />
              <SecondLineTablet>
                <DatePicker />
                <PassengerPicker />
                <SearchButton type="primary" htmlType="submit">
                  Найти
                </SearchButton>
              </SecondLineTablet>
            </InputContainer>
          </InputWrapper>
        </Wrapper>
      )}
    </Form>
  );
};

export default SearchForm;
