import { IFlightSearchState, IFlightSearchIntervals } from './types';

export const FlightSearchState: IFlightSearchState = {
  status: null,
  filter: {
    isFiltered: false,
  },
} as any;

export const FlightSearchIntervalsState: IFlightSearchIntervals = {} as any;
