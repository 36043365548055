import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@components/ui';

import CrossIcon from '../assets/images/crossRound';
import { ReactComponent as MobileCrossIcon } from '@assets/media/error-notifications/mobile-cross.svg';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getNotification,
  closeNotification,
  NotificationState,
} from '@modules/notification';
import { url } from '@modules/booking/utils';
import { Hooks } from '@utils';
import Cookies from 'js-cookie';

import { bookFlightRequest } from '@modules/booking';

const StyledPopup = styled(Popup)`
  &-content {
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`;

const StyledIcon = styled(CrossIcon)`
  cursor: pointer;
  display: none;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileIcon = styled(MobileCrossIcon)`
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 14px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Comment = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > span {
    text-align: center;
  }

  @media (max-width: 767px) {
    & > span {
      text-align: left;
    }
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > :first-child {
    margin-right: 14px;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
  }
`;

const Wrapper = styled.div`
  width: 569px;
  min-height: 261px;
  padding: 40px 30px;
  box-sizing: border-box;
  border-radius: 8px;

  & > ${Comment} {
    margin-top: 25px;
  }

  & > ${ButtonBlock} {
    margin-top: 44px;
  }

  @media (max-width: 767px) {
    max-width: 335px;
    padding: 20px 14px;
    min-height: auto;
    & > ${Comment} {
      margin-top: 20px;
    }

    & > ${ButtonBlock} {
      margin-top: 30px;
    }
  }
`;

const Row = styled.div`
  displat: flex;
  align-items: center;
`;

const SubHeading = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const ErrorNotification: React.FC = () => {
  const { label, body, buttonName, code, searchRequest, data, booking } =
    useSelector(getNotification) as Exclude<NotificationState, null>;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = Hooks.useMediaSizes();

  const handleClose = useCallback(() => {
    if (buttonName !== 'Перейти к авиабилетам') {
      dispatch(closeNotification({ searchRequest: searchRequest }));
    }
  }, []);

  const handleButtonClick = useCallback(() => {
    if (searchRequest) {
      handleClose();
      history.push(`/search/${url(searchRequest)}`);

      window.location.reload();
    } else if (buttonName !== 'Перейти к авиабилетам') {
      handleClose();
      history.goBack();

      // window.location.reload();
    } else if (buttonName === 'Перейти к авиабилетам') {
      Cookies.remove('wl_token');
      Cookies.remove('wl_token');
      Cookies.remove('wl_refresh_token');
      window.location.replace('https://newb2b.fstravel.com/avia');
    }
  }, [history]);

  const handleClick = useCallback(() => {
    handleClose();
    history.push(`/search/${url(data.SearchRequest)}`);
    window.location.reload();
  }, [history]);

  const handleBooking = useCallback(() => {
    const newBooking = {
      ...booking,
      OfferPrice: data?.NewPrice,
      BookingType: data?.BookingType,
    };
    //@ts-ignore
    newBooking && dispatch(bookFlightRequest(newBooking));

    handleClose();
  }, []);

  const handleBookingPassangers = useCallback(() => {
    const newBooking = {
      ...booking,
      OfferPrice: data?.NewPrice,
      BookingType: data?.BookingType,
    };
    //@ts-ignore
    newBooking && dispatch(bookFlightRequest(newBooking));

    handleClose();
  }, []);

  return (
    <StyledPopup
      defaultOpen
      onClose={handleClose}
      closeOnDocumentClick={
        buttonName === 'Перейти к авиабилетам' ? false : true
      }
      closeOnEscape={buttonName === 'Перейти к авиабилетам' ? false : true}
    >
      <Wrapper>
        <Head>
          <Heading>{label}</Heading>
          <StyledIcon onClick={handleClose} />
          <MobileIcon onClick={handleClose} />
        </Head>
        {label === 'Обращаем внимание!' && (
          <SubHeading>
            Норма багажа - не более&nbsp;
            <span style={{ color: '#4872F2' }}> 40 кг </span>&nbsp; на пассажира
          </SubHeading>
        )}
        {!data?.NewPrice ? (
          <Comment>
            <Text>
              {label !== 'Обращаем внимание!'
                ? body
                : 'Включая багаж по выбранному тарифу и дополнительный багаж. Пожалуйста, убедитесь, что вес вашего багажа не превышает установленной нормы.'}
            </Text>
          </Comment>
        ) : data?.NewPrice &&
          data?.BookingType ===
            'GetOfferWithRightPassengerTypesAndBook_12years' ? (
          <Comment>
            <Text>
              По правилам авиакомпании для пассажира возрастом
              <span style={{ fontWeight: 'bold' }}> от 12 лет </span> тип
              пассажира определяется как "Взрослый". В связи с этим стоимость
              перелета изменилсь
            </Text>
            {data?.NewPrice !== data?.OldPrice && (
              <>
                <Text style={{ marginTop: 10 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    Новая стоимость перелета за всех пассажиров:{' '}
                    <span style={{ color: '#4872F2' }}>
                      {data.NewPrice} ₽
                    </span>{' '}
                  </span>
                </Text>
                <Text style={{ marginTop: 10 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    Старая стоимость перелета за всех пассажиров:{' '}
                    <span style={{ color: '#4872F2' }}>
                      {data.OldPrice} ₽
                    </span>{' '}
                  </span>
                </Text>
              </>
            )}
          </Comment>
        ) : data?.NewPrice &&
          data?.BookingType ===
            'GetOfferWithRightPassengerTypesAndBook_14years' ? (
          <Comment>
            <Text>
              Для некоторых лоукостеров взрослый пассажир должен <br /> быть{' '}
              <span style={{ fontWeight: 'bold' }}> старше 14 лет </span> на
              момент первого отправления. Для <br /> пассажиров младше тип будет
              изменен на ребенка.
            </Text>
            {data?.NewPrice !== data?.OldPrice && (
              <>
                <Text style={{ marginTop: 10 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    Новая стоимость перелета за всех пассажиров:{' '}
                    <span style={{ color: '#4872F2' }}>
                      {data.NewPrice} ₽
                    </span>{' '}
                  </span>
                </Text>
                <Text style={{ marginTop: 10 }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    Старая стоимость перелета за всех пассажиров:{' '}
                    <span style={{ color: '#4872F2' }}>
                      {data.OldPrice} ₽
                    </span>{' '}
                  </span>
                </Text>
              </>
            )}
          </Comment>
        ) : (
          <Comment>
            <Text>
              К сожалению, места по выбранному тарифу закончились.
              <br />
              <span style={{ fontWeight: 'bold' }}>
                Стоимость перелета по новому тарифу:&nbsp;
                <span style={{ color: '#4872F2' }}>{data.NewPrice} ₽</span>
              </span>
            </Text>
          </Comment>
        )}

        <ButtonBlock>
          {!data?.NewPrice && data?.code !== 'SAMO_EXCEPTION' ? (
            <Button type="primary" onClick={handleButtonClick}>
              {buttonName}
            </Button>
          ) : data?.NewPrice &&
            (data?.BookingType === 'GetActualOfferAndBook' ||
              data?.BookingType ===
                'GetOfferWithRightPassengerTypesAndBook_12years') ? (
            <Row>
              <Button type="primary" onClick={handleBooking}>
                Продолжить бронирование
              </Button>
              <Button
                type="primary"
                style={{
                  marginLeft: isMobile ? 0 : 20,
                  marginTop: isMobile ? 10 : 0,
                  width: isMobile ? 287 : 'auto',
                }}
                onClick={handleClick}
              >
                Новый поиск
              </Button>
            </Row>
          ) : !data?.NewPrice && data?.code === 'SAMO_EXCEPTION' ? (
            <Button type="primary" onClick={handleClose}>
              Закрыть
            </Button>
          ) : (
            <Button type="primary" onClick={handleBookingPassangers}>
              Продолжить
            </Button>
          )}
        </ButtonBlock>
      </Wrapper>
    </StyledPopup>
  );
};

export default ErrorNotification;
