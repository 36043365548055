import React from 'react';
import styled from 'styled-components';
import { Title, GrayText, Paragraph } from './shared';
import { Button } from '@components/ui';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '@assets/media/main-page/telegram-link-arrow.svg';
import { ReactComponent as BackgroundSVG1 } from '@assets/media/main-page/support-for-order-background-1.svg';
import { ReactComponent as BackgroundSVG2 } from '@assets/media/main-page/support-for-order-background-2.svg';
import { ReactComponent as ButtonIcon } from '@assets/media/main-page/support-for-order-button-icon.svg';

interface Props {
  type: 'train' | 'avia' | 'bus';
}

const Wrapper = styled.div`
  padding: 45px;
  background: #feedff;
  border-radius: 16px;
  position: relative;

  @media (max-width: 767px) {
    padding: 30px 20px;
  }
`;

const ModalButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  padding: 11px 20px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    padding: 8px 20px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 2;

  ${Title} {
    display: block;
    margin-bottom: 24px;
  }

  ${GrayText} {
    display: block;
    margin-bottom: 8px;
  }

  ${Paragraph} {
    display: block;
    margin-bottom: 20px;
  }

  ${ModalButton} {
    margin-top: 36px;
  }
`;

const ShowMore = styled(NavLink)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;
  color: #4872f2;
  position: relative;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  right: -29px;
  top: 0;

  & > path {
    fill: #4872f2;
  }

  @media (max-width: 767px) {
    top: 18px;
    right: 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const BackgroundRight = styled(BackgroundSVG1)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    opacity: 0.4;
  }
`;

const BackgroundLeft = styled(BackgroundSVG2)`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;
const MobileText = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;

export default function SupportForOrderBanner(props: Props) {
  const history = useHistory();

  return (
    <Wrapper>
      <BackgroundLeft />
      <BackgroundRight />
      <Content>
        <GrayText>
          Ежедневная помощь <DesktopText>с 9:00 до 21:00</DesktopText>{' '}
          <MobileText>9:00 - 21:00</MobileText> по МСК
        </GrayText>
        <Title>Служба поддержки по заказу</Title>
        {props.type === 'train' && (
          <Paragraph>
            Вопросы по заказу или работе с Личным кабинетом Вы можете направить
            через обращение в службу поддержки. Наша профессиональная команда
            сотрудников окажет помощь на всех этапах бронирования.
          </Paragraph>
        )}
        {props.type === 'avia' && (
          <>
            <Paragraph>
              Вопросы по заказу, запрос на оформление возврата, обмена и
              добавления авиабилетов или дополнительных услуг вы можете
              направить через обращение в службу поддержки.
            </Paragraph>
            <Paragraph>
              Наша профессиональная команда сотрудников окажет помощь на всех
              этапах бронирования.
            </Paragraph>
          </>
        )}
        {props.type === 'avia' && (
          <Paragraph>
            Для более быстрого взаимодействия и решения вопросов ознакомьтесь с{' '}
            <ShowMore to="/questions/how-to-create-support-ticket">
              {' '}
              Инструкцией по созданию обращения <Arrow />
            </ShowMore>
          </Paragraph>
        )}
        {/* <ModalButton onClick={() => history.push('/questions/how-to-create-support-ticket')}><ButtonIcon /> Служба поддержки по заказу</ModalButton> */}
      </Content>
    </Wrapper>
  );
}
