import { SegmentGroup, Segment, AvailableActions } from '@modules/orders';
import { flatten } from 'lodash';
import { PositionAppealCode } from './types';

export function createFromToString(segmentGroups: SegmentGroup[]) {
  return (segmentGroups || [])
    .map((value) => {
      if (value.segments.length === 1) {
        return [...value.segments, ...value.segments].map(extractCity);
      } else {
        return value.segments.map(extractCity);
      }
    })
    .map(flatten)
    .filter((value, index, arr) => removeDuplicates(value, arr[index + 1]))
    .map((value) => value?.join(' - '));
}

function extractCity(value: Segment, index: number, arr: Segment[]) {
  if (index !== 0 && index !== arr.length - 1)
    return [value.departureCity?.name, value.arrivalCity?.name];
  return index === 0 ? value.departureCity?.name : value.arrivalCity?.name;
}

function removeDuplicates(x1: string[], x2?: string[]) {
  return !x2 || x1[x1.length - 1] === x2[0];
}

const PRINT = {
  actions: 'PRINT',
  value: 'PRINT',
  label: 'Печать билета',
};

const CREATE_APPEAL_WITH_ONLY_OTHER_THEME = {
  actions: 'CREATE_APPEAL',
  value: '',
  label: 'Служба поддержки',
};

const CANCEL = {
  actions: 'CANCEL',
  value: '',
  label: 'Отменить позицию',
};

const REFUND_BLANK = {
  actions: 'REFUND_BLANK',
  value: '',
  label: 'Печать возврата',
};

const CREATE_APPEAL_WITH_ANY_THEME = [
  {
    actions: 'CREATE_APPEAL',
    value: PositionAppealCode.FULL_REFUND,
    label: 'Возврат билета',
  },
  {
    actions: 'CREATE_APPEAL',
    value: PositionAppealCode.FUll_EXCHANGE,
    label: 'Обмен билета',
  },
];

export function createAvailableActionsOptionsList(
  availableActions: (keyof typeof AvailableActions)[]
) {
  let result: {
    actions: string;
    value: string;
    label: string;
  }[] = [];

  if (availableActions.includes(AvailableActions.PRINT)) {
    result.push(PRINT);
  }
  if (
    availableActions.includes(AvailableActions.CREATE_APPEAL_WITH_ANY_THEME)
  ) {
    result = [...result, ...CREATE_APPEAL_WITH_ANY_THEME];
  }

  if (
    availableActions.includes(
      AvailableActions.CREATE_APPEAL_WITH_ONLY_OTHER_THEME
    ) ||
    availableActions.includes(AvailableActions.CREATE_APPEAL_WITH_ANY_THEME)
  ) {
    result.push(CREATE_APPEAL_WITH_ONLY_OTHER_THEME);
  }

  if (availableActions.includes(AvailableActions.CANCEL)) {
    result.push(CANCEL);
  }

  if (availableActions.includes(AvailableActions.REFUND_BLANK)) {
    result.push(REFUND_BLANK);
  }

  return result;
}
