import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { signUp } from '@modules/user';
import DesktopForm from './DesktopForm';
import { useHistory } from 'react-router-dom';

const DesktopTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = React.useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  const urlParams = new URLSearchParams(window.location.search);
  const agentId = urlParams.get('agentId');
  const history = useHistory();
  const handleSubmit = (values: typeof initialValues) => {
    if (agentId) {
      dispatch(
        signUp({
          ...values,
          agentId,
        })
      );
    } else
      dispatch(
        signUp({
          ...values,
        })
      );

    setShowLoader(true);
    // history.push('/');
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .email('Некорректный email')
          .required('Необходимо заполнить поле'),
        password: yup
          .string()
          .required('Необходимо заполнить поле')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$%^&*]{8,20}$/gi,
            'Пароль должен содержать не менее 8 символов, строчные и заглавные латинские буквы и цифру'
          ),
      })}
      onSubmit={handleSubmit}
    >
      {(props) => <DesktopForm {...props} showLoader={showLoader} />}
    </Formik>
  );
};

export default DesktopTemplate;
