import React from 'react';
import RCSlider from 'rc-slider';
import styled from 'styled-components';
import { RangeProps as RCRangeProps } from 'rc-slider/lib/Range';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledRange = styled(RCSlider.Range)`
  & .rc-slider-handle {
    border: 1px solid ${({ theme: { colors } }) => colors.gray};
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }
  & .rc-slider-handle-dragging {
    border-color: ${({ theme: { colors } }) => colors.main} !important;
    box-shadow: none !important;
  }
  & .rc-slider-track {
    background-color: ${({ theme: { colors } }) => colors.main};
    height: 1px;
  }
  & .rc-slider-rail {
    height: 1px;
  }

  &.rc-slider {
    width: 93%;
  }
`;

export type RangeChangeHandler = (val: number[]) => void;

export type RangeProps = RCRangeProps;

const Range: React.FC<RangeProps> = (props) => {
  return (
    <Wrapper>
      <StyledRange {...props} />
    </Wrapper>
  );
};

export default Range;
