import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text } from '@components/ui';
import { Helper } from '@utils';
import { getDetailOrder, payOrderRequest } from '@modules/orders';

const DetalizationWrapper = styled.div`
  border: 1px solid #4872f2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetalizationList = styled.div`
  margin-top: 20px;
  & > div {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Header = styled(Flex)`
  margin-bottom: 10px;
  ${Text} {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #4872f2;
  }
`;

const Row = styled(Flex)`
  ${Text} {
    font-size: 14px;
    line-height: 18px;
    color: #3c3c3c;
  }
`;

const ChekoutBlock = styled(Flex)`
  margin-top: 30px;
`;

const ExtraPayment: React.FC = () => {
  const dispatch = useDispatch();
  const { invoice, orderNumber } = useSelector(getDetailOrder);

  const handleBuy = React.useCallback(() => {
    dispatch(payOrderRequest({ OrderNumber: orderNumber }));
  }, [orderNumber]);

  return (
    <>
      <DetalizationWrapper id="extra-payment">
        <Header>
          <Text>Доплата по заказу</Text>
          <Text>{Helper.formatPrice(invoice.total)}</Text>
        </Header>
        <Text color="#737373">
          В заказ были внесены изменения. Пожалуйста, ознакомьтесь с ними и
          внесите доплату
        </Text>
        <DetalizationList>
          {invoice.items.map((item, key) => (
            <Row key={key}>
              <Text>{item.description}</Text>
              <Text>{Helper.formatPrice(item.price)}</Text>
            </Row>
          ))}
        </DetalizationList>
      </DetalizationWrapper>
      <ChekoutBlock>
        <Button type="outlined" style={{ padding: '7px 50px' }}>
          Отмена
        </Button>
        <Button onClick={handleBuy} type="primary" color="yellow">
          Доплатить {Helper.formatPrice(invoice.total)}
        </Button>
      </ChekoutBlock>
    </>
  );
};

export default ExtraPayment;
