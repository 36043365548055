import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  carsPurify,
  trainTicketsSearchSelector,
  useTrainSelector,
} from '@modules/trainTickets';
import { Text as BaseText } from '@components/ui';

import Car from './Car';
import CarSkeleton from '@components/train/TrainSearch/Ticket/CarSkeleton';
import { useMediaQuery } from 'react-responsive';
import { getAllServiceClassesFromCar } from '@modules/trainTickets/utils';

const Wrapper = styled.div`
  background: #f8f9ff;
  padding: 20px 28px;
  border-radius: 0 0 8px 8px;
  border: 0;

  @media (max-width: 767px) {
    border: 0;
    padding: 14px;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const BigText = styled(BaseText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  &.blue {
    color: #4872f2;
  }

  &.gray {
    color: rgba(196, 196, 196, 1);
  }
`;
const Text = styled(BaseText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  &.blue {
    color: #4872f2;
  }

  &.gray {
    color: rgba(196, 196, 196, 1);
  }

  &.pointer {
    cursor: pointer;
  }
`;

const AttentionPanel = styled.div`
  display: flex;
  margin: 20px 0 30px 0;
`;

export default function DetailInfo() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const data = useTrainSelector();
  const train = data?.train;
  const trainArrivalDateTime = train?.localArrivalDate;
  const isBrandedTrain = train?.isBranded;
  const isSapsan = train?.trainName === 'САПСАН';
  const carsStatus = useSelector(trainTicketsSearchSelector).carsStatus;

  const trainHasDiffArrivalDateTime = useMemo(() => {
    return data?.cars?.some(
      (x) => x.carLocalArrivalDateTime !== trainArrivalDateTime
    );
  }, [data]);

  return (
    <Wrapper>
      {!isMobile && (
        <Head>
          <BigText>Выберите вагон и место</BigText>
          <Text
            onClick={() => {
              dispatch(carsPurify());
            }}
            className="blue pointer"
          >
            Свернуть выбор
          </Text>
        </Head>
      )}
      {trainHasDiffArrivalDateTime && (
        <AttentionPanel>
          <BaseText color={'#F25F61'} fontWeight={'600'}>
            В состав поезда входят вагоны, в которых маршрут следования и
            расписания отличаются от основного графика движения поезда.
          </BaseText>
        </AttentionPanel>
      )}

      <List>
        {carsStatus === 'loading' && <CarSkeleton />}
        {carsStatus !== 'loading' &&
          !!data?.cars?.length &&
          data.cars.map((car, key) => {
            const serviceClasses = getAllServiceClassesFromCar(car);
            const serviceClassString = serviceClasses.map((x) => x).join(', ');
            const hasAnotherArrivalDateTime =
              car.carLocalArrivalDateTime !== trainArrivalDateTime;
            const notBrandedCar = isBrandedTrain && car.carDescription.includes('НФ');
            return (
              <Car
                key={key}
                {...car}
                serviceClass={serviceClassString}
                open={data.currentCar.index === key}
                index={key}
                hasAnotherArrivalDateTime={hasAnotherArrivalDateTime}
                notBrandedCar={notBrandedCar}
                isSapsan={isSapsan}
              />
            );
          })}
      </List>
    </Wrapper>
  );
}
