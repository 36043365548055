import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';

const Wrapper = styled.div`
  padding: 24px 24px 20px 24px;
  background: #ffe2e3;
  color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid #f64c50;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 12px;
`;

const SubTitle = styled(Text)`
  display: block;
`;
const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 22px;
  margin-left: 5px;
  width: 20px;
  vertical-align: bottom;

  & > svg {
    position: absolute;
  }
`;

const BadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM17.781 7.281C17.9218 7.14017 18.0009 6.94916 18.0009 6.75C18.0009 6.55084 17.9218 6.35983 17.781 6.219C17.6402 6.07817 17.4492 5.99905 17.25 5.99905C17.0508 5.99905 16.8598 6.07817 16.719 6.219L12 10.9395L7.281 6.219C7.21127 6.14927 7.12848 6.09395 7.03738 6.05621C6.94627 6.01848 6.84862 5.99905 6.75 5.99905C6.65138 5.99905 6.55373 6.01848 6.46262 6.05621C6.37152 6.09395 6.28873 6.14927 6.219 6.219C6.14927 6.28873 6.09395 6.37152 6.05621 6.46262C6.01848 6.55373 5.99905 6.65138 5.99905 6.75C5.99905 6.84862 6.01848 6.94627 6.05621 7.03738C6.09395 7.12848 6.14927 7.21127 6.219 7.281L10.9395 12L6.219 16.719C6.07817 16.8598 5.99905 17.0508 5.99905 17.25C5.99905 17.4492 6.07817 17.6402 6.219 17.781C6.35983 17.9218 6.55084 18.0009 6.75 18.0009C6.94916 18.0009 7.14017 17.9218 7.281 17.781L12 13.0605L16.719 17.781C16.7887 17.8507 16.8715 17.906 16.9626 17.9438C17.0537 17.9815 17.1514 18.0009 17.25 18.0009C17.3486 18.0009 17.4463 17.9815 17.5374 17.9438C17.6285 17.906 17.7113 17.8507 17.781 17.781C17.8507 17.7113 17.906 17.6285 17.9438 17.5374C17.9815 17.4463 18.0009 17.3486 18.0009 17.25C18.0009 17.1514 17.9815 17.0537 17.9438 16.9626C17.906 16.8715 17.8507 16.7887 17.781 16.719L13.0605 12L17.781 7.281Z"
      fill="#3C3C3C"
    />
  </svg>
);

export default function Notification() {
  return (
    <Wrapper>
      <Title>
        Изменение полетных данных отклонено{' '}
        <IconContainer>
          <BadIcon />
        </IconContainer>
      </Title>
      <SubTitle>
        Вы отклонили изменение полетных данных по вашему перелету. Для получения
        информации обратитесь в чат к операционисту.
      </SubTitle>
    </Wrapper>
  );
}
