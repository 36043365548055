import { put, select } from 'typed-redux-saga';
import {
  initPriceDetail,
  updatePriceDetailItem,
  setSubagentTotal,
} from '../../duck';
import {
  getSelectedFare,
  getTotalPrice,
  getSubAgentCost,
} from '../../selectors';
import { getUserDataState } from '@modules/user';
import { PriceItem } from '../../types';
import { Helper } from '@utils';

const getPassengerName = (type: string, quantity: number) => {
  switch (type) {
    case 'Adult':
      return Helper.pluralWord(quantity, 'взрослый', 'взрослых', 'взрослых');
    case 'Child':
      return Helper.pluralWord(quantity, 'ребёнок', 'ребёнка', 'детей');
    default:
      return Helper.pluralWord(quantity, 'младенец', 'младенца', 'младенцов');
  }
};

export function* initPriceDetailWorker() {
  const fare = yield* select(getSelectedFare);
  const user = yield* select(getUserDataState);
  const subAgentExtraCharge = yield* select(getSubAgentCost);

  const passengerPricesFormated = fare.prices.reduce(
    (acc, x) => [
      ...acc,
      {
        quantity: 1,
        description: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        cost: x.total * x.count,
        type: 'pasenger-type',
        id: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        subAgentExtraCharge: x?.subAgentExtraCharge,
      },
    ],
    [] as PriceItem[]
  );

  const detailList = [...passengerPricesFormated];

  const totalPrice = yield* select(getTotalPrice);
  if (user && user.agentId) {
    detailList.push({
      quantity: 0,
      description: `Сумма сборов субагента`,
      cost: subAgentExtraCharge,

      type: 'subagent-cost',
      id: `subagent`,
    });
  }

  yield* put(
    initPriceDetail({
      total: totalPrice,
      detailList,
      subagentTotal:
        user && user.agentId ? totalPrice - subAgentExtraCharge : null,
    })
  );
}

export function* updateFareData() {
  const fare = yield* select(getSelectedFare);
  const user = yield* select(getUserDataState);
  const subAgentExtraCharge = yield* select(getSubAgentCost);
  // console.log(fare, 'fare previous');
  const passengerPricesFormated = fare.prices.reduce(
    (acc, x) => [
      ...acc,
      {
        quantity: 1,
        description: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
        cost: x.total * x.count,
        type: 'pasenger-type',
        id: `${x.count} ${getPassengerName(x.passengerType, x.count)}`,
      },
    ],
    [] as PriceItem[]
  );

  const totalPrice = yield* select(getTotalPrice);

  // console.log(
  //   fare,
  //   'fare',
  //   passengerPricesFormated,
  //   'passengerPricesFormated',
  //   totalPrice
  // );

  const detailList = [...passengerPricesFormated];

  if (user && user.agentId) {
    detailList.push({
      quantity: 0,
      description: `Сумма сборов субагента`,
      cost: subAgentExtraCharge,
      type: 'subagent-cost',
      id: `subagent`,
    });
  }

  for (const x of detailList) {
    yield* put(updatePriceDetailItem({ total: totalPrice, id: x.id, item: x }));
  }

  yield* put(
    setSubagentTotal(
      user && user.agentId ? totalPrice - subAgentExtraCharge : null
    )
  );
}
