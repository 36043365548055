import { ApplicationState } from '@modules/index';
import { createSelector } from 'reselect';

export const getUserState = (state: ApplicationState) => state.user;

export const getUserStatusState = (state: ApplicationState) =>
  state.user.status;

export const getUserDataState = (state: ApplicationState) => state.user.data;

export const aviaPassengersSelector = (state: ApplicationState) =>
  state.user.aviaPassengers;

export const getAuthStatus = createSelector(
  getUserStatusState,
  ({ isAuthorized }) => isAuthorized
);

export const getUserError = createSelector(
  getUserStatusState,
  (state) => state.error
);
