import React from 'react';

const TicketTrainIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7817 12.5H4V21.168C4 21.4554 4.11415 21.731 4.31735 21.9342C4.52055 22.1373 4.79614 22.2515 5.0835 22.2515H25.6592C26.3396 22.2527 27.0108 22.0937 27.6184 21.7873C28.2259 21.4809 28.7528 21.0357 29.1564 20.4878C29.5599 19.9399 29.8288 19.3047 29.9412 18.6336C30.0537 17.9625 30.0065 17.2744 29.8036 16.6249C29.3421 15.1556 28.6604 13.7648 27.7817 12.5Z"
        fill="#839FF6"
      />
      <path
        d="M28.9205 27.67H5.0835C4.79614 27.67 4.52055 27.5558 4.31735 27.3527C4.11415 27.1495 4 26.8739 4 26.5865C4 26.2991 4.11415 26.0235 4.31735 25.8204C4.52055 25.6172 4.79614 25.503 5.0835 25.503H28.9205C29.2079 25.503 29.4835 25.6172 29.6867 25.8204C29.8899 26.0235 30.004 26.2991 30.004 26.5865C30.004 26.8739 29.8899 27.1495 29.6867 27.3527C29.4835 27.5558 29.2079 27.67 28.9205 27.67ZM17.002 6.10185V10.334H25.9593C23.5349 7.95538 20.379 6.46425 17.002 6.10185ZM10.501 6V10.334H14.835V6H10.501ZM8.334 10.334V6H5.0835C4.79614 6 4.52055 6.11415 4.31735 6.31735C4.11415 6.52055 4 6.79614 4 7.0835V10.334H8.334Z"
        fill="#486AD4"
      />
    </svg>
  );
};

export default TicketTrainIcon;
