import { all, put, takeLatest } from 'typed-redux-saga';
import { changeSearchPanelType } from '@modules/ui/duck';
import { SEARCH_PANEL_TYPES } from '@modules/ui/types';
import { Action } from 'redux-act';
import { clearSimpleSearch, clearSimpleSearchTrain } from '@modules/simpleSearch';

export function* changeSearchPanelTypeSaga(params: Action<SEARCH_PANEL_TYPES>) {
  if (params.payload === 'avia') {
    yield* put(clearSimpleSearch());
  } else {
    yield* put(clearSimpleSearchTrain());
  }
}

export default function* uiFlow() {
  yield* all([
    takeLatest(changeSearchPanelType.getType(), changeSearchPanelTypeSaga),
  ]);
}
