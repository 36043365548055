import React from 'react';
import Tippy from '@tippyjs/react';
import { IconWithPopoverProps } from './types';
import styled from 'styled-components';

const LogoWrapper = styled.div<{ normalizePosition?: boolean }>`
  display: ${({ normalizePosition }) =>
    normalizePosition ? 'flex' : 'inline'};
  height: ${({ normalizePosition }) => (normalizePosition ? 'unset' : '20px')};
  width: ${({ normalizePosition }) => (normalizePosition ? 'unset' : '20px')};
`;

const Logo: React.FC<IconWithPopoverProps & React.PropsWithChildren> = ({
  children,
  tip,
  normalizePosition,
}) => {
  return (
    <Tippy content={tip} theme="light" offset={[0, 16.5]}>
      <LogoWrapper normalizePosition={normalizePosition}>
        {children}
      </LogoWrapper>
    </Tippy>
  );
};

export default Logo;
