import React from 'react';
import styled from 'styled-components';
import { Text, Button, Checkbox } from '@components/ui';
import { useSteps, STEPS } from '../useSteps';
import { useRefund } from '../useRefund';
import { Manager, SupportTicketCalculationItems } from '@modules/appeals';
import moment from 'moment';
import { formatTableRows } from '../utils';
import Tippy from '@tippyjs/react';
import { Helper } from '@utils';
import TableByTickets from './TableByTickets';

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    width: 190px;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
  }
  @media (max-width: 767px) {
      flex-direction: column-reverse; 
      & > button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 645px;
  padding: 30px;
  padding-right: 35px;
  box-sizing: border-box;

  & > ${ButtonBlock} {
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;

    & > ${ButtonBlock} {
      margin-top: 25px;
    }
  }
`;

const Title = styled(Text)`
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 25px;

  > span {
    color: rgba(72, 114, 242, 1);
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const TitleMobile = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 22px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const BottomText = styled(Text)`
  display: flex;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
`;

const BottomBlueText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #4872f2;
  margin-left: 3px;
`;

interface ConfirmRulesProps {
  error?: string;
}
const ConfirmRules = styled.div<ConfirmRulesProps>`
  margin-top: 18px;
  display: flex;
  align-items: center;
  & > ${Text} {
    display: block;
    margin-left: 10px;
    ${({ error }) => (error ? 'color: red;' : '')}
  }
  @media (max-width: 767px) {
    align-items: flex-start;
  }
`;

const Calculate: React.FC = () => {
  const [step, setStep] = useSteps();
  const props = useRefund();

  const { total, timelimit, id, onCancel } = props;

  const [{ isRulesAccepted, acceptError }, setRulesState] = React.useState({
    isRulesAccepted: step.meta === 'SHOW_MODE',
    acceptError: undefined as string | undefined,
  });

  const stillUtc = moment.utc(timelimit).toDate();
  const dateTo = moment(stillUtc).local().format('DD MMMM HH:mm');

  const passengersData = props?.selectedTickets.map((x) => {
    const calculation: SupportTicketCalculationItems | undefined | {} =
      props?.supportTicketCalculationItems
        ? props.supportTicketCalculationItems.find(
            (y) => y.aviaPassengerId === x.id
          )
        : {};

    return { selectedTicket: x, ...calculation, type: props.type };
  });

  const rows = passengersData.map((x) => ({
    item: x,
    rows: formatTableRows(x),
  }));

  return (
    <Wrapper>
      <Title>
        Расчет на доплату <span>№ {props.number}</span>
      </Title>
      <TitleMobile>Счет на доплату</TitleMobile>

      {!!rows?.length &&
        rows.map((x, i: number) => (
          <TableByTickets
            isLastElement={i === rows.length - 1}
            data={x as any}
            key={i}
            index={i}
          />
        ))}

      <BottomBlock>
        <BottomText>
          Итоговая сумма доплаты:{' '}
          <BottomBlueText>{` ${total} ₽`}</BottomBlueText>
        </BottomText>
        {timelimit && (
          <BottomText>
            Расчет действителен до: <BottomBlueText>{dateTo}</BottomBlueText>
          </BottomText>
        )}
        {props?.comment && (
          <BottomText>
            Комментарий к расчету:{' '}
            <BottomBlueText>{` ${props?.comment} `}</BottomBlueText>
          </BottomText>
        )}
      </BottomBlock>

      <ConfirmRules error={acceptError}>
        <Checkbox
          type="squar"
          error={acceptError}
          checked={isRulesAccepted}
          disabled={step.meta === 'SHOW_MODE'}
          onChange={(e) => {
            setRulesState((state) => ({
              acceptError: undefined,
              isRulesAccepted: !state.isRulesAccepted,
            }));
          }}
        />

        <Text>
          Я ознакомлен и согласен с условием на {props.subject}, договором{' '}
          <a
            style={{ color: '#4872F2', textDecoration: 'none' }}
            href="/docs/oferta.pdf"
            target="_blank"
          >
            публичной оферты
          </a>{' '}
          и{' '}
          <Tippy
            content={`Подтверждаю ${props.subject} по заказу №${
              props.orderNumber
            }. С доплатой в размере ${Helper.formatPrice(total)} согласен.`}
          >
            <Text color="blue" style={{ cursor: 'pointer' }}>
              текстом заявления{' '}
            </Text>
          </Tippy>{' '}
        </Text>
      </ConfirmRules>
      {step.meta !== 'SHOW_MODE' && (
        <ButtonBlock>
          <Button
            type="outlined"
            onClick={() => {
              setStep(STEPS.LOADING);
              Manager.declineSupportTicketCalculation(id)
                .then(() => {
                  const step =
                    window.innerWidth > 767 ? STEPS.SUCCESS : STEPS.HIDDEN;
                  setStep(step, 'declined');
                  onCancel();
                })
                .catch(() => setStep(STEPS.FAILURE));
            }}
          >
            Отклонить счёт
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (isRulesAccepted) {
                setStep(STEPS.LOADING);
                Manager.applySupportTicketCalculation(props.id)
                  .then(() => {
                    setStep(STEPS.HIDDEN);
                    props.onConfirm();
                  })
                  .catch(() => setStep(STEPS.FAILURE));
              } else {
                setRulesState((state) => ({
                  ...state,
                  acceptError: 'ошибочка',
                }));
              }
            }}
          >
            Одобрить доплату
          </Button>
        </ButtonBlock>
      )}
    </Wrapper>
  );
};

export default Calculate;
