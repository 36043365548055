import React from 'react';
import MobileTemplate from './Mobile';
import DesktopTemplate from './Desktop';
import { useMediaQuery } from 'react-responsive';

const Filter = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return isMobile ? <MobileTemplate /> : <DesktopTemplate />;
};

export default Filter;
