import React from 'react';
import moment from 'moment';
import { FlightSegmentProps } from '../types';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { Helper } from '@utils';
import { Point } from '@components/common/FlightSegment';
import Place from '@components/flightsSearch/TicketList/Place';
import PlaneIcon from '@assets/images/plane';
import flightNumberIcon from '@assets/media/flight-search/flightNumberIcon.svg';
import ManIcon from '@assets/images/man';
import { cityIn } from 'lvovich';
import { ApplicationState } from '@app/modules';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';

const Time = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  margin-bottom: 9px;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
`;

const ItemContainer = styled.li`
  list-style-type: none;
  position: relative;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
`;

const FlightTime = styled.span<{ blue?: boolean }>`
  display: inline;
  font-size: 12px;
  line-height: 16px;
  color: ${({ blue, theme: { colors } }) => (blue ? colors.main : '#737373')};

  @media (max-width: 767px) {
    display: none;
  }
`;

const ProgressInfo = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 10px;

  span {
    margin-bottom: 7px;
  }
`;

const ProgressLine = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: #dcdcdc;
  position: relative;

  & > div {
    top: -8px;
  }

  & > :last-child {
    right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 13px;
    & > div {
      top: -6px;
    }
  }
`;

const PlaceTimeWrapper = styled.div`
  display: flex;
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const PathTime = styled(Text)`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #737373;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 3px;

  @media (min-width: 768px) {
    display: none;
  }
`;
const Description = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPlaneIcon = styled(PlaneIcon)`
  width: 20px;
  height: 20px;
  fill: #737373;
  margin-right: 10px;
`;

const StyledManIcon = styled(ManIcon)`
  width: 16px;
  height: 16px;
  fill: #737373;
  margin-right: 14px;
`;

const DescriptionText = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const FlightNumberIcon = styled.img.attrs({ src: flightNumberIcon })`
  margin-right: 7px;
`;

const DescriptionBlock = styled.div`
  background: #edf1fe;
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 10px;

  & > div {
    margin-bottom: 7px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
`;

const ActiveDescriptionBlock = styled.div`
  margin-top: 12px;

  & > span {
    display: block;
    margin-bottom: 5px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const GrayText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
`;

const TechInfoIcon = styled.img.attrs({ src: techInfoIcon })`
  margin-right: 5px;
  margin-bottom: 7px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TechLanding = styled.img.attrs({ src: techInfoIcon })`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const TechLandingBlock = styled.div`
  padding: 4px 8px 4px 8px;
  background: #ffd3ca;
  border-radius: 8px;
  width: 165px;
  font-size: 12px;
  cursor: pointer;
`;

const Segment: React.FC<FlightSegmentProps> = ({
  from,
  to,
  className,
  ...props
}) => {
  const fromDate = moment(from.date);
  const toDate = moment(to.date);
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail.data);
  const techLandings = order?.orderPositions
    .map((x) =>
      x.segmentGroups.map((y) => y.segments.map((z) => z?.techLandings)).flat(),
    )
    .flat()
    .flat();

  const travelTime = Helper.formatDuration(props.duration);
  const showDefaultTravelTime =
    props.active || (!props.active && props.stops.length === 0);

  const stopString =
    props.stops.length > 0
      ? `${props.stops.length} ${Helper.pluralWord(
          props.stops.length,
          'пересадка',
          'пересадки',
          'пересадок',
        )}`
      : 'прямой';

  return (
    <div>
      <ItemContainer>
        <PlaceTimeWrapper>
          <Time color="#3C3C3C" bold>
            {fromDate.format('HH:mm')}
          </Time>
          <ProgressInfo>
            {/* <Text size="small" color="dark-gray">
            В пути: {travelTime}
          </Text> */}
            <ProgressLine>
              <PointsContainer>
                <Point />
                {!props.active && props.stops.length > 0 && (
                  <Point
                    mobileProps={{
                      duration: props.duration,
                      stopDuration: props.stops[0].duration,
                      stopCount: props.stops.length,
                      city: {
                        name: props.stops[0].city.city,
                        code: props.stops[0].city.code,
                      },
                      airport: {
                        name: props.stops[0].city.airport,
                        code: props.stops[0].city.code,
                      },
                    }}
                  />
                )}

                <Point />
                {showDefaultTravelTime && (
                  <PathTime>{Helper.formatDuration(props.duration)}</PathTime>
                )}
              </PointsContainer>
              {((from.techLandings && from.techLandings.length !== 0) ||
                techLandings.length !== 0) && (
                <>
                  {' '}
                  <Tippy
                    content={
                      <>
                        Техническая остановка в аэропорту{' '}
                        <b>
                          {from.techLandings && from.techLandings.length !== 0
                            ? from.techLandings[0].city.airport
                            : techLandings[0]?.city.airport}
                        </b>
                        ,{' '}
                        <b>
                          {from.techLandings && from.techLandings.length !== 0
                            ? from.techLandings[0].city.city
                            : techLandings[0]?.city.city}
                        </b>
                        . Длительность остановки{' '}
                        <b>
                          {from.techLandings && from.techLandings.length !== 0
                            ? from.techLandings[0].duration
                            : techLandings[0]?.duration}
                        </b>{' '}
                        минут.
                      </>
                    }
                    theme="light"
                    maxWidth={231}
                  >
                    <Flex>
                      <TechInfoIcon />
                      <Text
                        style={{
                          fontWeight: 'bold',
                          color: '#E73C3E',
                          fontSize: 10,
                        }}
                      >
                        тех. остановка
                      </Text>{' '}
                    </Flex>
                  </Tippy>
                </>
              )}
            </ProgressLine>
          </ProgressInfo>

          <Time color="#3C3C3C" bold>
            {toDate.format('HH:mm')}
          </Time>
        </PlaceTimeWrapper>
        <PlaceDateWrapper>
          <Place
            code={from.airport.code}
            airport={from.airport.name}
            date={fromDate}
          />

          <FlightTime>
            В пути: {travelTime},{' '}
            <FlightTime blue={props.stops.length > 0}>{stopString}</FlightTime>{' '}
          </FlightTime>
          <Place
            code={to.airport.code}
            airport={to.airport.name}
            date={toDate}
          />
        </PlaceDateWrapper>
      </ItemContainer>
      {(props.airlines || []).map((x, key) => (
        <CompanyLogoMobile key={key}>
          <Image src={Helper.getAirlinesLogoUrl(x.code, 65, 65)} alt={x.code} />{' '}
          <LogoDescription data-cy={`airlineSegmentCompanyName_${key}`}>
            {x.name}
          </LogoDescription>
        </CompanyLogoMobile>
      ))}
      {!props.active && (
        <CompanyLogoMobile>
          <FlightNumberIcon />{' '}
          <LogoDescription>
            {' '}
            Рейс: {props?.airplane ? props?.airplane : props.flightNo}{' '}
          </LogoDescription>
        </CompanyLogoMobile>
      )}

      {props.active && (
        <>
          {props.flightNo && (
            <DescriptionBlock>
              <Description>
                <StyledPlaneIcon />

                <DescriptionText>Рейс: {props.flightNo}</DescriptionText>
              </Description>
              {props.stops.map((x, key) => (
                <Description key={x.duration}>
                  <StyledManIcon />
                  <DescriptionText>
                    Пересадка в {cityIn(x.city.city)}{' '}
                    {Helper.formatDuration(x.duration)}
                  </DescriptionText>{' '}
                </Description>
              ))}
              {((from.techLandings && from.techLandings.length !== 0) ||
                techLandings.length !== 0) && (
                <>
                  {' '}
                  <Tippy
                    content={
                      <>
                        Техническая остановка в аэропорту{' '}
                        <b>
                          {from.techLandings && from.techLandings.length !== 0
                            ? from.techLandings[0].city.airport
                            : techLandings[0]?.city.airport}
                        </b>
                        ,{' '}
                        <b>
                          {from.techLandings && from.techLandings.length !== 0
                            ? from.techLandings[0].city.city
                            : techLandings[0]?.city.city}
                        </b>
                        . Длительность остановки{' '}
                        <b>
                          {from.techLandings && from.techLandings.length !== 0
                            ? from.techLandings[0].duration
                            : techLandings[0]?.duration}
                        </b>{' '}
                        минут.
                      </>
                    }
                    theme="light"
                    maxWidth={231}
                  >
                    <TechLandingBlock>
                      <Description>
                        <TechLanding />
                        <DescriptionText style={{ color: '#E73C3E' }}>
                          Техническая остановка
                        </DescriptionText>
                      </Description>
                    </TechLandingBlock>
                  </Tippy>
                </>
              )}
            </DescriptionBlock>
          )}

          {(from.terminal ||
            to.terminal ||
            (props.airlines &&
              props.airlines.length > 0 &&
              !!props.airlines[0].code)) && (
            <ActiveDescriptionBlock>
              {(from.terminal || to.terminal) && (
                <GrayText>
                  Терминалы: {from.terminal} - вылет, {to.terminal} - прилет
                </GrayText>
              )}

              <GrayText>
                Рейс выполняет: {(props.airlines || [])[0].name}
              </GrayText>
            </ActiveDescriptionBlock>
          )}
        </>
      )}
    </div>
  );
};

export default Segment;
