import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useOrderPosition } from '../../useItemProps';
import { createAvailableActionsOptionsList } from '../../utils';
import { CreateFormPopup } from '@components/common/FormOfAppeal';
import { Api } from '@utils';
import { ORDER_SERVICE_HOST } from '@modules/constants';
import { cancelOrderPositionRequest, getDetailOrder } from '@modules/orders';
import { useDispatch, useSelector } from 'react-redux';
import { addAppealRefund } from '@modules/appeals';
import { ReactComponent as InfoIcon } from '@assets/media/info-icon.svg';
import { PositionAppealCode } from '../../types';

const createOrderLink = Api.createFetchUrl(
  ORDER_SERVICE_HOST,
  process.env.REACT_APP_ORDER_API as string,
);

const selectStyles: any = {
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Open Sans',
    color: '#4872F2',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    paddingLeft: '12px',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    color: '#4872F2',
  }),
  control: (provided: any, { isFocused, ...state }: any) => {
    const border = isFocused
      ? '1px solid #4872F2!important'
      : '1px solid #4872F2';
    const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
    const boxShadow = 'none';
    return {
      ...provided,
      height: 32,
      minHeight: 32,
      border,
      borderColor,
      boxShadow,
    };
  },
  menu: (provided: any, state: any) => ({ ...provided, zIndex: 100 }),
};

const StyledSelect = styled(Select)`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  max-width: 200px;
`;

export const PlaceholderWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Placeholder = styled.span`
  color: #4872f2;
`;

const CustomPlaceholder = () => (
  <PlaceholderWrapper>
    <InfoIcon />
    <Placeholder>Действия по позиции</Placeholder>
  </PlaceholderWrapper>
);

const ActionSelector: React.FC = () => {
  const [appeal, setAppeal] = React.useState<{
    open: boolean;
    subject?: string | null;
  }>({ open: false });

  const orderPosition = useOrderPosition();
  const dispatch = useDispatch();
  const { id } = useSelector(getDetailOrder);

  const printLink = createOrderLink(
    process.env.REACT_APP_PRINT_ORDER_E_TICKETS as string,
    {
      orderId: orderPosition.orderId,
      orderPositionId: orderPosition.id,
      format: 'pdf',
    },
  );

  const options = createAvailableActionsOptionsList(
    orderPosition.availableActions,
  );

  const handleSelectChange = (data: any) => {
    if (data.actions === 'CREATE_APPEAL') {
      if (data.value === PositionAppealCode.FULL_REFUND)
        dispatch(addAppealRefund());
      setAppeal({ open: true, subject: data.value });
    } else if (data.actions === 'PRINT') {
      window.open(printLink, '_blank');
    } else if (data.actions === 'CANCEL') {
      dispatch(
        cancelOrderPositionRequest({
          orderId: id,
          orderPositionId: orderPosition.id,
        }),
      );
    }
  };

  const handleAppealClose = () => setAppeal({ open: false, subject: '' });

  return (
    <>
      <StyledSelect
        value={null}
        components={{
          DropdownIndicator: null,
          Placeholder: () => <CustomPlaceholder />,
        }}
        styles={selectStyles}
        onChange={handleSelectChange}
        options={options}
        isSearchable={false}
      />
      <CreateFormPopup
        insurances={orderPosition.insurances}
        onlyOtherSubject={(orderPosition.availableActions || []).includes(
          'CREATE_APPEAL_WITH_ONLY_OTHER_THEME',
        )}
        open={appeal.open}
        onClose={handleAppealClose}
        orderPosition={orderPosition}
        defaultSubject={appeal.subject}
      />
    </>
  );
};

export default ActionSelector;
