import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { BlockWithMaxWidth, Title, GrayText } from './shared';
import { NavLink } from 'react-router-dom';
import { ReactComponent as MealIcon } from '@assets/media/addictional-services/meal.svg';
import { ReactComponent as ReturnIcon } from '@assets/media/addictional-services/return.svg';
import { ReactComponent as SeatMapIcon } from '@assets/media/addictional-services/seat-map.svg';
import { ReactComponent as PrintServiceIcon } from '@assets/media/addictional-services/print-service.svg';
import { ReactComponent as CellPhoneIcon } from '@assets/media/addictional-services/cellphone.svg';
import { ReactComponent as LuggageIcon } from '@assets/media/addictional-services/luggage.svg';
import { ReactComponent as ArrowIcon } from '@assets/media/main-page/telegram-link-arrow.svg';
import { ReactComponent as InsuranceIcon } from '@assets/media/addictional-services/insurance-main-banner.svg';
import { ReactComponent as BackgroundIcon } from '@assets/media/main-page/addictional-services-background.svg';

const Wrapper = styled(BlockWithMaxWidth)`
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const ShowMore = styled(NavLink)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-decoration: none;
  color: #4872f2;
  position: relative;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  right: -29px;
  top: -2px;
  & > path {
    fill: #4872f2;
  }
`;

const Dot = styled.div<{ inactive: boolean }>`
  width: 8px;
  height: 8px;
  border: none;
  outline: none;
  display: block;
  background: #4872f2;
  border-radius: 50%;
  opacity: ${({ inactive }) => (inactive ? '0.15' : '1')};
`;

const MobileDotsContainer = styled.div`
  display: none;
  justify-content: center;

  & > div {
    margin-right: 4px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;

const Container = styled.div`
  background: #edf1fe;
  border-radius: 28px;
  padding: 45px 45px 41px 45px;
  overflow: hidden;
  position: relative;
  & > span {
    display: block;
  }

  ${GrayText} {
    margin-bottom: 8px;
    z-index: 2;
  }

  ${Title} {
    margin-bottom: 35px;
    z-index: 2;
  }

  ${ShowMore} {
    margin-top: 37.5px;
    display: inline-block;
  }

  ${MobileDotsContainer} {
    margin-top: 20px;
  }

  @media (max-width: 1169px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    border-radius: 16px;
    padding: 30px 20px;
    border-radius: 0;

    ${Title} {
      margin-bottom: 30px;
    }

    ${ShowMore} {
      margin-top: 26px;
    }
  }
`;

const FlexWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 2;
`;

const FlexContainer = styled.div`
  & > :first-child {
    margin-bottom: 20px;
  }

  @media (max-width: 1169px) {
    display: flex;
    justify-content: space-between;
    & > :first-child {
      margin-right: 26px;
    }
  }

  @media (max-width: 767px) {
    overflow: scroll;
    margin-bottom: -30px;
    padding-bottom: 30px;
    & > :first-child {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  & > a {
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 1169px) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;

    & > :first-child {
      margin-bottom: 30px;
    }
    & > :nth-child(2) {
      margin-right: 0;
    }
  }

  @media (max-width: 1169px) {
    display: block;
    width: 668px;
    margin: 0 auto;

    & > :first-child {
      margin-bottom: 20px;
    }

    & > a {
      margin-bottom: 20px;
      margin-right: 0;
    }
    & > :nth-child(2) {
      margin-right: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    width: calc(100vw - 40px);
    flex-shrink: 0;

    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

const Background = styled(BackgroundIcon)`
  position: absolute;
  bottom: -177px;
  right: -192px;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 243px;
  text-decoration: none;
  border-radius: 16px;
  padding: 16px;
  padding-right: 4px;
  box-sizing: border-box;
  position: relative;
  background: white;
  border: 1px solid #d4d9de;
  transition: border-color 0.2s, box-shadow 0.2s;

  &:hover {
    border-color: rgb(184, 191, 231);
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 5px 3px;
  }

  & > * {
    pointer-events: none;
  }

  & > svg {
    margin-right: 16px;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
  }

  @media (max-width: 1169px) {
    height: 88px;
    width: 100%;
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

const LinkDescription = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  @media (max-width: 1169px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    & > br {
      display: none;
    }
  }
`;

export default () => {
  const ref = React.useRef(null as null | HTMLDivElement);
  const [currentPosition, setCurrentPosition] = React.useState('left');

  const scroll = React.useCallback(
    (touchDirection: string | null) => {
      if (touchDirection === 'left') {
        (ref.current as HTMLDivElement).scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        setCurrentPosition(touchDirection);
      } else if (touchDirection === 'right') {
        (ref.current as HTMLDivElement).scrollTo({
          left: 768,
          behavior: 'smooth',
        });
        setCurrentPosition(touchDirection);
      }
    },
    [ref]
  );

  React.useEffect(() => {
    if (ref.current === null) {
      return () => {};
    }
    ref.current.addEventListener('touchstart', handleTouchStart, false);
    ref.current.addEventListener('touchmove', handleTouchMove, false);
    ref.current.addEventListener('touchend', handleTouchEnd, false);

    let touchDirection: string | null = null;

    let xDown: any = null;
    let yDown: any = null;

    function handleTouchEnd(evt: any) {
      scroll(touchDirection);
    }

    function getTouches(evt: any) {
      return evt.touches || evt.originalEvent.touches;
    }

    function handleTouchStart(evt: any) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt: any) {
      if (!xDown || !yDown) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          touchDirection = 'right';
        } else {
          touchDirection = 'left';
        }
      } else {
        if (yDiff > 0) {
          /* down swipe */
        } else {
          /* up swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [ref]);

  return (
    <Wrapper>
      <Container>
        <GrayText>Не забудьте добавить</GrayText>
        <Title>Дополнительные услуги</Title>
        <FlexWrapper>
          <FlexContainer ref={ref}>
            <Flex>
              <Link to="/questions/additional-services/#sms">
                <CellPhoneIcon />
                <LinkDescription>
                  SMS-
                  <br />
                  информирование
                </LinkDescription>
              </Link>
              <Link to="/questions/additional-services/#meal">
                <MealIcon />
                <LinkDescription>
                  Питание в <br /> самолете
                </LinkDescription>
              </Link>
              <Link to="/questions/additional-services/#print">
                <PrintServiceIcon />
                <LinkDescription>
                  Справка о <br /> стоимости
                </LinkDescription>
              </Link>
              <Link to="/questions/additional-services/#insurance">
                <InsuranceIcon />
                <LinkDescription>Страхование</LinkDescription>
              </Link>
            </Flex>

            <Flex>
              <Link to="/questions/additional-services/#insurance">
                <ReturnIcon />
                <LinkDescription>Гарантия возврата билета</LinkDescription>
              </Link>
              <Link to="/questions/additional-services/#seat">
                <SeatMapIcon />
                <LinkDescription>Место в самолете</LinkDescription>
              </Link>
              <Link to="/questions/additional-services/#luggage">
                <LuggageIcon />
                <LinkDescription>Дополнительный багаж</LinkDescription>
              </Link>
            </Flex>
          </FlexContainer>
        </FlexWrapper>
        <MobileDotsContainer>
          <Dot
            onClick={() => scroll('left')}
            inactive={currentPosition === 'right'}
          />
          <Dot
            onClick={() => scroll('right')}
            inactive={currentPosition === 'left'}
          />
        </MobileDotsContainer>
        <ShowMore to="/questions/additional-services">
          Узнать подробнее <DesktopText>об услугах</DesktopText> <Arrow />
        </ShowMore>
        <Background />
      </Container>
    </Wrapper>
  );
};
