import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.div<{ isOpen: boolean }>`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #4872f2;
  cursor: pointer;

  svg {
    margin-left: 8px;
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : '180deg')});
    transition: transform 0.15s linear;
  }
`;

export const Dropdown = styled.ul`
  margin: 0;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 100%;
  min-width: 159px;
  height: 128px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.05);
  list-style-type: none;
  padding: 8px 13px 8px 8px;
  background-color: #fff;
  overflow-y: auto;
  z-index: 2;
  box-sizing: border-box;
`;

export const Option = styled.li`
  padding: 6px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #edf1fe;
  }
`;
