import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@components/ui';

interface Props {
  type: 'warning' | 'danger' | 'info';
  header: string;
  text: string;
  icon?: ReactNode;
  moreMargin?: boolean;
}

const NotyContainer = styled.div<{ type: 'warning' | 'danger' | 'info' }>`
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  ${(props) => {
    switch (props.type) {
      case 'danger':
        return css`
          background: #ffe2e3;
          border: 0;
        `;
      case 'warning':
        return css`
            background: #FFF3CD;
            border: 1px solid #f5be8f;
        `;
      case 'info':
      default:
        return css`
          background: #edfaf9;
          border: 1px solid #8ac5c0;
        `;
    }
  }};

  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const NotyContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;

  & * strong {
    font-weight: 600;
  }
`;

const NotificationPanel: React.FC<Props> = (props) => {
  const { type, header, text, icon, moreMargin } = props;
  return (
    <NotyContainer type={type}>
      <NotyContent>
        {icon}
        <Text>
          <strong>{header}</strong>
          <br />
          {moreMargin && <br/>}
          {text}
        </Text>
      </NotyContent>
    </NotyContainer>
  );
};

export default NotificationPanel;
