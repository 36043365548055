import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { Title, GrayText, Paragraph } from './shared';
import { ReactComponent as DiscountBadgeIcon } from '@assets/media/main-page/discount-badge.svg';
import { ReactComponent as StopwatchIcon } from '@assets/media/main-page/stopwatch.svg';
import { ReactComponent as SecurityIcon } from '@assets/media/main-page/security.svg';
// import { ReactComponent as PhoneSupportIcon } from '@assets/media/main-page/phone-support.svg';
import { ReactComponent as CircleFromRowsIcon } from '@assets/media/main-page/circle-from-rows.svg';
import { ReactComponent as BlueCircleIcon } from '@assets/media/main-page/blue-circle.svg';

interface Props {
  type: 'train' | 'avia' | 'bus';
}

const Container = styled.div`
  background: #edf1fe;
  border-radius: 28px;
  padding: 45px 45px 41px 45px;
  position: relative;
  overflow: hidden;

  & > span {
    display: block;
  }

  ${GrayText} {
    margin-bottom: 8px;
    z-index: 2;
  }

  ${Title} {
    margin-bottom: 24px;
    z-index: 2;
  }

  @media (max-width: 1169px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
    border-radius: 0;

    ${Title} {
      margin-bottom: 30px;
    }
  }
`;

const Flex = styled.div`
  display: block;
  width: auto;

  & > div {
    margin-bottom: 35px;
    margin-right: 0;
  }

  & > :first-child {
    margin-bottom: 35px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1169px) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 668px;
    margin: 0 auto;

    & > :first-child {
      margin-bottom: 30px;
    }

    & > :nth-child(2) {
      margin-right: 0;
    }
  }

  @media (max-width: 1169px) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    & > div {
      margin-right: 0;
    }

    & > :first-child {
      margin-bottom: 30px;
    }

    & > :nth-child(2) {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    display: block;
    width: auto;

    & > div {
      margin-bottom: 35px;
      margin-right: 0;
    }

    & > :first-child {
      margin-bottom: 35px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

const AdvantageTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
`;

const AdvantageHead = styled.div`
  ${AdvantageTitle} {
    display: block;
  }

  display: flex;
  align-items: center;

  & > svg {
    width: 36px;
    height: 36px;
    margin-bottom: 0;
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    display: flex;
    align-items: center;

    & > svg {
      width: 36px;
      height: 36px;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;

const Advantage = styled.div`
  width: auto;

  & > span {
    display: block;
  }

  ${AdvantageHead} {
    margin-bottom: 12px;
  }

  @media (max-width: 1169px) {
    width: calc(50vw - 73px);
  }

  @media (max-width: 767px) {
    width: auto;
  }
`;

const CircleFromRows = styled(CircleFromRowsIcon)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    opacity: 0.3;
  }
`;

const BlueCircle = styled(BlueCircleIcon)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 767px) {
    height: 194px;
    width: 194px;
    right: -50px;
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const WhyChooseUsBanner: React.FC<Props> = (props) => {
  return (
    <Container>
      <GrayText>
        Экономьте время и деньги, <DesktopText>выбирая лучшее</DesktopText>
      </GrayText>
      <Title>Почему выбирают именно нас?</Title>
      <Flex>
        <Advantage>
          <AdvantageHead>
            <DiscountBadgeIcon />
            <AdvantageTitle>Выгодная цена</AdvantageTitle>
          </AdvantageHead>
          <Paragraph>
            Бронируйте по выгодной стоимости, без наценок и комиссии, а наша
            система надежно защитит ваши данные при онлайн-оплате.
          </Paragraph>
        </Advantage>
        <Advantage>
          <AdvantageHead>
            <StopwatchIcon />
            <AdvantageTitle>Скорость и удобство</AdvantageTitle>
          </AdvantageHead>
          <Paragraph>
            {props.type === 'train' &&
              'С помощью легкой формы поиска вы можете сравнить цены на ближайшие дни, найти самый удобный вариант, посмотреть полный маршрут следования поезда.'}
            {props.type === 'avia' &&
              'С помощью легкой формы поиска вы можете сравнить цены на ближайшие дни, составить сложные маршруты, комбинируя разные рейсы.'}
          </Paragraph>
        </Advantage>
        <Advantage>
          <AdvantageHead>
            <SecurityIcon />
            <AdvantageTitle>Гарантия безопасности</AdvantageTitle>
          </AdvantageHead>
          <Paragraph>
            {props.type === 'train' &&
              'FUN&SUN — надёжный туроператор с безупречной репутацией. Мы осуществляем полную поддержку наших клиентов от этапа покупки билетов до станции прибытия.'}
            {props.type === 'avia' &&
              'FUN&SUN – туроператор с безупречной репутацией. Мы осуществляем поддержку клиентов от этапа покупки билетов до приземления.'}
          </Paragraph>
        </Advantage>
        {/* <Advantage>
        <AdvantageHead>
          <PhoneSupportIcon />
          <AdvantageTitle>Ежедневная поддержка</AdvantageTitle>
        </AdvantageHead>
        <Paragraph style={{ width: 263 }}>
          Наша профессиональная команда сотрудников оказывает ежедневную
          поддержку (c 9:00 до 21:00 по МСК) на всех этапах бронирования.
        </Paragraph>
      </Advantage> */}
      </Flex>
      <BlueCircle />
      <CircleFromRows />
    </Container>
  );
};
