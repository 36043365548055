import React from 'react';
import { PaymentProps } from './types';

const Steps = React.createContext<PaymentProps>(null as any);

export const useRefund = () => React.useContext(Steps);

export const RefundProvider: React.FC<PaymentProps & React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <Steps.Provider value={props}>{children}</Steps.Provider>;
};
