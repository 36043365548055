import React from 'react';
import Service from './Service';
import { useSelector, useDispatch } from 'react-redux';
import Modal from './Modal';
import {
  getBookingAncillaryServicesState,
  getBookingPassengersState,
  switchAncillaryService,
  AncillaryServiceType,
} from '@modules/booking';
import { SeatMapNotificationModal } from '@components/common/Modals';
import { useModalState } from '../context';
import { Enums } from '../types';
import AncillaryDetailsModal from './AncillaryDetailsModal';

export default function () {
  const services = useSelector(getBookingAncillaryServicesState);
  const passengers = useSelector(getBookingPassengersState);
  const dispatch = useDispatch();

  const [index, setIndex] = React.useState(null as null | number);
  const [notificationVisibility, setNotification] = React.useState(false);
  const { visibility, hideModal, showModal } = useModalState();

  const handleClick = (x: number, type: AncillaryServiceType) => {
    setIndex(x);
    showModal(Enums.Ancillary);
  };
  const handleClose = () => {
    if (services[index as number].segments.find((x) => !x.fulfilled)) {
      setNotification(true);
    } else if (visibility !== Enums.AncillaryDetails) {
      setIndex(null);
      hideModal();
    }
  };

  return (
    <>
      {services.map((x, key) => (
        <>
          <Service
            key={x.type}
            type={x.type}
            minPrice={x.minPrice}
            data={x.addictionalInfo}
            passengers={passengers}
            index={key}
            onClick={handleClick}
          />
        </>
      ))}
      {index !== null && (
        <AncillaryDetailsModal
          open={visibility === Enums.AncillaryDetails}
          data={services[index]}
          onClose={() => {
            if (
              visibility !== Enums.SeatMap &&
              visibility !== Enums.Ancillary &&
              visibility !== Enums.SeatMapDetails
            ) {
              setNotification(false);

              hideModal();
              showModal(Enums.Ancillary);
            }
          }}
        />
      )}

      {index !== null &&
        visibility === Enums.Ancillary &&
        !notificationVisibility && (
          <Modal
            onClose={handleClose}
            data={services[index]}
            passengers={passengers}
            onItemClick={({ checked, ...data }) => {
              dispatch(switchAncillaryService(data as any));
            }}
          />
        )}
      {index !== null && notificationVisibility && (
        <SeatMapNotificationModal
          open
          data={services[index].segments}
          title="Выбрано не по всем маршрутам"
          subtitle={`Не забудьте выбрать дополнительн${
            services[index].type === AncillaryServiceType.Luggage
              ? 'ый багаж'
              : 'ое питание'
          } по маршруту`}
          buttonsText={[
            `Выбрать ${
              services[index].type === AncillaryServiceType.Luggage
                ? 'багаж'
                : 'питание'
            }`,
            `Закончить выбор ${
              services[index].type === AncillaryServiceType.Luggage
                ? 'багажа'
                : 'питания'
            }`,
          ]}
          onConfirm={() => {
            setNotification(false);
          }}
          onClose={() => {
            setNotification(false);
            setIndex(null);
          }}
        />
      )}
    </>
  );
}
