import React from 'react';
import { useSelector } from 'react-redux';
import FlightDetail from '@components/booking/FlightDetail/Desktop/FlightDetail';
import { ApplicationState } from '@modules/index';
import { orderDetailsStateToFlightEntity } from '@modules/booking/utils';
import { getMinBaggageDescription } from '@modules/orders/utils';

interface Props {
  onClick?: () => void;
  isOpen?: boolean;
}

const FlightDetailContainerStepTwo: React.FC<Props> = (props) => {
  const orderDetail = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.data,
  );
  const data = orderDetailsStateToFlightEntity(orderDetail);
  const [isActive, setActive] = React.useState(false);

  const isDetail = window.location.pathname.includes('/orders');

  let baggageDescr = '';
  const orderPositions = orderDetail.orderPositions;
  if (orderPositions?.length > 0 && orderPositions[0].tariff.fareFamilies) {
    baggageDescr =
      getMinBaggageDescription(orderPositions[0].tariff.fareFamilies) || '';
  }

  React.useEffect(() => {
    if (isDetail) {
      if (props.isOpen) {
        setActive(true);
      }
      if (!props.isOpen) {
        setActive(false);
      }
    }
  }, [isDetail, props.isOpen]);

  return (
    <FlightDetail
      baggageDescr={baggageDescr}
      isActive={isActive}
      data={data}
      isDetail={isDetail}
      setActive={setActive}
    />
  );
};

export default FlightDetailContainerStepTwo;
