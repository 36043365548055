import React from 'react';
import styled from 'styled-components';
import { SimpleSearch } from '@components/flightsSearch';
import { Text } from '@components/ui';
import { getUiState, setMobileHeadVisibility } from '@modules/ui';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';

const Wrapper = styled.div<{ mainPage?: boolean; top?: boolean | null }>`
  background: #edf1fe;
  transition: transform 0.3s ease-in-out;
  padding: 50px 0 16px 0;

  @media (max-width: 1169px) {
    padding: 30px 20px;
  }

  @media (max-width: 767px) {
    padding: 30px 5%;
    top: 54px;
    ${({ top }) => (top ? 'transform: translateY(-55px);' : '')}
  }
  top: 0;

  z-index: 999;
  ${({ mainPage }) => (mainPage ? 'position: sticky' : '')};
`;

const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;

const StyledSimpleSearch = styled(SimpleSearch)`
  @media (max-width: 1169px) {
    width: 100%;
    box-sizing: border-box;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2e2e32;
  margin-bottom: 20px;
  display: block;

  @media (min-width: 768px) and (max-width: 1169px) {
    display: none;
  }

  @media (max-width: 1169px) {
    margin-bottom: 24px;
  }
`;

// const DifficultSearch = styled(Text)`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 18px;
//   color: #4872f2;
//   display: block;
//   margin-top: 16px;

//   @media (max-width: 1169px) {
//     display: none;
//   }
// `;

const DesktopText = styled.span`
  display: inline;
  @media (max-width: 1169px) {
    display: none;
  }
`;

const MobileText = styled.span`
  display: none;
  @media (max-width: 1169px) {
    display: inline;
  }
`;

const SearchBlock: React.FC<{ mainPage?: boolean }> = ({ mainPage }) => {
  const dispatch = useDispatch();
  const { isMobileHeadVisible } = useSelector(getUiState);

  React.useEffect(
    () => () => {
      dispatch(setMobileHeadVisibility(true));
    },
    []
  );

  return (
    <Wrapper
      mainPage={false}
      // mainPage={location.pathname.includes('/search')}
      top={!isMobileHeadVisible}
    >
      <Container>
        <Title>
          <DesktopText>Авиабилеты</DesktopText> <MobileText>Билеты</MobileText>{' '}
          в любую точку мира
        </Title>
        <StyledSimpleSearch />
        {/* <DifficultSearch>Составить сложный маршрут</DifficultSearch> */}
      </Container>
    </Wrapper>
  );
};

export default SearchBlock;
