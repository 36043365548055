import React from 'react';
import styled from 'styled-components';
import { Train, CarGroup } from '@modules/trainTickets';

import TicketForwardContainer from '@components/train/TrainSearch/Ticket/TicketForwardContainer';
import moment from 'moment/moment';
import TrainFilteredListEmptyContainer from '@components/flightsSearch/Notifications/TrainFilteredListEmptyContainer';
import TicketBackwardContainer from '@components/train/TrainSearch/Ticket/TicketBackwardContainer';
import { RoundTripPanel } from '@components/train/Panels/RoundTripPanel';

interface Props {
  trains: Train[];
  trainsCount: number;
  searchStep: 'forward' | 'backward';
  hasRoundTrip: boolean;
}

const TicketList = styled.div`
  & > div {
    margin-bottom: 30px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const EmptySearchWrapper = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 52px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 5px, rgba(0, 0, 0, 0.1) 0px 10px 10px;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  font-family: 'Open Sans';
  min-width: 794px;
  box-sizing: border-box;
  color: rgb(60, 60, 60);
  align-self: baseline;
  margin: 0px auto;
`;

const EmptySearchHeader = styled.h2`
  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const EmptySearchList = styled.ul`
  padding-left: 0;
`;

const EmptySearchText = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const EmptySearchListItem = styled.li`
  & + li {
    margin-top: 15px;
  }
`;

export function TicketsList(props: Props) {
  const { trains, trainsCount, searchStep, hasRoundTrip } = props;

  const getMinPriceInCarGroups = (carGroups: CarGroup[]) => {
    return carGroups?.reduce((prev, current) =>
      prev.minPrice < current.minPrice ? prev : current
    ).minPrice.totalPrice;
  };

  const emptySearchResult = () => {
    return (
      <EmptySearchWrapper>
        <EmptySearchHeader>Билеты не найдены</EmptySearchHeader>
        <EmptySearchText>
          Это могло произойти из-за следующих причин:
        </EmptySearchText>
        <EmptySearchList>
          <EmptySearchListItem>
            <EmptySearchText>
              На эти даты все билеты куплены. Попробуйте поискать на другие
              даты.
            </EmptySearchText>
          </EmptySearchListItem>
          <EmptySearchListItem>
            <EmptySearchText>
              Неправильно выбраны даты. Проверьте год отправления и прибытия.
            </EmptySearchText>
          </EmptySearchListItem>
          <EmptySearchListItem>
            <EmptySearchText>
              По данному направлению не ходят поезда.
            </EmptySearchText>
          </EmptySearchListItem>
        </EmptySearchList>
      </EmptySearchWrapper>
    );
  };

  const fastestTrain =
    trains?.length > 0
      ? trains.reduce((prev, current) =>
          moment.duration(prev.tripDuration).asMinutes() <
          moment.duration(current.tripDuration).asMinutes()
            ? prev
            : current
        ).trainNumber
      : null;

  const cheapestTrain =
    trains?.length > 0
      ? trains?.reduce((prev, current) =>
          getMinPriceInCarGroups(prev.carGroups) <
          getMinPriceInCarGroups(current.carGroups)
            ? prev
            : current
        ).trainNumber
      : null;

  const fastestTrainMemo = React.useMemo(() => fastestTrain, [trains]);
  const cheapestTrainMemo = React.useMemo(() => cheapestTrain, [trains]);
  const emptySearchResultMemoized = React.useMemo(
    () => emptySearchResult(),
    []
  );

  return trainsCount === 0 ? (
    emptySearchResultMemoized
  ) : props.trains?.length > 0 ? (
    <TicketList>
      {props.hasRoundTrip && <RoundTripPanel />}
      {searchStep === 'forward'
        ? trains.map((train, key) => (
            <TicketForwardContainer
              isFastest={fastestTrainMemo === train.trainNumber}
              isCheapest={cheapestTrainMemo === train.trainNumber}
              hasRoundTrip={hasRoundTrip}
              key={train.index}
              train={train}
            />
          ))
        : trains.map((train, key) => (
            <TicketBackwardContainer
              isFastest={fastestTrainMemo === train.trainNumber}
              isCheapest={cheapestTrainMemo === train.trainNumber}
              hasRoundTrip={hasRoundTrip}
              key={train.index}
              train={train}
            />
          ))}
    </TicketList>
  ) : (
    <TrainFilteredListEmptyContainer />
  );
}
