import React from 'react';

import { Separator } from '@components/common';
import Text from '@components/ui/Typography';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { FareEntity, PriceItem } from '@modules/booking';
import { Button, Field } from '@components/ui';
import { useHistory } from 'react-router-dom';

import { Helper } from '@utils';
import AnimatedNumber from 'animated-number-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import commissionEye from '@assets/media/booking/commissionEye.svg';
import commissionEyeOpen from '@assets/media/booking/commissionEyeOpen.svg';

import { getUserDataState, setCommissionStatus } from '@modules/user';
import {
  getDetailOrder,
  promocodeApply,
  promocodeApplySuccess,
} from '@modules/orders';
import { ApplicationState } from '@modules/index';

interface Props {
  total: number;
  subagentTotal: number | null;
  detailList: PriceItem[];
  isShowCommission: boolean;
  fare?: FareEntity;
  title: string;
  noTimes?: boolean;
  isSecondBookingPage?: boolean;
  chosenPaymentMethod: string;
  priceType: 'avia' | 'train';
}

const PassengerPrices = styled(TransitionGroup).attrs({
  className: 'price-list',
  mode: 'ease-in-out',
})`
  overflow: hidden;
  margin: 24px 0 0 0;

  & > div {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  padding: 0 24px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.price-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  &.price-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition:
      transform 500ms,
      opacity 300ms;
  }

  &.price-exit {
    transform: translateX(0);
    opacity: 1;
  }

  &.price-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition:
      transform 500ms,
      opacity 500ms;
  }
`;

const Wrapper = styled.div``;
const WrapperWithPadding = styled.div`
  padding: 0 24px;
`;

const Title = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ShowCommissionBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #4872f2;
  cursor: pointer;
  max-width: 250px;
`;

const GoToOrderButton = styled.div`
  margin: 20px 0 0 0;
  background-color: #ffe100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e32;
  height: 46px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledField = styled(Field)`
  width: 185px;
  height: 40px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;

  color: #e73c3e;
  position: absolute;
  top: 40px;
`;

const StyledButton = styled(Button)`
  min-width: 110px;
  width: 110px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  margin-left: 15px;

  @media screen and (min-width: 768px) and (max-width: 1070px) {
    margin-top: 12px;
    width: 185px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 25px;
  @media screen and (min-width: 768px) and (max-width: 1070px) {
    flex-direction: column;
    align-items: baseline;
    margin-left: 0px;
  }
`;

const CommissionEye = styled.img.attrs({ src: commissionEye })`
  margin-right: 5px;
`;

const CommissionEyeOpen = styled.img.attrs({ src: commissionEyeOpen })`
  margin-right: 5px;
`;

const PassengerPrice: React.FC<Props> = (props: Props) => {
  const {
    total,
    subagentTotal,
    detailList,
    isShowCommission,
    fare,
    title,
    noTimes,
    chosenPaymentMethod,
    priceType,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector(getUserDataState);
  const [promocodeValue, setPromocodeValue] = React.useState('');
  const history = useHistory();

  const promocodeStatus = useSelector(
    (x: ApplicationState) => x.orders.orderDetail.promocodeApplyStatus,
  );

  const { acquiringCommissions, id, orderNumber } = useSelector(getDetailOrder);

  const isBookingSecondPage =
    window.location.pathname.includes('/bookingDetails');

  const agentDetails = detailList?.filter((x) =>
    x.description.includes('агента'),
  );

  const fareFamiliesTitle = fare?.fareFamilies.map((fare) => fare.name);

  return (
    <Wrapper>
      {/* {isBookingInfoModalOpen && (
        <BookingInfoModal
          isBookingInfoModalOpen={isBookingInfoModalOpen}
          setIsBookingInfoModalOpen={setIsBookingInfoModalOpen}
        />
      )} */}
      <WrapperWithPadding>
        <Title fontWeight="600" size="big">
          {title}{' '}
          {fare?.fareFamilies[0] && (
            <Title fontWeight="600" size="big" color="blue">
              {fareFamiliesTitle?.join(' + ')}
            </Title>
          )}
        </Title>
      </WrapperWithPadding>
      <PassengerPrices>
        {detailList
          .sort((a, b) => typeToNumber(a.type) - typeToNumber(b.type))
          .map((value, key) => (
            <CSSTransition key={value.id} timeout={500} classNames="price">
              <>
                {value.type === '17' || value.type === '16' ? (
                  <></>
                ) : (
                  value.quantity > 0 && (
                    <PriceContainer className="price">
                      <Row>
                        <Text
                          style={{
                            fontWeight:
                              value.type === 'subagent-cost' ? 600 : 400,
                          }}
                        >
                          {value.description}{' '}
                          {value.quantity > 1 &&
                            !noTimes &&
                            ` x ${value.quantity}  `}{' '}
                        </Text>
                        <Text
                          data-cy={
                            value.dataCy ? `${value.dataCy}RightPanel` : ''
                          }
                          style={{
                            fontWeight:
                              value.type === 'subagent-cost' ? 600 : 400,
                          }}
                        >
                          {}
                          {Helper.formatPrice(
                            priceType === 'avia'
                              ? value.cost * value.quantity
                              : value.cost,
                          )}
                        </Text>
                      </Row>
                    </PriceContainer>
                  )
                )}
              </>
            </CSSTransition>
          ))}
        {user?.agentId && (
          <ShowCommissionBlock
            onClick={() => dispatch(setCommissionStatus(!isShowCommission))}
          >
            {!isShowCommission ? <CommissionEyeOpen /> : <CommissionEye />}{' '}
            <div>
              {!isShowCommission
                ? 'Скрыть детали для агента'
                : 'Открыть детали для агента'}{' '}
            </div>
          </ShowCommissionBlock>
        )}
      </PassengerPrices>
      {chosenPaymentMethod &&
        chosenPaymentMethod !== 'Российская карта' &&
        chosenPaymentMethod !== 'Оплатить через СБП' && (
          <Row style={{ margin: '10px 24px 0px 24px' }}>
            <Text>Коммиссия за эквайринг</Text>
            <Text>
              {acquiringCommissions &&
                acquiringCommissions?.length > 1 &&
                acquiringCommissions[1] &&
                Helper.formatPrice(acquiringCommissions[1].acquiringAmount)}
            </Text>
          </Row>
        )}

      {/* {props.isSecondBookingPage && (
        <BookingInfoButton onClick={() => setIsBookingInfoModalOpen(true)}>
          Посмотреть состав бронирования
        </BookingInfoButton>
      )} */}
      <Separator />
      {isBookingSecondPage && (
        <Flex>
          <Column>
            <StyledField
              placeholder="% Промокод"
              value={promocodeValue}
              onChange={(e) => {
                setPromocodeValue(e.target.value);
                dispatch(promocodeApplySuccess(false));
              }}
            />
            {promocodeStatus && promocodeValue !== '' && (
              <Label style={{ fontSize: 12 }}>{promocodeStatus} </Label>
            )}
          </Column>

          <StyledButton
            onClick={() =>
              dispatch(
                promocodeApply({
                  orderId: id,
                  promocode: promocodeValue,
                }),
              )
            }
          >
            Применить
          </StyledButton>
        </Flex>
      )}

      <WrapperWithPadding>
        <Row>
          <Text fontWeight="600" color="#3C3C3C">
            Итоговая сумма
          </Text>
          <Text data-cy={'totalPriceRight'} fontWeight="600" color="blue">
            <AnimatedNumber
              duration={500}
              value={
                chosenPaymentMethod !== 'Российская карта' &&
                chosenPaymentMethod !== 'Оплатить через СБП'
                  ? total + acquiringCommissions[1].acquiringAmount
                  : total
              }
              formatValue={(value: number) => Helper.formatPrice(value)}
            />
          </Text>
        </Row>
        {!!subagentTotal && !isShowCommission && (
          <Row style={{ marginTop: 5 }}>
            <Text color="#3C3C3C">Сумма оплаты для агента</Text>
            <Text
              data-cy={'totalPriceRightForAgent'}
              fontWeight="600"
              color="blue"
            >
              <AnimatedNumber
                duration={500}
                value={subagentTotal}
                formatValue={(value: number) => Helper.formatPrice(value)}
              />
            </Text>
          </Row>
        )}

        {!isShowCommission &&
          agentDetails?.map((x, index) => (
            <Row key={index} style={{ marginTop: 5 }}>
              <Text color="#3C3C3C">{x.description}</Text>
              <Text
                data-cy={'totalPriceRightForAgent'}
                fontWeight="600"
                color="blue"
              >
                <AnimatedNumber
                  duration={500}
                  value={x.cost}
                  formatValue={(value: number) => Helper.formatPrice(value)}
                />{' '}
              </Text>
            </Row>
          ))}

        {isBookingSecondPage && (
          <GoToOrderButton
            onClick={async () => {
              history.push(`/orders/${orderNumber}`);
            }}
          >
            Оплатить
          </GoToOrderButton>
        )}
      </WrapperWithPadding>
    </Wrapper>
  );
};

function typeToNumber(type: string) {
  return type === 'subagent-cost' ? 1 : 0;
}

export default PassengerPrice;
