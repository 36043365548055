import { all, put, call, select, takeLatest } from 'typed-redux-saga';
import {
  createFeeRequest,
  deleteFeeRequest,
  editFeesRequest,
  getAgentFeesInfoRequest,
  getAgentFeesInfoSuccess,
  getAgentTaxesSuccess,
  updateTaxesFailure,
  updateTaxesRequest,
  updateTaxesSuccess,
  editFeesSuccess,
  createFeeSuccess,
} from '@modules/agents/agent-fees/duck';
import { setLKLoadingStatus } from '@modules/personalArea';
import { getAgentSiginState } from '@modules/agents/agent-finance/selectors';
import * as Manager from './Manager';
import { Action } from 'redux-act';
import { UpdateTaxesDto } from '@modules/agents/agent-fees/types';
import { FormIncomeValue } from '@components/agents/agent-rules/types';
import { NavigationService } from '@services/index';

function* getAgentFeesInfo() {
  yield* put(setLKLoadingStatus(true));
  const agent = yield* select(getAgentSiginState);

  try {
    if (agent) {
      const [fee, tax] = yield* all([
        call(Manager.getAgentFee, agent.agentId),
        call(Manager.getAgentTax, agent.agentId),
      ]);

      yield put(getAgentFeesInfoSuccess(fee));
      yield put(getAgentTaxesSuccess(tax));
    }

    yield* put(setLKLoadingStatus(false));
  } catch (err) {
    yield* put(setLKLoadingStatus(false));
  }
}

function* updateTaxesSaga(action: Action<UpdateTaxesDto>) {
  try {
    const agent = yield* select(getAgentSiginState);

    yield* call(Manager.updateTaxes, action.payload);
    if (agent) {
      const taxes = yield* call(Manager.getAgentTax, agent?.agentId);
      yield put(getAgentTaxesSuccess(taxes));
      yield put(updateTaxesSuccess());
    }
  } catch (err) {
    yield put(updateTaxesFailure());
  }
}

function* addFeeSafa(action: Action<FormIncomeValue>) {
  try {
    const agent = yield* select(getAgentSiginState);

    if (agent) {
      yield* call(Manager.createFee, action.payload, agent.agentId);
      const fee = yield* call(Manager.getAgentFee, agent?.agentId);
      yield put(getAgentFeesInfoSuccess(fee));
      yield put(createFeeSuccess(true));

      yield* call(NavigationService.navigate, '/profile/agent/fees');
    }
  } catch (err) {
    //
  }
}

function* editFeeSafa(action: Action<FormIncomeValue>) {
  try {
    const agent = yield* select(getAgentSiginState);

    if (agent) {
      yield* call(Manager.editFee, action.payload);
      const fee = yield* call(Manager.getAgentFee, agent?.agentId);
      yield put(getAgentFeesInfoSuccess(fee));
      yield put(editFeesSuccess(true));

      yield* call(NavigationService.navigate, '/profile/agent/fees');
    }
  } catch (err) {
    //
  }
}

function* deleteFeeSafa(action: Action<string>) {
  yield put(setLKLoadingStatus(true));
  try {
    const agent = yield* select(getAgentSiginState);

    if (agent) {
      yield* call(Manager.deleteFee, action.payload);
      const fee = yield* call(Manager.getAgentFee, agent?.agentId);
      yield put(getAgentFeesInfoSuccess(fee));
      yield put(setLKLoadingStatus(false));
    }
  } catch (err) {
    yield put(setLKLoadingStatus(false));
  }
}

export default function* rootSaga() {
  yield* all([
    takeLatest(getAgentFeesInfoRequest.getType(), getAgentFeesInfo),
    takeLatest(updateTaxesRequest.getType(), updateTaxesSaga),
    takeLatest(createFeeRequest.getType(), addFeeSafa),
    takeLatest(editFeesRequest.getType(), editFeeSafa),
    takeLatest(deleteFeeRequest.getType(), deleteFeeSafa),
  ]);
}
