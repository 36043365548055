import { Moment } from 'moment';
export enum DayStatus {
  today = 'today',
  hidden = 'hidden',
  disabled = 'disabled',
}

export function isToday(day: Moment, today: Moment, current: Moment) {
  return day.diff(today) === 0 && day.month() === current.month();
}

export function getDateStatus(day: Moment, current: Moment, today: Moment, daysLimit?: number) {
  const currentMonth = current.month();
  if (isToday(day, today, current)) {
    return DayStatus.today;
  }
  if (day.month() !== currentMonth) {
    return DayStatus.hidden;
  }
  // disable days before today or after daysLimit
  if (day.isBefore(today, 'day') || (daysLimit && day.diff(today, 'day') > daysLimit)) {
    return DayStatus.disabled;
  } else {
    return '';
  }
}

export function isTupleOfNull(arr: Array<Moment | null>) {
  return !arr[0] && !arr[1];
}

export function isBefore(date: Moment, arr: Array<Moment | null>) {
  if (!arr[1]) {
    return date.isBefore(arr[0]);
  } else {
    return date.isBefore(arr[1]);
  }
}
