import { createFetchUrl } from '../../../utils/fetch';
import { Api } from '@utils';
import {
  convertToRequestData,
  feesUiMapper,
} from '@modules/agents/agent-fees/utils';
import { UpdateTaxesDto } from '@modules/agents/agent-fees/types';
import { FormIncomeValue } from '@components/agents/agent-rules/types';

const AGENTS_HOST = process.env.REACT_APP_AGENTS_HOST as string;
const FEES_API = process.env.REACT_APP_AGENTS_FEES_API as string;
const TAXES_API = process.env.REACT_APP_AGENTS_TAXES_API as string;

const END_POINTS = {
  getFee: process.env.REACT_APP_AGENTS_GET_FEE as string,
  getTaxes: process.env.REACT_APP_AGENTS_GET_TAXES as string,
  updateTaxes: process.env.REACT_APP_AGENTS_UPDATE_TAXES as string,
  addFee: process.env.REACT_APP_AGENTS_CREATE_FEE as string,
  updateFee: process.env.REACT_APP_AGENT_UPDATE_FEE as string,
  deleteFee: process.env.REACT_APP_AGENT_DELETE_FEE as string,
};

const createTaxesUrl = createFetchUrl(AGENTS_HOST, TAXES_API);
const createFeesUrl = createFetchUrl(AGENTS_HOST, FEES_API);

export function getAgentFee(agentId: string) {
  return Api.request({
    url: createFeesUrl(END_POINTS.getFee, {
      agentId,
    }),
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => {
      return data?.agentFees?.length ? feesUiMapper(data.agentFees) : null;
    });
}

export function getAgentTax(agentId: string) {
  return Api.request({
    url: createTaxesUrl(END_POINTS.getTaxes, {
      agentId,
    }),
  })
    .then(Api.statuses)
    .then(Api.json);
}

export function updateTaxes(data: UpdateTaxesDto) {
  return Api.request(
    {
      url: createTaxesUrl(END_POINTS.updateTaxes),
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  )
    .then(Api.statuses)
    .then(Api.json);
}

export function createFee(data: FormIncomeValue, agentId: string) {
  const transformedData = convertToRequestData(data);

  return Api.request(
    {
      url: createFeesUrl(END_POINTS.addFee),
      method: 'POST',
      body: JSON.stringify({ ...transformedData, agentId }),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  ).then(Api.statuses);
}

export function editFee(data: FormIncomeValue) {
  const transformedData = convertToRequestData(data);

  return Api.request(
    {
      url: createFeesUrl(END_POINTS.updateFee),
      method: 'PUT',
      body: JSON.stringify(transformedData),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  ).then(Api.statuses);
}

export function deleteFee(feeId: string) {
  return Api.request(
    {
      url: createFeesUrl(END_POINTS.deleteFee),
      method: 'POST',
      body: JSON.stringify({ id: feeId }),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    },
    'AUTH'
  ).then(Api.statuses);
}
