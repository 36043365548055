import React from 'react';
import styled from 'styled-components';
import Modal from '@components/common/Modals/ImageModal';
import { useInView } from 'react-intersection-observer';
import { ImageWithModalProps } from './types';

const Container = styled.div<ImageWithModalProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || '8'}px;
  background-color: #f7f7f7;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;

    & > img {
      width: 100%;
      height: auto;
    }
  }
`;

export default function ImageWithModal({
  modalImage,
  className,
  ...props
}: ImageWithModalProps) {
  const [isLoaded, setLoad] = React.useState(false);
  const [isOpened, setOpen] = React.useState(false);
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView) {
      const img = new Image();
      img.src = props.src;
      img.onload = () => {
        setLoad(true);
      };
    }
  }, [props.src, inView]);

  const modalProps = modalImage
    ? { ...modalImage, scaleRate: 1 }
    : { ...props, scaleRate: props.scaleRate || 1.5 };

  return (
    <>
      <Container
        onClick={() => setOpen(true)}
        ref={ref}
        {...props}
        className={className}
      >
        {isLoaded && <img {...props} />}
      </Container>
      <Modal open={isOpened} onClose={() => setOpen(false)} {...modalProps} />
    </>
  );
}
