import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ReactComponent as BusIcon } from '@assets/images/booking/bus_circle.svg';
import { ReactComponent as NumberIcon } from '@assets/images/booking/number_circle.svg';
import { ReactComponent as RubleIcon } from '@assets/images/booking/ruble.svg';

export interface ShortCardProps {
  time: string;
  direction: string;
  price: string;
  carrier: string[];
  raceNumber: (string | undefined)[];
}

export const Wrapper = styled.div`
  position: relative;
`;

export const DateAndTime = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Row = styled.div`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Direction = styled.div`
  color: #3c3c3c;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  color: #4872f2;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  svg {
    margin-left: 5px;

    path {
      fill: #4872f2;
    }
  }
`;

export const RowWithIcon = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ShortCard = ({
  time,
  direction,
  price,
  carrier,
  raceNumber,
}: ShortCardProps): ReactElement => {
  return (
    <Wrapper>
      <DateAndTime>{time}</DateAndTime>
      <Row>
        <Direction>{direction}</Direction>
        <Price>
          {price}
          <RubleIcon />
        </Price>
      </Row>
      <RowWithIcon>
        <BusIcon />
        Перевозчик:&nbsp;<b>{carrier.join('; ')}</b>
      </RowWithIcon>
      <RowWithIcon>
        <NumberIcon />
        Рейс:&nbsp;
        <b>
          {raceNumber?.filter(Boolean).length === 0
            ? 'отсуствует'
            : raceNumber?.filter(Boolean).join('; ')}
        </b>
      </RowWithIcon>
    </Wrapper>
  );
};

export default ShortCard;
