import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { getBookingFaresState } from '@modules/booking';
import { useSelector } from 'react-redux';

const Container = styled.div`
  padding: 14px 11px 14px 24px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
`;

export default function TariffChangedNotification() {
  const fares = useSelector(getBookingFaresState);
  if (!fares.notificationVisibility) return null;

  return (
    <Container>
      <Text>
        Внимание, состав заказа изменен! Пожалуйста, проверьте наполнение заказа
      </Text>
    </Container>
  );
}
