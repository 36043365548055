import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Header, Text } from '@components/ui/Typography';
import { DesktopTemplate } from '@components/signUp';
// import { Hooks } from '@utils';
import { useHistory } from 'react-router-dom';

interface browserProps {
  browser?: string;
}

const StyledHeader = styled(Header)<browserProps>`
  text-align: center;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  ${({ theme }) => `
    ${theme.max('767px')} {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 8px;
    }
  `}
  @media screen and (max-width: 374px) {
    margin-top: ${({ browser }) => (browser === 'Safari' ? `0px` : '0px')};
  }
  @media screen and (min-width: 375px) and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @media {
      margin-top: 0;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const Container = styled(InfoBlock)`
  width: 468px;
  align-self: center;

  ${({ theme }) => `
    ${theme.max('767px')} {
      border: none;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0px 20px;
      width: 100%;
      box-shadow: none;
      margin-top:40px;
      height: calc(100vh - 54px);
    }
  `}
`;

const DescriptionRow = styled.div`
  margin-top: 30px;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const ForgetPasswordText = styled(Text)`
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }
`;

const SignUp: React.FC = () => {
  const [browser, setBrowser] = React.useState('');
  const ref = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  React.useEffect(() => {
    if (
      navigator.userAgent.indexOf('Edge') > -1 &&
      navigator.appVersion.indexOf('Edge') > -1
    ) {
      return setBrowser('any');
    } else if (
      navigator.userAgent.indexOf('Opera') != -1 ||
      navigator.userAgent.indexOf('OPR') != -1
    ) {
      return setBrowser('any');
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return setBrowser('Chrome');
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return setBrowser('Safari');
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return setBrowser('Firefox');
    } else {
      return setBrowser('');
    }
  }, []);

  return (
    <Wrapper ref={ref}>
      <Container>
        <StyledHeader browser={browser}>Регистрация</StyledHeader>
        <DesktopTemplate />
        <DescriptionRow>
          <Text size="big">
            Уже являетесь участником?{' '}
            <ForgetPasswordText
              size="big"
              fontWeight="600"
              onClick={() => history.push('/orders')}
            >
              Войдите
            </ForgetPasswordText>
          </Text>
        </DescriptionRow>
      </Container>
    </Wrapper>
  );
};

export default SignUp;
