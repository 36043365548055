import React from 'react';
import Category from './Category';
import { CheckboxGroup, PriceRange } from '@components/ui';
import TimeRangeWithPlane from '@components/flightsSearch/Filter/TimeRangeWithPlane';
import DateRangeWithPlane from '@components/flightsSearch/Filter/DateRangeWithPlane';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  resetTrainFilter,
  setCarrierTrainFilter,
  setCarTypeTrainFilter,
  setDurationTrainFilter,
  setPricesTrainFilter,
  setTimeTrainFilter,
  setTrainDiscountsTrainFilter,
  setTrainNameTrainFilter,
  setTrainServicesTrainFilter,
  setTrainTypeFilter,
  TrainFilter,
} from '@modules/trainTickets';

interface Props {
  filter: TrainFilter;
}

const Wrapper = styled.ul`
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;

  & > :first-child {
    padding-top: 0;
  }

  width: 100%;
  box-sizing: border-box;
`;

const FilterTrainsList = (props: React.PropsWithChildren<Props>) => {
  const {
    prices,
    trainTypes,
    carriers,
    trainNames,
    carTypes,
    trainServices,
    discounts,
    values,
    times,
    // departureDates,
    // arrivalDates,
    isDefault,
    filterDestinationType,
    trainDurationsList,
  } = props.filter;
  const dispatch = useDispatch();

  const carTypesValues = carTypes.map((carType) => {
    return {
      label: carType.label,
      value: carType.value,
      checked: values.carTypes[carType.value],
    };
  });

  const trainTypesValues = trainTypes.map((trainType) => {
    return {
      label: trainType.label,
      value: trainType.value,
      checked: values.trainTypes[trainType.value],
    };
  });

  const trainNamesValues = trainNames.map((trainName) => {
    return {
      label: trainName.label,
      value: trainName.value,
      checked: values.trainNames[trainName.value],
    };
  });

  const trainServicesValues = trainServices.map((trainService) => {
    return {
      label: trainService.label,
      value: trainService.value,
      checked: values.trainServices[trainService.value],
    };
  });

  const carriersValues = carriers.map((carrier) => {
    return {
      label: carrier.label,
      value: carrier.value,
      checked: values.carriers[carrier.value],
    };
  });

  const discountsValues = discounts.map((discount) => {
    return {
      label: discount.label,
      value: discount.value,
      checked: values.discounts[discount.value],
    };
  });

  return (
    <Wrapper {...props}>
      {values?.prices && (
        <Category
          active
          name="Цена билета"
          resetAvailable={!isDefault.prices}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'prices',
                type: filterDestinationType,
              })
            );
          }}
        >
          <PriceRange
            min={prices.min}
            max={prices.max}
            values={values?.prices}
            onAfterChange={(values) => {
              dispatch(
                setPricesTrainFilter({
                  values: values,
                  type: props.filter.filterDestinationType,
                })
              );
            }}
          />
        </Category>
      )}

      {values?.times && (
        <Category
          name="Время в пути"
          resetAvailable={!isDefault.trainDurations}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'trainDuration',
                type: filterDestinationType,
              })
            );
          }}
        >
          <TimeRangeWithPlane
            type={'train'}
            from={trainDurationsList[0].from}
            to={trainDurationsList[0].to}
            min={trainDurationsList[0].min}
            max={trainDurationsList[0].max}
            values={values.trainDurations}
            onAfterChange={(values) => {
              dispatch(
                setDurationTrainFilter({
                  values: values,
                  type: props.filter.filterDestinationType,
                })
              );
            }}
          />
        </Category>
      )}

      {values?.times && (
        <Category
          name="Время отправления и прибытия"
          resetAvailable={!isDefault.times}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'times',
                type: filterDestinationType,
              })
            );
          }}
        >
          <DateRangeWithPlane
            noIcon
            type={'train'}
            from={times[0].from}
            to={times[0].to}
            onChange={(type, values) => {
              dispatch(
                setTimeTrainFilter({
                  values: values,
                  timeType: type,
                  type: props.filter.filterDestinationType,
                })
              );
            }}
          />
        </Category>
      )}

      {!!trainTypesValues?.length && (
        <Category
          name="Тип поезда"
          resetAvailable={!isDefault.trainTypes}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'trainTypes',
                type: filterDestinationType,
              })
            );
          }}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(
                setTrainTypeFilter({
                  type: props.filter.filterDestinationType,
                  values: values,
                })
              );
            }}
            groupType="default"
            scrollContent
            type="squar"
            values={trainTypesValues}
          />
        </Category>
      )}

      {!!carTypesValues?.length && (
        <Category
          name="Тип вагона"
          resetAvailable={!isDefault.carTypes}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'carTypes',
                type: filterDestinationType,
              })
            );
          }}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(
                setCarTypeTrainFilter({
                  type: props.filter.filterDestinationType,
                  values: values,
                })
              );
            }}
            groupType="default"
            type="squar"
            values={carTypesValues}
          />
        </Category>
      )}

      {!!trainNamesValues?.length && (
        <Category
          name="Название поезда"
          resetAvailable={!isDefault.trainNames}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'trainNames',
                type: filterDestinationType,
              })
            );
          }}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(
                setTrainNameTrainFilter({
                  type: props.filter.filterDestinationType,
                  values: values,
                })
              );
            }}
            groupType="default"
            type="squar"
            values={trainNamesValues}
          />
        </Category>
      )}

      {!!carriersValues.length && (
        <Category
          name="Перевозчик"
          resetAvailable={!isDefault.carriers}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'carriers',
                type: filterDestinationType,
              })
            );
          }}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(
                setCarrierTrainFilter({
                  type: props.filter.filterDestinationType,
                  values: values,
                })
              );
            }}
            groupType="default"
            scrollContent
            type="squar"
            values={carriersValues}
          />
        </Category>
      )}

      {!!trainServicesValues.length && (
        <Category
          name="Услуги"
          resetAvailable={!isDefault.trainServices}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'trainServices',
                type: filterDestinationType,
              })
            );
          }}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(
                setTrainServicesTrainFilter({
                  type: props.filter.filterDestinationType,
                  values: values,
                })
              );
            }}
            groupType="default"
            type="squar"
            values={trainServicesValues}
          />
        </Category>
      )}

      {!!discountsValues.length && (
        <Category
          name="Льготный проезд"
          resetAvailable={!isDefault.discounts}
          onReset={() => {
            dispatch(
              resetTrainFilter({
                value: 'discounts',
                type: filterDestinationType,
              })
            );
          }}
        >
          <CheckboxGroup
            onChange={(val) => {
              const values = val as { [key: string]: boolean };
              dispatch(
                setTrainDiscountsTrainFilter({
                  type: props.filter.filterDestinationType,
                  values: values,
                })
              );
            }}
            groupType="default"
            type="squar"
            values={discountsValues}
          />
        </Category>
      )}
      {props.children}
    </Wrapper>
  );
};

export default FilterTrainsList;
