import React from 'react';
import styled from 'styled-components';
import { Field, PasswordField, Button } from '@components/ui';
import Text, { Link } from '@components/ui/Typography';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { StepTemplateProps, FirstStepSubmitParams } from './types';
import InputMask from 'react-input-mask';

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledPassword = styled(PasswordField)`
  width: 100%;
`;

const DescriptionBlock = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const StyledForm = styled(Form)`
  display: grid;
  row-gap: 30px;
`;

const StepOne: React.FC<StepTemplateProps<FirstStepSubmitParams>> = ({
  onSubmit,
}) => {
  const initialValues = {
    phone: '',
    email: '',
    password: '',
  };

  const handleSubmit = (values: typeof initialValues) => onSubmit(values);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        phone: yup
          .string()
          .matches(
            /\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/,
            'Некоректный номер телефона'
          )
          .required('Необходимо заполнить поле'),
        email: yup
          .string()
          .email('Некорректный email')
          .required('Необходимо заполнить поле'),
        password: yup
          .string()
          .required('Необходимо заполнить поле')
          .min(
            8,
            'Пароль должен содержать не менее 8 символов, строчные и заглавные латинские буквы и цифру'
          ),
      })}
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleBlur, errors, submitCount, values }) => (
        <StyledForm>
          <InputMask
            mask="+7 (999) 999-99-99"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
          >
            <StyledField
              error={errors.phone && submitCount > 0 ? errors.phone : undefined}
              name="phone"
              placeholder="Телефон"
            />
          </InputMask>

          <StyledField
            error={errors.email && submitCount > 0 ? errors.email : undefined}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
            placeholder="Email"
          />

          <StyledPassword
            error={
              errors.password && submitCount > 0 ? errors.password : undefined
            }
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Пароль"
            name="password"
          />
          <div>
            <Button
              type="primary"
              htmlType="submit"
              color="yellow"
              style={{ width: '100%' }}
            >
              Зарегистрироваться
            </Button>

            <DescriptionBlock>
              <Text size="big">
                Уже являетесь участником?{' '}
                <Link fontWeight="600" size="big" to="/sign-in">
                  Войдите
                </Link>
              </Text>
            </DescriptionBlock>
          </div>
        </StyledForm>
      )}
    </Formik>
  );
};

export default StepOne;
