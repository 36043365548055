import React from 'react';
import * as Styled from '../styles/Default.style';
import PassengerEditWarning from '@app/components/booking/PassengersBlock/PassengerEditWarning';

export const DefaultHeader = ({ isTrainOrder }: { isTrainOrder?: boolean }) => (
  <Styled.TextBlock>
    <Styled.Title>Создание обращения</Styled.Title>
    {isTrainOrder ? (
      <PassengerEditWarning isAppeal />
    ) : (
      <>
        <Styled.Description>
          Для возврата или обмена по заказу необходимо выбрать соответствующую
          тему обращения, а также:
        </Styled.Description>
        <Styled.DescriptionUl>
          <li>
            <strong>Если нужен возврат/обмен только части заказа</strong> -
            выберите из списка пассажиров, сегменты маршрута, по которым
            необходим возврат/обмен.
          </li>
          <li>
            <strong>Если нужен возврат/обмен заказа целиком</strong> - отметьте
            всех пассажиров и все сегменты маршрута.
          </li>
        </Styled.DescriptionUl>
      </>
    )}
  </Styled.TextBlock>
);
