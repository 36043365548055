import React from 'react';
import { ContextProps } from './types';
import { NotificationsProvider } from './useNotification';
import Queue from './Queue';

export default function Notification(props: ContextProps) {
  return (
    <NotificationsProvider {...props}>
      {content(props.notification.type)}
    </NotificationsProvider>
  );
}

function content(type: string) {
  switch (type) {
    case 'ChangeSegmentDates':
      return <Queue />;
    case 'ChangesPassengers':
      return <Queue />;
    default:
      return null;
  }
}
