type CreateParamFunction<T> = (args: [string, T]) => string;

export const createArrayParam: CreateParamFunction<any[]> = ([
  name,
  values,
]) => {
  const encodedName = encodeURIComponent(name);
  return values
    .map(encodeURIComponent)
    .map((value) => `${encodedName}=${value}`)
    .join('&');
};

export const createSimpleParam: CreateParamFunction<any> = (args) => {
  return args.map(encodeURIComponent).join('=');
};

export const createParamFactory = (value: any) => {
  const x = value[1];
  if (x === undefined) return '';
  if (Array.isArray(x)) return createArrayParam(value);
  return createSimpleParam(value);
};

export function createQueryParams(args: { [key: string]: any }) {
  return Object.entries(args).map(createParamFactory).join('&');
}

/**
 * Create url for rest api method
 * @example const createMethodUrl = createFetchUrl('http://localhost:3000','/api');
 * createMethodUrl('/addTodo'); // http://localhost:3000/api/addTodo
 * createMethodUrl('/addTodo',{id: 1}); // http://localhost:3000/api/addTodo?id=1
 */
export const createFetchUrl =
  (host: string | undefined, uri: string) =>
  (endpoint: string, queryParams?: { [key: string]: any }) =>
    host +
    uri +
    endpoint +
    (queryParams ? `?${createQueryParams(queryParams)}` : '');

export function appendObjectToFormData(
  key: string,
  value: any,
  formData: FormData
) {
  return appendObjectToFormDataRecursive(value, formData, key);
}

function appendObjectToFormDataRecursive(
  obj: any,
  formData: FormData,
  prevKey: string
) {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      appendObjectToFormDataRecursive(
        value,
        formData,
        prevKey ? `${prevKey}[${key}]` : `[${key}]`
      );
    } else {
      formData.append(prevKey + `[${key}]`, value as any);
    }
  });
}
