import React from 'react';
import styled from 'styled-components';
import { Text, Button, Checkbox } from '@components/ui';
import { useSteps, STEPS } from '../useSteps';
import { useRefund } from '../useRefund';
import { Manager } from '@modules/appeals';
import { FieldsTypes, EventSegmentsTypes } from '@modules/orders';
import { ReactComponent as Icon } from './assets/media/icon.svg';
import moment from 'moment';

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 10px;
  }
  ${({ theme }) => `
    ${theme.max('767px')} {
      flex-direction: column-reverse;
      & > button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  `}
`;

const Description = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  margin-top: 25px;
`;

const Wrapper = styled.div`
  width: 665px;
  padding: 40px;
  box-sizing: border-box;

  ${({ theme }) => `
    ${theme.max('767px')} {
      width: 100%;
      padding: 0;
    }
  `}

  ${Text} {
    display: block;
  }

  & > ${ButtonBlock} {
    margin-top: 40px;
  }
`;

const SegmentList = styled.div`
  margin-top: 25px;
  & > div {
    margin-bottom: 12px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Segment = styled.div``;

const SegmentName = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 12px;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
`;

const NameRow = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

const DateRow = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
`;

const BlueDateRow = styled(DateRow)`
  color: #4872f2;
`;

const DescriptionRow = styled(Text)`
  font-size: 12px;
  line-height: 16px;
`;

interface ConfirmRulesProps {
  error?: string;
}

const ConfirmRules = styled.div<ConfirmRulesProps>`
  margin-top: 28px;
  display: flex;
  align-items: center;
  & > ${Text} {
    display: block;
    margin-left: 10px;
    ${({ error }) => (error ? 'color: red;' : '')}
  }
`;

const TypeDescription = styled.div`
  display: flex;
  margin-bottom: 13px;
  align-items: center;
  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #f64c50;
    margin-left: 8px;
  }
`;

const FlexTable = styled.div`
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Refund: React.FC = () => {
  const [step, setStep] = useSteps();
  const _context = useRefund();
  const [{ isRulesAccepted, acceptError }, setRulesState] = React.useState({
    isRulesAccepted: step.meta === 'SHOW_MODE',
    acceptError: undefined as string | undefined,
  });

  return (
    <Wrapper>
      <Text size={18} bold>
        Подтверждение новых данных
      </Text>
      <Description>
        {_context.subjectCode !== 'ConsentToChangePassengers'
          ? 'Вы подверждаете новые полетные данные по вашему перелету.'
          : 'Вы подверждаете новые данные пассажиров по вашему перелету.'}
      </Description>
      <SegmentList>
        {(_context.segmentEvents || []).map((x, key) => (
          <Segment key={key}>
            <TypeDescription>
              <Icon width={20} height={20} />
              <span>{getTitle(x.type)} </span>
            </TypeDescription>
            <SegmentName>
              Перелет: {x.departureCity.name}-{x.arrivalCity.name}
            </SegmentName>
            {x.type === EventSegmentsTypes.Changed && (
              <Table>
                <DescriptionRow>Измененный параметр:</DescriptionRow>
                <DescriptionRow>Старые данные:</DescriptionRow>
                <DescriptionRow>Новые данные:</DescriptionRow>
                {x.fields.map((z, k) => (
                  <React.Fragment key={k}>
                    <NameRow>{z.description}</NameRow>
                    <DateRow>{getFormatedValue(z.type, z.oldValue)}</DateRow>
                    <BlueDateRow>
                      {getFormatedValue(z.type, z.newValue)}
                    </BlueDateRow>
                  </React.Fragment>
                ))}
              </Table>
            )}
            {x.type === EventSegmentsTypes.Added && (
              <FlexTable>
                {x.fields.map((z, k) => (
                  <div key={k}>
                    <NameRow>{z.description}:</NameRow>{' '}
                    <DateRow>{getFormatedValue(z.type, z.newValue)}</DateRow>
                  </div>
                ))}
              </FlexTable>
            )}
            {x.type === EventSegmentsTypes.Deleted && (
              <FlexTable>
                {x.fields.map((z, k) => (
                  <div key={k}>
                    <NameRow>{z.description}:</NameRow>{' '}
                    <DateRow>{getFormatedValue(z.type, z.oldValue)}</DateRow>
                  </div>
                ))}
              </FlexTable>
            )}
          </Segment>
        ))}
      </SegmentList>
      <ConfirmRules error={acceptError}>
        <Checkbox
          type="squar"
          error={acceptError}
          checked={isRulesAccepted}
          disabled={step.meta === 'SHOW_MODE'}
          onChange={(e) => {
            setRulesState((state) => ({
              acceptError: undefined,
              isRulesAccepted: !state.isRulesAccepted,
            }));
          }}
        />

        <Text>
          Я ознакомлен и согласен с условиями{' '}
          <a
            style={{ color: '#4872F2', textDecoration: 'none' }}
            href="/docs/oferta.pdf"
            target="_blank"
          >
            Обмена и возврата билета
          </a>
        </Text>
      </ConfirmRules>
      <ButtonBlock>
        <Button
          style={{ fontWeight: 'normal' }}
          type="primary"
          onClick={() => {
            if (isRulesAccepted) {
              setStep(STEPS.LOADING);
              Manager.applySupportTicketCalculation(_context.id)
                .then(() => {
                  setStep(STEPS.HIDDEN);
                  _context.onConfirm();
                })
                .catch(() => setStep(STEPS.FAILURE));
            } else {
              setRulesState((state) => ({
                ...state,
                acceptError: 'ошибочка',
              }));
            }
          }}
        >
          Да, подтвердить данные
        </Button>
        <Button
          type="outlined"
          onClick={() => {
            setStep(STEPS.HIDDEN);
          }}
        >
          Нет, вернуться к обращению
        </Button>
      </ButtonBlock>
    </Wrapper>
  );
};

function getFormatedValue(type: FieldsTypes, value: string) {
  switch (type) {
    case FieldsTypes.datetime:
      return moment(value).format('DD.MM.YYYY HH:mm');
    case FieldsTypes.string:
      return value;
  }
}

function getTitle(type: EventSegmentsTypes) {
  switch (type) {
    case EventSegmentsTypes.Added:
      return 'Новый рейс добавлен в заказ';
    case EventSegmentsTypes.Changed:
      return 'Произошло изменение рейса';
    case EventSegmentsTypes.Deleted:
      return 'Произошла отмена рейса';
    case EventSegmentsTypes.Undefined:
      return '';
  }
}

export default Refund;
