import React from 'react';
import styled from 'styled-components';
import { signInFunTinkoffRequest } from '@modules/user';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as TBankIcon } from '@assets/media/main-page/tbank_id_icon.svg';

export default function TinkoffLoginButton() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleTinkoffLogin = () => {
    sessionStorage.setItem('tinkoffRedirectUrl', window.location.href);
    dispatch(signInFunTinkoffRequest({ isMobile }));
  };

  const Button = styled.button`
    cursor: pointer;
    background: #ffdd2d;
    height: 44px;
    width: 100%;
    font-size: 14px;
    padding: 12px 0;
    border: none;
    border-radius: 8px;
    line-height: 22px;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;

    &:hover {
      background: #fcc521;
      box-shadow: 0 0 9px -1px #fcc521;
    }

    &:active {
      background: #fab619;
    }
  `;

  const ButtonContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    font-size: 14px;
  `;

  const Text = styled.span`
    color: #000000;
    opacity: 0.8;
    font-weight: 600;
  `;

  return (
    <Button onClick={handleTinkoffLogin}>
      <ButtonContent>
        <Text>Войти с</Text>
        <TBankIcon style={{ width: 34, height: 18 }} />
      </ButtonContent>
    </Button>
  );
}
