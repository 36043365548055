import React, { ReactElement, useEffect, useState } from 'react';
import RouteDetail, { BusForRouteDetail } from './components/RouteDetail';
import moment from 'moment';
import 'moment/locale/ru';
import { Wrapper, Divider, Header, Icon } from './styles';
import pasArrow from '@assets/media/order-detail/pasArrow.svg';
import ShortCard from '@components/bus/Booking/components/TripDetails/components/ShortCard';

export interface Props {
  isSecondStep?: boolean;
  forward: BusForRouteDetail;
  backward?: BusForRouteDetail;
  places: {
    forward?: string[];
    backward?: string[];
  };
}

const formatTime = (
  forward: BusForRouteDetail,
  backward?: BusForRouteDetail,
): string => {
  const firstDate = forward.departureDateTimeUtc;
  const secondDate = backward?.arrivalDateTimeUtc || forward.arrivalDateTimeUtc;

  const transformDate = (dateStr: string): string => {
    const date = moment(dateStr).locale('ru');

    const formattedDate = date.format('D MMM YYYY');
    const formattedTime = date.format('HH:mm');
    const formattedWeekday = date.format('ddd');

    return `${formattedDate}, ${formattedTime}, ${formattedWeekday}`;
  };

  return `${transformDate(firstDate)} - ${transformDate(secondDate)}`;
};

const TripDetails = ({
  forward,
  backward,
  places,
  isSecondStep,
}: Props): ReactElement | null => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const time = formatTime(forward, backward);
  const direction = [
    ...forward.busName.split('-').map((city) => city.trim()),
    ...(backward?.busName || '')
      .split('-')
      .map((city) => city.trim())
      .splice(1),
  ].join(' — ');
  const price = forward.price + (backward?.price || 0);
  const carrier = [
    ...forward.carrierDisplayNames,
    ...(backward?.carrierDisplayNames || []),
  ];
  const raceNumber = [forward.busNumber, backward?.busNumber];

  useEffect(() => {
    !isSecondStep && setIsOpen(true);
  }, [isSecondStep]);

  return (
    <Wrapper>
      <Header>
        <h2>Детали поездки</h2>
        {isSecondStep && (
          <Icon
            reverse={isOpen}
            src={pasArrow as string}
            alt="arrow"
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </Header>
      {isSecondStep && !isOpen && (
        <ShortCard
          time={time}
          direction={direction}
          price={String(price)}
          carrier={carrier}
          raceNumber={raceNumber}
        />
      )}
      {isOpen && (
        <>
          {forward !== undefined && (
            <RouteDetail
              bus={forward}
              direction="forward"
              transportNumber={forward.busNumber}
              places={places.forward}
              busAddresses={{
                forward: {
                  address: forward.address,
                  geolocation: forward.geolocation,
                },
                backward: {
                  address: backward?.address || null,
                  geolocation: backward?.geolocation || null,
                },
              }}
            />
          )}
          {backward !== undefined && (
            <>
              <Divider />
              <RouteDetail
                bus={backward}
                direction="backward"
                transportNumber={backward.busNumber}
                places={places.backward}
                busAddresses={{
                  forward: {
                    address: forward.address,
                    geolocation: forward.geolocation,
                  },
                  backward: {
                    address: backward.address,
                    geolocation: backward.geolocation,
                  },
                }}
              />
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default TripDetails;
