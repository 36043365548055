import React from 'react';

import styled from 'styled-components';
import {
  PassengerItemEntity,
  PassengerAgeCategory,
  getBookingAncillaryServicesState,
} from '@modules/booking';
import { Text } from '@components/ui';
import adultPath from '../assets/media/adult.svg';
import childPath from '../assets/media/child.svg';
import { ReactComponent as Cross } from '../assets/media/cross.svg';
import { Helper } from '@utils';

const PassengerSelect = styled.button<{ background: string }>`
  width: calc(100% - 32px);
  outline: none;
  padding: 15px;
  background: ${({ background }) => background};
  border: 1px solid
    ${({ background }) => (background === '#E3EAFF' ? '#4872F2' : '#c4c4c4')};
  box-sizing: content-box;
  border-radius: 6px;
  outline: none;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%), 0px 0px 5px rgb(0 0 0 / 5%);

  @media (max-width: 767px) {
    margin-top: 15px;
    margin-left: 15px;
    padding: 8px 8px;
    height: 42px;
    width: 94%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    border: ${({ background }) =>
      background === '#E3EAFF' ? 'none' : '1px solid #c4c4c4'};
  }
`;

const PassengerSelectLeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  & > img {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    height: 16px;
    margin-bottom: 5px;
    & > img {
      max-height: 16px;
    }
  }
`;

const PassengerSelectRightSide = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  & > :first-child {
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    height: 16px;
    margin-left: 16px;
  }
`;

const PassengerName = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const DesktopText = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileText = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;

const StyledCross = styled(Cross)`
  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
`;

const PassengerSelectButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    PassengerItemEntity & {
      number: number;
      selected: boolean;
      title: string;
      cost?: number;
      ageCategoryDescription?: string;
      seat?: any;
      onDelete?: any;
    }
> = (props) => {
  const iconPath =
    props.ageCategory === PassengerAgeCategory.Adult ? adultPath : childPath;

  return (
    <PassengerSelect
      {...props}
      background={props.selected ? '#E3EAFF' : '#ffffff'}
    >
      <PassengerSelectLeftSide>
        <img src={iconPath} alt="" />
        <PassengerName>
          <MobileText>
            Пассажир {props.number},{' '}
            {props.ageCategoryDescription
              ? props.ageCategoryDescription?.toLocaleLowerCase()
              : props.ageCategoryDescription?.toLocaleLowerCase()}
          </MobileText>

          <DesktopText>
            Пассажир {props.number},{' '}
            {props.ageCategoryDescription
              ? props.ageCategoryDescription?.toLocaleLowerCase()
              : props.ageCategoryDescription?.toLocaleLowerCase()}
          </DesktopText>
        </PassengerName>
      </PassengerSelectLeftSide>

      <PassengerSelectRightSide>
        {!props.seat && (
          <>
            {' '}
            {props.cost ? (
              <PassengerName style={{ fontWeight: 'bold', color: '#4872F2' }}>
                {Helper.formatPrice(props.cost)}
              </PassengerName>
            ) : (
              <PassengerName>Не выбрано</PassengerName>
            )}
          </>
        )}

        {props.seat && (
          <>
            <PassengerName>Кресло {props.seat}</PassengerName>
            <StyledCross
              onClick={() => props.onDelete(props.id, props.seat.segmentId)}
            />
          </>
        )}
      </PassengerSelectRightSide>
      {/* <PassengerSelectLeftSide>
        <img src={iconPath} alt="" />
        <PassengerName>{name}</PassengerName>
      </PassengerSelectLeftSide>

      <PassengerSelectRightSide>
        <PassengerName>{props.title}</PassengerName>
      </PassengerSelectRightSide> */}
    </PassengerSelect>
  );
};

export default PassengerSelectButton;
