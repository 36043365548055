import { put, select, call } from 'typed-redux-saga';
import { AncillaryServiceType } from '../../types';
import { Action } from 'redux-act';
import * as Sentry from '@sentry/react';
import {
  addPriceDetailItem,
  removePriceDetailItem,
  updatePriceDetailItem,
} from '../../duck';
import {
  getTotalPrice,
  getBookingAncillaryServicesState,
  getPriceDetail,
} from '../../selectors';
import { updateFareData } from '../workers';

export function* switchAncillaryServiceFlow({
  payload,
}: Action<{
  type: AncillaryServiceType;
  passengerId: string;
  segmentId: string;
  key: string;
}>) {
  try {
    const total = yield* select(getTotalPrice);
    const ancillaryServices = yield* select(getBookingAncillaryServicesState);
    const priceDetail = yield* select(getPriceDetail);

    const serviceByType = ancillaryServices.find(
      (x) => x.type === payload.type
    );

    if (!serviceByType) return;

    const item = serviceByType.offers[payload.segmentId][
      payload.passengerId
    ].find((x) => x.key === payload.key);

    if (!item) return;
    const id = `${item.title}-${item.cost}`;
    const currentItem = priceDetail.detailList.find((x) => x.id === id);
    if (item.checked) {
      let description = item.name;
      if (payload.type === AncillaryServiceType.Luggage) {
        description = item.luggageInfo
          ? `Багаж до ${item.luggageInfo.onePlaceWeight} кг`
          : 'Багаж';
      }
      if (currentItem) {
        yield* put(
          updatePriceDetailItem({
            total,
            id: id,
            item: { ...currentItem, quantity: currentItem.quantity + 1 },
          })
        );
      } else {
        yield* put(
          addPriceDetailItem({
            total,
            item: {
              description,
              cost: (item as any)?.total || item?.cost,
              type: payload.type.toLowerCase(),
              quantity: 1,
              id,
            },
          })
        );
      }
    } else {
      if (currentItem && currentItem.quantity > 1) {
        yield* put(
          updatePriceDetailItem({
            total,
            id: id,
            item: { ...currentItem, quantity: currentItem.quantity - 1 },
          })
        );
      } else {
        yield put(removePriceDetailItem({ total, id }));
      }
    }
    yield call(updateFareData);
  } catch (e) {
    Sentry.captureException(e);
  }
}
