import {
  Car,
  CarPlace,
  Discount,
  LoyaltyCardOption,
  Train,
} from '@modules/trainTickets';
import {
  FormikPassengerItemEntity,
  PassengerAgeCategory,
  PriceItem,
  TrainPriceItem,
} from '@modules/booking';
import { TrainPassengerInOrder } from '@modules/orders';
import {
  ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS,
  DOSS_CARRIER,
  FPK_CARRIER,
  LOYALTY_CARDS_NAMES,
  TARIFFS_NAMES,
  TVERSK_CARRIER,
} from '@modules/trainBooking/constants';
import { RailwayTariff } from '@modules/trainBooking/types';
import moment from 'moment/moment';
import { translatePassengerAgeCategory } from '../../utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { BedClothingService } from '@modules/trainBooking/sagas';

export const fillTrainPassenger = (
  count: number,
  type: PassengerAgeCategory,
) => {
  const list = [];
  for (let i = 0; i < count; i++) {
    list.push({
      name: '',
      surname: '',
      secondName: '',
      ageCategoryDescription: translatePassengerAgeCategory(type),
      documentType: 'PASSPORT',
      ageCategory: type,
      uid: uuidv4(),
      preferredAdultTariffType: 'Full',
      otherRailwayBonusCard: null,
      rzhdBonusCard: null,
      carPlaces: [],
    });
  }
  return list;
};

export const getFilledTrainPassengers = (
  adult: number,
  child: number,
  infant: number,
) => {
  // TODO: типизировать
  const passengersList: any[] = [];
  if (adult > 0) {
    passengersList.push(
      ...fillTrainPassenger(adult, PassengerAgeCategory.Adult),
    );
  }

  if (child > 0) {
    passengersList.push(
      ...fillTrainPassenger(child, PassengerAgeCategory.Child),
    );
  }

  if (infant > 0) {
    passengersList.push(
      ...fillTrainPassenger(infant, PassengerAgeCategory.Infant),
    );
  }

  return passengersList;
};

/**
 * Возвращает тип пола места по номеру места
 * Если пол не задан, но есть возможность его выбора, то заменяем на Mixed
 * @param placeNumber
 */
export const getCarPlaceGenderForBookingTypeByPlaceNumber = (
  placeNumber: string,
): 'NoValue' | 'Male' | 'Female' | 'Mixed' => {
  const match = placeNumber.replace(/\d/g, '');
  switch (match) {
    case 'Ж':
      return 'Female';
    case 'М':
      return 'Male';
    case 'Ц':
    case 'С':
      return 'Mixed';
    default:
      return 'NoValue';
  }
};

export const getDocumentTypeForDto = (
  type:
    | 'PASSPORT'
    | 'FOREIGN_DOCUMENT'
    | 'BIRTH_CERTIFICATE'
    | 'INTERNATIONAL_PASSPORT1',
):
  | 'RussianPassport'
  | 'ForeignPassport'
  | 'BirthCertificate'
  | 'RussianForeignPassport' => {
  switch (type) {
    case 'PASSPORT':
      return 'RussianPassport';
    case 'FOREIGN_DOCUMENT':
      return 'ForeignPassport';
    case 'BIRTH_CERTIFICATE':
      return 'BirthCertificate';
    case 'INTERNATIONAL_PASSPORT1':
      return 'RussianForeignPassport';
    default:
      return 'RussianPassport';
  }
};

export const getTrainPriceDetails = (
  placesForward: CarPlace[],
  placesBackward: CarPlace[],
  passengerCounters: [number, number, number],
  bedClothing: [BedClothingService] | [BedClothingService, BedClothingService],
): PriceItem[] => {
  const result: PriceItem[] = [
    {
      quantity: 0,
      description: `Билет, взрослый`,
      cost: 0,
      type: 'passenger-type',
      id: 'Билет, взрослый',
      subAgentExtraCharge: 0,
    },
    {
      quantity: 0,
      description: 'Билет, детский',
      cost: 0,
      type: 'passenger-type',
      id: 'Билет, детский',
      subAgentExtraCharge: 0,
    },
    {
      quantity: 0,
      description: 'Билет, младенец',
      cost: 0,
      type: 'passenger-type',
      id: 'Билет, младенец',
      subAgentExtraCharge: 0,
    },
  ];

  const maxSeats = passengerCounters[0] + passengerCounters[1];
  let usedSpecialReservationTypeForward = false;
  let usedSpecialReservationTypeBackward = false;
  const adultResult = result[0];
  const childResult = result[1];
  const infantResult = result[2];

  // ADULT
  if (placesForward.length > 0) {
    for (let i = 0; i < passengerCounters[0]; i++) {
      const place = placesForward[i];
      const adultPrice = place.prices.find((x) => x.tariffType === 'Full');
      const isSpecialReservationType =
        place.placeReservationType === 'FourPlacesAtOnce' ||
        place.placeReservationType === 'TwoPlacesAtOnce';
      adultResult.quantity = adultResult.quantity + 1;
      if (adultPrice) {
        if (isSpecialReservationType) {
          if (!usedSpecialReservationTypeForward) {
            if (maxSeats > 1) {
              adultResult.cost =
                adultResult.cost + adultPrice?.maxPrice.totalPrice;
            } else {
              adultResult.cost =
                adultResult.cost + adultPrice?.minPrice.totalPrice;
            }
            usedSpecialReservationTypeForward = true;
          }
        } else {
          adultResult.cost = place._isNotRefundable
            ? adultResult.cost + adultPrice?.minPrice.totalPrice
            : adultResult.cost + adultPrice?.maxPrice.totalPrice;
          if (bedClothing[0] && bedClothing[0].bedClothing === false) {
            adultResult.cost = adultResult.cost - bedClothing[0].cost;
          }
        }
      }
    }
  }
  if (placesBackward.length > 0) {
    for (let i = 0; i < passengerCounters[0]; i++) {
      const place = placesBackward[i];
      const adultPrice = place.prices.find((x) => x.tariffType === 'Full');
      const isSpecialReservationType =
        place.placeReservationType === 'FourPlacesAtOnce' ||
        place.placeReservationType === 'TwoPlacesAtOnce';
      adultResult.quantity = adultResult.quantity + 1;
      if (adultPrice) {
        if (isSpecialReservationType) {
          if (i === 0) {
            if (maxSeats > 1) {
              adultResult.cost = adultPrice?.maxPrice.totalPrice;
            } else {
              adultResult.cost = adultPrice?.minPrice.totalPrice;
            }
            usedSpecialReservationTypeBackward = true;
            break;
          }
        } else {
          adultResult.cost = place._isNotRefundable
            ? adultResult.cost + adultPrice?.minPrice.totalPrice
            : adultResult.cost + adultPrice?.maxPrice.totalPrice;
          if (bedClothing[1] && bedClothing[1].bedClothing === false) {
            adultResult.cost = adultResult.cost - bedClothing[1].cost;
          }
        }
      }
    }
  }
  adultResult.description = `${adultResult.description} x ${adultResult.quantity}`;

  // CHILD
  if (placesForward.length > 0) {
    if (usedSpecialReservationTypeForward && passengerCounters[1] > 0) {
      childResult.quantity = childResult.quantity + 1;
    } else {
      for (let i = 0; i < passengerCounters[1]; i++) {
        childResult.quantity = childResult.quantity + 1;
        const childPrice = placesForward[i + passengerCounters[0]].prices.find(
          (x) => x.tariffType === 'Child',
        );
        if (childPrice) {
          if (placesForward[i + passengerCounters[0]]._isNotRefundable) {
            childResult.cost =
              childResult.cost + childPrice.minPrice.totalPrice;
          } else {
            childResult.cost =
              childResult.cost + childPrice.maxPrice.totalPrice;
          }
          if (bedClothing[0] && bedClothing[0].bedClothing === false) {
            childResult.cost = childResult.cost - bedClothing[0].cost;
          }
        }
      }
    }
  }
  if (placesBackward.length > 0) {
    if (usedSpecialReservationTypeBackward && passengerCounters[1] > 0) {
      childResult.quantity = childResult.quantity + 1;
    } else {
      for (let i = 0; i < passengerCounters[1]; i++) {
        childResult.quantity = childResult.quantity + 1;
        const childPrice = placesBackward[i + passengerCounters[0]].prices.find(
          (x) => x.tariffType === 'Child',
        );
        if (childPrice) {
          if (placesBackward[i + passengerCounters[0]]._isNotRefundable) {
            childResult.cost =
              childResult.cost + childPrice.minPrice.totalPrice;
          } else {
            childResult.cost =
              childResult.cost + childPrice.maxPrice.totalPrice;
          }
          if (bedClothing[1] && bedClothing[1].bedClothing === false) {
            childResult.cost = childResult.cost - bedClothing[1].cost;
          }
        }
      }
    }
  }
  childResult.description = `${childResult.description} x ${childResult.quantity}`;

  // INFANT
  if (placesForward.length > 0) {
    for (let i = 0; i < passengerCounters[2]; i++) {
      infantResult.quantity = infantResult.quantity + 1;
    }
  }
  if (placesBackward.length > 0) {
    for (let i = 0; i < passengerCounters[2]; i++) {
      infantResult.quantity = infantResult.quantity + 1;
    }
  }
  infantResult.description = `${infantResult.description} x ${infantResult.quantity}`;

  // Оставшиеся места
  if (placesForward.length > passengerCounters[0] + passengerCounters[1]) {
    for (
      let i = passengerCounters[0] + passengerCounters[1];
      i < placesForward.length;
      i++
    ) {
      const place = placesForward[i];
      const adultPrice = place.prices.find((x) => x.tariffType === 'Full');

      if (
        adultPrice &&
        place.placeReservationType !== 'FourPlacesAtOnce' &&
        place.placeReservationType !== 'TwoPlacesAtOnce'
      ) {
        adultResult.cost += place._isNotRefundable
          ? adultPrice.minPrice.totalPrice
          : adultPrice.maxPrice.totalPrice;
      }
    }
  }

  if (placesBackward.length > passengerCounters[0] + passengerCounters[1]) {
    for (
      let i = passengerCounters[0] + passengerCounters[1];
      i < placesBackward.length;
      i++
    ) {
      const place = placesBackward[i];
      const adultPrice = place.prices.find((x) => x.tariffType === 'Full');

      if (
        adultPrice &&
        place.placeReservationType !== 'FourPlacesAtOnce' &&
        place.placeReservationType !== 'TwoPlacesAtOnce'
      ) {
        adultResult.cost += place._isNotRefundable
          ? adultPrice.minPrice.totalPrice
          : adultPrice.maxPrice.totalPrice;
      }
    }
  }

  return result;
};

export const getTrainPriceDetailsForOrder = (
  passengers: TrainPassengerInOrder[],
  positionTitle: string,
): TrainPriceItem[] => {
  const result: TrainPriceItem[] = [
    {
      quantity: 1,
      description: positionTitle,
      cost: 0,
      type: 'passenger-type',
      id: 'position',
      subAgentExtraCharge: 0,
      additionalPriceItems: [],
    },
  ];

  const passengersPart = result[0].additionalPriceItems;
  passengers.forEach((passenger) => {
    const serviceChargeForPassenger = passenger.priceDetailsPerRoute.reduce(
      (acc, x) => acc + x.tax + x.extraCharge + x.agentExtraCharge,
      0,
    );
    const totalPriceForPassenger = passenger.priceDetailsPerRoute.reduce(
      (acc, x) =>
        acc + x.basePrice + x.tax + x.extraCharge + x.agentExtraCharge,
      0,
    );
    result[0].cost = result[0].cost + totalPriceForPassenger;
    const basePriceForPassenger =
      totalPriceForPassenger - serviceChargeForPassenger;
    const tariffTitle = getTariffNameByTariffType(
      passenger.priceDetailsPerRoute[0].ticketTariffInfo.tariffType,
    );
    switch (passenger.passengerCategoryType) {
      case 'Adult':
        passengersPart.push({
          quantity: 1,
          description: `Билет, ${tariffTitle}`,
          cost: totalPriceForPassenger,
          type: 'passenger-type',
          id: `${passenger.document.documentNumber}`,
          subAgentExtraCharge: 0,
          additionalPriceItems: [
            {
              quantity: 1,
              description: `Тариф`,
              cost: basePriceForPassenger,
              type: 'passenger-type',
              id: `tariff-${passenger.document.documentNumber}`,
              subAgentExtraCharge: 0,
              additionalPriceItems: [],
            },
            {
              quantity: 1,
              description: `Сервисный сбор`,
              cost: serviceChargeForPassenger,
              type: 'passenger-type',
              id: `extra-charge-${passenger.document.documentNumber}`,
              subAgentExtraCharge: 0,
              additionalPriceItems: [],
            },
          ],
        });
        break;

      case 'Child':
        passengersPart.push({
          quantity: 1,
          description: `Билет, ${tariffTitle}`,
          cost: totalPriceForPassenger,
          type: 'passenger-type',
          id: `${passenger.document.documentNumber}`,
          subAgentExtraCharge: 0,
          additionalPriceItems: [
            {
              quantity: 1,
              description: `Тариф`,
              cost: basePriceForPassenger,
              type: 'passenger-type',
              id: `tariff-${passenger.document.documentNumber}`,
              subAgentExtraCharge: 0,
              additionalPriceItems: [],
            },
            {
              quantity: 1,
              description: `Сервисный сбор`,
              cost: serviceChargeForPassenger,
              type: 'passenger-type',
              id: `extra-charge-${passenger.document.documentNumber}`,
              subAgentExtraCharge: 0,
              additionalPriceItems: [],
            },
          ],
        });
        break;

      case 'Infant':
        passengersPart.push({
          quantity: 1,
          description: `Билет, младенец`,
          cost: totalPriceForPassenger,
          type: 'passenger-type',
          id: `${passenger.document.documentNumber}`,
          subAgentExtraCharge: 0,
          additionalPriceItems: [],
        });
        break;
      default:
        break;
    }
  });

  return result;
};

export const LOYALTY_CARDS = ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS.filter(
  (x) => LOYALTY_CARDS_NAMES.includes(x.id),
);
export const TARIFFS = ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS.filter((x) =>
  TARIFFS_NAMES.includes(x.id),
);

export const getSameDiscountsByDiscountType = (
  discountFrom: Discount[],
  discountTo: Discount[],
): Discount[] => {
  const result: Discount[] = [];
  discountFrom.forEach((x) => {
    if (discountTo.find((y) => y.discountType === x.discountType)) {
      result.push(x);
    }
  });
  return result;
};

export const getTariffByDiscountType = (
  discountType: string,
): RailwayTariff | undefined => {
  return TARIFFS.find((x) => x.apiValue === discountType);
};

export const getTariffsByDiscountTypes = (
  discountTypes: string[],
): RailwayTariff[] => {
  return ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS.filter((x) =>
    discountTypes.includes(x.apiValue),
  );
};

export const getTariffNameByTariffType = (tariffType: string): string => {
  const tariff = ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS.find(
    (x) => x.apiValue === tariffType,
  );
  return tariff ? tariff.title : '';
};

export const filterOnlyLoyaltyCardsInTariffs = (
  tariffs: RailwayTariff[],
): RailwayTariff[] => {
  return tariffs.filter((x) => x.isLoyaltyCard);
};

export const filterNotLoyaltyCardsInTariffs = (
  tariffs: RailwayTariff[],
): RailwayTariff[] => {
  return tariffs.filter((x) => !x.isLoyaltyCard);
};

export const railwayTariffsToTariffsOptions = (
  tariffs: RailwayTariff[],
): LoyaltyCardOption[] => {
  return tariffs.map((tariff) => {
    return {
      value: tariff.apiValue,
      label: tariff.title,
    };
  });
};

export const filterTariffsByParams = (
  direction: 'forward' | 'backward',
  tariffs: RailwayTariff[],
  trains: {
    forward: Train;
    backward?: Train;
  },
  cars: {
    forward: Car;
    backward?: Car;
  },
  passenger: FormikPassengerItemEntity,
  allPassengers: FormikPassengerItemEntity[],
): RailwayTariff[] => {
  const carrierForward = cars.forward.carrier;
  const carrierBackward = cars.backward?.carrier || '';

  const currentPassenger = allPassengers.find(
    (x) => x.uid === passenger.uid,
  ) as FormikPassengerItemEntity;

  const allPassengersIsAdult = allPassengers.every(
    (passenger) => passenger.ageCategory === 'ADULT',
  );

  const passengersHasInfant = allPassengers.some(
    (passenger) => passenger.ageCategory === 'INFANT',
  );

  const currentPassengerHasRFCitizenship =
    currentPassenger.citizenship === 'RU';

  // check carriers
  const isDossForward = carrierForward === DOSS_CARRIER;
  const isFpkForward = carrierForward === FPK_CARRIER;
  const isTverskForward = carrierForward === TVERSK_CARRIER;
  const isDossBackward = carrierBackward === DOSS_CARRIER;
  const isFpkBackward = carrierBackward === FPK_CARRIER;
  const isTverskBackward = carrierBackward === TVERSK_CARRIER;
  const isDossBoth = isDossForward && isDossBackward;
  const isFpkBoth = isFpkForward && isFpkBackward;
  const isTverskBoth = isTverskForward && isTverskBackward;

  // check passenger age
  const departureDate = trains.forward.localDepartureDate;
  const passengerYears = currentPassenger.birthDate
    ? moment(departureDate).get('year') -
      moment(currentPassenger.birthDate).get('year')
    : 0;

  const isChildCategory = currentPassenger.ageCategory === 'CHILD';
  const isInfantCategory = currentPassenger.ageCategory === 'INFANT';
  const isSeniorAge = passengerYears >= 60;
  const isJuniorAge = passengerYears >= 10 && passengerYears < 21;
  const isChild17Age = passengerYears >= 10 && passengerYears < 17;

  // any passenger choose Birthday tariff on forward direction
  const passengerWithSelectedBirthdayTariffForward = allPassengers.find(
    (passenger) => {
      return passenger.tariffsPerRoute[0].tariffType === 'Birthday';
    },
  );
  // any passenger choose Birthday tariff on backward direction
  const passengerWithSelectedBirthdayTariffBackward = allPassengers.find(
    (passenger) => {
      return passenger.tariffsPerRoute[1]?.tariffType === 'Birthday';
    },
  );

  // Forward BirthdayAccompanying tariff can be used only if passengerWithSelectedBirthdayTariffForward passenger exist and not equal passenger
  const canShowBirthdayAccompanyingForward =
    passengerWithSelectedBirthdayTariffForward !== undefined &&
    passengerWithSelectedBirthdayTariffForward.uid !== currentPassenger.uid;

  // Backward BirthdayAccompanying tariff can be used only if passengerWithSelectedBirthdayTariffBackward passenger exist and not equal passenger
  const canShowBirthdayAccompanyingBackward =
    passengerWithSelectedBirthdayTariffBackward &&
    passengerWithSelectedBirthdayTariffBackward.uid !== currentPassenger.uid;

  // check stations
  const stationForwardFrom = trains.forward.fromStation.id;
  const stationForwardTo = trains.forward.toStation.id;
  const stationBackwardFrom = trains.backward?.fromStation.id;
  const stationBackwardTo = trains.backward?.toStation.id;
  const isRoundTrip =
    stationForwardFrom === stationBackwardTo &&
    stationForwardTo === stationBackwardFrom;

  function isBirthdayNearDeparture(
    departure: moment.Moment,
    birthday: moment.Moment,
  ): boolean {
    const departureDayMonth = departure.clone().set({ year: 2000 });
    const birthdayDayMonth = birthday.clone().set({ year: 2000 });
    let diff = departureDayMonth.diff(birthdayDayMonth, 'days');

    if (Math.abs(diff) >= 365 - 7) {
      diff = Math.abs(diff) - 365;
    }
    return diff >= -7 && diff <= 7;
  }

  const isBirthday = isBirthdayNearDeparture(
    moment(departureDate),
    moment(currentPassenger.birthDate),
  );

  const filteredTariffs: RailwayTariff[] = [];
  tariffs.forEach((tariff) => {
    // for child and infant don't push any tariff
    if (isChildCategory || isInfantCategory) {
      return;
    }
    // Full
    if (tariff.id === 'Full') {
      filteredTariffs.push(tariff);
    }
    // RoundTrip
    // if (tariff.id === 'RoundTrip' && isRoundTrip) {
    //   filteredTariffs.push(tariff);
    // }
    // Senior
    // if (
    //   tariff.id === 'Senior30' &&
    //   isSeniorAge &&
    //   (direction === 'forward' ? isDossForward : isDossBackward)
    // ) {
    //   filteredTariffs.push(tariff);
    // } else if (
    //   tariff.id === 'Senior15' &&
    //   isSeniorAge &&
    //   (direction === 'forward' ? isFpkForward : isFpkBackward)
    // ) {
    //   filteredTariffs.push(tariff);
    // }
    // Junior
    // if (tariff.id === 'Junior' && isJuniorAge) {
    //   filteredTariffs.push(tariff);
    // }
    // Kupek
    if (tariff.id === 'Kupek') {
      filteredTariffs.push(tariff);
    }
    // Pupil
    if (tariff.id === 'Pupil' && isChild17Age) {
      filteredTariffs.push(tariff);
    }
    // Birthday
    if (
      tariff.id === 'Birthday30' &&
      currentPassengerHasRFCitizenship &&
      isBirthday &&
      tariff.carrier === DOSS_CARRIER &&
      (carrierBackward ? isDossBoth : isDossForward)
    ) {
      filteredTariffs.push(tariff);
    } else if (
      tariff.id === 'Birthday10' &&
      currentPassengerHasRFCitizenship &&
      isBirthday &&
      tariff.carrier === FPK_CARRIER &&
      (carrierBackward ? isFpkBoth : isFpkForward)
    ) {
      filteredTariffs.push(tariff);
    } else if (
      tariff.id === 'Birthday15' &&
      currentPassengerHasRFCitizenship &&
      isBirthday &&
      tariff.carrier === TVERSK_CARRIER &&
      (carrierBackward ? isTverskBoth : isTverskForward)
    ) {
      filteredTariffs.push(tariff);
    }
    // BirthdayAccompanying Forward
    if (
      direction === 'forward' &&
      currentPassengerHasRFCitizenship &&
      tariff.apiValue === 'BirthdayAccompanying' &&
      canShowBirthdayAccompanyingForward
    ) {
      if (
        tariff.id === 'BirthdayAccompanying30' &&
        currentPassengerHasRFCitizenship &&
        tariff.carrier === DOSS_CARRIER &&
        (carrierBackward ? isDossBoth : isDossForward)
      ) {
        filteredTariffs.push(tariff);
      } else if (
        tariff.id === 'BirthdayAccompanying10' &&
        currentPassengerHasRFCitizenship &&
        tariff.carrier === FPK_CARRIER &&
        (carrierBackward ? isFpkBoth : isFpkForward)
      ) {
        filteredTariffs.push(tariff);
      } else if (
        tariff.id === 'BirthdayAccompanying15' &&
        currentPassengerHasRFCitizenship &&
        tariff.carrier === TVERSK_CARRIER &&
        (carrierBackward ? isTverskBoth : isTverskForward)
      ) {
        filteredTariffs.push(tariff);
      }
    }
    // BirthdayAccompanying Backward
    if (
      direction === 'backward' &&
      currentPassengerHasRFCitizenship &&
      tariff.apiValue === 'BirthdayAccompanying' &&
      canShowBirthdayAccompanyingBackward
    ) {
      if (
        tariff.id === 'BirthdayAccompanying30' &&
        currentPassengerHasRFCitizenship &&
        tariff.carrier === DOSS_CARRIER &&
        isDossBoth
      ) {
        filteredTariffs.push(tariff);
      } else if (
        tariff.id === 'BirthdayAccompanying10' &&
        currentPassengerHasRFCitizenship &&
        tariff.carrier === FPK_CARRIER &&
        isFpkBoth
      ) {
        filteredTariffs.push(tariff);
      } else if (
        tariff.id === 'BirthdayAccompanying15' &&
        tariff.carrier === TVERSK_CARRIER &&
        isTverskBoth
      ) {
        filteredTariffs.push(tariff);
      }
    }
    // СИНГЛ можно применять только если пассажир один ИЛИ два пассажира и один из них младенец
    if (
      allPassengers.length === 1 ||
      (allPassengers.length === 2 && passengersHasInfant)
    ) {
      if (
        tariff.id === 'Single25' &&
        (direction === 'forward' ? isDossForward : isDossBackward)
      ) {
        filteredTariffs.push(tariff);
      } else if (
        tariff.id === 'Single20' &&
        (direction === 'forward' ? isFpkForward : isFpkBackward)
      ) {
        filteredTariffs.push(tariff);
      } else if (
        tariff.id === 'Single25Tversk' &&
        (direction === 'forward' ? isTverskForward : isTverskBackward)
      ) {
        filteredTariffs.push(tariff);
      }
    }
  });

  return filteredTariffs;
};
