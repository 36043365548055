import React from 'react';
import { ContextProps } from './types';

const Steps = React.createContext<ContextProps>(null as any);

export const useNotifications = () => React.useContext(Steps);

export const NotificationsProvider: React.FC<ContextProps & React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <Steps.Provider value={props}>{children}</Steps.Provider>;
};
