import React from 'react';
import styled from 'styled-components';
import Ticket from './Ticket';
import { useSelector, useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { getNext, selectTicketListData } from '@modules/main';
import { TicketProvider } from '../useTicketProps';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@components/ui';

const ListContainer = styled.ul`
  & > li {
    margin: 0 auto;
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
  & > :last-child {
    margin-bottom: 0;
  }
  padding: 0;
  margin: 0;
  position: relative;
  & > div {
    border-radius: 10px;
    & > div {
      border-radius: 10px;
    }
  }
  width: 100%;
  @media screen and (min-width: 1188px) {
    width: 100%;
    max-width: 858px;
  }
`;

const IntersectionDiv = styled.div`
  width: 100%;
  heigth: 5px;
`;

const ButtonLoadMore = styled(Button)`
  margin: 20px 0;
  display: block;
  width: 100%;
`;

const List: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { visibleItems, page, pageCount } = useSelector(selectTicketListData);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView) {
      dispatch(getNext());
    }
  }, [inView]);

  return (
    <ListContainer>
      {visibleItems.map((item) => (
        <TicketProvider key={item.id} {...item}>
          <Ticket />
        </TicketProvider>
      ))}
      {!isMobile && <IntersectionDiv ref={ref} />}
      {isMobile && page !== pageCount && (
        <ButtonLoadMore
          onClick={() => {
            dispatch(getNext());
          }}
        >
          Показать еще 10 билетов
        </ButtonLoadMore>
      )}
    </ListContainer>
  );
};

export default List;
