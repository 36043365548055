import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import DiscountIcon from '@assets/media/train-tickets/discount-yellow-icon.svg';
import BG_Circle_Lines from '@assets/media/train-tickets/bg-circle-lines.svg';
import BG_Circle from '@assets/media/train-tickets/bg-circle.svg';

const Container = styled.div<{ border: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ border }) => (border ? 'center' : 'flex-start')};
  gap: 14px;
  padding: 14px 24px;
  background: url(${BG_Circle_Lines}) right top no-repeat,
  url(${BG_Circle}) right top no-repeat,
  #edf1fe;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 14px;
  }
`;

const DiscountIconContainer = styled.div`
  padding: 5px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-family: 'Open Sans', sans-serif;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const DiscountIconItem = styled.img.attrs({ src: DiscountIcon })`
  width: 30px;
  height: 30px;
`;

export const RoundTripPanel = () => {
  return (
    <Container border={false}>
      <Content>
        <DiscountIconContainer>
          <DiscountIconItem />
        </DiscountIconContainer>
        <TextContainer>
          <Text fontWeight={'600'} size={'big'}>
            Скидка на обратный билет
          </Text>
          <Text>
            Выберите поезда в обе стороны с пометкой{' '}
            <b>«-20% на обратный билет»</b> и получите скидку.
          </Text>
        </TextContainer>
      </Content>
    </Container>
  );
};
