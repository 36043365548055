import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { AUTH_MODAL_STATUSES, openAuthModal } from '@modules/ui';
import Popup from 'reactjs-popup';
import SignIn from './SignIn';
import ForgetPassword from './ForgetPassword';
import SignUp from './SingUp';
import CrossIconSrc from '../../assets/images/cross';

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 8px;
    width: auto;
    padding: 0;
    position: relative;
  }
  @media (max-width: 767px) {
    &-overlay {
      // top: 39px !important;
    }
    &-content {
      height: 100vh;
      width: 100%;
      border: none;
      border-radius: 0;
      overflow-x: scroll;
    }
  }
`;

const MainModal: React.FC = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(
    (state: ApplicationState) => state.ui.authStatus
  );

  React.useEffect(() => {
    if (authStatus !== '') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [authStatus]);

  return (
    <StyledPopup
      onClose={() => dispatch(openAuthModal(AUTH_MODAL_STATUSES.EMPTY))}
      open={authStatus !== AUTH_MODAL_STATUSES.EMPTY}
    >
      {authStatus === AUTH_MODAL_STATUSES.AUTH ? (
        <SignIn />
      ) : authStatus === AUTH_MODAL_STATUSES.RESET_PASSWORD ? (
        <ForgetPassword />
      ) : authStatus === AUTH_MODAL_STATUSES.Registration ? (
        <SignUp />
      ) : (
        <></>
      )}
      {authStatus !== '' && (
        <CrossIconSrc
          onClick={() => dispatch(openAuthModal(AUTH_MODAL_STATUSES.EMPTY))}
          style={{
            position: 'absolute',
            top: 15,
            right: 15,
            cursor: 'pointer',
          }}
        />
      )}
    </StyledPopup>
  );
};

export default MainModal;
