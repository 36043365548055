/** Https error descriptions */
export enum ERRORS_DESCRIPTIONS {
  AUTH = 'Необходима авторизация',
  CONNECTION = 'Отсутствует соединение с сервером. Попробуйте позже',
  BAD_REQUEST = 'Произошла техническая ошибка, пожалуйста, попробуйте позже.',
  NO_CONTENT = 'Информация не найдена',
}

/** Http statuses */
export enum STATUS {
  AUTH = 401,
  CONNECTION = 0,
  BAD_REQUEST = 400,
  NO_CONTENT = 204,
}

/** Strategy types for custom fetch */
export enum STRATEGY_TYPES {
  AUTH = 'AUTH',
}

/** Http content types */
export enum CONTENT_TYPE {
  JSON = 'application/json',
  TEXT = 'text/html; charset=utf-8',
}

export const CONNECTION_ERROR_FETCH = 'Failed to fetch';
