import React from 'react';

import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { RoundIcon } from '@components/loader';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';
const BookingInfo = React.lazy(() => import('../screens/Info/BookingInfo'));
const BookingInfoAgent = React.lazy(
  () => import('../screens/AgentInfo/BookingInfoAgent')
);

const PaymentInfo = React.lazy(() => import('../screens/Info/PaymentInfo'));
const PaymentInfoAgent = React.lazy(
  () => import('../screens/AgentInfo/PaymentInfoAgent')
);

const RulesInfo = React.lazy(() => import('../screens/Info/RulesInfo'));
const OrderHelpAgent = React.lazy(
  () => import('../screens/AgentInfo/OrderHelpAgent')
);

const MoneyManagmentAgent = React.lazy(
  () => import('../screens/AgentInfo/MoneyManagmentAgent')
);

const RefundInfo = React.lazy(() => import('../screens/Info/RefundInfo'));
const RefundAndExchangeAgent = React.lazy(
  () => import('../screens/AgentInfo/RefundAndExchangeAgent')
);

const ContactsInfo = React.lazy(() => import('../screens/Info/ContactsInfo'));
const TermsOfUse = React.lazy(() => import('../screens/Info/TermsOfUse'));
const AgentsCooperation = React.lazy(
  () => import('../screens/Info/AgentsCooperation')
);
const Transfers = React.lazy(() => import('../screens/Info/Transfers'));

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 9999;
`;

const TuiInfo: React.FC<RouteComponentProps> = (props) => {
  const { path } = useRouteMatch();
  return (
    <>
      <React.Suspense
        fallback={
          <LoaderWrapper>
            <RoundIcon />
          </LoaderWrapper>
        }
      >
        <Switch>
          <Route exact path={`${path}/payment`} component={PaymentInfo} />
          <Route
            exact
            path={`${path}/paymentAgent`}
            component={PaymentInfoAgent}
          />

          <Route exact path={`${path}/booking`} component={BookingInfo} />
          <Route
            exact
            path={`${path}/bookingAgent`}
            component={BookingInfoAgent}
          />
          <Route
            exact
            path={`${path}/moneyManagmentAgent`}
            component={MoneyManagmentAgent}
          />
          <Route exact path={`${path}/exchange`} component={RefundInfo} />
          <Route
            exact
            path={`${path}/refundAndExchangeAgent`}
            component={RefundAndExchangeAgent}
          />

          <Route exact path={`${path}/rules`} component={RulesInfo} />
          <Route exact path={`${path}/orderHelp`} component={OrderHelpAgent} />

          <Route exact path={`${path}/contacts`} component={ContactsInfo} />
          <Route exact path={`${path}/termsOfUse`} component={TermsOfUse} />
          <Route
            exact
            path={`${path}/agentsCooperation`}
            component={AgentsCooperation}
          />
          <Route exact path={`${path}/transfers`} component={Transfers} />
        </Switch>
      </React.Suspense>
    </>
  );
};

export default TuiInfo;
