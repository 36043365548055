import { getPluginToken } from './../../../main/selectors';
import { put, call, select } from 'typed-redux-saga';
import {
  getAddictionalServicesRequest,
  getAddictionalServicesFailure,
  getAddictionalServicesSuccess,
} from '../../duck';

import * as Manager from '../../Manager';

export function* getAddictionalServicesRequestWorker(
  responseId: string,
  isCharter?: boolean
) {
  const pluginToken = yield* select(getPluginToken);

  try {
    yield* put(getAddictionalServicesRequest());

    const data = yield* call(
      Manager.getAddictionalServices,
      responseId,
      pluginToken,
      isCharter
    );

    yield* put(
      getAddictionalServicesSuccess(
        (data.additionalServices || []).map((x) => ({ ...x, checked: false }))
      )
    );
  } catch (e) {
    yield* put(getAddictionalServicesFailure());
  }
}
