import * as yup from 'yup';
import moment from 'moment/moment';
import { CarPlace, Train } from '@modules/trainTickets';
import { getCarPlaceGenderTypeByPlaceNumber } from '@components/train/TrainSearch/utils';
import {
  RAILWAY_CYRILLIC_PASSENGER_NAME_REGEX,
  RAILWAY_LATIN_AND_CYRILLIC_PASSENGER_NAME_REGEX,
  RAILWAY_LATIN_PASSENGER_NAME_REGEX,
} from '@modules/trainBooking';
import {
  DOCUMENTS_NUMBER_REGEXPS,
  CLIENT_DOCUMENTS_TYPES,
} from '@app/utils/constants/doctypes';

export const railwayBookingValidationSchema = (
  trainForward: Train,
  trainBackward: Train,
) => {
  const MIN_DATE_OF_BIRTH = new Date('1900-01-01, 00:00:00');
  return yup.object().shape({
    passengers: yup.array().of(
      yup.object().shape({
        email: yup.string().when('$needShareContacts', {
          is: (value: boolean) => value,
          then: (schema) =>
            schema.email('Неправильный формат').required('Заполните'),
          otherwise: (schema) => schema.nullable(),
        }),
        phoneNumber: yup.string().when('$needShareContacts', {
          is: (value: boolean) => value,
          then: (schema) =>
            schema
              .test(
                'Формат номера контактного телефона',
                'Неправильный формат номера',
                (val) => {
                  return val?.replace(/[()\-+_ ]/g, '').length === 11;
                },
              )
              .required('Заполните'),
          otherwise: (schema) => schema.nullable(),
        }),
        rzhdBonusCard: yup
          .object()
          .shape({
            cardNumber: yup
              .string()
              .matches(/\d{13}/, 'Невалидный номер карты')
              .nullable(),
            cardType: yup.string().nullable(),
          })
          .nullable(),

        otherRailwayBonusCard: yup
          .object()
          .shape({
            cardNumber: yup
              .string()
              .matches(/\d{13}/, 'Невалидный номер карты')
              .nullable(),
            cardType: yup.string().nullable(),
          })
          .nullable(),

        name: yup
          .string()
          .max(20, 'Максимум 20 символов')
          .when('firstNameRequired', {
            is: (value: boolean) => value,
            then: (schema) =>
              schema // Если разрешена только латиница
                .when('documentType', {
                  is: (value: string) => {
                    const document = CLIENT_DOCUMENTS_TYPES.find(
                      (document) => document.id === value,
                    );
                    return document?.charactersInFIO == 'en';
                  },
                  then: (schema) =>
                    schema.matches(
                      RAILWAY_LATIN_PASSENGER_NAME_REGEX,
                      'Имя должно быть на латинице',
                    ),
                  otherwise: (schema) => schema,
                })
                // Если разрешена только кириллица
                .when('documentType', {
                  is: (value: string) => {
                    const document = CLIENT_DOCUMENTS_TYPES.find(
                      (document) => document.id === value,
                    );
                    return document?.charactersInFIO == 'ru';
                  },
                  then: (schema) =>
                    schema.matches(
                      RAILWAY_CYRILLIC_PASSENGER_NAME_REGEX,
                      'Имя должно быть на кириллице',
                    ),
                  otherwise: (schema) => schema,
                })
                // Особые условия для Иностранного документа:
                // Если гражданство Беларусь, то разрешена латиница и кириллица
                // Если гражданство не Беларусь, то разрешена только латиница
                .when('documentType', {
                  is: (value: string) => value === 'ForeignPassport',
                  then: (schema) =>
                    schema.when('citizenship', {
                      is: (value: string) => value === 'BY',
                      then: (schema) =>
                        schema.matches(
                          RAILWAY_LATIN_AND_CYRILLIC_PASSENGER_NAME_REGEX,
                          'Имя должно быть как в документе',
                        ),
                      otherwise: (schema) =>
                        schema.matches(
                          RAILWAY_LATIN_PASSENGER_NAME_REGEX,
                          'Имя должно быть на латинице',
                        ),
                    }),
                }),
          })

          .required('Заполните'),

        surname: yup
          .string()
          .max(40, 'Максимум 40 символов')
          .when('lastNameRequired', {
            is: (value: boolean) => value,
            then: (schema) =>
              schema
                .when('documentType', {
                  // Если разрешена только латиница
                  is: (value: string) => {
                    const document = CLIENT_DOCUMENTS_TYPES.find(
                      (document) => document.id === value,
                    );
                    return document?.charactersInFIO == 'en';
                  },
                  then: (schema) =>
                    schema.matches(
                      RAILWAY_LATIN_PASSENGER_NAME_REGEX,
                      'Фамилия должна быть на латинице',
                    ),
                })
                .when('documentType', {
                  // Если разрешена только кириллица
                  is: (value: string) => {
                    const document = CLIENT_DOCUMENTS_TYPES.find(
                      (document) => document.id === value,
                    );
                    return document?.charactersInFIO == 'ru';
                  },
                  then: (schema) =>
                    schema.matches(
                      RAILWAY_CYRILLIC_PASSENGER_NAME_REGEX,
                      'Фамилия должна быть на кириллице',
                    ),
                })
                // Особые условия для Иностранного документа:
                // Если гражданство Беларусь, то разрешена латиница и кириллица
                // Если гражданство не Беларусь, то разрешена только латиница
                .when('documentType', {
                  is: (value: string) => value === 'ForeignPassport',
                  then: (schema) =>
                    schema.when('citizenship', {
                      is: (value: string) => value === 'BY',
                      then: (schema) =>
                        schema.matches(
                          RAILWAY_LATIN_AND_CYRILLIC_PASSENGER_NAME_REGEX,
                          'Фамилия должна быть как в документе',
                        ),
                      otherwise: (schema) =>
                        schema.matches(
                          RAILWAY_LATIN_PASSENGER_NAME_REGEX,
                          'Фамилия должна быть на латинице',
                        ),
                    }),
                })
                .required('Заполните'),
            otherwise: (schema) => schema,
          })
          .test(
            'at-least-one',
            'Хотя бы одно поле в ФИО должно быть заполнено',
            function (value) {
              const {
                name,
                surname,
                secondName,
                firstNameRequired,
                lastNameRequired,
                secondNameRequired,
              } = this.parent;
              if (
                !firstNameRequired &&
                !lastNameRequired &&
                !secondNameRequired
              ) {
                return false;
              }
              return true;
            },
          ),

        secondName: yup
          .string()
          .max(20, 'Максимум 20 символов')
          .when('secondNameRequired', {
            is: (value: boolean) => value,
            then: (schema) =>
              schema
                .when('documentType', {
                  // Если разрешена только латиница
                  is: (value: string) => {
                    const document = CLIENT_DOCUMENTS_TYPES.find(
                      (document) => document.id === value,
                    );
                    return document?.charactersInFIO == 'en';
                  },
                  then: (schema) =>
                    schema.matches(
                      RAILWAY_LATIN_PASSENGER_NAME_REGEX,
                      'Отчество должно быть на латинице',
                    ),
                })
                .when('documentType', {
                  // Если разрешена только кириллица
                  is: (value: string) => {
                    const document = CLIENT_DOCUMENTS_TYPES.find(
                      (document) => document.id === value,
                    );
                    return document?.charactersInFIO == 'ru';
                  },
                  then: (schema) =>
                    schema.matches(
                      RAILWAY_CYRILLIC_PASSENGER_NAME_REGEX,
                      'Отчество должно быть на кириллице',
                    ),
                })
                // Особые условия для Иностранного документа:
                // Если гражданство Беларусь, то разрешена латиница и кириллица
                // Если гражданство не Беларусь, то разрешена только латиница
                .when('documentType', {
                  is: (value: string) => value === 'ForeignPassport',
                  then: (schema) =>
                    schema.when('citizenship', {
                      is: (value: string) => value === 'BY',
                      then: (schema) =>
                        schema.matches(
                          RAILWAY_LATIN_AND_CYRILLIC_PASSENGER_NAME_REGEX,
                          'Отчество должно быть как в документе',
                        ),
                      otherwise: (schema) =>
                        schema.matches(
                          RAILWAY_LATIN_PASSENGER_NAME_REGEX,
                          'Отчество должно быть на латинице',
                        ),
                    }),
                })
                .required('Заполните'),
            otherwise: (schema) => schema,
          })
          .nullable(),

        sex: yup
          .string<'f' | 'm'>()
          .when(['carPlaces', 'birthDate', 'ageCategory'], {
            is: (
              carPlaces: CarPlace[],
              birthDate: string,
              ageCategory: string,
            ) => {
              return (
                ageCategory === 'ADULT' &&
                !!carPlaces?.length &&
                moment().diff(moment(birthDate), 'years') > 10 &&
                carPlaces.some(
                  (carPlace: CarPlace) =>
                    getCarPlaceGenderTypeByPlaceNumber(carPlace.number) ===
                    'female',
                )
              );
            },
            then: (schema) =>
              schema.test(
                'Female gender',
                'В женском купе могут ехать только женщины и дети до 10 лет любого пола',
                function (value) {
                  return value === 'f';
                },
              ),
          })
          .when(['carPlaces', 'birthDate', 'ageCategory'], {
            is: (
              carPlaces: CarPlace[],
              birthDate: string,
              ageCategory: string,
            ) => {
              return (
                ageCategory === 'ADULT' &&
                !!carPlaces?.length &&
                moment().diff(moment(birthDate), 'years') > 10 &&
                carPlaces.some(
                  (carPlace: CarPlace) =>
                    getCarPlaceGenderTypeByPlaceNumber(carPlace.number) ===
                    'male',
                )
              );
            },
            then: (schema) =>
              schema.test(
                'Male gender',
                'В мужском купе могут ехать только мужчины и дети до 10 лет любого пола',
                function (value) {
                  return value === 'm';
                },
              ),
          })
          .required('Укажите пол'),

        birthDate: yup
          .date()
          .min(MIN_DATE_OF_BIRTH, 'Дата рождения должна быть не менее 1900 года')

          .when('ageCategory', {
            is: (value: string) => value === 'CHILD',
            then: (schema) =>
              schema
                .test(
                  'Child',
                  'Ребёнку на момент поездки должно быть до 10 лет',
                  function (value) {
                    if (value === null || value === undefined) {
                      return true;
                    }
                    let lastSegmentDate = moment(
                      trainForward.localDepartureDate,
                    );
                    if (trainBackward) {
                      lastSegmentDate = moment(
                        trainBackward.localDepartureDate,
                      );
                    }
                    const years = moment
                      .duration(moment(lastSegmentDate).diff(moment(value)))
                      .years();

                    return years <= 10;
                  },
                )
                .required('Заполните')
                .nullable(),
          })

          .when('ageCategory', {
            is: (value: string) => value === 'INFANT',
            then: (schema) =>
              schema
                .test(
                  'Infant',
                  'Младенцу на момент поездки должно быть до 5 лет',
                  function (value) {
                    if (value === null || value === undefined) {
                      return true;
                    }
                    let lastSegmentDate = moment(
                      trainForward.localDepartureDate,
                    );
                    if (trainBackward) {
                      lastSegmentDate = moment(
                        trainBackward.localDepartureDate,
                      );
                    }
                    const years = moment
                      .duration(moment(lastSegmentDate).diff(moment(value)))
                      .years();
                    return years <= 5;
                  },
                )
                .required('Заполните')
                .nullable(),
          })

          .when('ageCategory', {
            is: (value: string) => value === 'ADULT',
            then: (schema) =>
              schema
                .test(
                  'Adult',
                  'Взрослому на момент поездки должно быть больше 10 лет',
                  function (value) {
                    if (value === null || value === undefined) {
                      return true;
                    }
                    let lastSegmentDate = moment(
                      trainForward.localDepartureDate,
                    );
                    if (trainBackward) {
                      lastSegmentDate = moment(
                        trainBackward.localDepartureDate,
                      );
                    }
                    const years = moment
                      .duration(moment(lastSegmentDate).diff(moment(value)))
                      .years();
                    return years > 10;
                  },
                )
                .required('Заполните')
                .nullable(),
          })
          .required('Заполните')
          .test(
            'not alive',
            'Дата рождения должна быть не менее 1900 года, ',
            (v) => {
              if (v === null || v === undefined) {
                return true;
              }
              return (v as Date)?.getFullYear() > 1800;
            },
          ),

        number: yup
          .string()
          .when('documentType', {
            is: (documentType: string) =>
              documentType !== 'ForeignPassport' &&
              documentType !== 'DiplomaticPassport' &&
              documentType !== 'ServicePassport' &&
              documentType !== 'MedicalBirthCertificate',
            then: (schema) =>
              schema.test(
                'document number format',
                'Неверный формат номера документа',
                (value, ctx) => {
                  const document = CLIENT_DOCUMENTS_TYPES.find(
                    (document) => document.id === ctx.parent.documentType,
                  );
                  return ctx.originalValue.match(document?.regexp);
                },
              ),
          })
          .when('documentType', {
            is: (value: string) => value === 'ForeignPassport',
            then: (schema) =>
              schema
                .test(
                  'foreign document number format',
                  'Неверный формат номера документа',
                  (value, ctx) => {
                    // Если внутри номера документа только буквы, то валидация по DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT_WITH_ONLY_LETTERS
                    // Если буквы и цифры, то валидация по DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT
                    if (value) {
                      const isOnlyLatinLetters =
                        DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT_WITH_ONLY_LETTERS.test(
                          value,
                        );
                      const isLettersAndNumbers =
                        DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT.test(value);
                      if (isOnlyLatinLetters) {
                        return value.length >= 5;
                      } else if (isLettersAndNumbers) {
                        return isLettersAndNumbers;
                      }
                    }
                  },
                )
                .required('Заполните'),
          })
          .when('documentType', {
            is: (value: string) =>
              value === 'DiplomaticPassport' || value === 'ServicePassport',
            then: (schema) =>
              schema
                .test(
                  'diplomatic passport number validate',
                  'Неверный формат номера документа',
                  (value, ctx) => {
                    // Если гражданство РФ, то валидация как у Заграничного паспорта РФ
                    // Если гражданство не РФ, то валидация как у Иностранного документа
                    if (value) {
                      const isRussianCitizenship =
                        ctx.parent.citizenship === 'RU';
                      const isForeignCitizenship =
                        ctx.parent.citizenship !== 'RU';
                      if (isRussianCitizenship) {
                        return DOCUMENTS_NUMBER_REGEXPS.RUSSIAN_FOREIGN_PASSPORT.test(
                          value,
                        );
                      } else if (isForeignCitizenship) {
                        const isOnlyLatinLetters =
                          DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT_WITH_ONLY_LETTERS.test(
                            value,
                          );
                        const isLettersAndNumbers =
                          DOCUMENTS_NUMBER_REGEXPS.FOREIGN_PASSPORT.test(value);
                        if (isOnlyLatinLetters) {
                          return value.length >= 5;
                        } else if (isLettersAndNumbers) {
                          return isLettersAndNumbers;
                        }
                      }
                    }
                  },
                )
                .required('Заполните'),
          })
          .when('documentType', {
            is: (value: string) => value === 'MedicalBirthCertificate',
            then: (schema) =>
              schema
                .test(
                  'MedicalBirthCertificate passport number validate',
                  'Неверный формат номера документа или возраст ребенка больше 31 дня от даты рождения',
                  (number, ctx) => {
                    // Документ разрешен для ребенка, возраст которого должен быть на дату отправления
                    // поезда не более 31 дня от даты рож11дения.
                    if (number) {
                      const birthDate = ctx.parent.birthDate;
                      const departureDate = trainForward.localDepartureDate;
                      const days = moment(departureDate).diff(
                        moment(birthDate),
                        'days',
                      );
                      if (days >= 0 && days <= 31) {
                        return DOCUMENTS_NUMBER_REGEXPS.MEDICAL_BIRTH_CERTIFICATE.test(
                          number,
                        );
                      } else {
                        return false;
                      }
                    }
                  },
                )
                .required('Заполните'),
          })
          .required('Заполните'),

        dateOfIssue: yup.date().when('documentType', {
          is: (value: string) => !!value,
          then: (schema) =>
            schema
              .when('dateOfIssue', {
                is: (value: string) => !!value,
                then: (schema) =>
                  schema.test(
                    'dateOfIssueError',
                    'Некорректный срок действия',
                    function (value: any) {
                      if (value === null || value === undefined) {
                        return true;
                      }
                      const years = moment
                        .duration(moment(value).diff(moment(new Date())))
                        .years();
                      return years < 50;
                    },
                  ),
              })
              .when('documentType', {
                is: (value: string) => value === 'RussianForeignPassport',
                then: (schema) =>
                  schema
                    .required('Заполните')
                    .test(
                      'isAfterToday',
                      'Срок действия документа должен быть в будущем',
                      function (value: any) {
                        if (value === null || value === undefined) {
                          return true;
                        }
                        return moment(value).isAfter(moment(), 'day');
                      },
                    ),
              })
              .nullable(),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
        // Если Иностранный документ и возраст больше 14 лет и 2 месяцев (170 месяцев или 5110 дней) от даты отправления, то гражданство любое, но не РФ
        citizenship: yup
          .string()
          .when(['birthDate', 'documentType', 'ageCategory'], {
            is: (
              birthDate: string,
              documentType: string,
              ageCategory: string,
            ) => {
              const departureDate = trainForward.localDepartureDate;
              const days = moment(departureDate).diff(
                moment(birthDate),
                'days',
              );
              return (
                ageCategory === 'ADULT' &&
                documentType === 'ForeignPassport' &&
                days > 5110
              );
            },
            then: (schema) =>
              schema.notOneOf(['RU'], 'Выберите гражданство не РФ'),
            otherwise: (schema) => schema,
          }),
      }),
    ),

    phone: yup
      .string()
      .min(11, 'Некорректный номер телефона')
      .required('Некорректный номер телефона'),

    offerta: yup.boolean().when('isOffertaChecked', {
      is: true,
      then: (schema) => schema.oneOf([true], 'Field must be checked'),
      otherwise: (schema) => schema,
    }),

    isInsurancesChecked: yup.boolean(),
    isOffertaChecked: yup.boolean(),
    offertaWithInsurances: yup.boolean().when('isInsurancesChecked', {
      is: true,
      then: (schema) => schema.oneOf([true], 'Field must be checked'),
      otherwise: (schema) => schema,
    }),
    email: yup.string().email('Неправильный формат').required('Заполните'),
    name: yup.string().required('Заполните'),
    needShareContacts: yup.boolean(),
  });
};
