import { createAction, createReducer } from 'redux-act';
import {
  ResponseAgentTax,
  UIFeesResponse,
  UpdateTaxesDto,
} from '@modules/agents/agent-fees/types';
import { FormIncomeValue } from '@components/agents/agent-rules/types';

export const getAgentFeesInfoRequest = createAction(
  'agents/getAgentFeesInfoRequest'
);
export const getAgentFeesInfoSuccess = createAction<UIFeesResponse[] | null>(
  'agents/getAgentFeesInfoSuccess'
);
export const getAgentTaxesSuccess = createAction<ResponseAgentTax[]>(
  'agents/getAgentTaxesSuccess'
);
export const getAgentFeesInfoFailure = createAction(
  'agents/getAgentFeesInfoFailure'
);

export const updateTaxesRequest = createAction<UpdateTaxesDto>(
  'agents/updateTaxesRequest'
);
export const updateTaxesSuccess = createAction('agents/updateTaxesSuccess');
export const updateTaxesFailure = createAction('agents/updateTaxesFailure');

export const createFeeRequest = createAction<FormIncomeValue>(
  'agents/createFeeRequest'
);
export const createFeeSuccess = createAction<boolean>(
  'agents/createFeeSuccess'
);
export const createFeeFailure = createAction('agents/createFeeFailure');

export const editFeesRequest = createAction<FormIncomeValue>(
  'agents/editFeesRequest'
);
export const editFeesSuccess = createAction<boolean>('agents/createFeeSuccess');

export const deleteFeeRequest = createAction<string>('agents/deleteFeeRequest');

const taxes = createReducer<null | ResponseAgentTax[]>({}, null);
taxes.on(getAgentTaxesSuccess, (_, payload) => payload);

const fees = createReducer<null | UIFeesResponse[]>({}, null);
fees.on(getAgentFeesInfoSuccess, (_, payload) => payload);

const taxesLoading = createReducer<boolean>({}, false);
taxesLoading.on(updateTaxesRequest, () => true);
taxesLoading.on(updateTaxesSuccess, () => false);
taxesLoading.on(updateTaxesFailure, () => false);

const feesLoading = createReducer<boolean>({}, false);
feesLoading.on(editFeesRequest, () => true);
feesLoading.on(createFeeRequest, () => true);
feesLoading.on(createFeeSuccess, () => false);
feesLoading.on(editFeesSuccess, () => false);

const feesAgentsReducer = {
  taxes,
  fees,
  taxesLoading,
  feesLoading,
};

export default feesAgentsReducer;
