import { all, call, put, takeLatest } from 'typed-redux-saga';
import { Action } from 'redux-act';
import {
  BusBookingOrderCreation,
  OrderDetailsPreparedData,
} from '@modules/busBooking/dto/BookBusTicketDto';
import {
  bookBusRequest,
  busGetOrderDetails,
  busSetOrderDetails,
  setBusBookingOrderData,
} from '@modules/busBooking/duck';
import * as Manager from './manager';
import {
  BusSearchRequestStatus,
  setBusSearchRequestState,
  setBusStep,
} from '@modules/busTickets';
import * as OrderManager from '@modules/orders/Manager';
import { setDetailData, setIsBusOrder } from '@modules/orders';

export function* busBookRequestFlow({
  payload,
}: Action<BusBookingOrderCreation>) {
  try {
    yield* put(
      setBusSearchRequestState({
        status: BusSearchRequestStatus.tryToCreateOrder,
      }),
    );
    const result = yield* call(Manager.bookBusTicket, payload);
    yield* put(setBusBookingOrderData({ orderData: result }));
    const orderData = yield* call(
      OrderManager.getOrderByNumber,
      result.tariff.orderId as number,
    );
    yield* put(setDetailData(orderData));
    yield* put(
      setBusSearchRequestState({ status: BusSearchRequestStatus.success }),
    );
    yield* put(setIsBusOrder(true));
    yield* put(setBusStep({ step: 'BOOKING_SECOND_STEP', needClear: false }));
  } catch (e) {
    yield* put(
      setBusSearchRequestState({ status: BusSearchRequestStatus.failure }),
    );
    console.log(e);
  }
}

// TODO: разобраться с типами
export function* handleGetBookingDetails({
  payload,
}: Action<OrderDetailsPreparedData>) {
  try {
    const forward = yield* call(Manager.getBusOrderDetails, payload.forward);
    const backward = payload.backward
      ? yield* call(Manager.getBusOrderDetails, payload.backward)
      : null;
    //@ts-ignore
    yield* put(busSetOrderDetails({ forward, backward }));
  } catch (e) {
    console.log(e);
  }
}

export default function* busBookingFlow() {
  yield* all([
    takeLatest(bookBusRequest.getType(), busBookRequestFlow),
    takeLatest(busGetOrderDetails.getType(), handleGetBookingDetails),
  ]);
}
