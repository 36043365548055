import React from 'react';

export enum STEPS {
  CALCULATE,
  CONFIRM,
  SUCCESS,
  FAILURE,
  LOADING,
  HIDDEN,
  CONFIRM_APPROVE,
  CONFIRM_CANCEL,
}

type StepData = {
  step: STEPS;
  meta: string | null;
};

type StepContext = [StepData, (step: STEPS, meta?: string) => void];

const Steps = React.createContext<StepContext>(null as any);

export const useSteps = () => React.useContext(Steps);

export const StepsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, setState] = React.useState({
    step: STEPS.HIDDEN,
    meta: null,
  } as StepData);

  const setStep = React.useCallback(
    (step: STEPS, meta?: string) => setState({ step, meta: meta || null }),
    []
  );

  return <Steps.Provider value={[state, setStep]}>{children}</Steps.Provider>;
};
