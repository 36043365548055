import { all, call } from 'typed-redux-saga';
import mainSaga from './main/sagas';
import bookingSaga from './booking/sagas';
import ordersSaga from './orders/sagas';
import appealsSaga from './appeals/sagas';
import userSaga from './user/sagas';
import notificationSaga from './notification/sagas';
import personalAreaSaga from './personalArea/sagas';
import simpleSearchSaga from './simpleSearch/sagas';
import referencesSaga from './references/sagas';
import { agentsSaga } from './agents/sagas';
import trainTickets from './trainTickets/sagas';
import trainBookingFlow from './trainBooking/sagas';
import trainOrderFlow from '@modules/trainOrders/sagas';
import uiSagas from './ui/sagas';
import busTickets from './busTickets/sagas';
import busBooking from './busBooking/sagas';
import logSaga from './logs/sagas';

export function* rootSaga() {
  yield* all([
    call(mainSaga),
    call(bookingSaga),
    call(ordersSaga),
    call(appealsSaga),
    call(userSaga),
    call(notificationSaga),
    call(personalAreaSaga),
    call(simpleSearchSaga),
    call(referencesSaga),
    call(agentsSaga),
    call(trainTickets),
    call(trainBookingFlow),
    call(trainOrderFlow),
    call(uiSagas),
    call(busTickets),
    call(busBooking),
    call(logSaga),
  ]);
}
