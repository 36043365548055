import React from 'react';
import Notification from './Notification';
import { SEARCH_PANEL_TYPES } from '@modules/ui/types';

interface Props {
  type: SEARCH_PANEL_TYPES;
  step?: 'BOOKING' | 'TICKET_FORWARD' | 'TICKET_BACKWARD';
  message?: string;
}

const RequestError = (props: Props) => {
  const isBooking = props.step === 'BOOKING';
  const message = props.message;

  return (
    <Notification
      subTitle={
        message
          ? message
          : isBooking
            ? 'Что-то пошло не так'
            : 'Это могло произойти из-за следующих причин:'
      }
      title={
        isBooking ? 'Бронирование завершилось с ошибкой' : 'Билеты не найдены'
      }
    >
      <Notification.Ul>
        {!isBooking && (
          <li>
            На эти даты все билеты куплены. Попробуйте поискать билеты на
            соседние даты
          </li>
        )}
        {props.type === 'avia' && (
          <>
            <li>Неправильно выбраны даты. Проверьте год вылета и прилета</li>
            <li>В этот аэропорт не летают самолёты</li>
          </>
        )}
        {props.type === 'train' && !isBooking && (
          <>
            <li>
              Неправильно выбраны даты. Проверьте год отправления и прибытия.
            </li>
            <li>По данному направлению не ходят поезда.</li>
          </>
        )}
      </Notification.Ul>
    </Notification>
  );
};

export default RequestError;
