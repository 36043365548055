import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { ImageModalProps } from './types';

const Image = styled.img`
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: auto;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 50px 40px;
    border-radius: 10px;
    box-sizing: border-box;
    width: auto;
    & > * {
      display: block;
    }

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 21px;
  top: 17px;

  outline: none;
  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const Modal: React.FC<ImageModalProps> = ({
  src,
  width,
  height,
  alt,
  scaleRate,
  ...props
}) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Cross
        href="/sde"
        onClick={(e) => {
          e.preventDefault();

          typeof props.onClose === 'function' && props.onClose();
        }}
      />
      <Image alt={alt} src={src} width={width * 2} height={height * 2} />
    </StyledPopup>
  );
};

export default Modal;
