import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { CanelAppealModalProps } from './types';
import { Text, Button } from '@components/ui';

const StyledPopup = styled(Popup)`
  &-content {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 25px;
`;

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
`;

const ActionsBlock = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }
`;

const Modal: React.FC<CanelAppealModalProps> = ({ onConfirm, ...props }) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Title>Отмена обращения</Title>
      <Question>
        Вы уверены, что хотите отменить создание обращения по данному заказу?
      </Question>
      <ActionsBlock>
        <Button onClick={onConfirm} type="outlined">
          Да, отменить
        </Button>
        <Button onClick={props.onClose} type="outlined">
          Нет, вернуться к обращению
        </Button>
      </ActionsBlock>
    </StyledPopup>
  );
};

export default Modal;
