import React, { useCallback, useState } from 'react';
import { StyledInput, Wrapper, Label, InputWrapper } from './styles';
import { FieldProps } from './types';
import styled from 'styled-components';
import infoIcon from '@assets/media/info-icon.svg';

const ErrorText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #e73c3e;
  position: absolute;
  white-space: nowrap;
`;

const InfoIconContainer = styled.div`
  position: relative;
  margin-left: 5px;
  margin-top: 2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  position: absolute;
  min-width: 180px;
  background: #edf1fe;
  border-radius: 4px;
  padding: 15px;
  bottom: 25px;
  left: -85px;
`;

const InfoText = styled.div`
  font-size: 14px;
  line-height: 14px;

  color: #3c3c3c;
`;

const InfoIcon = styled.img.attrs({ src: infoIcon })`
  cursor: pointer;
`;

const Input: React.FC<FieldProps> = ({
  label,
  error,
  onFocus,
  onBlur,
  inputRef,
  children,
  placeholder,
  value,
  height,
  wrapperClassName,
  isInfo,

  ...props
}) => {
  const [isFocused, setFocus] = useState(false);
  const [showInfo, setShowInfo] = useState('0');
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFocus(true);
      if (typeof onFocus === 'function') {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFocus(false);
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <Wrapper
      className={wrapperClassName}
      focused={isFocused || (error !== undefined && error !== '')}
    >
      {label && (
        <Row>
          <Label>{label} </Label>
          {isInfo && (
            <InfoIconContainer>
              {showInfo !== '0' && (
                <InfoContainer>
                  <InfoText>
                    {showInfo === '1' ? (
                      'Номер заказа в ТТЕ - это пятизначное число.'
                    ) : showInfo === '3' ? (
                      <>
                        Порядок ввода:
                        <br />
                        <br />
                        1. Римская цифра, вводится латинскими буквами I, V, Х (в
                        английской раскладке клавиатуры).
                        <br />
                        <br />
                        2. Две буквы кириллицей.
                        <br />
                        <br />
                        3. Шесть цифр.
                        <br />
                        <br />
                        Пример VII-ЛЛ №905784
                      </>
                    ) : (
                      'Номер для оплаты - это шестизначное число, начинающееся минимум с цифры 6.'
                    )}
                  </InfoText>
                </InfoContainer>
              )}
              <InfoIcon
                onMouseEnter={() =>
                  label.includes('TTE')
                    ? setShowInfo('1')
                    : label.includes('Серия и номер')
                    ? setShowInfo('3')
                    : setShowInfo('2')
                }
                onMouseLeave={() => setShowInfo('0')}
              />
            </InfoIconContainer>
          )}
        </Row>
      )}
      <InputWrapper>
        <StyledInput
          height={height}
          value={value}
          placeholder={placeholder}
          error={error}
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
        />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
      {children}
    </Wrapper>
  );
};

export default Input;
