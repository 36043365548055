import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { TariffsInfoModalProps } from './types';
import CrossIconSrc from '@assets/media/tariff-features/cross.svg';
import CheckIconSrc from '@assets/media/tariff-features/check.svg';
import RubleIconSrc from '@assets/media/tariff-features/ruble.svg';
import { Text } from '@components/ui';
import { Helper } from '@utils';
import PenaltyDescription from './components/PenaltyDescription';
import { FareEntity } from '@modules/booking';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
      overflow: scroll;
    }
  }

  &-content {
    width: 655px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      border-radius: 0;
      padding: 20px;
    }
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const List = styled.div`
  overflow: auto;
  height: 80vh;
  & > div {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }

  & > :last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const Item = styled.div``;

const ItemName = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 15px;
`;

const FeaturesList = styled.div`
  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
const Feature = styled.div`
  display: flex;

  & > img {
    margin-right: 5px;
  }
`;

const FeatureText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 21px;
  top: 17px;
  outline: none;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
  display: block;
  margin-right: 8px;
`;

const TariffsInfoModal: React.FC<TariffsInfoModalProps> = ({
  fares,
  ...props
}) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  const uniqueFareFamilies = getUniqueFareFamilies(fares);

  return (
    <StyledPopup {...props}>
      <Cross onClick={props.onClose} />
      <PopupHead>
        <Title>
          <TitleText>Виды тарифных планов</TitleText>
          <img
            alt=""
            src={Helper.getAirlinesLogoUrl(fares[0].fareFamilies[0].carrier, 160, 60, true)}
            style={{ maxHeight: 45 }}
          />
        </Title>
      </PopupHead>
      <MobileHead>
        <MobileTitle>Тарифные планы</MobileTitle>{' '}
        <img
          alt=""
          src={Helper.getAirlinesLogoUrl(fares[0].fareFamilies[0].carrier, 160, 60, true)}
          style={{ maxHeight: 15 }}
        />
      </MobileHead>
      <List>
        <PenaltyDescription tariffType="regular" />
        {uniqueFareFamilies.map((fareFamily, familyKey) => (
          <Item key={familyKey}>
            <ItemName>{fareFamily.name}</ItemName>
            <FeaturesList>
              {fareFamily.features.map((feature, featureKey) => (
                <Feature key={featureKey}>
                  <img width={18} height={18} src={getIcon(feature.applicability)} />
                  <FeatureText>{`${getTitle(feature.type)}: ${feature.descriptionRus}`}</FeatureText>
                </Feature>
              ))}
            </FeaturesList>
          </Item>
        ))}
      </List>
    </StyledPopup>
  );
};

function getIcon(x: 'Included' | 'NotOffered' | 'AtCharge') {
  switch (x) {
    case 'Included':
      return CheckIconSrc;
    case 'NotOffered':
      return CrossIconSrc;
    case 'AtCharge':
      return RubleIconSrc;
  }
}

function getTitle(type: string) {
  switch (type) {
    case 'Baggage':
      return 'Багаж';
    case 'CarryOn':
      return 'Ручная кладь';
    case 'Exchange':
      return 'Обмен';
    case 'Refund':
      return 'Возврат';
    default:
      return '';
  }
}

function getUniqueFareFamilies(fares: FareEntity[]) {
  const uniqueNames = new Set();
  return fares.flatMap(fare =>
    fare.fareFamilies.filter(fareFamily => {
      if (uniqueNames.has(fareFamily.name)) {
        return false;
      }
      uniqueNames.add(fareFamily.name);
      return true;
    })
  );
}

export default TariffsInfoModal;
