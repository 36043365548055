import { DocumentTypes } from '@modules/user';

export function translateDocumentType(type: string) {
  switch (type) {
    case DocumentTypes.RussianFederationPassport:
      return 'Паспорт РФ';
    case DocumentTypes.BirthCertificate:
      return 'Свидетельство о рождении';
    case DocumentTypes.InternationalPassport:
      return 'Загран паспорт';
    default:
      return type;
  }
}

export const CONVERTED_DOCS: any = {
  RussianFederationPassport: { value: 'PASSPORT', label: 'Паспорт РФ' },
  InternationalPassport: {
    value: 'INTERNATIONAL_PASSPORT1',
    label: 'Заграничный паспорт',
  },
  NationalPassport: {
    value: 'NATIONALPASSPORT',
    label: 'Национальный паспорт',
  },
  BirthCertificate: {
    value: 'BIRTH_CERTIFICATE',
    label: 'Свидетельство о рождении',
  },
  Other: {
    value: 'INTERNATIONAL_PASSPORT',
    label: 'Другой документ',
  },
};
