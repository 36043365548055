import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import stickyInfo from '@assets/media/booking/stickyInfo.svg';
import stickyArrow from '@assets/media/booking/stickyArrow.svg';
import AnimatedNumber from 'animated-number-react';
import { Helper } from '@utils';
import { useSelector } from 'react-redux';
import { getPriceDetail } from '@modules/booking';
import Text from '@components/ui/Typography';
import { CSSTransition } from 'react-transition-group';
import { useModalState } from '@components/booking/AddictionalServices/context';
import { setNestedObjectValues, useFormikContext } from 'formik';
import { getDetailOrder } from '@modules/orders';
import arrowSrc from '@assets/media/order-detail/arrow1.svg';
import CheckPassangersModal from './CheckPassangersModal';
import { ApplicationState } from '@modules/index';
import { getUserState } from '@modules/user';
interface LinkProps {
  isActive: boolean;
  isDisplay?: boolean;
}

const Wrapper = styled.div<LinkProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: ${({ isActive }) =>
    isActive ? 'null' : '0px -4px 12px 0px #757a8830'};
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 100000;
`;

const GrayBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #f6f8fa;
  height: 45px;
  border-radius: 8px;
  margin-right: 15px;
  width: 26px;
`;

const PriceBlock = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #2e2e32;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const YellowButton = styled.div`
  width: 168px;
  height: 46px;
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  background-color: #ffe100;
  color: #2e2e32;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 20px;
`;

const YellowButtonBook = styled.button`
  width: 138px;
  height: 46px;
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  background-color: #ffe100;
  color: #2e2e32;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 20px;
  border: none;
`;

const Details = styled.div`
  position: fixed;
  bottom: 70px;
  background: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  min-height: 50px;
  width: 100%;
  right: 0;
  z-index: 100000;
  border: 1px solid #dbe0e5;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
  box-shadow: 0 -4px 12px 0 #757a8830;
`;

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #2e2e32;
`;

const DetailContent = styled.div`
  margin-top: 24px;

  & > div {
    margin-bottom: 22px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 12px;
    }
  }
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

const PositionNameWrapper = styled.div<{ interactable: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 18px;

  ${({ interactable }) => (interactable ? 'cursor: pointer;' : '')}
`;

const PositionName = styled(Text)<{ isBold?: boolean }>`
  font-style: normal;
  font-weight: ${(props) => (props.isBold ? '600' : '400')};
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  position: relative;
`;

const SecondLevelList = styled(DetailContent)`
  margin-top: 24px;
  padding-left: 11px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

const Icon = styled.img<{ reverse: boolean }>`
  position: absolute;
  right: -13px;
  top: 7px;
  transition: transform 0.2s ease-in-out;

  ${({ reverse }) => (reverse ? 'transform: rotate(180deg);' : '')}
`;

const StickyInfo = styled.img.attrs({ src: stickyInfo })``;

const StickyArrow = styled.img.attrs({ src: stickyArrow })<LinkProps>`
  transform: ${({ isActive }) =>
    !isActive ? 'rotate (0deg)' : 'rotate(180deg)'};
`;

const CheckPassangerData = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #4872f2;
`;

const SubagentText = styled(Text)<{ isSubagent: boolean }>`
  font-weight: ${({ isSubagent }) => (isSubagent ? 600 : 400)};
`;

const WrapperMarginTop = styled(DetailsRow)`
  margin-top: 5px;
`;

const WrapperRow = styled(DetailRow)`
  margin-top: 5px;
`;

const BlueText = styled.div`
  color: #4872f2;
`;

interface Props {
  chosenScreen?: number;
  submit: any;
  chosenPaymentMethod?: string;
  setMobileSubmitCount: Dispatch<SetStateAction<number>>;
}

type PassengerError = {
  birthDate: string;
  name: string;
  nameEng: string;
  surname: string;
  surnameEng: string;
  secondName: string;
  secondNameEng: string;
  number: string;
  documentInfo: string;
  documentSeries: string;
  dateOfIssue: string;
  sex: string;
  documentType: string;
  citizenship: string;
  type: {
    id: string;
    description: string;
  };
};

const StickyFooter: React.FC<Props> = ({
  chosenScreen,
  submit,
  chosenPaymentMethod,
  setMobileSubmitCount,
}) => {
  const stickyHide = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.stickyFooterStatus,
  );
  const userState = useSelector(getUserState);

  const { totalDetalization, acquiringCommissions } =
    useSelector(getDetailOrder);
  const [isCheckPassangersModalOpen, setIsCheckPassangersModalOpen] =
    React.useState(false);
  const { detailList } = useSelector(getPriceDetail);
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
  const { visibility } = useModalState();

  const [state, setState] = React.useState(
    totalDetalization.positionList.map((_, key) => false),
  );

  function typeToNumber(type: string) {
    return type === 'subagent-cost' ? 1 : 0;
  }

  const { validateForm, errors } = useFormikContext<{
    offerta: boolean;
    emailOfferta: boolean;
    isInsurancesChecked: boolean;
    offertaWithInsurances: boolean;
    charterTariffs: boolean;
    isCharter: boolean;
    passengers: Array<PassengerError | undefined>;
  }>();

  const handleClick = (key: number) => {
    if (
      (totalDetalization.positionList[key].detalizationList || []).length > 0
    ) {
      setState((s) => s.map((x, i) => (i === key ? !x : x)));
    }
  };

  const total = detailList.reduce((acc, x) => acc + x.cost, 0);

  const onClickOutsideListener = () => {
    setIsDetailsOpen(false);
    document.removeEventListener('click', onClickOutsideListener);
  };

  return (
    <>
      {isCheckPassangersModalOpen && (
        <CheckPassangersModal
          isCheckPassangersModalOpen={isCheckPassangersModalOpen}
          setIsCheckPassangersModalOpen={setIsCheckPassangersModalOpen}
        />
      )}
      {isDetailsOpen && !visibility && !stickyHide && (
        <Details>
          {chosenScreen && chosenScreen === 1 && (
            <>
              {detailList
                .sort((a, b) => typeToNumber(a.type) - typeToNumber(b.type))
                .map((value, key) => (
                  <CSSTransition
                    key={value.id}
                    timeout={500}
                    classNames="price"
                  >
                    <>
                      {value.id === 'subagent' ? (
                        <></>
                      ) : (
                        value.quantity > 0 && (
                          <>
                            <WrapperMarginTop>
                              <SubagentText
                                isSubagent={value.type === 'subagent-cost'}
                              >
                                {value.description}{' '}
                                {value.quantity > 1 && `X ${value.quantity}`}
                              </SubagentText>
                              <SubagentText
                                data-cy={
                                  value.dataCy
                                    ? `${value.dataCy}RightPanel`
                                    : ''
                                }
                                isSubagent={value.type === 'subagent-cost'}
                              >
                                {Helper.formatPrice(
                                  value.cost * value.quantity,
                                )}
                              </SubagentText>
                            </WrapperMarginTop>
                          </>
                        )
                      )}
                    </>
                  </CSSTransition>
                ))}
            </>
          )}

          {chosenScreen && chosenScreen === 3 && (
            <>
              <DetailContent>
                {totalDetalization.positionList.map((item, key) => (
                  <div key={key}>
                    <PositionNameWrapper
                      interactable={(item.detalizationList || []).length > 0}
                      onClick={() => handleClick(key)}
                    >
                      <PositionName isBold={item.isBold}>
                        {item.positionName}
                        {(item.detalizationList || []).length > 0 && (
                          <Icon
                            reverse={!state[key]}
                            src={arrowSrc}
                            alt="arrow"
                          />
                        )}
                      </PositionName>
                      <PositionName color="#3C3C3C" isBold={item.isBold}>
                        {Helper.formatPrice(item.total)}
                      </PositionName>
                    </PositionNameWrapper>
                    {chosenPaymentMethod &&
                      chosenPaymentMethod !== 'Российская карта' && (
                        <WrapperRow>
                          <StyledText color="dark-gray">
                            Коммиссия за эквайринг
                          </StyledText>
                          <StyledText color="dark-gray">
                            {acquiringCommissions &&
                              Helper.formatPrice(
                                acquiringCommissions[1].acquiringAmount,
                              )}
                          </StyledText>
                        </WrapperRow>
                      )}
                    {state[key] && (item.detalizationList || []).length > 0 && (
                      <SecondLevelList>
                        {item.detalizationList.map((item, key) => (
                          <>
                            <DetailRow key={key}>
                              <StyledText color="dark-gray">
                                {item.type === 'FirstBag'
                                  ? `Доп. багаж до ${item.name} кг`
                                  : item.name}
                              </StyledText>

                              <StyledText color="dark-gray">
                                {item.quantity > 0 && item.type !== 'passenger'
                                  ? `${item.quantity} x ${Helper.formatPrice(
                                      item.price,
                                    )}`
                                  : Helper.formatPrice(item.price)}
                              </StyledText>
                            </DetailRow>

                            {item?.detalization?.map((x: any, index) => (
                              <WrapperRow key={index}>
                                <StyledText color="dark-gray">
                                  {x.description}
                                </StyledText>
                                <StyledText color="dark-gray">
                                  {item.quantity > 0
                                    ? `${item.quantity} x ${Helper.formatPrice(
                                        x.value,
                                      )}`
                                    : Helper.formatPrice(x.value)}
                                </StyledText>
                              </WrapperRow>
                            ))}
                          </>
                        ))}
                      </SecondLevelList>
                    )}
                  </div>
                ))}
              </DetailContent>
            </>
          )}
        </Details>
      )}
      {!visibility && !isCheckPassangersModalOpen && !stickyHide && (
        <Wrapper
          isActive={isDetailsOpen}
          isDisplay={visibility === null ? true : false}
          onMouseLeave={() => {
            document.addEventListener('click', onClickOutsideListener);
          }}
        >
          <Row>
            <GrayBlock onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
              <StickyArrow isActive={isDetailsOpen} /> <StickyInfo />
            </GrayBlock>
            <PriceBlock>
              <Row>
                Итого&nbsp;
                {chosenScreen === 1 && total && (
                  <BlueText>
                    <AnimatedNumber
                      duration={500}
                      value={total}
                      formatValue={(value: number) =>
                        value !== 0 ? Helper.formatPrice(value) : ''
                      }
                    />
                  </BlueText>
                )}
                {chosenScreen === 3 && (
                  <BlueText>
                    <AnimatedNumber
                      duration={500}
                      value={
                        chosenPaymentMethod !== 'Российская карта'
                          ? totalDetalization.total +
                            acquiringCommissions[1].acquiringAmount
                          : totalDetalization.total
                      }
                      formatValue={(value: number) =>
                        value !== 0 ? Helper.formatPrice(value) : ''
                      }
                    />
                  </BlueText>
                )}
              </Row>
              <Row>
                {chosenScreen && chosenScreen === 3 && (
                  <CheckPassangerData
                    onClick={() =>
                      setIsCheckPassangersModalOpen(!isCheckPassangersModalOpen)
                    }
                  >
                    Данные пассажиров
                  </CheckPassangerData>
                )}
              </Row>
            </PriceBlock>
          </Row>
          {chosenScreen && chosenScreen === 1 && (
            <YellowButtonBook
              type="button"
              onClick={async () => {
                setMobileSubmitCount(1);
                const curErrors = await validateForm();
                const curErrorsKeys = Object.keys(curErrors);
                if (errors.offerta && !errors.passengers) {
                  const scrollElement = document.getElementById('offertaDiv');

                  scrollElement?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }
                if (curErrorsKeys.length) {
                  const key = curErrorsKeys[0];

                  const el = document.getElementById(
                    key === 'passengers' ? key : 'contacts',
                  );

                  if (el && key !== 'offerta') {
                    const offset = el.offsetTop;
                    window.scrollTo({
                      top: offset,
                      behavior: 'smooth',
                    });
                  } else if (el && key === 'offerta') {
                    const scrollElement = document.getElementById('offerta');

                    scrollElement?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                  }
                  submit.setTouched(setNestedObjectValues(errors, true));
                } else {
                  submit.handleSubmit();
                }
              }}
            >
              Продолжить
            </YellowButtonBook>
          )}

          {chosenScreen && chosenScreen === 3 && (
            <YellowButton
              onClick={() => {
                const scrollElement = document.getElementById('PaymentDiv');
                scrollElement?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }}
            >
              {userState?.data?.agentGroup !== 'Самотур (GDS тур)'
                ? 'Перейти к оплате'
                : 'Забронировать'}
            </YellowButton>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default StickyFooter;
