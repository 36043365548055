import { createReducer, createAction } from 'redux-act';
import { combineReducers } from 'redux';
import {
  NotificationState,
  ShowNotificationPayload,
  BookingWarningState,
} from './types';

export const showNotification =
  createAction<ShowNotificationPayload>('@notification/show');

export const showBookingWarning = createAction<
  Exclude<BookingWarningState, null>
>('@notification/bookingWarning/show');
export const closeBookingWarning = createAction(
  '@notification/bookingWarning/close'
);

export const setNotification =
  createAction<Exclude<NotificationState, null>>('@notification/set');

export const closeNotification = createAction<{ searchRequest?: any }>(
  '@notification/close'
);

export const getErrorData = createAction<any>('@notification/close');

const bookingWarning = createReducer<BookingWarningState>({}, null);
bookingWarning.on(showBookingWarning, (_, p) => p);
bookingWarning.on(closeBookingWarning, () => null);

const warnings = combineReducers({
  bookingWarning,
});

const notification = createReducer<NotificationState>({}, null);

notification.on(setNotification, (_, payload) => ({ ...payload }));
notification.on(
  closeNotification,
  (_, payload) => payload?.searchRequest || null
);

const errorData = createReducer<any>({}, null);

errorData.on(getErrorData, (_, payload) => payload);

export default combineReducers({ warnings, notification, errorData });
