import moment, { Moment } from 'moment';
import { FlightClassTypes } from './types';
import {
  FLIGHT_TYPES,
  FlightType,
} from '@components/booking/FlightDetail/types';

type IntervalType = {
  from: string;
  to: string;
  price: number;
};
export type IntervalObjType = { [key: string]: IntervalType };

export function sortIntervals(obj: IntervalObjType) {
  const sortable: { date: Moment; data: IntervalType }[] = [];
  const sortedObj: IntervalObjType = {};
  Object.keys(obj).forEach((key) => {
    sortable.push({ date: moment(obj[key].from), data: obj[key] });
  });
  sortable.sort((a, b) => {
    if (a.date.isSame(b.date)) {
      return 0;
    }
    return a.date.isAfter(b.date) ? 1 : -1;
  });
  sortable.forEach((el) => {
    sortedObj[el.data.from] = el.data;
  });
  return sortedObj;
}

export function createSearchParams(params: {
  from: string;
  to: string;
  dateFrom: string;
  dateTo?: string;
  category: string;
  adults: number;
  children: number;
  infants: number;
  flightClass: FlightClassTypes;
}) {
  const dateFromFormated = moment(params.dateFrom).format('MMYY');
  const from = `${params.from}${dateFromFormated}`;
  const dateToFormated = moment(params.dateTo).format('MMYY');
  const to = `${params.to}${params.dateTo ? dateToFormated : ''}`;
  const formatedTicketCategory = params.category[0].toUpperCase();
  const flightClassFormated =
    params.flightClass === FlightClassTypes.Econom ? '' : params.flightClass;

  const passengers = `${params.adults}-${params.children}-${params.infants}`;
  return `${from}${to}${formatedTicketCategory}${flightClassFormated}${passengers}`;
}

export function extractFirstNumber(input: string): number | null {
  const match = input.match(/\d+/);
  return match ? parseInt(match[0], 10) : null;
}

export const getFlightTypeTitle = (flightType: FlightType, isShort = false) => {
  switch (flightType) {
    case FLIGHT_TYPES.REGULAR:
      return isShort ? 'Регулярный' : 'Регулярный рейс';
    case FLIGHT_TYPES.CHARTER:
      return isShort ? 'Чартер' : 'Чартерный рейс';
    case FLIGHT_TYPES.LOWCOSTER:
      return 'Лоукостер';
    default:
      return isShort ? 'Регулярный' : 'Регулярный рейс';
  }
};
