import { createAction, createReducer } from 'redux-act';
import { AGENT_USERS_LOADING_STATE } from '@modules/agents/agent-finance/constants';
import {
  GeneratedTuiOrderServiceApiDomainModelAgentResponsesGetAgentUsersResponse,
  GeneratedTuiOrderServiceApiDomainModelUserResponsesGetUserRolesResponse,
  GeneratedAssignUsersToAgentRequest,
  GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserRoleRequest,
  GeneratedTuiOrderServiceApiDomainModelUserProjectionsUserRole,
  GeneratedTuiOrderServiceApiDomainModelUserProjectionsUserFromAgent,
  GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserActiveRequest,
  GeneratedTuiOrderServiceApiDomainModelUserResponsesSetUserActiveResponse,
  GeneratedAssignUsersToAgentResponse,
  GeneratedTuiOrderServiceApiRequestsAddSimpleUserRequest,
  GeneratedOrderHistoryModel,
  GeneratedOrderPositionEventModel,
} from './types';

export const getAgentUserRolesRequest = createAction(
  'order/getAgentUserRolesRequest'
);
export const getAgentUserRolesSuccess =
  createAction<GeneratedTuiOrderServiceApiDomainModelUserResponsesGetUserRolesResponse>(
    'order/getAgentUserRolesSuccess'
  );

export const getAgentUsersRequest = createAction<{ AgentId?: string | null }>(
  '@@agents/getAgentUsers'
);
export const getAgentUsersSuccess =
  createAction<GeneratedTuiOrderServiceApiDomainModelAgentResponsesGetAgentUsersResponse>(
    '@@agents/getAgentUsersSuccess'
  );

export const addAgentUsersRequest =
  createAction<GeneratedAssignUsersToAgentRequest>('@@agents/addAgentUsers');
export const addAgentUsersSuccess = createAction<
  GeneratedAssignUsersToAgentResponse | number
>('@@agents/addAgentUsersSuccess');

export const setRoleToUserRequest =
  createAction<GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserRoleRequest>(
    '@@agents/setRoleToUser'
  );
export const setRoleToUserSuccess = createAction<boolean>(
  '@@agents/setRoleToUserSuccess'
);

export const setAgentUsersRequest =
  createAction<GeneratedTuiOrderServiceApiDomainModelUserRequestsSetUserActiveRequest>(
    '@@agents/setAgentUsers'
  );
export const setAgentUsersSuccess = createAction<
  GeneratedTuiOrderServiceApiDomainModelUserResponsesSetUserActiveResponse | any
>('@@agents/setAgentUsersSuccess');

export const createUserInSamoRequest =
  createAction<GeneratedTuiOrderServiceApiRequestsAddSimpleUserRequest>(
    '@@agents/createUserInSamo'
  );
export const createUserInSamoSuccess = createAction<any>(
  '@@agents/createUserInSamoSuccess'
);

export const getAgentLogRequest = createAction<{ AgentId?: string }>(
  '@@agents/getAgentLogRequest'
);
export const getAgentLogSuccess = createAction<GeneratedOrderHistoryModel>(
  '@@agents/getAgentLogSuccess'
);

export const getUsefulInfoRequest = createAction(
  '@@agents/getUsefulInfoRequest'
);

const roles = createReducer<
  | GeneratedTuiOrderServiceApiDomainModelUserProjectionsUserRole[]
  | null
  | undefined
>({}, []);
roles.on(getAgentUserRolesSuccess, (_, payload) => payload?.userRoles);

const agentUsers = createReducer<
  | GeneratedTuiOrderServiceApiDomainModelUserProjectionsUserFromAgent[]
  | null
  | undefined
>({}, []);
agentUsers.on(getAgentUsersSuccess, (_, payload) => payload.users);

const addAgentUserStatus = createReducer<
  GeneratedAssignUsersToAgentResponse | null | number | any
>({}, null);
addAgentUserStatus.on(addAgentUsersSuccess, (_, payload) => payload);

const agentLog = createReducer<
  GeneratedOrderPositionEventModel[] | null | undefined
>({}, null);
agentLog.on(getAgentLogSuccess, (_, payload) => payload?.event);

const loadingsUser = createReducer<typeof AGENT_USERS_LOADING_STATE>(
  {},
  AGENT_USERS_LOADING_STATE
);
loadingsUser.on(addAgentUsersRequest, (state) => ({
  ...state,
  createUserInSamo: true,
}));
loadingsUser.on(addAgentUsersSuccess, (state) => ({
  ...state,
  createUserInSamo: false,
}));

const userAgentsReducers = {
  roles,
  agentUsers,
  addAgentUserStatus,
  agentLog,
  loadingsUser,
};

export default userAgentsReducers;
