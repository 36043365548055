import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import DiscountIcon from '@assets/media/train-tickets/discount-yellow-icon.svg';

const Container = styled.div<{border: boolean}>`
  display: flex;
  align-items: ${({ border }) => border ? 'center' : 'flex-start'};
  gap: 14px;
  padding: 14px 24px;
  background: #fff3cd;
  border: ${({ border }) => border ? '1px solid #ffb800' : 'none'};
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 14px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 8px;
`;

const DiscountIconContainer = styled.div`
  padding: 4px;
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiscountIconItem = styled.img.attrs({ src: DiscountIcon })`
  width: 15px;
  height: 15px;
`;

export function TrainDiscountNotyWithIcon() {
  return (
    <Container border={false}>
      <DiscountIconContainer>
        <DiscountIconItem />
      </DiscountIconContainer>
      <Text>
        Обращаем Ваше внимание, что скидка по выбранному тарифу будет применена на следующем этапе
      </Text>
    </Container>
  );
}

export function PupilNoty() {
  return (
    <Container border>
      <Text size={'small'}>
        Обращаем внимание, что при выборе данного тарифа обязательно предъявление оригинала справки обучающегося или воспитанника общеобразовательного учреждения Российской Федерации очной формы обучения при посадке в поезд.
        <br /><br />
        Справка должна содержать ФИО лица, на которое оформлена, реквизиты и юридический адрес школы, заверена печатью школы и подписью руководителя образовательного учреждения либо лица его замещающего на основании приказа, иметь номер и дату. Воспитанники Суворовских, Нахимовских училищ и Кадетских корпусов для подтверждения льготы предъявляют при посадке в поезд удостоверение воспитанника      </Text>
    </Container>
  );
}

export default function TrainDiscountNoty() {
  return (
    <Container border>
      <Text size={'small'}>
        Обращаем Ваше внимание, что скидка по выбранному тарифу и скидочным картам будет применена на следующем этапе бронирования, при нажатии на кнопку <b>“Перейти к оплате”</b>
      </Text>
    </Container>
  );
}
