import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Header, Text } from '@components/ui/Typography';
import { useDispatch } from 'react-redux';
import { signInFunTinkoffComplete} from '@modules/user';
import { TuiIcon } from '@components/loader';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const Container = styled(InfoBlock)`
  width: 468px;
  align-self: center;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 9999;
`;


const TinkoffSignIn: React.FC = () => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const state = params.get('state') || '';
    const code = params.get('code') || '';
    const sessionState = params.get('session_state') || '';
    dispatch(signInFunTinkoffComplete({ state, code, sessionState }))
  }, []);

  return (
    <Wrapper ref={ref}>
      <Container>
        <LoaderWrapper>
          <TuiIcon />
        </LoaderWrapper>
      </Container>
    </Wrapper>
  );
};

export default TinkoffSignIn;
