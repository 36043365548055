import React from 'react';
import { useSelector } from 'react-redux';
import { PriceItem } from '@modules/booking';
import { ApplicationState } from '@modules/index';
import PassengerPrice from '@components/booking/PassengerPrice/PassengerPrice';
import { orderTotalDetalizationToPriceDetails } from '@modules/booking/utils';

const AviaPassengerPriceOrderDetails: React.FC<{
  chosenPaymentMethod: string;
}> = (props) => {
  const isShowCommission = useSelector(
    (state: ApplicationState) => state?.user?.userCommissionStatus
  );
  const orderDetails = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.data
  );
  const total = orderDetails.totalDetalization.total;
  const subagentTotal = 0;
  const detailList: PriceItem[] = orderTotalDetalizationToPriceDetails(
    orderDetails.orderAmount.detalization
  );

  return (
    <>
      <PassengerPrice
        priceType={'avia'}
        title={'Тариф'}
        total={total}
        subagentTotal={subagentTotal}
        detailList={detailList}
        isShowCommission={isShowCommission}
        isSecondBookingPage={true}
        chosenPaymentMethod={props.chosenPaymentMethod}
      />
    </>
  );
};

export default AviaPassengerPriceOrderDetails;
