import React from 'react';
import styled from 'styled-components';

interface Props {
  isAvia: boolean;
}

const Container = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-top: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const TransportTypeDescription: React.FC<Props> = (props) => {
  return (
    <Container>
      {props.isAvia ? (
        <>
          {' '}
          Заполните данные пассажиров. После оплаты внесение изменений
          платное.
        </>
      ) : (
        <>
          Чтобы избежать трудностей при посадке на поезд, внимательно
          заполните данные на каждого пассажира в точности так, как в
          документе, по которому поедете.
        </>
      )}
    </Container>
  );
};
export default TransportTypeDescription;
