import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import {
  ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS,
  RailwayTariff,
  setRefundableStateSeatPlace,
} from '@modules/trainBooking';
import {
  filterNotLoyaltyCardsInTariffs,
  filterTariffsByParams,
  getTariffsByDiscountTypes,
} from '@modules/trainBooking/utils';
import { FormikPassengerItemEntity } from '@modules/booking';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { FreePlacesByCompartmentPrice } from '@modules/trainTickets';
import { TariffsContainerComponent } from '@components/train/Tariffs/TariffsContainerComponent';

interface Props {
  passenger: FormikPassengerItemEntity;
  passengerIndex: number;

  hasNonRefundableTariffForward: boolean;
  hasNonRefundableTariffBackward?: boolean;

  hasOnlyNonRefundableTariff: boolean;
  hasOnlyNonRefundableTariffBackward?: boolean;
}

export interface TrainTariff {
  type: string;
  active: boolean; // состояние активности тарифа
  disabled: boolean; // состояние доступности тарифа
  name: string; // название тарифа
  value: string; // цена тарифа
  text: string; // краткое описание тарифа
  fullDescription: string; // детальное описание тарифа
  fullDiscountDescription: string; // детальное описание cкидки
}

const Wrapper = styled.div``;

export const TariffsPanel: React.FC<Props> = (props) => {
  const {
    passengerIndex,
    passenger,
    hasNonRefundableTariffForward,
    hasNonRefundableTariffBackward,
    hasOnlyNonRefundableTariff,
    hasOnlyNonRefundableTariffBackward,
  } = props;
  const dispatch = useDispatch();
  const trainPassengers = useSelector(
    (state: ApplicationState) => state.trainBooking.passengers
  );
  const {
    setFieldValue,
    values: { passengers },
  } = useFormikContext<{
    passengers: FormikPassengerItemEntity[];
  }>();
  const trainForward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward
  );
  const trainBackward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainBack
  );
  const chosenTariffForwardFromStore = useSelector(
    (state: ApplicationState) => state.trainBooking.tariffs.forward
  );
  const chosenTariffBackwardFromStore = useSelector(
    (state: ApplicationState) => state.trainBooking.tariffs.backward
  );
  const isForwardKupekTariff = chosenTariffForwardFromStore === 'Kupek';
  const isBackwardKupekTariff = chosenTariffBackwardFromStore === 'Kupek';
  const isForwardSingleTariff = chosenTariffForwardFromStore === 'Single';
  const isBackwardSingleTariff = chosenTariffBackwardFromStore === 'Single';

  const isAdult = passenger.ageCategory === 'ADULT';
  const isChild = passenger.ageCategory === 'CHILD';

  const FullTariffForward = React.useMemo(() => {
    return ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS[0];
  }, []);

  const FullTariffBackward = React.useMemo(() => {
    return {
      ...ADULT_RAILWAY_TARIFFS_AND_LOYALTY_CARDS[0],
    };
  }, []);

  const [filteredRailwayTariffFrom, setFilteredRailwayTariffFrom] = useState<
    RailwayTariff[]
  >([FullTariffForward]);
  const [filteredRailwayTariffTo, setFilteredRailwayTariffTo] = useState<
    RailwayTariff[]
  >([FullTariffBackward]);
  const [tariffsListForward, setTariffsListForward] = useState<TrainTariff[]>(
    []
  );
  const [tariffsListBackward, setTariffsListBackward] = useState<TrainTariff[]>(
    []
  );

  const trains = {
    forward: trainForward.train,
    backward: trainBackward.train,
  };

  const cars = {
    forward: trainForward.currentCar?.data,
    backward: trainBackward?.currentCar?.data,
  };

  let diffTariffPriceBackward = 0;
  let railwayTariffsTo: RailwayTariff[] = [];

  const discountsFrom = trainForward.currentCar?.data.discounts;

  const railwayTariffsFrom = React.useMemo(() => {
    return filterNotLoyaltyCardsInTariffs(
      getTariffsByDiscountTypes(
        discountsFrom.map((discount) => discount.discountType)
      )
    );
  }, [discountsFrom]);

  let tariffPriceBackward: FreePlacesByCompartmentPrice | undefined =
    undefined;
  let diffTariffPriceForward = 0;
  let tariffPriceForward: FreePlacesByCompartmentPrice | undefined = undefined;
  if (isAdult) {
    tariffPriceForward = trainPassengers[passengerIndex]?.carPlaces?.length > 0
      ? trainPassengers[passengerIndex].carPlaces[0].prices?.find(
        (price) => price.tariffType === 'Full'
      )
      : undefined;
  }
  if (isChild) {
    tariffPriceForward = trainPassengers[passengerIndex]?.carPlaces?.length > 0
      ? trainPassengers[passengerIndex].carPlaces[0].prices?.find(
        (price) => price.tariffType === 'Child'
      )
      : undefined;
  }

  if (tariffPriceForward) {
    diffTariffPriceForward =
      tariffPriceForward.maxPrice.totalPrice -
      tariffPriceForward.minPrice.totalPrice;
  }

  if (trainBackward?.index !== null) {
    if (isAdult) {
      tariffPriceBackward =
        trainPassengers[passengerIndex]?.carPlaces?.length > 0
          ? trainPassengers[passengerIndex].carPlaces[1]?.prices?.find(
            (price) => price.tariffType === 'Full'
          )
          : undefined;
    }
    if (isChild) {
      tariffPriceBackward =
        trainPassengers[passengerIndex]?.carPlaces?.length > 0
          ? trainPassengers[passengerIndex].carPlaces[1]?.prices?.find(
            (price) => price.tariffType === 'Child'
          )
          : undefined;
    }
    if (tariffPriceBackward) {
      diffTariffPriceBackward =
        tariffPriceBackward.maxPrice.totalPrice -
        tariffPriceBackward.minPrice.totalPrice;
    }
    const discountsTo = trainBackward.currentCar?.data.discounts;
    railwayTariffsTo = filterNotLoyaltyCardsInTariffs(
      getTariffsByDiscountTypes(
        discountsTo.map((discount) => discount.discountType)
      )
    );
  }

  useEffect(() => {
    setFilteredRailwayTariffFrom(
      filterTariffsByParams(
        'forward',
        [...[FullTariffForward], ...railwayTariffsFrom],
        trains,
        cars,
        passenger,
        passengers
      )
    );
    if (trainBackward.index !== null) {
      setFilteredRailwayTariffTo(
        filterTariffsByParams(
          'backward',
          [...[FullTariffBackward], ...railwayTariffsTo],
          trains,
          cars,
          passenger,
          passengers
        )
      );
    }
  }, [passenger, passengers]);

  useEffect(() => {
    // if new tariff not in filteredRailwayTariffFrom, then reset tariff to Full
    if (
      filteredRailwayTariffFrom.length > 0 &&
      !filteredRailwayTariffFrom.some(
        (tariff) => tariff.apiValue === chosenTariffForwardFromStore
      )
    ) {
      resetTariffForward();
    }
  }, [filteredRailwayTariffFrom]);

  useEffect(() => {
    // if new tariff not in filteredRailwayTariffTo, then reset tariff to Full
    if (
      filteredRailwayTariffTo.length > 0 &&
      !filteredRailwayTariffTo.some(
        (tariff) => tariff.apiValue === chosenTariffBackwardFromStore
      )
    ) {
      resetTariffBackward();
    }
  }, [filteredRailwayTariffTo]);

  useEffect(() => {
    if (isForwardKupekTariff) {
      handleChangeTariff('Kupek', 'forward');
    }
    if (isForwardSingleTariff) {
      handleChangeTariff('Single', 'forward');
    }

    if (trainBackward.index !== null) {
      if (isBackwardKupekTariff) {
        handleChangeTariff('Kupek', 'backward');
      }
      if (isBackwardSingleTariff) {
        handleChangeTariff('Single', 'backward');
      }
    }
  }, []);

  useEffect(() => {
    setTariffsListForward(
      filteredRailwayTariffFrom.map((tariff) => {
        return {
          type: tariff.apiValue,
          active:
            tariff.apiValue ===
            passengers[passengerIndex].tariffsPerRoute[0].tariffType,
          disabled:
            (isForwardKupekTariff && tariff.apiValue !== 'Kupek') ||
            (!isForwardKupekTariff && tariff.apiValue === 'Kupek') ||
            (isForwardSingleTariff && tariff.apiValue !== 'Single') ||
            (!isForwardSingleTariff && tariff.apiValue === 'Single'),
          name: tariff.title,
          value: tariff.discountTitle,
          text: tariff.description,
          fullDescription: tariff.fullDescription,
          fullDiscountDescription: tariff.fullDiscountDescription,
        };
      })
    );
  }, [filteredRailwayTariffFrom, passengers[passengerIndex]]);

  useEffect(() => {
    const tariffsPerRoute = passengers[passengerIndex].tariffsPerRoute;
    const hasBackwardTariff = tariffsPerRoute.length === 2;
    setTariffsListBackward(
      filteredRailwayTariffTo.map((tariff) => {
        return {
          type: tariff.apiValue,
          active: hasBackwardTariff
            ? tariff.apiValue === tariffsPerRoute[1].tariffType
            : false,
          disabled:
            (isBackwardKupekTariff && tariff.apiValue !== 'Kupek') ||
            (!isBackwardKupekTariff && tariff.apiValue === 'Kupek') ||
            (isBackwardSingleTariff && tariff.apiValue !== 'Single') ||
            (!isBackwardSingleTariff && tariff.apiValue === 'Single'),
          name: tariff.title,
          value: tariff.discountTitle,
          text: tariff.description,
          fullDescription: tariff.fullDescription,
          fullDiscountDescription: tariff.fullDiscountDescription,
        };
      })
    );
  }, [filteredRailwayTariffTo, passengers[passengerIndex]]);

  useEffect(() => {
    // check passenger age
    const departureDateForward = trainForward.train.localDepartureDate;
    // const departureDateBackward = trainBackward?.train.departureDate;
    const passengerYears = props.passenger.birthDate
      ? moment(departureDateForward).get('year') -
        moment(props.passenger.birthDate).get('year')
      : 0;
    const isChildCategory = props.passenger.ageCategory === 'CHILD';
    const isInfantCategory = props.passenger.ageCategory === 'INFANT';
    const isSeniorAge = passengerYears >= 60;
    const isJuniorAge = passengerYears >= 10 && passengerYears < 21;
    const isChild17Age = passengerYears >= 10 && passengerYears < 17;

    const hasSeniorTariff = tariffsListForward.some(
      (tariff) => tariff.type === 'Senior'
    );
    const hasJuniorTariff = tariffsListForward.some(
      (tariff) => tariff.type === 'Junior'
    );

    // Set tariffs for passenger
    if (isSeniorAge && hasSeniorTariff) {
      handleChangeTariff('Senior', 'forward');

      if (trainBackward.index !== null) {
        handleChangeTariff('Senior', 'backward');
      }
    }

    // Junior tariffs for 10-21 years old
    if (isJuniorAge && hasJuniorTariff) {
      handleChangeTariff('Junior', 'forward');

      if (trainBackward.index !== null) {
        handleChangeTariff('Junior', 'backward');
      }
    }
  }, [props.passenger.birthDate]);

  const setTariffForwardInFormik = (tariffType: string) => {
    const stationCode = trainForward.train.fromStation.code;
    const currState = passengers[passengerIndex].tariffsPerRoute[0];
    setFieldValue(`passengers.${passengerIndex}.tariffsPerRoute.${0}`, {
      ...currState,
      originCode: stationCode,
      tariffType: tariffType,
    });
  };

  const setTariffBackwardInFormik = (tariffType: string) => {
    const stationCode = trainBackward.train.fromStation.code;
    const currState = passengers[passengerIndex].tariffsPerRoute[1];
    setFieldValue(`passengers.${passengerIndex}.tariffsPerRoute.${1}`, {
      ...currState,
      originCode: stationCode,
      tariffType: tariffType,
    });
  };

  const resetTariffForward = () => {
    setTariffForwardInFormik('Full');
  };

  const resetTariffBackward = () => {
    setTariffBackwardInFormik('Full');
  };

  const handleChangeTariff = (
    tariffType: string,
    direction: 'forward' | 'backward'
  ) => {
    if (tariffType === 'RoundTrip') {
      setTariffForwardInFormik(tariffType);
      setTariffBackwardInFormik(tariffType);
    }

    if (direction === 'forward') {
      setTariffForwardInFormik(tariffType);
      if (
        tariffType !== 'RoundTrip' &&
        chosenTariffBackwardFromStore === 'RoundTrip'
      ) {
        resetTariffBackward();
      }
    } else {
      setTariffBackwardInFormik(tariffType);

      if (
        tariffType !== 'RoundTrip' &&
        chosenTariffForwardFromStore === 'RoundTrip'
      ) {
        resetTariffForward();
      }
    }
  };

  const handleChangeNonRefundableTariff = (
    direction: 'forward' | 'backward'
  ) => {
    const isBackward = direction === 'backward';
    setFieldValue(
      `passengers.${passengerIndex}.tariffsPerRoute.${
        isBackward ? 1 : 0
      }.isNonRefundableTariff`,
      !passengers[passengerIndex].tariffsPerRoute[isBackward ? 1 : 0]
        ?.isNonRefundableTariff
    );
    dispatch(
      setRefundableStateSeatPlace({
        passengerIndex: passengerIndex,
        direction: isBackward ? 'backward' : 'forward',
        isNotRefundable:
          !passengers[passengerIndex].tariffsPerRoute[isBackward ? 1 : 0]
            ?.isNonRefundableTariff,
      })
    );
  };

  return (
    <Wrapper>
      <TariffsContainerComponent
        routeTitle={`${trainForward.train.fromStation.city.name_ru} – ${trainForward.train.toStation.city.name_ru}`}
        hasNonRefundableTariff={hasNonRefundableTariffForward}
        onlyNonRefundableTariff={hasOnlyNonRefundableTariff}
        tariffsList={tariffsListForward}
        diffTariffPrice={diffTariffPriceForward}
        handleChangeTariff={(tariffType: string) => {
          handleChangeTariff(tariffType, 'forward');
        }}
        handleChangeNonRefundableTariff={() => {
          handleChangeNonRefundableTariff('forward');
        }}
        nonRefundIsChecked={
          passengers[passengerIndex].tariffsPerRoute[0].isNonRefundableTariff
        }
      />
      {passengers[passengerIndex].tariffsPerRoute.length > 1 && (
        <TariffsContainerComponent
          routeTitle={`${trainBackward.train.fromStation.city.name_ru} – ${trainBackward.train.toStation.city.name_ru}`}
          hasNonRefundableTariff={hasNonRefundableTariffBackward}
          onlyNonRefundableTariff={hasOnlyNonRefundableTariffBackward}
          tariffsList={tariffsListBackward}
          diffTariffPrice={diffTariffPriceBackward}
          handleChangeTariff={(tariffType: string) => {
            handleChangeTariff(tariffType, 'backward');
          }}
          handleChangeNonRefundableTariff={() => {
            handleChangeNonRefundableTariff('backward');
          }}
          nonRefundIsChecked={
            passengers[passengerIndex].tariffsPerRoute[1]
              ?.isNonRefundableTariff || false
          }
        />
      )}
    </Wrapper>
  );
};
