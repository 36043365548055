import React from 'react';

const BaggageInfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="53"
      height="41"
      viewBox="0 0 53 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.0032 7.35352H3.99679C2.61785 7.35352 1.5 8.44366 1.5 9.78843V37.5653C1.5 38.91 2.61785 40.0002 3.99679 40.0002H49.0032C50.3822 40.0002 51.5 38.91 51.5 37.5653V9.78843C51.5 8.44366 50.3822 7.35352 49.0032 7.35352Z"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6794 1.00002H31.3235C31.9857 1.00002 32.6208 1.25656 33.089 1.71319C33.5573 2.16983 33.8203 2.78916 33.8203 3.43494V7.3538H19.1719V3.43494C19.1719 3.1143 19.2368 2.79682 19.363 2.50072C19.4891 2.20462 19.674 1.93573 19.9069 1.70949C20.1399 1.48325 20.4164 1.30411 20.7206 1.18236C21.0248 1.06061 21.3506 0.998646 21.6794 1.00002V1.00002Z"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.0156 40.0002V7.35352"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9844 7.35352V40.0002"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_18400_4825)">
        <path
          d="M27.0013 31.3327C31.6037 31.3327 35.3346 27.6017 35.3346 22.9993C35.3346 18.397 31.6037 14.666 27.0013 14.666C22.3989 14.666 18.668 18.397 18.668 22.9993C18.668 27.6017 22.3989 31.3327 27.0013 31.3327Z"
          stroke="#4872F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27 18.834H27.015"
          stroke="#4872F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.332 22.166H26.9987V26.3327"
          stroke="#4872F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.332 26.334H28.6654"
          stroke="#4872F2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_18400_4825">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(17 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BaggageInfoIcon;
