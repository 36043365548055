import React from 'react';
import DesktopHead from './Desktop';
import MobileHead from './Mobile';
import { Hooks } from '@utils';
import { useSelector } from 'react-redux';
import { getInfoBlockData } from '@modules/orders';

const Description: React.FC = React.memo(() => {
  const { isLaptopOrBigTablet } = Hooks.useMediaSizes();
  const props = useSelector(getInfoBlockData);

  return (
    <>
      {isLaptopOrBigTablet ? (
        <MobileHead {...props} />
      ) : (
        <DesktopHead {...props} />
      )}
    </>
  );
});

export default Description;
