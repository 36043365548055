import { BusForRouteDetail } from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { Bus } from '@modules/busTickets';
import { FlightEntity } from '@modules/booking';
import moment from 'moment';
import { PassengersFormData } from '@components/bus/Booking/components/Passengers/interfaces';
import { FormikValues } from 'formik';
import { parse } from 'date-fns';
import { BusBookingOrderCreation, Customer, Places } from './interfaces';
import { FLIGHT_TYPES } from '@components/booking/FlightDetail/types';

export const formatMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hourWord = getCorrectWordForm(hours, ['час', 'часа', 'часов']);

  // Формируем строку с часами
  let result = `${hours} ${hourWord}`;

  // Добавляем минуты только если их больше 0
  if (remainingMinutes > 0) {
    const minuteWord = getCorrectWordForm(remainingMinutes, [
      'минута',
      'минуты',
      'минут',
    ]);
    result += ` ${remainingMinutes} ${minuteWord}`;
  }

  return result;
};

const getCorrectWordForm = (
  number: number,
  wordForms: [string, string, string],
): string => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return wordForms[0];
  } else if (
    number % 10 >= 2 &&
    number % 10 <= 4 &&
    (number % 100 < 10 || number % 100 >= 20)
  ) {
    return wordForms[1];
  } else {
    return wordForms[2];
  }
};

export const transformDataFromBusToBusForRouteDetail = (
  buses: Bus[],
): BusForRouteDetail[] =>
  buses.map((bus: Bus) => ({
    fromStation: {
      code: bus.fromStation.code,
      name_ru: bus.fromStation.nameRu,
      desctiption: bus.originStationName,
    },
    toStation: {
      code: bus.toStation.code,
      name_ru: bus.toStation.nameRu,
      desctiption: bus.destinationStationName,
    },
    // TODO узнать про свойство isTransit у автобуса
    isTransit: false,
    carrierDisplayNames: [bus.carrier],
    initialStationName: '',
    finalStationName: '',
    busName: bus.raceName,
    busNumber: bus.raceNumber,
    tripDuration: formatMinutes(bus.durationInMinutes),
    departureDateTimeUtc: bus.departureDateTime,
    arrivalDateTimeUtc: bus.arrivalDateTime,
    localDepartureDate: bus.departureDateTime,
    localArrivalDate: bus.arrivalDateTime,
    index: bus.index,
    price: bus.price,
    totalPlaceQuantity: bus.ticketsCount,
    raceId: bus.raceId,
    freePlaces: null,
    address: bus.destinationStationAddress,
    geolocation: bus.destinationGeoLocation,
    provider: bus.provider,
    place: '',
  }));

export const busToFlightEntity = (bus: BusForRouteDetail): FlightEntity => {
  return {
    amount: 0,
    //  TODO разобраться с TripDuration
    duration: moment.duration('').asMinutes(),
    index: bus.index,
    minAvailSeats: 0,
    stops: [],
    flight_Type: FLIGHT_TYPES.REGULAR,
    isLowcoster: false,
    transitVisaInfo: null,
    transitVisaInfoText: null,
    time: '',
    segments: [
      {
        index: bus.index,
        fareFamilyFeature: null,
        techLandings: [],
        duration: 0,
        flightNo: '',
        airline: '',
        airplane: '',
        operatingAirlineCode: '',
        airlineCode: '',
        from: {
          code: bus.fromStation.name_ru,
          airport: bus.fromStation.name_ru,
          //  TODO разобраться с city
          city: '',
          country: 'RU',
          terminal: '',
        },
        to: {
          code: bus.toStation.name_ru,
          airport: bus.toStation.name_ru,
          //  TODO разобраться с city
          city: '',
          country: 'RU',
          terminal: '',
        },
        id: bus.index + '',
        //  TODO разобраться с TripDuration
        fromDate: moment(bus.departureDateTimeUtc).format('YYYY-MM-DD'),
        fromTime: moment(bus.departureDateTimeUtc).format('HH:mm:ss'),
        toDate: moment(bus.arrivalDateTimeUtc).format('YYYY-MM-DD'),
        toTime: moment(bus.arrivalDateTimeUtc).format('HH:mm:ss'),
        time: bus.tripDuration,
        flightClass: '',
        flight_Type: FLIGHT_TYPES.REGULAR,
        seatMap: null,
        fareBasis: '',
        availSeats: 0,
        classCode: '',
        fareFamily: null,
        operatingAirline: '',
        isLowcoster: false,
      },
    ],
  };
};

export const getInitialValues = (
  passengersCount: number,
  passengers: PassengersFormData[],
): FormikValues => {
  const result: FormikValues = {};

  for (let i = 0; i < passengersCount; i++) {
    const passengerKey = `passenger-${i + 1}`;
    result[passengerKey] = {};

    passengers.forEach(({ key, fields }) => {
      result[passengerKey][key] = fields[0].value;
    });
  }

  return result;
};

export const checkDisabledStatus = (
  key: string,
  baseKey: string,
  values: FormikValues,
): boolean => {
  if (key === 'middleName') {
    return values[baseKey].toggleMiddleName;
  }

  return false;
};

export const getPassengerPlaces = (data: FormikValues): Places => {
  const places: Places = {
    forward: [],
    backward: [],
  };

  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key) && key.startsWith('passenger-')) {
      const passenger = data[key];
      if (passenger.places) {
        if (passenger.places.forward) {
          places.forward.push(passenger.places.forward);
        }
        if (passenger.places.backward) {
          places.backward.push(passenger.places.backward);
        }
      }
    }
  }

  return places;
};

export const getPassengerKeys = (data: FormikValues): string[] => {
  const passengerKeys: string[] = [];

  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key) && key.startsWith('passenger-')) {
      passengerKeys.push(key);
    }
  }

  return passengerKeys;
};

const getAgeCategory = (dobString: string): string => {
  const today = new Date();
  const dob = new Date(dobString);
  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  if (age < 2) {
    return 'BabyWithoutPlace';
  } else if (age < 12) {
    return 'Child';
  } else {
    return 'Adult';
  }
};

const ensurePlusPrefix = (str: string): string => {
  return str.startsWith('+') ? str : `+${str}`;
};

export const preparePassengersFormData =
  (selectedBuses: Bus[]) =>
  (passenger: FormikValues, index: number): Customer => ({
    index,
    category: getAgeCategory(passenger.dob),
    documentNumber: passenger.passportNumber,
    documentType: passenger.doctype,
    documentValidTill:
      passenger.issueDate === ''
        ? null
        : parse(passenger.issueDate, 'dd.MM.yyyy', new Date()),
    citizenshipCode: passenger.citizenship,
    birthPlace: null,
    firstName: passenger.name,
    middleName: passenger.middleName,
    lastName: passenger.surname,
    sex: passenger.gender === 'woman' ? 'Female' : 'Male',
    birthday: parse(passenger.dob, 'dd.MM.yyyy', new Date()),
    email: null,
    phoneNumber: null,
    selectedPlaces: [
      {
        originCode: selectedBuses[0].fromStation.code,
        destinationCode: selectedBuses[0].toStation.code,
        departureDate: selectedBuses[0].departureDateTime,
        raceId: selectedBuses[0].raceId,
        placeNumber: passenger?.places?.forward || '',
        provider: selectedBuses[0].provider,
        originCityId: selectedBuses[0].fromStation.region.id,
        destinationCityId: selectedBuses[0].toStation.region.id,
        arrivingDateUtc: selectedBuses[0].arrivalDateTime,
        carrier: selectedBuses[0].carrier,
        clientCharge: 0,
      },
    ],
  });

export const prepareFormDataToRestRequest = (
  values: FormikValues,
  selectedBuses: Bus[],
): BusBookingOrderCreation => {
  const passengers = Object.keys(values)
    .filter((key) => key.startsWith('passenger-'))
    .map((key) => values[key]);

  const customers: Customer[] = passengers.map(
    preparePassengersFormData(selectedBuses),
  );

  return {
    contactPhone: ensurePlusPrefix(values.phone),
    contactName: values.name,
    contactEmail: values.email,
    customers,
    userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  };
};

export const transformFreePlacesToOptions = (
  freePlaces?: string[] | null,
): Array<{ text: string; value: string }> | null => {
  if (freePlaces === null || freePlaces === undefined) return null;

  return freePlaces.map((value) => ({ text: `Место ${value}`, value }));
};
