import styled from 'styled-components';
import passengerIconSrc from '@assets/media/shared/passenger.svg';
import childIconSrc from '@assets/media/booking/child.svg';
import {
  Select as SelectField,
  DatePicker as DatePickerField,
  Field as FormField,
  Radio as RadioField,
  Checkbox as CheckboxField,
} from '@components/ui/form';

export const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 14px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const Heading = styled.h3`
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 18px 0;
  }
`;

export const Description = styled.p`
  margin: 0 0 25px 0;
  padding: 0;
`;

export const FormContainer = styled.div`
  padding-bottom: 14px;
  border-bottom: 1px solid #dcdcdc;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: 768px) {
    padding-bottom: 24px;
  }
`;

export const Header = styled.div`
  padding: 20px 0 14px 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0 20px 0;
  }
`;

export const Body = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -23px;
  }
`;

export const PassengerIcon = styled.img.attrs({ src: passengerIconSrc })`
  width: 25px;
  height: 25px;
  margin-right: 5px;

  @media (min-width: 768px) {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
`;

export const ChildIcon = styled.img.attrs({ src: childIconSrc })`
  width: 25px;
  height: 25px;
  margin-right: 5px;

  @media (min-width: 768px) {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const FormHeading = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
`;

export const LinkBtn = styled.div`
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #4872f2;
`;

const getFieldWidth = ({ width }: { width: '1x' | '2x' | '3x' }): string => {
  switch (width) {
    case '1x':
      return 'calc(33% - 16px)';
    case '2x':
      return 'calc(66% - 16px)';
    default:
    case '3x':
      return '100%';
  }
};

export const Field = styled.div<{
  width: '1x' | '2x' | '3x';
  isFullWidth?: boolean;
}>`
  width: 100%;
  margin-bottom: 20px;

  & > div,
  & > span {
    &:first-child {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    width: ${getFieldWidth};
    margin-bottom: 30px;
    margin-right: 16px;
    display: ${({ isFullWidth }) => (isFullWidth ? 'block' : 'flex')};
    gap: 10px;
  }
`;

export const Input = styled(FormField)`
  width: 100%;

  &:disabled {
    background-color: dimgrey;
    color: linen;
    opacity: 0.1;

    &::placeholder {
      color: black;
    }
  }
`;

export const Checkbox = styled(CheckboxField)`
  margin-top: 10px;
`;

export const Select = styled(SelectField)`
  width: 100%;
`;

export const DatePicker = styled(DatePickerField)``;

export const Radio = styled(RadioField)`
  height: 30px;
`;

export const FieldDesc = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 14px;
  color: #737373;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const PlacesContainer = styled.div`
  padding: 12px;
  border-radius: 4px;
  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
