import { ApplicationState } from '@app/modules';
import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from '@components/ui';

export interface Props {
  isBusRefundModalOpen: boolean;
  setIsBusRefundModalOpen: Dispatch<SetStateAction<boolean>>;
  direction: string;
}

const StyledPopup = styled(Popup)`
  &-content {
    width: 603px;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 24px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #3c3c3c;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-top: 15px;
`;

const BusRefundModal: FunctionComponent<Props> = ({
  isBusRefundModalOpen,
  setIsBusRefundModalOpen,
  direction,
}) => {
  const busForward = useSelector(
    (x: ApplicationState) => x?.busBooking?.forward?.bus,
  );

  const busBackward = useSelector(
    (x: ApplicationState) => x?.busBooking?.backward?.bus,
  );

  return (
    <StyledPopup
      onClose={() => setIsBusRefundModalOpen(false)}
      open={isBusRefundModalOpen}
    >
      <Wrapper>
        <Header>Условия возврата билета</Header>
        <Text style={{ marginBottom: 40 }}>
          Возврат билета возможен только на сайте avia-new.fstravel.ru.
          Сервисный сбор за оформление билета не возвращается.
        </Text>
        <>
          {direction === 'forward' ? (
            <>
              {' '}
              {busForward?.returnCauses
                ?.flat()
                ?.map((x, index) => <Text key={index}>{x.condition}</Text>)}
            </>
          ) : (
            <>
              {busBackward?.returnCauses
                ?.flat()
                ?.map((x, index) => <Text key={index}>{x.condition}</Text>)}
            </>
          )}
        </>
        <Button
          style={{ marginTop: 25 }}
          onClick={() => setIsBusRefundModalOpen(false)}
          type={'primary'}
        >
          Вернуться к бронированию
        </Button>
      </Wrapper>
    </StyledPopup>
  );
};

export default BusRefundModal;
