import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16ZM23.708 9.708C23.8958 9.52023 24.0013 9.26555 24.0013 9C24.0013 8.73445 23.8958 8.47977 23.708 8.292C23.5202 8.10423 23.2656 7.99874 23 7.99874C22.7344 7.99874 22.4798 8.10423 22.292 8.292L16 14.586L9.708 8.292C9.61502 8.19902 9.50465 8.12527 9.38317 8.07495C9.26169 8.02464 9.13149 7.99874 9 7.99874C8.86851 7.99874 8.73831 8.02464 8.61683 8.07495C8.49535 8.12527 8.38498 8.19902 8.292 8.292C8.19902 8.38498 8.12527 8.49535 8.07495 8.61683C8.02464 8.73831 7.99874 8.86851 7.99874 9C7.99874 9.13149 8.02464 9.26169 8.07495 9.38317C8.12527 9.50465 8.19902 9.61502 8.292 9.708L14.586 16L8.292 22.292C8.10423 22.4798 7.99874 22.7344 7.99874 23C7.99874 23.2656 8.10423 23.5202 8.292 23.708C8.47977 23.8958 8.73445 24.0013 9 24.0013C9.26555 24.0013 9.52023 23.8958 9.708 23.708L16 17.414L22.292 23.708C22.385 23.801 22.4954 23.8747 22.6168 23.925C22.7383 23.9754 22.8685 24.0013 23 24.0013C23.1315 24.0013 23.2617 23.9754 23.3832 23.925C23.5046 23.8747 23.615 23.801 23.708 23.708C23.801 23.615 23.8747 23.5046 23.925 23.3832C23.9754 23.2617 24.0013 23.1315 24.0013 23C24.0013 22.8685 23.9754 22.7383 23.925 22.6168C23.8747 22.4954 23.801 22.385 23.708 22.292L17.414 16L23.708 9.708Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default Logo;
