import React, { ReactElement } from 'react';
import styled from 'styled-components';
import AuthorizationBlock from '@components/booking/AuthorizationBlock';

export const Wrapper = styled.div`
  & < div {
    &:first-child {
      margin: 0;
    }
  }
`;
const AuthBlock = (): ReactElement => (
  <Wrapper>
    <AuthorizationBlock />
  </Wrapper>
);

export default AuthBlock;
