import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { InfoBlock } from '@components/common';
import { Helper } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import { getDetailOrder } from '@modules/orders';
import { getUserDataState, setCommissionStatus } from '@modules/user';
import arrowSrc from '@assets/media/order-detail/arrow1.svg';
import commissionEye from '@assets/media/booking/commissionEye.svg';
import commissionEyeOpen from '@assets/media/booking/commissionEyeOpen.svg';

import { ApplicationState } from '@modules/index';
import {
  AGENT_PRICE_TYPES,
  AMOUNT_COMMISSION_AGENT,
} from '@modules/orders/constants';

const Head = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const DetailContent = styled.div`
  margin-top: 24px;

  & > div {
    margin-bottom: 22px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 12px;
    }
  }
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const Title = styled(Text)`
  margin-right: 10px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

const PositionNameWrapper = styled.div<{ interactable: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 18px;

  ${({ interactable }) => (interactable ? 'cursor: pointer;' : '')}
`;

const PositionName = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  position: relative;
`;

const SecondLevelList = styled(DetailContent)`
  margin-top: 24px;
  padding-left: 11px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

const Icon = styled.img<{ reverse: boolean }>`
  position: absolute;
  right: -13px;
  top: 7px;
  transition: transform 0.2s ease-in-out;

  ${({ reverse }) => (reverse ? 'transform: rotate(180deg);' : '')}
`;

const AgentBlock = styled.div`
  padding-top: 22px;
  margin-top: 22px;
  box-sixing: border-box;
  border-top: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    padding-top: 15px;
    margin-top: 15px;
  }
`;

const AgentText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const AgentTextBlue = styled(AgentText)`
  color: #4872f2;
`;

const ShowCommissionBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #4872f2;
  cursor: pointer;
  max-width: 250px;
`;

const CommissionEye = styled.img.attrs({ src: commissionEye })`
  margin-right: 5px;
`;

const CommissionEyeOpen = styled.img.attrs({ src: commissionEyeOpen })`
  margin-right: 5px;
`;

const PriceBlock: React.FC = () => {
  const { totalDetalization } = useSelector(getDetailOrder);
  const dispatch = useDispatch();
  const user = useSelector(getUserDataState);

  const [state, setState] = React.useState(
    totalDetalization.positionList.map((_, key) => false)
  );

  const isShowCommission = useSelector(
    (state: ApplicationState) => state?.user?.userCommissionStatus
  );

  const isAgent = !!user?.agentId;
  React.useEffect(() => {
    setState(totalDetalization.positionList.map((_, key) => false));
  }, [totalDetalization.positionList.length]);

  const handleClick = (key: number) => {
    if (
      (totalDetalization.positionList[key].detalizationList || []).length > 0
    ) {
      setState((s) => s.map((x, i) => (i === key ? !x : x)));
    }
  };

  return (
    <InfoBlock style={{ marginTop: '35px' }}>
      <Head>
        <HeaderWrapper>
          <Title>Стоимость заказа</Title>
        </HeaderWrapper>
        <Title style={{ marginRight: 0 }} fontWeight="600" color="blue" bold>
          <span data-cy={'orderPriceInBottomOrderDetails'}>
            {Helper.formatPrice(totalDetalization.total)}{' '}
          </span>
        </Title>
      </Head>
      <DetailContent>
        {totalDetalization.positionList.map((item, key) => {
          const mustShowPrice =
            (isAgent && isShowCommission) ||
            !AGENT_PRICE_TYPES.includes(item.priceType);
          const hasDetailItems = (item.detalizationList || []).length > 0;
          const canExpandDetailItems =
            hasDetailItems &&
            ((isAgent && isShowCommission) ||
              item.detalizationList.some(
                (x) => !AGENT_PRICE_TYPES.includes(x.priceType)
              ));
          return (
            <div key={key}>
              {item.priceType === AMOUNT_COMMISSION_AGENT &&
              isShowCommission ? (
                <></>
              ) : item.priceType === 20 ? (
                <></>
              ) : (
                mustShowPrice && (
                  <PositionNameWrapper
                    interactable={(item.detalizationList || []).length > 0}
                    onClick={() => handleClick(key)}
                  >
                    <PositionName
                      style={{ fontWeight: item.isBold ? 600 : 400 }}
                    >
                      {item.positionName}
                      {canExpandDetailItems && (
                        <Icon
                          reverse={!state[key]}
                          src={arrowSrc}
                          alt="arrow"
                        />
                      )}
                    </PositionName>
                    <PositionName
                      color="#3C3C3C"
                      style={{ fontWeight: item.isBold ? 600 : 400 }}
                    >
                      {Helper.formatPrice(item.total)}
                    </PositionName>
                  </PositionNameWrapper>
                )
              )}

              {state[key] && hasDetailItems && (
                <SecondLevelList>
                  {item.detalizationList.map((item, key) => {
                    const mustShowPrice =
                      (isAgent && isShowCommission) ||
                      !AGENT_PRICE_TYPES.includes(item.priceType);
                    return (
                      mustShowPrice && (
                        <>
                          <DetailRow key={key}>
                            <StyledText color="dark-gray">
                              {item.type === 'FirstBag'
                                ? `Доп. багаж до ${item.name} кг`
                                : item.name}
                            </StyledText>
                            <StyledText color="dark-gray">
                              {item.quantity > 0 && item.type !== 'passenger'
                                ? `${item.quantity} x ${Helper.formatPrice(
                                    item.price
                                  )}`
                                : Helper.formatPrice(item.price)}
                            </StyledText>
                          </DetailRow>
                          {item?.detalization?.map((x: any, key) => {
                            const mustShowPrice =
                              (isAgent && isShowCommission) ||
                              !AGENT_PRICE_TYPES.includes(x.priceType);
                            return (
                              mustShowPrice && (
                                <DetailRow style={{ marginLeft: 20 }}>
                                  <StyledText color="dark-gray">
                                    {x.description}
                                  </StyledText>
                                  <StyledText color="dark-gray">
                                    {item.quantity > 0
                                      ? `${
                                          item.quantity
                                        } x ${Helper.formatPrice(x.value)}`
                                      : Helper.formatPrice(x.value)}
                                  </StyledText>
                                </DetailRow>
                              )
                            );
                          })}
                        </>
                      )
                    );
                  })}
                </SecondLevelList>
              )}
            </div>
          );
        })}
        {user?.agentId && (
          <ShowCommissionBlock
            onClick={() => dispatch(setCommissionStatus(!isShowCommission))}
          >
            {!isShowCommission ? <CommissionEyeOpen /> : <CommissionEye />}{' '}
            <div>
              {isShowCommission
                ? 'Скрыть детали для агента'
                : 'Открыть детали для агента'}{' '}
            </div>
          </ShowCommissionBlock>
        )}
      </DetailContent>
      {!!totalDetalization.agentTotal && isShowCommission && (
        <AgentBlock>
          <AgentText>Сумма оплаты для агента</AgentText>
          <AgentTextBlue data-cy={'orderDetailPriceForAgent'}>
            {Helper.formatPrice(totalDetalization.agentTotal)}
          </AgentTextBlue>
        </AgentBlock>
      )}
    </InfoBlock>
  );
};

export default PriceBlock;
