import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import Success from './Success';
import Failure from './Failure';
import Loading from './Loading';
import Default from './Default';
import ConfirmApprove from './ConfirmApprove';
import ConfirmCancel from './ConfirmCancel';
import { useSteps, STEPS } from '../useSteps';
import { useRefund } from '../useRefund';
import { useMediaQuery } from 'react-responsive';

const StyledPopup = styled(Popup)`
  &-overlay {
    z-index: 1001 !important;
  }
  &-content {
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
    max-height: 80vh;
    overflow: hidden;
    ${({ theme }) => `
      ${theme.max('767px')} {
        height: 100vh;
        max-height: 100vh;
        width: 100%;
        border: none;
        border-radius: 0;
        overflow-x: scroll;
        max-height: 100vh;
      }
    `}
  }
`;

const PopupContentWrapper = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  margin-right: -50px;
  padding-right: 30px;
  @media (max-width: 767px) {
    max-height: 100vh;
    margin-right: 0;
    padding-right: 0;
  }
`;

const SurchargeRouter: React.FC = () => {
  const [{ step }, setStep] = useSteps();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const _context = useRefund();

  React.useEffect(() => {
    if (isMobile) {
      return;
    }
    if (step === STEPS.HIDDEN) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [step]);

  const content = () => {
    switch (step) {
      case STEPS.LOADING:
        return <Loading />;
      case STEPS.CONFIRM_APPROVE:
        return <ConfirmApprove />;
      case STEPS.CONFIRM_CANCEL:
        return <ConfirmCancel />;
      default: {
        // if (isMobile) {
        //   return <div />;
        // }
        switch (_context.status.code) {
          case 'WaitingForApproval':
            return <div />;
          case 'ApprovedByClient':
          case 'Paid':
            return <div />;
          default:
            return <Default />;
        }
      }
    }
  };

  return (
    <>
      {getDefaultDestopTemplate(_context.status.code)}

      <StyledPopup
        onClose={() => setStep(STEPS.HIDDEN)}
        open={step !== STEPS.HIDDEN}
      >
        <PopupContentWrapper>{content()}</PopupContentWrapper>
      </StyledPopup>
    </>
  );
  // );
};

function getDefaultDestopTemplate(status: string | null) {
  switch (status) {
    case 'ApprovedByClient':
    case 'Paid':
      return <Success />;
    case 'CanceledByClient':
    case 'Refunded':
      return <Failure />;
    default:
      return <Default />;
  }
}

export default SurchargeRouter;
