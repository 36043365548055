/**
 * Проверяет наличие дубликатов номеров документов среди пассажиров.
 * @param {any[]} passengers - Массив пассажиров.
 * @returns {number[]} Массив индексов пассажиров с дубликатами номеров документов.
 */
export const checkDuplicateDocuments = (passengers: any[]): number[] => {
  const duplicateIndexes: number[] = [];

  passengers.forEach((passenger, index) => {
    passengers.forEach((otherPassenger, otherIndex) => {
      if (
        index !== otherIndex &&
        otherPassenger.ageCategory !== 'ADULT' &&
        passenger.ageCategory !== 'ADULT' &&
        otherPassenger.number &&
        passenger.number &&
        otherPassenger.number === passenger.number
      ) {
        if (!duplicateIndexes.includes(index)) {
          duplicateIndexes.push(index);
        }
      }
    });
  });

  return duplicateIndexes;
};

/**
 * Форматирует номер телефона, удаляя пробелы и специальные символы.
 * Если номер телефона не начинается с '+', добавляет '+' в начало.
 * @param {string} phone - Номер телефона для форматирования.
 * @returns {string} Отформатированный номер телефона.
 */
export const formatPhoneNumber = (phone: string): string => {
  return phone[0] === '+'
    ? phone.replace(/[\s()-]/g, '')
    : `+${phone.replace(/[\s()-]/g, '')}`;
};
