import { ResponseError } from '@modules/main';
import { BUS_SEARCH_STEPS } from '@modules/busTickets/constants';
import { setGeneralBusesSort } from '@modules/busTickets/utils';
import { combineReducers } from 'redux';
import { createAction, createReducer } from 'redux-act';
import {
  GetTrainRoutePayload,
  OpenTicketPayload,
  TrainDirectionType,
  TrainSearchPayload,
} from '@modules/trainTickets/types';
import {
  GetBusesTicketsListDto,
  Bus,
  BusFilter,
  BusRaceDetails,
  GetBusRoutePayload,
  BusSearchRequestStatus,
} from '@modules/busTickets/types';
import { GetTrainRouteDto } from '@modules/trainTickets/dto/GetTrainRouteDto';

/** loading statuses */
export const setBusSearchRequestState = createAction<{
  errors?: ResponseError[] | null;
  status: BusSearchRequestStatus;
}>('@@busTickets/trainSearchRequestState');

// car scheme
export const setCarSchemeRequestState = createAction<{
  carSchemeStatus: BusSearchRequestStatus;
  errors?: ResponseError[] | null;
}>('@@busTickets/setCarSchemeRequestState');

// bus request statuses
export const busSearchRequest = createAction<TrainSearchPayload>(
  '@busTickets/searchRequest',
);
export const busSearchSuccess = createAction<GetBusesTicketsListDto>(
  '@busTickets/searchSuccess',
);
export const busSearchSuccessFilter = createAction<GetBusesTicketsListDto>(
  '@busTickets/searchSuccessFilter',
);

export const busSearchFailure = createAction('@busTickets/searchFailure');

/** purify data */
export const busPurify = createAction('@busTickets/busPurify');
// export const carsPurify = createAction('@trainTickets/carsPurify');

/** set bus sort */
export const setBusSort = createAction<
  'PRICE' | 'TIME' | 'TIME_ARRIVAL' | 'TIME_DEPARTURE '
>('@busTickets/setBusSort');

/** set search step */
export const setSearchStep = createAction<keyof typeof BUS_SEARCH_STEPS>(
  '@@busTickets/setSearchStep',
);

export const setPassengersCounters = createAction<[number, number, number]>(
  '@@busTickets/setPassengersCounters',
);

/** list of buses */
const list = createReducer({}, null as unknown as GetBusesTicketsListDto);
list.on(busSearchSuccess, (_, payload) => payload);

/** filtered list of trains */
const filteredList = createReducer(
  {},
  null as unknown as GetBusesTicketsListDto,
);
filteredList.on(busSearchSuccess, (_, payload) => payload);

filteredList.on(busSearchSuccessFilter, (_, payload) => payload);
filteredList.on(setBusSort, (state, sortBy) => {
  const sortedBuses: Bus[] = setGeneralBusesSort(
    state?.busesPerRoutes[0]?.buses,
    sortBy,
  );
  let sortedBusesBack: Bus[] = [];
  if (state?.busesPerRoutes?.length > 1) {
    sortedBusesBack = setGeneralBusesSort(
      state?.busesPerRoutes[1]?.buses,
      sortBy,
    );
  }
  return {
    ...state,
    busesPerRoutes: [
      {
        ...state?.busesPerRoutes[0],
        buses: sortedBuses,
      },
      {
        ...state?.busesPerRoutes[1],
        buses: sortedBusesBack,
      },
    ],
  };
});

/** open trains */
export const openBusHandler = createAction<OpenTicketPayload>(
  '@busTickets/openBusHandler',
);

export const openBusBackward = createAction<OpenTicketPayload>(
  '@busTickets/openBusBackward',
);

/** train forward */
export const openBusForward = createAction<OpenTicketPayload>(
  '@busTickets/openBusForward',
);
export const setBusForward = createAction<Bus>('@busTickets/setBus');
export const setBedClothesForward = createAction<boolean | null>(
  '@busTickets/setBedClothesForward',
);

const busForwardDir = createReducer({}, null as unknown as Bus);
const busIndexForward = createReducer({}, null as unknown as number);

busIndexForward.on(openBusForward, (_, payload) => payload.index);
busForwardDir.on(setBusForward, (_, payload) => payload);

/** train backward */
const busIndexBackward = createReducer({}, null as unknown as number);
export const setBusBackward = createAction<Bus>('@busTickets/setBackBus');

const busBackwardDir = createReducer({}, null as unknown as Bus);
busIndexBackward.on(openBusBackward, (_, payload) => payload.index);
// busIndexBackward.on(carsPurify, (_, payload) => null as any);
busBackwardDir.on(setBusBackward, (_, payload) => payload);

export const setBusFilter = createAction<BusFilter[]>(
  '@busTickets/setBusFilter',
);

// export const resetBusFilter = createAction<{
//   value: null | string;
//   type: TrainDirectionType;
// }>('@busTickets/resetBusFilter');
export const setPricesBusFilter = createAction<{
  values: number[];
  type: TrainDirectionType;
}>('@busTickets/setPricesBusFilter');
export const setTimeBusFilter = createAction<{
  timeType: 'from' | 'to';
  values: number[];
  type: TrainDirectionType;
}>('@busickets/setTimeBusFilter');

export const setCarrierBusFilter = createAction<{
  values: { [key: string]: boolean };
  type: TrainDirectionType;
}>('@busTickets/setCarrierBusFilter');

export const setStationBusFilter = createAction<{
  values: any;
  type: TrainDirectionType;
}>('@busTickets/setStationBusFilter');

export const setTagFilter = createAction<{
  tagType: string;
}>('@busTickets/setTagFilter');

export const setDurationBusFilter = createAction<{
  values: number[];
  type: TrainDirectionType;
}>('@busTickets/setDurationBusFilter');

export const BusSearchState: any = {
  currentSearchStep: 'TICKET_FORWARD',

  sortBy: 'TIME_DEPARTURE',
  passengers: [1, 0, 0],
  filter: [],
};

const busSearch = createReducer({}, BusSearchState);
busSearch.on(setPassengersCounters, (state, payload) => {
  return { ...state, passengers: payload };
});
busSearch.on(busPurify, () => BusSearchState);
busSearch.on(setBusSearchRequestState, (state, payload) => {
  return { ...state, ...payload };
});
busSearch.on(setBusSort, (state, payload) => {
  return { ...state, sortBy: payload };
});
busSearch.on(setSearchStep, (state, payload) => {
  return { ...state, currentSearchStep: payload };
});

busSearch.on(setBusFilter, (state, payload) => {
  return {
    ...state,
    filter: payload,
  };
});
// busSearch.on(resetBusFilter, (state, payload) => {
//   let filter: TrainFilter;
//   if (payload.type === 'forward') {
//     filter = state.filter[0];
//   } else {
//     filter = state.filter[1];
//   }

busSearch.on(setPricesBusFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: BusFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    currFilter.prices.max === payload.values[1] &&
    currFilter.prices.min === payload.values[0];

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      prices: isDefault,
    },
    values: {
      ...currFilter.values,
      prices: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

busSearch.on(setCarrierBusFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: BusFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    Object.entries(payload.values).find(([_, val]) => !val) === undefined;

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      carriers: isDefault,
    },
    values: {
      ...currFilter.values,
      carriers: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

busSearch.on(setStationBusFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: BusFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };

  const isDefault = payload.values
    .map((x: any) => Object.values(x).some((y) => y === true))
    .some((x: boolean) => x === true);

  const stations = currFilter.stations.map((el, key) => {
    return {
      ...el,
      stations: el.stations.map((station) => {
        return { ...station, checked: payload.values[key][station.code] };
      }),
    };
  });

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      stations: isDefault,
    },
    values: {
      ...currFilter.values,
      stations: stations,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

busSearch.on(setDurationBusFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: BusFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  const isDefault =
    currFilter.busDurationsList[0].min === payload.values[0] &&
    currFilter.busDurationsList[0].max === payload.values[1];

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      busDurations: isDefault,
    },
    values: {
      ...currFilter.values,
      busDurations: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

busSearch.on(setTimeBusFilter, (state, payload) => {
  const isForward = payload.type === 'forward';
  const stateFilter = [...state.filter];
  let currFilter: BusFilter = isForward
    ? { ...stateFilter[0] }
    : { ...stateFilter[1] };
  currFilter.times[0][payload.timeType].values = payload.values;
  const isDefault = currFilter.times.reduce((prev, next) => {
    return (
      prev &&
      next.from.min === next.from.values[0] &&
      next.from.max === next.from.values[1] &&
      next.to.min === next.to.values[0] &&
      next.to.max === next.to.values[1]
    );
  }, true);

  currFilter = {
    ...currFilter,
    isDefault: {
      ...currFilter.isDefault,
      times: isDefault,
    },
    values: {
      ...currFilter.values,
      times: payload.values,
    },
  };

  if (isForward) {
    stateFilter[0] = currFilter;
  } else {
    stateFilter[1] = currFilter;
  }

  return {
    ...state,
    filter: stateFilter,
  };
});

/** search steps */
export const setBusStep = createAction<{
  step: keyof typeof BUS_SEARCH_STEPS;
  needClear: boolean;
}>('@busTickets/setBusStep');

const busRoute = createReducer({}, null as unknown as BusRaceDetails);
const busSort = createReducer({}, null as unknown as string);
busSort.on(setBusSort, (_, payload) => payload);

export const getBusRoute = createAction<GetBusRoutePayload>(
  '@trainTickets/getBusRoute',
);
export const setBusRoute = createAction<BusRaceDetails>(
  '@trainTickets/setBusRoute',
);

export const clearBusRoute = createAction('@trainTickets/clearTrainRoute');
busRoute.on(setBusRoute, (state, payload) => {
  return { ...state, ...payload };
});
busRoute.on(clearBusRoute, (_, payload) => null as unknown as BusRaceDetails);

const busForward = combineReducers({
  index: busIndexForward,
  bus: busForwardDir,
});

const busBackward = combineReducers({
  index: busIndexBackward,
  bus: busBackwardDir,
});

export default combineReducers({
  list: list,
  filteredList: filteredList,
  busBack: busBackward,
  busForward: busForward,
  busSearch,
  busRoute,
  busSort,
});
