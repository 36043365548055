import React from 'react';
import styled from 'styled-components';
import { ButtonProps } from './types';
import PrimaryButton from './Primary';
import OutlinedButton from './Outlined';
import { useMediaQuery } from 'react-responsive';
import { postSessionLogHandler } from '@modules/logs/duck';
import { useDispatch } from 'react-redux';
import { UserActionTypes } from '@modules/logs/types';

const Button: React.FC<ButtonProps> = ({
  type,
  htmlType,
  onClick,
  loggingData,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loggingData?.screen) {
      dispatch(
        postSessionLogHandler({
          eventType: UserActionTypes['Click'],
          screen: loggingData?.screen,
          actionId: loggingData?.actionId,
          value: loggingData?.value,
        }),
      );
    }
    if (onClick) {
      onClick(event);
    }
  };

  const compoundedProps = {
    ...props,
    type: htmlType,
    isMobile: isMobile,
    onClick: handleClick,
  };
  return type === 'outlined' ? (
    <OutlinedButton {...compoundedProps} />
  ) : (
    <PrimaryButton {...compoundedProps} />
  );
};

export default styled(Button)``;
