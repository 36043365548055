import React from 'react';
import styled from 'styled-components';
import Head from './Head';
import Body from './Body';
import { MonthProps } from './types';

const Wrapper = styled.div`
  padding: 10px;
`;

const Month: React.FC<MonthProps> = (props) => {
  return (
    <Wrapper>
      <Head value={props.value} />
      <Body days={props.days} />
    </Wrapper>
  );
};

export default Month;
