import React from 'react';
import { SegmentListProps, TransferProps } from '../../flightsSearch/TicketList/Flight/types';
import styled from 'styled-components';
import SegmentInFlightList from '@components/common/FlightSegment/SegmentInFlightList';

const Wrapper = styled.div`
  & > div {
    margin-bottom: 22px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const SegmentList: React.FC<SegmentListProps> = ({
  segments,
  stops,
  price,
  features,
}) => {
  return (
    <Wrapper>
      {segments.map((segment, key) => {
        const stopProps: TransferProps = stops[key] && {
          ...stops[key],
          isDifferentAirports:
            segments[key + 1] &&
            segment.to.code !== segments[key + 1].from.code,
          isDifferentTerminals:
            segments[key + 1] &&
            segment.to.terminal !== segments[key + 1].from.terminal,
        };
        return (
          <SegmentInFlightList
            key={key}
            {...segment}
            stop={stopProps}
            price={price}
            features={features}
          />
        );
      })}
    </Wrapper>
  );
};

export default SegmentList;
