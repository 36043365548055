import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { InfoBlock, FareCondition } from '@components/common';
import { TariffProps } from '../types';
import CheckedIconSrc from '@assets/media/tariff-features/check.svg';
import CrossIconSrc from '@assets/media/tariff-features/cross.svg';
import RubleIconSrc from '@assets/media/tariff-features/ruble.svg';
import { ReactComponent as InfoIcon } from '@assets/media/info-icon.svg';
import { useOrderPosition } from '../useItemProps';
import { FareFamily, getRoutes } from '@modules/orders';
import { Helper } from '@utils';

import baggageIncluded from '@assets/media/tariff-features/baggageIncluded.svg';
import baggageAtCharge from '@assets/media/tariff-features/baggageAtCharge.svg';
import baggageNotOffered from '@assets/media/tariff-features/baggageNotOffered.svg';

import carryOnIncluded from '@assets/media/tariff-features/carryOnIncluded.svg';
import carryOnAtCharge from '@assets/media/tariff-features/carryOnAtCharge.svg';
import carryOnNotOffered from '@assets/media/tariff-features/carryOnNotOffered.svg';

import exchangeIncluded from '@assets/media/tariff-features/exchangeIncluded.svg';
import exchangeAtCharge from '@assets/media/tariff-features/exchangeAtCharge.svg';
import exchangeNotOffered from '@assets/media/tariff-features/exchangeNotOffered.svg';

import refundIncluded from '@assets/media/tariff-features/refundIncluded.svg';
import refundAtCharge from '@assets/media/tariff-features/refundAtCharge.svg';
import refundNotOffered from '@assets/media/tariff-features/refundNotOffered.svg';

const Title = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const BlueTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.main};
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;

  & > span {
    display: block;
    margin-left: 5px;
  }
`;

const FeaturesWrapper = styled.div`
  display: block;

  & > div {
    margin-bottom: 12px;
  }
`;

const DetailButton = styled(Text)`
  color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
  padding-top: 15px;
  display: flex;
  align-items: center;

  & > svg {
    width: 15px;
    height: 15px;
    margin-right: 6.5px;
  }
`;

const RowText = styled(Text)`
  white-space: pre-line;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const FareFamilyItem = styled.div`
  margin-bottom: 15px;

  & + & {
    margin-top: 35px;
  }
`;

const CheckIcon = () => <img src={CheckedIconSrc} alt="" />;

const CrossIcon = () => <img src={CrossIconSrc} alt="" />;

const RubleIcon = () => <img src={RubleIconSrc} alt="" />;

function getStatusIcon(
  x: 'Included' | 'NotOffered' | 'AtCharge',
  type: string,
) {
  switch (x) {
    case 'Included':
      if (type === 'Baggage') {
        return <img src={baggageIncluded} alt="" height={18} width={18} />;
      }
      if (type === 'Refund') {
        return <img src={refundIncluded} alt="" height={18} width={18} />;
      }
      if (type === 'CarryOn') {
        return <img src={carryOnIncluded} alt="" height={18} width={18} />;
      }
      if (type === 'Exchange') {
        return <img src={exchangeIncluded} alt="" height={18} width={18} />;
      }
      break;
    case 'NotOffered':
      if (type === 'Baggage') {
        return <img src={baggageNotOffered} alt="" height={18} width={18} />;
      }
      if (type === 'Refund') {
        return <img src={refundNotOffered} alt="" height={18} width={18} />;
      }
      if (type === 'CarryOn') {
        return <img src={carryOnNotOffered} alt="" height={18} width={18} />;
      }
      if (type === 'Exchange') {
        return <img src={exchangeNotOffered} alt="" height={18} width={18} />;
      }
      break;
    case 'AtCharge':
      if (type === 'Baggage') {
        return <img src={baggageAtCharge} alt="" height={18} width={18} />;
      }
      if (type === 'Refund') {
        return <img src={refundAtCharge} alt="" height={18} width={18} />;
      }
      if (type === 'CarryOn') {
        return <img src={carryOnAtCharge} alt="" height={18} width={18} />;
      }
      if (type === 'Exchange') {
        return <img src={exchangeAtCharge} alt="" height={18} width={18} />;
      }
      break;
  }
}

const Tariff: React.FC<TariffProps> = (props) => {
  const fareFamilies = React.useMemo(() => {
    return props.fareFamilies ? getUniqueFareFamilies(props.fareFamilies) : [];
  }, [props]);

  const fareFamilyNames = React.useMemo(() => {
    if (!props.fareFamilies) return '';
    const uniqueFareFamilies = getUniqueFareFamilies(props.fareFamilies);
    return uniqueFareFamilies.length > 1
      ? uniqueFareFamilies.map((fareFamily) => fareFamily.name).join(' + ')
      : uniqueFareFamilies[0]?.name || '';
  }, [props.fareFamilies]);

  const price = Helper.formatPrice(props.adultPrice + props.childPrice);

  const [isPopup, setPopup] = React.useState(false);

  const orderPosition = useOrderPosition();

  const fareFamilyItem = (fareFamily: FareFamily) => {
    return (
      <FareFamilyItem>
        <Head>
          <Title>{fareFamily?.name}</Title>
        </Head>
        <FeaturesWrapper>
          {fareFamily?.features?.map((item, index) => (
            <Feature key={index}>
              {getStatusIcon(item.applicability as any, item.type)}
              <RowText>
                {getTitle(item.type)}
                {item.descriptionRus}
              </RowText>
            </Feature>
          ))}
        </FeaturesWrapper>
        <DetailButton onClick={() => setPopup(true)}>
          <InfoIcon />
          Правила тарифа
        </DetailButton>

        <FareCondition
          open={isPopup}
          onClose={() => setPopup(false)}
          fare={fareFamily?.name || ''}
          routes={getRoutes(orderPosition)}
        />
      </FareFamilyItem>
    );
  };

  return (
    <InfoBlock className={props.className}>
      <Head>
        <Title>Тариф перелета: {fareFamilyNames}</Title>
        <BlueTitle>{price}</BlueTitle>
      </Head>
      {fareFamilies.map((fareFamily, index) => {
        return fareFamilyItem(fareFamily);
      })}
    </InfoBlock>
  );
};

function getTitle(type: string) {
  switch (type) {
    case 'Baggage':
      return 'Багаж: ';
    case 'CarryOn':
      return 'Ручная кладь: ';
    case 'Exchange: ':
      return 'Обмен: ';
    case 'Refund':
      return 'Возврат: ';
    default:
      return '';
  }
}

function getUniqueFareFamilies(fareFamilies: FareFamily[]) {
  const uniqueNames = new Set();
  return fareFamilies.filter((fareFamily) => {
    if (uniqueNames.has(fareFamily.name)) {
      return false;
    }
    uniqueNames.add(fareFamily.name);
    return true;
  });
}

export default Tariff;
