import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import newSMSBlock from '@assets/media/addictional-services/newSmsBlock.png';
import newReceiptBlock from '@assets/media/addictional-services/newReceiptBlock.png';
import newReturnBlock from '@assets/media/addictional-services/newReturnBlock.png';
import newReturnCircle from '@assets/media/addictional-services/newReturnCircle.svg';
import newReceiptCircle from '@assets/media/addictional-services/newReceiptCircle.svg';
import newSMSCircle from '@assets/media/addictional-services/newSMSCircle.svg';

interface Props {
  text: string;
  type: string;
  onClick: () => void;
  dataCy?: string;
  isLoading: boolean;
}

interface LinkProps {
  isSMS: boolean;
  isReceipt: boolean;
}

const Container = styled.div<LinkProps>`
  background: ${({ isSMS, isReceipt }) =>
    isReceipt ? '#EDF4FF' : isSMS ? '#FFFBE3' : '#F5EDFF'};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 14px 29px 14px 24px;
  width: 350px;
  height: 218px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;

  & > div {
    margin-bottom: 14px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #3c3c3c;
`;

const StyledButton = styled(Button).attrs({ htmlType: 'button' })`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background: #ffe100;
  color: #3c3c3c;
  width: 178px;
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.div`
  width: 200px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #3c3c3c;
  text-align: left;
  margin: 10px 0;
`;

const NewSMSBlock = styled.img.attrs({ src: newSMSBlock })`
  position: absolute;
  right: -50px;
  top: 10px;
  z-index: 100;
`;

const NewReceiptBlock = styled.img.attrs({ src: newReceiptBlock })`
  position: absolute;
  right: -90px;
  top: -20px;
  z-index: 100;
`;

const NewReturnBlock = styled.img.attrs({ src: newReturnBlock })`
  position: absolute;
  right: -65px;
  bottom: 1px;
  z-index: 100;
`;

const NewReturnCircle = styled.img.attrs({ src: newReturnCircle })`
  position: absolute;
  right: 0;
  top: 0;
`;

const NewReceiptCircle = styled.img.attrs({ src: newReceiptCircle })`
  position: absolute;
  right: 0;
  top: 0;
`;

const NewSMSCircle = styled.img.attrs({ src: newSMSCircle })`
  position: absolute;
  right: 0;
  top: 0;
`;

export default function AdditionalOfferBlock(props: Props) {
  const { text, dataCy, type, isLoading } = props;

  return (
    <Container isSMS={type === 'SMS'} isReceipt={type === 'Receipt'}>
      <Heading>
        {props.type === 'Receipt'
          ? 'Справка о стоимости'
          : props.type === 'SMS'
          ? 'SMS-информирование'
          : 'Гарантия возврата'}
      </Heading>
      <Flex>
        {props.type === 'Receipt' ? (
          <NewReceiptBlock />
        ) : props.type === 'SMS' ? (
          <NewSMSBlock />
        ) : (
          <NewReturnBlock />
        )}
        {props.type === 'Receipt' ? (
          <NewReceiptCircle />
        ) : props.type === 'SMS' ? (
          <NewSMSCircle />
        ) : (
          <NewReturnCircle />
        )}
        <StyledText>{text}</StyledText>
      </Flex>
      <StyledButton
        data-cy={dataCy || ''}
        onClick={props.onClick}
        isLoading={props.isLoading}
      >
        {!props.isLoading ? 'Добавить услугу' : ''}
      </StyledButton>
    </Container>
  );
}
