import React from 'react';
import styled from 'styled-components';

import { City } from '@modules/simpleSearch';
import { take } from 'lodash';
import { SuggestionItem } from './suggestion-item';
import { FocusType } from '../useFormFocus';

interface SuggestionWrapperProps {
  position: FocusType;
}

const SuggestionWrapper = styled.div<SuggestionWrapperProps>`
  ${({ position }) => {
    switch (position) {
      case 'origin':
        return 'left: 0;';
      case 'destination':
        return 'right: 0;';
      default:
        return 'display: none;';
    }
  }}
  position: absolute;
  top: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  z-index: 100;
  width: 330px;
  max-height: 335px;
  box-shadow:
    0px 0px 5px rgba(0, 0, 0, 0.05),
    0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 3px;
    height: 1px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(72, 114, 242);
    border-radius: 10px;
  }
  @media (max-width: 1024px) {
    width: 347px;
    top: 50px;
  }

  @media (max-width: 767px) {
    width: 347px;

    top: ${({ position }) => (position === 'origin' ? 60 : 130)}px;
    left: 0;
  }
`;
const CitySelector = styled.ul`
  border-bottom: 1px solid #f2f2f2;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

interface SuggesionProps {
  currentFocus: FocusType;
  onSelect(x: City, focus: FocusType): void;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  items: City[];
  suggestionIndex: null | number;
  setSuggestionIndex(index: number | null): void;
  searchPanelType?: 'avia' | 'train' | 'bus';
}

export const Suggestion: React.FC<SuggesionProps> = ({
  currentFocus,
  onSelect,

  ...props
}) => {
  const items = props.items.map((item, key) => (
    <SuggestionItem
      indexNumber={key}
      focus={currentFocus}
      data={item}
      onSelect={onSelect}
      key={item.id}
      active={key === props.suggestionIndex}
      setSuggestionIndex={props.setSuggestionIndex}
      searchPanelType={props.searchPanelType}
    />
  ));

  React.useEffect(
    () => () => {
      props.setSuggestionIndex(null);
    },
    [],
  );

  return (
    <SuggestionWrapper position={currentFocus} {...props}>
      <CitySelector>{items}</CitySelector>
    </SuggestionWrapper>
  );
};
