import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Text } from '@components/ui';
import { useOrderPosition } from '../../../useItemProps';
import { Helper } from '@utils';
import ActionSelector from '../../Head/ActionSelector';

const Wrapper = styled.div`
  padding: 0px 20px;
  @media (max-width: 767px) {
    padding: 0px 14px;
  }
`;

const TitleText = styled(Text)`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const GrayText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;
`;

const BlueText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: rgba(72, 114, 242, 1);
  font-weight: 600;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    margin-bottom: 5px;
  }
  & > :last-child {
    margin-bottom: 15px;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 5px 20px;
  height: 1px;
  width: 100%;
  background: #dcdcdc;

  @media (max-width: 1024px) {
    margin-block: 14px;
  }
`;

const Title: React.FC = () => {
  const props = useOrderPosition();
  const dateString = moment(props.createdDate).format('DD.MM.YYYY');
  const isActionsAvailable = (props.availableActions || []).length > 0;

  const stillUtc = moment.utc(props.cancellationTime).toDate();
  const localCancellationTime = moment(stillUtc).local();
  const duration = localCancellationTime.diff(moment(), 'minutes');

  return (
    <Wrapper>
      <TitleText>
        Бронь №{props.bookingCode} от {dateString}
      </TitleText>

      <Bottom>
        <GrayText>
          Cтатус позиции: <BlueText>{props.status?.description}</BlueText>
        </GrayText>
        <GrayText>
          Статус оплаты: <BlueText>{props.paymentStatus?.description}</BlueText>
        </GrayText>
        {duration > 0 && (props.availableActions || []).includes('PAYMENT') && (
          <GrayText>
            Время для оплаты:{' '}
            <BlueText>{Helper.formatDuration(duration)}</BlueText>
          </GrayText>
        )}
      </Bottom>
      {isActionsAvailable && <ActionSelector />}
      <Divider />
    </Wrapper>
  );
};
export default Title;
