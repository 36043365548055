import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import PassengersBlock from '@components/booking/PassengersBlock/PassengersBlock';

interface Props {
  setIsShowError: Dispatch<SetStateAction<boolean>>;
}

const AviaPassengersBlockContainer: React.FC<Props> = ({ setIsShowError }) => {
  const frequentFlyerAirlines = useSelector(
    (state: ApplicationState) => state.booking.ticket.frequentFlyerAirlines
  );

  return (
    <PassengersBlock
      isAvia
      frequentFlyerAirlines={frequentFlyerAirlines}
      setIsShowError={setIsShowError}
    />
  );
};

export default AviaPassengersBlockContainer;
