import React from 'react';
import styled from 'styled-components';
import { Title, GrayText, Paragraph } from '@components/mainPage/shared';
import { Button } from '@components/ui';
import { openAuthModal, AUTH_MODAL_STATUSES } from '@modules/ui';
import { useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { ReactComponent as BackgroundSVG1 } from '@assets/media/booking/authBackground1.svg';
import { ReactComponent as BackgroundSVG2 } from '@assets/media/booking/authBackground2.svg';

const Wrapper = styled.div`
  padding: 25px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: relative;
  margin-top: 45px;

  @media (max-width: 767px) {
    padding: 20px 14px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 35px;
  }
`;

const StyledButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  padding: 11px 20px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-weight: 300;
  @media (max-width: 767px) {
    padding: 7px 16px;
    width: 213px;
    height: 32px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const ModalButton = styled(NavLink)`
  text-decoration: none;
  color: white;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;

  ${Title} {
    display: block;
    margin-bottom: 10px;
  }

  ${GrayText} {
    display: block;
    margin-bottom: 8px;
  }

  ${Paragraph} {
    display: block;
    margin-bottom: 26px;
  }
  @media (max-width: 767px) {
    ${Title} {
      font-size: 16px;
      line-height: 20px;
    }
    ${Paragraph} {
      font-size: 12px;
      line-height: 16px;
      width: 287px;
    }
  }
`;

const BackgroundRight = styled(BackgroundSVG1)`
  position: absolute;
  right: 0;
  bottom: 27px;
  z-index: 1;
  @media (max-width: 767px) {
    bottom: 0;
  }
`;

const BackgroundLeft = styled(BackgroundSVG2)`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }
`;

const AuthorizationBlock: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <BackgroundLeft />
      <BackgroundRight />
      <Content>
        <Title>Вы уже зарегистрированы?</Title>
        <Paragraph>
          Войдите в Личный кабинет и выбирайте данные из раздела Мои пассажиры.
        </Paragraph>

        <ModalButton
          to="/"
          onClick={(e) => {
            e.preventDefault();
            dispatch(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
          }}
        >
          <StyledButton> Войти в Личный кабинет</StyledButton>
        </ModalButton>
      </Content>
    </Wrapper>
  );
};

export default AuthorizationBlock;
