import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';
import iconFlightReturnRed from '@assets/media/flight-search/iconFlightReturnRed.svg';
import terminalIconRed from '@assets/media/flight-search/terminalIconRed.svg';
import Chip from '@components/ui/Chip/Chip';
import { TransferProps } from '@components/flightsSearch/TicketList/Flight/types';
import { Text } from '@components/ui';
import { useMediaQuery } from 'react-responsive';

interface Props {
  hasTechLanding: boolean;
  techLandingsTitle: string;
  stop?: TransferProps;
  fromTerminal: string;
  toTerminal: string;
  airlineName: string;
  hasAdditionalInfo: boolean;
}

const DescriptionBlock = styled.div`
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0;

  & > div {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
`;

const DescriptionItem = styled.div`
  display: flex;
  gap: 4px;
`;

const MutedText = styled(Text)`
  color: #737373;
`;

const DescriptionAdditionalItem = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const AdditionalIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const AviaAdditionalInfo: React.FC<Props> = (props) => {
  const {
    hasTechLanding,
    techLandingsTitle,
    stop,
    fromTerminal,
    toTerminal,
    airlineName,
    hasAdditionalInfo,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <DescriptionBlock>
      {(fromTerminal || toTerminal) && (
        <DescriptionItem>
          <MutedText size={isMobile ? 'small' : 'normal'}>Терминалы:</MutedText>
          <Text size={isMobile ? 'small' : 'normal'}>
            {fromTerminal ? `вылет — ${fromTerminal}` : ''}
            {toTerminal ? `, прилёт — ${toTerminal}` : ''}
          </Text>
        </DescriptionItem>
      )}
      <DescriptionItem>
        <MutedText size={isMobile ? 'small' : 'normal'}>
          Рейс выполняет:
        </MutedText>
        <Text size={isMobile ? 'small' : 'normal'}>{airlineName}</Text>
      </DescriptionItem>
      {hasAdditionalInfo && (
        <DescriptionAdditionalItem>
          {hasTechLanding && (
            <Tippy content={techLandingsTitle} theme={'light'}>
              <Chip
                icon={<AdditionalIcon src={techInfoIcon} />}
                bgColor={'red'}
                label={'Техническая остановка'}
                adaptiveTextColor
              />
            </Tippy>
          )}

          {stop?.isDifferentAirports && (
            <Tippy content={'Смена аэропорта'} theme={'light'}>
              <Chip
                icon={<AdditionalIcon src={iconFlightReturnRed} />}
                bgColor={'red'}
                label={'Смена аэропорта'}
                adaptiveTextColor
              />
            </Tippy>
          )}
          {!stop?.isDifferentAirports && stop?.isDifferentTerminals && (
            <Tippy content={'Смена терминала'} theme={'light'}>
              <Chip
                icon={<AdditionalIcon src={terminalIconRed} />}
                bgColor={'red'}
                label={'Смена терминала'}
                adaptiveTextColor
              />
            </Tippy>
          )}

          {/*TODO: расскомментировать после добавления функционала на бэке*/}
          {/*</Tippy>*/}
          {/*<Tippy content={'Регистрация багажа'} theme={'light'}>*/}
          {/*  <Chip*/}
          {/*    icon={<AdditionalIcon src={luggageIconRed} />}*/}
          {/*    bgColor={'red'}*/}
          {/*    label={'Регистрация багажа'}*/}
          {/*    adaptiveTextColor*/}
          {/*  />*/}
          {/*</Tippy>*/}
        </DescriptionAdditionalItem>
      )}
    </DescriptionBlock>
  );
};

export default AviaAdditionalInfo;
