import React, { ReactElement } from 'react';
import TrainPaymentBlock from '@components/train/Booking/PaymentBlock';

const FormCheckboxes = (): ReactElement => (
  <div>
    <TrainPaymentBlock withCovid={true} withTariffs={true} />
  </div>
);

export default FormCheckboxes;
