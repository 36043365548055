import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Text } from '@components/ui';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CurveIcon } from '@assets/media/loader/curve.svg';
import { ReactComponent as Icon } from '@assets/media/redirect-payment/loader-icon.svg';
import { payOrderRequest } from '@modules/orders';
import { Hooks } from '@utils';
import { ApplicationState } from '@modules/index';

const Wrapper = styled.div`
  height: calc(100vh - 116px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`;

const spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  position: relative;
  animation: ${spin} 1s ease-in-out infinite;
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Description = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  display: block;
  margin-top: 12px;
`;

const Curve = styled(CurveIcon)`
  position: absolute;
  top: 0;
  right: 0;
`;

export default function () {
  const location = useLocation();
  const dispatch = useDispatch();

  Hooks.useBlockBodyScroll();
  const user = useSelector((x: ApplicationState) => x.user);

  React.useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(location.search));
    const orderId = params.get('orderId');
    const orderPositionIds = params.getAll('orderPositionId');
    const calculationId = params.get('calculationId');

    if (orderId && orderPositionIds.length > 0) {
      dispatch(
        payOrderRequest({
          OrderNumber: Number(orderId),
          OrderPositionIds: orderPositionIds,
        })
      );
    } else if (orderId && calculationId) {
      dispatch(
        payOrderRequest({
          OrderNumber: Number(orderId),
          SupportTicketCalculationId: calculationId,
        })
      );
    } else if (orderId) {
      dispatch(payOrderRequest({ OrderNumber: parseInt(orderId) }));
    }
  }, [location]);
  return (
    <Wrapper>
      <LoaderWrapper>
        <Circle>
          <Curve />
        </Circle>
        <IconStyled />
      </LoaderWrapper>
      <Description>Переходим на страницу оплаты</Description>
    </Wrapper>
  );
}
