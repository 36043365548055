import { all, takeLatest, select, call, put } from 'typed-redux-saga';
import {
  getAgentBillByOrderIdRequest,
  getAgentBillByOrderIdSuccess,
  getAgentBillBySamoNumberRequest,
  getAgentBillByTteNumberRequest,
  getAgentBillSuccess,
  getAgentCreditHistoryRequest,
  getAgentCreditHistorySuccess,
  getAgentMainInformation,
  getAgentPaymentHistoryFailure,
  getAgentPaymentHistoryRequest,
  getAgentPaymentHistorySuccess,
  getAgentReport,
  getAgentReportResponse,
  setAgentBalance,
  setAgentMainInfo,
  setAgentNotPaidOrders,
  setAgentOverPaidOrders,
  getAgentOverPaidOrders,
  getAgentNotPaidOrders,
  getAgentReportFailure,
  getAquiringCommission,
  getAquiringCommissionSuccess,
  addAgentRequest,
  addAgentSuccess,
  addAgentFailure,
  getAgentDeposit,
  getAgentDepositSuccess,
  getAgentDepositList,
  getAgentDepositListSuccess,
  getAgentInfo,
  getAgentInfoSuccess,
} from '@modules/agents/agent-finance/duck';
import {
  getModalErrorText,
  showModalError,
} from '@modules/orders/slices/detailOrderSlice';
import * as AgentsManager from './Manager';
import { getAgentSiginState } from '@modules/agents/agent-finance/selectors';
import { setLKLoadingStatus } from '@modules/personalArea';
import {
  AgentBalance,
  AgentFullInfo,
  AgentNotPaidOrder,
  AgentOverPaymentOrder,
  GetAgentPaymentPayload,
  GeneratedAddRequestAgentRequest,
} from '@modules/agents/agent-finance/types';
import { Action } from 'redux-act';
import { UserCookie } from '@services/UserCookies';
import { BookingManager } from '@modules/booking';

function* getAgentMainInformationRunner() {
  yield* put(setLKLoadingStatus(true));
  const agent: any = yield* select(getAgentSiginState);

  try {
    if (agent) {
      const agentInfo = yield* call(AgentsManager.getAgentInfo, agent.agentId);
      yield* put(setAgentMainInfo(agentInfo as AgentFullInfo));

      const [balance, overpaid, notPaid] = yield* all([
        call(AgentsManager.getAgentsBalance, agent.agentSamoCode),
        call(AgentsManager.getAgentOverPaidOrders, agent.agentSamoCode),
        call(AgentsManager.getAgentNotPaidOrders, agent.agentSamoCode),
      ]);
      yield* put(setAgentBalance(balance as AgentBalance));
      yield* put(
        setAgentOverPaidOrders((overpaid as AgentOverPaymentOrder[]) || null)
      );
      yield* put(setAgentNotPaidOrders(notPaid as AgentNotPaidOrder[]));

      yield* put(setLKLoadingStatus(false));
    }
  } catch (err) {
    yield* put(setLKLoadingStatus(false));
  }
}

function* getAgentCreditHistory({
  payload,
}: Action<{
  date: string;
  agentId: string;
  sortField?: string;
  sortAsc?: boolean;
}>) {
  try {
    //@ts-ignore
    const data = yield* call<any>(
      AgentsManager.getAgentCreditHistory,
      payload.agentId,
      payload.date,
      payload?.sortField,
      payload?.sortAsc
    );

    yield* put(getAgentCreditHistorySuccess(data));
  } catch (err) {
    yield put(getModalErrorText(err.message));
    yield put(showModalError(true));
  }
}

function* getAgentBillByOrderIdSaga(action: Action<string>) {
  try {
    yield call(
      BookingManager.getAgentOrderBillWithoutTrackPromise,
      action.payload,
      UserCookie.token
    );

    yield put(getAgentBillByOrderIdSuccess());
  } catch (err) {
    yield put(getAgentBillByOrderIdSuccess());

    // console.log(err);
  }
}

function* getAgentReportSaga(
  action: Action<{
    agentId: string;
    date: string;
    ReportType: string;
  }>
) {
  try {
    yield call(
      AgentsManager.getAgentReport,
      action.payload.agentId,
      action.payload.date,
      action.payload.ReportType,
      UserCookie.token
    );
    yield* put(getAgentReportResponse());
  } catch (err) {
    yield* put(getAgentReportFailure(true));
  }
}

function* getAgentPaymentHistorySaga(action: Action<GetAgentPaymentPayload>) {
  try {
    const data = yield* call(
      AgentsManager.getAgentPaymentHistory,
      action.payload
    );

    yield* put(getAgentPaymentHistorySuccess(data));
  } catch (err) {
    yield put(getAgentPaymentHistoryFailure());
  }
}

function* getAgentOverPaidOrdersSaga(
  action: Action<{
    partnerId?: string;
    sortField?: string;
    sortAsc?: boolean;
  }>
) {
  const { payload } = action;

  try {
    //@ts-ignore
    const data = yield* call<any>(
      AgentsManager.getAgentOverPaidOrders,
      payload?.partnerId,

      payload?.sortField,
      payload?.sortAsc
    );

    yield* put(
      setAgentOverPaidOrders((data as AgentOverPaymentOrder[]) || null)
    );
  } catch (err) {
    //
  }
}

function* getAgentNotPaidOrdersSaga(
  action: Action<{
    partnerId?: string;
    sortField?: string;
    sortAsc?: boolean;
  }>
) {
  const { payload } = action;

  try {
    //@ts-ignore
    const data = yield* call<any>(
      AgentsManager.getAgentNotPaidOrders,
      payload?.partnerId,

      payload?.sortField,
      payload?.sortAsc
    );

    yield* put(setAgentNotPaidOrders(data as AgentNotPaidOrder[]));
  } catch (err) {
    //
  }
}

function* getAgentBillByNumberSaga(action: Action<string>) {
  try {
    yield call(
      BookingManager.getAgentBillBySamoId,
      action.payload,
      UserCookie.token
    );
    yield* put(getAgentBillSuccess());
  } catch (err) {
    yield put(showModalError(true));
    yield put(getModalErrorText(err.message));
  }
}

function* getAgentBillByTteNumberSaga(action: Action<string>) {
  try {
    yield call(
      BookingManager.getAgentBillByTteNumber,
      action.payload,
      UserCookie.token
    );
    yield* put(getAgentBillSuccess());
  } catch (err) {
    yield put(showModalError(true));
    yield put(getModalErrorText(err.message));
  }
}

function* getAquiringCommissionSaga(action: Action<{ AgentId?: string }>) {
  const { payload } = action;

  try {
    const data = yield* call(
      AgentsManager.getAquiringCommission,
      payload?.AgentId
    );

    yield* put(getAquiringCommissionSuccess(data));
  } catch (err) {
    //
  }
}

function* addAgentRequestSaga(action: Action<GeneratedAddRequestAgentRequest>) {
  const { payload } = action;

  try {
    yield* call(AgentsManager.addAgentRequest, payload);
    yield* put(addAgentSuccess(true));
  } catch (err) {
    yield put(getModalErrorText(err.message));

    yield put(addAgentFailure(true));
  }
}

function* getAgentDepositSaga(
  action: Action<{
    AgentId?: string;
    Summ?: number;
  }>
) {
  const { payload } = action;
  try {
    yield call<any, any>(
      //@ts-ignore
      AgentsManager.getAgentDeposit,
      payload?.AgentId,
      payload?.Summ,
      UserCookie.token
    );
    yield* put(getAgentDepositSuccess());
  } catch (err) {
    //
  }
}

function* getAgentDepositListSaga(
  action: Action<{
    AgentId?: string;
    From?: string;
    To?: string;
  }>
) {
  const { payload } = action;
  try {
    const result = yield* call(
      AgentsManager.getDepositList,
      payload?.AgentId,
      payload?.From,
      payload?.To
    );
    yield put(getAgentDepositListSuccess(result));
  } catch (err) {
    //
  }
}

function* getAgentInfoSaga(
  action: Action<{
    PartnerId?: string;
  }>
) {
  const { payload } = action;
  try {
    const result = yield* call(
      AgentsManager.getAgentInfoRequests,
      payload?.PartnerId
    );
    yield* put(getAgentInfoSuccess(result));
  } catch (err) {
    //
  }
}

export default function* rootSaga() {
  yield* all([
    takeLatest(
      getAgentMainInformation.getType(),
      getAgentMainInformationRunner
    ),
    takeLatest(getAgentCreditHistoryRequest.getType(), getAgentCreditHistory),
    takeLatest(
      getAgentBillByOrderIdRequest.getType(),
      getAgentBillByOrderIdSaga
    ),
    takeLatest(getAgentReport.getType(), getAgentReportSaga),
    takeLatest(
      getAgentPaymentHistoryRequest.getType(),
      getAgentPaymentHistorySaga
    ),
    takeLatest(getAgentOverPaidOrders.getType(), getAgentOverPaidOrdersSaga),
    takeLatest(getAgentNotPaidOrders.getType(), getAgentNotPaidOrdersSaga),

    takeLatest(
      getAgentBillBySamoNumberRequest.getType(),
      getAgentBillByNumberSaga
    ),
    takeLatest(
      getAgentBillByTteNumberRequest.getType(),
      getAgentBillByTteNumberSaga
    ),
    takeLatest(getAquiringCommission.getType(), getAquiringCommissionSaga),
    takeLatest(addAgentRequest.getType(), addAgentRequestSaga),
    takeLatest(getAgentDeposit.getType(), getAgentDepositSaga),
    takeLatest(getAgentDepositList.getType(), getAgentDepositListSaga),
    takeLatest(getAgentInfo.getType(), getAgentInfoSaga),
  ]);
}
