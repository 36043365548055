import * as Styled from './styles/Tariffs.styles';
import React from 'react';
import { Text } from '@app/components/ui';

const PenaltyDescription = ({
  tariffType,
}: {
  tariffType: 'regular' | 'charter';
}) => {
  return (
    <div>
      <Styled.TextContainerSmall>
        <Styled.StrongText
          style={{ fontSize: tariffType === 'regular' ? '16px' : '14px' }}
        >
          Политика взимания сервисных сборов Fun&Sun при возврате, помимо
          штрафных санкций перевозчика:
        </Styled.StrongText>
      </Styled.TextContainerSmall>
      <Styled.TextContainerSmall>
        <Styled.StrongText>За возврат:</Styled.StrongText>
      </Styled.TextContainerSmall>
      <Styled.MarkedList>
        <Styled.MarkedListItem>
          <Text>
            Если сумма к возврату составляет 3100 рублей и более, сбор составит
            1900 рублей.
          </Text>
        </Styled.MarkedListItem>
        <Styled.MarkedListItem>
          <Text>
            Если сумма к возврату составляет менее 3100 рублей, сбор составит
            1100 рублей.
          </Text>
        </Styled.MarkedListItem>
        <Styled.MarkedListItem>
          <Text>
            Если сумма к возврату составляет менее 2000 рублей, сбор составит
            750 рублей.
          </Text>
        </Styled.MarkedListItem>
      </Styled.MarkedList>
      <Styled.TextContainerSmall>
        <Styled.StrongText>
          За возврат в случае отмены рейса, изменения расписания:
        </Styled.StrongText>
      </Styled.TextContainerSmall>
      <Styled.MarkedList>
        <Styled.MarkedListItem>
          <Text>
            Если сумма к возврату составляет 3100 рублей и более, сбор составит
            1100 рублей.
          </Text>
        </Styled.MarkedListItem>
        <Styled.MarkedListItem>
          <Text>
            Если сумма к возврату составляет менее 3100 рублей, сбор составит
            500 рублей.
          </Text>
        </Styled.MarkedListItem>
      </Styled.MarkedList>
      <Styled.TextContainer
        style={{ marginBottom: tariffType === 'regular' ? '0px' : 'inherit' }}
      >
        <Styled.StrongText>
          При любом возврате Fun&Sun удерживает сбор в размере 2% от стоимости
          заказа, если билет был оплачен российской картой и 3,6% в случае
          оплаты иностранной картой через Fun&Sun. Сбор Fun&Sun, оплаченный при
          покупке билета, возврату не подлежит.
        </Styled.StrongText>
      </Styled.TextContainer>
    </div>
  );
};

export default PenaltyDescription;
