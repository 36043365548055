import React from 'react';
import styled from 'styled-components';
import { Button } from '@components/ui';
import { useCalendar } from './useCalendar';
import { stickyFooterHide } from '@modules/orders';
import { useDispatch } from 'react-redux';
const Wrapper = styled.div`
  min-height: 71px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.8;
  }
`;

const OutlinedButton = styled(Button).attrs({ type: 'outlined' })`
  width: 90%;
  margin: 0 auto;
  padding: 8px 10px 9px;
  background-color: #4872f2;
  color: #fff;
`;

const Footer: React.FC = () => {
  const { direction, setDate } = useCalendar();
  const dispatch = useDispatch();

  return direction === 'backward' ? (
    <Wrapper>
      <OutlinedButton
        onClick={() => {
          setDate(null, 'backward');
          dispatch(stickyFooterHide(false));
        }}
      >
        Обратный билет не нужен
      </OutlinedButton>
    </Wrapper>
  ) : null;
};

export default Footer;
