import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { useSteps, STEPS } from './useSteps';
import { useRefund } from './useRefund';
import { Helper } from '@utils';
import { ConfirmStepProps } from './types';

const Comment = styled.div``;

const ButtonBlock = styled.div`
  display: flex;
  & > button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
  }
  & > :first-child {
    margin-right: 20px;
  }
`;

const Wrapper = styled.div`
  width: 569px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;

  & > ${Comment} {
    margin-top: 26px;
  }

  & > ${ButtonBlock} {
    margin-top: 30px;
  }
  ${({ theme }) => `
    ${theme.max('767px')} {
      width: 100%;
      & > ${ButtonBlock} {
        flex-direction: column;
        & > button {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  `}
`;

const Confirm: React.FC<ConfirmStepProps> = ({
  actionName,
  onActionConfirm,
}) => {
  const [, setStep] = useSteps();
  const { total, orderNumber, subject } = useRefund();

  return (
    <Wrapper>
      <Text size={18} bold>
        Расчет на {actionName}
      </Text>
      <Comment>
        <Text>
          Подтверждаю {subject} по заказу №{orderNumber}.
          <br />С {actionName}ом в размере {Helper.formatPrice(total)} согласен.
          Подтверждаю проведение {actionName}а по заказу №{orderNumber}. С
          возвратом в размере {Helper.formatPrice(total)} согласен.
          <br /> Вы уверены, что хотите произвести возврат денежных?
        </Text>
      </Comment>
      <ButtonBlock>
        <Button type="outlined" onClick={onActionConfirm}>
          Да, оформить {actionName}
        </Button>
        <Button type="outlined" onClick={() => setStep(STEPS.CALCULATE)}>
          Нет, вернуться к расчету
        </Button>
      </ButtonBlock>
    </Wrapper>
  );
};

export default Confirm;
