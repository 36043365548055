import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FilterIcon } from '@assets/media/train-tickets/filter-icon.svg';

interface ButtonProps {
  onClick(): void;

  counter?: number;
}

const Button = styled.button`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f6f8fa;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 8px;
  font-size: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const Counter = styled.div`
  position: absolute;
  top: 4px;
  right: 5px;
  width: 11px;
  height: 11px;
  background-color: #4872f2;
  border-radius: 50%;
  color: white;
`;

const FilterButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <div>
      <Button onClick={props.onClick}>
        <FilterIcon />
        {!!props?.counter && <Counter>{props.counter}</Counter>}
      </Button>
    </div>
  );
};

export default FilterButton;
