import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { useSteps, STEPS } from '../useSteps';
import ArrowIcon from '../../../../../assets/images/arrow-success';

const Comment = styled.div``;

const Head = styled.div`
  display: flex;
  align-items: center;
  &>: first-child {
    margin-right: 14px;
  }
`;

const ButtonBlock = styled.div`
  display: flex;
  & > button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
  }
  & > :first-child {
    margin-right: 20px;
  }
`;

const Wrapper = styled.div`
  width: 569px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;

  & > ${Comment} {
    margin-top: 26px;
  }

  & > ${ButtonBlock} {
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Refund: React.FC = () => {
  const [, setStep] = useSteps();
  return (
    <Wrapper>
      <Head>
        <Text size={18} bold>
          Упс, ошибка...
        </Text>
        <ArrowIcon />
      </Head>
      <Comment>
        <Text>Что-то пошло не так</Text>
      </Comment>
      <ButtonBlock>
        <Button type="outlined" onClick={() => setStep(STEPS.HIDDEN)}>
          Закрыть обращение
        </Button>
      </ButtonBlock>
    </Wrapper>
  );
};

export default Refund;
