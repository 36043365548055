import React from 'react';
import Tippy from '@tippyjs/react/';
import { RAILWAY_CAR_SERVICES } from '@modules/trainTickets/constants';

export default function RailwayCarService(props: { code: keyof typeof RAILWAY_CAR_SERVICES }) {
  return (
    <Tippy theme="light" content={RAILWAY_CAR_SERVICES[props.code]}>
      <img
        src={require(`@assets/media/train-tickets/services/${props.code}.svg`)}
        alt={props.code}
      />
    </Tippy>
  );
}
