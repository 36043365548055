import { FormikValues } from 'formik';
import moment from 'moment';
import { Passenger } from './interfaces';
import { PlaceOption } from '@components/ui/form/PlaceSelect/interfaces';

const getName = (passenger: FormikValues, index: number): string => {
  if (
    passenger.surname === '' &&
    passenger.name === '' &&
    passenger.middleName === ''
  ) {
    return `Пассажир ${index}`;
  }

  return `${passenger.surname} ${passenger.name} ${passenger.middleName}`;
};

const getAgeType = (dateOfBirth?: Date | string): string => {
  if (!dateOfBirth) {
    return '';
  }

  const birthDate = moment(dateOfBirth, 'DD.MM.YYYY');
  if (!birthDate.isValid()) {
    return '';
  }

  const today = moment();
  let age = today.year() - birthDate.year();

  if (today.isBefore(birthDate.add(age, 'years'))) {
    age--;
  }

  return age >= 18 ? 'Взрослый' : 'Ребенок';
};

export const extractPassengers = (data: FormikValues): Passenger[] => {
  const passengers: Passenger[] = [];

  for (const key in data) {
    if (key.startsWith('passenger')) {
      passengers.push(data[key]);
    }
  }

  return passengers.map((passenger: FormikValues, index: number) => ({
    id: `passenger-${index + 1}`,
    name: getName(passenger, index + 1),
    type: getAgeType(passenger.dob),
    places: passenger.places,
  }));
};

export const findBestSeats = (
  passengers: Passenger[],
  availableSeats: Array<string> | undefined,
) => {
  if (availableSeats === undefined) return;
  const numPassengers = passengers.length;
  const bestSeats = [];

  for (let i = 0; i <= availableSeats.length - numPassengers; i++) {
    const chunk = availableSeats.slice(i, i + numPassengers);
    if (chunk.length === numPassengers) {
      return chunk; // Нашли лучший вариант - все места рядом
    }
  }

  // Если не нашли всех мест рядом, ищем лучший из доступных вариантов
  for (let i = 0; i < availableSeats.length; i++) {
    bestSeats.push(availableSeats[i]);
    if (bestSeats.length === numPassengers) {
      break;
    }
  }

  return bestSeats;
};

export const getFreePlaces = (
  freePlaces: PlaceOption[] | null,
  values: FormikValues,
  direction: string,
): PlaceOption[] => {
  if (freePlaces === null) return [];

  const occupiedPlaces: (number | string)[] = [];

  Object.keys(values).forEach((key) => {
    if (!key.startsWith('passenger-')) return;

    const occupied = values[key].places?.[direction];
    occupied !== undefined && occupiedPlaces.push(occupied);
  });

  return freePlaces.filter((place) => !occupiedPlaces.includes(place.value));
};
