import React from 'react';
import { PositionContextProviderProps, PositionContextProps } from './types';

const PositionContext = React.createContext<PositionContextProps>(null as any);

export const useOrderPosition = () => React.useContext(PositionContext);

export const PositionContextProvider: React.FC<React.PropsWithChildren & PositionContextProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <PositionContext.Provider value={props}>
      {children}
    </PositionContext.Provider>
  );
};
