import React from 'react';
import styled from 'styled-components';
import { Helper } from '@utils';
import { EnhancedFlightSegment } from '@modules/main';
import moment from 'moment';
import { Text } from '@components/ui';

import { cityIn } from 'lvovich';
import AviaSegmentHeader from '@components/common/FlightSegment/AviaSegmentHeader';
import AviaInfoRow from '@components/common/FlightSegment/AviaInfoRow';
import StopSeparator from '@components/common/FlightSegment/StopSeparator';
import { TransferProps } from '@components/flightsSearch/TicketList/Flight/types';
import Timeline from '@components/common/FlightSegment/Timeline';
import PlaceDate from '@components/common/FlightSegment/PlaceDate';
import AviaAdditionalInfo from '@components/common/FlightSegment/AviaAdditionalInfo';

const Container = styled.div``;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

type SegmentInfoProps = EnhancedFlightSegment & {
  stop: TransferProps | undefined;
  price: number;
  features: {
    applicability: string;
    type: string;
    content: string;
  }[];
};

const SegmentInFlightList: React.FC<SegmentInfoProps> = (props) => {
  const { from, to, airlineInfo, stop, techLandings, price, features } = props;
  const fromDate = moment(`${props.fromDate} ${props.fromTime}`);
  const toDate = moment(`${props.toDate} ${props.toTime}`);
  const hasTechLanding = techLandings && techLandings.length > 0;
  const hasAdditionalInfo =
    stop?.isDifferentAirports || stop?.isDifferentTerminals || hasTechLanding;
  const stopTitle = stop
    ? `пересадка в ${cityIn(stop.city.city)} ${Helper.formatDuration(stop.duration)}`
    : '';
  const techLandingsTitle = hasTechLanding
    ? `тех. остановка в ${techLandings[0].city.airport} ${techLandings[0].city.city} ${Helper.formatDuration(techLandings[0].duration)}`
    : '';

  return (
    <Container>
      <AviaSegmentHeader
        fromCityName={props.from.city}
        toCityName={props.to.city}
        airlinesInfo={[{ name: airlineInfo.name, code: airlineInfo.code }]}
        flight_Type={props.flight_Type}
        price={price}
        features={features}
      />
      <Timeline
        fromDate={fromDate.format('HH:mm')}
        toDate={toDate.format('HH:mm')}
        stops={[]}
        techLanding={hasTechLanding ? techLandings : []}
        duration={props.duration}
      />
      <PlaceDate
        fromDate={fromDate}
        toDate={toDate}
        from={props.from}
        to={props.to}
        duration={Helper.formatDuration(props.duration)}
      />
      <CompanyLogoMobile>
        <Image
          src={Helper.getAirlinesLogoUrl(
            props.airlineInfo.operatingAirlineCode,
            65,
            65,
          )}
          alt={props.airlineInfo.operatingAirlineName}
        />{' '}
        <LogoDescription>
          {props.airlineInfo.operatingAirlineName}
        </LogoDescription>
      </CompanyLogoMobile>
      <AviaInfoRow
        title={'Рейс'}
        type={'aircraft'}
        baseItems={[`${props.airlineCode} ${props.flightNo}`]}
        additionalItem={props.airplane}
      />
      <AviaAdditionalInfo
        hasTechLanding={hasTechLanding}
        techLandingsTitle={techLandingsTitle}
        fromTerminal={from.terminal}
        toTerminal={to.terminal}
        airlineName={airlineInfo.operatingAirlineName}
        hasAdditionalInfo={hasAdditionalInfo}
      />
      {stop && <StopSeparator type={'normal'} title={stopTitle} />}
      {hasTechLanding && (
        <StopSeparator type={'technical'} title={techLandingsTitle} />
      )}
    </Container>
  );
};

export default SegmentInFlightList;
