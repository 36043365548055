import React, { useEffect } from 'react';
import { PassegerListProps } from './types';
import moment from 'moment/moment';
import man1 from '@assets/media/avia-passengers/man-1.svg';
import woman1 from '@assets/media/avia-passengers/woman-1.svg';
import boy1 from '@assets/media/avia-passengers/boy-1.svg';
import girl1 from '@assets/media/avia-passengers/girl-1.svg';
import styled from 'styled-components';
import pasArrow from '@assets/media/order-detail/pasArrow.svg';
import Text from '@components/ui/Typography';
import { AviaTickets, AviaTicketStatusCode } from '@app/modules/orders';

const Wrapper = styled.div`
  margin-top: 35px;
  padding: 24px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 5px 10px 0px #0000000d;
  border-radius: 10px;

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
  color: #3c3c3c;
`;

const BookingHeading = styled.div`
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 767px) {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const RowSB = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled(Text)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.blackGray};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  @media (max-width: 1024px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const GreyText = styled(Text)<{ active: boolean }>`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const RedText = styled(Text)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red};
`;

const Divider = styled.div`
  width: 100%;
  height: 0.7px;
  border-radius: 8px;
  background-color: #dcdcdc;
  margin: 20px 0 0 0;
`;

const PassangerWrapper = styled.div<{
  isBookingSecondPage?: boolean;
  allOpen: boolean;
}>`
  display: ${({ isBookingSecondPage, allOpen }) =>
    isBookingSecondPage && !allOpen ? 'none' : 'block'};
`;

const PassangerTitle = styled.div`
  margin-top: 5px;
`;

const Flex = styled.div`
  display: flex;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
`;

const IconWrapper = styled.div`
  margin-top: 5px;
`;

const Icon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-top: 15px;
  cursor: pointer;
  transform: ${({ reverse }) =>
    !reverse ? 'rotate (0deg)' : 'rotate(180deg)'};
`;

const SubText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-top: 20px;
`;

const SubTextStrong = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.blackGray};
  margin-top: 10px;
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1024px) {
    gap: 10px;
  }
`;

const Grid = styled.div<{ isBookingSecondPage?: boolean }>`
  margin-top: ${({ isBookingSecondPage }) =>
    isBookingSecondPage ? '22px' : '4px'};
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 24px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  border-bottom: 0.7px solid ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 767px) {
    border-bottom: none;
  }
  @media (max-width: 767px) {
    gap: unset;
  }
`;

const NewPassengersBlock: React.FC<PassegerListProps> = React.memo(
  ({ items, itemWrapper, isSecondStep }: PassegerListProps) => {
    const isBookingSecondPage =
      window.location.pathname.includes('/bookingDetails') || isSecondStep;

    const [open, setOpen] = React.useState<number[]>([]);
    const [allOpen, setAllOpen] = React.useState(false);
    const [allPassengersTypes, setAllPassengersTypes] = React.useState<any>([]);

    useEffect(() => {
      const allPassengersTypes: string[] = [];
      items?.map((passenger: any) =>
        allPassengersTypes.push(passenger.passengerType),
      );

      setAllPassengersTypes(allPassengersTypes);
    }, [items]);

    useEffect(() => {
      if (isBookingSecondPage) setOpen(items?.map((_, i) => i));
    }, []);

    function getPassengerTypeDescription(str: string) {
      switch (str) {
        case 'Adult':
          return 'взрослый';
        case 'Child':
          return 'ребенок';
        default:
          return 'младенец';
      }
    }

    function getImage(gender: string, birthDate: string) {
      const birth = moment(birthDate);
      const duration = moment.duration(moment().diff(birth));
      const age = duration.years();
      if (gender === 'Inessential') return undefined;
      if (gender === 'Male' && age > 18) {
        return man1;
      } else if (gender === 'Male' && age < 18) {
        return boy1;
      } else if (gender !== 'Male' && age < 18) {
        return girl1;
      } else return woman1;
    }

    function getPassportDescription(type: string) {
      switch (type) {
        case 'RussianPassport':
        case 'RussianFederationPassport':
          return 'Паспорт РФ';
        case 'BirthCertificate':
          return 'Свидетельство ';
        case 'InternationalPassport':
          return 'Загран. паспорт';
        case 'NationalPassport':
          return 'Нац. паспорт';
        default:
          return 'Другой документ';
      }
    }

    function getGenderDescription(type: string) {
      if (type === 'Inessential') return '';

      return type === 'Male' ? 'Мужской' : 'Женский';
    }

    const toggleOpen = (i: number) => {
      if (open.includes(i)) {
        setOpen(open.filter((x: any) => x !== i));
      } else {
        const newOpen = [...open];
        newOpen.push(i);
        setOpen(newOpen);
      }
    };

    function declOfNum(number?: number, titles?: string[]) {
      const cases = [2, 0, 1, 1, 1, 2];
      return titles && number
        ? number.toString() +
            titles[
              number % 100 > 4 && number % 100 < 20
                ? 2
                : cases[number % 10 < 5 ? number % 10 : 5]
            ]
        : '';
    }

    return (
      <Wrapper>
        {isBookingSecondPage ? (
          <>
            <RowSB>
              <BookingHeading>Данные пассажиров</BookingHeading>
              <Icon
                reverse={allOpen}
                src={pasArrow}
                alt="arrow"
                style={{ marginTop: 0, display: 'block' }}
                onClick={() => setAllOpen(!allOpen)}
              />
            </RowSB>
            {!allOpen && (
              <>
                <SubText>
                  {declOfNum(items?.length, [
                    ' пассажир ',
                    ' пассажира ',
                    ' пассажиров ',
                  ])}
                  по заказу
                </SubText>
                <SubTextStrong>
                  {(allPassengersTypes.includes('Adult')
                    ? declOfNum(
                        allPassengersTypes.filter((x: any) => x === 'Adult')
                          ?.length,
                        [' взрослый ', ' взрослых ', ' взрослых '],
                      )
                    : '') +
                    (allPassengersTypes.includes('Child')
                      ? declOfNum(
                          allPassengersTypes.filter((x: any) => x === 'Child')
                            ?.length,
                          [' ребенок ', 'ребенка', 'детей'],
                        )
                      : '') +
                    (allPassengersTypes.includes('Infant')
                      ? declOfNum(
                          allPassengersTypes.filter((x: any) => x === 'Infant')
                            ?.length,
                          [' младенец ', 'младенца', 'младенцев'],
                        )
                      : '')}
                </SubTextStrong>
              </>
            )}
          </>
        ) : (
          <Heading>Пассажиры и билеты по заказу</Heading>
        )}
        {items?.map((pas, i) => (
          <PassangerWrapper
            isBookingSecondPage={isBookingSecondPage}
            allOpen={allOpen}
            key={i}
          >
            <Row
              onClick={() => !isBookingSecondPage && toggleOpen(i)}
              style={{
                cursor: !isBookingSecondPage ? 'pointer' : 'auto',
                alignItems: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <Flex>
                {getImage(pas.gender, pas.birthDate) && (
                  <img
                    width={30}
                    height={30}
                    style={{ borderRadius: 8, marginRight: 7 }}
                    src={getImage(pas.gender, pas.birthDate)}
                  />
                )}
                <VerticalFlex>
                  <PassangerTitle>
                    {`${pas.lastName} ${pas.firstName}` +
                      (pas.patronymic ? ' ' + pas.patronymic + ', ' : ', ')}
                    {getPassengerTypeDescription(
                      pas.passengerType || pas.passengerCategoryType,
                    )}
                  </PassangerTitle>
                  {!isBookingSecondPage && (
                    <GreyText active={!open.includes(i)}>
                      билет <Tickets aviaTickets={pas.aviaTickets} />
                    </GreyText>
                  )}
                </VerticalFlex>
              </Flex>
              {!isBookingSecondPage && (
                <IconWrapper>
                  <Icon reverse={open.includes(i)} src={pasArrow} alt="arrow" />
                </IconWrapper>
              )}
            </Row>
            {((!isBookingSecondPage && open.includes(i)) ||
              (isBookingSecondPage && open.includes(i) && allOpen)) && (
              <Grid isBookingSecondPage={isBookingSecondPage}>
                <GridItem>
                  <Label>Пол:&nbsp;</Label>
                  <Description>{getGenderDescription(pas.gender)}</Description>
                </GridItem>
                <GridItem>
                  <Label>Дата рождения:&nbsp;</Label>
                  <Description>
                    {moment(pas.birthDate).format('DD.MM.YYYY')}
                  </Description>
                </GridItem>
                <GridItem>
                  <Label>Гражданство:&nbsp;</Label>
                  <Description>
                    {pas.citizen || pas.citizenshipLabel}
                  </Description>
                </GridItem>
                <GridItem>
                  <Label>Документ:&nbsp;</Label>
                  <Description>
                    {getPassportDescription(
                      pas.document.type || pas.document.documentType || '',
                    )}
                  </Description>
                </GridItem>
                <GridItem>
                  <Label>Серия и номер:&nbsp;</Label>
                  <Description>
                    {pas.document.number || pas.document.documentNumber}
                  </Description>
                </GridItem>
                {pas.document.issueDate && (
                  <GridItem>
                    <Label>Срок действия:&nbsp;</Label>
                    <Description>
                      {moment(pas.document.issueDate).format('DD.MM.YYYY')}
                    </Description>
                  </GridItem>
                )}
                {pas?.aviaTickets?.[0]?.eTicketNumber && (
                  <GridItem>
                    <Label>Билет №:&nbsp;</Label>
                    <Description>
                      <Tickets
                        aviaTickets={pas.aviaTickets}
                        divider={';'}
                        showNumber={false}
                      />
                    </Description>
                  </GridItem>
                )}
                {pas?.loyalityCard && (
                  <GridItem>
                    <Label>Бонусная карта:&nbsp;</Label>
                    <Description>{pas?.loyalityCard}</Description>
                  </GridItem>
                )}
              </Grid>
            )}
            <Divider />
          </PassangerWrapper>
        ))}
      </Wrapper>
    );
  },
);

const Tickets = ({
  aviaTickets,
  divider = ',',
  showNumber = true,
}: {
  aviaTickets: AviaTickets[];
  divider?: ',' | ';';
  showNumber?: boolean;
}) => {
  const checkIsCancelled = (status?: AviaTicketStatusCode) =>
    status === AviaTicketStatusCode.Voided ||
    status === AviaTicketStatusCode.Refunded ||
    status === AviaTicketStatusCode.Exchanged;

  return aviaTickets?.map((ticket, index) => (
    <>
      {index > 0 && `${divider} `}
      {showNumber ? '№' : ''} {ticket.eTicketNumber}
      {checkIsCancelled(ticket?.status?.code) && (
        <RedText> (анулирован)</RedText>
      )}
    </>
  ));
};

export default NewPassengersBlock;
