import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getAgentBalance } from '@modules/agents/agent-finance/selectors';
import { getAgentMainInformation } from '@modules/agents/agent-finance/duck';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #edf1fe;
  border-radius: 5px;
  padding: 9px;
  margin-top: 5px;
`;

const TextBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 17px;
  color: #3c3c3c;
  margin-top: 10px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  font-weight: bold;
`;

//извиняюсь за бойлерплейт делал быстро

const AgentBalanceBlock = () => {
  const data = useSelector(getAgentBalance);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAgentMainInformation());
  }, []);

  return (
    <Container>
      <Heading>Личный баланс агента:</Heading>
      <TextBlock>
        Остаток по кредиту:&nbsp;
        <span style={{ color: '#4872F2', fontWeight: 400 }}>
          {data?.creditLimitRest
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          &nbsp;₽
        </span>
      </TextBlock>
      {/* <TextBlock>
        Остаток по депозиту:{' '}
        <span style={{ color: '#4872F2' }}>
          {' '}
          {data?.creditLimitRest
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          ₽
        </span>
      </TextBlock> */}
      <TextBlock>
        Задолженность:&nbsp;
        <span style={{ color: '#4872F2', fontWeight: 400 }}>
          {' '}
          {data?.debt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}₽
        </span>
      </TextBlock>
    </Container>
  );
};

export default AgentBalanceBlock;
