import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import RailwayRouteDetailBlock from '@components/train/RouteDetail/RailwayRouteDetailBlock';
import { revertConvertSeatPlaceWithALetter } from '@modules/trainTickets/utils';

const BookingRouteDetailContainer: React.FC = () => {
  const forwardState = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward
  );
  const backwardState = useSelector(
    (state: ApplicationState) => state.trainTickets.trainBack
  );

  const forwardChosenPlaces = forwardState.chosenCarPlaces.map((place) => {
    if (parseInt(place.number) > 2000) {
      return revertConvertSeatPlaceWithALetter(parseInt(place.number));
    } else {
      return place.number;
    }
  });
  const backwardChosenPlaces = backwardState?.chosenCarPlaces?.map((place) => {
    if (parseInt(place.number) > 2000) {
      return revertConvertSeatPlaceWithALetter(parseInt(place.number));
    } else {
      return place.number;
    }
  });

  return (
    <RailwayRouteDetailBlock
      instance={'booking'}
      trainForward={forwardState.train}
      trainBackward={backwardState?.train}
      forwardCarNumber={forwardState.currentCar.data.carNumber}
      backwardCarNumber={backwardState?.currentCar?.data?.carNumber}
      forwardChosenPlaces={forwardChosenPlaces}
      backwardChosenPlaces={backwardChosenPlaces}
    />
  );
};
export default BookingRouteDetailContainer;
