import {
  BookTrainTicketDto,
  CreateTrainBookingPayload,
} from '@modules/trainBooking/dto/BookTrainTicketDto';
import { Api } from '@utils';
import { CONTENT_TYPE, STRATEGY_TYPES } from '../../utils/fetch';

const TRAIN_ORDER_CREATE = process.env.REACT_APP_TRAIN_ORDER_CREATE as string;
const TRAIN_API_URL = process.env.REACT_APP_TRAIN_SERVICE_HOST as string;

export const bookTrainTicket = (
  payload: CreateTrainBookingPayload
): Promise<BookTrainTicketDto> => {
  const bookTrainTicketLink = Api.createFetchUrl(
    TRAIN_API_URL,
    TRAIN_ORDER_CREATE
  );
  const url = bookTrainTicketLink('', {});
  return Api.request(
    {
      url: url,
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH
  )
    .then(Api.statuses)
    .then(Api.jsonBooking);
};
