import React from 'react';
import { ContextProps, Enums } from './types';

const ModalContext = React.createContext<ContextProps>(null as any);

export const useModalState = () => React.useContext(ModalContext);

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visibility, setVisibility] = React.useState(null as Enums | null);
  const hideModal = () => setVisibility(null);
  const showModal = (x: Enums) => setVisibility(x);
  return (
    <ModalContext.Provider value={{ visibility, hideModal, showModal }}>
      {children}
    </ModalContext.Provider>
  );
};
