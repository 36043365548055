import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import Cross from '@assets/images/cross';
import { usePopup } from '../../../flightsSearch/Filter/Mobile/Popup/usePopup';
import { useDispatch } from 'react-redux';
import { resetTrainFilter } from '@modules/trainTickets';
import { useCurrentTrainFilter } from '@modules/trainTickets/utils';

const Wrapper = styled.div`
  min-height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  border-bottom: 1px solid #eff1f4;
`;

const ResetButton = styled.button`
  color: ${({ theme: { colors } }) => colors.main};
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;
  line-height: 18px;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
`;

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Head: React.FC = () => {
  const { onClose } = usePopup();
  const dispatch = useDispatch();
  const activeFilter = useCurrentTrainFilter();

  const filterResetHandler = () => {
    dispatch(
      resetTrainFilter({
        value: null,
        type: activeFilter.filterDestinationType,
      })
    );
    typeof onClose === 'function' && onClose();
  };

  return (
    <Wrapper>
      <ResetButton onClick={filterResetHandler}>Сбросить всё</ResetButton>
      <Title>Фильтры</Title>
      <Cross onClick={onClose} />
    </Wrapper>
  );
};

export default Head;
