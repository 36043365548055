import React from 'react';

const Pencil: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.8182 1.91789C19.4277 1.52737 18.7945 1.52737 18.404 1.91789L1.29289 19.029C1.10536 19.2165 1 19.4709 1 19.7361V24.625C1 25.1773 1.44772 25.625 2 25.625H6.88889C7.15411 25.625 7.40846 25.5196 7.596 25.3321L24.7071 8.221C24.8946 8.03346 25 7.77911 25 7.51389C25 7.24867 24.8946 6.99432 24.7071 6.80678L19.8182 1.91789Z"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8889 8.73633L6.88892 19.7363"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4443 6.38379L20.3332 11.1805"
        stroke="#4872F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pencil;
