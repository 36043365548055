import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import {
  purifyTrainPassengers,
  bookTrainRequest,
  enterInBooking,
  purifyTrainPriceItems,
  setTrainPriceItems,
  updateTrainPassengers,
  setTrainTotalPrice,
  setRefundableStateSeatPlace,
} from '@modules/trainBooking/duck';
import { setAuthPopup, setStatus } from '@modules/booking';
import * as Sentry from '@sentry/react';
import { Action } from 'redux-act';
import { CreateTrainBookingPayload } from '@modules/trainBooking/dto/BookTrainTicketDto';
import { getUserStatusState } from '@modules/user';
import { trainBookWorkers, trainSignUp } from '@modules/trainBooking/workers';
import { ApplicationState } from '@modules/index';
import {
  getFilledTrainPassengers,
  getTrainPriceDetails,
} from '@modules/trainBooking/utils';
import { TrainPassengerItemEntity } from '@modules/trainBooking/types';
import {
  CarPlace,
  setChosenCarPlace,
  setChosenCarPlaceBackward,
} from '@modules/trainTickets';
import { revertConvertSeatPlaceWithALetter } from '@modules/trainTickets/utils';

export function* trainBookingWorker() {
  try {
    yield* put(purifyTrainPassengers());
    yield* put(purifyTrainPriceItems());
    const passengersCounts = yield* select(
      (state: ApplicationState) =>
        state.searchReducer.simpleSearchForm.passengers,
    );
    const trainForwardState = yield* select(
      (state: ApplicationState) => state.trainTickets.trainForward,
    );
    const trainBackwardState = yield* select(
      (state: ApplicationState) => state.trainTickets.trainBack,
    );

    const chosenTariffForward = yield* select(
      (state: ApplicationState) => state.trainBooking.tariffs.forward,
    );
    const chosenTariffBackward = yield* select(
      (state: ApplicationState) => state.trainBooking.tariffs.backward,
    );

    let needAddExtraPlacesToFirstPassengerForward = false;
    let needAddExtraPlacesToFirstPassengerBackward = false;
    const chosenForwardPlacesFromStore = trainForwardState.chosenCarPlaces;
    const chosenBackwardPlacesFromStore = trainBackwardState.chosenCarPlaces;
    const chosenForwardPlaces: CarPlace[] = chosenForwardPlacesFromStore.map(
      (place) => ({
        ...place,
        number: place.number.match(/[МЖЦС]/)
          ? place.number
          : revertConvertSeatPlaceWithALetter(parseInt(place.number)),
      }),
    );
    const chosenBackwardPlaces: CarPlace[] = chosenBackwardPlacesFromStore.map(
      (place) => ({
        ...place,
        number: place.number.match(/[МЖЦС]/)
          ? place.number
          : revertConvertSeatPlaceWithALetter(parseInt(place.number)),
      }),
    );
    if (chosenForwardPlaces.length > 0) {
      const isFourPlacesAtOnceForward =
        chosenForwardPlaces[0].placeReservationType === 'FourPlacesAtOnce';
      const isTwoPlacesAtOnceForward =
        chosenForwardPlaces[0].placeReservationType === 'TwoPlacesAtOnce';
      const isKupekOrSingleForward =
        chosenTariffForward === 'Kupek' || chosenTariffForward === 'Single';
      needAddExtraPlacesToFirstPassengerForward =
        isKupekOrSingleForward ||
        isFourPlacesAtOnceForward ||
        isTwoPlacesAtOnceForward;
    }
    if (chosenBackwardPlaces.length > 0) {
      const isFourPlacesAtOnceBackward =
        chosenBackwardPlaces[0].placeReservationType === 'FourPlacesAtOnce';
      const isTwoPlacesAtOnceBackward =
        chosenBackwardPlaces[0].placeReservationType === 'TwoPlacesAtOnce';
      const isKupekOrSingleBackward =
        chosenTariffBackward === 'Kupek' || chosenTariffBackward === 'Single';

      needAddExtraPlacesToFirstPassengerBackward =
        isKupekOrSingleBackward ||
        isFourPlacesAtOnceBackward ||
        isTwoPlacesAtOnceBackward;
    }

    const adultsCount = passengersCounts.adults.count;
    const childrenCount = passengersCounts.children.count;
    const infantsCount = passengersCounts.infants.count;
    const passengersList: TrainPassengerItemEntity[] = getFilledTrainPassengers(
      adultsCount,
      childrenCount,
      infantsCount,
    );

    // садим пассажиров на места
    passengersList.forEach((passenger, index) => {
      // если пассажир - младенец, то отдаём место первому пассажиру (взрослому)
      if (passenger.ageCategory === 'INFANT') {
        if (chosenForwardPlaces[index]) {
          passengersList[0].carPlaces.push({
            ...chosenForwardPlaces[index],
            _direction: 'forward',
          });
        }
        if (chosenBackwardPlaces[index]) {
          passengersList[0].carPlaces.push({
            ...chosenBackwardPlaces[index],
            _direction: 'backward',
          });
        }
        return;
      }
      if (chosenBackwardPlaces.length > 0) {
        if (chosenForwardPlaces[index] && chosenBackwardPlaces[index]) {
          passenger.carPlaces = [
            { ...chosenForwardPlaces[index], _direction: 'forward' },
            { ...chosenBackwardPlaces[index], _direction: 'backward' },
          ];
        }
      } else {
        if (chosenForwardPlaces[index]) {
          passenger.carPlaces = [
            { ...chosenForwardPlaces[index], _direction: 'forward' },
          ];
        }
      }
    });
    // все оставшиеся места отдаёт первому пассажиру
    if (
      needAddExtraPlacesToFirstPassengerForward &&
      chosenForwardPlaces.length > passengersList.length
    ) {
      for (let i = passengersList.length; i < chosenForwardPlaces.length; i++) {
        passengersList[0].carPlaces.push({
          ...chosenForwardPlaces[i],
          _direction: 'forward',
        });
      }
    }
    if (
      chosenBackwardPlaces.length > 0 &&
      needAddExtraPlacesToFirstPassengerBackward &&
      chosenBackwardPlaces.length > passengersList.length
    ) {
      for (
        let i = passengersList.length;
        i < chosenBackwardPlaces.length;
        i++
      ) {
        passengersList[0].carPlaces.push({
          ...chosenBackwardPlaces[i],
          _direction: 'backward',
        });
      }
    }
    yield* call(updateTrainPricing);
    yield* put(updateTrainPassengers(passengersList));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export interface BedClothingService {
  bedClothing: boolean | null;
  cost: number;
}

export function* updateTrainPricing() {
  try {
    const passengersCounts = yield* select(
      (state: ApplicationState) =>
        state.searchReducer.simpleSearchForm.passengers,
    );
    const trainForwardState = yield* select(
      (state: ApplicationState) => state.trainTickets.trainForward,
    );
    const trainBackwardState = yield* select(
      (state: ApplicationState) => state.trainTickets.trainBack,
    );

    const chosenForwardPlaces = trainForwardState.chosenCarPlaces;
    const chosenBackwardPlaces = trainBackwardState.chosenCarPlaces;
    const currentCarForward = trainForwardState.currentCar.data;
    const currentCarBackward = trainBackwardState.currentCar.data;
    const bedClothing:
      | [BedClothingService]
      | [BedClothingService, BedClothingService] = [
      {
        bedClothing: trainForwardState.bedClothes,
        cost:
          !!currentCarForward.carPriceDetalizations?.length &&
          currentCarForward.carPriceDetalizations[0]
            ? currentCarForward.carPriceDetalizations[0].serviceCost?.totalPrice
            : 0,
      },
    ];
    if (currentCarBackward?.carNumber) {
      bedClothing.push(
        {
          bedClothing: trainBackwardState?.bedClothes,
          cost:
            !!currentCarBackward?.carPriceDetalizations?.length &&
            currentCarBackward.carPriceDetalizations[0]
              ? currentCarBackward.carPriceDetalizations[0].serviceCost?.totalPrice
              : 0,
        }
      )
    }

    const adultsCount = passengersCounts.adults.count;
    const childrenCount = passengersCounts.children.count;
    const infantsCount = passengersCounts.infants.count;
    const counters: [number, number, number] = [
      adultsCount,
      childrenCount,
      infantsCount,
    ];

    const detailList = getTrainPriceDetails(
      chosenForwardPlaces,
      chosenBackwardPlaces,
      counters,
      bedClothing,
    );
    const totalPrice = detailList.reduce((acc, item) => acc + item.cost, 0);

    yield* put(setTrainPriceItems(detailList));
    yield* put(setTrainTotalPrice(totalPrice));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

// update _isNotRefundable flag in passengers.seatPlaces by passenger index and car place number
export function* setRefundableStateSeatPlaceSaga({
  payload,
}: Action<{
  passengerIndex: number;
  direction: 'forward' | 'backward';
  isNotRefundable: boolean;
}>) {
  try {
    const isForward = payload.direction === 'forward';
    const passengers = yield* select(
      (state: ApplicationState) => state.trainBooking.passengers,
    );
    const chosenPlaces = yield* select((state: ApplicationState) =>
      isForward
        ? state.trainTickets.trainForward.chosenCarPlaces
        : state.trainTickets.trainBack.chosenCarPlaces,
    );
    const updatedPassengers = [...passengers];
    const passenger = updatedPassengers[payload.passengerIndex];
    let carPlace = passenger.carPlaces[0];
    if (payload.direction === 'backward') {
      carPlace = passenger.carPlaces[1];
    }

    if (carPlace) {
      const updatedChosenPlaces = [...chosenPlaces];
      carPlace._isNotRefundable = payload.isNotRefundable;
      // update chosenPlaces by placeNumber
      const placeIndex = updatedChosenPlaces.findIndex(
        (place) => place.number === carPlace.number,
      );
      if (placeIndex !== -1) {
        updatedChosenPlaces[placeIndex]._isNotRefundable =
          payload.isNotRefundable;
      }
      yield* put(
        isForward
          ? setChosenCarPlace(updatedChosenPlaces)
          : setChosenCarPlaceBackward(updatedChosenPlaces),
      );
    }
    yield* put(updateTrainPassengers(updatedPassengers));
    yield* call(updateTrainPricing);
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export function* trainBookRequestFlow({
  payload,
}: Action<CreateTrainBookingPayload>) {
  yield* put(setStatus(true));
  try {
    const { isAuthorized } = yield* select(getUserStatusState);

    if (!isAuthorized) {
      yield* call(trainSignUp, payload);
    } else {
      yield* call(trainBookWorkers, payload);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield* put(setStatus(false));
    yield* put(setAuthPopup(false));
  }
  yield* put(setStatus(false));
}

export default function* trainBookingFlow() {
  yield* all([
    takeLatest(enterInBooking.getType(), trainBookingWorker),
    takeLatest(bookTrainRequest.getType(), trainBookRequestFlow),
    takeLatest(
      setRefundableStateSeatPlace.getType(),
      setRefundableStateSeatPlaceSaga,
    ),
  ]);
}
