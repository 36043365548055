import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';
import { useMonth, MonthListItem, useCalendareContext } from './context';
import ArrowCheck from '../../assets/images/arrow';
import { SelectWrapper, SelectMonth, SelectYear } from './styles';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
`;

const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  width: 110px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.blackGray};

  &:hover {
    color: ${({ theme: { colors } }) => colors.main};
  }
`;

const Arrow = styled.svg`
  width: 15px;
  height: 15px;
  margin-left: 7px;
  fill: ${({ theme: { colors } }) => colors.main};

  & > path {
    cursor: pointer;

    &:hover {
      fill: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #f2f2f2;
  }

  & > svg {
    width: 100%;
    fill: rgb(72, 114, 242);
  }
`;

interface MonthProps {
  list: MonthListItem;
  index: number;
}

export const Month: React.FC<MonthProps> = (props) => {
  const {setSelectVisibility, selectVisibility } = useMonth();
  const { monthState } = useCalendareContext();
  const selectedDate = useMemo(
    () => monthState.date[props.index].format('MMMM YYYY'),
    [props]
  );

  const handleClick = () => {
    setSelectVisibility(true);
  };
  return (
    <Wrapper>
      <Text onClick={handleClick}>{selectedDate}</Text>
      <ArrowContainer onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>выбрать месяц</title>
          <path d="M12,6L7,11H17L12,6M7,13L12,18L17,13H7Z" />
        </svg>
      </ArrowContainer>
      {selectVisibility && <MonthSelect list={monthState.list[props.index]} index={props.index} />}
    </Wrapper>
  );
};

const MonthSelect: React.FC<MonthProps> = ({ list, index }) => {
  const { setSelectVisibility } = useMonth();
  const ref = useRef(null as null | HTMLUListElement);

  useEffect(() => {
    const handleCLickOutside = (e: MouseEvent) => {
      if (ref.current) {
        if (!ref.current.contains(e.target as any)) {
          setSelectVisibility(false);
        }
      }
    };
    document.addEventListener('mousedown', handleCLickOutside);
    return () => document.removeEventListener('mousedown', handleCLickOutside);
  }, [ref, setSelectVisibility, list]);

  return (
    <SelectWrapper ref={ref}>
      {Object.keys(list).map((year, index2) => {
        return (
          <div key={`${index}-${index2}`}>
            <SelectYear key={year}>{year}</SelectYear>
            {list[year].map((props, key) => (
              <SelectMonthItem
                key={`${index}-${key}`}
                {...props}
                index={index}
              />
            ))}
          </div>
        );
      })}
    </SelectWrapper>
  );
};

interface SelectMonthItemProps {
  selected: boolean;
  date: Moment;
  index: number;
}

const SelectMonthItem: React.FC<SelectMonthItemProps> = React.memo(
  ({ date, selected, index }) => {
    const { handleMonthChange } = useCalendareContext();
    const { setSelectVisibility } = useMonth();

    const handleClick = () => {
      handleMonthChange(date, index);
      setSelectVisibility(false);
    };
    return (
      <SelectMonth onClick={handleClick} active={selected}>
        <span>{date.format('MMMM')}</span>
        <ArrowCheck width={15} height={10} />
      </SelectMonth>
    );
  }
);
