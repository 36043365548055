import React from 'react';
import styled from 'styled-components';
import ImageWithModal from './ImageWithModal';
import { StepGalleryProps } from './types';
import { Text } from '@components/ui';

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 52px;
  margin: 40px 0;

  @media (max-width: 1169px) {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
`;

const GalleryTitle = styled(Text)`
  font-weight: 600;
`;

const GalleryDescription = styled(Text)`
  & > strong {
    font-weight: 600;
  }
`;

const GalleryItem = styled.div`
  display: block;

  ${GalleryTitle} {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-top: 20px;
  }

  @media (max-width: 1169px) {
    & > * {
      display: unset;
    }
  }

  @media (max-width: 767px) {
    ${GalleryTitle} {
      margin-bottom: 12px;
      display: block;
    }

    ${GalleryDescription} {
      margin-bottom: 20px;
      display: block;
    }
  }
`;

export default function StepImageRow(props: StepGalleryProps) {
  return (
    <Gallery>
      {props.items.map((x, key) => (
        <GalleryItem key={key}>
          <GalleryTitle>Шаг {key + 1}.</GalleryTitle>
          <GalleryDescription>{x.description}</GalleryDescription>
          <ImageWithModal
            src={x.src}
            width={x.width}
            height={x.height}
            alt={`шаг ${key + 1}`}
            borderRadius={21}
            scaleRate={2.34285714286}
          />
        </GalleryItem>
      ))}
    </Gallery>
  );
}
