import styled, { css } from 'styled-components';
import {
  Button,
  Text,
  DragAndDropFIles as DragAndDropFIlesUi,
} from '@components/ui';
import { Form as Formik } from 'formik';

export const SecondSelectBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  & > span {
    width: 48%;
  }

  ${({ theme }) => `
    ${theme.max('767px')} {
      flex-direction: column;
      margin: 0;
      margin-top: 15px;
      & > span {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  `}
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const Description = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const DescriptionUl = styled.ul`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  max-width: 493px;
  padding-left: 27px;
  margin-top: 20px;

  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  & > li {
    margin-bottom: 20px;

    & > strong {
      font-weight: 600;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const TextBlock = styled.div`
  margin-bottom: 25px;

  & > span {
    display: block;
  }

  ${Title} {
    margin-bottom: 30px;
  }
`;

export const AncillaryServicesBlock = styled(SecondSelectBlock)`
  & > span {
    width: 100%;
  }
`;

export const LastBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const Form = styled(Formik)`
  width: 100%;
  margin-top: 30px;
`;

export const PassengersListWrapper = styled.div`
  & > * {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-top: 20px;
    margin-bottom: 0;
  }

  margin-bottom: 25px;
`;

export const PassengerName = styled(Text)`
  white-space: nowrap;
  margin-right: 10px;
  margin-top: 3px;
`;

export const InsurancesPassenger = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GrayLine = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 8px;
  background: #dcdcdc;
`;

export const InsuranceList = styled.div`
  white-space: break-spaces;
  text-align: right;
`;

export const InsuranceItem = styled.div`
  display: inline-flex;

  & > :first-child {
    margin-right: 5px;
  }
`;

export const ButtonBase = css`
  background: ${({ theme: { colors } }) => colors.blackGray};
  width: 18px;
  height: 18px;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  &:disabled {
    background: ${({ theme: { colors } }) => colors.gray};
  }
`;

export const CrossButton = styled.button.attrs({
  type: 'button',
})`
  ${ButtonBase}
  &:before, &:after {
    position: absolute;
    content: ' ';
    height: 9px;
    width: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 1px;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const ContainerMultiValue = styled.div`
  background: white;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 0;

  & > div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    text-overflow: ellipsis;
  }
`;

export const ContainerMultiList = styled.div`
  display: flex;

  [class*='-multiValue'] {
    background: transparent;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Submit = styled(Button).attrs({ htmlType: 'submit' })`
  font-size: clamp(14px, 3vw, 16px);

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DragAndDropFiles = styled(DragAndDropFIlesUi)`
  margin-top: 25px;
`;
