import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import { ReactComponent as InfoIcon } from '@assets/media/info-icon.svg';

import { AgentPayModalProps } from '../types';
import StepList from './StepList';
import { Text, Button as BaseButton, Checkbox } from '@components/ui';
import close from '@assets/images/common/close.svg';

const StyledPopup = styled(Popup)`
  max-height: 100vh;
  overflow-y: auto;

  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }

  &-content {
    width: 663px;
    border: 1px solid #dcdcdc;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;

    @media screen and (min-width: 768px) and (max-width: 1439px) {
      width: 616px;
      border-radius: 0;
      max-height: auto;
    }

    @media (max-width: 767px) {
      padding: 34px 20px;
      width: 100%;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      border-radius: 0px;
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-bottom: 20px;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    margin-bottom: 15px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
    white-space: wrap;
  }
`;

const Description = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: block;
  margin-bottom: 30px;
  white-space: nowrap;

  & > strong {
    font-weight: 600;
  }

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    white-space: normal;
  }
`;

const ActionsList = styled.div`
  & > * {
    margin-bottom: 30px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const ActionBlockTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 14px;
  }
`;

const ActionBlockDescription = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
  }

  @media screen and (min-width: 375px) and (max-width: 1439px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Button = styled(BaseButton)`
  min-width: 209px;
  padding: 8px 19.5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 767px) {
    min-width: auto;
    width: 48%;
    padding: 7px 10px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const ActionBlockButtons = styled.div`
  display: flex;

  & > button {
    margin-right: 20px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const ActionButtonMobile = styled.div`
  display: none;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: flex;
    & > button {
      min-width: auto;
    }
  }

  @media (max-width: 425px) {
    display: block;
    & > button {
      width: 100%;
      margin-bottom: 10px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

const ErrorDescription = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #e73c3e;
  @media screen and (min-width: 375px) and (max-width: 1439px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const ActionBlockContent = styled.div`
  padding-top: 2px;

  ${ActionBlockTitle} {
    display: block;
    margin-bottom: 12px;
  }

  ${ErrorDescription} {
    display: block;
    margin-bottom: 20px;
  }

  ${ActionBlockDescription} {
    display: block;
    margin-bottom: 10px;
  }

  ${StepList} {
    margin: 20px 0;
  }

  ${ActionBlockButtons} {
    margin-top: 20px;
  }

  @media screen and (min-width: 375px) and (max-width: 767px) {
    ${ActionBlockDescription} {
      display: block;
      margin-bottom: 15px;
    }

    ${ErrorDescription} {
      display: block;
      margin-bottom: 15px;
    }
  }
`;

const Flex = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 10px;
  }
  & > svg {
    flex-shrink: 0;
  }
`;

const ActionBlock = styled.div<{ disabled?: boolean; selected?: boolean }>`
  border: 1px solid #c4c4c4;
  background-color: transparent;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 18px 20px 20px 14px;

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding: 18px 10px 10px 14px;
  }

  ${ErrorDescription} {
    display: none;
  }

  ${({ selected }) =>
    selected
      ? `
  border-color: #4872F2;
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
      background-color: #F9F9F9;
      border-color: #DCDCDC;

      button {
        background: #DEDEDE !important;
        color: #737373 !important;
      }

      & > :last-child {
        display: none;
      }

      ${ActionBlockContent} {

        & > * {
          display: none;
        }

        ${ActionBlockTitle} {
          display: block;
        }
  
        ${ErrorDescription} {
          display: block;
          margin-bottom: 0;
        }
      }
  `
      : ''}
`;

const RowSB = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BlueContainer = styled.div`
  background: #edf1fe;
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 20px;
`;

const Modal: React.FC<AgentPayModalProps> = ({
  creditPaid,
  creditEnough,
  onPay,
  onExtract,
  onCreateInvoice,
  setAgentModal,
  ...props
}) => {
  const [state, setState] = React.useState({
    a: { type: 1, selected: false, disabled: false, disabledButton: true },
    b: { type: 2, selected: true, disabled: false, disabledButton: false },
  } as {
    [key: string]: {
      type: number;
      selected: boolean;
      disabled: boolean;
      disabledButton: boolean;
    };
  });
  const aquiringInfo = useSelector(
    (state: ApplicationState) => state.agents.aquiringInfo
  );
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail.data);

  const handleChange = (prop: 'a' | 'b' | 'c') => {
    if ((prop === 'c' && creditEnough) || prop === 'a' || prop === 'b') {
      setState((s) => {
        if (!s[prop]) {
          return { ...s };
        }
        const next = {
          ...s[prop],
          selected: !s[prop].selected,
          disabledButton: s[prop].selected,
        };

        Object.keys(s).forEach((key) => {
          s[key].selected = false;
          s[key].disabledButton = !s[key].selected;
        });

        return {
          ...s,
          [prop]: next,
        };
      });
    }
  };

  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
      const s: {
        [key: string]: {
          type: number;
          selected: boolean;
          disabled: boolean;
          disabledButton: boolean;
        };
      } = {
        a: { type: 1, selected: false, disabled: false, disabledButton: true },
        b: { type: 2, selected: true, disabled: false, disabledButton: false },
      };
      if (creditPaid) {
        s.c = {
          type: 3,
          selected: false,
          disabled: !creditEnough,
          disabledButton: true,
        };
      }

      setState(s);
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <RowSB>
        <Title>Выберите способ оплаты</Title>
        <img
          src={close}
          style={{ marginBottom: 20, cursor: 'pointer' }}
          onClick={() => setAgentModal(false)}
        />
      </RowSB>
      {/* <Description>
        Выберите один из доступных способов оплаты заказа и выписки билетов.
      </Description> */}
      <ActionsList>
        {state.a && (
          <ActionBlock selected={state.a.selected} disabled={state.a.disabled}>
            <Flex>
              <Checkbox
                type="roundWithArrow"
                data-cy="payWithBankCardCheckbox"
                checked={state.a.selected}
                blueBorder
                onChange={(e) => {
                  handleChange('a');
                }}
              />
              <ActionBlockContent>
                <ActionBlockTitle>Оплатить банковской картой</ActionBlockTitle>
                <ActionBlockDescription>
                  Оплата картой онлайн и моментальная выписка заказа.{' '}
                </ActionBlockDescription>

                {aquiringInfo && (
                  <>
                    <BlueContainer>
                      <Flex>
                        <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
                        <ActionBlockDescription style={{ marginBottom: 0 }}>
                          При оплате банковской картой взимается комиссия в
                          размере {aquiringInfo[0].acquiringCommission}%.
                        </ActionBlockDescription>
                      </Flex>
                    </BlueContainer>
                    <ActionBlockDescription>
                      Комиссия по заказу составит:{' '}
                      <span style={{ color: '#4872F2', fontWeight: 600 }}>
                        {order?.acquiringCommissions[0]?.acquiringAmount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                        ₽
                      </span>
                    </ActionBlockDescription>
                    <ActionBlockDescription>
                      Итого к оплате c учетом комиссии:{' '}
                      <span style={{ color: '#4872F2', fontWeight: 600 }}>
                        {order?.acquiringCommissions[0]?.amount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                        ₽
                      </span>
                    </ActionBlockDescription>
                  </>
                )}

                <ActionBlockButtons>
                  <Button
                    type={state.a.selected ? 'primary' : 'outlined'}
                    template={state.a.selected ? undefined : 'gray'}
                    disabled={state.a.disabledButton}
                    onClick={onPay}
                  >
                    Оплатить заказ
                  </Button>
                </ActionBlockButtons>
              </ActionBlockContent>
            </Flex>
            <ActionButtonMobile>
              <Button
                type={state.a.selected ? 'primary' : 'outlined'}
                template={state.a.selected ? undefined : 'gray'}
                disabled={state.a.disabled}
                onClick={onPay}
                data-cy="agentModalPayWithCardButton"
              >
                Оплатить заказ
              </Button>
            </ActionButtonMobile>
          </ActionBlock>
        )}
        {state.b && (
          <ActionBlock
            selected={state.b.selected && !state.b.disabled}
            disabled={state.b.disabled}
          >
            <Flex>
              <Checkbox
                type="roundWithArrow"
                data-cy="payWithDepositOrBillCheckbox"
                checked={state.b.selected}
                blueBorder
                onChange={(e) => {
                  handleChange('b');
                }}
              />
              <ActionBlockContent>
                <ActionBlockTitle>
                  Оплатить по счету или депозиту{' '}
                </ActionBlockTitle>
                {/* <ActionBlockDescription>
                  Выписка будет доступна после зачисления денежных средств. Срок
                  зачисления составляет <strong>от 1-го банковского дня</strong>
                  .
                </ActionBlockDescription>
                <Flex>
                  <InfoIcon style={{ marginTop: 2 }} />{' '}
                  <ActionBlockDescription
                    style={{ color: '#4872F2', marginBottom: 0 }}
                  >
                    Моментальная выписка при оплате в счет депозита.
                  </ActionBlockDescription>
                </Flex> */}
                {/* <StepList
                  items={[
                    'Сформируйте счёт для получения номера для оплаты заказа. Оплатите счет (при наличии положительного депозита оплата не требуется).',
                    'Оформите билет нажатием на кнопку “Выписать билеты”.',
                  ]}
                /> */}
                <ActionBlockDescription>
                  1. Нажмите на кнопку{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    “Сформировать счет”
                  </span>{' '}
                  и оплатите счёт (при положительном депозите оплата не
                  требуется).
                </ActionBlockDescription>
                <ActionBlockDescription>
                  2. Оформите заказ нажатием на кнопку{' '}
                  <span style={{ fontWeight: 'bold' }}>“Выписать заказ”.</span>{' '}
                  <br />
                  (кнопка доступна в шапке заказа после обработки платежа)
                </ActionBlockDescription>
                <BlueContainer style={{ marginTop: 20 }}>
                  <Flex>
                    <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
                    <ActionBlockDescription style={{ marginBottom: 0 }}>
                      Время обработки составляет от 1-го банковского дня. <br />
                      Моментальная выписка при оплате в счет депозита.
                    </ActionBlockDescription>
                  </Flex>
                </BlueContainer>
                <ActionBlockButtons>
                  <Button
                    disabled={state.b.disabledButton}
                    onClick={() => {
                      onCreateInvoice('createBill');
                    }}
                    type={state.b.selected ? 'primary' : 'outlined'}
                    template={state.b.selected ? undefined : 'gray'}
                  >
                    Сформировать счет
                  </Button>
                  {/* <Button
                    disabled={state.b.disabledButton}
                    onClick={() => onExtract(false)}
                    type={state.b.selected ? 'primary' : 'outlined'}
                    template={state.b.selected ? undefined : 'gray'}
                  >
                    Выписать билеты
                  </Button> */}
                </ActionBlockButtons>
              </ActionBlockContent>
            </Flex>
            <ActionButtonMobile>
              <Button
                disabled={state.b.disabledButton}
                onClick={() => {
                  onCreateInvoice('createBill');
                }}
                type={state.b.selected ? 'primary' : 'outlined'}
                template={state.b.selected ? undefined : 'gray'}
              >
                Сформировать счет
              </Button>
              {/* <Button
                disabled={state.b.disabledButton}
                onClick={() => onExtract(false)}
                type={state.b.selected ? 'primary' : 'outlined'}
                template={state.b.selected ? undefined : 'gray'}
              >
                Выписать билеты
              </Button> */}
            </ActionButtonMobile>
          </ActionBlock>
        )}
        {state.c && (
          <ActionBlock selected={state.c.selected} disabled={state.c.disabled}>
            <Flex>
              <Checkbox
                type="roundWithArrow"
                data-cy="payWithCreditCheckbox"
                checked={state.c.selected}
                blueBorder
                onChange={(e) => {
                  handleChange('c');
                }}
              />
              <ActionBlockContent>
                <ActionBlockTitle>Оплатить в кредит</ActionBlockTitle>

                <ErrorDescription>
                  Данный способ не доступен пока остаток по кредитному лимиту
                  меньше суммы заказа. Необходимо погасить долг.
                </ErrorDescription>
                {/* 
                <ActionBlockDescription>
                  Выписка будет доступна сразу после формирования счета.
                </ActionBlockDescription> */}
                {/* <StepList
                  items={[
                    'Сформируйте счёт для получения номера для оплаты заказа. ',
                    'Оформите билет нажатием на кнопку “Выписать билеты в кредит”.',
                    'Оплатите счет в течение 3-х рабочих дней.',
                  ]}
                /> */}
                <ActionBlockDescription>
                  1. Нажмите на кнопку{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    “Оплатить в кредит”
                  </span>{' '}
                  и оплатите счет в течение{' '}
                  <span style={{ fontWeight: 'bold' }}>3-х рабочих дней.</span>
                </ActionBlockDescription>
                <BlueContainer style={{ marginTop: 20 }}>
                  <Flex>
                    <InfoIcon style={{ marginRight: 5, marginTop: 2 }} />
                    <ActionBlockDescription style={{ marginBottom: 0 }}>
                      При оплате в кредит происходит автоматическая выписка
                      билетов по заказу. <br /> Дополнительных действий не
                      требуется.
                    </ActionBlockDescription>
                  </Flex>
                </BlueContainer>
                <ActionBlockButtons>
                  <Button
                    disabled={state.c.disabledButton}
                    onClick={() => {
                      onCreateInvoice('credit');
                    }}
                    type={state.c.selected ? 'primary' : 'outlined'}
                    template={state.c.selected ? undefined : 'gray'}
                  >
                    Оплатить в кредит
                  </Button>
                  {/* <Button
                    disabled={state.c.disabledButton}
                    onClick={() => onExtract(true)}
                    type={state.c.selected ? 'primary' : 'outlined'}
                    template={state.c.selected ? undefined : 'gray'}
                  >
                    Выписать в кредит
                  </Button> */}
                </ActionBlockButtons>
              </ActionBlockContent>
            </Flex>
            <ActionButtonMobile>
              <Button
                disabled={state.c.disabledButton}
                onClick={() => {
                  onCreateInvoice('credit');
                }}
                type={state.c.selected ? 'primary' : 'outlined'}
                template={state.c.selected ? undefined : 'gray'}
              >
                Оплатить в кредит
              </Button>
              {/* <Button
                disabled={state.c.disabledButton}
                onClick={() => onExtract(true)}
                type={state.c.selected ? 'primary' : 'outlined'}
                template={state.c.selected ? undefined : 'gray'}
              >
                Выписать в кредит
              </Button> */}
            </ActionButtonMobile>
          </ActionBlock>
        )}
      </ActionsList>
    </StyledPopup>
  );
};

export default Modal;
