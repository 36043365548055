import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui/Typography';
import people1 from '../../assets/images/tui-page/main/people_1.svg';
import people2 from '../../assets/images/tui-page/main/people_2.svg';
import people3 from '../../assets/images/tui-page/main/people_3.svg';
import people4 from '../../assets/images/tui-page/main/people_4.svg';
import mobileBackground from './assets/media/background.png';
import { Button, Field } from '@components/ui';

import background from '../../assets/images/tui-page/main/background.png';

const Container = styled.div`
  width: 100%;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    margin-top: 100px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  color: #092a5e;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 60px;
  }
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  justify-content: center;

  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 100px;
  @media (max-width: 1024px) {
    background-position-y: 321px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
  @media (max-width: 1152px) {
    padding: 0 40px;
    & > div {
      margin-bottom: 90px;
    }
  }
`;

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 1024px) {
    img {
      width: 253px;
    }
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    max-width: 335px;
  }
`;

const SubTitle = styled(Text)`
  font-weight: 600;
  font-size: 28px;
  line-height: 37px;
  color: #092a5e;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
`;

const Description = styled(Text)`
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #3c3c3c;
  white-space: pre-line;
  text-align: left;
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    white-space: normal;
  }
`;

const SecondTitle = styled(Title)`
  margin-top: 140px;
  @media (max-width: 1024px) {
    margin-bottom: 30px;
    margin-top: 100px;
  }
`;

const ThirdTitleLevel = styled(Text)`
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #3c3c3c;
  margin-bottom: 45px;
  text-align: center;
  white-space: pre-line;

  @media (max-width: 1024px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    margin-bottom: 150px;
  }
`;

const Input = styled.input`
  width: 431px;
  height: 60px;
  margin-right: 20px;
  padding: 19px 14px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  border: none;
`;

const DesktopButton = styled.div`
  width: 279px;
  height: 60px;

  background: #092a5e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled(Text)`
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`;

const MobileContainer = styled.div`
  text-align: center;
  padding-top: 80px;
  position: relative;
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileTitle = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #092a5e;
`;

const ScrollBlock = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 20px 0;
  margin: 20px 0;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-bottom: 30px;
  margin-bottom: -30px;
  padding-top: 30px;
  margin-top: -30px;
  & > div {
    margin-right: 15px;
  }
`;

const Item = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 0px 5px 0px rgba(34, 60, 80, 0.2);
  padding: 20px 20px 0 20px;
  text-align: left;
  width: 280px;
  flex-shrink: 0;
  box-sizing: border-box;

  & > img {
    display: block;
  }
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #092a5e;
  display: block;
  margin-bottom: 15px;
`;

const ItemDescription = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: #000c2d;
  white-space: pre-line;
  display: block;
`;

const MobileSubcribeBlock = styled.div`
  padding: 20px;
`;

const MobileSubcribeTitle = styled(Text)`
  display: block;
  color: #092a5e;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: 301px;
`;
const MobileSubcribeDescription = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  margin-bottom: 30px;
  display: block;
`;

const MobileBackground = styled.div`
  background-image: url(${mobileBackground});
  background-size: 100% auto;
  background-position: bottom;
  padding-bottom: 178px;
  background-repeat: no-repeat;
`;

const Content = () => {
  return (
    <>
      <MobileContainer>
        <MobileTitle>Почему выбирают именно нас?</MobileTitle>
        <MobileBackground>
          <ScrollBlock>
            <ScrollContainer>
              <div style={{ width: 15 }} />
              <Item>
                <Name>Самые низкие цены</Name>
                <ItemDescription>
                  {`Бронируйте билеты в любую точку мира
              по выгодной стоимости, без наценок и 
              комиссии. Цена билета не изменится в
              ходе его бронирования, а наша система
              надежно защитит ваши данные при 
              онлайн-оплате.`}
                </ItemDescription>
                <img
                  width={142}
                  style={{ margin: '0 auto' }}
                  alt="logo"
                  src={people1}
                />
              </Item>
              <Item>
                <Name>Удобный и быстрый сервис</Name>
                <ItemDescription>
                  {`С помощью  легкой формы поиска вы
                можете найти перелеты на любую дату,
                сравнить цены на ближайшие дни,
                составить сложные и необычные
                маршруты, комбинируя рейсы разных
                авиакомпаний. `}
                </ItemDescription>
                <img
                  width={142}
                  style={{ margin: '0 0 0 auto' }}
                  alt="logo"
                  src={people2}
                />
              </Item>
              <Item>
                <Name>Проверенные партнеры</Name>
                <ItemDescription>
                  {`TUI Group — лидир на мировом
                туристическом рынке. Мы работаем
                только с надежными проверенными
                авиакомпаниями, гарантирует своим
                клиентам высокую степень комфорта
                и безопасности при перелете.`}
                </ItemDescription>
                <img
                  width={142}
                  style={{ margin: '0 auto' }}
                  alt="logo"
                  src={people3}
                />
              </Item>
              <Item>
                <Name>Круглосуточная поддержка</Name>
                <ItemDescription>
                  {`Наша профессиональная команда
                сотрудников окажет поддержку на всех
                этапах бронирования. Напишите нам,
                чтобы произвести обмен или возврат
                или проконсультироваться до
                оформления заказа.`}
                </ItemDescription>
                <img
                  width={142}
                  style={{ margin: '0 0 0 auto' }}
                  alt="logo"
                  src={people4}
                />
              </Item>
            </ScrollContainer>
          </ScrollBlock>
          <MobileSubcribeBlock>
            <MobileSubcribeTitle>
              Узнавайте о новых акциях и скидках
            </MobileSubcribeTitle>
            <MobileSubcribeDescription>
              Получайте самыми первыми информацию о скидках, снижении цен на
              билеты и спецпредложениях
            </MobileSubcribeDescription>
            <Field
              style={{ width: '100%', marginBottom: 30 }}
              placeholder="Ваша электронная почта"
            />
            <Button color="#092A5E" style={{ width: '100%' }}>
              Подписаться на рассылку
            </Button>
          </MobileSubcribeBlock>
        </MobileBackground>
      </MobileContainer>
      <Container>
        <Title>Почему выбирают именно нас?</Title>
        <BackgroundWrapper
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <InfoContainer>
            <Block>
              <TextBlock>
                <SubTitle>Самые низкие цены</SubTitle>
                <Description>
                  {`С помощью нашего сервиса вы сможете 
              забронировать билеты в любую точку мира по самой 
              выгодной стоимости, без наценок и комиссии. Мы
              гарантируем, что цена билета не изменится в ходе
              его бронирования, а наша система надежно защитит
              ваши платежные данные при онлайн-оплате`}
                </Description>
              </TextBlock>
              <img alt="logo" src={people1} />
            </Block>
            <Block>
              <img alt="logo" src={people2} />

              <TextBlock>
                <SubTitle>Удобный и быстрый сервис</SubTitle>
                <Description>
                  {`С помощью  легкой формы поиска вы можете найти
                 перелеты на любую дату, сравнить цены на
                  ближайшие дни, составить сложные и необычные
                   маршруты, комбинируя рейсы разных авиакомпаний
                   . В личном кабинете хранится список ваших заказов, 
а так же данные о пассажирах и документах, 
что упрощает процедуру бронирования.`}
                </Description>
              </TextBlock>
            </Block>
            <Block>
              <TextBlock>
                <SubTitle>Проверенные партнеры</SubTitle>
                <Description>
                  {`TUI Group — группа компаний, 
                занимающая лидирующие позиции на мировом туристическом
                 рынке. Мы сотрудничаем только с надежными
                  проверенными партнерами-авиакомпаниями,
                   гарантирует своим клиентам высокую степень
                    безопасности и комфорта при перелете.`}
                </Description>
              </TextBlock>

              <img alt="logo" src={people3} />
            </Block>

            <Block style={{ marginBottom: 0 }}>
              <img alt="logo" src={people4} />

              <TextBlock>
                <SubTitle>Круглосуточная поддержка</SubTitle>
                <Description>
                  {`Наша профессиональная команда сотрудников
                 незамедлительно отреагирует на ваш запрос окажет
                  поддержку на всех этапах бронирования. Напишите
                   нам, чтобы произвести обмен или возврат 
                   или проконсультироваться до оформления заказа.`}
                </Description>
              </TextBlock>
            </Block>
            <SecondTitle>Узнавайте о новых акциях и скидках</SecondTitle>
            <ThirdTitleLevel>{`Получайте самыми первыми информацию о скидках, снижении цен на билеты  
          и спецпредложениях от авиакомпаний`}</ThirdTitleLevel>
          </InfoContainer>
          <Row>
            <Input />
            <DesktopButton>
              <ButtonText>Подписаться на рассылку</ButtonText>
            </DesktopButton>
          </Row>
        </BackgroundWrapper>
      </Container>
    </>
  );
};

export default Content;
