import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import OfferBlock from '@components/booking/AddictionalServices/OfferBlocks/OfferBlock';
import {
  AddictionalServicesProps,
  Enums,
  ItemProps,
} from '@components/booking/AddictionalServices/types';
import {
  ServiceRulesModal as Modal,
  SmsServiceModal,
} from '@components/common/Modals';
import {
  useModalState,
  Provider,
} from '@components/order/Detail/AddictionalServices/context';
import SmsService from '../SmsService';
import PrintService from '../PrintService';
import ReturnService from '../ReturnService';
import {
  getAddictionalServices,
  getDetailOrderContactInfo,
} from '@modules/orders';
import AdditionalOfferBlock from './AdditionalOfferBlock';

export default () => (
  <Provider>
    <AgentOfferBlock />
  </Provider>
);

function AgentOfferBlock() {
  const {
    visibility,
    hideModal,
    smsModalVisibility,
    hideSmsModal,
    addSmsService,
    handleActionButtonClick,
    showSmsModal,
  } = useModalState();
  const addictionalServices = useSelector(getAddictionalServices);
  const user = useSelector(getDetailOrderContactInfo);

  function getUniqueItems(addictionalServices: any) {
    const uniqueIds = new Set(
      addictionalServices.items.map((item: any) => item.id)
    );

    const itemsWithUniqueIds = [...uniqueIds]
      .map((id) =>
        addictionalServices.items.find((item: any) => item.id === id)
      )
      .filter(Boolean);

    return itemsWithUniqueIds;
  }

  const dispatch = useDispatch();

  function getTemplate(x: ItemProps) {
    switch (x.type) {
      case Enums.SMS_Service:
        return <SmsService {...x} />;
      case Enums.Return_Guarantee:
        return <ReturnService {...x} />;
      case Enums.Payment_Receipt:
        return <PrintService {...x} />;
      default:
        return null;
    }
  }

  if (!getUniqueItems(addictionalServices).some((x) => x.checked === false)) {
    return null;
  }

  return (
    <>
      <AdditionalOfferBlock
        isLoading={getUniqueItems(addictionalServices).some(
          (x) => x.isLoading === true
        )}
        text={
          !getUniqueItems(addictionalServices).find(
            (x) => x.type === 'SMS_Service'
          )?.checked
            ? 'Получайте всю важную информацию о перелете в SMS-сообщениях!'
            : !getUniqueItems(addictionalServices).find(
                (x) => x.type === 'Return_Guarantee'
              )?.checked
            ? 'Вернем деньги за билет в случае отмены вашей поездки!'
            : !getUniqueItems(addictionalServices).find(
                (x) => x.type === 'Payment_Receipt'
              )?.checked
            ? 'Для компенсации оплаты билетов по рабочей поездке!'
            : ''
        }
        dataCy={'chooseSeatPlaceInRightPanel'}
        type={
          !getUniqueItems(addictionalServices).find(
            (x) => x.type === 'SMS_Service'
          )?.checked
            ? 'SMS'
            : !getUniqueItems(addictionalServices).find(
                (x) => x.type === 'Return_Guarantee'
              )?.checked
            ? 'Return'
            : !getUniqueItems(addictionalServices).find(
                (x) => x.type === 'Payment_Receipt'
              )?.checked
            ? 'Receipt'
            : 'SMS'
        }
        onClick={() =>
          !getUniqueItems(addictionalServices).find(
            (x) => x.type === 'SMS_Service'
          ).checked
            ? showSmsModal()
            : !getUniqueItems(addictionalServices).find(
                (x) => x.type === 'Return_Guarantee'
              ).checked
            ? handleActionButtonClick(
                getUniqueItems(addictionalServices).find(
                  (x) => x.type === 'Return_Guarantee'
                )
              )
            : handleActionButtonClick(
                getUniqueItems(addictionalServices).find(
                  (x) => x.type === 'Payment_Receipt'
                )
              )
        }
      />
      <Modal open={visibility !== null} onClose={hideModal} type={visibility} />
      <SmsServiceModal
        onSubmit={(data) => {
          addSmsService(data);
        }}
        open={smsModalVisibility}
        onClose={hideSmsModal}
        user={user}
      />
    </>
  );
}
