import React from 'react';
import { RailwayMainTicketInfo } from '@components/train/TrainSearch/Ticket/RailwayMainTicketInfo';
import { Train } from '@modules/trainTickets';
import RouteDetailItem from '@components/common/RouteDetail/RouteDetailItem';

interface Props {
  train?: Train;
  carNumber: string;
  direction: 'forward' | 'backward';
  carPlaces: string[];
}

const RailwayRouteDetailItem: React.FC<Props> = (props) => {
  const { train, carNumber, direction, carPlaces } = props;

  if (!train) {
    return null;
  }

  return (
    <RouteDetailItem
      routeType={'railway'}
      direction={direction}
      fromStation={train.fromStation.city.name_ru}
      toStation={train.toStation.city.name_ru}
      transportNumber={carNumber}
      seats={{
        numbers: carPlaces,
        needRenderAsCount: false,
      }}
      mainTicketInfo={
        <RailwayMainTicketInfo
          noCarServices
          noFeatures
          noServices
          train={train}
        />
      }
      additionalOptions={{
        isRailwayTransit: train.isTransit,
      }}
    />
  );
};
export default RailwayRouteDetailItem;
