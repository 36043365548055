import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';

export const useCommonSearchData = () => {
  const data = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm,
  );
  const dataBus = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchFormBus,
  );

  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType,
  );

  const { passengers, from, to, dates, ticketClass } = data;
  const adultsCount = passengers.adults.count;
  const childrenCount = passengers.children.count;
  const infantsCount = passengers.infants.count;
  const allPassengersIsZero =
    adultsCount === 0 && childrenCount === 0 && infantsCount === 0;
  const dummyAdultNumber = 1;

  return {
    data,
    dataBus,
    searchPanelType,
    passengers,
    from,
    to,
    dates,
    ticketClass,
    adultsCount,
    childrenCount,
    infantsCount,
    allPassengersIsZero,
    dummyAdultNumber
  };
};