import React from 'react';
import * as Styled from './styles/Result.style';
import { Button } from '@components/ui';
import { useDispatch } from 'react-redux';
import { APPEAL_STEP, setRefundStep } from '@modules/appeals';
import { useProps } from './useProps';
import SuccessOrderIcon from '@assets/images/SuccessOrder';

const CreateFormRefundSuccess: React.FC = () => {
  const { onClose } = useProps();

  const handleClick = () => {
    onClose && onClose();
  };

  return (
    <Styled.Container>
      <Styled.Title>
        Расчёт одобрен{' '}
        <SuccessOrderIcon style={{ height: 32, width: 32, marginLeft: 8 }} />
      </Styled.Title>

      <Styled.Description>
        Вами одобрен расчёт на возврат денежных средств. Денежные средства
        поступят на ваш счет в течении 3х рабочих дней.
      </Styled.Description>

      <Styled.ButtonGroup>
        <Button
          onClick={handleClick}
          type={'outlined'}
          template={'blue'}
          style={{ paddingInline: '20px' }}
        >
          Вернуться к заказу
        </Button>
      </Styled.ButtonGroup>
    </Styled.Container>
  );
};

const CreateFormRefundDecline: React.FC = () => {
  const dispatch = useDispatch();
  const { onClose } = useProps();

  const handleConfirm = () => {
    onClose && onClose();
  };

  const handleReturn = () => {
    dispatch(setRefundStep(APPEAL_STEP.REFUND_CONFIRM));
  };

  return (
    <Styled.Container>
      <Styled.Title>Отклонить расчёт</Styled.Title>

      <Styled.Description>
        Вы уверены, что хотите отклонить расчёт на возврат по данному заказу?
      </Styled.Description>

      <Styled.ButtonGroup>
        <Button
          onClick={handleConfirm}
          type={'outlined'}
          template={'blue'}
          style={{ paddingInline: '20px' }}
        >
          Да, отклонить расчёт
        </Button>
        <Button
          onClick={handleReturn}
          type={'outlined'}
          template={'blue'}
          style={{ paddingInline: '20px' }}
        >
          Нет, вернуться к расчёту
        </Button>
      </Styled.ButtonGroup>
    </Styled.Container>
  );
};

export { CreateFormRefundSuccess, CreateFormRefundDecline };
