import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import { ReactComponent as SeatMapIcon } from '@assets/media/addictional-services/seat-map.svg';
import { ReactComponent as RecommendIcon } from '@assets/media/addictional-services/dont-miss.svg';
import { ReactComponent as TriangleIcon } from '../assets/media/triangle.svg';
import { useModalState } from '../context';
import { Enums } from '../types';
import { useSelector } from 'react-redux';
import { getSeatMapState, getSeatsGroupedByPassengers } from '@modules/booking';
import { ApplicationState } from '@modules/index';

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
    padding-top: 0;
  }
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4872f2;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Name} {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }

  ${Price} {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileHeadLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const DesktopIcon = styled(SeatMapIcon)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const Description = styled(Text)`
  display: block;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

const Action = styled(Button).attrs({ htmlType: 'button' })`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;
  min-width: 153px;
`;

const Label = styled(RecommendIcon)`
  position: absolute;
  right: -117px;
  top: -3px;
`;

const DesktopLabel = styled(Label)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileLabel = styled(Label)`
  top: -12px;
  right: -4px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const List = styled.div`
  margin: 20px 0 15px 0;
  & > div {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const SegmentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const SegmentHeadRight = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 5px;
  }
`;

const SegmentPrice = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: right;

  color: #4872f2;
`;

const Segment = styled.div<{ open: boolean }>`
  ${({ open }) => {
    if (!open) {
      return `
        ${SegmentHeadRight} {
           svg {
            transform: rotate(180deg);
          }
        }
        ${PassengerList} {
          display: none;
        }
      `;
    }
  }}
`;

const SegmentName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const PassengerList = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Passenger = styled.div``;

const PassengerHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PassengerText = styled(Text)`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const OffersList = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 8px;
  & > div {
    margin-bottom: 5px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const Offer = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  display: block;
`;

export default function () {
  const { showModal } = useModalState();
  const { minPrice } = useSelector(getSeatMapState);
  const segments = useSelector(getSeatsGroupedByPassengers);
  const seatMapState = useSelector(getSeatMapState);

  const [openPassengerIndexes, setOpen] = React.useState(
    {} as { [key: string]: boolean }
  );

  return (
    <Wrapper data-cy={'additionalServiceWrapperSeats'}>
      <MobileLabel />
      <DesktopIcon />
      <Content>
        <Head>
          <Name>
            Место в самолете <DesktopLabel />
          </Name>
          <Price data-cy={`additionalServicePriceSeats`}>
            {seatMapState.totalPrice === 0
              ? 'от ' + Helper.formatPrice(minPrice)
              : seatMapState.totalPrice
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'}
          </Price>
        </Head>
        <MobileHead>
          <MobileHeadLeftSide>
            <SeatMapIcon /> <Name>Место в самолете</Name>
          </MobileHeadLeftSide>
          <Price data-cy={`additionalServicePriceSeats`}>
            {seatMapState.totalPrice === 0
              ? 'от ' + Helper.formatPrice(minPrice)
              : seatMapState.totalPrice
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'}
          </Price>
        </MobileHead>
        <Description>
          Застрахуйте себя от случайной рассадки в самолете. Выберите место
          прямо сейчас и получайте удовольствие от комфортного полета.
        </Description>
        {segments.length > 0 && (
          <List>
            {segments.map((x) => (
              <Segment key={x.key} open={openPassengerIndexes[x.key]}>
                <SegmentHead
                  onClick={() => {
                    setOpen((s) => ({
                      ...s,
                      [x.key]: s[x.key] ? !s[x.key] : true,
                    }));
                  }}
                >
                  <SegmentHeadRight>
                    <SegmentName>{x.name}</SegmentName>
                    <TriangleIcon />
                  </SegmentHeadRight>
                  <SegmentPrice data-cy={`additionalServiceSegmentPriceSeats`}>
                    {Helper.formatPrice(x.price)}
                  </SegmentPrice>
                </SegmentHead>
                <PassengerList>
                  {x.items.map((y) => (
                    <Passenger key={y.key}>
                      <PassengerHead>
                        <PassengerText>{y.name}</PassengerText>
                        <PassengerText
                          data-cy={`additionalServicePriceChosenSeat`}
                        ></PassengerText>
                      </PassengerHead>
                      <OffersList>
                        <Offer>{y.number}</Offer>
                        <Offer> {Helper.formatPrice(y.price)}</Offer>
                      </OffersList>
                    </Passenger>
                  ))}
                </PassengerList>
              </Segment>
            ))}
          </List>
        )}

        <Action
          data-cy={`additionalServiceSeatMap`}
          onClick={() => showModal(Enums.SeatMap)}
          color={segments.length > 0 ? 'gray' : undefined}
        >
          {segments.length > 0 ? 'Изменить выбор' : 'Выбрать место'}
        </Action>
      </Content>
    </Wrapper>
  );
}
