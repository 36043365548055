import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import {
  SearchBlock,
  Footer,
  CompositeSearchBlock,
} from '@components/tui-page';
import Header from '@components/tui-page/Header';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initializeSimpleSearch } from '@modules/simpleSearch';
import { ApplicationState } from '@modules/index';
import NetworkStatus from '@services/NetworkStatus';
import OfflinePanel from '@components/OfflinePanel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Main = styled.main<LinkProps>`
    flex: 1;
    display: ${({ isActive }) => (isActive ? 'block' : 'flex')}
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${({ isActive }) => (isActive ? 'auto' : '0')} & > * {
    width: 100%;
}
`;

interface LinkProps {
  isActive: boolean;
}

const TuiTemplateWrapper: React.FC<{
  children: any;
  withoutSearch?: boolean;
  mainPage?: boolean;
  staticMobileMenu?: boolean;
}> = (props) => {
  const dispatch = useDispatch();
  const isOnlyTrain = useSelector(
    (state: ApplicationState) => state.user.isOnlyTrain,
  );
  const isOnlyBus = useSelector(
    (state: ApplicationState) => state.user.isOnlyBus,
  );
  const isOffline = useSelector(
    (state: ApplicationState) => state.ui.isOffline,
  );
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1024 });
  const location = useLocation();

  React.useEffect(() => {
    if (!location.pathname.includes('/search')) {
      dispatch(initializeSimpleSearch());
    }
  }, []);

  const withSearch = !isMobileOrTablet
    ? !props.withoutSearch
    : isMobileOrTablet &&
        !(
          location.pathname === '/' ||
          location.pathname.includes('/search') ||
          location.pathname.includes('/train-search') ||
          location.pathname.includes('/bus-search') ||
          location.pathname.includes('/booking')
        )
      ? false
      : true;

  return (
    <Container>
      <NetworkStatus />
      {location.pathname.includes('SuccessNew') ||
      location.pathname.includes('FailNew') ? null : (
        <Header staticPosition={props.staticMobileMenu} />
      )}

      {withSearch ? (
        process.env.REACT_APP_MODE === 'test' ||
        !process.env.REACT_APP_MODE ||
        isOnlyTrain ||
        isOnlyBus ? (
          <CompositeSearchBlock
            isOnlyTrain={isOnlyTrain}
            isOnlyBus={isOnlyBus}
          />
        ) : (
          <SearchBlock mainPage={props.mainPage} />
        )
      ) : (
        <span />
      )}
      {!isOffline ? (
        <Main
          id={'main-template'}
          isActive={location.pathname.includes('SuccessNew')}
        >
          {props.children}
        </Main>
      ) : (
        <OfflinePanel />
      )}
      {location.pathname.includes('SuccessNew') ||
      location.pathname.includes('FailNew') ? null : (
        <Footer />
      )}
    </Container>
  );
};

export default TuiTemplateWrapper;
