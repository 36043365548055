import React from 'react';
import styled from 'styled-components';
import { Separator } from '@components/common';
import { PassengerListProps, FormikFields } from './types';
import PassengerItem from './AviaPassengerItem';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';

const Container = styled.div`
  & > div {
    margin: 24px 0;
    margin-bottom: 0;
    @media (max-width: 768px) {
      margin: 0;
    }
  }
`;

const AviaPassengerList: React.FC<PassengerListProps> = ({
  onChange,
  countriesList,
  setIsShowError,
}) => {
  const formikContext = useFormikContext<FormikFields>();
  const frequentFlyerAirlines = useSelector(
    (state: ApplicationState) => state.booking.ticket.frequentFlyerAirlines
  );

  return (
    <Container>
      {formikContext.values.passengers.map((props, key) => {
        return (
          <React.Fragment key={props.uid}>
            <PassengerItem
              setIsShowError={setIsShowError}
              index={key}
              countriesList={countriesList}
              frequentFlyerAirlines={frequentFlyerAirlines}
              onChange={(data) => {
                onChange(data, key);
              }}
              {...props}
            />
            {key !== formikContext.values.passengers.length - 1 && (
              <Separator />
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};
export default AviaPassengerList;
