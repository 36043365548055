import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4 1.35H11.25V0.15C11.25 0.0675 11.1825 0 11.1 0H10.05C9.9675 0 9.9 0.0675 9.9 0.15V1.35H5.1V0.15C5.1 0.0675 5.0325 0 4.95 0H3.9C3.8175 0 3.75 0.0675 3.75 0.15V1.35H0.6C0.268125 1.35 0 1.61813 0 1.95V14.4C0 14.7319 0.268125 15 0.6 15H14.4C14.7319 15 15 14.7319 15 14.4V1.95C15 1.61813 14.7319 1.35 14.4 1.35ZM13.65 13.65H1.35V6.525H13.65V13.65ZM1.35 5.25V2.7H3.75V3.6C3.75 3.6825 3.8175 3.75 3.9 3.75H4.95C5.0325 3.75 5.1 3.6825 5.1 3.6V2.7H9.9V3.6C9.9 3.6825 9.9675 3.75 10.05 3.75H11.1C11.1825 3.75 11.25 3.6825 11.25 3.6V2.7H13.65V5.25H1.35Z" />
    </svg>
  );
};

export default Logo;
