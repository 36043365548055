import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { mainReducer } from './main';
import { simpleSearchReducer } from './simpleSearch';
import { booking } from './booking';
import { orders } from './orders';
import { appeals } from './appeals';
import { user } from './user';
import { ui } from './ui';
import { notification } from './notification';
import { personalArea } from './personalArea';
import { references } from './references';
import { trainTickets } from './trainTickets';
import { busTickets } from './busTickets';
import { busBooking } from './busBooking';

import { trainBooking } from './trainBooking';
import { trainOrders } from './trainOrders';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './rootSaga';
import * as Sentry from '@sentry/react';
import { agents } from '@modules/agents';

const reducer = combineReducers({
  mainReducer,
  searchReducer: simpleSearchReducer,
  booking,
  orders,
  appeals,
  user,
  personalArea,
  notification,
  ui,
  references,
  agents,
  trainTickets,
  trainBooking,
  trainOrders,
  busTickets,
  busBooking,
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    Sentry.captureException(error);
  },
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
);

export type ApplicationState = ReturnType<typeof reducer>;

export default store;

sagaMiddleware.run(rootSaga);
export { DocumentTypesTitleArray } from '@modules/trainOrders/utils';
