import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Helper } from '@utils';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  City,
  setDestination,
  setDestinationBus,
  getCityListByStr,
  getCityListByStrTrain,
  getCityListByStrDefault,
  getCityListByStrBus,
  search,
  BusSearchDto,
  BusSearch,
} from '@modules/simpleSearch';
import Field from './Field';
import styled from 'styled-components';
import Arrow from './Arrow';
import { Suggestion } from './suggestion';
import { debounce } from 'lodash';
import { FocusType, useFocus } from '../useFormFocus';
import { SEARCH_PANEL_TYPES } from '@modules/ui/types';
import { trainStationDtoToAviaCityDto } from '@modules/simpleSearch/utils';
import { ApplicationState } from '@modules/index';
import { getNotification } from '@modules/notification';
import { SuggestionBus } from './suggestionBus';
import { StationBus } from '@app/modules/busTickets';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 0;

  & > :first-child > input {
    border-radius: 4px 0 0 4px;
  }

  @media (max-width: 1169px) {
    & > div {
      width: 49%;

      & > input {
        border-radius: 4px;
      }
    }

    & > svg {
      position: relative;
      left: -100px;
    }

    & > :first-child > input {
      border-radius: 4px;
    }

    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    & > div {
      width: 100%;
    }

    & > :first-child {
      margin-bottom: 10px;
    }

    width: 100%;
    flex-direction: column;
  }
`;

const ArrowContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  @media (max-width: 425px) {
    left: -7px;
    bottom: 14px;
  }
`;

export interface CityFieldProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  errors: {
    from?: string;
    to?: string;
  };
}

const CityFields: React.FC<CityFieldProps & React.PropsWithChildren> = ({
  onChange,
  onBlur,
  children,
  errors,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isInsideSuggestion, setInsideSuggestion] = useState(true);
  const ref = useRef(null as null | HTMLDivElement);
  const inputRef = useRef(null as null | HTMLInputElement);
  const abortControllerRef = useRef(new AbortController());
  const [suggestionList, setSuggestionList] = React.useState([] as City[]);
  const [suggestionListBus, setSuggestionListBus] = React.useState(
    [] as BusSearch[],
  );

  const [transformedData, setTransformedData] = React.useState(false);
  const { focus, setFocus } = useFocus();
  const [suggestionIndex, setSuggestionIndex] = useState(null as number | null);

  const [{ origin, destination }, setValues] = useState({
    origin: { name: '', code: '' },
    destination: { name: '', code: '' },
  });

  const searchPanelType = useSelector(
    (state: ApplicationState) => state.ui.searchPanelType,
  );
  const simpleSearchForm = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm,
  );
  const { from, to, dates } = simpleSearchForm;

  const simpleSearchFormBus = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchFormBus,
  );

  const { fromBus, toBus } = simpleSearchFormBus;

  const data = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchForm,
  );

  const history = useHistory();

  useEffect(() => {
    setValues({
      origin: { name: '', code: '' },
      destination: { name: '', code: '' },
    });
  }, [searchPanelType]);

  const loadSuggestions = useCallback(
    debounce(
      (value: string, type: SEARCH_PANEL_TYPES, signal: AbortController) => {
        try {
          if (type === 'avia' && value !== '') {
            getCityListByStr(value, signal)
              .then((data) => {
                setSuggestionList(data.data);
              })
              .catch(() => {});
          } else if (type === 'avia' && value === '') {
            getCityListByStrDefault()
              .then((data) => {
                setSuggestionList(data);
              })
              .catch(() => {});
          } else if (type === 'train') {
            getCityListByStrTrain(value, signal)
              .then((data) => {
                const transformedData = trainStationDtoToAviaCityDto(data);
                setSuggestionList(transformedData);
              })
              .catch(() => {});
          } else if (type === 'bus') {
            getCityListByStrBus(value, signal)
              .then((data) => {
                const filteredData = data.filter((x) => x.isActive === true);
                setSuggestionListBus(filteredData);
              })
              .catch(() => {});
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      },
      200,
    ),
    [],
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const MAX_INDEX =
        suggestionList.length > 6 ? 5 : suggestionList.length - 1;
      const key = e.key;
      setSuggestionIndex((state) => {
        let result: null | number = null;
        if (key === 'ArrowDown') {
          result = state !== null ? (state > MAX_INDEX ? 0 : ++state) : 0;
        } else {
          result =
            state !== null ? (state === 0 ? MAX_INDEX : --state) : MAX_INDEX;
        }
        // console.log(state, state > 5 ? 0 : state + 1);
        return result;
      });
    }
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const index = suggestionIndex !== null ? suggestionIndex : 0;
      if (focus === 'destination' && suggestionList.length > 0) {
        dispatch(setDestination({ to: suggestionList[index] }));

        setValues((state) => {
          return {
            ...state,
            destination: {
              name: suggestionList[index].nameRus,
              code: suggestionList[index].iataCode,
            },
          };
        });
        if (dates.from === null && window.innerWidth > 1023) {
          setFocus('forward');
        } else if (searchPanelType === 'avia') {
          history.push(
            `/search/${Helper.createSearchParams({
              fromCode: (data.from as any).iataCode,
              ToCode: (suggestionList[index] as any).iataCode,
              forwardDate: data.dates.from || moment(),
              backwardDate: data.dates.to,
              adults: data.passengers.adults.count,
              children: data.passengers.children.count,
              infants: data.passengers.infants.count,
              flightClass: data.ticketClass.id,
            })}`,
          );
          setSuggestionList([]);
        }
        (inputRef.current as HTMLInputElement).blur();
      } else if (suggestionList.length > 0) {
        dispatch(setDestination({ from: suggestionList[index] }));

        setValues((state) => {
          return {
            ...state,
            origin: {
              name: suggestionList[index].nameRus,
              code: suggestionList[index].iataCode,
            },
          };
        });
        if (searchPanelType === 'avia' && destination.code) {
          history.push(
            `/search/${Helper.createSearchParams({
              fromCode: (suggestionList[index] as any).iataCode,
              ToCode: (data.to as any).iataCode,
              forwardDate: data.dates.from || moment(),
              backwardDate: data.dates.to,
              adults: data.passengers.adults.count,
              children: data.passengers.children.count,
              infants: data.passengers.infants.count,
              flightClass: data.ticketClass.id,
            })}`,
          );
          setSuggestionList([]);
        }
        if (destination.code === '' && window.innerWidth > 860) {
          setFocus('destination');
          (inputRef.current as HTMLInputElement).focus();
        } else if (window.innerWidth > 860 && dates.from === null) {
          setFocus('forward');
        } else {
          setFocus(null);
          (inputRef.current as HTMLInputElement).blur();
        }
      }
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const target = e.target;
      const name = e.target.dataset.value as 'origin' | 'destination';
      const { value } = target;
      setValues((state) => {
        return name === 'origin'
          ? { ...state, origin: { name: value, code: '' } }
          : { ...state, destination: { name: value, code: '' } };
      });
      setFocus(name);
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      loadSuggestions(
        value,
        searchPanelType,
        abortControllerRef.current as AbortController,
      );
    },
    [searchPanelType],
  );

  const swap = useCallback(() => {
    // setFocus(null);
    dispatch(setDestination({ from: to, to: from }));
    setValues({ destination: { ...origin }, origin: { ...destination } });
  }, [from, to, dispatch]);

  const canSwap = useMemo(() => {
    return from !== '' && to !== '';
  }, [from, to]);

  const handleArrowClick = useCallback(() => {
    if (canSwap) {
      swap();
    }
  }, [swap, canSwap]);

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!isInsideSuggestion) {
      const name = e.target.dataset.value as string;
      const obj = focus === 'origin' ? from : to;
      const index = suggestionIndex !== null ? suggestionIndex : 0;
      if (typeof obj === 'string') {
        // search
        if (suggestionList.length > 0) {
          if (name === 'origin') {
            dispatch(
              setDestination({
                from: suggestionList[index],
              }),
            );
          }
          if (name === 'destination') {
            dispatch(setDestination({ to: suggestionList[index] }));
          }
        }
      }
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      setSuggestionList([]);
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.target;
    const name = target.dataset.value as 'origin' | 'destination';
    if (target.value !== '') {
      target.select();
    }
    if (target.value === '' && searchPanelType === 'avia') {
      getCityListByStrDefault()
        .then((data) => {
          setSuggestionList(data);
        })
        .catch(() => {});
    }
    setFocus(name);
  };

  useEffect(() => {
    setSuggestionList([]);
  }, [focus]);

  useEffect(() => {
    if (
      typeof from === 'object' &&
      (origin.name !== from.nameRus ||
        origin.name !== (fromBus as StationBus).nameRu)
    ) {
      setValues((state) => ({
        ...state,
        origin: {
          name:
            searchPanelType !== 'bus'
              ? from.nameRus
              : (fromBus as StationBus).nameRu,
          code:
            searchPanelType !== 'bus'
              ? from.iataCode
              : (fromBus as StationBus).guid,
        },
      }));
    }
    if (
      typeof to === 'object' &&
      (destination.name !== to.nameRus ||
        destination.name !== (toBus as StationBus).nameRu)
    ) {
      setValues((state) => ({
        ...state,
        destination: {
          name:
            searchPanelType !== 'bus'
              ? to.nameRus
              : (toBus as StationBus).nameRu,
          code:
            searchPanelType !== 'bus'
              ? to.iataCode
              : (toBus as StationBus).guid,
        },
      }));
    }
  }, [from, to]);

  const handleSelect = React.useCallback(
    (x: City, focus: FocusType) => {
      if (focus === 'origin') {
        if (
          window.innerWidth > 860 &&
          (destination.code === null || destination.code === '')
        ) {
          (inputRef.current as any).focus();
          setFocus('destination');
        } else {
          setFocus(null);
        }
        dispatch(setDestination({ from: x }));
        setValues((state) => ({
          ...state,
          origin: { name: x.nameRus, code: x.iataCode },
        }));
      } else {
        if (window.innerWidth > 860 && dates.from === null) {
          setFocus('forward');
        } else {
          setFocus(null);
        }

        (inputRef.current as any).blur();

        setInsideSuggestion(false);
        dispatch(setDestination({ to: x }));

        setValues((state) => ({
          ...state,
          destination: { name: x.nameRus, code: x.iataCode },
        }));
      }
    },
    [dates.from],
  );

  const handleSelectBus = React.useCallback(
    (x: StationBus, focus: FocusType) => {
      if (focus === 'origin') {
        if (
          window.innerWidth > 860 &&
          (destination.code === null || destination.code === '')
        ) {
          (inputRef.current as any).focus();
          setFocus('destination');
        } else {
          setFocus(null);
        }
        dispatch(setDestinationBus({ fromBus: x }));

        setValues((state) => ({
          ...state,
          origin: { name: x.nameRu, code: x.guid },
        }));
      } else {
        if (window.innerWidth > 860 && dates.from === null) {
          setFocus('forward');
        } else {
          setFocus(null);
        }

        (inputRef.current as any).blur();

        setInsideSuggestion(false);
        dispatch(setDestinationBus({ toBus: x }));

        setValues((state) => ({
          ...state,
          destination: { name: x.nameRu, code: x.guid },
        }));
      }
    },
    [dates.from],
  );

  return (
    <Wrapper ref={ref}>
      <Field
        id={'whereFromSearchField'}
        data-cy="whereFromSearchField"
        active={focus === 'origin'}
        label="Откуда"
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        value={origin.name}
        error={errors.from}
        code={searchPanelType === 'avia' ? origin.code : undefined}
        type="text"
        data-value="origin"
        autoComplete="off"
        {...props}
      />
      <ArrowContainer>
        <Arrow className={canSwap ? 'active' : ''} onClick={handleArrowClick} />
      </ArrowContainer>
      <Field
        id={'whereToSearchField'}
        data-cy="whereToSearchField"
        label="Куда"
        active={focus === 'destination'}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        value={destination.name}
        onFocus={handleFocus}
        data-value="destination"
        error={errors.to}
        code={searchPanelType === 'avia' ? destination.code : undefined}
        inputRef={inputRef}
        autoComplete="off"
        {...props}
      />
      {suggestionList.length > 0 && (
        <Suggestion
          onMouseEnter={() => {
            setInsideSuggestion(true);
          }}
          onMouseLeave={() => {
            setInsideSuggestion(false);
          }}
          searchPanelType={searchPanelType}
          currentFocus={focus}
          onSelect={handleSelect}
          items={suggestionList}
          suggestionIndex={suggestionIndex}
          setSuggestionIndex={setSuggestionIndex}
        />
      )}
      {suggestionListBus.length > 0 && (
        <SuggestionBus
          onMouseEnter={() => {
            setInsideSuggestion(true);
          }}
          onMouseLeave={() => {
            setInsideSuggestion(false);
          }}
          searchPanelType={searchPanelType}
          currentFocus={focus}
          onSelect={handleSelectBus}
          items={suggestionListBus}
          suggestionIndex={suggestionIndex}
          setSuggestionIndex={setSuggestionIndex}
        />
      )}
    </Wrapper>
  );
};

export default CityFields;
