import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@components/ui';
import CharterTariffsModal from '@components/common/Modals/CharterTariffsModal';
import { Text } from '@components/ui/Typography';
import { FareCondition } from '@components/common';

interface Props {
  isCharter: boolean;
  agreementToMailing: boolean;
  fareFamily?: {
    name: string;
    description: string;
  };
}

const TextRow = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px !important;

  @media (max-width: 768px) {
    margin-top: 14px;
  }
`;

const Agreement = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-left: 8px;
`;

const AgreementBlue = styled.a`
  color: rgba(72, 114, 242, 1);
  text-decoration: none;
  cursor: pointer;
`;

const AgreementBlock: React.FC<Props> = (props) => {
  const { isCharter, agreementToMailing, fareFamily } = props;
  const [popup, setPopup] = React.useState(false);

  const handleClose = () => {
    setPopup(false);
  };

  const handleOpen = () => {
    setPopup(true);
  };

  return (
    <div>
      <TextRow>
        <Checkbox disabled type="squar" checked />
        <Agreement>
          На этапе бронирования заказа Вы ознакомились и согласились с{' '}
          <AgreementBlue href="/docs/oferta.pdf" target="_blank">
            договором публичной оферты
          </AgreementBlue>
          ,{' '}
          <AgreementBlue onClick={handleOpen}>
            условиями применения тарифов и
          </AgreementBlue>{' '}
          <AgreementBlue href="/docs/oferta.pdf" target="_blank">
            политикой в отношении обработки персональных данных.
          </AgreementBlue>
        </Agreement>
      </TextRow>
      {isCharter && (
        <TextRow>
          <Checkbox disabled type="squar" checked />
          <Agreement>
            Я согласен(а) с{' '}
            <AgreementBlue onClick={handleOpen}>
              условиями применения тарифов перевозки
            </AgreementBlue>
            {'.'} Я предупреждён, что в исключительных случаях возможна замена
            перевозчика, типа воздушного судна на аналогичные услуги без
            взимания какой-либо доплаты.
          </Agreement>
        </TextRow>
      )}
      <TextRow>
        <Checkbox type="squar" checked={agreementToMailing} />
        <Agreement>Я согласен(а) на получение email-рассылки.</Agreement>
      </TextRow>

      {isCharter ? (
        <CharterTariffsModal open={popup} onClose={handleClose} />
      ) : (
        <FareCondition
          open={popup}
          onClose={handleClose}
          routes={fareFamily ? [fareFamily] : []}
          fare={fareFamily?.name || ''}
        />
      )}
    </div>
  );
};

export default AgreementBlock;
