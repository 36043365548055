import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CheckboxGroupProps,
  SubgroupValType,
  CheckboxValProp,
  ValsState,
  ValStateGroup,
  ValStateItem,
} from './types';
import ScrollBar from './scroll-bar';
import SubGroup from './subgroup';
import Checkbox from './checkbox';

const CheckboxContainer = styled.div`
  & label {
    display: block;
    margin-bottom: 15px;
  }
  & label:last-of-type {
    margin-bottom: 0;
  }
`;

const Group: React.FC<CheckboxGroupProps> = ({
  values,
  scrollSubGroup = false,
  scrollContent = false,
  type,
  onChange,
  groupType,
}) => {
  const [vals, setVals] = useState(
    values.map((val) => {
      if (Array.isArray((val as SubgroupValType).values)) {
        return {
          name: (val as SubgroupValType).name,
          values: (val as SubgroupValType).values.map((v) => {
            return {
              checked: v.checked || true,
              value: v.value,
              label: v.label,
            };
          }),
        };
      } else {
        return {
          checked: (val as CheckboxValProp).checked || true,
          value: (val as CheckboxValProp).value,
          label: (val as CheckboxValProp).label,
          icon: (val as CheckboxValProp).icon,
        };
      }
    }) as ValsState
  );

  const [subGroupsAll, setSubgroupsAllCheck] = useState(values.map(() => true));
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    const groupCheckedAll: boolean[] = [];
    let checkedAll = true;
    setVals(
      values.map((val) => {
        if (Array.isArray((val as SubgroupValType).values)) {
          let all = true;
          const data = {
            name: (val as SubgroupValType).name,
            values: (val as SubgroupValType).values.map((v) => {
              if (v.checked !== undefined && !v.checked) {
                all = false;
              }
              return {
                checked: v.checked !== undefined ? v.checked : true,
                value: v.value,
                label: v.label,
                icon: v.icon,
              };
            }),
          };
          groupCheckedAll.push(all);
          return data;
        } else {
          if (
            (val as CheckboxValProp).checked !== undefined &&
            !(val as CheckboxValProp).checked
          ) {
            checkedAll = false;
          }
          return {
            checked:
              (val as CheckboxValProp).checked !== undefined
                ? (val as CheckboxValProp).checked
                : true,
            value: (val as CheckboxValProp).value,
            label: (val as CheckboxValProp).label,
            icon: (val as CheckboxValProp).icon,
          };
        }
      }) as ValsState
    );
    setSubgroupsAllCheck([...groupCheckedAll]);
    setSelectAll(checkedAll);
  }, [values]);

  const sendChanges = (vals: ValsState) => {
    if (typeof onChange !== 'function') {
      return;
    }
    if (groupType === 'subgroups') {
      const result = vals.map((val, index) => {
        const v = val as ValStateGroup;
        const obj: { [key: string]: boolean } = {};
        v.values.forEach(({ value, checked }) => {
          obj[value] = checked;
        });
        return obj;
      });
      onChange(result);
    } else {
      const obj: { [key: string]: boolean } = {};
      vals.forEach((val, index) => {
        const { value, checked } = val as ValStateItem;
        obj[value] = checked;
      });
      onChange(obj);
    }
  };

  const handleSubGroupChangeAll: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const key = parseInt(e.target.dataset.key as string);
    const checked = !subGroupsAll[key];
    subGroupsAll[key] = checked;
    setSubgroupsAllCheck([...subGroupsAll]);
    vals[key] = {
      name: (vals[key] as ValStateGroup).name,
      values: (vals[key] as ValStateGroup).values.map((val) => {
        return { ...val, checked };
      }),
    };
    sendChanges(vals);
    setVals([...vals]);
  };

  const handleSimpleSelectAll: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const checked = !selectAll;
    setSelectAll(checked);

    const newVals = vals.map((val) => {
      return { ...(val as ValStateItem), checked };
    });
    sendChanges(newVals);
    setVals([...newVals]);
  };

  const handleSubgroupChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const index = parseInt(e.target.dataset.index as string);
    const key = parseInt(e.target.dataset.key as string);
    const item = (vals[index] as ValStateGroup).values;
    const checked = !item[key].checked;
    item[key] = { ...item[key], checked };
    sendChanges(vals);
    setVals([...vals]);
  };

  const hanldeSimpleChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const key = parseInt(e.target.dataset.key as string);
    const checked = !(vals[key] as ValStateItem).checked;
    vals[key] = { ...(vals[key] as ValStateItem), checked };
    const selectAllVal = vals.reduce(
      (prev, val) => prev && (val as ValStateItem).checked,
      true
    );
    setSelectAll(selectAllVal);
    sendChanges(vals);
    setVals([...vals]);
  };

  // React.useEffect(() => {
  //   let allChecked = true;
  //   let newVals = [...vals]
  //   newVals.forEach((val, index) => {
  //     if (Array.isArray((val as ValStateGroup).values)) {
  //       const notCheckedItem = (val as ValStateGroup).values.find((val) => val.checked === false);
  //       if (!notCheckedItem) {
  //         (newVals[index] as ValStateGroup).values = (newVals[index] as ValStateGroup).values.map(x => ({ ...x, checked: false }))
  //       }
  //     } else {
  //       if (!(val as ValStateItem).checked) {
  //         allChecked = false;
  //       }
  //     }
  //   });
  //   if (!allChecked) {
  //     newVals = newVals.map(x => {
  //       if (!Array.isArray((x as ValStateGroup).values)) {
  //         return { ...x, checked: false };
  //       }
  //       return { ...x };
  //     });
  //   }
  //   setVals([...newVals]);
  // }, [vals])

  const content = vals.map((val, index) => {
    if (Array.isArray((val as ValStateGroup).values)) {
      return (
        <SubGroup
          key={index}
          name={(val as ValStateGroup).name}
          scrollContent={scrollSubGroup}
        >
          {/* {(val as SubgroupValType).values.length > 1 && (
            <Checkbox
              data-key={key}
              checked={subGroupsAll[key]}
              onChange={handleSubGroupChangeAll}
              value="all"
              label="Все"
              type={type}
            />
          )} */}
          {(val as SubgroupValType).values.map((props, index2) => (
            <Checkbox
              key={`${index}-${index2}`}
              onChange={handleSubgroupChange}
              data-index={index}
              data-key={index2}
              checked={(vals[index] as ValStateGroup).values[index2].checked}
              {...props}
              type={type}
            />
          ))}
        </SubGroup>
      );
    } else {
      return (
        <Checkbox
          key={index}
          onChange={hanldeSimpleChange}
          data-key={index}
          {...val}
          type={type}
          icon={(val as any).icon}
        />
      );
    }
  });

  return scrollContent ? (
    <ScrollBar>
      {/* {!scrollSubGroup && vals.length > 1 && (
        <Checkbox
          checked={selectAll}
          onChange={handleSimpleSelectAll}
          value="all"
          label="Все"
          type={type}
        />
      )} */}
      {content}
    </ScrollBar>
  ) : (
    <CheckboxContainer>{content}</CheckboxContainer>
  );
};

export default Group;
