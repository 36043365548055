import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Select } from '@components/ui';
import { searchFormDates } from '@modules/main';
import Breadcrumbs from '@components/train/TrainSearch/Breadcrumbs';
import Direction from '@components/train/TrainSearch/Direction';
import {
  FilterTrain,
  RequestError,
  TicketListSkeleton,
} from '@components/flightsSearch';

import {
  TRAIN_SEARCH_STEPS,
  TRAINS_SEARCH_SORT_BY,
  setTrainSort,
  trainPurify,
  trainSearchRequest,
  toggleTrainSearchFilter,
  setStep,
} from '@modules/trainTickets';
import FilterButton from '@components/ui/FilterButton/FilterButton';
import { changeSearchPanelType, getUiState } from '@modules/ui';
import {
  trainsHasRoundTripTariff,
  useCurrentTrainFilter,
} from '@modules/trainTickets/utils';
import Booking from '@components/train/Booking/Booking';
import { AlreadyChosenTrainPanel } from '@components/train/Panels/AlreadyChosenTrainPanel';
import { TicketsList } from '@components/train/TrainSearch/Ticket/TicketsList';
import {
  RailwayFullscreenLoader,
  RailwayLoader,
} from '@components/common/loader';
import { ApplicationState } from '@modules/index';

export interface BreadCrumbItem {
  uiStatus: 'enabled' | 'selected' | 'disabled';
  text: string;

  onClick?(): void;
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;

  @media (max-width: 1169px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  @media screen and (max-width: 1142px) {
    margin-top: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled.div`
  width: 100%;
`;

const LeftSideHead = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  justify-content: space-between;
  margin-bottom: ${({ isMobile }) => (isMobile ? '30px' : '40px')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const SelectWrapper = styled.div<{ isMobile: boolean }>`
  width: 260px;
  display: ${({ isMobile }) => (isMobile ? 'flex' : 'block')};
  margin-top: ${({ isMobile }) => (isMobile ? '16px' : 'unset')};

  & > span {
    width: 260px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    & > span {
      //width: 220px;
      margin-right: 20px;
    }
  }
`;

const FilterButtonWrapper = styled.div`
  margin-left: 15px;
`;

const TrainsSearch: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isTrainSearch = useSelector(getUiState).searchPanelType === 'train';
  const dates = useSelector(searchFormDates);

  const trainTickets = useSelector(
    (state: ApplicationState) => state.trainTickets,
  );
  const ticketsList = trainTickets.list;
  const filteredTicketsList = trainTickets.filteredList;
  const trainSearch = trainTickets.trainSearch;
  const trainSearchError = trainTickets.trainSearch.errors;

  const activeFilter = useCurrentTrainFilter();
  let trainsBackwardHasRoundTripTariff = false;

  const [chosenTrainPanelIsOpen, setChosenTrainPanelIsOpen] =
    useState<boolean>(false);
  const [chosenBackwardTrainPanelIsOpen, setChosenBackwardTrainPanelIsOpen] =
    useState<boolean>(false);

  let filterCounter = 0;
  if (activeFilter) {
    Object.values(activeFilter?.isDefault).forEach((value) => {
      return !value ? (filterCounter = filterCounter + 1) : filterCounter;
    });
  }
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const needCollapseFilter = useMediaQuery({ maxWidth: 1024 });

  const dispatch = useDispatch();
  const location = useLocation();

  const trainsForwardHasRoundTripTariff = trainsHasRoundTripTariff(
    ticketsList?.trainsPerRoutes[0]?.trains,
  );
  if (
    ticketsList?.trainsPerRoutes.length > 1 &&
    ticketsList.trainsPerRoutes[1] &&
    ticketsList.trainsPerRoutes[1].trains?.length > 0
  ) {
    trainsBackwardHasRoundTripTariff = trainsHasRoundTripTariff(
      ticketsList?.trainsPerRoutes[1]?.trains,
    );
  }

  const hasRoundTrip =
    trainsForwardHasRoundTripTariff && trainsBackwardHasRoundTripTariff;

  React.useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(location.search));
    const From = params.get('From');
    const To = params.get('To');
    const DepartureDate = params.get('DepartureDate');
    const DepartureDateBack = params.get('DepartureDateBack');
    const Adults = parseInt(params.get('Adults') || '0');
    const Children = parseInt(params.get('Children') || '0');
    const Infants = parseInt(params.get('Infants') || '0');
    if (From && To && DepartureDate) {
      dispatch(
        trainSearchRequest({
          Adults,
          Children,
          DepartureDate,
          DepartureDateBack,
          From,
          Infants,
          To,
        }),
      );
    }
  }, [location]);

  React.useEffect(
    () => () => {
      dispatch(trainPurify());
    },
    [],
  );

  useEffect(() => {
    if (!isTrainSearch) {
      dispatch(changeSearchPanelType('train'));
    }
  }, []);

  const defaultSelectOption = {
    label: 'По времени отправления',
    value: 'TIME_DEPARTURE',
  };

  const handleChangeSort = (data: any) => {
    dispatch(setTrainSort(data.value));
  };

  const isStepForward =
    trainSearch.currentSearchStep === TRAIN_SEARCH_STEPS.TICKET_FORWARD;
  const isStepBackward =
    trainSearch.currentSearchStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
  const isStepBooking =
    trainSearch.currentSearchStep === TRAIN_SEARCH_STEPS.BOOKING;
  const needFilter = isStepForward || isStepBackward;
  const loadingTitle = useMemo(() => {
    switch (trainSearch.currentSearchStep) {
      case TRAIN_SEARCH_STEPS.TICKET_FORWARD:
        return 'Подбираем билеты';
      case TRAIN_SEARCH_STEPS.TICKET_BACKWARD:
        return 'Подбираем обратные билеты';
      case TRAIN_SEARCH_STEPS.BOOKING:
        return 'Подготавливаем бронирование';
      default:
        return 'Загрузка...';
    }
  }, [trainSearch.currentSearchStep]);

  const breadcrumbItems: BreadCrumbItem[] = [
    {
      uiStatus: isStepForward ? 'selected' : 'enabled',
      text: isMobile ? 'Поезд туда' : 'Выбор поезда и мест туда',
      onClick: () => {
        setChosenTrainPanelIsOpen(true);
      },
    },
    {
      uiStatus: isStepBooking ? 'selected' : 'disabled',
      text: 'Бронирование',
      onClick: () => {
        console.log('booking');
      },
    },
  ];

  if (dates.to) {
    breadcrumbItems.splice(1, 0, {
      uiStatus: isStepBackward
        ? 'selected'
        : isStepBooking
          ? 'enabled'
          : 'disabled',
      text: isMobile ? 'Поезд обратно' : 'Выбор поезда и мест обратно',
      onClick: () => {
        setChosenBackwardTrainPanelIsOpen(true);
      },
    });
  }

  return (
    <Container>
      <Body>
        {trainSearch.status === 'failure' && (
          <RequestError
            message={
              trainSearchError
                ? trainSearchError.message
                : undefined
            }
            step={trainSearch.currentSearchStep}
            type={'train'}
          />
        )}
        {trainSearch.status !== 'failure' && (
          <React.Fragment>
            <Breadcrumbs items={breadcrumbItems} />
            <Content ref={ref}>
              {trainSearch.status === 'initial' && <TicketListSkeleton />}
              {trainSearch.status === 'loading' && (
                <RailwayLoader title={loadingTitle} />
              )}
              {trainSearch.status === 'tryToCreateOrder' && (
                <RailwayFullscreenLoader title={'Создаём бронирование'} />
              )}
              {trainSearch.status === 'success' && (
                <React.Fragment>
                  {needFilter && <FilterTrain />}
                  <LeftSide>
                    {needFilter && (
                      <LeftSideHead isMobile={isMobile}>
                        {isStepForward && (
                          <Direction
                            direction={'forward'}
                            fromCityName={
                              filteredTicketsList.trainsPerRoutes[0].stationFrom
                                .name_ru
                            }
                            toCityName={
                              filteredTicketsList.trainsPerRoutes[0].stationTo
                                .name_ru
                            }
                          />
                        )}
                        {isStepBackward && (
                          <Direction
                            direction={'backward'}
                            fromCityName={
                              filteredTicketsList.trainsPerRoutes[1].stationFrom
                                .name_ru
                            }
                            toCityName={
                              filteredTicketsList.trainsPerRoutes[1].stationTo
                                .name_ru
                            }
                          />
                        )}
                        {needFilter && (
                          <SelectWrapper isMobile={needCollapseFilter}>
                            <Select
                              onChange={(data: any) => {
                                handleChangeSort(data);
                              }}
                              value={TRAINS_SEARCH_SORT_BY.find(
                                (item) => item.value === trainSearch.sortBy,
                              )}
                              defaultValue={defaultSelectOption}
                              isSearchable={false}
                              name="sort"
                              options={TRAINS_SEARCH_SORT_BY}
                            />
                            {needCollapseFilter && (
                              <FilterButtonWrapper>
                                <FilterButton
                                  onClick={() => {
                                    dispatch(toggleTrainSearchFilter());
                                  }}
                                  counter={filterCounter}
                                />
                              </FilterButtonWrapper>
                            )}
                          </SelectWrapper>
                        )}
                      </LeftSideHead>
                    )}

                    {isStepForward && (
                      <TicketsList
                        hasRoundTrip={hasRoundTrip}
                        trains={filteredTicketsList?.trainsPerRoutes[0]?.trains}
                        searchStep={'forward'}
                        trainsCount={
                          ticketsList.trainsPerRoutes[0]?.trains?.length
                        }
                      />
                    )}

                    {isStepBackward && (
                      <TicketsList
                        hasRoundTrip={hasRoundTrip}
                        trains={filteredTicketsList?.trainsPerRoutes[1]?.trains}
                        searchStep={'backward'}
                        trainsCount={
                          ticketsList.trainsPerRoutes[1]?.trains?.length
                        }
                      />
                    )}

                    {isStepBooking && <Booking />}
                  </LeftSide>
                </React.Fragment>
              )}
            </Content>
          </React.Fragment>
        )}
        {
          <AlreadyChosenTrainPanel
            onSave={() => {
              setChosenTrainPanelIsOpen(false);
            }}
            onChooseAnother={() => {
              dispatch(setStep({ step: 'TICKET_FORWARD', needClear: true }));
              setChosenTrainPanelIsOpen(false);
            }}
            direction={'forward'}
            open={chosenTrainPanelIsOpen}
          />
        }
        {
          <AlreadyChosenTrainPanel
            onSave={() => {
              setChosenBackwardTrainPanelIsOpen(false);
            }}
            onChooseAnother={() => {
              dispatch(setStep({ step: 'TICKET_BACKWARD', needClear: true }));
              setChosenBackwardTrainPanelIsOpen(false);
            }}
            direction={'backward'}
            open={chosenBackwardTrainPanelIsOpen}
          />
        }
      </Body>
    </Container>
  );
};

export default TrainsSearch;
