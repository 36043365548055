/**
 * Типы пользовательских событий
 * @format int32
 */
export enum UserActionTypes {
  Click = 0,
  Enter = 1,
  Leave = 2,
  Focus = 3,
  Blur = 4,
  Change = 5,
  Open = 6,
  Close = 7,
  MouseMove = 8,
  Typing = 9,
  Idle = 10,
  Submit = 11,
  Receive = 12,
}

export interface LogSessionHandleData {
  eventType?: UserActionTypes;
  screen: string;
  actionId?: string;
  value?: unknown;
}

export interface LogSessionPostData extends LogSessionHandleData {
  logSessionId: string;
  appVersion: string;
  createDate: string;
}