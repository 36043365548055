import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import {
  PopupProps,
  PopupActions,
} from '../../../../../node_modules/reactjs-popup/dist/types';
import { FareEntity, setAncillaryDetailsSegment } from '@modules/booking';

import { useDispatch } from 'react-redux';

import { useModalState } from '../context';
import { Enums } from '../types';

interface BaseModalProps
  extends Omit<PopupProps & React.RefAttributes<PopupActions>, 'children'> {}

interface ModalProps extends BaseModalProps {
  fare: FareEntity;
}

const StyledPopup = styled(Popup)`
  &-overlay {
    overflow: scroll;
  }

  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0;
    border-radius: 8px;
    // height: calc(var(--vh, 1vh) * 95);
    display: flex;
    width: auto;
    overflow: hidden;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: fit-content;

    @media (min-height: 878px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      display: block;
      overflow: scroll;
      height: calc(var(--vh, 1vh) * 100);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
      padding: 30px 20px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

const InfoText = styled.div`
  display: flex;
  align-items: center;
  color: #737373;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  justify-content: space-between;
  margin-top: 10px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  top: 202px;
  left: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  margin: 20px 0;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  outline: none;
  z-index: 100;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    position: fixed;
  }
`;

const ChangeText = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #4872f2;
`;

const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  margin-top: 30px;
`;

const AncillaryDetailsModal: React.FC<any> = ({ ...props }) => {
  const dispatch = useDispatch();

  const { showModal } = useModalState();

  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      {' '}
      <Cross
        href="/sde"
        onClick={(e) => {
          e.preventDefault();
          typeof props.onClose === 'function' && props.onClose();
        }}
      />
      <Heading>Детали стоимости</Heading>
      {props.data.addictionalInfo.map((x: any, i: number) => (
        <div key={i} style={{ marginTop: 20 }}>
          <Row>
            <Heading>{x.segmentName}</Heading>
            <ChangeText
              onClick={() => {
                showModal(Enums.Ancillary);
                dispatch(setAncillaryDetailsSegment(x.key));
              }}
            >
              изменить
            </ChangeText>
          </Row>
          {x.passengers.map((passanger: any, i: number) =>
            passanger.offers.map((offer: any) => (
              <>
                {' '}
                <InfoText>
                  <div>Пассажир {i + 1}</div>
                  <div>{offer.name}</div>
                  <div style={{ fontWeight: 'bold', color: '#3C3C3C' }}>
                    {offer.price} ₽
                  </div>
                </InfoText>
                {i !== passanger.length - 1 && <Line />}
              </>
            )),
          )}
        </div>
      ))}
      {/* <PriceBlock>
        Итого за {chosenSegments.length} доп услуги:&nbsp;
        <span style={{ color: '#4872F2' }}>
          {chosenSegments.reduce((acc: number, x: any) => acc + x.price, 0)} ₽
        </span>{' '}
      </PriceBlock> */}
    </StyledPopup>
  );
};

export default AncillaryDetailsModal;
