import React, { useState } from 'react';
import { ReactComponent as TrainIcon } from '@assets/media/train-tickets/train-blue-icon.svg';
import { ReactComponent as SeatIcon } from '@assets/media/train-tickets/seat-blue-icon.svg';
import { ReactComponent as InfoIcon } from '@assets/media/order-detail/notification-icon.svg';
import { Text } from '@components/ui';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import MapModal from '@components/common/RouteDetail/MapModal';
import { ReactComponent as MapIcon } from '@assets/images/common/map_icon.svg';
import busRefund from '@assets/images/booking/busRefund.svg';
import busAdditionalInfo from '@assets/images/booking/busAdditionalInfo.svg';

import BusRefundModal from './BusRefundModal';
import BusAdditionalInfoModal from './BusAdditionalInfoModal';

interface Props {
  routeType: 'avia' | 'railway' | 'bus'; // Тип маршрута: авиа, железнодорожный или автобус
  direction: 'forward' | 'backward'; // Направление: вперед или назад
  fromStation: string; // Станция отправления
  toStation: string; // Станция прибытия
  transportNumber?: string; // Номер транспортного средства
  seats: {
    numbers: string[]; // Номера выбранных мест
    needRenderAsCount: boolean; // Нужно ли отображать количество мест вместо их номеров
  };
  additionalOptions?: {
    isRailwayTransit: boolean; // Является ли маршрут транзитным через СНГ
  };
  mainTicketInfo: React.ReactNode; // Основная информация о билете
  busAddresses?: {
    forward: {
      address: string | null;
      geolocation: string | null;
    };
    backward: {
      address: string | null;
      geolocation: string | null;
    };
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 767px) {
    gap: 14px;
  }
`;

const AttentionTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ffe2e3;
  padding: 15px;
  border-radius: 4px;

  & > svg {
    color: #f25f61;
  }
`;

const RouteDescription = styled.h3`
  color: #737373;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Container = styled.div`
  display: block;

  & > div:first-child {
    flex-grow: 1;
  }
`;

const PlaceContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  margin-top: ${({ isMobile }) => (isMobile ? '14px' : '20px')};
  display: flex;
  flex-direction: column;
  justify-content: ${({ isMobile }) =>
    isMobile ? 'space-between' : 'flex-start'};
  gap: 12px;
  flex-shrink: 0;
  width: ${({ isMobile }) => (isMobile ? 'unset' : '100%')};
`;

const PlaceItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const PlaceItemText = styled.div`
  font-size: 14px;
  line-height: 18px; /* 128.571% */
`;

const PlaceIconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const RefundRow = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: #4872f2;
  cursor: pointer;
  width: 250px;
  font-size: 14px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const BusRefund = styled.img.attrs({ src: busRefund })`
  margin-right: 7px;
`;

const BusAdditionalInfo = styled.img.attrs({ src: busAdditionalInfo })`
  margin-right: 7px;
`;

const RouteDetailItem: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    direction,
    fromStation,
    toStation,
    seats,
    routeType,
    transportNumber,
    additionalOptions,
    mainTicketInfo,
    busAddresses,
  } = props;
  const isForward = direction === 'forward';
  const [isMapModalOpen, setIsMapModalOpen] = useState<boolean>(false);
  const [addressInModal, setAddressInModal] = useState<string | null>('');
  const [geometryInModal, setGeometryInModal] = useState<number[]>([0, 0]);
  const [isBusRefundModalOpen, setIsBusRefundModalOpen] = useState(false);
  const [isBusAdditionalInfoModalOpen, setIsBusAdditionalInfoModalOpen] =
    useState(false);

  const openMapByAddress = (
    address: string | null,
    geometry: string | null,
  ): void => {
    if (geometry === null || geometry?.length === 0) return;

    setAddressInModal(address);
    setGeometryInModal(geometry.split(', ').map(Number));
    setIsMapModalOpen(true);
  };

  const closeMapModal = (): void => {
    setIsMapModalOpen(false);
  };

  if (!routeType) {
    return null;
  }

  return (
    <Wrapper>
      <MapModal
        isOpen={isMapModalOpen}
        onClose={closeMapModal}
        address={addressInModal}
        geometry={geometryInModal}
      />
      {isBusRefundModalOpen && (
        <BusRefundModal
          isBusRefundModalOpen={isBusRefundModalOpen}
          setIsBusRefundModalOpen={setIsBusRefundModalOpen}
          direction={direction}
        />
      )}
      {isBusAdditionalInfoModalOpen && (
        <BusAdditionalInfoModal
          isBusAdditionalInfoModalOpen={isBusAdditionalInfoModalOpen}
          setIsBusAdditionalInfoModalOpen={setIsBusAdditionalInfoModalOpen}
          direction={direction}
        />
      )}
      <RouteDescription>
        {isForward ? 'Туда' : 'Обратно'}: {fromStation} — {toStation}
      </RouteDescription>
      <Container>
        {mainTicketInfo}
        <PlaceContainer isMobile={isMobile}>
          <PlaceItem>
            <PlaceIconContainer>
              <SeatIcon />
            </PlaceIconContainer>
            <PlaceItemText>
              {seats.needRenderAsCount ? (
                `${seats.numbers.length} места`
              ) : (
                <>
                  Места №:{' '}
                  <b>
                    {seats.numbers.length > 0
                      ? seats.numbers.join(', ')
                      : 'не выбраны'}
                  </b>
                </>
              )}
            </PlaceItemText>
          </PlaceItem>
          <PlaceItem>
            <PlaceIconContainer>
              {routeType !== 'bus' && <TrainIcon />}
              {routeType === 'bus' && <SeatIcon />}
            </PlaceIconContainer>
            <PlaceItemText>
              {routeType === 'railway' && (
                <>
                  Вагон №: <b>{transportNumber}</b>
                </>
              )}
              {(routeType === 'avia' || routeType === 'bus') && (
                <>
                  Рейс:
                  <b>{transportNumber ? transportNumber : ' нет данных'}</b>
                </>
              )}
            </PlaceItemText>
          </PlaceItem>
          {routeType === 'bus' && (
            <RefundRow
              style={{ marginTop: 20 }}
              onClick={() => setIsBusRefundModalOpen(!isBusRefundModalOpen)}
            >
              <BusRefund />
              Условия возврата билета
            </RefundRow>
          )}
          {routeType === 'bus' && (
            <RefundRow
              onClick={() =>
                setIsBusAdditionalInfoModalOpen(!isBusAdditionalInfoModalOpen)
              }
            >
              <BusAdditionalInfo />
              Дополнительные условия
            </RefundRow>
          )}
          {busAddresses?.forward?.address !== null &&
            busAddresses?.forward?.address?.length &&
            busAddresses?.forward?.address?.length > 0 && (
              <>
                <PlaceItem>
                  {routeType === 'bus' && (
                    <PlaceIconContainer>
                      <MapIcon />
                    </PlaceIconContainer>
                  )}
                  <PlaceItemText>
                    {routeType === 'bus' && (
                      <>
                        Отправление:
                        <span
                          onClick={() =>
                            openMapByAddress(
                              busAddresses!.forward.address,
                              busAddresses!.forward.geolocation,
                            )
                          }
                        >
                          {busAddresses!.forward.address}
                        </span>
                      </>
                    )}
                  </PlaceItemText>
                </PlaceItem>
                <PlaceItem>
                  {routeType === 'bus' && <PlaceIconContainer />}
                  <PlaceItemText>
                    {busAddresses?.backward?.address !== null &&
                      busAddresses?.backward?.address?.length &&
                      busAddresses?.backward?.address?.length > 0 && (
                        <>
                          Прибытие:
                          <span
                            onClick={() =>
                              openMapByAddress(
                                busAddresses!.backward.address,
                                busAddresses!.backward.geolocation,
                              )
                            }
                          >
                            {busAddresses!.backward.address}
                          </span>
                        </>
                      )}
                  </PlaceItemText>
                </PlaceItem>
              </>
            )}
        </PlaceContainer>
      </Container>
      {additionalOptions?.isRailwayTransit && (
        <AttentionTextContainer>
          <InfoIcon />
          <Text>Внимание, поезд следует транзитом через СНГ.</Text>
        </AttentionTextContainer>
      )}
    </Wrapper>
  );
};
export default RouteDetailItem;
