import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { BookingWarningModalProps, AncillaryServiceType } from './types';
import { Text, Button } from '@components/ui';
import { ReactComponent as MealIcon } from './assets/media/meal.svg';
import { ReactComponent as LuggageIcon } from './assets/media/luggage.svg';

const StyledPopup = styled(Popup)`
  &-content {
    width: 519px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px 21px 30px 30px;
    border-radius: 8px;
    box-sizing: border-box;
  }
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled(BlockText)`
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;

const Description = styled(BlockText)`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
`;

const ListTitle = styled(Description)`
  margin-bottom: 15px;
  font-weight: 600;
`;

const List = styled.div`
  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
`;

const ItemName = styled(Text)`
  font-size: 16px;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: 25px auto 0 auto;
`;

const ProductsRemovedModal: React.FC<BookingWarningModalProps> = ({
  data,
  warnings,
  ...props
}) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Title>
        {warnings.bookingWarning.type === 'PriceChanged'
          ? 'Закончились места по тарифу'
          : ' Внимание!'}
      </Title>
      <Description>
        {warnings.bookingWarning.type === 'PriceChanged' ? (
          <>
            К сожалению, места по выбранному тарифу закончились. <br />
            <br />
            <span style={{ fontWeight: 'bold', marginTop: 5 }}>
              Стоимость перелета по новому тарифу:&nbsp;
            </span>
            <span style={{ color: '#4872F2', fontWeight: 'bold' }}>
              {warnings?.bookingWarning?.data?.newPrice} ₽
            </span>
          </>
        ) : (
          'Некоторые дополнительные услуги сейчас невозможно добавить к бронированию. Приносим Вам свои извинения за неудобство.'
        )}
      </Description>
      {warnings.bookingWarning.type !== 'PriceChanged' && (
        <>
          <ListTitle>Отсутствующие доп. услуги:</ListTitle>
          <List>
            {data.unconfirmedAncillaryServices?.map((x, key) => (
              <Item key={key}>
                {getServiceIcon(x.type)}
                <ItemName>{x.name}</ItemName>
              </Item>
            ))}
          </List>
        </>
      )}

      <StyledButton onClick={props.onClose}>
        Продолжить бронирование
      </StyledButton>
    </StyledPopup>
  );
};

function getServiceIcon(type: AncillaryServiceType) {
  switch (type) {
    case AncillaryServiceType.Meal:
      return <MealIcon />;
    case AncillaryServiceType.Luggage:
      return <LuggageIcon />;
    default:
      return null;
  }
}

export default ProductsRemovedModal;
