import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Text from '@components/ui/Typography';
import { FlightEntity } from '@modules/booking';
import FlightsList from './FlightsList';
import { ApplicationState } from '@modules/index';
import { orderDetailsStateToFlightEntity } from '@modules/booking/utils';

const Wrapper = styled.div`
  padding: 0 24px;
`;

const Title = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
    color: #3c3c3c;
  }
`;

const YourFlightContainerBookingStepTwo: React.FC = () => {
  const orderDetails = useSelector((state: ApplicationState) => state.orders.orderDetail.data);
  const flights: FlightEntity[] = orderDetailsStateToFlightEntity(orderDetails);
  return (
    <Wrapper>
      <Title fontWeight="600" size="big">
        Ваш перелёт
      </Title>
      <FlightsList items={flights} />
    </Wrapper>
  );
};

export default YourFlightContainerBookingStepTwo;
