import { FetchStrategy, FetchStrategyCallback, FetchArgs } from './types';
import {
  STRATEGY_TYPES,
  CONNECTION_ERROR_FETCH,
  ERRORS_DESCRIPTIONS,
  STATUS,
} from './constants';
import { authGuard } from './guards';
import { HttpError } from './handlers';

const fetchStrategyResolver = (x?: FetchStrategy): FetchStrategyCallback => {
  if (!x)
    return [(args) => ({ status: true, args: args }), () => ({ status: true })];
  if (x === STRATEGY_TYPES.AUTH) return authGuard();
  return x;
};

/**
 * Slightly enhanced fetch
 * @param args base fetch params with url
 * @param strategy check for authorization or custom strategy
 */
export default function request(
  args: FetchArgs,
  strategy?: FetchStrategy,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    // Передаются массив с калбеками [до фетча, после], до фетча можно модифицыровать его параметры и сделать какуюто валидацию, после можно удалит
    const [beforeFetch, onFetchResponse] = fetchStrategyResolver(strategy);
    const beforeFetchResult = beforeFetch(args);
    const canFetch = beforeFetchResult.status;
    if (canFetch) {
      const { url, ...restArgs } = beforeFetchResult.args;
      const headers = new Headers(restArgs.headers);
      const logSessionId = sessionStorage.getItem('logSessionId');
      if (logSessionId) {
        headers.append('X-Log-Session-Id', logSessionId);
      }

      fetch(url, { ...restArgs, headers })
        .then((response) => {
          const responseCheck = onFetchResponse(response);
          const canResolve = responseCheck.status;
          if (canResolve) {
            resolve(response);
          } else {
            reject(responseCheck.error || { code: 0, message: '' });
          }
        })
        .catch((e) => {
          if (e.message === CONNECTION_ERROR_FETCH) {
            reject(
              new HttpError(STATUS.CONNECTION, ERRORS_DESCRIPTIONS.CONNECTION),
            );
          }
          reject(e);
        });
    } else {
      reject(beforeFetchResult.error || { code: 0, message: '' });
    }
  });
}
