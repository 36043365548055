import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import Icon from '../../../../assets/images/flight-search/filter-mobile';
import { ButtonProps } from './types';
import { useSelector } from 'react-redux';
import { getFiltersCounter } from '@modules/main';

const Wrapper = styled.button`
  background: #4872f2;
  border-radius: 23px;
  outline: none;
  border: none;
  position: fixed;
  bottom: 14px;
  display: flex;
  align-items: center;
  padding: 4px 30px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  & > :first-child {
    margin-right: 6px;
  }

  & > ${Text} {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
`;

const IconWrapper = styled.div`
  position: relative;
`;
const Round = styled(Text)`
  position: absolute;
  top: -2px;
  right: -3px;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background: white;
  color: ${({ theme: { colors } }) => colors.main};
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  z-index: 10;
`;

const AviaButton: React.FC<ButtonProps> = (props) => {
  const filtersCounter = useSelector(getFiltersCounter);

  return (
    <Wrapper {...props}>
      <IconWrapper>
        <Icon />
        {filtersCounter > 0 && <Round>{filtersCounter}</Round>}
      </IconWrapper>
      <Text>Фильтр</Text>
    </Wrapper>
  );
};

export default AviaButton;
