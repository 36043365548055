import React, { FunctionComponent } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import Map from './Map';

export interface Props {
  onClose: () => void;
  isOpen: boolean;
  address: string | null;
  geometry: number[];
}

const StyledPopup = styled(Popup)`
  &-content {
    width: 60%;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 16px;
`;

export const Header = styled.header`
  margin-bottom: 38px;
`;

export const Heading = styled.h2`
  margin: 0;
  font-size: 24px;
  line-height: 120%;
`;

export const Body = styled.section``;

export const MapContainer = styled.div`
  height: 456px;
  width: 100%;
`;

export const Info = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const Text = styled.p`
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  line-height: 20px;

  &:last-child {
    margin: 0;
  }
`;

const MapModal: FunctionComponent<Props> = ({
  onClose,
  address,
  geometry,
  isOpen,
}) => {
  return (
    <StyledPopup onClose={onClose} open={isOpen}>
      <Wrapper>
        <Header>
          <Heading>Адрес остановки на карте</Heading>
        </Header>
        <Body>
          <MapContainer>
            <Map address={address} geometry={geometry} />
          </MapContainer>
          <Info>
            <Text>
              <b>Адрес остановки автобуса </b>
            </Text>
            <Text>{address}</Text>
          </Info>
        </Body>
      </Wrapper>
    </StyledPopup>
  );
};

export default MapModal;
