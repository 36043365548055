import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signUp } from '@modules/user';
import StepOne from './MobileStepOne';
import StepTwo from './MobileStepTwo';
import { Text } from '@components/ui';
import styled from 'styled-components';
import moment from 'moment';

const StepWrapper = styled.div`
  text-align: center;
  margin-bottom: 15px;
  ${Text} {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #737373;
  }
`;

const MobileTemplate: React.FC = () => {
  const [step, setStep] = useState(1);
  const [firstStepData, setFirstStepData] = useState({
    phone: '',
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const agentId = urlParams.get('agentId');

  return (
    <>
      <StepWrapper>
        <Text>Шаг {step}</Text>
      </StepWrapper>
      {step === 1 ? (
        <StepOne
          onSubmit={(data) => {
            setFirstStepData(data);
            setStep(2);
          }}
        />
      ) : (
        <StepTwo
          onSubmit={(data) => {
            if (agentId) {
              dispatch(
                signUp({
                  ...firstStepData,
                  ...data,
                  agentId: agentId,
                  phone: firstStepData.phone.replaceAll(/-|№|\(|\)|\s/g, ''),
                  birthDate: moment(data.birthDate as Date).format(
                    'YYYY-MM-DD'
                  ),
                })
              );
            } else
              dispatch(
                signUp({
                  ...firstStepData,
                  ...data,
                  phone: firstStepData.phone.replaceAll(/-|№|\(|\)|\s/g, ''),
                  birthDate: moment(data.birthDate as Date).format(
                    'YYYY-MM-DD'
                  ),
                })
              );
          }}
        />
      )}
    </>
  );
};

export default MobileTemplate;
