export enum PassengerTypes {
  Adult = 'Adult',
  Child = 'Child',
  Infant = 'Infant',
  BabyWithoutPlace = 'BabyWithoutPlace',
}

export enum AvailableActions {
  //Печать маршрутной квитанции
  PRINT = 'PRINT',

  //Создание обращения с любой темой
  CREATE_APPEAL_WITH_ANY_THEME = 'CREATE_APPEAL_WITH_ANY_THEME',

  // Создание обращения только с темой "прочее"
  CREATE_APPEAL_WITH_ONLY_OTHER_THEME = 'CREATE_APPEAL_WITH_ONLY_OTHER_THEME',

  // Отмена
  CANCEL = 'CANCEL',

  // Оплата
  PAYMENT = 'PAYMENT',

  // Печать возврата
  REFUND_BLANK = 'REFUND_BLANK',

  // Возврат ЖД
  REFUND_RAILWAY = 'REFUND_RAILWAY',

  // Отказ от заказа ЖД
  RAILWAY_ORDER_CANCEL = 'RAILWAY_ORDER_CANCEL',
}

export enum STATUS {
  loading = 'loading',
  failure = 'failure',
  success = 'success',
}

export enum AddictionalServiceTypes {
  SMS_Service = 'SMS_Service',
  Return_Guarantee = 'Return_Guarantee',
  Payment_Receipt = 'Payment_Receipt',
}

export enum FieldsTypes {
  datetime = 'datetime',
  string = 'string',
}

export enum EventSegmentsTypes {
  Deleted = 'Deleted',
  Changed = 'Changed',
  Added = 'Added',
  Mixed = 'Mixed',
  Undefined = 'Undefined',
}
