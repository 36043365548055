import { all, takeLatest, put } from 'typed-redux-saga';
import { Action } from 'redux-act';
import { showNotification } from '@modules/notification';
import { signInFailure } from '@modules/user';
import { handleHttpErrors } from './duck';
import { HttpError, Api } from '@utils';

//TODO move to user module
export function* handleHttpErrorsSaga({ payload }: Action<HttpError>) {
  if (payload.name === Api.STATUS.AUTH) {
    yield* put(signInFailure(null));
  } else {
    // yield* put(setLKLoadingStatus(false));
    yield* put(
      showNotification({ code: payload.name, message: payload.message })
    );
  }
}

export default function* rootSaga() {
  yield* all([takeLatest(handleHttpErrors.getType(), handleHttpErrorsSaga)]);
}
