import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import Calculate from './Calculate';
import Success from './Success';
import Failure from './Failure';
import Loading from './Loading';
import Default from './Default';
import Confirm from '../Confirm';
import { Button } from '@components/ui';
import { useSteps, STEPS } from '../useSteps';
import { useRefund } from '../useRefund';
import { Manager } from '@modules/appeals';
import { useMediaQuery } from 'react-responsive';

const StyledPopup = styled(Popup)`
  &-overlay {
    z-index: 1001 !important;
  }
  &-content {
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
    max-height: 80vh;
    overflow: hidden;
    ${({ theme }) => `
      ${theme.max('767px')} {
        height: 100vh;
        max-height: 100vh;
        width: 100%;
        border: none;
        border-radius: 0;
        overflow-x: scroll;
        max-height: 100vh;
      }
    `}
  }
`;

const PopupContentWrapper = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  margin-right: -50px;
  padding-right: 30px;
  @media (max-width: 767px) {
    max-height: 100vh;
    margin-right: 0;
    padding-right: 0;
  }
`;

const CrossWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 6px;
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  outline: none;
  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const OrderButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 12px;
`;

const SurchargeRouter: React.FC = () => {
  const [{ step }, setStep] = useSteps();
  const data = useRefund();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  React.useEffect(() => {
    if (isMobile) {
      return;
    }
    if (step === STEPS.HIDDEN) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [step]);

  const content = () => {
    switch (step) {
      case STEPS.CALCULATE:
        return <Calculate />;
      case STEPS.LOADING:
        return <Loading />;
      case STEPS.CONFIRM:
        return (
          <Confirm
            actionName="обмен"
            onActionConfirm={() => {
              setStep(STEPS.LOADING);
              Manager.applySupportTicketCalculation(data.id)
                .then(() => {
                  setStep(STEPS.HIDDEN);
                  data.onConfirm();
                })
                .catch(() => setStep(STEPS.FAILURE));
            }}
          />
        );
      case STEPS.SUCCESS: {
        if (data.template === 'order') {
          return null;
        }
        return <Success />;
      }
      case STEPS.FAILURE:
        return <Failure />;
      default: {
        if (data.template === 'order') {
          return null;
        }
        return isMobile ? <Default /> : <div></div>;
      }
    }
  };

  return isMobile ? (
    <>
      {data.template === 'order' && (
        <OrderButton onClick={() => setStep(STEPS.CALCULATE)}>
          Открыть расчет
        </OrderButton>
      )}
      {step !== STEPS.HIDDEN && (
        <CrossWrapper>
          <Cross
            onClick={() => {
              setStep(STEPS.HIDDEN);
            }}
          />
        </CrossWrapper>
      )}
      {content()}
    </>
  ) : (
    <>
      {data.template !== 'order' && <Default />}
      {data.template === 'order' && (
        <OrderButton onClick={() => setStep(STEPS.CALCULATE)}>
          Открыть расчет
        </OrderButton>
      )}

      <StyledPopup
        onClose={() => setStep(STEPS.HIDDEN)}
        open={step !== STEPS.HIDDEN}
      >
        <PopupContentWrapper>{content()}</PopupContentWrapper>
      </StyledPopup>
    </>
  );
};

export default SurchargeRouter;
