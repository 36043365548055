import {
  BusBookingOrderCreation,
  BusOrderDetails,
  OrderDetailsData,
  BusOrder,
} from '@modules/busBooking/dto/BookBusTicketDto';
import { Api } from '@utils';
import { CONTENT_TYPE, STRATEGY_TYPES } from '../../utils/fetch';

const BUS_API_URL = process.env.REACT_APP_BUS_SERVICE_HOST as string;
const BUS_ORDER_CREATE = process.env.REACT_APP_BUS_ORDER_CREATE as string;
const BUS_RACE_DETAILS = process.env.REACT_APP_BUS_RACE_DETAILS as string;

export const bookBusTicket = (
  payload: BusBookingOrderCreation,
): Promise<BusOrder> => {
  const bookTrainTicketLink = Api.createFetchUrl(BUS_API_URL, BUS_ORDER_CREATE);
  const url = bookTrainTicketLink('', {});
  return Api.request(
    {
      url: url,
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.jsonBooking);
};

export const getBusOrderDetails = (
  payload: OrderDetailsData,
): Promise<BusOrderDetails> => {
  const toQueryString = (data: OrderDetailsData): string => {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(data)) {
      params.append(key, value);
    }

    return params.toString();
  };

  const url = `${BUS_API_URL}${BUS_RACE_DETAILS}?${toQueryString(payload)}`;
  return Api.request(
    {
      url: url,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.jsonBooking);
};
