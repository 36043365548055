import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const shimmer = keyframes`
    100% {
      transform: translateX(100%);
    }
`;

const background = css`
  position: relative;
  overflow: hidden;
  background-color: #dcdcdc;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${shimmer} 1.5s infinite;
    content: '';
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Filter = styled.div`
  width: 286px;
  margin-right: 26px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const FilterItem = styled.div`
  padding: 24px 0px;
  border-bottom: 1px solid #dcdcdc;
`;

const FilterText = styled.div`
  height: 18px;
  background: #dcdcdc;
  border-radius: 4px;
`;

const MiniText = styled(FilterText)`
  height: 14px;
  width: 47px;
`;

const Slider = styled.div`
  position: relative;
  height: 24px;
  padding: 12px 0;
  box-sizing: border-box;
  & > :first-child {
    position: absolute;
    top: 0;
    left: 0;
  }
  & > :last-child {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Round = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: #dcdcdc;
`;

const Row = styled.div`
  height: 1px;
  width: 100%;
  background: #dcdcdc;
`;

const List = styled.div`
  & > div {
    margin: 0 auto;
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
  & > :last-child {
    margin-bottom: 0;
  }
  padding: 0;
  margin: 0;
  position: relative;
  width: 858px;
  @media screen and (max-width: 1188px) {
    width: 100%;
    max-width: 858px;
  }
`;

const Cart = styled.div`
  width: 100%;
  height: 213px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  ${background}
`;

export default function () {
  return (
    <Flex>
      <Filter>
        <FilterItem>
          <FilterText style={{ width: 110, marginBottom: 24 }} />
          <Flex style={{ marginBottom: 15 }}>
            <MiniText /> <MiniText />
          </Flex>
          <Slider>
            <Round />
            <Row />
            <Round />
          </Slider>
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 98 }} />
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 63 }} />
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 215 }} />
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 99 }} />
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 132 }} />
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 205 }} />
        </FilterItem>
        <FilterItem>
          <FilterText style={{ width: 116 }} />
        </FilterItem>
      </Filter>
      <List>
        {Array(3)
          .fill('')
          .map((_, key) => (
            <Cart key={key}></Cart>
          ))}
      </List>
    </Flex>
  );
}
