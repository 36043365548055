import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { CreateFormPopupProps } from './types';
import Router from './Router';
import { CreateFormProvider } from './useProps';
import { useDispatch } from 'react-redux';
import { addAppealPurify } from '@modules/appeals';
import crossIconSrc from '../../../../assets/images/appeal-forms/cross.svg';

const Wrapper = styled.div`
  padding: 40px;
  padding-bottom: 30px;
  width: 569px;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    padding: 25px 21px;
    height: auto;
    min-height: 100%;
    display: flex;
    // align-items: center;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    border-radius: 8px;
    width: auto;
  }

  &-overlay {
    overflow-y: scroll;
  }
  @media (max-width: 767px) {
    &-overlay {
      // top: 39px !important;
    }
    &-content {
      height: 100vh;
      width: 100%;
      border: none;
      border-radius: 0;
      overflow-x: scroll;
    }
  }
`;

const CloseButton = styled.img.attrs({ src: crossIconSrc as any, alt: '' })`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CreateFormPopup: React.FC<CreateFormPopupProps> = ({
  orderPosition,
  defaultSubject,
  onlyOtherSubject,
  onlyOtherRefundReturnSubjects,
  insurances,
  onClose,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(addAppealPurify());
    typeof onClose === 'function' && onClose();
  };

  return (
    <StyledPopup {...props} onClose={handleClose}>
      <CreateFormProvider
        {...{
          orderPosition,
          defaultSubject,
          onlyOtherSubject,
          onlyOtherRefundReturnSubjects,
          insurances,
          onClose,
        }}
      >
        <Wrapper>
          <CloseButton onClick={handleClose} />
          <Router />
        </Wrapper>
      </CreateFormProvider>
    </StyledPopup>
  );
};

export default CreateFormPopup;
