import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { intersection as _intersection } from 'lodash';
import { TrainBookingInitState } from '@modules/trainBooking/constants';
import { UpdatePassengerPayload } from '@modules/booking/slices/passengersSlice';
import { PassengerItemEntity, PriceItem } from '@modules/booking';
import {
  BookTrainTicketDto,
  CreateTrainBookingPayload,
} from '@modules/trainBooking/dto/BookTrainTicketDto';
import {
  RailwayDocumentTypeIds,
  TrainPassengerItemEntity,
} from '@modules/trainBooking/types';

const passengers = createReducer({}, TrainBookingInitState.passengers);
const ticket = createReducer({}, TrainBookingInitState.ticket);
const pricing = createReducer({}, TrainBookingInitState.pricing);
const tariffs = createReducer({}, TrainBookingInitState.tariffs);
const allowedDocumentTypes = createReducer(
  {},
  TrainBookingInitState.allowedDocumentTypes
);

export const enterInBooking = createAction('@trainBooking/enter');

export const purifyTrainPassengers = createAction('@trainBooking/purify');

// PASSENGERS ACTIONS
export const updateTrainPassengers = createAction<TrainPassengerItemEntity[]>(
  '@trainBooking/updatePassenger'
);
export const setTrainPassengerName = createAction<UpdatePassengerPayload>(
  '@trainBooking/setPassengerName'
);
export const setTrainPassengerSurname = createAction<UpdatePassengerPayload>(
  '@trainBooking/setPassengerName'
);
export const setTrainPassengerSecondName = createAction<UpdatePassengerPayload>(
  '@trainBooking/setPassengerName'
);
export const updateTrainPassengerByIndex = createAction<{
  index: number;
  data: PassengerItemEntity;
}>('@trainBooking/updatePassengerByIndex');

export const setRefundableStateSeatPlace = createAction<{
  passengerIndex: number;
  direction: 'forward' | 'backward';
  isNotRefundable: boolean;
}>('@trainBooking/setRefundableStateSeatPlace');

// PRICING ACTIONS
export const setTrainPriceItems = createAction<PriceItem[]>(
  '@trainBooking/setTrainPriceItems'
);
export const updateTrainPriceItems = createAction<PriceItem[]>(
  '@trainBooking/updateTrainPriceItems'
);
export const updateTrainPriceItemByIndex = createAction<{
  index: number;
  data: PriceItem;
}>('@trainBooking/updateTrainPriceItemByIndex');
export const purifyTrainPriceItems = createAction(
  '@trainBooking/purifyTrainPriceItems'
);
export const purifyTrainPricing = createAction(
  '@trainBooking/purifyTrainPricing'
);
export const purifyTotalPrice = createAction('@trainBooking/purifyTotalPrice');
export const setTrainTotalPrice = createAction<number>(
  '@trainBooking/setTrainTotalPrice'
);

// TARIFFS ACTIONS
export const setTariffForward = createAction<string>(
  '@trainBooking/setTariffForward'
);
export const setTariffBackward = createAction<string>(
  '@trainBooking/setTariffBackward'
);
export const purifyTariffs = createAction('@trainBooking/purifyTariffs');
export const purifyTariffForward = createAction(
  '@trainBooking/purifyTariffForward'
);
export const purifyTariffBackward = createAction(
  '@trainBooking/purifyTariffBackward'
);

export const setAllowedDocumentTypes = createAction<RailwayDocumentTypeIds[]>(
  '@trainBooking/setAllowedDocumentTypes'
);

// PASSENGERS REDUCERS
passengers.on(purifyTrainPassengers, () => []);
passengers.on(updateTrainPassengers, (_, payload) => [...payload]);
passengers.on(updateTrainPassengerByIndex, (state, { index, data }) => {
  state[index] = { ...state[index], ...data };
  return [...state];
});
passengers.on(setTrainPassengerName, (state, { value, passengerIndex }) => {
  state[passengerIndex] = { ...state[passengerIndex], name: value };
  return [...state];
});
passengers.on(setTrainPassengerSurname, (state, { value, passengerIndex }) => {
  state[passengerIndex] = { ...state[passengerIndex], surname: value };
  return [...state];
});
passengers.on(
  setTrainPassengerSecondName,
  (state, { value, passengerIndex }) => {
    state[passengerIndex] = { ...state[passengerIndex], secondName: value };
    return [...state];
  }
);

export const bookTrainRequest = createAction<CreateTrainBookingPayload>(
  '@trainBooking/create'
);

export const setTrainTicket = createAction<BookTrainTicketDto>(
  '@trainBooking/setTicket'
);
export const clearTrainTicket = createAction('@trainBooking/clearTicket');

ticket.on(setTrainTicket, (_, payload) => payload);
ticket.on(clearTrainTicket, () => null);

// PRICING REDUCERS
pricing.on(purifyTrainPriceItems, (state, payload) => ({
  ...state,
  priceItems: [],
  total: 0,
}));
pricing.on(setTrainPriceItems, (state, payload) => ({
  ...state,
  priceItems: [...payload],
}));
pricing.on(updateTrainPriceItems, (state, payload) => ({
  ...state,
  priceItems: [...state.priceItems, ...payload],
}));
pricing.on(updateTrainPriceItemByIndex, (state, { index, data }) => {
  state.priceItems[index] = { ...state.priceItems[index], ...data };
  return { ...state };
});
pricing.on(setTrainTotalPrice, (state, payload) => ({
  ...state,
  total: payload,
}));

tariffs.on(setTariffForward, (state, payload) => ({
  ...state,
  forward: payload,
}));
tariffs.on(setTariffBackward, (state, payload) => ({
  ...state,
  backward: payload,
}));
tariffs.on(purifyTariffs, () => ({ ...TrainBookingInitState.tariffs }));
tariffs.on(purifyTariffForward, (state) => ({
  ...state,
  forward: TrainBookingInitState.tariffs.forward,
}));
tariffs.on(purifyTariffBackward, (state) => ({
  ...state,
  backward: TrainBookingInitState.tariffs.backward,
}));

allowedDocumentTypes.on(setAllowedDocumentTypes, (state, payload) => {
  let result: RailwayDocumentTypeIds[] = [];
  if (state === null) {
    result = [...payload];
  } else {
    result = _intersection(state, payload);
  }
  return result;
});

export default combineReducers({
  passengers,
  ticket,
  pricing,
  tariffs,
  allowedDocumentTypes,
});
