import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface Props {
  isSmall?: boolean;
}

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const background = css`
  position: relative;
  overflow: hidden;
  background-color: #dcdcdc;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${shimmer} 1.5s infinite;
    content: '';
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1141px) {
    width: 100%;
  }
`;

const List = styled.div`
  & > div {
    margin: 0 auto;
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
`;

const Cart = styled.div<{ isSmall?: boolean }>`
  width: 100%;
  height: ${({ isSmall }) => (isSmall ? '130px' : '213px')};
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  ${background}
`;

export const Skeleton = (props: Props) => {
  return (
    <Flex>
      <List>
        {Array(1)
          .fill('')
          .map((_, key) => (
            <Cart isSmall={props?.isSmall} key={key}></Cart>
          ))}
      </List>
    </Flex>
  );
};

export default Skeleton;
