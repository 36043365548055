import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 4C15.1046 4 16 3.10457 16 2C16 0.89543 15.1046 0 14 0C12.8954 0 12 0.89543 12 2C12 3.10457 12.8954 4 14 4Z"
        fill="#737373"
      />
      <path
        d="M12.777 7.96897C13.0066 8.31105 13.3358 8.57419 13.7201 8.72268C14.1044 8.87117 14.5251 8.8978 14.925 8.79897L18.241 7.96997L17.758 6.02997L14.442 6.85897L13.063 4.79197C12.7684 4.35053 12.3111 4.04354 11.791 3.93797L7.945 3.16797C7.51474 3.08162 7.06802 3.13933 6.67383 3.33217C6.27964 3.52502 5.9599 3.84229 5.764 4.23497L4.106 7.55097L5.895 8.44597L7.553 5.12897L9.52 5.52297L4.434 14H0V16H4.434C5.132 16 5.789 15.628 6.149 15.029L8.067 11.833L13.236 12.867L15.052 18.316L16.948 17.683L15.133 12.235C15.021 11.9005 14.8228 11.6014 14.5584 11.3679C14.294 11.1345 13.9728 10.9748 13.627 10.905L10.588 10.298L12.36 7.34397L12.777 7.96897Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Logo;
