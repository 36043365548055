//@ts-nocheck

import React from 'react';
import styled from 'styled-components';
import { Select } from '@components/ui';
import { useMediaQuery } from 'react-responsive';
import FilterButton from '@components/ui/FilterButton/FilterButton';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentTrainFilter } from '@modules/trainTickets/utils';
import moment from 'moment/moment';

import BusTicket from './Ticket';
import { ApplicationState } from '@modules/index';
import {
  TRAINS_SEARCH_SORT_BY,
  toggleTrainSearchFilter,
} from '@modules/trainTickets';

import { Bus, setBusSort } from '@modules/busTickets';

interface Props {
  buses: any;
  trainsCount?: number;
  searchStep: 'forward' | 'backward';
  setSelectedBus?: (bus: Bus) => void;
}

interface LinkProps {
  isCheap?: boolean;
  isFast?: boolean;
  chosen?: boolean;
}

const TicketList = styled.div`
  & > div {
    margin-bottom: 30px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #3c3c3c;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const EmptySearchWrapper = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 52px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 0px 5px,
    rgba(0, 0, 0, 0.1) 0px 10px 10px;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  font-family: 'Open Sans';
  min-width: 794px;
  box-sizing: border-box;
  color: rgb(60, 60, 60);
  align-self: baseline;
  margin: 0px auto;

  @media screen and (max-width: 768px) {
    min-width: 290px;
  }
`;

const EmptySearchHeader = styled.h2`
  text-align: center;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const EmptySearchList = styled.ul`
  padding-left: 0;
`;

const EmptySearchText = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const EmptySearchListItem = styled.li`
  & + li {
    margin-top: 15px;
  }
`;

const SelectWrapper = styled.div<{ isMobile: boolean }>`
  display: ${({ isMobile }) => (isMobile ? 'flex' : 'block')};
  margin-top: ${({ isMobile }) => (isMobile ? '16px' : 'unset')};
  width: 230px;

  & > span {
    width: 230px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    & > span {
      //width: 220px;
      margin-right: 20px;
    }
  }
`;

const FilterButtonWrapper = styled.div`
  margin-left: 15px;
`;

const TagButtonsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.div<LinkProps>`
  width: 141px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #3c3c3c;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  background: ${({ isFast, isCheap, chosen }) =>
    isCheap && chosen ? '#ECE0FE' : isFast && chosen ? '#DAF0A2' : 'none'};
  margin-left: ${({ isFast, isCheap, chosen }) => (isCheap ? '15px' : '0')};
  &:hover {
    border: ${({ isFast, isCheap }) =>
      isFast ? '1px solid #b1d753' : '1px solid #A785DA'};
  }
`;

export function TicketsList(props: Props) {
  const { buses, trainsCount, searchStep, setSelectedBus } = props;
  const busTickets = useSelector((state: ApplicationState) => state.busTickets);
  const busSort = useSelector(
    (state: ApplicationState) => state.busTickets.busSort,
  );

  const busSearch = busTickets.busSearch;
  const dispatch = useDispatch();
  const activeFilter = useCurrentTrainFilter();

  const [chosenTagFilter, setChosenTagFilter] = React.useState('');

  let filterCounter = 0;
  if (activeFilter) {
    Object.values(activeFilter?.isDefault).forEach((value) => {
      return !value ? (filterCounter = filterCounter + 1) : filterCounter;
    });
  }

  const simpleSearchFormBus = useSelector(
    (state: ApplicationState) => state.searchReducer.simpleSearchFormBus,
  );

  const handleChangeSort = (data: any) => {
    dispatch(setBusSort(data.value));
  };

  const emptySearchResult = () => {
    return (
      <EmptySearchWrapper>
        <EmptySearchHeader>Билеты не найдены</EmptySearchHeader>
        <EmptySearchText>
          Это могло произойти из-за следующих причин:
        </EmptySearchText>
        <EmptySearchList>
          <EmptySearchListItem>
            <EmptySearchText>
              На эти даты все билеты куплены. Попробуйте поискать на другие
              даты.
            </EmptySearchText>
          </EmptySearchListItem>
          <EmptySearchListItem>
            <EmptySearchText>
              Неправильно выбраны даты. Проверьте год отправления и прибытия.
            </EmptySearchText>
          </EmptySearchListItem>
          <EmptySearchListItem>
            <EmptySearchText>
              По данному направлению не ходят поезда.
            </EmptySearchText>
          </EmptySearchListItem>
        </EmptySearchList>
      </EmptySearchWrapper>
    );
  };

  const fastestBusNumber =
    buses?.length > 0 &&
    buses.reduce((prev, current) =>
      moment.duration(prev.durationInMinutes).asMinutes() <=
      moment.duration(current.durationInMinutes).asMinutes()
        ? prev
        : current,
    ).durationInMinutes;

  const cheapestBusNumber =
    buses?.length > 0 &&
    buses?.reduce((prev, current) =>
      prev.price < current.price ? prev : current,
    ).price;

  const fastestBuses =
    buses?.length > 0 &&
    buses.filter((x) => x.durationInMinutes === fastestBusNumber);
  const cheapestBuses =
    buses?.length > 0 && buses.filter((x) => x.price === cheapestBusNumber);

  const fastestBusMemo = React.useMemo(() => fastestBuses, [buses]);
  const cheapestBusMemo = React.useMemo(() => cheapestBuses, [buses]);

  const emptySearchResultMemoized = React.useMemo(
    () => emptySearchResult(),
    [],
  );

  const defaultSelectOption = {
    label: 'По времени отправления',
    value: 'TIME_DEPARTURE',
  };

  const needCollapseFilter = useMediaQuery({ maxWidth: 1024 });

  return props.buses?.length == 0 ? (
    emptySearchResultMemoized
  ) : (
    <TicketList>
      <Heading>
        {' '}
        {searchStep === 'forward' ? 'Туда:' : 'Обратно:'}&nbsp;
        {simpleSearchFormBus.fromBus.nameRu +
          ' - ' +
          simpleSearchFormBus.toBus.nameRu}
        <SelectWrapper isMobile={needCollapseFilter}>
          <Select
            onChange={(data: any) => {
              handleChangeSort(data);
            }}
            value={TRAINS_SEARCH_SORT_BY.find(
              (item) => item.value === busSearch.sortBy,
            )}
            defaultValue={defaultSelectOption}
            isSearchable={false}
            name="sort"
            options={TRAINS_SEARCH_SORT_BY}
          />
          {needCollapseFilter && (
            <FilterButtonWrapper>
              <FilterButton
                onClick={() => {
                  dispatch(toggleTrainSearchFilter());
                }}
                counter={filterCounter}
              />
            </FilterButtonWrapper>
          )}
        </SelectWrapper>
      </Heading>
      <TagButtonsBlock>
        <Button
          isFast
          chosen={busSort === 'TIME'}
          onClick={() =>
            dispatch(setBusSort(busSort !== 'TIME' ? 'TIME' : 'TIME_ARRIVAL'))
          }
        >
          Самый быстрый
        </Button>
        <Button
          isCheap
          chosen={busSort === 'PRICE'}
          onClick={() =>
            dispatch(setBusSort(busSort !== 'PRICE' ? 'PRICE' : 'TIME_ARRIVAL'))
          }
        >
          Самый дешевый
        </Button>
      </TagButtonsBlock>

      {searchStep === 'forward'
        ? buses?.map((bus: any, key: number) => (
            <BusTicket
              isFastest={fastestBusMemo.find((x) => x.raceId === bus.raceId)}
              isCheapest={cheapestBusMemo.find((x) => x.raceId === bus.raceId)}
              // hasRoundTrip={hasRoundTrip}
              key={bus.index}
              bus={bus}
              setSelectedBus={setSelectedBus}
            />
          ))
        : buses?.map((bus: any, key: number) => (
            // <TicketBackwardContainer
            //   isFastest={fastestTrainMemo === train.trainNumber}
            //   isCheapest={cheapestTrainMemo === train.trainNumber}
            //   hasRoundTrip={hasRoundTrip}
            //   key={train.index}
            //   train={train}
            // />
            <BusTicket
              isFastest={fastestBusMemo.find((x) => x.raceId === bus.raceId)}
              isCheapest={cheapestBusMemo.find((x) => x.raceId === bus.raceId)}
              // hasRoundTrip={hasRoundTrip}
              key={bus.index}
              bus={bus}
              setSelectedBus={setSelectedBus}
            />
          ))}
    </TicketList>
  );
}
