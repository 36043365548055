export const FLIGHT_CLASSES = [
  {
    label: 'Эконом',
    value: 'e',
  },

  {
    label: 'Бизнес',
    value: 'b',
  },
  {
    label: 'Первый',
    value: 'f',
  },
  {
    label: 'Любой',
    value: '',
  },
];

export const ROUTE_TYPES = [
  {
    label: 'В одну сторону',
    value: 'OW',
  },
  { label: 'Туда-обратно', value: 'RT' },
  { label: 'Сложный', value: 'CR' },
  { label: 'Любой', value: '' },
];

export const AGENT_FEE_FLIGHT_TYPES = [
  {
    label: 'Любой',
    value: 'all',
  },
  {
    label: 'Международный перелет',
    value: 'international',
  },
  {
    label: 'Внутренний перелет',
    value: 'inner',
  },
];

export const CRUISE_TYPE = [
  {
    label: 'Чартерный рейс',
    value: 'charter',
  },

  {
    label: 'Регулярный рейс',
    value: 'regular',
  },
];

export const APPLY_TO = [
  // {
  //   value: 'segment',
  //   label: 'К сегментам (сбор умножается на количество сегментов)',
  // },
  {
    value: 'passanger',
    label: 'К пассажирам (сбор умножается на количество пассажиров)',
  },
  // {
  //   value: 'all',
  //   label:
  //     'К пассажирам и к сегментам (cбор применится к количеству пассажиров и сегментов)',
  // },
  {
    value: 'order',
    label:
      'К заказу (cбор применится к стоимости перелета вне зависимости от пассажиров и сегментов)',
  },
];

export const APPLY_TO_PASSANGER = [
  {
    value: 'ADT',
    label: 'Взрослый',
    checked: true,
  },
  {
    value: 'CLD',
    label: 'Ребенок',
    checked: true,
  },

  {
    value: 'INS',
    label: 'Младенец с местом',
    checked: true,
  },
];

export const TYPE_AMOUNT = [
  { value: 'rub', label: 'рубли' },
  { value: 'percent', label: 'процент' },
];
