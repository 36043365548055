import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  APPEAL_STEP,
  GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket,
  getAddAppealState,
  getRefundAmountState,
  setRefundPositions,
  setRefundStep,
} from '@modules/appeals';
import { refundNormalizer } from '../normalizer';
import {
  pickEmdsForTickets,
  getInitialReason,
  reducer,
  filterRefundReasons,
  findPassanger,
  findAncillary,
  getPassangerFullName,
  pickAncillaryType,
  filterPositionsByReason,
  findSegment,
  formatDate,
  pickAncillaryLabel,
} from '../utils/createFormRefund';
import { RefundTypeCode } from '../CreateFormRefund';
import { ApplicationState } from '@modules/index';
import { PositionAwailableReason } from '@app/components/order/Detail/OrderPositions/Item/types';
import { getCityByCode } from '@app/modules/simpleSearch';

export interface RefundSelectOption {
  checked: boolean;
  index: number;
  value?: string | null;
  label: string;
  data: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket;
}

const useCreateFormRefund = () => {
  const [tickets, setTickets] = useState<RefundSelectOption[]>([]);
  const [services, setServices] = useState<RefundSelectOption[]>([]);
  const initState = refundNormalizer(tickets, services);
  const [state, innerDispatch] = React.useReducer<React.Reducer<any, any>>(
    reducer,
    initState,
  );
  const { STEP } = useSelector(getAddAppealState);
  const refundAmount = useSelector(getRefundAmountState);
  const dispatch = useDispatch();
  const orderDetail = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.data,
  );
  const initialReason = getInitialReason();
  const refundReasons = filterRefundReasons(refundAmount.data.involuntry);
  const refundType = state.refundType;

  const onSubmit = async () => {
    const selectedTickets = state.tickets.filter(
      (ticket: RefundSelectOption) => ticket.checked,
    );
    const selectedServices = state.services.filter(
      (service: RefundSelectOption) => service.checked,
    );
    const selectedRefundItems =
      refundType === RefundTypeCode.AVIA
        ? [
            ...selectedTickets,
            ...pickEmdsForTickets(
              selectedTickets,
              refundAmount.data.refundEmds,
            ),
          ]
        : selectedServices;

    const refundItems = selectedRefundItems.map(
      (item: {
        data: GeneratedTuiOrderServiceApiServicesOrderModelRefundTicket;
        index: number;
      }) => {
        const { data } = item;
        const passanger = findPassanger(refundAmount, data);
        const ancillaryService = findAncillary(refundAmount, data);

        if (
          PositionAwailableReason.FULL_REFUND_SPLIT.includes(
            data.reasonCode || '',
          )
        )
          return {
            fullName: getPassangerFullName(passanger),
            number: data.number,
            fullPrice: data.fullPrice || 0,
            equivAmount: data.equivAmount?.value || 0,
            totalHoldSum: data.totalHoldSum || 0,
            totalRefundSum: data.totalRefundSum?.value || 0,
            totalTaxAmount: data.totalTaxAmount?.value || 0,
            tteCaTax: data.tteCaTax || 0,
            ttePrTax: data.ttePrTax || 0,
            reasonCode: data.reasonCode,
            fee: data.fee?.value || 0,
          };

        if (
          PositionAwailableReason.PARTIAL_REFUND.includes(data.reasonCode || '')
        )
          return {
            fullName: getPassangerFullName(passanger),
            emdName: ancillaryService.name,
            emdType: pickAncillaryType(ancillaryService.name),
            number: data.number,
            fullPrice: data.fullPrice || 0,
            equivAmount: data.equivAmount?.value || 0,
            totalHoldSum: data.totalHoldSum || 0,
            totalRefundSum: data.totalRefundSum?.value || 0,
            totalTaxAmount: data.totalTaxAmount?.value || 0,
            tteCaTax: data.tteCaTax || 0,
            ttePrTax: data.ttePrTax || 0,
            reasonCode: data.reasonCode,
            fee: data.fee?.value || 0,
          };
      },
    );
    dispatch(setRefundStep(APPEAL_STEP.REFUND_CONFIRM));
    dispatch(
      setRefundPositions(
        // @ts-ignore
        refundItems,
      ),
    );
  };

  const parseRefundData = async () => {
    const tickets = refundAmount.data.refundTickets || [];
    const emds = refundAmount.data.refundEmds || [];

    const resultTickets: RefundSelectOption[] = tickets
      .filter((ticket) => filterPositionsByReason(ticket, initialReason))
      .map((ticket, index) => {
        const passanger = findPassanger(refundAmount, ticket);
        return {
          checked: false,
          index,
          preLabel: getPassangerFullName(passanger),
          label: `билет №${ticket.number}, дата рождения ${
            passanger?.birthDate
              ? moment(passanger?.birthDate).format('DD.MM.YYYY')
              : 'отсутствует'
          }`,
          value: ticket.number,
          data: ticket,
        };
      });

    const resultEmds: RefundSelectOption[] = await Promise.all(
      emds
        .filter((emd) => filterPositionsByReason(emd, initialReason))
        .map(async (emd, index) => {
          const ancillaryService = findAncillary(refundAmount, emd);
          const aviaSegment = findSegment(refundAmount, emd);
          const departureCity = await getCityByCode(
            aviaSegment?.departureCityCode || '',
          );
          const arrivalCity = await getCityByCode(
            aviaSegment?.arrivalCityCode || '',
          );

          return {
            checked: false,
            index,
            preLabel: `${departureCity.nameRus} - ${arrivalCity.nameRus} ${formatDate(aviaSegment?.departureDateTime)}`,
            label: `${pickAncillaryLabel(orderDetail, ancillaryService.type)}, № EMD ${emd.number}`,
            value: emd.number,
            data: emd,
          };
        }),
    );
    setTickets(resultTickets);
    setServices(resultEmds);
    innerDispatch({
      type: `updateTickets`,
      payload: resultTickets,
    });
    innerDispatch({
      type: `updateEmds`,
      payload: resultEmds,
    });
  };

  useEffect(() => {
    if (STEP === APPEAL_STEP.REFUND_AUTO && !refundAmount.isLoading) {
      parseRefundData();
    }
  }, [STEP, refundAmount, refundType]);

  useEffect(() => {
    if (!initialReason.value) return;
    innerDispatch({
      type: `setRefundType`,
      payload: initialReason,
    });
  }, []);

  return {
    state,
    innerDispatch,
    onSubmit,
    initialReason,
    refundReasons,
  };
};

export default useCreateFormRefund;
