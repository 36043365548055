import React from 'react';
import styled from 'styled-components';
import { BlockWithMaxWidth, Title, GrayText, Paragraph } from './shared';
import { ReactComponent as InsuranceIcon } from '@assets/media/main-page/insurance-banner.svg';

const Container = styled.div`
  background: #edfaf9;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 45px;
  height: 350px;
  overflow: hidden;

  & > svg {
    align-self: flex-end;
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    position: relative;
    & > svg {
      width: 345.2px;
      height: 309.29px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    & > :first-child {
      z-index: 2;
    }
  }

  @media (max-width: 767px) {
    height: auto;
    display: block;
    padding-left: 0;
    border-radius: 16px;

    & > :first-child {
      margin-bottom: 24px;
    }

    & > svg {
      width: 100%;
      height: auto;
    }
  }
`;

const InfoContainer = styled.div`
  width: 474px;
  & > span {
    display: block;
  }

  ${GrayText} {
    margin-bottom: 8px;
  }

  ${Title} {
    margin-bottom: 24px;
  }

  ${Paragraph} {
    margin-bottom: 20px;
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 350px;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: auto;
    padding: 30px 21px 0 21px;
    ${GrayText} {
      margin-bottom: 5px;
    }
  }
`;

export default () => (
  <BlockWithMaxWidth>
    <Container>
      <InfoContainer>
        <GrayText>Ваша защита в любых условиях</GrayText>
        <Title>Страхование перелета</Title>
        <Paragraph>
          Отдых пройдет спокойнее с поддержкой надежной страховой компании.
          Полис обеспечит вам финансовую защиту в случае болезни, утраты багажа,
          отмены поездки и в других непредвиденных ситуациях.
        </Paragraph>
        <Paragraph>
          Обезопасьте свое путешествие от любых рисков с помощью надежных
          пакетов страхования.
        </Paragraph>
      </InfoContainer>
      <InsuranceIcon />
    </Container>
  </BlockWithMaxWidth>
);
