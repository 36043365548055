import { ApplicationState } from '@modules/index';

export const getAgentSiginState = (state: ApplicationState) =>
  state.agents.agentSigninInfo;

export const getIsAgent = (state: ApplicationState) =>
  !!getAgentSiginState(state);

export const getAgentInfo = (state: ApplicationState) => state.agents.agent;

export const getAgentBalance = (state: ApplicationState) =>
  state.agents.balance;

export const getOverPaidsOrders = (state: ApplicationState) =>
  state.agents.overPaidOrders;

export const getNotPaidOrders = (state: ApplicationState) =>
  state.agents.notPaidOrders;

export const getAgentCreditHistory = (state: ApplicationState) =>
  state.agents.agentsCreditLimitHistory;

export const getAgentPaymentHistory = (state: ApplicationState) =>
  state.agents.agentPaymentHistory;

export const getAgentLoadings = (state: ApplicationState) =>
  state.agents.loadings;
