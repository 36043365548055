import { FetchStrategyCallback } from './types';
import { UserCookie } from '@services/UserCookies';
import { ERRORS_DESCRIPTIONS, STATUS } from './constants';
import { HttpError } from './handlers';

const updateObject = (obj: any, addictionalParams: { [key: string]: any }) =>
  obj ? { ...obj, ...addictionalParams } : { ...addictionalParams };

export const authGuard = (): FetchStrategyCallback => [
  (args) => {
    if (UserCookie.token) {
      return {
        status: true,
        args: {
          ...args,
          headers: updateObject(args.headers, {
            Authorization: `Bearer ${UserCookie.token}`,
          }),
        },
      };
    } else {
      return {
        status: false,
        args: args,
        error: new HttpError(STATUS.AUTH, ERRORS_DESCRIPTIONS.AUTH),
      };
    }
  },
  (response) => {
    const isNotAuthorized = response.status === 401;
    if (isNotAuthorized) {
      return {
        status: false,
        error: new HttpError(STATUS.AUTH, ERRORS_DESCRIPTIONS.AUTH),
      };
    }
    return { status: true };
  },
];
