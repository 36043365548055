import React from 'react';

const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6024 18.3337 10C18.3337 5.39765 14.6027 1.66669 10.0003 1.66669C5.39795 1.66669 1.66699 5.39765 1.66699 10C1.66699 14.6024 5.39795 18.3334 10.0003 18.3334Z"
        stroke="#4872F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 5.83331H10.0083"
        stroke="#4872F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33301 9.16669H9.99967V13.3334"
        stroke="#4872F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33301 13.3333H11.6663"
        stroke="#4872F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InfoIcon;
