import React from 'react';
import styled from 'styled-components';

const List = styled.ol`
  padding-left: 17px;
  & > li {
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const Item = styled.li`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  font-family: Open Sans;
  font-style: normal;

  &::marker,
  > strong {
    font-weight: 600;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 230px;
  }
`;

export default styled(function ({
  items,
  className,
}: {
  items: (string | any)[];
  className?: string;
}) {
  return (
    <List className={className}>
      {items.map((x, key) => (
        <Item key={key}>{x}</Item>
      ))}
    </List>
  );
})``;
