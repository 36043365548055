import React from 'react';
import { Button } from '@components/ui';
import styled from 'styled-components';
import { Checkbox } from '@components/ui/form';
import Text, { Link } from '@components/ui/Typography';
import { useFormikContext } from 'formik';

export interface Props {
  withCovid?: boolean;
  withTariffs?: boolean;
}

const Wrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;

  & > :first-child {
    margin-right: 8px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    width: 230px;
  }

  @media (max-width: 767px) {
    display: block;
    & > button {
      width: 100%;
    }
  }
`;

const StyledLink = styled(Link)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const PromoContainer = styled.div`
  display: flex;
`;

const TrainPaymentBlock: React.FC<Props> = ({
  withCovid,
  withTariffs,
}: Props) => {
  const {
    isValid,
    dirty,
    handleChange,
    errors,
    values,
    submitCount,
    validateForm,
    setFieldValue,
  } = useFormikContext<{
    covid: boolean;
    tariffs: boolean;
    offerta: boolean;
    emailOfferta: boolean;
  }>();

  return (
    <Wrapper>
      {withCovid && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.covid}
            name="covid"
            error={errors.covid && submitCount > 0 ? true : false}
            onChange={handleChange}
          />{' '}
          <StyledText
            color={errors.covid && submitCount > 0 ? 'dark-gray' : 'dark-gray'}
          >
            Я подтверждаю, что все пассажиры в моем бронировании соответствуют
            требованиям&nbsp;
            <StyledLink to="/docs/oferta.pdf" target="_blank">
              “Заявление о состоянии здоровья в связи с COVID-19”
            </StyledLink>
            &nbsp; на дату оформления. Я понимаю, что при нарушении любых
            пунктов этого заявления пассажирам будет отказано в поездке.
          </StyledText>
        </CheckboxWrapper>
      )}
      <CheckboxWrapper style={{ marginBottom: 10 }}>
        <Checkbox
          type="squar"
          checked={values.offerta}
          name="offerta"
          error={errors.offerta && submitCount > 0 ? true : false}
          onChange={handleChange}
        />{' '}
        <StyledText
          color={errors.offerta && submitCount > 0 ? 'dark-gray' : 'dark-gray'}
        >
          Я ознакомлен и согласен с{' '}
          <StyledLink to="/docs/oferta.pdf" target="_blank">
            договором публичной оферты
          </StyledLink>
          и{' '}
          <StyledLink to="/docs/police.pdf" target="_blank">
            политикой в отношении обработки персональных данных
          </StyledLink>
          .
        </StyledText>
      </CheckboxWrapper>
      {withTariffs && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.tariffs}
            name="tariffs"
            error={errors.tariffs && submitCount > 0 ? true : false}
            onChange={handleChange}
          />{' '}
          <StyledText
            color={
              errors.tariffs && submitCount > 0 ? 'dark-gray' : 'dark-gray'
            }
          >
            Я согласен(а) c&nbsp;
            <StyledLink to="/docs/oferta.pdf" target="_blank">
              условиями применения тарифов перевозки.
            </StyledLink>
          </StyledText>
        </CheckboxWrapper>
      )}
      <CheckboxWrapper>
        <Checkbox
          type="squar"
          checked={values.emailOfferta}
          name="emailOfferta"
          error={errors.emailOfferta && submitCount > 0 ? true : false}
          onChange={handleChange}
        />{' '}
        <StyledText
          color={
            errors.emailOfferta && submitCount > 0 ? 'dark-gray' : 'dark-gray'
          }
        >
          Я согласен(а) на получение email-рассылки.
        </StyledText>
      </CheckboxWrapper>
      <Container>
        <Button
          disabled={!dirty}
          type="primary"
          htmlType="submit"
          color="yellow"
          onClick={async () => {
            const curErrors = await validateForm();
            const curErrorsKeys = Object.keys(curErrors);
            if (curErrorsKeys.length) {
              const key = curErrorsKeys[0];
              let el;
              if (key === 'offerta') return;

              if (key === 'passengers') {
                // @ts-ignore
                const firstErrorIndex = curErrors.passengers.findIndex(
                  (item: undefined) => item !== undefined,
                );
                el = document.getElementById(`passenger.${firstErrorIndex}`);
              } else if (
                key === 'contacts' ||
                ['name', 'phone', 'email'].includes(key)
              ) {
                el = document.getElementById('contacts');
              }

              if (el) {
                const offset = el.offsetTop;
                window.scrollTo({
                  top: offset - 100,
                  behavior: 'smooth',
                });
              }
            }
          }}
        >
          Перейти к оплате
        </Button>
      </Container>
    </Wrapper>
  );
};

export default TrainPaymentBlock;
