import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Text, Button } from '@components/ui';
import { IssueModalProps } from '../types';

const StyledPopup = styled(Popup)`
  &-content {
    width: 569px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
    @media screen and (min-width: 375px) and (max-width: 1439px) {
      width: 335px;
      height: 218px;
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 25px;
  @media screen and (min-width: 375px) and (max-width: 1439px) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
  max-width: 430px;
  @media screen and (min-width: 375px) and (max-width: 1439px) {
    max-width: 313px;
    margin-bottom: 20px;
    line-height: 22px;
  }
`;

const IssueModal: React.FC<IssueModalProps> = ({
  issueStatus,
  onClose,
  ...props
}) => {
  return (
    <StyledPopup {...props} onClose={onClose}>
      <Title>
        {issueStatus?.ticketIssue === true
          ? 'Успешная выписка'
          : 'Выписка недоступна'}
      </Title>
      <Question>
        {issueStatus?.ticketIssue === true
          ? 'Выписка билетов по данному заказу прошла успешно. Статус заказа обновлен.'
          : `${issueStatus?.errorText}`}
      </Question>
      <Button type="outlined" onClick={() => onClose()}>
        Вернуться к деталям заказа
      </Button>
    </StyledPopup>
  );
};

export default IssueModal;
