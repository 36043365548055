import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import ManIcon from '@assets/images/man';
import AirplaneUpIcon from '@assets/images/airplaneUpIcon';

interface Props {
  title: string;
  /**
   * Тип остановки - техническая или обычная
   */
  type: 'technical' | 'normal';
}

const Separator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 6px;
`;

const SeparatorContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    flex-shrink: 0;
`;

const StyledManIcon = styled(ManIcon)`
    width: 16px;
    height: 16px;
    fill: #737373;
    flex-shrink: 0;
`;

const StyledText = styled(Text)`
    font-size: 14px;
    line-height: 18px;
    color: #737373;
    
    @media (max-width: 767px) {
        font-size: 12px;
    }
`;

const DashedLine = styled.div`
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #C4C4C4 8px, transparent 4px);
  background-repeat: repeat;
  background-size: 16px 1px; /* 4px dash + 4px gap */
`;

const StopSeparator: React.FC<Props> = (props) => {
  const { title, type } = props;
  return (
    <Separator>
      <DashedLine />
      <SeparatorContent>
        {type === 'normal' && <StyledManIcon />}
        {type === 'technical' && <AirplaneUpIcon />}
        <StyledText>{title}</StyledText>
      </SeparatorContent>
      <DashedLine />
    </Separator>
  );
};

export default StopSeparator;
