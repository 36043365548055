import React, { useState } from 'react';
import Tippy from '@tippyjs/react/';
import styled from 'styled-components';
import { Text as BaseText } from '@components/ui';
import { Point } from '@components/common/FlightSegment';

import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import Popup from 'reactjs-popup';
import { GetTrainRouteDto } from '@modules/trainTickets/dto/GetTrainRouteDto';
import RailwayDetailRoutesContainer from '@components/common/RouteDetail/RailwayDetailRoutesContainer';

interface Props {
  routeType: 'avia' | 'railway' | 'bus'; // Тип маршрута: авиа, железнодорожный или автобус
  title: string; // Заголовок
  departureDateTimeUtc: string; // Время отправления в формате UTC
  arrivalDateTimeUtc: string; // Время прибытия в формате UTC
  localDepartureDate: string; // Местная дата отправления
  localArrivalDate: string; // Местная дата прибытия
  tripDuration: string; // Продолжительность поездки

  fromStation: {
    title: string; // Название станции отправления
    description: string; // Описание станции отправления
    code: string; // Код станции отправления
  };

  toStation: {
    title: string; // Название станции прибытия
    description: string; // Описание станции прибытия
    code: string; // Код станции прибытия
  };

  noServices?: boolean; // Отсутствуют ли услуги
  noFeatures?: boolean; // Отсутствуют ли особенности
  services?: React.ReactNode[]; // Услуги
  features?: React.ReactNode[]; // Особенности

  selectedPlacesListNodes?(): React.ReactNode; // Выбранные места

  getRoute: () => void; // Получить маршрут
  routeDetails?: GetTrainRouteDto; // Детали маршрута

  additionalOptions?: {
    isRailwayTransit: boolean; // Является ли маршрут транзитным через СНГ
  };
}

const LeftTicketPart = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const HeaderText = styled(BaseText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const HeaderServices = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 14px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const BigTime = styled(BaseText)`
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  font-weight: bold;
  color: rgb(60, 60, 60);

  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const ProgressLineWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin: 0px 10px;
`;

const Line = styled.div`
  margin-top: 20px;
  height: 2px;
  background-color: rgb(220, 220, 220);
  position: relative;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
`;

const Duration = styled(BaseText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  @media (max-width: 767px) {
    font-size: 10px;
  }
`;

const TrainRouteText = styled(BaseText)`
  &:hover {
    cursor: pointer;
  }
`;

const PointsContainer = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: -7px;

  @media (max-width: 767px) {
    top: -5px;
  }
`;

const NormalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :first-child {
    text-align: left;
  }

  & > :last-child {
    text-align: right;
  }
`;

const FlexStarRow = styled(NormalRow)`
  align-items: flex-start;
`;

const SmallText = styled(BaseText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;

  & * strong {
    color: #4872f2;
    font-weight: 600;
  }
`;

const TextFeaturesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;

  @media (max-width: 767px) {
    margin-bottom: 14px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    max-width: 600px;
    padding: 0;
  }

  @media (max-width: 767px) {
    &-content {
      width: auto;
    }
  }
`;

const TimeLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.footer`
  margin-top: 14px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 14px;

  span {
    cursor: pointer;
    color: #4872f2;
  }
`;

export function MainTicketInfo(props: Props) {
  const {
    title,
    services,
    noServices,
    routeType,
    noFeatures,
    features,
    departureDateTimeUtc,
    arrivalDateTimeUtc,
    localDepartureDate,
    localArrivalDate,
    tripDuration,
    fromStation,
    toStation,
    routeDetails,
    additionalOptions,
    selectedPlacesListNodes,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [trainRouteState, setTrainRouteState] = useState(false);

  const handleGetRoute = () => {
    props.getRoute();
    setTrainRouteState(true);
  };

  return (
    <LeftTicketPart>
      <Header>
        <HeaderText>{title}</HeaderText>
        {!isMobile && !noServices && (
          <HeaderServices>
            {services?.map((service, index) => (
              <React.Fragment key={index}>{service}</React.Fragment>
            ))}
          </HeaderServices>
        )}
      </Header>
      <div>
        <TimeLabelContainer>
          <SmallText>Местное</SmallText>
          <SmallText>Местное</SmallText>
        </TimeLabelContainer>
        <FirstRow>
          <Tippy
            theme="light"
            content={`Московское время: ${moment(
              `${departureDateTimeUtc}`,
            ).format('HH:mm')} `}
          >
            <BigTime>{moment(localDepartureDate).format('HH:mm')}</BigTime>
          </Tippy>

          <ProgressLineWrapper>
            <Line>
              <Duration>
                {tripDuration ? `${tripDuration},` : ''}
                <>
                  {tripDuration ? ' ' : ', '}
                  <TrainRouteText
                    onClick={handleGetRoute}
                    size={'small'}
                    color={'blue'}
                  >
                    маршрут
                  </TrainRouteText>
                </>
              </Duration>
              <PointsContainer>
                <Point />
                <Point />
              </PointsContainer>
            </Line>
          </ProgressLineWrapper>
          <Tippy
            theme="light"
            content={`Московское время: ${moment(
              `${arrivalDateTimeUtc}`,
            ).format('HH:mm')} `}
          >
            <BigTime>{moment(localArrivalDate).format('HH:mm')}</BigTime>
          </Tippy>
        </FirstRow>
        <FlexStarRow>
          <SmallText>
            <div>{fromStation.title}</div>
            {fromStation.description ? (
              <div>
                <strong>{fromStation.description}</strong>
              </div>
            ) : (
              ''
            )}
          </SmallText>
          {toStation && (
            <SmallText>
              <div>{toStation.title}</div>
              {toStation.description ? (
                <div>
                  <strong>{toStation.description}</strong>
                </div>
              ) : (
                ''
              )}
            </SmallText>
          )}
        </FlexStarRow>
        <NormalRow>
          <SmallText>
            {moment(localDepartureDate).format('DD MMMM YYYY, dd')}
          </SmallText>
          <SmallText>
            {moment(localArrivalDate).format('DD MMMM YYYY, dd')}
          </SmallText>
        </NormalRow>
        {isMobile && !noServices && (
          <HeaderServices>
            {services?.map((service, index) => (
              <React.Fragment key={index}>{service}</React.Fragment>
            ))}
          </HeaderServices>
        )}
        {!noFeatures && (
          <TextFeaturesWrapper>
            {features?.map((feature, index) => (
              <React.Fragment key={index}>{feature}</React.Fragment>
            ))}
          </TextFeaturesWrapper>
        )}
        {isMobile && selectedPlacesListNodes && selectedPlacesListNodes()}
      </div>
      <StyledPopup
        open={trainRouteState}
        onClose={() => {
          setTrainRouteState(false);
        }}
      >
        <RailwayDetailRoutesContainer
          title={title}
          routeType={routeType}
          routeDetails={routeDetails}
          fromStationCode={fromStation.code}
          toStationCode={toStation?.code || ''}
          additionalOptions={{
            isRailwayTransit: additionalOptions?.isRailwayTransit,
          }}
        />
      </StyledPopup>
    </LeftTicketPart>
  );
}
