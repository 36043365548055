import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2709 18.1918C13.1844 18.1061 13.1158 18.0042 13.069 17.8921C13.0221 17.7799 12.998 17.6597 12.998 17.5383C12.998 17.4169 13.0221 17.2966 13.069 17.1845C13.1158 17.0723 13.1844 16.9705 13.2709 16.8847L18.1868 11.9998L13.2709 7.11489C13.0965 6.94156 12.9985 6.70648 12.9985 6.46135C12.9985 6.21623 13.0965 5.98114 13.2709 5.80781C13.4452 5.63448 13.6817 5.53711 13.9283 5.53711C14.1749 5.53711 14.4114 5.63448 14.5857 5.80781L20.1572 11.3463C20.2437 11.432 20.3123 11.5339 20.3591 11.646C20.4059 11.7582 20.43 11.8784 20.43 11.9998C20.43 12.1212 20.4059 12.2415 20.3591 12.3536C20.3123 12.4657 20.2437 12.5676 20.1572 12.6534L14.5857 18.1918C14.4995 18.2778 14.397 18.346 14.2842 18.3925C14.1714 18.439 14.0504 18.463 13.9283 18.463C13.8061 18.463 13.6852 18.439 13.5724 18.3925C13.4596 18.346 13.3571 18.2778 13.2709 18.1918Z"
        fill="#4872F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.9997C0 11.7549 0.0978323 11.5201 0.271975 11.347C0.446118 11.1739 0.682306 11.0767 0.928581 11.0767H17.643C17.8893 11.0767 18.1255 11.1739 18.2996 11.347C18.4738 11.5201 18.5716 11.7549 18.5716 11.9997C18.5716 12.2446 18.4738 12.4793 18.2996 12.6525C18.1255 12.8256 17.8893 12.9228 17.643 12.9228H0.928581C0.682306 12.9228 0.446118 12.8256 0.271975 12.6525C0.0978323 12.4793 0 12.2446 0 11.9997Z"
        fill="#4872F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2133 24C23.9522 24 24.6607 23.7082 25.1832 23.1889C25.7056 22.6696 25.9991 21.9652 25.9991 21.2308V2.76923C25.9991 2.03479 25.7056 1.33042 25.1832 0.811089C24.6607 0.291757 23.9522 0 23.2133 0H8.35606C7.61723 0 6.90867 0.291757 6.38624 0.811089C5.86381 1.33042 5.57031 2.03479 5.57031 2.76923V5.53846C5.57031 5.78328 5.66815 6.01806 5.84229 6.19118C6.01643 6.36429 6.25262 6.46154 6.49889 6.46154C6.74517 6.46154 6.98136 6.36429 7.1555 6.19118C7.32964 6.01806 7.42747 5.78328 7.42747 5.53846V2.76923C7.42747 2.52442 7.52531 2.28963 7.69945 2.11652C7.87359 1.94341 8.10978 1.84615 8.35606 1.84615H23.2133C23.4596 1.84615 23.6958 1.94341 23.87 2.11652C24.0441 2.28963 24.1419 2.52442 24.1419 2.76923V21.2308C24.1419 21.4756 24.0441 21.7104 23.87 21.8835C23.6958 22.0566 23.4596 22.1538 23.2133 22.1538H8.35606C8.10978 22.1538 7.87359 22.0566 7.69945 21.8835C7.52531 21.7104 7.42747 21.4756 7.42747 21.2308V18.4615C7.42747 18.2167 7.32964 17.9819 7.1555 17.8088C6.98136 17.6357 6.74517 17.5385 6.49889 17.5385C6.25262 17.5385 6.01643 17.6357 5.84229 17.8088C5.66815 17.9819 5.57031 18.2167 5.57031 18.4615V21.2308C5.57031 21.9652 5.86381 22.6696 6.38624 23.1889C6.90867 23.7082 7.61723 24 8.35606 24H23.2133Z"
        fill="#4872F2"
      />
    </svg>
  );
};

export default Logo;
