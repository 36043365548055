import { CountryListItemType } from '@components/booking/PassengersBlock/types';
import { getCountryList } from '../../../../../utils/helpers';
import { COUNTRIES_WITHOUT_FLAGS } from '@modules/trainBooking';
import ReactCountryFlag from 'react-country-flag';
import React from 'react';
import { PassengersFormData } from '@components/bus/Booking/components/Passengers/interfaces';
import {
  CLIENT_DOCUMENTS_TYPES,
  ClientDocumentType,
  DocumentTypeIds,
} from '@app/utils/constants/doctypes';
import moment from 'moment/moment';

export const getCountries = async (
  setter: (countries: CountryListItemType[]) => void
): Promise<void> => {
  const baseCountries = await getCountryList();
  const countries = baseCountries.filter(
    (country) => !COUNTRIES_WITHOUT_FLAGS.includes(country.value)
  );

  if (countries.length) {
    setter(
      countries.map((country) => ({
        value: country.value,
        label: country.label,
        image: COUNTRIES_WITHOUT_FLAGS.includes(country.value) ? null : (
          <ReactCountryFlag
            countryCode={country.value}
            svg
            style={{
              marginRight: 5,
              width: '24px',
              height: '18px',
              borderRadius: '30%',
            }}
          />
        ),
      }))
    );
  }
};

export const setCountriesToFormData =
  (countriesList: CountryListItemType[]) =>
  (field: PassengersFormData): PassengersFormData => {
    if (field.key !== 'citizenship') return field;

    return {
      ...field,
      fields: [
        ...field.fields.map((localField) => ({
          ...localField,
          options: countriesList,
        })),
      ],
    };
  };

export const filterDocumentTypes = (
  ageCategory: 'Adult' | 'Child' | 'Infant',
  allowedDocumentTypes: DocumentTypeIds[],
  citizenship: string,
  birthDate: Date
) => {
  let documents: ClientDocumentType[] = [];
  // из общего списка документов оставляем только те, которые разрешены для данного вагона и Взрослого
  // TODO потом добавить allowedDocumentTypes

  documents = CLIENT_DOCUMENTS_TYPES.filter((documentType) =>
    documentType.passengerCategory.includes(ageCategory)
  );

  // если выбрана страна Россия, то оставляем только документы для граждан РФ и документы без ограничений
  if (citizenship === 'RU') {
    documents = documents.filter(
      (documentType) =>
        documentType.allowedCitizenship === 'ru' ||
        documentType.allowedCitizenship === 'any' ||
        documentType.allowedCitizenship === 'foreign-special'
    );

    // Если ADULT и меньше 14 лет, то надо исключить Паспорт РФ
    if (
      ageCategory === 'Adult' &&
      moment(birthDate).isAfter(moment().subtract(14, 'years'))
    ) {
      documents = documents.filter(
        (documentType) => documentType.id !== 'RussianPassport'
      );
    }

    // если CHILD, то BirthCertificate надо поставить на первое место
    if (ageCategory === 'Child') {
      const birthCertificate = documents.find(
        (documentType) => documentType.id === 'BirthCertificate'
      );
      if (birthCertificate) {
        documents = documents.filter(
          (documentType) => documentType.id !== 'BirthCertificate'
        );
        documents.unshift(birthCertificate);
      }
    }
  }

  // если выбрана страна не Россия, то оставляем только документы для иностранных граждан и документы без ограничений
  if (citizenship !== 'RU') {
    documents = documents.filter(
      (documentType) =>
        documentType.allowedCitizenship === 'not-ru' ||
        documentType.allowedCitizenship === 'any' ||
        documentType.allowedCitizenship === 'foreign-special'
    );
  }

  return documents.map((documentType) => ({
    value: documentType.id,
    label: documentType.title,
  }));
};
