import React from 'react';
import styled from 'styled-components';
import Flight from './Flight';
import { DescriptionDetailProps } from './types';

const Body = styled.div`
  padding: 20px;
  & > div {
    margin-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
  & > :last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const DescriptionDetail: React.FC<DescriptionDetailProps> = React.memo(
  ({ items }) => {
    return (
      <Body>
        {items.map((props, key) => (
          <Flight className="flight" key={key} {...props} />
        ))}
      </Body>
    );
  }
);

export default DescriptionDetail;
