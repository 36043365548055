import React from 'react';

const SuccessOrderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="23" cy="23" r="23" fill="#4872F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3374 13.4732L18.3939 34.1594L5.605 22.999L9.02196 19.2221L18.3475 27.3601L35.8713 10.0516L39.3374 13.4732Z"
        fill="white"
      />
    </svg>
  );
};

export default SuccessOrderIcon;
