import { GetAppealSubjectsDTO, PaymentStatus } from './types';
import { Api } from '@utils';
import { ORDER_SERVICE_HOST } from '@modules/constants';

const { CONTENT_TYPE, STRATEGY_TYPES } = Api;

export const getAppealSubjects = (): Promise<GetAppealSubjectsDTO> =>
  Api.request(
    {
      url: `${ORDER_SERVICE_HOST}${process.env.REACT_APP_REFFERNCES_SUBJECTS}`,
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));

export const getAppealStatuses = (): Promise<GetAppealSubjectsDTO> =>
  Api.request(
    {
      url: process.env.REACT_APP_APPEAL_STATUS_REFERENCES as string,
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));

export const getPaymentStatuses = (): Promise<PaymentStatus[]> =>
  Api.request(
    {
      url: process.env.REACT_APP_ORDER_PAYMENT_STATUS_REFERENCES as string,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));
