import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import errorIcon from '@assets/images/common/errorIcon.svg';
import { getModalErrorText } from '@modules/orders/slices/detailOrderSlice';
import { ApplicationState } from '@modules/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPopup = styled(Popup)`
  &-content {
    width: 510px;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
    min-height: 200px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: 'Open Sans', serif;
  margin-left: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

const Text = styled.div`
  font-family: 'Open Sans', serif;
  margin-left: 20px;
  display: block;
  margin-bottom: 30px !important;
`;

const ErrorIcon = styled.img.attrs({ src: errorIcon })`
  margin-left: 7px;
  margin-bottom: 10px;
  cursor: pointer;
`;

interface Props {
  showErrorModal: boolean;
  setShowErrorModal: Dispatch<SetStateAction<boolean>>;
  issue?: boolean;
}

const ErrorModal: React.FC<Props> = ({
  showErrorModal,
  setShowErrorModal,
  issue,
}) => {
  const dispatch = useDispatch();

  const errorModalText = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.modalErrorText
  );

  return (
    <StyledPopup
      onClose={() => {
        setShowErrorModal(false);
        dispatch(getModalErrorText(''));
      }}
      open={showErrorModal}
    >
      <Container>
        <Row>
          <Title>
            {issue ? 'Ошибка при выписке билета' : 'Внимание! Ошибка '}
          </Title>
          <ErrorIcon
            onClick={() => {
              setShowErrorModal(false);
              dispatch(getModalErrorText(''));
            }}
          />
        </Row>
        <Text>{errorModalText}</Text>
      </Container>
    </StyledPopup>
  );
};

export default ErrorModal;
