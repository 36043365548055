import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Text as BaseText } from '@components/ui';
import { Bus } from '@modules/busTickets';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FlexSpaceBetween = styled(Flex)`
  justify-content: space-between;
`;

const Container = styled(FlexSpaceBetween)`
  padding: 0 14px;
  padding-top: 9px;
  border-top: 1px solid #f2f2f2;
  margin-top: 10px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BaggageWrapper = styled(Flex)`
  & > :first-child {
    margin-right: 5px;
  }
`;

const Button = styled(NavLink)`
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background: #4872f2;
  border-radius: 4px;
  padding: 7px 10.5px;
  min-width: 139px;
  box-sizing: border-box;
  text-align: center;
`;

const Text = styled(BaseText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

interface Props {
  bus: Bus;
}

const MobileBottomBus: React.FC<Props> = ({ bus }) => {
  return (
    <Container>
      <BaggageWrapper>
        <Text>
          Доступно{' '}
          <span style={{ color: '#4872F2' }}>
            {' '}
            {bus.freePlaceQuantity} мест{' '}
          </span>
        </Text>
      </BaggageWrapper>
      <Button to={`/`}> Купить за {bus.price} ₽</Button>
    </Container>
  );
};

export default MobileBottomBus;
