import React from 'react';
import styled from 'styled-components';
import clockIconPath from '@assets/media/booking/clock-price-changed.png';
import { Text, Button } from '@components/ui';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  background: #fff3cd;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 14px;

  & > :first-child {
    margin-right: 8px;
  }
`;

const Description = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  margin-bottom: 14px;
`;

const GoBackButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  padding: 7px 16px;
`;

export default function PriceChangeBlock() {
  const { goBack } = useHistory();

  return (
    <Wrapper>
      <div>
        <img width={30} height={30} src={clockIconPath} alt="icon" />
      </div>
      <div>
        <Description>
          Внимание, цена бронирования изменилась. Пожалуйста, повторите поиск.
        </Description>
        <GoBackButton onClick={goBack}>
          Вернуться к результатам поиска
        </GoBackButton>
      </div>
    </Wrapper>
  );
}
