import {
  FlightEntity,
  SegmentEntity,
  StopEntity,
  SegmentPlaceEntity,
} from '@modules/booking';

export type FlightType = FLIGHT_TYPES.REGULAR | FLIGHT_TYPES.CHARTER | FLIGHT_TYPES.LOWCOSTER;
export enum FLIGHT_TYPES {
  REGULAR = 'Regular',
  CHARTER = 'Charter',
  LOWCOSTER = 'Lowcoster',
}

export interface TicketListProps {
  items: FlightEntity[];
  active: boolean;
  baggageDescr?: string;
}

export interface TicketItemProps extends FlightEntity {
  separator: boolean;
  flightIndex: number;
  length: number;
  active: boolean;
  baggageDescr?: string;
}

export interface SegmentProps extends SegmentEntity {
  transfer?: StopEntity;
  active: boolean;
  fromTo?: string;
  flightType?: FlightType;
  baggageDescr?: string;
}

export type Place = {
  time: string;
  date: string;
} & SegmentPlaceEntity;



