import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  & label {
    display: block;
    margin-bottom: 15px;
  }
  & label:last-of-type {
    margin-bottom: 0;
  }
  text-align: left;
`;

const Thumb = styled.div`
  width: 2px;
  background-color: ${({ theme: { colors } }) => colors.main};
`;
const Track = styled.div`
  height: 100%;
  top: 0;
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.lightGray};
`;

const ScrollBar: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Scrollbars
    autoHeight
    hideTracksWhenNotNeeded
    autoHeightMax={188}
    renderThumbVertical={({ style, ...props }) => (
      <Thumb {...props} style={{ ...style, width: 2, right: 1 }} />
    )}
    renderTrackVertical={({ style, ...props }) => (
      <Track {...props} style={{ ...style, width: 1 }} />
    )}
    renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
    renderView={(props) => <CheckboxContainer {...props} />}
  >
    {children}
  </Scrollbars>
);

export default ScrollBar;
