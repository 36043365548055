import { PassengersFormData } from './interfaces';

export const TITLE = 'Пассажиры';
export const DESCRIPTION =
  'Чтобы избежать трудностей при посадке на поезд, внимательно заполните данные на каждого пассажира в точности так, как в документе, по которому поедете.';
export const BTN_LINK = '+ Добавить из моих пассажиров';

export const PASSENGERS: PassengersFormData[] = [
  {
    key: 'surname',
    width: '1x',
    fields: [
      {
        key: 'surname',
        label: 'Фамилия',
        placeholder: '',
        description: 'Мы переведем ФИО на латиницу',
        value: '',
        disabled: false,
        required: false,
        type: 'input',
      },
    ],
  },
  {
    key: 'name',
    width: '1x',
    fields: [
      {
        key: 'name',
        label: 'Имя',
        placeholder: '',
        value: '',
        disabled: false,
        required: false,
        type: 'input',
      },
    ],
  },
  {
    key: 'middleName',
    width: '1x',
    isFullWidth: true,
    fields: [
      {
        key: 'middleName',
        label: 'Отчество',
        placeholder: '',
        value: '',
        disabled: false,
        required: false,
        type: 'input',
      },
      {
        key: 'toggleMiddleName',
        label: 'Без отчества',
        placeholder: '',
        value: false,
        disabled: false,
        required: false,
        type: 'checkbox',
      },
    ],
  },
  {
    key: 'dob',
    width: '1x',
    fields: [
      {
        key: 'dob',
        label: 'Дата рождения',
        placeholder: 'дд.мм.гггг',
        value: '',
        disabled: false,
        required: false,
        type: 'datePicker',
      },
    ],
  },
  {
    key: 'gender',
    width: '2x',
    title: 'Пол',
    fields: [
      {
        key: 'woman',
        label: 'Женский',
        placeholder: '',
        value: 'woman',
        disabled: false,
        required: false,
        type: 'radio',
      },
      {
        key: 'man',
        label: 'Мужской',
        placeholder: '',
        value: 'man',
        disabled: false,
        required: false,
        type: 'radio',
      },
    ],
  },
  {
    key: 'citizenship',
    width: '1x',
    fields: [
      {
        key: 'citizenship',
        label: 'Гражданство',
        description: 'Выберите из списка или введите',
        placeholder: '',
        value: 'RU',
        options: [],
        disabled: false,
        required: false,
        type: 'countriesSelect',
      },
    ],
  },
  {
    key: 'doctype',
    width: '2x',
    fields: [
      {
        key: 'doctype',
        label: 'Выберите документ',
        placeholder: '',
        value: '',
        options: [],
        disabled: false,
        required: false,
        type: 'select',
      },
    ],
  },
  {
    key: 'passportNumber',
    width: '1x',
    fields: [
      {
        key: 'passportNumber',
        label: 'Серия и номер',
        placeholder: '',
        description: 'Латинские буквы (Х, V, I), 2 буквы кириллицей и 6 цифр',
        value: '',
        disabled: false,
        required: false,
        type: 'input',
      },
    ],
  },
  {
    key: 'issueDate',
    width: '1x',
    fields: [
      {
        key: 'issueDate',
        label: 'Годен до',
        placeholder: 'дд.мм.гггг',
        value: '',
        disabled: false,
        required: false,
        type: 'datePicker',
      },
    ],
  },
  {
    key: 'tariff',
    width: '1x',
    fields: [
      {
        key: 'tariff',
        label: 'Тариф',
        placeholder: '',
        tooltipText:
          'Детский тариф распространяется на детей до 12 лет. Взрослый тариф действует для всех пассажиров старше 12 лет.',
        value: '',
        options: [
          {
            label: 'Полный',
            value: 'full',
          },
          {
            label: 'Детский',
            value: 'children',
          },
        ],
        disabled: false,
        required: false,
        type: 'select',
      },
    ],
  },
];
