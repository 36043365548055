import React from 'react';
import RefundRouter from './Refund/Router';
import SurchargeRouter from './Surcharge/Router';
import DataChangedNotificationRouter from './DataChangedNotification/Router';
import { StepsProvider } from './useSteps';
import { RefundProvider, useRefund } from './useRefund';
import { PaymentProps, CalculationType } from './types';

const Calculation: React.FC<PaymentProps> = (props) => (
  <RefundProvider {...props}>
    <StepsProvider>{content(props?.type?.code)}</StepsProvider>
  </RefundProvider>
);

export const CalculationProvider: React.FC<PaymentProps & React.PropsWithChildren> = (props) => (
  <RefundProvider {...props}>
    <StepsProvider>{props.children}</StepsProvider>
  </RefundProvider>
);

export const CalculationWithouProvider = () => {
  const context = useRefund();
  return content(context?.type?.code);
};

function content(type: CalculationType) {
  switch (type) {
    case CalculationType.ExtraPayment:
      return <SurchargeRouter />;
    case CalculationType.Refund:
      return <RefundRouter />;
    case CalculationType.AgreementWithChanges:
      return <DataChangedNotificationRouter />;
    default:
      return <DataChangedNotificationRouter />;
  }
}

export default Calculation;
