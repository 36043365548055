import React from 'react';
import styled from 'styled-components';
import { BlockRowProps } from './types';

interface WrapperProps {
  template?: string;
  mobileTemplate?: string;
}
const Wrapper = styled.div<WrapperProps>`
  ${({ template }) =>
    template ? `grid-template-columns: ${template} !important;` : ''}
  @media (max-width: 1024px) {
    ${({ mobileTemplate }) =>
      mobileTemplate
        ? `grid-template-columns: ${mobileTemplate} !important;`
        : ''}
  }
`;

const BlockRow: React.FC<BlockRowProps & React.PropsWithChildren> = ({
  children,
  template,
  mobileTemplate,
}) => (
  <Wrapper template={template} mobileTemplate={mobileTemplate}>
    {children}
  </Wrapper>
);

export default BlockRow;
