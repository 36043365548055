import {
  CarGroup,
  GetTicketsListDto,
  Train,
} from '@modules/trainTickets/dto/GetTicketsListDto';
import moment from 'moment/moment';
import {
  Car,
  CarPlace,
  FreePlacesByCompartment,
  GetCarsDto,
} from '@modules/trainTickets/dto/GetCarsDto';
import { useSelector } from 'react-redux';
import {
  trainCurrentSearchStepSelector,
  trainFilterSelector,
} from '@modules/trainTickets/selectors';
import { TRAIN_SEARCH_STEPS } from '@modules/trainTickets/constants';
import { TrainFilter } from '@modules/trainTickets/types';
import { parseInt, uniq } from 'lodash';

export const setGeneralTrainsSort = (
  trains: Train[],
  sortBy: 'TIME' | 'PRICE' | 'TIME_ARRIVAL' | 'TIME_DEPARTURE'
): Train[] => {
  if (!trains.length) return [];
  switch (sortBy) {
    case 'TIME_DEPARTURE':
      return sortTrainsByTimeDeparture(trains);
    case 'TIME_ARRIVAL':
      return sortTrainByTimeArrival(trains);
    case 'TIME':
      return sortTrainsByTime(trains);
    case 'PRICE':
      return sortTrainsByMinPrice(trains);
    default:
      return trains;
  }
};

export const sortTrainsByTimeDeparture = (trains: Train[]): Train[] => {
  return [...trains].sort((a, b) => {
    return (
      moment(a.localDepartureDate).unix() - moment(b.localDepartureDate).unix()
    );
  });
};

export const sortTrainByTimeArrival = (trains: Train[]): Train[] => {
  return [...trains].sort((a, b) => {
    return (
      moment(a.localArrivalDate).unix() - moment(b.localArrivalDate).unix()
    );
  });
};

export const sortTrainsByTime = (trains: Train[]): Train[] => {
  return [...trains].sort((a, b) => {
    return (
      moment.duration(a.tripDuration).asMinutes() -
      moment.duration(b.tripDuration).asMinutes()
    );
  });
};

export const enrichmentTrain = (train: Train): Train => {
  // добавляем в сервисы услугу назначения пола в купе
  const updatedTrainServices = [...train.carServices];
  let hasGenderCabins = false;
  let hasNonRefundableTariff = false;
  train.carGroups.forEach((carGroup, index) => {
    if (carGroup.hasGenderCabins) {
      hasGenderCabins = true;
    }
    if (carGroup.hasNonRefundableTariff) {
      hasNonRefundableTariff = true;
    }
  });
  if (hasGenderCabins) {
    updatedTrainServices.unshift('F_Gender');
  }
  if (hasNonRefundableTariff) {
    updatedTrainServices.unshift('F_RefundTicket');
  }
  return { ...train, carServices: updatedTrainServices };
};

export const enrichmentTrainsData = (
  data: GetTicketsListDto
): GetTicketsListDto => {
  return {
    ...data,
    trainsPerRoutes: data.trainsPerRoutes.map((trainRoute) => {
      return {
        ...trainRoute,
        trains: trainRoute.trains.map((train) => enrichmentTrain(train)),
      };
    }),
  };
};

/**
 * Input: A02, A32;
 * Output: 2002, 2032;
 * @param seatNumber
 */
export const convertSeatPlaceWithALetter = (seatNumber: string): number => {
  return parseInt(seatNumber.replace('А', '')) + 2000;
};

/**`
 * Input: 2001, 2023;
 * Output: А01, А23;
 * @param seatNumber
 */
export const revertConvertSeatPlaceWithALetter = (
  seatNumber: number
): string => {
  if (seatNumber < 2000) {
    return `${seatNumber}`;
  }
  if (seatNumber - 2000 < 10) {
    return `А0${seatNumber - 2000}`;
  } else {
    return `А${seatNumber - 2000}`;
  }
};

/**
 * Convert car place number from scheme to number
 * Input 'Seat22', 'Seat7'
 * Output: '22', '7'
 * If Input 'Seat22A', then output is '2022'
 */
export const convertCarPlaceNumberFromScheme = (placeNumber: string) => {
  const shortNumber = placeNumber.replace('Seat', '');
  // if shortNumber contains А letter, then convertSeatPlaceWithALetter
  const hasALetter = shortNumber.match(/А/g);
  if (hasALetter) {
    return convertSeatPlaceWithALetter(shortNumber) + '';
  } else {
    return shortNumber;
  }
};

export const enrichmentCar = (car: Car, ATrain: boolean): Car => {
  // добавляем в сервисы услугу назначения пола в купе
  const updatedCarServices = [...car.services];
  let updatedFreePlaces = [...car.freePlaces];
  let updatedFreePlacesByCompartments = [...car.freePlacesByCompartments];
  if (car.hasGenderCabins) {
    updatedCarServices.unshift('F_Gender');
  }
  if (car.hasNonRefundableTariffs) {
    updatedCarServices.unshift('F_RefundTicket');
  }
  if (ATrain) {
    updatedFreePlacesByCompartments = car.freePlacesByCompartments.map(
      (compartment) => {
        return {
          ...compartment,
          places: compartment.places.map((place) => {
            const hasALetter = place.number.match(/А/g);
            if (hasALetter) {
              return {
                ...place,
                number: convertSeatPlaceWithALetter(place.number) + '',
              };
            } else {
              return place;
            }
          }),
        };
      }
    );
    updatedFreePlaces = car.freePlaces.map((place) => {
      const hasALetter = place.match(/А/g);
      if (hasALetter) {
        return convertSeatPlaceWithALetter(place) + '';
      } else {
        return place;
      }
    });
  }
  return {
    ...car,
    services: updatedCarServices,
    freePlaces: updatedFreePlaces,
    freePlacesByCompartments: updatedFreePlacesByCompartments,
  };
};

export const enrichmentCarsData = (
  data: GetCarsDto,
  ATrain: boolean
): GetCarsDto => {
  return {
    ...data,
    cars: data.cars.map((car) => enrichmentCar(car, ATrain)),
  };
};

export const sortTrainsByMinPrice = (trains: Train[]): Train[] => {
  // Create a map of car group objects to their corresponding minimum price
  const carGroupMinPriceMap = new Map<CarGroup, number>();
  trains.forEach((train) => {
    train.carGroups.forEach((carGroup) => {
      const currentMinPrice = carGroupMinPriceMap.get(carGroup);
      if (
        currentMinPrice === undefined ||
        carGroup.minPrice.totalPrice < currentMinPrice
      ) {
        carGroupMinPriceMap.set(carGroup, carGroup.minPrice.totalPrice);
      }
    });
  });

  // Sort the trains by their minimum car group price
  return [...trains].sort((a, b) => {
    const aMinPrice = Math.min(
      ...a.carGroups.map(
        (carGroup) => carGroupMinPriceMap.get(carGroup) || Infinity
      )
    );
    const bMinPrice = Math.min(
      ...b.carGroups.map(
        (carGroup) => carGroupMinPriceMap.get(carGroup) || Infinity
      )
    );
    return aMinPrice - bMinPrice;
  });
};

export const useCurrentTrainFilter = (): TrainFilter => {
  const filter = useSelector(trainFilterSelector);
  const currentStep = useSelector(trainCurrentSearchStepSelector);
  const isForward = currentStep === TRAIN_SEARCH_STEPS.TICKET_FORWARD;
  return isForward ? filter[0] : filter[1];
};

export const trainHasNonRefundableTariff = (train: Train): boolean => {
  return train.carGroups.some((carGroup) => carGroup.hasNonRefundableTariff);
};

export const trainHasRoundTripTariff = (train: Train): boolean => {
  return train.carGroups.some((carGroup) =>
    carGroup.discounts.some((discount) => discount.discountType === 'RoundTrip')
  );
};

export const trainsHasRoundTripTariff = (trains: Train[]): boolean => {
  if (!trains?.length) return false;
  return trains.some((train) => trainHasRoundTripTariff(train));
};

export const getTrainPlaceByNumber = (
  seatNumber: number,
  freePlacesByCompartments: FreePlacesByCompartment[]
): CarPlace | null => {
  const freePlaces: CarPlace[] = [];
  let activePlace = null;
  freePlacesByCompartments.forEach((compartment, index) => {
    freePlaces.push(...compartment.places);
  });
  activePlace = freePlaces.find(
    (place) => parseInt(place.number) === seatNumber
  );
  if (activePlace) {
    return activePlace;
  }
  return null;
};

export const getAllPlacesFromCar = (car: Car): CarPlace[] => {
  const places: CarPlace[] = [];
  car.freePlacesByCompartments.forEach((compartment) => {
    places.push(...compartment.places);
  });
  return places;
};

export const getAllServiceClassesFromCar = (car: Car): string[] => {
  const places = getAllPlacesFromCar(car);
  const serviceClasses = uniq(places.map((place) => place.serviceClass));
  return serviceClasses;
};

export const getAllPlaceReservationTypesFromCar = (car: Car): string[] => {
  const places = getAllPlacesFromCar(car);
  //@ts-ignore
  const placeTypes = uniq(places.map((place) => place.placeReservationType));
  return placeTypes;
};
