import * as Styled from './components/styles/Tariffs.styles';
import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import PenaltyDescription from './components/PenaltyDescription';
import { BaseModalProps } from './types';
import { Text } from '@components/ui';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
      overflow: scroll;
    }
  }

  &-content {
    width: 610px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px 20px 40px 40px;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      padding: 20px 10px 20px 20px;
      box-sizing: border-box;
      border-radius: 0;
    }
  }
`;

const OverflowContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding-right: 20px;

  @media (max-width: 767px) {
    max-height: 90vh;
    padding-right: 10px;
  }
`;

const PopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const BlockText = styled(Text)`
  display: block;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  line-height: 20px;
  color: #3c3c3c;
`;

const AccentBluePanel = styled.div`
  border: 1px solid #4872f2;
  background-color: #edf1fe;
  padding: 14px;
  border-radius: 8px;
`;

const TitleText = styled(BlockText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-right: 8px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
  }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  & > :last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 46px;
  outline: none;

  @media (max-width: 767px) {
    right: 25px;
    top: 25px;
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const MobileTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3c3c3c;
  display: block;
  margin-right: 8px;
`;

const CharterTariffsModal: React.FC<BaseModalProps> = (props) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Cross onClick={props.onClose} />
      <PopupHead>
        <Title>
          <TitleText>Условия применения тарифа</TitleText>
        </Title>
      </PopupHead>
      <MobileHead>
        <MobileTitle>Условия применения тарифа</MobileTitle>{' '}
      </MobileHead>
      <OverflowContainer>
        <PenaltyDescription tariffType="charter" />
        <List>
          <Styled.TextContainer>
            <StyledText>
              Клиент предупреждён, что в исключительных случаях возможна замена
              перевозчика, типа воздушного судна на аналогичные услуги без
              взимания какой-либо доплаты со стороны Клиента.
            </StyledText>
          </Styled.TextContainer>
          <Styled.TextContainer>
            <StyledText>
              Клиент уведомлен, что вправе в любое время отказаться
              (аннулировать) от заявки, бронирование которой было подтверждено,
              при условии выплаты фактически понесенных расходов, связанных с
              исполнением обязательств по аннулируемой заявке.
            </StyledText>
          </Styled.TextContainer>
          <Styled.TextContainer>
            <StyledText>
              Под издержками, в данном случае, понимаются фактически понесенные
              расходы агента/консолидатора, в том числе денежные средства,
              потраченные агентом/консолидатором в целях организации туристского
              обслуживания до момента получения письменного извещения от туриста
              об аннулировании заказа.
            </StyledText>
          </Styled.TextContainer>
          <Styled.TextContainer>
            <StyledText>
              Клиент уведомлен, что стоимость авиабилета возвращается частично,
              за исключением суммы фактически понесенных расходов составляющих
              полную или частичную стоимость авиабилетов. Расчет фактически
              понесенных расходов, а также их минимизация производится после
              даты совершения вылета рейса.
            </StyledText>
          </Styled.TextContainer>
          <Styled.TextContainer>
            <StyledText>
              Клиент предупрежден, что если договором воздушной перевозки
              предусмотрено условие о возврате пассажиру стоимости авиабилета и
              пассажир уведомил агента/консолидатора об отказе от перелета,
              расчет денежных средств подлежащих возврату за вычетом фактически
              понесенных расходов происходит по следующей таблице:
            </StyledText>
          </Styled.TextContainer>

          <AccentBluePanel>
            <Styled.StrongText>
              Аннуляция авиабилетов по направлениям Кипр, Индия, ОАЭ, Мальдивы,
              Таиланд, Шри-Ланка, Казахстан, Куба:
            </Styled.StrongText>
            <Styled.MarkedList style={{ marginBottom: '30px' }}>
              <Styled.MarkedListItem>
                <Text>
                  За 22 дня и более до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 25%
                </Text>
              </Styled.MarkedListItem>
              <Styled.MarkedListItem>
                <Text>
                  За 21 день и менее до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 100%
                </Text>
              </Styled.MarkedListItem>
            </Styled.MarkedList>
            <Styled.StrongText>
              Аннуляция авиабилетов по направлениям Турция, Египет, Россия:
            </Styled.StrongText>
            <Styled.MarkedList>
              <Styled.MarkedListItem>
                <Text>
                  За 22 дня и более до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 25%
                </Text>
              </Styled.MarkedListItem>
              <Styled.MarkedListItem>
                <Text>
                  От 21 дня до 10 дней до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 50%
                </Text>
              </Styled.MarkedListItem>
              <Styled.MarkedListItem>
                <Text>
                  За 9 дней и менее до вылета – фактически понесенные расходы
                  ориентировочно могут составлять 100%{' '}
                </Text>
              </Styled.MarkedListItem>
            </Styled.MarkedList>
          </AccentBluePanel>

          <Styled.TextContainer>
            <StyledText>
              Клиент предупрежден, что если заключен договор воздушной перевозки
              пассажира, предусматривающий условие о невозврате провозной платы
              при расторжении договора воздушной перевозки пассажира, уплаченная
              за воздушную перевозку пассажира провозная плата не возвращается,
              и удерживается авиакомпанией.
            </StyledText>
          </Styled.TextContainer>

          <Styled.TextContainer>
            <Text>
              Клиент уведомлен, что он имеет право получить возврат денежных
              средств по невозвратному тарифу в случае вынужденного отказа от
              перевозки. Вынужденным отказом признается отказ вызванный в
              следующих случаях:
            </Text>
          </Styled.TextContainer>
          <Styled.TextContainer>
            <Styled.MarkedList>
              <Styled.MarkedListItem>
                <StyledText>
                  отказ в связи с болезнью пассажира или члена его семьи либо
                  близкого родственника, совместно следующего с ним на воздушном
                  судне, что подтверждается медицинскими документами; либо
                </StyledText>
              </Styled.MarkedListItem>
              <Styled.MarkedListItem>
                <StyledText>
                  смерти члена его семьи или близкого родственника, что
                  подтверждается документально, при условии уведомления об этом
                  перевозчика до окончания времени регистрации пассажиров на
                  указанный в билете рейс.
                </StyledText>
              </Styled.MarkedListItem>
            </Styled.MarkedList>
          </Styled.TextContainer>
        </List>
      </OverflowContainer>
    </StyledPopup>
  );
};

export default CharterTariffsModal;
