import React from 'react';
import {
  CityItemTitle,
  CityItemText,
  CityItemDescription,
  CityCode,
} from './styles';
import { BusSearch, City } from '@modules/simpleSearch';
import { FocusType } from '../useFormFocus';
import styled from 'styled-components';
import { StationBus } from '@app/modules/busTickets';

const CityItem = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  cursor: pointer;
  ${({ active }) => (active ? 'background-color: #f2f2f2;' : '')}
  padding: 0 16px 0 12px;
`;

interface SuggestionItemProps {
  data: BusSearch;

  onSelect(data: StationBus, focus: FocusType): void;

  focus: FocusType;
  active: boolean;

  setSuggestionIndex(index: number | null): void;

  indexNumber: number;
  searchPanelType?: 'avia' | 'train' | 'bus';
}

export const SuggestionItemBus: React.FC<SuggestionItemProps> = ({
  data,
  onSelect,
  focus,
  active,
  setSuggestionIndex,
  indexNumber,
  searchPanelType,
}) => {
  const handleOnEnter = () => {
    setSuggestionIndex(indexNumber);
  };

  const handleOnLeave = () => {
    setSuggestionIndex(null);
  };

  const handleClick = () => {
    //@ts-ignore
    onSelect(data, focus);
  };

  return (
    <CityItem
      active={active}
      onMouseEnter={handleOnEnter}
      onMouseLeave={handleOnLeave}
      onClick={handleClick}
    >
      <CityItemText>
        <CityItemTitle>{data.nameRu}</CityItemTitle>
      </CityItemText>
    </CityItem>
  );
};
