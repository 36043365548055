import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Text, Button as BaseButton } from '@components/ui';
import {
  PopupProps,
  PopupActions,
} from '../../../../../../node_modules/reactjs-popup/dist/types';

export interface BaseModal
  extends Omit<PopupProps & React.RefAttributes<PopupActions>, 'children'> {}

export interface ConfirmCancelationModalProps extends BaseModal {
  onConfirm: () => void;
}

const StyledPopup = styled(Popup)`
  &-content {
    width: 588px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 40px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &-overlay {
    overflow: scroll;
    padding: 20px 0;

    @media (max-width: 767px) {
      z-index: 1000 !important;
      padding: 0;
    }
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3c3c;
  display: block;
  margin-bottom: 20px;
`;

const Question = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
  text-align: left;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: block;

    & > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const Button = styled(BaseButton).attrs({
  type: 'outlined',
  htmlType: 'button',
})`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20px;
`;

export default function ConfirmCancelationModal({
  onConfirm,
  ...props
}: ConfirmCancelationModalProps) {
  return (
    <StyledPopup {...props}>
      <Title>Отклонение изменений</Title>
      <Question>
        Вы уверены, что хотите отклонить изменения в вашем перелете?
      </Question>
      <ButtonBlock>
        <Button onClick={onConfirm}>Да, отклонить изменения </Button>
        <Button
          onClick={() => {
            typeof props.onClose === 'function' && props.onClose();
          }}
        >
          Нет, вернуться к заказу
        </Button>
      </ButtonBlock>
    </StyledPopup>
  );
}
