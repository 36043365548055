import React from 'react';
import { Text } from '@components/ui/Typography';
import styled from 'styled-components';
import airplaneImg from '@assets/media/main-page/Group 3570.svg';
import bookingImg from '@assets/media/main-page/Group 3567.svg';
import contactsImg from '@assets/media/main-page/Group 3571.svg';
import exchangeImg from '@assets/media/main-page/Group 3577.svg';
import paymentImg from '@assets/media/main-page/Group 3578.svg';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  margin-top: 97px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    margin-top: 70px;
    align-items: center;
  }
  @media (max-width: 767px) {
    margin-top: 45px;
    align-items: center;
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  color: #092a5e;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    max-width: 348px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const SubTitle = styled(Text)`
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #3c3c3c;
  white-space: pre;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const SubtitleMobile = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #3c3c3c;
  margin-bottom: 40px;
  max-width: 335px;
  @media (min-width: 767px) {
    display: none;
  }
`;

const IconsWrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) and (min-width: 768px) {
    width: 100%;
    box-sizing: content-box;
    padding: 40px;
  }
  @media (max-width: 767px) {
    width: auto;
  }
`;

const IconsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
    width: auto;
    & > div {
      margin-bottom: 15px;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: row;
  }
`;

// const IconCircle = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   box-shadow: 0px 5px 19px rgba(41, 41, 42, 0.04);

//   &:hover {
//     cursor: pointer;
//     background: #edf1fe;
//     box-shadow: 0px 5px 19px rgba(1, 83, 214, 0.35);
//     transition: 0.3s;
//   }

//   @media (max-width: 767px) {
//     width: 50px;
//     height: 50px;
//     margin-right: 20px;
//     & > img {
//       width: 50%;
//     }
//   }
// `;

const IconText = styled(Text)`
  margin-top: 18px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000c2d;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  }
`;

const IconImage = styled.img`
  width: 100px;
  height: 100px;
  transform: translateY(3px);

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    & > img {
      width: 50%;
    }
  }
`;

const SearchInfo = () => {
  const history = useHistory();
  return (
    <Container>
      <Title>Поиск и бронирование авиабилетов</Title>
      <SubTitle>
        {`Наш сервис — это быстрый поиск и легкое бронирование авиабилетов в любую точку мира. 
        Теперь вам не нужно переходить на сторонние сайты авиакомпаний. Мы предоставим
         широкий выбор авиабилетов, сравним цены и предложим самые 
        выгодные варианты для бронирования`}
      </SubTitle>
      <SubtitleMobile>
        Наш сервис — это быстрый поиск, широкий выбор и легкое бронирование
        авиабилетов в любую точку мира
      </SubtitleMobile>
      <IconsWrapper>
        <IconsContainer>
          <IconWrapper onClick={() => history.push('/info/payment')}>
            <IconImage src={paymentImg} />
            <IconText>Cпособы оплаты билета</IconText>
          </IconWrapper>
          <IconWrapper onClick={() => history.push('/info/payment')}>
            <IconImage src={bookingImg} />
            <IconText>Как забронировать билет</IconText>
          </IconWrapper>
          <IconWrapper onClick={() => history.push('/info/exchange')}>
            <IconImage src={exchangeImg} />
            <IconText>Обмен и возврат билета</IconText>
          </IconWrapper>
          <IconWrapper onClick={() => history.push('/info/rules')}>
            <IconImage src={airplaneImg} />
            <IconText>Часто задаваемые вопросы</IconText>
          </IconWrapper>
          <IconWrapper onClick={() => history.push('/info/contacts')}>
            <IconImage src={contactsImg} />
            <IconText>Контакты авиаотдела</IconText>
          </IconWrapper>
        </IconsContainer>
      </IconsWrapper>
    </Container>
  );
};

export default SearchInfo;
