import { ApplicationState } from '@modules/index';

/** train tickets */
export const trainTicketsSelector = (state: ApplicationState) =>
  state.trainTickets;

export const trainTicketsListSelector = (state: ApplicationState) =>
  state.trainTickets.list;

export const filteredTrainTicketsListSelector = (state: ApplicationState) =>
  state.trainTickets.filteredList;

/** train search */
export const trainTicketsSearchSelector = (state: ApplicationState) =>
  state.trainTickets.trainSearch;

export const trainCurrentSearchStepSelector = (state: ApplicationState) =>
  state.trainTickets.trainSearch.currentSearchStep;

/** train forward */
export const trainForwardSelector = (state: ApplicationState) =>
  state.trainTickets.trainForward;

export const trainForwardCurrentCarSelector = (state: ApplicationState) =>
  state.trainTickets.trainForward.currentCar;

export const trainForwardChosenCarPlacesSelector = (state: ApplicationState) =>
  state.trainTickets.trainForward.chosenCarPlaces;

/** train backward */
export const trainBackSelector = (state: ApplicationState) =>
  state.trainTickets.trainBack;

export const trainBackCurrentCarSelector = (state: ApplicationState) =>
  state.trainTickets.trainBack.currentCar;

export const trainBackChosenCarPlacesSelector = (state: ApplicationState) =>
  state.trainTickets.trainBack.chosenCarPlaces;

/** train filter */
export const trainFilterSelector = (state: ApplicationState) =>
  state.trainTickets.trainSearch.filter;

export const trainFilterForwardSelector = (state: ApplicationState) =>
  state.trainTickets.trainSearch.filter[0];

export const trainFilterBackwardSelector = (state: ApplicationState) =>
  state.trainTickets.trainSearch.filter[1];

export const bedClothsForwardSelector = (state: ApplicationState) =>
  state.trainTickets.trainForward.bedClothes;

export const bedClothsBackwardSelector = (state: ApplicationState) =>
  state.trainTickets.trainBack.bedClothes;
