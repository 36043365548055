import React from 'react';
import styled from 'styled-components';
import { Button } from '@components/ui';
import { usePopup } from '../../../flightsSearch/Filter/Mobile/Popup/usePopup';
import { useDispatch } from 'react-redux';
import { useCurrentTrainFilter } from '@modules/trainTickets/utils';
import { resetTrainFilter } from '@modules/trainTickets';

const Wrapper = styled.div`
  min-height: 71px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  padding: 10px 20px 20px;
  align-self: flex-end;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)``;

const Footer: React.FC = () => {
  const { onClose } = usePopup();
  const dispatch = useDispatch();
  const activeFilter = useCurrentTrainFilter();

  const onResetButtonClick = () => {
    dispatch(
      resetTrainFilter({
        value: null,
        type: activeFilter.filterDestinationType,
      })
    );
    typeof onClose === 'function' && onClose();
  };

  return (
    <Wrapper>
      <StyledButton
        template={'blue'}
        type={'outlined'}
        onClick={() => {
          onResetButtonClick();
        }}
      >
        Сбросить
      </StyledButton>
      <StyledButton color={'yellow'} type={'primary'} onClick={onClose}>
        Применить фильтры
      </StyledButton>
    </Wrapper>
  );
};

export default Footer;
