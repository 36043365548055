import React, { ReactElement } from 'react';
import Info from '@components/bus/Booking/components/Info';

const InfoMessageForPlaces = (): ReactElement => (
  <Info>
    <b>Пассажиры этого рейса выбирают места при посадке в автобус.</b>
    Перевозчик не предоставил возможность выбора мест при бронировании.
  </Info>
);

export default InfoMessageForPlaces;
