import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { CanelAppealModalProps } from './types';
import { Text, Button } from '@components/ui';

const StyledPopup = styled(Popup)`
  &-overlay {
    @media (max-width: 767px) {
      z-index: 1000 !important;
    }
  }
  &-content {
    width: 519px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      padding: 14px;
      padding-top: 88px;
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      padding: 20px;
    }
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }
`;

const Question = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 40px;
`;

const ActionsBlock = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    & > button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: normal;
`;

const Modal: React.FC<CanelAppealModalProps> = ({ onConfirm, ...props }) => {
  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.open]);

  return (
    <StyledPopup {...props}>
      <Title>Не выбрано страхование</Title>
      <Question>
        Вы не выбрали ни один пакет страхования для защиты вашего перелета
      </Question>
      <ActionsBlock>
        <StyledButton onClick={props.onClose} type="primary">
          Добавить страховку
        </StyledButton>
        <Button onClick={onConfirm} type="outlined">
          Мне не нужна страховка
        </Button>
      </ActionsBlock>
    </StyledPopup>
  );
};

export default Modal;
