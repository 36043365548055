import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import htmlParse from 'html-react-parser';
import { getPrintedOrder } from '@modules/orders';
import { useParams, useHistory } from 'react-router-dom';
import { printOrderRequest, printOrderSuccess } from '@modules/orders/duck';
import styled from 'styled-components';
import { RoundIcon } from '@components/loader';
import { getAuthStatus } from '@modules/user';
import SignIn from '@components/authModals/SignIn';

const Wrapper = styled.div`
  max-width: 640px;
  margin: 20px auto;
`;

const SignInWrapper = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const PrintOrder: React.FC = () => {
  const order = useSelector(getPrintedOrder);
  const isAuthorized = useSelector(getAuthStatus);
  const print = order ? htmlParse(order) : '';
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const orderPositionId = searchParams.get('orderPositionId') || '';
    dispatch(printOrderRequest({ orderId: params.id, orderPositionId }));
    return () => {
      dispatch(printOrderSuccess(''));
    };
  }, [history]);

  return isAuthorized ? (
    <>{order === '' ? <RoundIcon /> : <Wrapper>{print}</Wrapper>}</>
  ) : (
    <SignInWrapper>
      <SignIn disableRedirect />
    </SignInWrapper>
  );
};

export default PrintOrder;
