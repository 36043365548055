import React from 'react';
import styled from 'styled-components';

import Passengers from './Passengers';
import { InfoBlock } from '@components/common';
import Tariff from './Mobile/Tariff';
import { PositionContextProvider } from './useItemProps';
import { PositionContextProviderProps } from './types';

import PriceDetail from './PriceDetail';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    margin-bottom: 30px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

const StyledTariff = styled(Tariff)`
  margin-bottom: 30px;
`;

const PassengerWrapper = styled(InfoBlock)`
  padding: 16px 14px 20px 14px !important;
`;

const DetailPopup: React.FC<PositionContextProviderProps> = (props) => {
  return (
    <PositionContextProvider {...props}>
      <Body>
        <StyledTariff {...props.tariff} />
        {/* <Passengers
          items={props.passengers}
          itemWrapper={<PassengerWrapper />}
        /> */}
        {/* <InfoBlock>
          <PriceDetail {...props.priceDetail} />
        </InfoBlock> */}
      </Body>
    </PositionContextProvider>
  );
};

export default DetailPopup;
