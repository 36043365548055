import React from 'react';

const TicketPlaneIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4632_11068)">
        <path
          d="M19.5213 13.0905L7.67447 8.14615L4.80502 10.1957L13.7829 16.7223L19.5213 13.0905Z"
          fill="#486AD4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5189 13.0657L25.0543 9.55858C26.0571 8.92145 28.0519 8.4613 28.7464 9.56723C29.4409 10.6674 28.5075 12.0555 27.5047 12.6927L21.6427 16.4121L21.1422 29.22L18.0707 30.9594L15.9239 20.034L10.8126 23.277L10.3171 27.6287L8.46048 28.7481L8.00298 22.7116L2.73781 19.723L4.55425 18.5177L8.70028 19.9306L13.8116 16.6875L13.8109 16.687L19.5186 13.0655L19.5189 13.0657Z"
          fill="#839FF6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4632_11068">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TicketPlaneIcon;
