import React from 'react';
import styled from 'styled-components';
import { SelectWithCheckboxesProps } from './types';
import Arrow2 from '../../../assets/images/arrow';
import { Text } from '../Typography';

const Wrapper = styled.div<{ errShow?: boolean; disabled?: boolean }>`
  border: 1px solid #dcdcdc;
  color: #3c3c3c;
  ${({ errShow }) => {
    if (errShow) {
      return `border-color: #E73C3E; color: #E73C3E`;
    }
    return '';
  }}
  ${({ disabled }) => {
    if (disabled) {
      return `background-color: #dcdcdc; cursor: default`;
    }
    return '';
  }}
  border-radius: 4px;
  min-height: 40px;
  width: 100%;
  position: relative;
  outline: none;
`;

const Button = styled.button.attrs({ type: 'button' })`
  border: none;
  outline: none;
  background: transparent;
  padding: 10px;
  color: hsl(0, 0%, 80%);
`;

const Arrow = styled.svg.attrs({
  height: 20,
  width: 20,
  viewBox: '0 0 20 20',
  focusable: false,
})`
  fill: currentColor;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
`;

const InputArea = styled(Text)`
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  user-select: none;

  & > span {
    font-weight: 600;
  }
`;

const List = styled.div<{ visible: boolean }>`
  position: absolute;
  transition: max-height 0.15s ease-in-out, opacity 0.15s ease-in-out;
  background: white;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  height: auto;
  max-height: 0;
  overflow: auto;
  opacity: 0.2;
  ${({ visible }) => (visible ? 'max-height: 240px; opacity: 1;' : '')}
`;

const ListItemBase = styled.button<{
  focused: boolean;
}>`
  border: none;
  outline: none;
  background-color: transparent;
  display: block;
  height: 44px;
  width: 100%;
  text-align: left;
  padding: 10px 14px;
  box-sizing: border-box;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  user-select: none;
  ${({ focused }) => (focused ? 'background-color: #edf0ff;' : '')}

  & > :first-child {
    margin-right: 8px;
  }
`;

const SelectAll = styled(ListItemBase)`
  color: #4872f2;
`;

const SquarCheckbox = styled.div<{
  checked: boolean;
}>`
  ${({ theme: { colors }, checked }) => `
        display: inline-flex;
        flex-shrink: 0;
        border: 1px solid ${checked ? colors.main : colors.gray};
        box-sizing: border-box;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        background: ${colors.white};
        transition: all 150ms;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        & > svg {
            transition: all 150ms;
            display: block;
            transform: scale(${checked ? '1' : '0'});
            fill: ${colors.main};
        }
    `}
`;
const PreLabel = styled.span`
  font-weight: 600;
`;

export const Label = styled.label<{ errShow?: boolean }>`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors }, errShow }) =>
    errShow ? '#E73C3E' : colors.darkGray};

  margin-bottom: 4px;
  user-select: none;
`;

export default function (props: SelectWithCheckboxesProps) {
  const [open, setOpen] = React.useState(false);
  const [focus, setFocus] = React.useState(null as null | number);
  const ref = React.useRef(null as null | HTMLDivElement);

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current) {
        if (!ref.current.contains(e.target as any)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (props.items.filter((x) => x.checked).length === props.items.length) {
      setOpen(false);
    }
  }, [props.items]);

  const handleSelectAll = () => {
    setOpen(false);
    props.onSelectAll();
  };

  const handleClick = () => {
    setOpen((s) => !s);
    typeof props.onClick === 'function' && props.onClick();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const length = props.items.length;
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      if (focus === null) {
        setFocus(0);
        return;
      }

      const key = e.key;

      if (key === 'ArrowDown') {
        setFocus((x) => {
          return (x as number) < length ? (x as number) + 1 : 0;
        });
      } else {
        setFocus((x) => {
          return (x as number) > 0 ? (x as number) - 1 : length;
        });
      }
    }
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' && focus !== null) {
      e.preventDefault();
      if (focus === props.items.length) {
        props.onSelectAll();
        return;
      }
      const item = props.items[focus];
      props.onSelect(item, item.checked ? 'remove' : 'select');
      setOpen(false);
    }
  };

  const inputText = React.useMemo(
    () =>
      props.items
        .filter((x) => x.checked)
        .reduce(
          (acc, x, index) =>
            acc +
            `${index !== 0 ? ' / ' : ''}${
              x?.preLabel
                ? `<span style={font-weight="600"}>${x.preLabel}, </span>${x.label}`
                : x.label
            }`,
          ''
        ),
    [props.items]
  );

  return (
    <div style={{ width: '100%' }}>
      {props.label && <Label errShow={props.error}>{props.label}</Label>}
      <Wrapper
        errShow={props.error}
        ref={ref}
        disabled={props.disabled}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        tabIndex={0}
      >
        <Flex onClick={handleClick}>
          <InputArea dangerouslySetInnerHTML={{ __html: inputText }} />
          <Button>
            <Arrow aria-hidden="true">
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </Arrow>
          </Button>
        </Flex>

        <List visible={open}>
          {props.items.map((x, key) => (
            <ListItemBase
              key={key}
              type="button"
              focused={focus === key}
              onMouseEnter={() => setFocus(key)}
              onClick={() => props.onSelect(x, x.checked ? 'remove' : 'select')}
            >
              <SquarCheckbox checked={x.checked}>
                <Arrow2 width={15} height={10} />
              </SquarCheckbox>
              <span>
                {x?.preLabel && <PreLabel>{x?.preLabel}, </PreLabel>}
                {x.label}
              </span>
            </ListItemBase>
          ))}

          {props.selectAllText && !!props.items.length && (
            <SelectAll
              type="button"
              focused={props.items.length === focus}
              onMouseEnter={() => setFocus(props.items.length)}
              onClick={handleSelectAll}
            >
              {props.selectAllText}
            </SelectAll>
          )}
        </List>
      </Wrapper>
    </div>
  );
}
