import React from 'react';
import { useDispatch } from 'react-redux';
import { setIsOffline } from '@modules/ui/duck';

const NetworkStatus: React.FC = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const goOnline = () => {
            dispatch(setIsOffline(false));
        };

        const goOffline = () => {
            dispatch(setIsOffline(true));
        };

        window.addEventListener('online', goOnline);
        window.addEventListener('offline', goOffline);

        return () => {
            window.removeEventListener('online', goOnline);
            window.removeEventListener('offline', goOffline);
        };
    }, [dispatch]);

    return null;
};

export default NetworkStatus;