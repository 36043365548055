import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import Head from './Head';
import Body from './Body';
import Footer from './Footer';
import { useDispatch } from 'react-redux';

import { CalendarProps } from '../types';
import { CalendareProvider } from './useCalendar';
import { stickyFooterHide } from '@modules/orders';

const StyledPopup = styled(Popup)`
  &-content {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    border: none;
    border-radius: 0;
    overflow-x: scroll;
    position: relative;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

interface CalendarComponentProps extends CalendarProps {
  onTypeChange?(type: 'range' | 'default'): void;
}
const CalendarComponent: React.FC<CalendarComponentProps> = ({
  type = 'default',
  onTypeChange,
  onClose,
  values,
  open,
  ...props
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  const handlePopupClose = () => {
    document.body.style.overflow = 'auto;';
    dispatch(stickyFooterHide(false));

    props.setDirection(null);
    dispatch(stickyFooterHide(false));
  };

  return open ? (
    <CalendareProvider
      values={values}
      onClose={handlePopupClose}
      onChange={props.onChange}
      direction={props.direction}
      setDirection={props.setDirection}
    >
      <StyledPopup open={true} onClose={handlePopupClose}>
        <Head />
        <Body />
        <Footer />
      </StyledPopup>
    </CalendareProvider>
  ) : null;
};

export default CalendarComponent;
