import React from 'react';
import { Train, trainBackSelector, trainTicketsSearchSelector } from '@modules/trainTickets';
import { Ticket } from '@components/train/TrainSearch/Ticket/Ticket';
import { useSelector } from 'react-redux';

interface Props {
  train: Train;
  isFastest?: boolean;
  isCheapest?: boolean;
  hasRoundTrip: boolean;
}

const TicketBackwardContainer: React.FC<Props> = (props: Props) => {
  const trainIndexFromState = useSelector(trainBackSelector).index;
  const carsIsLoading = useSelector(trainTicketsSearchSelector).carsStatus == 'loading';
  const { train, isFastest, isCheapest, hasRoundTrip } = props;
  return (
    <Ticket
      trainIndexFromState={trainIndexFromState}
      train={train}
      isCheapest={isCheapest}
      isFastest={isFastest}
      hasRoundTrip={hasRoundTrip}
      isLoading={carsIsLoading}
    />
  );
};

export default TicketBackwardContainer;
