import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  FormikPassengerItemEntity,
  PassengerAgeCategory,
  setPassengerName,
  setPassengerSecondName,
  setPassengerSurname,
  UpdatePassengerPayload,
} from '@modules/booking';
import Text from '@components/ui/Typography';
import { CountryListItemType, TrainPassengerItemProps } from './types';
import styled from 'styled-components';
import {
  Select,
  DatePicker,
  Field,
  RadioGroup,
  Checkbox,
} from '@components/ui/form';
import { Hooks } from '@utils';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@modules/index';
// import { PassangerAutoSelectModal } from '../AutoSelectModal';
import _ from 'lodash';
import moment from 'moment';
// import { getPassanagersRequest } from '@modules/user';
import NumberTooltip from '@components/aviaPassengers/Form/NumberTooltip';
import InputMask from 'react-input-mask';
import { getPassengerIconPath } from '@components/booking/PassengersBlock/utils';
import { RailwayBonusCard } from '@modules/trainBooking/dto/BookTrainTicketDto';
import {
  BookingFormikErrors,
  RailwayClientDocumentType,
  TEST_PASSENGER_ADULT,
  TEST_PASSENGER_CHILD,
  TEST_PASSENGER_INFANT,
  TRAIN_DISCOUNT_CARD_TYPES,
} from '@modules/trainBooking';
import CountriesSelect from '@components/booking/CountriesSelect';
import { CLIENT_DOCUMENTS_TYPES } from '@app/utils/constants/doctypes';
import Button from '@components/ui/Button';

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: contents;
`;

const Header = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    ${Text} {
      font-weight: bold;
    }

    @media (max-width: 780px) {
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      ${Text} {
        font-weight: 600;
      }
    }
  `,
};

const Form = styled.div<{ hasContactsFields: boolean }>`
  display: grid;
  grid-template-areas:
    'field-1 field-2 field-3'
    'lastNameRequired firstNameReqired field-4'
    'field-5 field-6 .'
    '. field-14 field-14'
    'field-7 field-8 field-8'
    'field-9 field-10 field-11'
    'field-12 field-13 .'
    ${(props) => (props.hasContactsFields ? "'field-15 field-16 .'" : '')};
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 20px;

  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    grid-template-areas:
      'field-6'
      'field-1'
      'field-2'
      'field-3'
      'field-4'
      'field-5'
      'field-7'
      'field-8'
      'field-9'
      'field-10'
      'field-11'
      'field-12'
      'field-13'
      'field-14'
      ${(props) => (props.hasContactsFields ? "'field-15'" : '')}
      ${(props) => (props.hasContactsFields ? "'field-16'" : '')};
  }

  & .field-1 {
    grid-area: field-1;
  }

  & .field-2 {
    grid-area: field-2;
  }

  & .field-3 {
    grid-area: field-3;
  }

  & .field-4 {
    grid-area: field-4;
  }

  & .field-5 {
    grid-area: field-5;
  }

  & .field-6 {
    grid-area: field-6;
    @media (max-width: 767px) {
      height: 56px;
    }
  }

  & .field-7 {
    grid-area: field-7;
  }

  & .field-8 {
    grid-area: field-8;
  }

  & .field-9 {
    grid-area: field-9;
  }

  & .field-10 {
    grid-area: field-10;
  }

  & .field-11 {
    grid-area: field-11;
  }

  & .field-12 {
    grid-area: field-12;
  }

  & .field-13 {
    grid-area: field-13;
  }

  & .field-14 {
    grid-area: field-14;
    margin-top: -24px;
  }

  & .field-15 {
    grid-area: field-15;
  }

  & .field-16 {
    grid-area: field-16;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const AddPassangers = styled(Text)`
  margin-left: auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => `
    ${theme.max('780px')} {
      margin-left:0px;
      margin-top: 15px;
    }`}
`;

const PassengerIcon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;

const FioBLock = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

const ErrorText = styled.span`
  color: #e73c3e;
  font-size: 11px;
`;

const TestButtonsContainer = styled.span`
  display: flex;
  gap: 10px;
`;

const TrainPassengerItem: React.FC<TrainPassengerItemProps> = ({
  index,
  onChange,
  countriesList,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isMobile } = Hooks.useMediaSizes();
  const { allowedDocumentTypes } = props;

  const isAdult = props.ageCategory === 'ADULT';
  const formik = useFormikContext<{
    passengers: FormikPassengerItemEntity[];
    needShareContacts: boolean;
  }>();

  const handleTicketGenderChange = (value: string) => {
    formik.setFieldValue(`passengers.${index}.sex`, value);
  };

  const filterDocumentTypes = (ageCategory: 'Adult' | 'Child' | 'Infant') => {
    let documents: RailwayClientDocumentType[] = [];
    // из общего списка документов оставляем только те, которые разрешены для данного вагона и Взрослого
    if (allowedDocumentTypes && allowedDocumentTypes.length > 0) {
      documents = CLIENT_DOCUMENTS_TYPES.filter(
        (documentType) =>
          allowedDocumentTypes &&
          allowedDocumentTypes.includes(documentType.id) &&
          documentType.passengerCategory.includes(ageCategory),
      );
    }

    // если выбрана страна Россия, то оставляем только документы для граждан РФ и документы без ограничений
    if (props.citizenship === 'RU') {
      documents = documents.filter(
        (documentType) =>
          documentType.allowedCitizenship === 'ru' ||
          documentType.allowedCitizenship === 'any' ||
          documentType.allowedCitizenship === 'foreign-special',
      );

      // Если ADULT и меньше 14 лет, то надо исключить Паспорт РФ и предвыбрать Свидетельство о рождении
      if (
        ageCategory === 'Adult' &&
        moment(props.birthDate).isSameOrBefore(moment()) && // Проверка на будущее
        moment(props.birthDate).isAfter(moment().subtract(14, 'years'))
      ) {
        documents = documents.filter(
          (documentType) => documentType.id !== 'RussianPassport'
        );
        const birthCertificate = documents.find(
          (documentType) => documentType.id === 'BirthCertificate',
        );
        if (birthCertificate) {
          documents = documents.filter(
            (documentType) => documentType.id !== 'BirthCertificate',
          );
          documents.unshift(birthCertificate);
        }
      }

      // если CHILD, то BirthCertificate надо поставить на первое место
      if (ageCategory === 'Child') {
        const birthCertificate = documents.find(
          (documentType) => documentType.id === 'BirthCertificate',
        );
        if (birthCertificate) {
          documents = documents.filter(
            (documentType) => documentType.id !== 'BirthCertificate',
          );
          documents.unshift(birthCertificate);
        }
      }
    }

    // если выбрана страна не Россия, то оставляем только документы для иностранных граждан и документы без ограничений
    if (props.citizenship !== 'RU') {
      documents = documents.filter(
        (documentType) =>
          documentType.allowedCitizenship === 'not-ru' ||
          documentType.allowedCitizenship === 'any' ||
          documentType.allowedCitizenship === 'foreign-special',
      );
    }

    return documents.map((documentType) => ({
      value: documentType.id,
      label: documentType.title,
    }));
  };

  const errors =
    (formik.errors.passengers as undefined | BookingFormikErrors[]) || [];
  const error = errors[index] ? errors[index] : ({} as BookingFormikErrors);
  const { touched, setFieldTouched } = formik;
  const isTouched =
    touched.passengers && touched.passengers[index]
      ? touched.passengers[index]
      : ({} as FormikPassengerItemEntity);

  const documentTypes = React.useMemo(() => {
    switch (props.ageCategory) {
      case 'ADULT': {
        return filterDocumentTypes('Adult');
      }
      case 'CHILD':
        return filterDocumentTypes('Child');
      case 'INFANT': {
        return filterDocumentTypes('Infant');
      }
      default: {
        return filterDocumentTypes('Adult');
      }
    }
  }, [
    props.ageCategory,
    props.birthDate,
    props.citizenship,
    props.allowedDocumentTypes,
  ]);

  const [{ documentType, citizenship }, setSelectVal] = useState({
    documentType: documentTypes[0],
    citizenship: { value: 'RU', label: 'RU - Россия' },
  });

  useEffect(() => {
    // Если текущий выбранный документ не входит в список доступных документов, то меняем его на первый доступный
    if (!documentTypes.some((x) => x.value === documentType?.value)) {
      setSelectVal((select) => ({ ...select, documentType: documentTypes[0] }));
    } else {
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        documentType?.value,
      );
      formik.setFieldValue(
        `passengers.${index}.citizenship`,
        citizenship?.value,
      );
    }
  }, [documentTypes]);

  useEffect(() => {
    if (!formik.values.needShareContacts) {
      formik.setFieldValue(`passengers.${index}.phoneNumber`, null);
      formik.setFieldValue(`passengers.${index}.email`, null);
    }
  }, [formik.values.needShareContacts]);

  const handleRzhdBonusCardNumberChange = (number: string) => {
    // max number length is 13 and accept only numbers or empty string
    if ((number.length > 13 || !/^\d*$/.test(number)) && number !== '') {
      return;
    }

    const newCard: RailwayBonusCard = {
      cardNumber: number,
      cardType: 'RzhdBonus',
    };

    if (number === '') {
      formik.setFieldValue(`passengers.${index}.rzhdBonusCard`, null);
      return;
    } else {
      formik.setFieldValue(`passengers.${index}.rzhdBonusCard`, newCard);
    }
  };

  const handleBirthDateChange = (val: Date | null | any) => {
    if (val.getMonth) {
      formik.setFieldValue(`passengers.${index}.birthDate`, val);
    } else {
      formik.setFieldValue(`passengers.${index}.birthDate`, val);

      const date = val;
      const [day, month, year] = date.split('.');
      const newDate = new Date(+year, +month - 1, +day);
      // const today = new Date();
      if (moment(newDate).isValid()) {
        formik.setFieldValue(`passengers.${index}.birthDate`, newDate);
      }
    }
  };

  const setPassengerNameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        dispatch(setPassengerName(args));
      }, 400),
    [],
  );

  const setPassengerSurnameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        dispatch(setPassengerSurname(args));
      }, 400),
    [],
  );

  const setPassengerSecondNameDebounced = React.useMemo(
    () =>
      _.debounce((args: UpdatePassengerPayload) => {
        dispatch(setPassengerSecondName(args));
      }, 400),
    [],
  );

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const trimValue = e.target.value.trim();
    setPassengerNameDebounced({ passengerIndex: index, value: trimValue });
    formik.setFieldValue(`passengers.${index}.name`, trimValue);
  };

  const handleSurnameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const trimValue = e.target.value.trim();

    setPassengerSurnameDebounced({
      passengerIndex: index,
      value: trimValue,
    });
    formik.setFieldValue(`passengers.${index}.surname`, trimValue);
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(`passengers.${index}.email`, e.target.value);
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(`passengers.${index}.phoneNumber`, e.target.value);
  };

  const handleSecondNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const trimValue = e.target.value.trim();
    setPassengerSecondNameDebounced({
      passengerIndex: index,
      value: trimValue,
    });
    formik.setFieldValue(`passengers.${index}.secondName`, trimValue);
  };

  const handledateOfIssue = (val: Date | null | any) => {
    if (val.getMonth) {
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);
    } else {
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, val);

      const date = val;
      const [day, month, year] = date.split('.');
      const newDate = new Date(+year, +month - 1, +day);
      if (moment(newDate).isValid()) {
        formik.setFieldValue(`passengers.${index}.dateOfIssue`, newDate);
      }
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.target;
    setFieldTouched(`passengers.${index}.${target.name}`, true, true);
  };

  const clearLoyaltyCard = () => {
    formik.setFieldValue(`passengers.${index}.otherRailwayBonusCard`, null);
  };

  const handleChangeLoyaltyCardType = (cardType: string) => {
    const newCard: RailwayBonusCard = {
      cardNumber: '',
      cardType: cardType,
    };

    formik.setFieldValue(`passengers.${index}.otherRailwayBonusCard`, newCard);
  };

  const handleChangeDiscountCardNumber = (cardNumber: string) => {
    // max number length is 13 and accept only numbers or empty string
    if (
      (cardNumber.length > 13 || !/^\d*$/.test(cardNumber)) &&
      cardNumber !== ''
    ) {
      return;
    }

    formik.setFieldValue(
      `passengers.${index}.otherRailwayBonusCard.cardNumber`,
      cardNumber,
    );
  };

  const handleDocumentTypeChange = (val: any) => {
    if (val) {
      setSelectVal((select) => ({ ...select, documentType: val }));
      formik.setFieldValue(`passengers.${index}.documentType`, val.value);
      return;
    }
  };

  const handleCitizenshipChange = React.useCallback(
    (val: CountryListItemType | null | '') => {
      if (val === null) {
        return;
      } else {
        // @ts-ignore
        setSelectVal((select) => ({ ...select, citizenship: val }));
        // @ts-ignore
        formik.setFieldValue(`passengers.${index}.citizenship`, val.value);
        // @ts-ignore
        if (val.value !== 'RU') {
          const foreignDocument = documentTypes.find(
            (x) => x.value === 'ForeignPassport',
          );
          handleDocumentTypeChange(foreignDocument);
          formik.setFieldValue(`passengers.${index}.number`, '');
        } else {
          handleDocumentTypeChange(documentTypes[0]);
          formik.setFieldValue(`passengers.${index}.number`, '');
        }
      }
      return;
    },
    [index],
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(
      `passengers.${index}.secondNameRequired`,
      !e.target.checked,
    );
    if (e.target.checked) {
      formik.setFieldValue(`passengers.${index}.secondName`, '');
    }
  };

  const handleNoLastNameCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    formik.setFieldValue(
      `passengers.${index}.lastNameRequired`,
      !e.target.checked,
    );
    if (e.target.checked) {
      formik.setFieldValue(`passengers.${index}.surname`, '-');
    } else {
      formik.setFieldValue(`passengers.${index}.surname`, '');
    }
  };

  const handleNoNameCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    formik.setFieldValue(
      `passengers.${index}.firstNameRequired`,
      !e.target.checked,
    );
    if (e.target.checked) {
      formik.setFieldValue(`passengers.${index}.name`, '-');
    } else {
      formik.setFieldValue(`passengers.${index}.name`, '');
    }
  };

  const documentMask = React.useMemo(() => {
    switch (documentType?.value) {
      case 'RussianPassport':
        return props.citizenship === 'RU' ? '9999-999999' : '';

      default:
        return '';
    }
  }, [documentType?.value, props.citizenship]);

  const numberPlaceholder = React.useMemo(() => {
    if (
      documentType?.value === 'BirthCertificate' &&
      citizenship?.value === 'RU'
    ) {
      return 'XIIФФ123456';
    } else if (documentType?.value === 'RussianPassport') {
      return '9999-999999';
    } else if (documentType?.value === 'RussianForeignPassport') {
      return '99 9999999';
    } else {
      return isMobile ? undefined : 'Номер';
    }
  }, [isMobile, documentType?.value, citizenship]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState<any>(null);

  const isAuthorized = useSelector(
    (state: ApplicationState) => state.user.status.isAuthorized,
  );

  const openAutoSelect = () => {
    setIsModalOpen(true);
    setModalData(null);
  };
  const closeAutoSelect = () => {
    setIsModalOpen(false);
  };
  const [autoSelectInProgress, setProgress] = React.useState(false);

  // React.useEffect(() => {
  //   if (isAuthorized) {
  //     dispatch(getPassanagersRequest({ SearchText: '' }));
  //   }
  // }, [isAuthorized]);

  React.useEffect(() => {
    if (modalData) {
      const {
        documentType,
        lastName,
        name,
        secondName,
        number,
        dateOfIssue,
        birhDate,
        sex,
        secondNameRequired,
        lastNameRequired,
        firstNameRequired,
        citizen,
        customerPassengerId,
      } = modalData;
      setSelectVal((select) => ({
        ...select,
        citizenship: citizen,
        documentType,
      }));
      formik.setFieldValue(
        `passengers.${index}.documentType`,
        documentType?.value,
      );
      formik.setFieldValue(
        `passengers.${index}.citizenship`,
        citizen ? citizen?.value : 'RU',
      );
      formik.setFieldValue(`passengers.${index}.birthDate`, birhDate);
      formik.setFieldValue(`passengers.${index}.surname`, lastName);
      formik.setFieldValue(`passengers.${index}.number`, number);

      formik.setFieldValue(`passengers.${index}.name`, name);
      formik.setFieldValue(`passengers.${index}.secondName`, secondName);
      // formik.setFieldValue(`passengers.${index}.nameEng`, name);
      // formik.setFieldValue(`passengers.${index}.secondNameEng`, secondName);

      // formik.setFieldValue(`passengers.${index}.surnameEng`, lastName);
      formik.setFieldValue(`passengers.${index}.dateOfIssue`, dateOfIssue);
      formik.setFieldValue(`passengers.${index}.sex`, sex);
      formik.setFieldValue(
        `passengers.${index}.customerPassengerId`,
        customerPassengerId,
      );

      formik.setFieldValue(
        `passengers.${index}.secondNameRequired`,
        secondNameRequired,
      );

      formik.setFieldValue(
        `passengers.${index}.lastNameRequired`,
        lastNameRequired,
      );

      formik.setFieldValue(
        `passengers.${index}.firstNameRequired`,
        firstNameRequired,
      );

      closeAutoSelect();
      setProgress(false);
    }
  }, [modalData]);

  const handleBlurName = () => {
    formik.setFieldTouched(`passengers.${index}.name`, true);
  };

  const handleBlurSurname = () => {
    formik.setFieldTouched(`passengers.${index}.surname`, true);
  };

  const handleBlurSecondName = () => {
    formik.setFieldTouched(`passengers.${index}.secondName`, true);
  };

  const discountCardForSelectValue = React.useMemo(() => {
    return TRAIN_DISCOUNT_CARD_TYPES.find(
      (card) => card.value === props.otherRailwayBonusCard?.cardType,
    );
  }, [props.otherRailwayBonusCard]);

  const fillTestPassenger = (ageCategory: PassengerAgeCategory) => {
    let passenger;
    switch (ageCategory) {
      case 'ADULT':
        passenger = TEST_PASSENGER_ADULT;
        break;
      case 'CHILD':
        passenger = TEST_PASSENGER_CHILD;
        break;
      case 'INFANT':
        passenger = TEST_PASSENGER_INFANT;
        break;
      default:
        passenger = TEST_PASSENGER_ADULT;
    }
    // @ts-ignore
    handleSurnameChange({ target: { value: passenger.surname } });
    // @ts-ignore
    handleNameChange({ target: { value: passenger.name } });
    // @ts-ignore
    handleSecondNameChange({ target: { value: passenger.secondName } });
    handleBirthDateChange(new Date(passenger.birthDate));
    handleTicketGenderChange(passenger.sex);
    formik.setFieldValue(`passengers.${index}.number`, passenger.number || '');
  };

  const dateOfIssueRender = () => {
    return (
      <InputMask
        mask="99.99.9999"
        onChange={(e) => handledateOfIssue(e.target.value)}
        value={
          moment(props.dateOfIssue).isValid()
            ? moment(props.dateOfIssue).format('DD.MM.YYYY')
            : props.dateOfIssue?.toString()
        }
        maskChar={null}
        alwaysShowMask={false}
      >
        <StyledField
          id={`passengers.${index}.dateOfIssue`}
          data-cy="expireDateOfDocument"
          className="field-10"
          label="Срок действия"
          name="partnerId"
          type="tel"
          error={
            error.dateOfIssue &&
            isTouched.dateOfIssue &&
            !error.dateOfIssue.includes('must be a `date`')
              ? error.dateOfIssue
              : error.dateOfIssue &&
                  isTouched.dateOfIssue &&
                  error.dateOfIssue.includes('must be a `date`')
                ? 'Неправильный формат'
                : undefined
          }
          inputMode="numeric"
        />
      </InputMask>
    );
  };

  return (
    <Wrapper id={`passenger.${index}`} key={props.uid}>
      <Header.Wrapper>
        <Text size="normal" color="black">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PassengerIcon
              src={getPassengerIconPath(props.ageCategory)}
              alt=""
            />
            <span style={{ marginLeft: 8, marginRight: 8 }}>
              Пассажир{' '}
              {`${index + 1}, ${props.ageCategoryDescription.toLowerCase()}`}
            </span>
            <TestButtonsContainer>
              <Button
                htmlType={'button'}
                type={'outlined'}
                onClick={() => {
                  fillTestPassenger(props.ageCategory);
                }}
              >
                Тестовый пассажир
              </Button>
            </TestButtonsContainer>
          </div>
        </Text>
        {/*{isAuthorized && filteredPassengers.length > 0 && (*/}
        {/*  <AddPassangers onClick={() => openAutoSelect()}>*/}
        {/*    Добавить из моих пассажиров*/}
        {/*  </AddPassangers>*/}
        {/*)}*/}
      </Header.Wrapper>
      <Form hasContactsFields={formik.values.needShareContacts}>
        <FioBLock className="field-1" title={props.surname}>
          <StyledField
            id={`passengers.${index}.lastNameRu`}
            data-cy="lastNameRu"
            error={
              error.surname && isTouched.surname ? error.surname : undefined
            }
            disabled={!props.lastNameRequired}
            onChange={handleSurnameChange}
            onBlur={handleBlurSurname}
            label="Фамилия"
            value={props.surname}
          />
        </FioBLock>
        <FioBLock className="field-2" title={props.name}>
          <StyledField
            id={`passengers.${index}.name`}
            data-cy="nameRu"
            error={
              error.name && isTouched.name && props.firstNameRequired
                ? error.name
                : undefined
            }
            disabled={!props.firstNameRequired}
            onChange={handleNameChange}
            onBlur={handleBlurName}
            label="Имя"
            value={props.name}
          />
        </FioBLock>
        <FioBLock className="field-3" title={props.secondName}>
          <StyledField
            id={`passengers.${index}.secondName`}
            data-cy="patronymicNameRu"
            error={
              error.secondName &&
              isTouched.secondName &&
              props.secondNameRequired
                ? error.secondName
                : undefined
            }
            disabled={!props.secondNameRequired}
            onChange={handleSecondNameChange}
            onBlur={handleBlurSecondName}
            label="Отчество"
            value={props.secondName}
          />
        </FioBLock>

        <div className="lastNameRequired">
          <Checkbox
            id={`passengers.${index}.lastNameRequired`}
            onChange={handleNoLastNameCheckboxChange}
            type="squar"
            label="Без фамилии"
            checked={!props.lastNameRequired}
          />
        </div>

        <div className="firstNameRequired">
          <Checkbox
            id={`passengers.${index}.firstNameRequired`}
            onChange={handleNoNameCheckboxChange}
            type="squar"
            label="Без имени"
            checked={!props.firstNameRequired}
          />
        </div>

        <div className="field-4">
          <Checkbox
            id={`passengers.${index}.secondNameRequired`}
            onChange={handleCheckboxChange}
            type="squar"
            label="Без отчества"
            checked={!props.secondNameRequired}
          />
        </div>
        {isMobile && (
          <InputMask
            mask="99.99.9999"
            onChange={(e) => handleBirthDateChange(e.target.value)}
            value={
              moment(props.birthDate).isValid()
                ? moment(props.birthDate).format('DD.MM.YYYY')
                : props.birthDate?.toString()
            }
            // maskChar={'дд.мм.гггг'}
            alwaysShowMask={false}
          >
            <StyledField
              data-cy="birthDate"
              id={`passengers.${index}.birthDate`}
              className="field-5"
              label="Дата рождения"
              name="partnerId"
              type="tel"
              error={
                error.birthDate &&
                isTouched.birthDate &&
                !error.birthDate.includes('must be a `date`')
                  ? error.birthDate
                  : error.birthDate &&
                      isTouched.birthDate &&
                      error.birthDate.includes('must be a `date`')
                    ? 'Неправильный формат'
                    : undefined
              }
              inputMode="numeric"
              placeholder="дд.мм.гггг"
            />
          </InputMask>
        )}
        {!isMobile && (
          <InputMask
            mask="99.99.9999"
            onChange={(e) => handleBirthDateChange(e.target.value)}
            value={
              moment(props.birthDate).isValid()
                ? moment(props.birthDate).format('DD.MM.YYYY')
                : props.birthDate?.toString()
            }
            // maskChar={'дд.мм.гггг'}
            alwaysShowMask={false}
          >
            <StyledField
              data-cy="birthDate"
              id={`passengers.${index}.birthDate`}
              className="field-5"
              label="Дата рождения"
              name="partnerId"
              type="tel"
              error={
                error.birthDate &&
                isTouched.birthDate &&
                !error.birthDate.includes('must be a `date`')
                  ? error.birthDate
                  : error.birthDate &&
                      isTouched.birthDate &&
                      error.birthDate.includes('must be a `date`')
                    ? 'Неправильный формат'
                    : undefined
              }
              inputMode="numeric"
              placeholder="дд.мм.гггг"
            />
          </InputMask>
        )}

        <div className="field-6">
          <Label>Пол</Label>
          <div>
            <RadioGroup
              id={`passengers.${index}.sex`}
              name={`passenger-${index}`}
              flex
              defaultValue={false}
              onChange={handleTicketGenderChange}
              items={[
                { value: 'm', label: 'Мужской' },
                { value: 'f', label: 'Женский' },
              ]}
              withAutoSelect={!!modalData}
              autoSelectData={modalData ? modalData.sex : ''}
            />
          </div>
        </div>

        <div className={'field-14'}>
          {error.sex && isTouched.sex ? (
            <ErrorText>{error.sex}</ErrorText>
          ) : undefined}
        </div>

        <div className="field-7" data-cy={'citizenshipSelect'}>
          <Label>Гражданство</Label>
          <span id={`passengers.${index}.citizenship`}>
            <CountriesSelect
              searchType={'train'}
              error={error.citizenship ? error.citizenship : undefined}
              citizenship={citizenship}
              handleCitizenshipChange={(val) => handleCitizenshipChange(val)}
              countriesList={countriesList}
            />
          </span>
        </div>

        <div className="field-8" data-cy={'documentSelect'}>
          <Select
            id={`passengers.${index}.documentType`}
            label="Выберите документ"
            onChange={handleDocumentTypeChange}
            isSearchable={false}
            value={documentType}
            options={documentTypes}
          />
        </div>

        <div className="field-9">
          <NumberTooltip
            key={index}
            handleChange={(event) => {
              formik.setFieldValue(
                `passengers.${index}.number`,
                event.target.value,
              );
            }}
            handleBlur={handleBlur}
            mask={documentMask}
            value={props.number}
          >
            <StyledField
              id={`passengers.${index}.number`}
              data-cy="passportNumber"
              wrapperClassName="custom-input"
              error={
                error.number && isTouched.number ? error.number : undefined
              }
              label="Серия и номер"
              placeholder={numberPlaceholder}
            />
          </NumberTooltip>
        </div>

        {(documentType?.value === 'RussianForeignPassport' ||
          documentType?.value === 'ForeignPassport') &&
          isMobile && <>{dateOfIssueRender()}</>}

        {(documentType?.value === 'RussianForeignPassport' ||
          documentType?.value === 'ForeignPassport') &&
          !isMobile && <div className={'field-10'}>{dateOfIssueRender()}</div>}

        {formik.values.needShareContacts && (
          <div className="field-15">
            <InputMask
              mask="7 (999) 999-99-99"
              onChange={handleChangePhone}
              value={props.phoneNumber}
              maskChar={null}
              alwaysShowMask={false}
            >
              <StyledField
                id={`passengers.${index}.phoneNumber`}
                data-cy={`passengers.${index}.phoneNumber`}
                label="Телефон"
                name={`passengers.${index}.phoneNumber`}
                type="tel"
                error={
                  error.phoneNumber && isTouched.phoneNumber
                    ? 'Неправильный формат'
                    : undefined
                }
                inputMode="numeric"
              />
            </InputMask>
          </div>
        )}
        {formik.values.needShareContacts && (
          <div className="field-16">
            <StyledField
              data-cy="contactEmail"
              label="Email"
              name="email"
              onChange={handleChangeEmail}
              value={props.email}
              onBlur={handleBlur}
              error={error.email && isTouched.email ? error.email : undefined}
              style={{ width: '100%' }}
            />
          </div>
        )}

        {/*{isAdult && (*/}
        {/*  <div className={'field-11'}>*/}
        {/*    <StyledField*/}
        {/*      label="РЖД бонус (необязательно)"*/}
        {/*      name={'rzhdBonusCard'}*/}
        {/*      placeholder="13 цифр"*/}
        {/*      data-cy="rzhdBonusCardInput"*/}
        {/*      wrapperClassName="custom-input"*/}
        {/*      inputMode="numeric"*/}
        {/*      onChange={(event) => {*/}
        {/*        handleRzhdBonusCardNumberChange(event.target.value);*/}
        {/*      }}*/}
        {/*      error={*/}
        {/*        error.rzhdBonusCard && isTouched.rzhdBonusCard*/}
        {/*          ? error.rzhdBonusCard*/}
        {/*          : undefined*/}
        {/*      }*/}
        {/*      value={props.rzhdBonusCard ? props.rzhdBonusCard?.cardNumber : ''}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*{isAdult && props.loyaltyCards.length > 0 && (*/}
        {/*  <div className="field-12" data-cy={'rzhdLoyaltyCardSelect'}>*/}
        {/*    <Select*/}
        {/*      label="Карта лояльности"*/}
        {/*      placeholder={'Выберите карту'}*/}
        {/*      isClearable*/}
        {/*      isSearchable={false}*/}
        {/*      onChange={(val) => {*/}
        {/*        if (val === null) {*/}
        {/*          clearLoyaltyCard();*/}
        {/*        }*/}
        {/*        if (val?.value) {*/}
        {/*          handleChangeLoyaltyCardType(val.value);*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      value={discountCardForSelectValue}*/}
        {/*      options={props.loyaltyCards}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*{props.otherRailwayBonusCard?.cardType && (*/}
        {/*  <div className="field-13">*/}
        {/*    <StyledField*/}
        {/*      data-cy="rzhdCardTypesNumber"*/}
        {/*      placeholder="13 цифр"*/}
        {/*      wrapperClassName="custom-input"*/}
        {/*      error={*/}
        {/*        error.otherRailwayBonusCard?.cardNumber &&*/}
        {/*        isTouched.otherRailwayBonusCard?.cardNumber*/}
        {/*          ? error.otherRailwayBonusCard.cardNumber*/}
        {/*          : undefined*/}
        {/*      }*/}
        {/*      onChange={(event) => {*/}
        {/*        handleChangeDiscountCardNumber(event.target.value);*/}
        {/*      }}*/}
        {/*      label="Номер карты лояльности"*/}
        {/*      value={props.otherRailwayBonusCard.cardNumber}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </Form>
    </Wrapper>
  );
};

export default TrainPassengerItem;
