import React from 'react';
import styled from 'styled-components';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  height?: number;
  wrapperClassName?: string;
  active: boolean;
  code?: string;
}

const Container = styled.div<{ active: boolean }>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.blackGray};
  height: 58px;
  box-sizing: border-box;
  width: 256px;
  position: relative;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 767px) {
    height: 54px;
  }

  ${({ active }) => (active ? `z-index: 2;` : '')}
`;

const StyledInput = styled.input`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  border: none;
  outline: 0;
  padding: 29px 50px 9px 12px;
  box-sizing: border-box;
  text-transform: capitalize;
  outline: none;
  width: 100%;
  box-shadow: 0 0 0 2px #4872f2;
  transition: 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  &:focus {
    box-shadow: 0 0 0 2px #ff6d00;
    z-index: 2;
  }
`;

const Label = styled.label<{ active: boolean }>`
  position: absolute;
  transform: translate(12px, 20px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  color: #b9b9b9;

  ${({ active }) =>
    active ? 'transform: translate(12px, 9px) scale(.75);' : ''}
`;

const Code = styled.span`
  color: #b9b9b9;
  text-transform: uppercase;
  position: absolute;
  top: 25px;
  right: 12px;
  pointer-events: none;
`;

const Field: React.FC<InputProps> = (props) => {
  return (
    <Container className={props.className} active={props.active}>
      <Label active={props.active || Boolean(props.value)}>{props.label}</Label>
      <StyledInput {...props} ref={props.inputRef} />
      {props.code && <Code>{props.code}</Code>}
    </Container>
  );
};

export default Field;
