import { all, takeLatest, put } from 'typed-redux-saga';
import { showNotification, setNotification } from './duck';
import { ShowNotificationPayload } from './types';
import { Action } from 'redux-act';

export function* showNotificationWorker({
  payload,
}: Action<ShowNotificationPayload>) {
  const { code, message } = payload;

  switch (code) {
    case 0: {
      yield* put(
        setNotification({
          label: 'Ошибка',
          body: 'Отсутствует подключение к интернету, попробуйте еще раз',
          buttonName: 'Попробовать еще раз',
          code,
        })
      );
      break;
    }
    case 401:
      break;
    case 999: {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      yield* put(
        setNotification({
          label:
            width > 767 ? 'Цена бронирования изменилась' : 'Цена изменилась',
          body:
            width > 767
              ? 'Внимание, цена вашего бронирования изменилась. Пожалуйста, повторите поиск.'
              : 'Внимание, цена бронирования изменилась. Пожалуйста, повторите поиск.',
          buttonName: 'Вернуться к результатам поиска',
          code,
        })
      );
      break;
    }

    default: {
      yield* put(
        setNotification({
          label:
            code === 499
              ? 'Не удалось забронировать заказ'
              : code === 498
              ? 'Билеты на рейс закончились'
              : payload?.data?.NewPrice &&
                payload?.data?.BookingType !== 'GetActualOfferAndBook'
              ? 'Обращаем внимание!'
              : payload?.data?.NewPrice &&
                payload?.data?.BookingType === 'GetActualOfferAndBook'
              ? 'Закончились места по тарифу'
              : code === 404 &&
                message ===
                  'Истек срок действия предложения. Информация будет обновлена'
              ? 'Не удалось забронировать'
              : payload.message ===
                'Норма провоза багажа на выбранном рейсе - не более 40 кг на пассажира'
              ? 'Обращаем внимание!'
              : code === 497
              ? 'Успешная авторизация'
              : 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
          body:
            (code >= 400 && message && code !== 499) ||
            (code < 500 && message && code !== 499)
              ? message
              : code === 499
              ? 'Пожалуйста, повторите поиск и создайте бронирование повторно'
              : code === 497
              ? 'Вы будете перенаправлены в раздел агентов FUN&SUN, где сможете работать с авиабилетами.'
              : 'Произошла техническая ошибка, пожалуйста, попробуйте позже',
          buttonName:
            code === 499
              ? 'Повторить поиск'
              : code === 498
              ? 'Показать рейсы'
              : code === 497
              ? 'Перейти к авиабилетам'
              : 'Вернуться назад',
          code,
          searchRequest: payload.searchRequest,
          data: payload?.data,
          booking: payload?.booking,
        })
      );
      break;
    }
  }
}

export default function* notificationFlow() {
  yield* all([takeLatest(showNotification.getType(), showNotificationWorker)]);
}
