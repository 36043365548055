import { Month, Day } from './types';
import moment, { Moment } from 'moment';

export function generateMonthList(
  dateForward?: string | null,
  dateBack?: string | null,
  direction?: string | null
) {
  const MONTH_LIST_SIZE = 13;
  const result: Month[] = [];
  const initialDate = moment().startOf('month');

  for (let i = 0; i < MONTH_LIST_SIZE; i++) {
    result.push(generateMonth(initialDate, dateForward, dateBack, direction));
    initialDate.add(1, 'month');
  }

  return result;
}

function generateMonth(
  month: Moment,
  dateForward?: string | null,
  dateBack?: string | null,
  direction?: string | null
): Month {
  const initialDate = month.clone().startOf('month').startOf('week');
  const finalDate = month.clone().endOf('month');
  const monthValue = month.clone().startOf('month').format('MMMM YYYY');

  const days: Day[] = [];

  while (initialDate.isSameOrBefore(finalDate)) {
    days.push({
      disabled: isDisabled(initialDate, dateForward, direction),
      selected: isSelected(initialDate, dateForward, dateBack),
      isBetween: isBetween(initialDate, dateForward, dateBack),
      isToday: initialDate.isSame(moment(), 'day'),
      isVisible: isSameMonth(initialDate, finalDate),
      value: initialDate.format('DD'),
      dateStr: initialDate.toISOString(),
    });
    initialDate.add(1, 'day');
  }
  return {
    value: monthValue,
    days,
  };
}

function isBetween(
  date: Moment,
  dateForward?: string | null,
  dateBack?: string | null
) {
  if (!dateForward || !dateBack) {
    return false;
  }
  return moment(date).isBetween(moment(dateForward), moment(dateBack));
}

function isSelected(
  date: Moment,
  dateForward?: string | null,
  dateBack?: string | null
) {
  if (!dateForward && !dateBack) {
    return false;
  }
  return date.isSame(moment(dateForward)) || date.isSame(moment(dateBack));
}

function isDisabled(
  initialDate: Moment,
  dateForward?: string | null,
  direction?: string | null
) {
  const today = moment().startOf('day');
  if (dateForward !== null) {
    return (
      initialDate.isBefore(today) ||
      (initialDate.isBefore(moment(dateForward)) && direction === 'back')
    );
  }
  return initialDate.isBefore(today);
}

function isSameMonth(date: Moment, month: Moment) {
  return date.isSame(month, 'month');
}
