import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { TableByTicketsProps, InsuranceUiData } from '../../../types';
import { Helper } from '@utils';

const Container = styled.div<{ isLastElement?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ isLastElement }) =>
    !isLastElement ? '1px solid #DCDCDC' : 'inherit'};
  margin-bottom: 25px;
`;

const RowText = styled(Text)`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  margin-bottom: 10px;
`;

const BlueSpan = styled(RowText)`
  color: #4872f2;
  margin-bottom: 0px;
  margin-left: 3px;
  font-weight: bold;
`;

const BoldSpan = styled(RowText)`
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 3px;
`;

const Taxes = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
  background: #edf1fe;
  border-radius: 6px;

  > :last-child {
    margin-bottom: 0px;
  }
`;

const TaxRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TaxLabel = styled(Text)`
  margin-bottom: 0px;
`;

const TaxCost = styled(RowText)`
  margin-bottom: 0px;
  color: #4872f2;
`;

const InsuranceTable: React.FC<TableByTicketsProps<InsuranceUiData>> = ({
  data,
  index,
  isLastElement,
}) => {
  const ticket = data?.item?.selectedTicket;
  const patronymic = ticket?.passenger?.patronymic
    ? ticket?.passenger?.patronymic
    : '';
  const name =
    ticket.passenger?.lastName +
    ' ' +
    ticket.passenger?.firstName +
    ' ' +
    patronymic;

  const totalTaxes = Helper.formatPrice(
    Number(data?.item?.supportTicketCalculationTax?.totalTaxes) +
      Number(data?.item?.supportTicketCalculationParameter?.totalParameters)
  );
  const refundAmount =
    data?.item?.supportTicketCalculationParameter?.refundAmount;

  return (
    <Container
      style={{ marginTop: !index ? 'inherit' : 25 }}
      isLastElement={isLastElement}
    >
      <RowText>
        Пассажир: <BoldSpan>{name}</BoldSpan>
      </RowText>
      <RowText>
        Название продукта: <BoldSpan>{ticket?.name}</BoldSpan>
      </RowText>
      <RowText>
        Стоимость продукта:{' '}
        <BoldSpan>
          {Helper.formatPrice(ticket?.basePrice + ticket?.agentExtraCharge)}
        </BoldSpan>
      </RowText>
      {!!ticket?.eTicketNumber && (
        <RowText>
          Номер билета: <BoldSpan>{ticket?.passenger?.eTicketNumber}</BoldSpan>
        </RowText>
      )}

      <RowText>
        Сумма удержаний за возврат: <BlueSpan>{`${totalTaxes}`}</BlueSpan>{' '}
      </RowText>
      {data?.rows && (
        <Taxes>
          {data.rows.map((x, i) => {
            return (
              !!x?.label && (
                <TaxRow key={i}>
                  <TaxLabel>{x.label}</TaxLabel>
                  <TaxCost>{Helper.formatPrice(x.cost)}</TaxCost>
                </TaxRow>
              )
            );
          })}
        </Taxes>
      )}
      <RowText>
        Сумма возврата по продукту: <BlueSpan>{`${refundAmount}  ₽`}</BlueSpan>{' '}
      </RowText>
    </Container>
  );
};

export default InsuranceTable;
