import React from 'react';
import { uniq as _uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import RailwayRouteDetailBlock from '@components/train/RouteDetail/RailwayRouteDetailBlock';
import { createOrderTrainFromOrderSegment } from '@modules/trainOrders/utils';
import { TrainOrderSegment } from '@modules/orders';

interface Props {
  orderId: string;
}

const OrderRouteDetailContainer: React.FC<Props> = (props) => {
  const railwayOrderPositions = useSelector(
    (state: ApplicationState) =>
      state.orders.orderDetail.data.railwayOrderPositions,
  );
  const orderPositions = railwayOrderPositions.map(
    (item) => item.orderPosition,
  );
  const railwayOrderPosition = orderPositions.find(
    (item) => item.internalOrderPositionId === props.orderId,
  );
  if (!railwayOrderPosition) return null;

  const departureCityCodes: string[] = _uniq(
    railwayOrderPosition.segments.map((segment) => segment.departureCityCode),
  );
  const hasBackwardRoute = departureCityCodes.length > 1;

  const forwardSegments = railwayOrderPosition.segments.filter(
    (segment) => segment.departureCityCode === departureCityCodes[0],
  );

  let trainBackward;
  let backwardSegments: TrainOrderSegment[] = [];

  const forwardChosenPlaces: string[] = [];
  const backwardChosenPlaces: string[] = [];

  if (forwardSegments.length > 0) {
    forwardSegments.forEach((segment) => {
      const forwardSegmentId = segment.segmentId;
      railwayOrderPosition.passengers.forEach((passenger) => {
        passenger.priceDetailsPerRoute.forEach((priceDetail) => {
          if (!!priceDetail.place && priceDetail.segmentId === forwardSegmentId) {
            forwardChosenPlaces.push(priceDetail.place);
          }
        });
      });
    });
  }

  const trainForward = createOrderTrainFromOrderSegment(forwardSegments[0]);

  if (hasBackwardRoute) {
    backwardSegments = railwayOrderPosition.segments.filter(
      (segment) => segment.departureCityCode === departureCityCodes[1],
    );
    backwardSegments.forEach((segment) => {
      const backwardSegmentId = segment.segmentId;
      railwayOrderPosition.passengers.forEach((passenger) => {
        passenger.priceDetailsPerRoute.forEach((priceDetail) => {
          if (!!priceDetail.place && priceDetail.segmentId === backwardSegmentId) {
            backwardChosenPlaces.push(priceDetail.place);
          }
        });
      });
    });

    trainBackward = createOrderTrainFromOrderSegment(backwardSegments[0]);
  }

  return (
    <RailwayRouteDetailBlock
      instance={'order'}
      trainForward={trainForward}
      trainBackward={trainBackward}
      forwardCarNumber={railwayOrderPosition.segments[0].train.carNumber}
      backwardCarNumber={railwayOrderPosition.segments[1]?.train.carNumber}
      forwardChosenPlaces={forwardChosenPlaces}
      backwardChosenPlaces={backwardChosenPlaces}
    />
  );
};
export default OrderRouteDetailContainer;
