import React from 'react';
import styled from 'styled-components';

interface Props {
  carPlacesNodes: React.ReactNode[];
}

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #f9f9f9;
  gap: 10px;
`;

export const SeatPanel: React.FC<Props> = (props: Props) => {
  return (
    <Panel>
      {props.carPlacesNodes.map((placeNode, index) => {
        return <React.Fragment key={index}>{placeNode}</React.Fragment>;
      })}
    </Panel>
  );
};
