import React from 'react';
import { useRefund } from '../../useRefund';
import AdditionalServiceCalculate from './AdditionalServiceCalculate/AdditionalServiceCalculate';
import RefundCalculate from './RefundCalculate/RefundCalculate';
import InsuranceCalculate from './InsuranceCalculate/InsuranceCalculate';
import { PositionAppealCode } from '@app/components/order/Detail/OrderPositions/Item/types';

const Calculate: React.FC = () => {
  const props = useRefund();
  const { subjectCode } = props;

  if (subjectCode === PositionAppealCode.REFUND_ADDITIONAL_SERVICE) {
    return <AdditionalServiceCalculate />;
  } else if (subjectCode === 'RefundInsurance') {
    return <InsuranceCalculate />;
  } else {
    return <RefundCalculate />;
  }
};

export default Calculate;
