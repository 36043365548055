import styled from 'styled-components';

export const TextContaner = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  color: ${({ theme: { colors } }) => colors.blackGray};
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const TimeText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
`;
