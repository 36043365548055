import React from 'react';
import DesktopTemplate from './Desktop';
import MobileTemplate from './Mobile';
import { useMediaQuery } from 'react-responsive';
import { CalendarProps } from './types';

const Calendar: React.FC<CalendarProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? (
    <MobileTemplate {...props} />
  ) : (
    <DesktopTemplate {...props} />
  );
};

export default Calendar;
