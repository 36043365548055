import { TrainSearchPayload } from '@modules/trainTickets/types';
import { AirSearchPayload } from '@modules/simpleSearch';
import { FlightClassTypes } from '@modules/main';
import moment from 'moment/moment';

export const trainSearchPayloadToAirSearchPayload = (
  payload: TrainSearchPayload
): AirSearchPayload => {
  const forwardDate = moment(payload.DepartureDate, 'YYYY-MM-DD').format(
    'DDMMYYYY'
  );
  const backwardDate = payload.DepartureDateBack
    ? moment(payload.DepartureDateBack, 'YYYY-MM-DD').format('DDMMYYYY')
    : null;
  return {
    origin: payload.From,
    destination: payload.To,
    forwardDate: forwardDate,
    backwardDate: backwardDate,
    adults: payload.Adults,
    children: payload.Children,
    infants: payload.Infants,
    flightClass: FlightClassTypes.Econom,
  };
};
