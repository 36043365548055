import React from 'react';
import { useRefund } from '../../../useRefund';
import { formatTableRows } from '../../../utils';
import AdditionalServiceTable from '../AdditionalServiceCalculate/AdditionalServiceTable';
import CalculateTemplate from '../CalculateTemplate';
import InsuranceTable from '../InsuranceCalculate/InsuranceTable';
import RefundTable from './RefundTable';

const RefundCalculate: React.FC = () => {
  const props = useRefund();

  const passengersData = React.useMemo(
    () =>
      props?.selectedTickets
        ? props?.selectedTickets
            .map((x) => {
              const calculation = props?.supportTicketCalculationItems
                ? props.supportTicketCalculationItems.find(
                    (y) => y?.aviaPassengerId === x.id
                  )
                : {};
              return { selectedTicket: x, ...calculation, type: props.type };
            })
            .filter((x: any) => x !== undefined)
        : [],
    [props.selectedTickets, props.supportTicketCalculationItems]
  );

  const insurances = React.useMemo(
    () =>
      props?.selectedInsurances
        ? props?.selectedInsurances
            .map((x) => {
              const calculation = props?.supportTicketCalculationItems
                ? props.supportTicketCalculationItems.find(
                    (y) => y?.insurancePolicyId === x?.id
                  )
                : {};

              return { selectedTicket: x, ...calculation, type: props.type };
            })
            .filter((x) => x !== undefined)
        : [],
    [props.selectedInsurances, props.supportTicketCalculationItems]
  );

  const services = React.useMemo(
    () =>
      props?.selectedAncillaryServices
        ? props?.selectedAncillaryServices
            .map((x) => {
              const calculation = props?.supportTicketCalculationItems
                ? props?.supportTicketCalculationItems.find(
                    (y) => y.ancillaryServiceId === x?.ancillaryServicetId
                  )
                : {};

              return { selectedTicket: x, ...calculation, type: props.type };
            })
            .filter((x) => x !== undefined)
        : [],
    [props.selectedAncillaryServices, props.supportTicketCalculationItems]
  );

  const insurancesRows = React.useMemo(
    () =>
      insurances?.length
        ? insurances.map((x) => ({ item: x, rows: formatTableRows(x) }))
        : [],
    [insurances]
  );
  const servicesRows = React.useMemo(
    () =>
      services?.length
        ? services.map((x) => ({ item: x, rows: formatTableRows(x) }))
        : [],
    [services]
  );
  const rows = React.useMemo(
    () =>
      passengersData.map((x) => ({
        rows: formatTableRows(x),
        item: x,
      })),
    [passengersData]
  );

  return (
    <CalculateTemplate>
      <>
        {!!rows?.length &&
          rows.map((x, i: number) => (
            <RefundTable
              isLastElement={
                i === rows.length - 1 &&
                (!insurancesRows?.length || !servicesRows.length)
              }
              data={x as any}
              key={i}
              index={i}
            />
          ))}
        {!!insurancesRows?.length &&
          insurancesRows.map((x, i: number) => (
            <InsuranceTable
              isLastElement={
                i === insurancesRows.length - 1 && !servicesRows?.length
              }
              data={x as any}
              key={i}
              index={i}
            />
          ))}
        {!!servicesRows?.length &&
          servicesRows.map((x, i: number) => (
            <AdditionalServiceTable
              isLastElement={i === servicesRows.length - 1}
              data={x as any}
              key={i}
              index={i}
            />
          ))}
      </>
    </CalculateTemplate>
  );
};

export default RefundCalculate;
