import { CONTENT_TYPE, createFetchUrl, handleStatuses } from '../../../utils/fetch';
import { Api } from '@utils';
import {
  AgentBalance,
  AgentFullInfo,
  AgentNotPaidOrder,
  AgentOrders,
  AgentOverPaymentOrder,
  CreditLimitHistory,
  GetAgentPaymentItem,
  GetAgentPaymentPayload,
  AquiringInfo,
  GeneratedAddRequestAgentRequest,
  GeneratedAddRequestAgentResponse,
  GeneratedTuiTransportEngineSharedAgentsClientContractsGetAgentDepositsResponse,
} from '@modules/agents/agent-finance/types';
import { ORDER_SERVICE_HOST } from '@modules/constants';
import { trackPromise } from 'react-promise-tracker';

const AGENTS_HOST = process.env.REACT_APP_AGENTS_HOST as string;
const AGENTS_API = process.env.REACT_APP_AGENTS_API as string;
const AGENTS_REQUEST_API = process.env.REACT_APP_AGENTS_REQUEST_API as string;
const ORDER_SERVICE_HOST3 = process.env.REACT_APP_ORDER_SERVICE_HOST3 as string;

const REACT_APP_AGENTS_INFO_SERVICE = process.env
  .REACT_APP_AGENTS_INFO_SERVICE as string;
const REACT_APP_AGENTS_REQUESTS = process.env
  .REACT_APP_AGENTS_REQUESTS as string;
const END_POINTS = {
  getAgentInfo: process.env.REACT_APP_AGENTS_GET as string,
  getAgentsBalance: process.env.REACT_APP_AGENTS_BALANCE as string,
  getAgentsNodePaidOrder: process.env
    .REACT_APP_AGENTS_NOT_PAID_ORDERS as string,
  getAgentsOverPaidOrder: process.env
    .REACT_APP_AGENTS_OVER_PAID_ORDERS as string,
  getAgentCreditHistory: process.env.REACT_APP_AGENTS_CREDIT_HISTORY as string,
  getAgentReport: process.env.REACT_APP_GET_AGENT_REPORT as string,
  getAgentPaymentHistory: process.env
    .REACT_APP_AGENTS_PAYMENT_HISTORY as string,
  getAgentBillBySamoNumber: process.env.REACT_APP_GET_BILL_BY_NUMBER as string,
  getAquiringCommission: process.env.REACT_APP_GET_AQUIRING as string,
  addAgentRequest: process.env.REACT_APP_ADD_AGENT_REQUEST as string,
  getAgentDeposit: process.env.REACT_APP_GET_DEPOSIT as string,
  getAgentDepositList: process.env.REACT_APP_GET_DEPOSIT_LIST as string,
  getAgentInfoRequests: process.env.REACT_APP_GET_AGENT_INFO as string,
};

const createAgentsUrl = createFetchUrl(AGENTS_HOST, AGENTS_API);
const createAgentRequestUrl = createFetchUrl(AGENTS_HOST, AGENTS_REQUEST_API);
const createAgentRequestsUrl = createFetchUrl(
  ORDER_SERVICE_HOST3,
  REACT_APP_AGENTS_INFO_SERVICE
);
const createAgentRequestsUrlTest = createFetchUrl(
  AGENTS_HOST,
  REACT_APP_AGENTS_REQUESTS
);

const createOrderFetchUrl = Api.createFetchUrl(
  ORDER_SERVICE_HOST,
  process.env.REACT_APP_ORDER_AGENT as string
);

export function getAgentInfo(agentId: string): Promise<AgentFullInfo> {
  return Api.request({
    url: createAgentsUrl(END_POINTS.getAgentInfo, {
      id: agentId,
    }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json);
}

export function getAgentsBalance(samoId: string): Promise<AgentBalance> {
  return Api.request({
    url: createAgentsUrl(END_POINTS.getAgentsBalance, {
      partnerId: samoId,
    }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json);
}

export function getAgentNotPaidOrders(
  samoId: string,
  sortField?: string,
  sortAsc?: string
): Promise<AgentNotPaidOrder[]> {
  return Api.request({
    url: createAgentsUrl(END_POINTS.getAgentsNodePaidOrder, {
      partnerId: samoId,
      sortField: sortField,
      sortAsc: sortAsc,
    }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data: AgentOrders<AgentNotPaidOrder>) => data.orders);
}

export function getAgentOverPaidOrders(
  samoId?: string,
  sortField?: string,
  sortAsc?: string
): Promise<AgentOverPaymentOrder[]> {
  return Api.request({
    url: createAgentsUrl(END_POINTS.getAgentsOverPaidOrder, {
      partnerId: samoId,
      sortField: sortField,
      sortAsc: sortAsc,
    }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data: AgentOrders<AgentOverPaymentOrder>) => data.orders);
}

export function getAgentCreditHistory(
  samoId: string,
  date: string,
  sortField?: string,
  sortAsc?: string
): Promise<CreditLimitHistory[]> {
  return Api.request({
    url: createAgentsUrl(END_POINTS.getAgentCreditHistory, {
      partnerId: samoId,
      beginDate: date,
      sortField: sortField,
      sortAsc: sortAsc,
    }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data: { items: CreditLimitHistory[] }) => data.items);
}

export function getAgentPaymentHistory(
  data: GetAgentPaymentPayload
): Promise<GetAgentPaymentItem[]> {
  return Api.request({
    url: createAgentsUrl(END_POINTS.getAgentPaymentHistory, {
      PartnerId: data.PartnerId,
      BeginDate: data.BeginDate,
      EndDate: data.EndDate,
    }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data: { payments: GetAgentPaymentItem[] }) => data.payments);
}

export function getAquiringCommission(AgentId?: string): Promise<AquiringInfo> {
  return Api.request({
    url:
      process.env.REACT_APP_MODE !== 'test'
        ? createAgentRequestUrl(END_POINTS.getAquiringCommission, {
            AgentId: AgentId,
          })
        : createAgentsUrl(END_POINTS.getAquiringCommission, {
            AgentId: AgentId,
          }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
}

export function addAgentRequest(
  data?: GeneratedAddRequestAgentRequest
): Promise<GeneratedAddRequestAgentResponse> {
  return Api.request({
    url: createAgentRequestUrl(END_POINTS.addAgentRequest),
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(handleStatuses())
    .then(Api.json)
    .then((data) => data);
}

export function getAgentReport(
  agentId: string,
  period: string,
  ReportType: string,
  token?: string | null
) {
  return Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.getAgentReport, {
        Period: period,
        AgentId: agentId,
        ReportType: ReportType,
      }),
      method: 'GET',
      headers: {
        'Content-Type': CONTENT_TYPE.JSON,
        Authorization: `Bearer ${token}`,
      },
    },
    'AUTH'
  )
    .then(handleStatuses())
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file/*';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
}

export const getAgentDeposit = (
  AgentId?: string,
  Summ?: string,
  token?: string | null
) =>
  trackPromise(
    Api.request(
      {
        url: createOrderFetchUrl(END_POINTS.getAgentDeposit, {
          Summ: Summ,
          AgentId: AgentId,
        }),
        method: 'GET',
        headers: {
          'Content-Type': CONTENT_TYPE.JSON,
          Authorization: `Bearer ${token}`,
        },
      },
      'AUTH'
    )
      .then(handleStatuses())
      .then((data) => {
        return data.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'file.pdf';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      })
  );

export const getDepositList = (
  AgentId?: string,
  From?: string,
  To?: string
): Promise<GeneratedTuiTransportEngineSharedAgentsClientContractsGetAgentDepositsResponse> =>
  trackPromise(
    Api.request({
      url: createOrderFetchUrl(END_POINTS.getAgentDepositList, {
        AgentId: AgentId,
        From: From,
        To: To,
      }),
      headers: {
        'Content-Type': Api.CONTENT_TYPE.JSON,
      },
    })
      .then(Api.statuses)
      .then(Api.json)
      .then((data) => data)
  );

export const getAgentInfoRequests = (PartnerId?: string): Promise<any> =>
  Api.request({
    url:
      process.env.REACT_APP_MODE !== 'test'
        ? createAgentRequestsUrl('/agent-fin-info', {
            PartnerId: PartnerId,
          })
        : createAgentRequestsUrlTest('/info', {
            PartnerId: PartnerId,
          }),
    headers: {
      'Content-Type': Api.CONTENT_TYPE.JSON,
    },
  })
    .then(Api.statuses)
    .then(Api.json)
    .then((data) => data);
