import { StationBus } from '@modules/busTickets';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const PlaceContainer = styled.div``;

const Description = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;

  & > strong {
    display: inline;
    font-weight: 600;
    color: ${({ theme: { colors } }) => colors.main};
  }
`;

interface Props {
  station: string;
  date: string;
}

const PlaceBus: React.FC<Props> = ({ station, date }) => {
  const dateString = moment(date).format('DD MMM YYYY, dd');
  return (
    <PlaceContainer>
      <Description>
        <strong>{station}</strong>
      </Description>
      <Description>{dateString}</Description>
    </PlaceContainer>
  );
};

export default PlaceBus;
