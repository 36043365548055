import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { Subject, PaymentStatus } from './types';

export const getAppealReferencesRequest = createAction(
  '@references/getAppealReferencesRequest'
);
export const getAppealSubjectsSuccess = createAction<Subject[]>(
  '@references/getAppealSubjectsSuccess'
);

export const getAppealStatusesSuccess = createAction<Subject[]>(
  '@references/getAppealStatusesSuccess'
);

export const getOrderReferencesRequest = createAction(
  '@references/getAppealStatusesRequest'
);
export const getPaymentStatusesSuccess = createAction<PaymentStatus[]>(
  '@references/getAppealStatusesSuccess'
);
const paymentStatuses = createReducer<PaymentStatus[] | []>({}, []);
paymentStatuses.on(getPaymentStatusesSuccess, (_, payload) => payload);

const subjects = createReducer<Subject[]>({}, []);
subjects.on(getAppealSubjectsSuccess, (_, payload) => payload);

const statuses = createReducer<Subject[]>({}, []);
statuses.on(getAppealStatusesSuccess, (_, payload) => payload);

const appeals = combineReducers({
  subjects,
  statuses,
});

const order = combineReducers({
  paymentStatuses,
});

export default combineReducers({
  appeals,
  order,
});
