import React from 'react';
import Notification from '@components/flightsSearch/Notifications/Notification';

const OfflinePanel = () => {
  return (
    <Notification
      subTitle={
        'Пожалуйста, проверьте ваше интернет-соединение или попробуйте снова через некоторое время.'
      }
      title={'Потеряно соединение с сервером'}
    >
      <Notification.Ul></Notification.Ul>
    </Notification>
  );
};

export default OfflinePanel;
