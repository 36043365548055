import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { LogHistoryProps, LogHistoryItem } from './types';
// import PRINT_FAILURE from '../../assets/images/history-log/print-failure.svg';
import PRINT from '../../assets/images/history-log/print.svg';
import REFUND from '../../assets/images/history-log/refund.svg';
import IMPORT from '../../assets/images/history-log/import.svg';
import PAYMENT from '../../assets/images/history-log/payment.svg';
import ANCILLARY from '../../assets/images/history-log/ancillaryServiceAdded.svg';
import EXCHANGE from '../../assets/images/history-log/exchange.svg';
import REWRITE from '../../assets/images/history-log/rewrite.svg';
// import VOID from '../../assets/images/history-log/void.svg';
import STATUS from '../../assets/images/history-log/status.svg';
import NEW_APPEAL from '../../assets/images/history-log/appeal/new.svg';
// import IN_WORK_APPEAL from '../../assets/images/history-log/appeal/in-work.svg';
// import PAID_APPEAL from '../../assets/images/history-log/appeal/paid.svg';
// import RESPONSE_APPEAL from '../../assets/images/history-log/appeal/response.svg';
// import WAIT_RESPONSE_APPEAL from '../../assets/images/history-log/appeal/waiting-response.svg';

import moment from 'moment';
import { PositionAppealCode } from '../order/Detail/OrderPositions/Item/types';

const Wrapper = styled.div``;

const Item = styled.div`
  border-bottom: 1px solid #dcdcdc;
  padding: 14px 0;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // background-color: gray;
  margin-right: 15px;
`;

const Date = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-right: 14px;
`;

const Description = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
`;

const MobileContainer = styled.div`
  margin-top: -4px;
  display: flex;
  @media (max-width: 1024px) {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
`;

const ImageSkeleton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #b9b9b9;
  margin-right: 15px;
`;

const DateSkeleton = styled.div`
  width: 112.5px;
  height: 18px;
  border-radius: 4px;
  background-color: #b9b9b9;
  margin-right: 14px;
  @media (max-width: 1024px) {
    margin-bottom: 3px;
  }
`;

const DescriptionSkeleton = styled.div`
  width: 300px;
  height: 18px;
  border-radius: 4px;
  background-color: #b9b9b9;
`;

const LogHistory: React.FC<LogHistoryProps> = ({ items, ...props }) => {
  return (
    <Wrapper {...props}>
      {items.length === 0
        ? Array(3)
            .fill('')
            .map((_, key) => (
              <Item key={key}>
                <ImageSkeleton />
                <MobileContainer>
                  <DateSkeleton />
                  <DescriptionSkeleton />
                </MobileContainer>
              </Item>
            ))
        : items.map((item, key) => (
            <Item key={key}>
              <Icon src={getIconPathByAction(item)} />
              <MobileContainer>
                <Date>{moment(item.dateTime).format('DD-MM-YYYY HH:mm')}</Date>
                <Description>{item.description2}</Description>
              </MobileContainer>
            </Item>
          ))}
    </Wrapper>
  );
};

function getIconPathByAction(data: LogHistoryItem) {
  switch (data.actionType) {
    case 'ChangeOrderPaymentStatus':
      return PAYMENT;
    case 'ChangeOrderPositionStatus':
      return STATUS;
    case 'OrderPositionPaymentStatusChanged':
      return PAYMENT;
    case 'PartiallyExchange':
    case PositionAppealCode.FUll_EXCHANGE:
      return EXCHANGE;
    case PositionAppealCode.FULL_REFUND:
    case 'PartiallyRefund':
    case 'RefundAncillaryService':
      return REFUND;
    case 'AddAncillaryService':
      return ANCILLARY;
    case 'ImportOrderPostion':
      return IMPORT;
    case 'RewritePnr':
      return REWRITE;
    case 'OrderPositionStatusChanged':
      return PRINT;
    case 'SupportTicketCreated':
      return NEW_APPEAL;

    default:
      return STATUS;
  }
}

export default LogHistory;
