import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 10 6" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M4.43526 5.355L0.838258 1.2435C0.413758 0.75975 0.758758 2.75779e-07 1.40301 2.75779e-07H8.59701C8.74119 -0.000123345 8.88236 0.0413159 9.00359 0.119356C9.12483 0.197396 9.221 0.308728 9.2806 0.44002C9.34019 0.571312 9.36067 0.716999 9.3396 0.859633C9.31852 1.00227 9.25678 1.1358 9.16176 1.24425L5.56476 5.35425C5.49436 5.43481 5.40755 5.49937 5.31014 5.54361C5.21273 5.58785 5.10699 5.61073 5.00001 5.61073C4.89303 5.61073 4.78728 5.58785 4.68988 5.54361C4.59247 5.49937 4.50565 5.43481 4.43526 5.35425V5.355Z" />
    </svg>
  );
};

export default Logo;
