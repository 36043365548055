import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ReactComponent as PersonIcon } from '@assets/media/shared/person.svg';
import { ReactComponent as PassengersIcon } from '@assets/media/header/passengers.svg';
import { ReactComponent as MessageIcon } from '@assets/media/header/message.svg';
import { ReactComponent as SuitcaseIcon } from '@assets/media/header/suitcase.svg';
import { ReactComponent as CartIcon } from '@assets/media/header/cart.svg';

import Door from '../../assets/images/head/door';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import AgentBalanceBlock from '@components/agents/agent-finance/AgentBalanceBlock';
import { ApplicationState } from '@modules/index';

const StyledNavLink = styled(NavLink)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 46px;
  padding-left: 5px;

  :hover {
    background: #f7f7f7;
  }

  & > svg {
    margin-right: 10px;
    flex-shrink: 0;

    & > path {
      stroke: #737373;
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.main};

    & > svg > rect {
      fill: ${({ theme }) => '#E0E7FF'};
    }
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 6px;
  position: absolute;
  z-index: 1001;
  padding: 20px;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.25);
  top: 60px;

  & > :last-child {
    margin-bottom: 0;
  }
`;

const VersionContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
  color: #737373;
`;

interface Props {
  setIsMenuShown: Dispatch<SetStateAction<boolean>>;
}

const HoverMenu: React.FC<Props> = ({ setIsMenuShown }) => {
  const user = useSelector((x: ApplicationState) => x.user);

  const isMobileSize = useMediaQuery({ maxWidth: 767 });

  // React.useEffect(() => {
  //   dispatch(getAgentMainInformation());
  // }, [location.pathname]);

  return (
    <Wrapper
      onMouseLeave={() => setIsMenuShown(false)}
      onMouseOver={() => setIsMenuShown(true)}
    >
      <StyledNavLink to="/profile/contacts">
        <PersonIcon height={28} />
        Мои учетные данные
      </StyledNavLink>
      {user?.data?.role !== 'PluginManager' && (
        <StyledNavLink to="/profile/passengers">
          <PassengersIcon height={28} />
          Мои пассажиры
        </StyledNavLink>
      )}

      <StyledNavLink to="/orders">
        <CartIcon height={28} />
        Мои заказы
      </StyledNavLink>
      <StyledNavLink to="/tickets">
        <MessageIcon height={28} />
        {user?.data?.role !== 'PluginManager' ? 'Обращения' : 'Helpdesk'}
      </StyledNavLink>
      {user?.data?.agentId &&
        user?.data?.agentGroup !== 'Собственная розница' && (
          <StyledNavLink
            style={{ whiteSpace: 'break-spaces', marginBottom: 10 }}
            to="/profile/agent"
          >
            <SuitcaseIcon height={28} />
            Финансы и отчеты
          </StyledNavLink>
        )}
      {user?.data?.agentId && user?.data?.role !== 'PluginManager' && (
        <AgentBalanceBlock />
      )}
      {isMobileSize && (
        <StyledNavLink to="/sign-in">
          <Door fill="#737373" height={28} />
          Выйти
        </StyledNavLink>
      )}
      <VersionContainer>v{packageJson.version}</VersionContainer>
    </Wrapper>
  );
};

export default HoverMenu;
