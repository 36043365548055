import { AviaPassengerEntity, DocumentTypes } from '@modules/user';
import * as yup from 'yup';

export const FORM_DEFAULT_INITIAL_VALUE: AviaPassengerEntity = {
  customerPassengerId: '',
  customerId: '',
  firstName: '',
  lastName: '',
  patronymic: '',
  birthDate: '',
  citizen: null,
  gender: 'Male',
  personType: 'Adult',
  correlationId: '',
  secondNameNotRequired: false,
  documents: [
    {
      type: DocumentTypes.RussianFederationPassport,
      number: '',
      citizenship: 'RU',
      citizenshipLabel: 'Россия',
      id: '',
    },
  ],
};

export const RUSSIAN_PASSPORT = {
  label: 'Паспорт РФ',
  value: DocumentTypes.RussianFederationPassport,
};
export const INTERNATIONAL_PASSPORT = {
  label: 'Загран. паспорт',
  value: DocumentTypes.InternationalPassport,
};

export const BIRTH_CERTIFICATE = {
  label: 'Свидетельство о рождении',
  value: DocumentTypes.BirthCertificate,
};
export const NATIONAL_PASSPORT = {
  label: 'Нац. паспорт',
  value: DocumentTypes.NationalPassport,
};

export const INTERNATIONAL_PASSPORT1 = {
  label: 'Другой документ',
  value: DocumentTypes.Other,
};

export const DOCUMENT_TYPE_SELECT_LIST: {
  value: DocumentTypes;
  label: string;
}[] = [
  RUSSIAN_PASSPORT,
  INTERNATIONAL_PASSPORT,
  BIRTH_CERTIFICATE,
  NATIONAL_PASSPORT,
  INTERNATIONAL_PASSPORT1,
];

export const SELECT_STYLES = {
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    visibility: 'hidden',
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Open Sans',
    color: '#3C3C3C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    paddingLeft: '12px',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided: any, { isFocused, ...state }: any) => {
    const border = isFocused
      ? '1px solid #3C3C3C !important'
      : '1px solid #DCDCDC';

    const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
    const boxShadow = 'none';
    return {
      ...provided,
      minHeight: 42,
      border,
      borderColor,
      boxShadow,
    };
  },
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: 100,
  }),
};

export const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required('Заполните'),
  lastName: yup.string().required('Заполните'),
  patronymic: yup.string().when('secondNameRequired', {
    is: (value: boolean) => value,
    then: (schema) => schema.required('Заполните').nullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  birthDate: yup.date().required('Заполните').nullable(),
  documents: yup.array().of(
    yup.object().shape({
      number: yup.string().when('type', {
        is: (value: string) => !!(value === DocumentTypes.RussianFederationPassport),
        then: (schema) => schema.trim()
          .required('Заполните'),
        otherwise: (schema) => schema.when('type', {
          is: (value: string) => !!(value === DocumentTypes.BirthCertificate),
          then: (schema) => schema.trim()
            .required('Заполните'),
          otherwise: (schema) => schema.required('Заполните'),
        }),
      }),
      issueDate: yup.date().when('type', {
        is: (value: string) =>
          !!(
            value !== DocumentTypes.RussianFederationPassport &&
            value !== DocumentTypes.BirthCertificate
          ),
        then: (schema) => schema.required('Заполните').nullable(),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
    })
  ),
});
