import { simpleSearchUpdateState } from '@modules/simpleSearch';
import { trainSearchPayloadToAirSearchPayload } from '@modules/trainTickets';
import { Action } from 'redux-act';
import { all, call, put, select, spawn, takeLatest } from 'typed-redux-saga';
import {
  setSearchStep,
  setBusSearchRequestState,
  busSearchRequest,
  busSearchSuccess,
  setBusFilter,
  busSearchSuccessFilter,
  setPricesBusFilter,
  setDurationBusFilter,
  setCarrierBusFilter,
  setTimeBusFilter,
  setBusStep,
  setBusForward,
  setStationBusFilter,
  getBusRoute,
  setBusRoute,
} from './duck';
import {
  BusSearchRequestStatus,
  Station,
  BusFilter,
  GetBusesTicketsListDto,
  GetBusRoutePayload,
} from './types';
import * as Manager from './manager';
import * as _ from 'lodash';
import { ApplicationState } from '..';
import {
  busCurrentSearchStepSelector,
  busTicketsListSelector,
  filteredBusTicketsListSelector,
} from './selectors';
import { BUS_SEARCH_STEPS } from './constants';
import { TrainSearchPayload } from '@modules/trainTickets/types';

function* busSearchSaga(params: Action<TrainSearchPayload>) {
  yield* put(setSearchStep('TICKET_FORWARD'));
  // yield* put(clearBusTicket());
  // yield* call(purifyAllBusData);
  yield* put(
    setBusSearchRequestState({ status: BusSearchRequestStatus.initial }),
  );
  try {
    yield* spawn(
      simpleSearchUpdateState,
      trainSearchPayloadToAirSearchPayload(params.payload),
    );
    const response = yield* call(Manager.getBusTicketsList, params.payload);
    yield* put(busSearchSuccess(response));
    const filter = Manager.createBusFilter(response);
    yield* put(setBusFilter(filter));
    yield* all([
      yield* put(busSearchSuccess(_.cloneDeep(response))),
      yield* put(busSearchSuccessFilter(_.cloneDeep(response))),
    ]);

    yield* put(
      setBusSearchRequestState({ status: BusSearchRequestStatus.success }),
    );
  } catch (e) {
    console.log(e);
    // yield* put(trainSearchFailure());
    yield* put(
      setBusSearchRequestState({ status: BusSearchRequestStatus.failure }),
    );
  }
}

function* runBusFilterSaga() {
  let filter: BusFilter;
  const currentStep = yield* select(busCurrentSearchStepSelector);
  const list = _.cloneDeep(yield* select(busTicketsListSelector));
  const state = yield* select(filteredBusTicketsListSelector);

  if (currentStep === 'TICKET_FORWARD') {
    filter = yield* select(
      (state: ApplicationState) => state.busTickets.busSearch.filter[0],
    );
  } else {
    filter = yield* select(
      (state: ApplicationState) => state.busTickets.busSearch.filter[1],
    );
  }
  const updatedState = busFilterWorker(state, list, filter);

  yield* put(busSearchSuccessFilter(updatedState));
}

function busFilterWorker(
  state: GetBusesTicketsListDto,
  list: GetBusesTicketsListDto,
  filter: BusFilter,
): GetBusesTicketsListDto {
  const {
    values,

    carriersTickets,
  } = filter;

  const isForward = filter.filterDestinationType === 'forward';

  const stationConcat = values.stations.reduce(
    (
      acc: any,
      x: {
        label: string;
        stations: Station[];
        tickets: string[];
      },
    ) => acc.concat(x.stations),
    [],
  );

  let keys = filter.busDurationsList[0].tickets
    .map((_, key) => key)
    .filter((key) => {
      const pricesFilter =
        (filter.prices.tickets[key][0] >= values.prices[0] ||
          filter.prices.tickets[key][1] >= values.prices[0]) &&
        (filter.prices.tickets[key][0] <= values.prices[1] ||
          filter.prices.tickets[key][1] <= values.prices[1]);

      const carriersFilter = Object.values(values.carriers).find((x) => x)
        ? carriersTickets[key].some((val) => values.carriers[val])
        : true;

      const firstExpression = pricesFilter && carriersFilter;

      if (firstExpression) {
        let secondExpression = true;

        // times
        filter.times.forEach((group) => {
          const { from, to } = group;
          secondExpression =
            secondExpression &&
            from.values[0] <= from.tickets[key] &&
            from.values[1] >= from.tickets[key] &&
            to.values[0] <= to.tickets[key] &&
            to.values[1] >= to.tickets[key];
        });

        return firstExpression && secondExpression;
      } else {
        return false;
      }
    });

  filter.busDurationsList.forEach((bus, key) => {
    const values = filter.values.busDurations;

    keys = bus.tickets
      .map((val, key) => {
        return { val, key };
      })
      .filter((item, key) => {
        return (
          keys.find((k) => k === key) !== undefined &&
          item.val >= values[0] &&
          item.val <= values[1]
        );
      })

      //@ts-ignore
      .map(({ key }) => key);
  });

  let ObjKeys: { val: string; key: number }[] = [];

  stationConcat?.filter(Boolean).map((station: Station, key: number) => {
    if (station.checked === true) {
      ObjKeys = filter.stations[0].tickets
        .map((val, key) => {
          return { val, key };
        })
        .filter((x, key) => {
          return (
            keys.find((k) => k === key) !== undefined &&
            station?.raceIds?.includes(x.val)
          );
        });
    }
  });

  if (ObjKeys.length !== 0) {
    keys = ObjKeys.map((key) => key.key);
  } else keys;

  const filteredState = [...list.busesPerRoutes];

  if (isForward) {
    filteredState[0].buses = filteredState[0].buses.filter(
      (_, key) => keys.find((k) => k === key) !== undefined,
    );
  } else {
    filteredState[1].buses = filteredState[1].buses.filter(
      (_, key) => keys.find((k) => k === key) !== undefined,
    );
  }

  return {
    ...state,
    busesPerRoutes: filteredState,
  };
}

export function* busSearchSetStepSaga(
  action: Action<{
    step: keyof typeof BUS_SEARCH_STEPS;
    needClear: boolean;
  }>,
) {
  try {
    window.scrollTo({
      top: 0,
    });
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
    yield* put(setSearchStep(action.payload.step));
    // yield* put(
    // setTrainSearchRequestState({ status: TrainSearchRequestStatus.loading })
    // );
    yield delay(1500);

    if (action.payload.needClear) {
      if (action.payload.step === BUS_SEARCH_STEPS.TICKET_FORWARD) {
        yield* call(purifyForwardBusData);
      } else if (action.payload.step === BUS_SEARCH_STEPS.TICKET_BACKWARD) {
        yield* call(purifyForwardBusData);
      }
    }

    // yield* put(
    // setTrainSearchRequestState({ status: TrainSearchRequestStatus.success })
    // );
  } catch (e) {
    console.log(e);
  }
}

// function* purifyAllBusData() {
// try {
// yield* call(purifyBackwardBusData);
// yield* call(purifyForwardBusData);
// } catch (e) {
// // console.log(e);
// }
// }

// function* purifyBackwardBusData() {
// try {
// yield* put(setBusBackward(null as any));
// yield* put(getBackCarsSuccess(null as any));
// yield* put(setBackCarInfo(null as any));
// yield* put(clearChosenCarPlaceBackward());
// } catch (e) {
// // console.log(e);
// }
// }

function* purifyForwardBusData() {
  try {
    yield* put(setBusForward(null as any));
  } catch (e) {
    // console.log(e);
  }
}

export function* handleGetBusRouteDetails({
  payload,
}: Action<GetBusRoutePayload>) {
  try {
    const routeDetails = yield* call(Manager.getBusRouteDetails, payload);

    yield* put(setBusRoute(routeDetails));
  } catch (e) {
    console.log(e);
  }
}

export default function* busTicketsFlow() {
  yield* all([
    takeLatest(busSearchRequest.getType(), busSearchSaga),
    takeLatest(
      [
        setPricesBusFilter,
        setDurationBusFilter,
        setCarrierBusFilter,
        setTimeBusFilter,
        setStationBusFilter,
      ],
      runBusFilterSaga,
    ),
    takeLatest(setBusStep.getType(), busSearchSetStepSaga),
    takeLatest(getBusRoute.getType(), handleGetBusRouteDetails),
  ]);
}
