import Cookies from 'js-cookie';
import moment, { Moment } from 'moment';

class UserCookiesEntity {
  private _expires: Moment | null;
  private _token: string | null;
  private _refreshToken: string | null;

  readonly WL_EXPIRATION = 'wl_expiration';
  readonly WL_TOKEN = 'wl_token';
  readonly WL_REFRESH_TOKEN = 'wl_refresh_token';

  constructor() {
    const token = Cookies.get(this.WL_TOKEN);
    const expires = Cookies.get(this.WL_EXPIRATION);
    const refreshToken = Cookies.get(this.WL_REFRESH_TOKEN);
    this._token = token || null;
    this._expires = expires ? moment.unix(parseInt(expires) | 0) : null;
    this._refreshToken = refreshToken || null;
  }

  clear() {
    Cookies.remove(this.WL_TOKEN);
    Cookies.remove(this.WL_EXPIRATION);
    Cookies.remove(this.WL_REFRESH_TOKEN);
    this._token = null;
    this._expires = null;
    this._refreshToken = null;
  }

  private _saveCookies(
    token: string,
    refreshToken: string,
    expires: number,
    remember?: boolean
  ) {
    Cookies.set(this.WL_TOKEN, token, {
      expires: remember ? 30 : undefined,
    });
    Cookies.set(this.WL_EXPIRATION, expires.toString(), {
      expires: remember ? 30 : undefined,
    });
    Cookies.set(this.WL_REFRESH_TOKEN, refreshToken, {
      expires: remember ? 30 : undefined,
    });
  }

  private _updateProps(
    token: string,
    refreshToken: string,
    expires: Moment,
    remember?: boolean
  ) {
    this._token = token || null;
    this._expires = expires.subtract(10, 'minutes');
    this._refreshToken = refreshToken || null;
  }

  save(
    token: string,
    refreshToken: string,
    expires: number,
    remember?: boolean
  ) {
    const expiresMoment = moment.unix(expires || 0);
    this._saveCookies(token, refreshToken, expires, remember);
    this._updateProps(token, refreshToken, expiresMoment, remember);
  }

  async refresh() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ORDER_SERVICE_HOST}${process.env.REACT_APP_USER_API}${process.env.REACT_APP_SIGN_IN}`,
        {
          method: 'POST',
          body: JSON.stringify({
            refreshToken: this._refreshToken,
            grant_Type: 'refresh_token',
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this._token}`,
          },
        }
      );
      const data = await response.json();
      this.save(data.accessToken, data.refreshToken, data.expresIn, true);
      return true;
    } catch (e) {
      this.clear();
      return false;
    }
  }

  get token() {
    return this._token;
  }

  get expires() {
    return this._expires;
  }

  get refreshToken() {
    return this._refreshToken;
  }
}

export const UserCookie = new UserCookiesEntity();
