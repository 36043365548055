import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { NavLink } from 'react-router-dom';
import linkArrowPath from '@assets/media/questions/link-arrow.svg'

export const GrayText = styled(Text)`
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #7E8389;
    z-index: 2;
`;
export const Title = styled(Text)`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2E2E32; 
    z-index: 2;
    position: relative;

    @media(max-width: 1169px) {
        font-size: 20px;
        line-height: 26px;
    }

    @media(max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
`;
export const Paragraph = styled(Text)`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #2E2E32;
    margin-top: 0;
    margin-bottom: 20px;
    display: block;

    & strong {
        font-weight: 600;
    }

    & > a {
      color: #4872f2;
      text-decoration: none;
      transition: color .1s ease-in-out;

      &:hover {
        color: #9e9e9e;
      }
    }

    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const Notification = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  padding: 14px;
  background: #FFFAEC;
  border-radius: 12px;

  & > strong {
    font-weight: 600;
  }

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Ul = styled.ul`
  padding-left: 20px;
  & > li {
    margin-bottom: 40px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media(max-width: 767px) {
    & > li {
      margin-bottom: 30px;
    }
  }
  
`;


export const LiTitle = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  padding: 0;
  margin-top: 0;
  margin-bottom: 15px;

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;
export const LiParagraph = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 15px;

  & > strong {
    font-weight: 600;
  }

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Li = styled.li<{ fontWeight?: string }>`
  font-size: 16px;
  line-height: 22px;
  color: #3c3c3c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || 400};

  ${Notification} {
    width: calc(100% + 14px);
    box-sizing: border-box;
    transform: translateX(-14px);
    margin-bottom: 15px;
  }

  &::marker,
  > strong {
    font-weight: 600;
  }

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

`;


export const NavLinkWithArrow = styled(NavLink)`
  position: relative;
  &:after {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 5px);
    background: url(${linkArrowPath});
  }

  &:hover {
    color: #9e9e9e;
  }
`;
