import {
  AddTicketRequestBody,
  GetCustomerTicketsParams,
  GetCustomerTicketsDTO,
  GetDetailTicketDTO,
  AddAppealDTO,
  AdditionalPaymentDTO,
  SupportTicketCalculationDto,
  AppealHistoryDto,
  GetCalculationByNumberDto,
  GetRefundAmountDTO,
} from './types';
import { getNormalizeMessages } from './utils';
import { Api } from '@utils';
import { ORDER_SERVICE_HOST } from '@modules/constants';
import { trackPromise } from 'react-promise-tracker';
import { CreateRefundPayload, RefundAmountPayload } from '@modules/orders';

const { CONTENT_TYPE, STRATEGY_TYPES, createFetchUrl } = Api;

const TICKET_URI = process.env.REACT_APP_APPEAL_API as string;
const MESSAGE_URI = process.env.REACT_APP_MESSAGE_API as string;
const ORDER_URI = process.env.REACT_APP_ORDER_API as string;

const END_POINTS = {
  addMessage: process.env.REACT_APP_ADD_MESSAGE_BY_CUSTOMER as string,
  setRead: process.env.REACT_APP_SET_READ_MESSAGE as string,
  addTicket: process.env.REACT_APP_ADD_NEW_APPEAL as string,
  getTicket: process.env.REACT_APP_GET_APPEAL_BY_NUMBER as string,
  getCustomerTickets: process.env.REACT_APP_GET_CUSTOMER_APPEALS as string,
  getTickets: process.env.REACT_APP_MESSAGE_API as string,
  changeStatus: '/change-status',
  getRefundAmount: '/refund-amount',
  createRefund: '/refund',
  applySupportTicketCalculation: process.env
    .REACT_APP_CONFIRM_CALCULATION_ON_APPEAL as string,
  declineSupportTicketCalculation: process.env
    .REACT_APP_REJECT_CALCULATION_ON_APPEAL as string,
  getAppealSubjects: '/support-ticket-subjects',
  getAppealStatuses: '/support-ticket-statuses',
};

const createTicketFetchUrl = createFetchUrl(ORDER_SERVICE_HOST, TICKET_URI);
const createMessageFetchUrl = createFetchUrl(ORDER_SERVICE_HOST, MESSAGE_URI);
const createOrderFetchUrl = Api.createFetchUrl(ORDER_SERVICE_HOST, ORDER_URI);

/**
 * Create new appeal
 * @appeals/create action
 * @param data
 */
export const addTicket = (
  args: AddTicketRequestBody,
): Promise<AddAppealDTO> => {
  const formData = new FormData();
  Object.entries(args).map(([key, item]) => {
    if (key === 'files') {
      item.forEach((x: any) => {
        formData.append('Files', x);
      });
    } else if (key === 'insuranceIds') {
      if (item.length > 0) {
        formData.append(key, JSON.stringify(item));
      }
    } else if (typeof item === 'object') {
      formData.append(key, JSON.stringify(item));
    } else {
      formData.append(key, item);
    }
  });
  const url = `${process.env.REACT_APP_NEW_ORDER_SERVICE_HOST}/ticket${END_POINTS.addTicket}`;

  return Api.request(
    {
      url: url,

      method: 'POST',
      body: formData,
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));
};

/**
 * Get list of customer tickets
 * action: @appeals/getList
 * saga: runCreateAppeal
 * @param data
 */
export const getCustomerTickets = (
  data: GetCustomerTicketsParams,
): Promise<GetCustomerTicketsDTO> =>
  Api.request(
    {
      url: createTicketFetchUrl(END_POINTS.getCustomerTickets, {
        Page: data.page,
        PageSize: data.pageSize,
        'Filter.Subject': data.subjects,
        'Filter.Status': data.statuses,
        SortByCreateDate: data.dateSorting,
        'Search.SupportTicketNumber': data.number,
      }),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));

/**
 * Get ticket by number
 * action: @appeals/getDetail
 * saga: runDetailLoad
 * @param number
 */
export const getTicketByNumber = (ticketNumber: number) =>
  Api.request(
    {
      url: createTicketFetchUrl(END_POINTS.getTicket, {
        ticketNumber,
      }),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response) as Promise<GetDetailTicketDTO>)
    .then((result) => ({
      ...result,
      messages: getNormalizeMessages(result.messages),
    }));

/**
 * Apply refund proposal
 * @param calculationId calculation id
 */
export const applySupportTicketCalculation = (calculationId: string) =>
  trackPromise(
    Api.request(
      {
        url: createTicketFetchUrl(END_POINTS.applySupportTicketCalculation),
        method: 'POST',
        body: JSON.stringify({ calculationId }),
        headers: { 'Content-Type': CONTENT_TYPE.JSON },
      },
      STRATEGY_TYPES.AUTH,
    )
      .then(Api.statuses)
      .then((response) => Api.text(response)),
  );

/**
 * Cancel refund proposal
 * @param calculationId calculation id
 */
export const declineSupportTicketCalculation = (calculationId: string) =>
  Api.request(
    {
      url: createTicketFetchUrl(END_POINTS.declineSupportTicketCalculation),
      method: 'POST',
      body: JSON.stringify({ calculationId }),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.text(response));

/**
 * Surcharge
 * @param calculationId calculation id
 */
export const surcharge = (
  orderId: string,
  supportTicketCalculationId: string,
) =>
  Api.request(
    {
      url: createOrderFetchUrl(
        process.env.REACT_APP_SET_ADDITIONAL_PAYMENT as string,
      ),
      method: 'POST',
      body: JSON.stringify({ orderId, supportTicketCalculationId }),
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response) as Promise<AdditionalPaymentDTO>);

/**
 * Send new user message
 * action: @appeals/sendMessage
 * saga: runMessageSend
 */
export const sendMessage = async (
  text: string,
  ticketId: string,
  files: File[],
) => {
  const formData = new FormData();
  files.forEach((x) => {
    formData.append('Files', x);
  });
  formData.append('Text', text);
  formData.append('TicketId', ticketId);

  return Api.request(
    {
      url: `${process.env.REACT_APP_NEW_ORDER_SERVICE_HOST}/Message/customer/add-message`,
      method: 'POST',
      body: formData,
      // headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));
};
/**
 * Set user message as read
 * action: @appeals/getDetail
 * saga: runDetailLoad
 */
export const setRead = (messageId: string) => {
  Api.request(
    {
      url: createMessageFetchUrl(END_POINTS.setRead, {
        MessageId: messageId,
      }),
      method: 'PUT',
    },
    STRATEGY_TYPES.AUTH,
  );
};

/**
 * Set user message as read
 * action: @appeals/getDetail
 * saga: runDetailLoad
 */
export const getFile = (fieldId: string, name?: string) => {
  Api.request(
    {
      url: `${process.env.REACT_APP_NEW_ORDER_SERVICE_HOST}/Ticket/get-file?fileId=${fieldId}`,
    },
    STRATEGY_TYPES.AUTH,
  )
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = name ? name : 'file/*';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    })
    .catch((ex) => {
      throw ex;
    });
};

export const getCalculationByNumber = (
  id: string,
): Promise<GetCalculationByNumberDto> =>
  Api.request(
    {
      url: createTicketFetchUrl(
        process.env.REACT_APP_ADD_GET_APPEAL_CALCULATION as string,
        { id },
      ),
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));

export const setCalculationRead = (
  supportTicketCalculationId: string,
): Promise<SupportTicketCalculationDto> =>
  Api.request(
    {
      url: process.env.REACT_APP_SET_CALCULATION_READ as string,
      method: 'PUT',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify({ supportTicketCalculationId }),
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then((response) => Api.json(response));

export const getHistory = (id: string): Promise<AppealHistoryDto> => {
  return Api.request(
    {
      url: `${process.env.REACT_APP_GET_APPEAL_HISTORY}?actionSourceId=${id}&actionSource=SupportTicketProcessing`,
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.json);
};

export const cancelAppeal = (id: string): Promise<AppealHistoryDto> => {
  return Api.request(
    {
      url: process.env.REACT_APP_CANCEL_APPEAL as string,
      method: 'PUT',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify({
        supportTicketId: id,
      }),
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.json);
};

export const getRefundAmountRequest = (
  payload: RefundAmountPayload,
): Promise<GetRefundAmountDTO> => {
  return Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.getRefundAmount),
      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(payload),
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.json);
};

export const createRefundRequest = (
  payload: CreateRefundPayload,
): Promise<GetRefundAmountDTO> => {
  return Api.request(
    {
      url: createOrderFetchUrl(END_POINTS.createRefund),
      method: 'POST',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
      body: JSON.stringify(payload),
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.json);
};
