import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Text } from '@components/ui';
import SmsService from '@components/order/Detail/AddictionalServices/SmsService';
import RefundService from '@components/order/Detail/AddictionalServices/RefundService';
import PrintService from '@components/order/Detail/AddictionalServices/PrintService';
import {
  useModalState,
  Provider,
} from '@components/order/Detail/AddictionalServices/context';
import {
  ServiceRulesModal as Modal,
  SmsServiceModal,
} from '@components/common/Modals';
import {
  AddictionalServicesBookingProps,
  Enums,
  ItemProps,
} from '@components/order/Detail/AddictionalServices/types';
import { useSelector } from 'react-redux';
import {
  ancillaryServicesSelector,
  getAddictionalServices,
  getDetailOrderContactInfo,
} from '@modules/orders';
import _ from 'lodash';
import { Helper } from '@app/utils';

const Wrapper = styled(InfoBlock)`
  @media (max-width: 767px) {
    border: none;
    box-shadow: none;
    margin-top: 10px;
    width: 100%;
    padding: 0;
  }

  @media (min-width: 767px) {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
    border: none;
    box-shadow: none;
    padding: 0;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    margin-left: 5px;
  }
`;

const List = styled.div`
  & > div {
    margin-bottom: 20px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export default (props: AddictionalServicesBookingProps) => (
  <Provider>
    <AddictionalServicesBooking {...props} />
  </Provider>
);

function AddictionalServicesBooking({
  showExtraServices,
}: AddictionalServicesBookingProps) {
  const {
    visibility,
    hideModal,
    smsModalVisibility,
    hideSmsModal,
    addSmsService,
  } = useModalState();
  const ancillaryServices = useSelector(ancillaryServicesSelector);
  const user = useSelector(getDetailOrderContactInfo);
  const addictionalServices = useSelector(getAddictionalServices);
  const items = Helper.getUniqueItems(addictionalServices);

  const showPaidItems =
    items.filter((x) => x.isPaid).length > 0 ||
    ancillaryServices.luggage.length > 0 ||
    ancillaryServices.meal.length > 0 ||
    ancillaryServices.others.length > 0 ||
    ancillaryServices.seats.length > 0;

  const showNotPaidItems =
    showExtraServices && items.filter((x) => !x.isPaid).length > 0;

  if (!showPaidItems && !showNotPaidItems) return null;

  return (
    <Wrapper>
      <div>
        <Title>Дополнительные услуги</Title>
        <SubTitle>
          Сделайте путешествие более комфортным с помощью дополнительных услуг.
        </SubTitle>
        <List>
          {showPaidItems &&
            items
              .filter((x) => x.isPaid)
              .map((x) => (
                <React.Fragment key={x.type}>{getTemplate(x)}</React.Fragment>
              ))}
          {showNotPaidItems &&
            items
              .filter((x) => !x.isPaid)
              .map((x) => {
                return (
                  <React.Fragment key={x.type}>{getTemplate(x)}</React.Fragment>
                );
              })}
        </List>
      </div>

      <Modal open={visibility !== null} onClose={hideModal} type={visibility} />
      <SmsServiceModal
        onSubmit={(data) => {
          addSmsService(data);
        }}
        open={smsModalVisibility}
        onClose={hideSmsModal}
        user={user}
      />
    </Wrapper>
  );
}

function getTemplate(x: ItemProps) {
  switch (x.type) {
    case Enums.SMS_Service:
      return <SmsService {...x} />;
    case Enums.Return_Guarantee:
      return <RefundService {...x} />;
    case Enums.Payment_Receipt:
      return <PrintService {...x} />;
    default:
      return null;
  }
}
