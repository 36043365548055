import { ApplicationState } from '..';

export const busFilterSelector = (state: ApplicationState) =>
  state.busTickets.busSearch.filter;

export const busCurrentSearchStepSelector = (state: ApplicationState) =>
  state.busTickets.busSearch.currentSearchStep;

export const busTicketsListSelector = (state: ApplicationState) =>
  state.busTickets.list;

export const filteredBusTicketsListSelector = (state: ApplicationState) =>
  state.busTickets.filteredList;

export const busRouteDetailsSelector = (state: ApplicationState) =>
  state.busTickets.busRoute;
