import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { TextareaProps } from './types';

const StyledTextarea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 12px;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.blackGray};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.blackGray};
  }
  &.error {
    border: 1px solid ${({ theme }) => theme.colors.red};
    &::placeholder {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

const Label = styled(Text)`
  display: block;
  margin-bottom: 5px;
  &.error {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const Textarea: React.FC<TextareaProps> = ({
  error,
  label,
  children,
  marginTop,
  ...props
}) => {
  const className = error ? 'error' : '';
  return (
    <Label style={{ marginTop }}>
      {label && (
        <Label className={className} color="dark-gray">
          {label}
        </Label>
      )}
      <StyledTextarea className={className} {...props} />
      {children}
    </Label>
  );
};

export default Textarea;
