import React from 'react';
import { default as ReactSelect, components } from 'react-select';
import styled, { css } from 'styled-components';
import Text from '@components/ui/Typography';
import { SelectProps } from './types';
import Tippy from '@tippyjs/react';
import infoIcon from '@assets/media/info-icon.svg';

const InfoIcon = styled.img.attrs({ src: infoIcon })`
  cursor: pointer;
  margin-left: 5px;
`;

const Wrapper = styled(Text)`
  ${({ error, theme: { colors } }) => {
    return css`
      display: block;
      margin-bottom: 4px;

      & > div > div {
        &:first-child {
          border-color: ${error !== undefined ? colors.red : colors.lightGray};
        }
      }
    `;
  }}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const Label = styled(Text)`
  display: block;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
`;

const OptionTitle = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  padding-bottom: 2px;
`;

const OptionDocument = styled(Text)`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #737373;
`;

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <OptionContainer>
        <OptionTitle>{props?.data?.label}</OptionTitle>
        <OptionDocument>{props?.data.subLabel}</OptionDocument>
      </OptionContainer>
    </components.Option>
  );
};

const groupOption = {
  option: (provided: any, state: any) => {
    return {
      ...provided,
      background: !state.isSelected ? '#FFFFFF' : '#EDF1FE',
    };
  },
};

const styles: any = {
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    visibility: 'hidden',
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Open Sans',
    color: '#3C3C3C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    paddingLeft: '12px',
    fontFamily: 'Open Sans',
    color: '#3C3C3C',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided: any, { isFocused, ...state }: any) => {
    const border = isFocused
      ? '1px solid #3C3C3C !important'
      : '1px solid #DCDCDC';
    const borderColor = isFocused ? '#3C3C3C' : '#DCDCDC';
    const boxShadow = 'none';
    return {
      ...provided,
      minHeight: 42,
      border,
      borderColor,
      boxShadow,
    };
  },
  menu: (provided: any, state: any) => ({ ...provided, zIndex: 100 }),
};

export const Error = styled.span`
  ${({ theme: { colors } }) => {
    return `
      font-size: 11px;
      line-height: 16px;
      color: ${colors.red};
    `;
  }}
`;

const Select: React.FC<
  SelectProps & {
    withCustomOption?: boolean;
    isInfo?: boolean;
    error?: string;
    tooltipText?: string;
  }
> = ({
  label,
  withCustomOption,
  isInfo,
  error,
  value,
  tooltipText,
  ...props
}) => {
  return (
    <Wrapper error={error}>
      {label && (
        <LabelContainer>
          <Label color="dark-gray">{label}</Label>
          {tooltipText && (
            <Tippy content={tooltipText} theme="light" maxWidth={190}>
              <InfoIcon />
            </Tippy>
          )}
        </LabelContainer>
      )}

      {withCustomOption ? (
        <ReactSelect
          value={value}
          isMulti={false}
          components={{
            Option,
            ...(props.components || {}),
          }}
          styles={{ ...styles, ...groupOption }}
          {...props}
        />
      ) : (
        <ReactSelect styles={styles} {...props} value={value} />
      )}
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default Select;
