import React from 'react';
import styled from 'styled-components';
import { DescriptionProps } from '../types';
import { Separator } from '@components/common';
import { MobileSegment } from '@components/common/FlightSegment';
import { useOrderPosition } from '../../../useItemProps';
import FLighTypeIcon from '@components/common/FlightTypeIcon';
import { Text as BaseText } from '@components/ui';
import _ from 'lodash';

const Wrapper = styled.div`
  padding: 15px 15px 20px 20px;
  @media (max-width: 767px) {
    padding: 20px 14px 14px 14px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const ItemWrapper = styled.div``;

const StyledFLighTypeIcon = styled(FLighTypeIcon)`
  margin-bottom: 17px;
  width: min-content;
`;

const FromToText = styled(BaseText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const SegmentList = styled.div`
  & > div {
    margin-bottom: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #f2f2f2;
  }

  & > :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const FlightList: React.FC<DescriptionProps> = React.memo(
  ({ onClick, segmentGroups, airlines, ...info }) => {
    const orderPosition = useOrderPosition();
    const baggage = orderPosition.tariff.fareFamilies
      ? (orderPosition.tariff.fareFamilies[0].features || []).find(
          (el) => el.type === 'Baggage'
        )
      : null;

    const baggageFormatted = baggage ? baggage.descriptionRus : 'неизвестно';

    const flightsFormated = segmentGroups.map((item) => {
      const firstSegment = item.segments[0];
      return {
        from: {
          date: item.departureDateTime,
          city: item.departureCity,
          airport: item.departureAirport,
          terminal: item.departureAirport.terminal,
        },
        to: {
          date: item.arrivalDateTime,
          city: item.arrivalCity,
          airport: item.arrivalAirport,
          terminal: item.arrivalAirport.terminal,
        },
        transfersCount: item.transferCount,
        duration: item.duration,
        luggage: baggageFormatted,
        airlane: firstSegment.marketingAirline.name,
        operatingAirlane: firstSegment.operatingAirline.name,
        terminalA: firstSegment.departureAirport.terminal,
        terminalB: firstSegment.arrivalAirport.terminal,
        airplane: `${firstSegment.marketingAirline.code} ${firstSegment.flightNumber} • ${firstSegment.airplane?.name}`,
        flightNo: ``,
        stops: item.stopOvers.map((x) => ({
          city: {
            code: x.city,
            airport: x.city,
            city: x.city,
            country: '',
            terminal: '',
          },
          duration: x.duration,
          time: '',
        })),
        active: info.active,
        airlines: airlines,
      };
    });

    const allSegments = _(segmentGroups)
      .map((x, key) => ({
        fromTo: `${key === 0 ? 'Туда' : 'Обратно'}: ${x.departureCity.name} - ${
          x.arrivalCity.name
        }`,
        items: _(x.segments)
          .map((item) => {
            return {
              from: {
                date: item.departureDateTime,
                city: item.departureCity,
                airport: item.departureAirport,
                terminal: item.departureAirport.terminal,
              },
              to: {
                date: item.arrivalDateTime,
                city: item.arrivalCity,
                airport: item.arrivalAirport,
                terminal: item.arrivalAirport.terminal,
              },
              transfersCount: item.stopOvers.length,
              duration: item.duration,
              luggage: baggageFormatted,
              airlane: item.marketingAirline.name,
              operatingAirlane: item.operatingAirline.name,
              terminalA: item.departureAirport.terminal,
              terminalB: item.arrivalAirport.terminal,
              airplane: `${item.marketingAirline.code} ${item.flightNumber} • ${item.airplane?.name}`,
              flightNo: `${item.marketingAirline.code} ${item.flightNumber} • ${item.airplane?.name}`,
              stops: item.stopOvers.map((y) => ({
                city: {
                  code: item.arrivalAirport.code,
                  airport: item.arrivalAirport.name,
                  city: item.arrivalCity.name,
                  country: '',
                  terminal: '',
                },
                duration: y.duration,
                time: '',
              })),
              active: info.active,
              airlines: airlines,
            };
          })
          .value(),
      }))
      .value();

    return (
      <Wrapper>
        {!info.active &&
          flightsFormated.map((item, key) => (
            <React.Fragment key={key}>
              <ItemWrapper>
                <StyledFLighTypeIcon flightType={orderPosition.flight_Type} />
                <MobileSegment {...item} />
              </ItemWrapper>

              {key !== flightsFormated.length - 1 && <Separator />}
            </React.Fragment>
          ))}
        {!!info.active && (
          <SegmentList>
            {allSegments.map((item, key) => (
              <div key={key}>
                <ItemWrapper>
                  <StyledFLighTypeIcon flightType={orderPosition.flight_Type} />
                  <FromToText>{item.fromTo}</FromToText>
                  <SegmentList>
                    {item.items.map((x, key) => (
                      <MobileSegment key={key} {...x} />
                    ))}
                  </SegmentList>
                </ItemWrapper>
              </div>
            ))}
          </SegmentList>
        )}
      </Wrapper>
    );
  }
);

export default FlightList;
