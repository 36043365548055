import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import { ReactComponent as Meal } from '@assets/media/addictional-services/meal.svg';
import { ReactComponent as Luggage } from '@assets/media/addictional-services/luggage.svg';
import { ReactComponent as Added } from './assets/media/payment-awaiting.svg';
import { ReactComponent as SeatMap } from '@assets/media/addictional-services/seat-map.svg';
import { useSelector } from 'react-redux';
import {
  AncillaryServices,
  ancillaryServicesSelector,
  ItemsEntity,
} from '@modules/orders';
import { ReactComponent as TriangleIcon } from '../../../booking/AddictionalServices/assets/media/triangle.svg';
import { ApplicationState } from '@modules/index';
import ServiceModalFromDetail from './ServiceModalFromDetail';
import SeatModalFromDetail from './SeatModalFromDetail';

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 10px 14px;
  }
`;

const PositionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const PositionHeadRight = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 5px;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-left: 5px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    position: initial;
  }
`;

const InfoBlockText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2e2e32;
  padding-bottom: 10px;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const AddedIcon = styled(Added)`
  position: absolute;
  right: -120px;
  @media (max-width: 767px) {
    position: absolute;
    right: -4px;
    top: -12px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const PositionsList = styled.div`
  margin-left: 7px;

  margin-bottom: 15px;

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const PassengersList = styled.div`
  margin-top: 15px;

  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

const Position = styled.div<{ open: boolean }>`
  ${({ open }) => {
    if (!open) {
      return `
        ${PositionHeadRight} {
          svg {
            transform: rotate(180deg);
          }
        }
        ${PassengersList} {
          display: none;
        }
      `;
    }
  }}
`;

const PositionName = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Passenger = styled.div``;

const PassengerName = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding-left: 20px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    padding-left: 9px;
  }
`;

const AncillaryList = styled.div`
  margin-top: 3px;
`;
const Ancillary = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 5px 0;
  padding-left: 20px;
  @media (max-width: 767px) {
    padding-left: 9px;
  }

  ${({ disabled }) =>
    disabled ? 'background: #F2F2F2; padding-right: 5px;' : ''}
`;

const AncillaryName = styled(Text)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
  color: #737373;
`;
const AncillaryPrice = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const PositionTitleMobile = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const RedText = styled.span`
  font-weight: 600;
  color: #d40e14;
`;

const PlaceList = styled.div`
  & > div {
    margin-bottom: 15px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  margin-left: 20px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const Place = styled.div``;

const PlaceTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`;

const GrayText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
`;

const PrimaryButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;
  min-width: 153px;
  max-width: 163px;
`;

export default function () {
  const items = useSelector(
    (state: ApplicationState) => state?.orders?.orderDetail.newAncillary
  );

  const bookingItems = useSelector(ancillaryServicesSelector);
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);

  const [isServiceModalOpen, setIsServiceModalOpen] = React.useState(false);
  const [isSeatsModalOpen, setIsSeatsModalOpen] = React.useState(false);

  const [chosenService, setChosenService] = React.useState([]);
  const [chosenServiceName, setChosenServiceName] = React.useState('');

  const [openSegmentIndexes, setOpen] = React.useState(
    {} as { [key: string]: boolean }
  );

  // TODO NEED ICON FOR SEAT

  return (
    <>
      {isServiceModalOpen && (
        <ServiceModalFromDetail
          data={chosenService}
          setIsServiceModalOpen={setIsServiceModalOpen}
          isServiceModalOpen={isServiceModalOpen}
          chosenServiceName={chosenServiceName}
        />
      )}
      {isSeatsModalOpen && (
        <SeatModalFromDetail
          data={chosenService}
          setIsSeatsModalOpen={setIsSeatsModalOpen}
          isSeatsModalOpen={isSeatsModalOpen}
          chosenServiceName={chosenServiceName}
        />
      )}
      {(items?.seatMaps ||
        Object.entries(bookingItems.mergedSeats).length > 0) && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <SeatMap style={{ width: 30, height: 30 }} />
                <Name>Место в самолете</Name>
              </Row>
            </Head>
            <InfoBlockText>
              Застрахуйте себя от случайной рассадки в самолете. Выберите место
              прямо сейчас и получайте удовольствие от комфортного полета.
            </InfoBlockText>
          </Content>
          <PositionsList>
            {Object.entries(bookingItems.mergedSeats).map((x: any, key) => (
              <Position
                key={key}
                open={openSegmentIndexes[`seats-${x[0]}-${key}`]}
              >
                <PositionHead
                  onClick={() => {
                    const k = `seats-${x[0]}-${key}`;
                    setOpen((s) => ({
                      ...s,
                      [k]: s[k] ? !s[k] : true,
                    }));
                  }}
                >
                  <PositionHeadRight>
                    <PositionName>{x[0]}</PositionName>
                    <TriangleIcon />
                  </PositionHeadRight>
                  <Price>
                    {Helper.formatPrice(
                      x[1].reduce(
                        (acc: number, x: ItemsEntity) => acc + x.price,
                        0
                      )
                    )}
                  </Price>
                </PositionHead>
                <PassengersList>
                  {x[1].map((y: ItemsEntity, key: number) => (
                    <Passenger key={key}>
                      <PassengerName>{y.passangerName}</PassengerName>
                      <AncillaryList>
                        <Ancillary disabled={y.status.code === 'Refunded'}>
                          <AncillaryName>
                            Место {y.seatNumber} &nbsp;
                            {y.emDcode && `(№ услуги: ${y.emDcode})`}{' '}
                            {y.status.code === 'Refunded' && (
                              <RedText>аннулирована</RedText>
                            )}
                          </AncillaryName>
                          <AncillaryPrice>
                            {Helper.formatPrice(y.price)}
                          </AncillaryPrice>
                        </Ancillary>
                      </AncillaryList>
                    </Passenger>
                  ))}
                </PassengersList>
              </Position>
            ))}
          </PositionsList>
          {items?.seatMaps && (
            <PrimaryButton
              onClick={() => {
                setChosenService(items.seatMaps);
                setChosenServiceName('Seats');
                setIsSeatsModalOpen(true);
              }}
            >
              Добавить место
            </PrimaryButton>
          )}
        </Wrapper>
      )}
      {(items?.meals?.length > 0 ||
        Object.entries(bookingItems.mergedMeals).length > 0) && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <Meal style={{ width: 30, height: 30 }} />
                <Name>Питание в самолете</Name>
              </Row>
            </Head>
            <InfoBlockText>
              Выберите блюдо, подходящее Вашим вкусовым предпочтениям, и
              наслаждайтесь полетом.
            </InfoBlockText>
          </Content>
          {/* {order.data.paymentStatus.code !== 'Paid' && ( */}
          <>
            {' '}
            <PositionsList>
              {Object.entries(bookingItems.mergedMeals).map((x: any, key) => (
                <Position
                  key={key}
                  open={openSegmentIndexes[`food-${x[0]}-${key}`]}
                >
                  <PositionHead
                    onClick={() => {
                      const k = `food-${x[0]}-${key}`;
                      setOpen((s) => ({
                        ...s,
                        [k]: s[k] ? !s[k] : true,
                      }));
                    }}
                  >
                    <PositionHeadRight>
                      <PositionName>{x[0]}</PositionName>
                      <TriangleIcon />
                    </PositionHeadRight>
                    <Price>
                      {Helper.formatPrice(
                        x[1].reduce(
                          (acc: number, x: AncillaryServices) => acc + x.price,
                          0
                        )
                      )}
                    </Price>
                  </PositionHead>
                  <PassengersList>
                    {x[1].map((y: AncillaryServices, key: number) => (
                      <Passenger key={key}>
                        <PassengerName>{y.passangerName}</PassengerName>
                        <AncillaryList>
                          <Ancillary disabled={y.status.code === 'Refunded'}>
                            <AncillaryName>
                              {y.name}&nbsp;
                              {y.emDcode && `(№ услуги: ${y.emDcode})`}{' '}
                              {y.status.code === 'Refunded' && (
                                <RedText>аннулирована</RedText>
                              )}
                            </AncillaryName>
                            <AncillaryPrice>
                              {Helper.formatPrice(y.price)}
                            </AncillaryPrice>
                          </Ancillary>
                        </AncillaryList>
                      </Passenger>
                    ))}
                  </PassengersList>
                </Position>
              ))}
            </PositionsList>
          </>
          {/* )} */}
          {items?.meals?.length > 0 && (
            <PrimaryButton
              onClick={() => {
                setChosenService(items.meals);
                setChosenServiceName('Meal');
                setIsServiceModalOpen(true);
              }}
            >
              Добавить питание
            </PrimaryButton>
          )}
        </Wrapper>
      )}
      {(items?.luggages?.length > 0 ||
        Object.entries(bookingItems.mergedLuggage).length > 0) && (
        <Wrapper>
          <Content>
            <Head>
              <Row>
                <Luggage style={{ width: 30, height: 30 }} />
                <Name>Дополнительный багаж</Name>
              </Row>
            </Head>
            <InfoBlockText>
              Оформите багаж заранее, в аэропорту эта услуга займет время и
              может стоить дороже, чем на сайте.
            </InfoBlockText>
          </Content>
          <PositionsList>
            {Object.entries(bookingItems.mergedLuggage).map((x: any, key) => (
              <Position
                key={key}
                open={openSegmentIndexes[`luggage-${x[0]}-${key}`]}
              >
                <PositionHead
                  onClick={() => {
                    const k = `luggage-${x[0]}-${key}`;
                    setOpen((s) => ({
                      ...s,
                      [k]: s[k] ? !s[k] : true,
                    }));
                  }}
                >
                  <PositionHeadRight>
                    <PositionName>{x[0]}</PositionName>
                    <TriangleIcon />
                  </PositionHeadRight>
                  <Price>
                    {Helper.formatPrice(
                      x[1].reduce(
                        (acc: number, x: AncillaryServices) => acc + x.price,
                        0
                      )
                    )}
                  </Price>
                </PositionHead>
                <PassengersList>
                  {x[1].map((y: AncillaryServices, key: number) => (
                    <Passenger key={key}>
                      <PassengerName>{y.passangerName}</PassengerName>
                      <AncillaryList>
                        <Ancillary disabled={y.status.code === 'Refunded'}>
                          <AncillaryName>
                            Багаж {y.value} кг{' '}
                            {y.emDcode && `(№ услуги: ${y.emDcode})`}{' '}
                            {y.status.code === 'Refunded' && (
                              <RedText>аннулирована</RedText>
                            )}
                          </AncillaryName>
                          <AncillaryPrice>
                            {Helper.formatPrice(y.price)}
                          </AncillaryPrice>
                        </Ancillary>
                      </AncillaryList>
                    </Passenger>
                  ))}
                </PassengersList>
              </Position>
            ))}
          </PositionsList>
          {items?.luggages?.length > 0 && (
            <PrimaryButton
              onClick={() => {
                setChosenService(items.luggages);
                setChosenServiceName('Luggage');
                setIsServiceModalOpen(true);
              }}
            >
              Добавить багаж
            </PrimaryButton>
          )}
        </Wrapper>
      )}
    </>
  );
}
