// import React from 'react';
import Text from './Text';
import { default as Header } from './Header';
import { default as Link } from './Link';

type CompoundedComponent = typeof Text & {
  Header: typeof Header;
  Link: typeof Link;
};

const Typography = (Text as unknown) as CompoundedComponent;
Typography.Header = Header;
Typography.Link = Link;

export { Text, Header, Link };
export * from './types';

export default Typography;
