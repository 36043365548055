import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldProps } from './types';
import { isSafari } from 'react-device-detect';
import closeEyeSrc from '../../../../assets/images/common/closeEye.svg';
import openEyeSrc from '../../../../assets/images/common/openEye.svg';
import { boolean } from 'yup';
const Wrapper = styled.label`
  font-family: Open Sans;
  position: relative;
`;

const Label = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 4px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  // display: inline-block;
`;

const ClosedEye = styled.img.attrs({ src: closeEyeSrc as any, alt: '' }) <{ safari: boolean }>`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;

  // ${({ safari }) => safari ? 'right: 35px;' : ''}
`;

const OpenEye = styled.img.attrs({ src: openEyeSrc as any, alt: '' }) <{ safari: boolean }>`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  // ${({ safari }) => safari ? 'right: 30px;' : ''}
`;

interface InputStyledProps {
  error?: string | boolean;
}
const Input = styled.input<InputStyledProps>`
  font-family: Open Sans;
  color: #3c3c3c;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  outline: none;
  padding: 10px 12px;
  &::placeholder {
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray};
  }
  &:focus {
    border: 1px solid #3c3c3c;
  }
  ${({ error }) => (error ? 'border: 1px solid #e73c3e;' : '')}
`;

const ErrorText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #e73c3e;
  position: absolute;
`;

const PasswordField: React.FC<FieldProps> = ({ label, error, ...props }) => {
  const [type, setType] = useState('password');

  const handleEyeClick = () => {
    setType((type) => {
      if (type === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    });
  };
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <Input {...props} error={error} type={type} />
        {type === 'password' && <OpenEye safari={isSafari} onClick={handleEyeClick} />}
        {type === 'text' && <ClosedEye safari={isSafari} onClick={handleEyeClick} />}
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

export default PasswordField;
