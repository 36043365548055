import React from 'react';
import styled from 'styled-components';
import { Separator } from '@components/common';
import { Text } from '@components/ui';
import TinkoffLoginButton from '@components/partners/TinkoffLoginButton';

interface Props {
  onSignUp: () => void;
}

const DescriptionRow = styled.div`
  margin-top: 30px;
  text-align: center;
  position: relative;
`;

const Link = styled(Text)`
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }
`;

const SeparatorText = styled(Text)`
  position: absolute;
  color: #737373;
  line-height: 20px;
  padding: 0 14px;
  background-color: #ffffff;
  transform: translateX(-50%);
  top: -10px;
`;

const BottomAuthOrTinkoffPart: React.FC<Props> = (props: Props) => {
  return (
    <>
      <DescriptionRow>
        <Text>
          У вас нет аккаунта?{' '}
          <Link fontWeight="600" onClick={props.onSignUp}>
            Зарегистрируйтесь
          </Link>
        </Text>
      </DescriptionRow>
      <DescriptionRow>
        <Separator />
        <SeparatorText>или</SeparatorText>
      </DescriptionRow>
      <DescriptionRow>
        <TinkoffLoginButton />
      </DescriptionRow>
    </>
  );
};

export default BottomAuthOrTinkoffPart;
