export interface GetCalculationByNumberDto {
  supportTicketCalculation: SupportTicketCalculation;
  segmentEvents: null;
  selectedTickets: SelectedTicketsEntity[];
  selectedAncillaryServices?: SelectedAncillaryServicesEntity[];
  selectedInsurances?: null;
}

export enum CalculationType {
  Refund = 'Refund',

  /// <summary>
  /// Доплата
  /// </summary>
  ExtraPayment = 'ExtraPayment',

  /// <summary>
  /// Согласие с изменениями
  /// </summary>
  AgreementWithChanges = 'AgreementWithChanges',
}

export interface SupportTicketCalculation {
  number: number;
  supportTicketNumber: number;
  supportTicketId: string;
  type: {
    code: CalculationType;
    description: null;
  };
  tteUserName: string;
  status: Status;
  supportTicketCalculationTax: SupportTicketCalculationTax;
  supportTicketCalculationParameter: SupportTicketCalculationParameter;
  orderId: string;
  total: number;
  isRead: boolean;
  comment?: string;
  timelimit?: number;
  id: string;
  createdDate: string;
  modifiedDate: string;
}

export interface Status {
  code: string;
  description: string;
}
export interface SupportTicketCalculationItemsEntity {
  aviaPassengerId: string;
  ancillaryServiceId?: null;
  insurancePolicyId?: null;
  supportTicketCalculationTax: SupportTicketCalculationTax;
  supportTicketCalculationParameter: SupportTicketCalculationParameter;
}
export type SupportTicketCalculationTax = {
  [key in string & 'totalTaxes']: number | string | undefined;
};

export type SupportTicketCalculationParameter = {
  refundTax: number;
  tariff: number;
  usedRouteTax: number;
  aviaCompanyRefundTax: number;
  aviaCompanyCancellationTax: number;
  agencyTax: number;
  subAgencyTax: number;
  paySystemTax: number;
  comment: string;
  customerRefund: number;
  totalParameters: number;
  refundAmount: number;
};

export interface SelectedTicketsEntity {
  price: Price;
  aviaTicketId: string;
  id: string;
  lastName: string;
  firstName: string;
  eTicketNumber: string;
  patronymic: string;
  passengerType: string;
  userId: string;
}
export interface Price {
  basePrice: number;
  taxes: number;
  subAgentExtraCharge: number;
  agentExtraCharge: number;
  discount: number;
  commission: number;
}
export interface SelectedAncillaryServicesEntity {
  basePrice: number;
  agentExtraCharge: number;
  subAgentExtraCharge: number;
  ancillaryServicetId: string;
  name: string;
  type: string;
  emd: string;
  status: string;
  passenger: Passenger;
}
export interface Passenger {
  id: string;
  lastName: string;
  firstName: string;
  eTicketNumber: string;
  patronymic?: null;
  passengerType: string;
}
