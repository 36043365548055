import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import { isTupleOfNull, isBefore } from './utilities';
import map from 'lodash/map';
import flow from 'lodash/flow';

export type MonthListItem = {
  [year: string]: {
    date: Moment;
    selected: boolean;
  }[];
};

export type MonthList = MonthListItem[];

type MonthState = {
  date: Moment[];
  list: { [year: string]: { date: Moment; selected: boolean }[] }[];
};

type Values = Array<Moment | null>;

interface CalendarContext {
  date: Moment;

  setDate(date: Moment): void;

  values: Values;

  setValue(date: Moment | (Moment | null)[]): void;

  setByIndex(date: Moment, index: number): void;

  movedDateIndex: number | null;

  setMovedDateIndex(index: number | null): void;

  sendChanges(): void;

  handleMonthIncrement(): void;

  handleMonthDecrement(): void;

  handleMonthChange(date: Moment, index: number): void;

  monthState: MonthState;
  direction: 'forward' | 'backward' | null;
  type: 'range' | 'default';

  setDirection(value: 'forward' | 'backward' | null): void;
}

const MainContext = createContext<CalendarContext>(null as any);

export const useCalendareContext = () => useContext(MainContext);

type DateParam = Moment | null;
export type CalendarChangeHandler = (
  values: Array<Moment | null> | Moment | null
) => void;

/** Month */
const changeMonth = (date: Moment, datepickerIndex: number) => (state: MonthState) => {
  // установить начальную дату как выбранную, а к конечной прибавить один месяц
  const newDates = state.date.map((item, key) => {
    // если меняем начальную дату, то устанавливаем ее как выбранную и к конечной прибавляем один месяц
    if (datepickerIndex === 0) {
      if (key === 0) {
        return date;
      } else {
        return date.clone().add(1, 'month');
      }
    }
    // если меняем конечную дату, то устанавливаем ее как выбранную и от начальной отнимаем один месяц
    if (datepickerIndex === 1) {
      if (key === 1) {
        return date;
      } else {
        return date.clone().subtract(1, 'month');
      }
    }
  });
  return {
    ...state,
    date: newDates,
  };
};

const incrementMonth = (state: MonthState): MonthState => {
  return {
    ...state,
    date: map(state.date, (date) => {
      const newDate = date.clone().add(1, 'month');
      return newDate;
    }),
  };
};
const decrementMonth = (state: MonthState): MonthState => {
  return {
    ...state,
    date: map(state.date, (date) => {
      date.subtract(1, 'month');
      return date;
    }),
  };
};

const updateMonthList = (state: MonthState): MonthState => {
  const list = Object.entries(state.date).map(([index, date]) => {
    const arr: { [year: string]: { date: Moment; selected: boolean }[] } = {};
    const startDate = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    date.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    for (let i = 0; i < 9; i++) {
      if (typeof arr[startDate.format('YYYY')] === 'undefined') {
        arr[startDate.format('YYYY')] = [];
      }
      arr[startDate.format('YYYY')].push({
        date: startDate.clone(),
        selected: startDate.isSame(date),
      });
      startDate.add(1, 'month');
    }
    return arr;
  }, state.date);
  return {
    ...state,
    list,
  };
};

export interface ProviderProps {
  /**
   * Месяц календаря для показа. По умолчанию стоит текущий месяц.
   */
  date?: Date | string;
  /** Выбранная дата/даты */
  values: Array<DateParam>;
  /** Выбор только одной даты или промежутка */
  type?: 'range' | 'default';
  direction: 'forward' | 'backward' | null;
  setDirection(direction: 'forward' | 'backward' | null): void;
  onChange?: CalendarChangeHandler;
}

export const CalendarProvider: React.FC<
  ProviderProps & React.PropsWithChildren
> = ({ children, type = 'default', onChange, ...props }) => {
  const [monthState, setMonthState] = useState({
    date: [moment(), moment()],
    list: [] as MonthList,
  });

  useEffect(() => {
    const newState = flow(
      changeMonth(moment().add(1, 'month'), 1),
      updateMonthList
    )(monthState);
    setMonthState(newState);
  }, []);

  const handleMonthChange = (month: Moment, index: number) => {
    setMonthState(flow(changeMonth(month, index), updateMonthList)(monthState));
  };

  const handleMonthIncrement = () => {
    setMonthState(flow(incrementMonth, updateMonthList)(monthState));
  };

  const handleMonthDecrement = () =>
    setMonthState(flow(decrementMonth, updateMonthList)(monthState));

  const [date, setDate] = useState(
    moment(props.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );
  const valuesOfParent = props.values;

  const [values, setValues] = useState([null, null] as Array<null | Moment>);
  const [movedDateIndex, setMovedDateIndex] = useState(null as number | null);

  const setByIndex = (date: Moment, index: number) => {
    const arr = values as Array<Moment | null>;
    arr[index] = date;
    setValues([...arr]);
  };

  const sendChanges = useCallback(
    (val?: Values) => {
      typeof onChange === 'function' && onChange(val || values);
    },
    [onChange, type, values, valuesOfParent]
  );

  const handleSetValues = useCallback(
    (value: Moment | Moment[]) => {
      const setValuesWithMIddleware = (val: Array<null | Moment>) => {
        setValues(val);
        sendChanges(val);
      };

      if (type === 'default') {
        setValuesWithMIddleware([value as Moment, null]);
      } else if (type === 'range' && Array.isArray(values)) {
        if (Array.isArray(value)) {
          setValuesWithMIddleware(value);
        } else if (isTupleOfNull(values)) {
          setValuesWithMIddleware([value, null]);
        } else if (isBefore(value, values)) {
          setValuesWithMIddleware([value, values[1]]);
        } else {
          setValuesWithMIddleware([values[0], value]);
        }
      }
      setMovedDateIndex(null);
    },
    [values, type, setValues, sendChanges]
  );

  useEffect(() => {
    if (!valuesOfParent) {
      return;
    }
    setValues(valuesOfParent);
  }, [valuesOfParent, type]);

  useEffect(() => {
    moment(props.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }, [props.date]);

  return (
    <MainContext.Provider
      value={{
        direction: props.direction,
        setDirection: props.setDirection,
        date,
        setDate,
        values,
        setValue: handleSetValues,
        setMovedDateIndex,
        movedDateIndex,
        setByIndex,
        sendChanges,
        type,
        handleMonthIncrement,
        handleMonthDecrement,
        handleMonthChange,
        monthState,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

interface MonthContextProps {
  month: Moment;

  setMonth(date: Moment): void;

  monthList: { [year: string]: Moment[] };
  selectVisibility: boolean;

  setSelectVisibility(val: boolean): void;
}

export const MonthContext = createContext<MonthContextProps>(null as any);
export const useMonth = () => useContext(MonthContext);

interface MonthProviderProps {
  month?: Moment;
}

export const MonthProvider: React.FC<
  MonthProviderProps & React.PropsWithChildren
> = ({ month = moment(), children }) => {
  const [data, setData] = useState(month);
  const [selectVisibility, setSelectVisibility] = useState(false);

  /** Month list for month selector */
  const monthList = useMemo(() => {
    const arr: { [year: string]: Moment[] } = {};
    const date = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    for (let i = 0; i < 9; i++) {
      if (typeof arr[date.format('YYYY')] === 'undefined') {
        arr[date.format('YYYY')] = [];
      }
      arr[date.format('YYYY')].push(date.clone());
      date.add(1, 'month');
    }

    return arr;
  }, []);

  useEffect(() => {
    setData(month);
  }, [month]);

  return (
    <MonthContext.Provider
      value={{
        month: data,
        setMonth: setData,
        monthList,
        selectVisibility,
        setSelectVisibility,
      }}
    >
      {children}
    </MonthContext.Provider>
  );
};
