import React, { Dispatch, SetStateAction } from 'react';
import { InfoBlock, Separator } from '@components/common';
import { Header, Text } from '@components/ui/Typography';

import { FlightEntity } from '@modules/booking';
import List from '../List';
import styled from 'styled-components';
import { SegmentGroup } from '@modules/orders';
import { ApplicationState } from '@modules/index';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Helper } from '@utils';
import pasArrow from '@assets/media/order-detail/pasArrow.svg';
import baggageSrc from '@assets/media/shared/baggage.svg';
import noBaggageSrc from '@assets/media/shared/no-baggage.svg';
import Tariff from '@components/order/Detail/OrderPositions/Item/Tarrif';
import { useMediaQuery } from 'react-responsive';
import flightNumberIcon from '@assets/media/flight-search/flightNumberIcon.svg';

interface Props {
  data: FlightEntity[];
  isActive: boolean;
  baggageDescr?: string;
  isDetail?: boolean;
  setActive?: Dispatch<SetStateAction<boolean>>;
}

interface LinkProps {
  isDetail?: boolean;
}

const StyledHeader = styled(Header)`
  padding: 0 24px;
  margin-bottom: 15px;

  @media (max-width: 767px) {
    font-weight: 600;
    display: block;
    padding: 0 14px;
  }
`;

const FromTo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 24px;
  margin-top: 7px;

  @media (max-width: 767px) {
    margin-top: 5px;
    margin-left: 14px;
  }
`;

const FromToText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
  }
`;

const Dates = styled(Text)`
  color: #737373;
  margin-left: 24px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    margin-left: 14px;
  }
`;

const StyledInfoBlock = styled(InfoBlock)<LinkProps>`
  padding: 20px 0;
  box-shadow: ${({ isDetail }) =>
    isDetail ? 'none' : '0px 5px 10px rgba(0, 0, 0, 0.05)'};
  border: ${({ isDetail }) => (isDetail ? 'none' : '1px solid #DCDCDC')};

  @media (max-width: 767px) {
    padding: 14px 0;
  }
`;

const BlueTitle = styled.div`
  color: #4872f2;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 20px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const RowSB = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  transform: ${({ reverse }) =>
    !reverse ? 'rotate (0deg)' : 'rotate(180deg)'};
  margin-right: 20px;
  margin-bottom: 5px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  gap: 10px;
  @media (max-width: 767px) {
    margin-left: 14px;
    flex-direction: column;
    align-items: flex-start;
    & > div {
      width: 100%;
    }
  }
`;

const AdditionalInfoItem = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;

  & > img {
    margin-right: 7px;
  }

  & > strong {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
  }
`;

const FlightNumberIcon = styled.img.attrs({ src: flightNumberIcon })`
  margin-right: 7px;
`;

const AirlineNormalized = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
  @media (max-width: 767px) {
    width: 22px;
    height: 22px;
  }
`;

const BlockWithPadding = styled.div`
  padding: 0 24px;
`;

const FlightDetail: React.FC<Props> = (props) => {
  const { data, isActive, baggageDescr } = props;
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let flightNumbers = '';

  if (order?.data?.orderPositions[0]?.segmentGroups) {
    flightNumbers = order?.data?.orderPositions[0]?.segmentGroups
      .map((x) =>
        x.segments
          .map((y) => y.operatingAirline.code + ' ' + y.flightNumber)
          .join(', '),
      )
      .join(', ');
  }

  const isBookingSecondPage =
    window.location.pathname.includes('/bookingDetails');

  function fromToStr(arr: SegmentGroup[]) {
    if (!arr || arr.length === 0) return 'нет сегментов';
    if (arr.length === 1) {
      return `${arr[0].departureCity?.name} – ${arr[0].arrivalCity?.name}`;
    }
    return `${arr[0].departureCity?.name} – ${arr[0].arrivalCity?.name} – ${arr[1].arrivalCity?.name}`;
  }

  const price = Helper.formatPrice(
    order?.data?.orderPositions[0]?.tariff.adultPrice +
      order?.data?.orderPositions[0]?.tariff.childPrice +
      order?.data?.orderPositions[0]?.tariff.infantPrice,
  );

  const company = order?.data?.orderPositions[0]?.validationAirline;

  const tariff = order?.data?.orderPositions[0]?.tariff;

  return (
    <StyledInfoBlock isDetail={props.isDetail}>
      {!props.isDetail && (
        <RowSB>
          <div>
            <StyledHeader size="h4">Детали перелета</StyledHeader>
          </div>
          <Icon
            reverse={isActive}
            src={pasArrow}
            alt="arrow"
            onClick={() => props.setActive && props.setActive(!isActive)}
          />
        </RowSB>
      )}
      {isBookingSecondPage && !isActive && (
        <>
          <Dates>
            {moment(order.data.departureDate).format('D MMM YYYY, HH:mm, ddd')}{' '}
            – {moment(order.data.arrivalDate).format('D MMM YYYY, HH:mm, ddd')}
          </Dates>
          <FromTo>
            <FromToText>
              {fromToStr(order?.data?.orderPositions[0]?.segmentGroups)},{' '}
              {isMobile && <br />}
              <span style={{ color: '#737373' }}>
                {order?.data?.orderPositions[0]?.segmentGroups[0]?.segments[0]?.bookingClassRu &&
                  order?.data?.orderPositions[0]?.segmentGroups[0]?.segments[0]?.bookingClassRu}
              </span>
              {isMobile && (
                <span style={{ color: '#4872f2' }}>&nbsp;{price}</span>
              )}
            </FromToText>
            <BlueTitle>{price}</BlueTitle>
          </FromTo>
          <AdditionalInfoContainer>
            <AdditionalInfoItem>
              {baggageDescr === 'Без багажа' ? (
                <img src={noBaggageSrc} />
              ) : (
                <img src={baggageSrc} />
              )}
              {baggageDescr}
            </AdditionalInfoItem>
            {flightNumbers && (
              <AdditionalInfoItem>
                <FlightNumberIcon />
                {/* Рейс:&nbsp; <strong>{marketingAirline.code}</strong> */}
                <strong>{flightNumbers.toString()}</strong>
              </AdditionalInfoItem>
            )}
            {company && (
              <AdditionalInfoItem>
                <AirlineNormalized
                  src={Helper.getAirlinesLogoUrl(company, 50, 50)}
                  alt={company}
                />
                {order?.data?.orderPositions[0]?.validationAirlineName}
              </AdditionalInfoItem>
            )}
          </AdditionalInfoContainer>
        </>
      )}

      {((isBookingSecondPage && isActive) || !isBookingSecondPage) && (
        <>
          <List baggageDescr={baggageDescr} active={isActive} items={data} />
        </>
      )}
      {isBookingSecondPage && isActive && (
        <>
          <Separator />
          <BlockWithPadding>
            <Tariff {...tariff} position={order?.data?.orderPositions[0]} />
          </BlockWithPadding>
        </>
      )}
    </StyledInfoBlock>
  );
};

export default FlightDetail;
