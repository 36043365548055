import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Arrow from '../../assets/images/checked';
import { ExpandableBlockProps } from './types';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px 0;
  @media (max-width: 767px) {
    padding: 14px 0;
  }
`;

interface WrapperProps {
  buttonPosition: 'top' | 'center' | 'bottom';
}
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ buttonPosition }) =>
    buttonPosition === 'center'
      ? buttonPosition
      : buttonPosition === 'bottom'
      ? 'flex-end'
      : 'baseline'};
  padding: 0 24px;
  @media (max-width: 767px) {
    padding: 0 14px;
    display: flex;
  }
`;
interface ContentProps {
  padding: boolean;
}
const Content = styled.div<ContentProps>`
  margin-top: 20px;
  padding: ${({ padding }) => (padding ? '0 24px' : '0')};
  @media (max-width: 767px) {
    padding: 0 14px;
  }
`;

const LeftSide = styled.div`
  cursor: pointer;
  align-self: end;
`;

const StyledArrow = styled(Arrow)`
  cursor: pointer;
`;

const ExpandableBlock: React.FC<ExpandableBlockProps & React.PropsWithChildren> = ({
  label,
  children,
  className,
  padding = true,
  open = true,
  onClose,
  defaultClose,
  buttonPosition = 'center',
}) => {
  const [isOpen, setOpen] = useState(open);
  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (typeof defaultClose !== 'undefined') {
      setOpen(!defaultClose);
    }
  }, [defaultClose]);

  const handleClick = () => {
    const state = !isOpen;
    setOpen(state);
    typeof onClose === 'function' && !state && onClose();
  };
  return (
    <Container className={className}>
      <Wrapper buttonPosition={buttonPosition} onClick={handleClick}>
        <LeftSide>{label}</LeftSide>
        <StyledArrow />
      </Wrapper>
      {children && isOpen && <Content padding={padding}>{children}</Content>}
    </Container>
  );
};

export default ExpandableBlock;
