import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import {
  PopupProps,
  PopupActions,
} from '../../../../../node_modules/reactjs-popup/dist/types';
import {
  FareEntity,
  getSeatMapState,
  setActiveSegment,
  setActivePassenger,
  selectSeat,
  setActivePassengerType,
  DecksEntity,
} from '@modules/booking';
import SeatMap from '@components/SeatsMap';
import { Text, Button } from '@components/ui';
import { useSelector, useDispatch } from 'react-redux';
import PassengerSelectButton from '@components/booking/AddictionalServices/AviaServices/PassengerSelectButton';
import { ReactComponent as ExclamatoryIcon } from '@assets/media/shared/exclamatory.svg';
import { SeatMapLoaderWithWrapper } from '@components/loader';
import { Helper, Hooks } from '@utils';
import Tippy from '@tippyjs/react/headless';
import blueInfoIcon from '@assets/media/agents/blueInfoIcon.svg';
import _ from 'lodash';
import { addAncillaryNew, newAncillaryServicesSelector } from '@modules/orders';
import { ApplicationState } from '@modules/index';

interface BaseModalProps
  extends Omit<PopupProps & React.RefAttributes<PopupActions>, 'children'> {}

interface ModalProps extends BaseModalProps {
  fare: FareEntity;
}

const StyledPopup = styled(Popup)`
  &-overlay {
    overflow: scroll;
  }

  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0;
    border-radius: 8px;
    // height: calc(var(--vh, 1vh) * 95);
    display: flex;
    width: auto;
    overflow: hidden;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: fit-content;

    @media (min-height: 878px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      display: block;
      overflow: scroll;
      height: calc(var(--vh, 1vh) * 100);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
`;

const LeftSide = styled.div`
  padding: 30px;
  width: 420px;
  border-right: 3px solid #e3eaff;
  @media (max-width: 1023px) {
    padding: 32px 20px;
    padding-bottom: 0;
    width: 100%;
    box-sizing: border-box;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
    padding-bottom: 0;
    width: 100%;
    box-sizing: border-box;
  }
`;
const RightSide = styled.div`
  overflow-y: scroll;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-right: 100px;
  margin-right: -117px;
  // position: relative;
  @media (max-width: 1023px) {
    padding: 32px 20px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 0;
    margin-right: 0;
    padding-top: 25px;
    padding-left: 0;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 30px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const FromTo = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  & > strong {
    color: #4872f2;
  }

  display: block;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ButtonBlock = styled.div`
  & > button {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  margin-bottom: 35px;

  @media (max-width: 1023px) {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;

const SelectButton = styled.button<{ selected: boolean }>`
  ${({ selected, theme: { colors, fonts, borderRadius } }) => {
    const mainColor = colors.blackGray;
    const background = selected ? '#EDF1FE' : 'transparent';

    return `
    min-width: 100px;
    font-family: ${fonts.regular};
    border-radius: ${borderRadius};
    background: ${selected ? background : 'transparent'};
    color: ${selected ? colors.blue : '#3C3C3C'};
    border: 1px solid ${selected ? '#4872F2' : '#C4C4C4'};
    outline: none;
    font-style: normal;
    padding: 10px 11px;
    line-height: 20px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    max-width: 183px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 5px 10px rgba(0, 0, 0, 0.05);

    &:disabled {
        cursor: not-allowed;
        background: transparent;
        color: ${colors.gray};
        border: 1px solid ${colors.gray};
    }
    &:active {
        color: ${mainColor};
        border: 1px solid ${mainColor};
        background-color: ${background};
        box-shadow: 0px 0px 6px rgba(72, 114, 242, 0.64);
    }
    &:hover{
      box-shadow: 0 0 9px -1px ${colors.blue};
    }
    @media (max-width: 1023px) {
      padding: 8px;
      min-width: 77px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      max-width: 156px;
    }
      `;
  }}
`;

const Obozncheniya = styled.div`
  margin-bottom: 45px;
  @media (max-width: 1023px) {
    margin-bottom: 0px;
  }
`;

const ObozncheniyaTitle = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  display: block;
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 15px;
  }
`;

const ObobznacheniyaList = styled.div`
  margin-bottom: 45px;

  & > div {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

const ObotnacheniyaItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ObotnacheniyaName = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 10px;
  }
`;
const ObotnacheniyaPrice = styled(Text)`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  & > strong {
    font-weight: 600;
  }

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ObobznacheniyaText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;

  & > strong {
    color: #4872f2;
    font-weight: 600;
  }

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const CloseButton = styled(Button).attrs({ type: 'primary' })`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
  display: block;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const PassengerBlockWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 25px;
    overflow: hidden;
  }
`;

const PassengersBlock = styled.div`
  & > button {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: scroll;

    box-sizing: border-box;
    & > button {
      margin-right: 8px;
    }
  }
`;

const Squar = styled.div<{ background: string }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: ${({ background }) => background};

  border: 1.5px solid
    ${({ background }) => {
      switch (background) {
        case '#CFCBCB':
          return '#848484';
        case '#9484F6':
          return '#6E5CE0';
        case '#96DBDB':
          return '#50A9A9';
        default:
          return '#9C5EB0';
      }
    }};

  @media (max-width: 1023px) {
    width: 20px;
    height: 20px;
  }
`;

const TotalPrice = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;

  & > strong {
    color: #4872f2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const MobileCloseButton = styled.button`
  position: sticky;
  width: 100%;
  bottom: 0px;
  z-index: 2;
  background: #4872f2;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  border: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  outline: none;
  z-index: 100;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    position: fixed;
  }
`;

const Notification = styled.div`
  padding: 14px 24px;
  padding-left: 12px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > svg {
    margin-right: 7px;
  }

  & > span {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const DesktopTop = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const MobileTop = styled.div`
  top: 59px;
  z-index: 9999;
  background: white;
  width: 100%;
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    display: none;
  }
`;

const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const PriceBlockDesktop = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const DesktopButtons = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

const InfoIcon = styled.img.attrs({ src: blueInfoIcon })`
  margin-left: 7px;
  width: 18px;
  cursor: pointer;
`;

const StickyFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -4px 12px 0px #757a8830;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-around;
  padding: 0 10px;
  z-index: 100000;

  @media (min-width: 767px) {
    display: none;
  }
`;

const PrimaryButton = styled(Button).attrs({ type: 'primary' })`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20.5px;
  display: block;
  height: 40px;
  min-width: 200px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const OutlinedButton = styled(Button).attrs({ type: 'outlined' })`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20.5px;
  height: 40px;
  margin-left: 20px;
  min-width: 200px;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const RouteName = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: block;
  margin-bottom: 20px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    padding: 30px 20px 10px 20px;
    margin-bottom: 0;
  }
`;

const ButtonNext = styled.div`
  width: 50%;
  height: 46px;
  border-radius: 8px;
  background-color: #4872f2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 20px;
  cursor: pointer;
  @media (min-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface BaseModalProps
  extends Omit<
    PopupProps & React.RefAttributes<PopupActions>,
    'children' | 'open'
  > {
  data?: any;
  setIsSeatsModalOpen: Dispatch<SetStateAction<boolean>>;
  isSeatsModalOpen: boolean;
  chosenServiceName: string;
}

const Modal: React.FC<BaseModalProps> = ({
  data,
  setIsSeatsModalOpen,
  isSeatsModalOpen,
  chosenServiceName,
}: BaseModalProps) => {
  const dispatch = useDispatch();
  const { isMobile } = Hooks.useMediaSizes();
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);

  const [segmentId, setSegment] = React.useState('');
  const [passengerId, setPassengerId] = React.useState('');
  const [selectedSeats, setSelectedSeats] = React.useState<any>({});

  const newAncillarySelector = useSelector(newAncillaryServicesSelector);

  const sementsList = newAncillarySelector?.segments?.map((x) => x.id);

  const pricesArr = _(Object.values(data) || [])
    .map((x: any) =>
      x?.decks?.map((y: DecksEntity) =>
        y.rows.map((z) =>
          z.seatGroups.map((d) => d.seats.map((g) => g.price.amount))
        )
      )
    )
    .flattenDeep()
    .sort((a, b) => a - b)
    .value();

  const priceArrUnique = _(pricesArr).filter(Boolean).uniq().value();

  const minPrice = Math.min(...pricesArr.filter(Boolean));

  function findNextNotFilledSegment() {
    if (segmentId && sementsList) {
      const index = sementsList.indexOf(segmentId);

      return sementsList[index + 1];
    }
  }

  function findPreviousSegment() {
    if (segmentId && sementsList) {
      const index = sementsList.indexOf(segmentId);

      return sementsList[index - 1];
    }
  }

  const nextSegmentId = findNextNotFilledSegment();
  const previousSegment = findPreviousSegment();

  const getPassangers = () => {
    const passangersIds = order.data.orderPositions[0].passengers.map((x) => {
      return {
        id: x.passengerId,
        firstName: x.firstName,
        lastName: x.lastName,
        passengerType: x.passengerType,
        patronymic: x.patronymic,
      };
    });

    return passangersIds;
    // return {
    //   name: passanger?.firstName + ' ' + passanger?.lastName,
    //   ageCategory: getPassengerTypeDescription(passanger?.passengerType),
    //   isChosen: id === passengerId,
    // };
  };

  React.useEffect(() => {
    setSegment(Object.keys(data)[0]);
    setPassengerId(getPassangers()[0].id);
  }, [data]);

  function getPassengerTypeDescription(str?: string) {
    switch (str) {
      case 'Adult':
        return 'Взрослый';
      case 'Child':
        return 'Ребенок';
      default:
        return 'Младенец';
    }
  }

  function declOfNum(number?: number, titles?: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return (
      titles &&
      number &&
      titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ]
    );
  }

  const removeSeat = (id: string, segmentId: string) => {
    setSelectedSeats((selectedSeats: any) => {
      const { [id + segmentId]: _, ...newItems } = selectedSeats;
      return newItems;
    });
  };

  const nextSegment = (nextSegmentId: string) => {
    setSegment(nextSegmentId);
    setPassengerId(getPassangers()[0].id);
  };

  //   const isIncludesOnlyForAdult = segment?.decks?.some((x) =>
  //     x?.rows?.some((y) =>
  //       y.seatGroups.some((z) => z.seats.some((h) => h.onlyForAdult === true))
  //     )
  //   );

  const Submit = (selectedSeats: any) => {
    dispatch(
      addAncillaryNew({
        orderPositionId: order.data.orderPositions[0].id,
        ancillaryServices: Object.entries(selectedSeats).map((x: any) => ({
          rfic: x[1].rfic,
          rfisc: x[1].rfisc,
          passengerId: x[1].passengerId,
          segmentIds: [x[1].segmentId],
          seatNumber: x[1].number,
        })),
      })
    );
  };

  return (
    <StyledPopup
      onClose={() => setIsSeatsModalOpen(false)}
      open={isSeatsModalOpen}
    >
      <Cross
        href="/sde"
        onClick={(e) => {
          e.preventDefault();
          setIsSeatsModalOpen(false);
        }}
      />
      <MobileTop>
        {' '}
        {/* <FromTo>
          Маршрут:{' '}
          <strong>
            {segment?.from.city} - {segment?.to.city}
          </strong>
        </FromTo> */}
        <PassengerBlockWrapper>
          <PassengersBlock>
            {getPassangers()?.map((x, key) => (
              //@ts-ignore
              <PassengerSelectButton
                seat={
                  selectedSeats[x.id + segmentId] &&
                  selectedSeats[x.id + segmentId].segmentId === segmentId
                    ? selectedSeats[x.id + segmentId]?.number
                    : ''
                }
                key={key}
                {...x}
                number={key + 1}
                cost={
                  selectedSeats[x.id + segmentId] &&
                  selectedSeats[x.id + segmentId].segmentId === segmentId
                    ? selectedSeats[x.id + segmentId].price.amount
                    : 0
                }
                selected={x.id === passengerId}
                title={x.firstName + ' ' + x.lastName}
                ageCategoryDescription={getPassengerTypeDescription(
                  x.passengerType
                )}
                onClick={() => {
                  setPassengerId(x.id);
                  // setPassengerItems(
                  //   x.items.filter((x: any) =>
                  //     x.aviaSegmentIds.includes(segmentId)
                  //   )
                  // );
                }}
                onDelete={() => removeSeat(x.id, segmentId)}
              />
            ))}
          </PassengersBlock>
        </PassengerBlockWrapper>
      </MobileTop>
      <LeftSide>
        <DesktopTop>
          <Title>Проведите полет в комфорте</Title>

          <RouteName>
            Маршрут:{' '}
            <strong>
              {segmentId &&
                newAncillarySelector?.groupedSegments[segmentId]?.departureCity
                  .name +
                  ' - ' +
                  newAncillarySelector?.groupedSegments[segmentId]?.arrivalCity
                    .name}
            </strong>
          </RouteName>

          <PassengerBlockWrapper>
            <PassengersBlock>
              {getPassangers()?.map((x, key) => (
                //@ts-ignore
                <PassengerSelectButton
                  seat={
                    selectedSeats[x.id + segmentId] &&
                    selectedSeats[x.id + segmentId].segmentId === segmentId
                      ? selectedSeats[x.id + segmentId]?.number
                      : ''
                  }
                  key={key}
                  {...x}
                  number={key + 1}
                  cost={
                    selectedSeats[x.id + segmentId] &&
                    selectedSeats[x.id + segmentId].segmentId === segmentId
                      ? selectedSeats[x.id + segmentId].price.amount
                      : 0
                  }
                  selected={x.id === passengerId}
                  title={x.firstName + ' ' + x.lastName}
                  ageCategoryDescription={getPassengerTypeDescription(
                    x.passengerType
                  )}
                  onClick={() => {
                    setPassengerId(x.id);
                    // setPassengerItems(
                    //   x.items.filter((x: any) =>
                    //     x.aviaSegmentIds.includes(segmentId)
                    //   )
                    // );
                  }}
                  onDelete={() => removeSeat(x.id, segmentId)}
                />
              ))}
            </PassengersBlock>
          </PassengerBlockWrapper>
        </DesktopTop>
        {Object.values(selectedSeats).length !== 0 && (
          <PriceBlock>
            Стоимость за {Object.values(selectedSeats).length}
            {declOfNum(Object.values(selectedSeats).length, [
              ' место ',
              ' места ',
              ' мест ',
            ])}{' '}
            :&nbsp;
            <span style={{ color: '#4872F2' }}>
              {Object.values(selectedSeats).reduce(
                (acc: number, x: any) => acc + x.price.amount,
                0
              )}{' '}
              ₽
            </span>{' '}
          </PriceBlock>
        )}

        <Obozncheniya>
          <ObozncheniyaTitle>Условные обозначения:</ObozncheniyaTitle>
          <ObobznacheniyaList>
            <ObotnacheniyaItem>
              <ObotnacheniyaName>
                <Squar background="#9484F6"></Squar>
                <ObobznacheniyaText>
                  Для того, кому важна цена
                </ObobznacheniyaText>
              </ObotnacheniyaName>
              <ObotnacheniyaPrice>
                от{' '}
                <strong data-cy={'seatMapLegendSeatCheapPrice'}>
                  {Helper.formatPrice(priceArrUnique[0])}
                </strong>
              </ObotnacheniyaPrice>
            </ObotnacheniyaItem>
            {priceArrUnique[1] && (
              <ObotnacheniyaItem>
                <ObotnacheniyaName>
                  <Squar background="#96DBDB"></Squar>
                  <ObobznacheniyaText>
                    Для того, кто хочет отдохнуть
                  </ObobznacheniyaText>
                </ObotnacheniyaName>
                <ObotnacheniyaPrice>
                  от{' '}
                  <strong data-cy={'seatMapLegendSeatMediumPrice'}>
                    {Helper.formatPrice(priceArrUnique[1])}
                  </strong>
                </ObotnacheniyaPrice>
              </ObotnacheniyaItem>
            )}
            {priceArrUnique[2] && (
              <ObotnacheniyaItem>
                <ObotnacheniyaName>
                  <Squar background="#CA96DB"></Squar>
                  <ObobznacheniyaText>
                    Для того, кому важно удобство
                  </ObobznacheniyaText>
                </ObotnacheniyaName>
                <ObotnacheniyaPrice>
                  от{' '}
                  <strong data-cy={'seatMapLegendSeatExpensivePrice'}>
                    {Helper.formatPrice(priceArrUnique[2])}
                  </strong>
                </ObotnacheniyaPrice>
              </ObotnacheniyaItem>
            )}

            <ObotnacheniyaItem>
              <ObotnacheniyaName>
                <Squar background="#CFCBCB"></Squar>
                <ObobznacheniyaText>Кресло уже занято</ObobznacheniyaText>
              </ObotnacheniyaName>
            </ObotnacheniyaItem>
            {/* {isIncludesOnlyForAdult && (
              <ObotnacheniyaItem>
                <ObotnacheniyaName>
                  <Squar background="#CA96DB">ВЗ</Squar>
                  <ObobznacheniyaText>
                    Кресло только для взрослых
                  </ObobznacheniyaText>
                </ObotnacheniyaName>
              </ObotnacheniyaItem>
            )} */}
          </ObobznacheniyaList>
        </Obozncheniya>
        {Object.values(selectedSeats).length > 0 && (
          <PriceBlockDesktop>
            Итого за {Object.values(selectedSeats).length}
            {declOfNum(Object.values(selectedSeats).length, [
              ' место ',
              ' места ',
              ' мест ',
            ])}{' '}
            :&nbsp;
            <span style={{ color: '#4872F2' }}>
              {' '}
              {Object.values(selectedSeats).reduce(
                (acc: number, x: any) => acc + x.price.amount,
                0
              )}{' '}
              ₽
            </span>{' '}
          </PriceBlockDesktop>
        )}

        <DesktopButtons>
          {!previousSegment && (
            <PrimaryButton
              onClick={() =>
                nextSegmentId
                  ? nextSegment(nextSegmentId)
                  : setIsSeatsModalOpen(false)
              }
            >
              Перейти далее
            </PrimaryButton>
          )}
          {previousSegment && (
            <>
              <PrimaryButton
                onClick={() => previousSegment && setSegment(previousSegment)}
              >
                Вернуться назад
              </PrimaryButton>
            </>
          )}
          {Object.values(selectedSeats).length > 0 && (
            <OutlinedButton
              style={{ width: 147 }}
              onClick={() => Submit(selectedSeats)}
            >
              Закончить выбор
            </OutlinedButton>
          )}
        </DesktopButtons>
      </LeftSide>
      <RightSide>
        {/* {state.loading && <SeatMapLoaderWithWrapper />} */}
        <SeatMap
          onPlaceSelect={(x) =>
            setSelectedSeats({
              ...selectedSeats,
              [passengerId + segmentId]: { ...x, segmentId, passengerId },
            })
          }
          selectedSeats={selectedSeats}
          decks={data[segmentId]?.decks || []}
          airplaneName={''}
          pricesArr={pricesArr}
          selectedPassengerType={'ADULT'}
          isDetail={true}
          passengerId={passengerId}
          segmentId={segmentId}
        />
      </RightSide>
      {/* {!!state.selectedSeatCount && (
        <MobileCloseButton onClick={props.onClose}>
          Добавить {state.selectedSeatCount}{' '}
          {Helper.pluralWord(state.selectedSeatCount, 'место', 'места', 'мест')}{' '}
          за: {Helper.formatPrice(state.totalPrice)}
        </MobileCloseButton>
      )} */}
      <StickyFooter>
        {!previousSegment && (
          <ButtonNext
            onClick={() =>
              nextSegmentId
                ? nextSegment(nextSegmentId)
                : setIsSeatsModalOpen(false)
            }
          >
            Перейти далее
          </ButtonNext>
        )}
        {previousSegment && (
          <>
            <ButtonNext
              onClick={() => previousSegment && setSegment(previousSegment)}
            >
              Вернуться назад
            </ButtonNext>
          </>
        )}
        {Object.values(selectedSeats).length > 0 && (
          <ButtonNext
            style={{
              backgroundColor: 'white',
              color: '#4872F2',
              border: '1px solid #4872F2',
            }}
            onClick={() => Submit(selectedSeats)}
          >
            Закончить выбор
          </ButtonNext>
        )}
      </StickyFooter>
    </StyledPopup>
  );
};

export default Modal;
