import React from 'react';

import styled from 'styled-components';

import MainSegmentBus from './MainSegmentBus';
import { Bus, getBusRoute } from '@modules/busTickets';

import { useDispatch } from 'react-redux';

const Container = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    :not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 10px;

  @media (max-width: 767px) {
    display: none;
  }
`;

interface Props {
  bus: Bus;
  isFastest: string;
  isCheapest: string;
}

const ItemBuses: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { bus, isFastest, isCheapest } = props;

  return (
    <Container>
      <HeadContainer>{bus.carrier}</HeadContainer>

      <MainSegmentBus
        bus={bus}
        from={bus.originStationName}
        to={bus.destinationStationName}
        fromDate={bus.departureDateTime}
        toDate={bus.arrivalDateTime}
        duration={bus.durationInMinutes}
        isFastest={isFastest}
        isCheapest={isCheapest}
        getRoute={() => {
          dispatch(
            getBusRoute({
              Provider: bus.provider,
              DepartureDate: bus.departureDateTime,
              RaceID: bus.raceId,
            }),
          );
        }}
      />
    </Container>
  );
};

export default ItemBuses;
