import React, { useEffect, useState } from 'react';
import { Range, RangeChangeHandler } from '@components/ui';
import PlaneLogo from '../../../assets/images/plane';
import { ReactComponent as TrainLogo } from '../../../assets/media/header/train.svg';
import styled from 'styled-components';
import moment from 'moment';
import { DateRangeWithPlaneProps } from './types';
import { ReactComponent as BusLogo } from '../../../assets/media/buses/busesFilter.svg';

const TopWrapper = styled.div`
  margin-bottom: 12px;
`;

const TextWrapper = styled.div`
  & > svg {
    margin: 0 8px;
  }

  display: inline-flex;
  align-items: center;
`;

const Text = styled.span`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.blackGray};
`;

const TextContaner = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  color: ${({ theme: { colors } }) => colors.blackGray};
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const TimeText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
`;

const DateRangeLabel = styled.div`
  color: ${({ theme: { colors } }) => colors.blackGray};
  margin-bottom: 8px;
`;

const DateRangeWrapper = styled.div`
  & > div {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const DateRangeWithPlane: React.FC<DateRangeWithPlaneProps> = ({
  from,
  to,
  onChange,
  type,
  noIcon,
  onlyOneSlider,
}) => {
  const [values, setValues] = useState({ from: from.values, to: to.values });
  useEffect(() => {
    setValues({ from: from.values, to: to.values });
  }, [from, to]);

  const handleChangeFrom: RangeChangeHandler = (val) => {
    setValues((vals) => {
      return { ...vals, from: val };
    });
  };

  const handleChangeTo: RangeChangeHandler = (val) => {
    setValues((vals) => {
      return { ...vals, to: val };
    });
  };

  const handleOnAfterChangeFrom: RangeChangeHandler = (val) => {
    onChange('from', val);
  };

  const handleOnAfterChangeTo: RangeChangeHandler = (val) => {
    onChange('to', val);
  };

  return (
    <DateRangeWrapper className="range-time-with-plane">
      {!noIcon && (
        <TopWrapper>
          <TextWrapper>
            <Text>{from.city}</Text>
            {type === 'avia' && <PlaneLogo width={24} />}
            {type === 'train' && <TrainLogo />}
            {type === 'bus' && <BusLogo />}
            <Text>{to.city}</Text>
          </TextWrapper>
        </TopWrapper>
      )}
      {(!onlyOneSlider || onlyOneSlider === 'from') && (
        <div>
          <DateRangeLabel>
            <TimeText>{from.label}</TimeText>
          </DateRangeLabel>
          <TextContaner>
            <TimeText>от {timeFormatted(values.from[0])}</TimeText>
            <TimeText>до {timeFormatted(values.from[1])}</TimeText>
          </TextContaner>
          <Range
            onAfterChange={handleOnAfterChangeFrom}
            step={100}
            min={from.min}
            max={from.max}
            onChange={handleChangeFrom}
            value={values.from}
          />
        </div>
      )}

      {(!onlyOneSlider || onlyOneSlider === 'to') && (
        <div>
          <DateRangeLabel>
            <TimeText>{to.label}</TimeText>
          </DateRangeLabel>
          <TextContaner>
            <TimeText>от {timeFormatted(values.to[0])}</TimeText>
            <TimeText>до {timeFormatted(values.to[1])}</TimeText>
          </TextContaner>
          <Range
            onAfterChange={handleOnAfterChangeTo}
            step={100}
            min={to.min}
            max={to.max}
            onChange={handleChangeTo}
            value={values.to}
          />
        </div>
      )}
    </DateRangeWrapper>
  );
};

function timeFormatted(val: number) {
  return moment.unix(val).format('HH:mm D MMM');
}

export default DateRangeWithPlane;
