import React from 'react';
import styled from 'styled-components';
import {
  RoundIcon,
  TuiIcon,
  RailwayIcon,
} from '@components/loader';

interface Props {
  title?: string;
}

const LoaderFullscreenWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 600px);
  width: 100%;
`;

const LoaderTitle = styled.div`
  margin-top: 20px;
`;

const RelativeDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default () => (
  <LoaderWrapper>
    <RoundIcon />
  </LoaderWrapper>
);

export const TuiLoader = () => (
  <LoaderWrapper>
    <TuiIcon />
  </LoaderWrapper>
);

export const RailwayFullscreenLoader = (props: Props) => (
  <LoaderFullscreenWrapper>
    <RailwayLoader title={props.title} />
  </LoaderFullscreenWrapper>
);

export const RailwayLoader = (props: Props) => (
  <LoaderWrapper>
    <RailwayIcon />
    {!!props.title && <LoaderTitle>{props.title}</LoaderTitle>}
  </LoaderWrapper>
);
