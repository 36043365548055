import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Text, Button } from "@components/ui";

import { TuiLoader } from "@components/common/loader";
import SuccessOrderIcon from "../../assets/images/SuccessOrder";

import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
// import { Helper, Hooks } from '@utils';
import {
  isOrdersLoading,
  confirmPaymentRequest,
  NotifyOrderPayload,
} from "@modules/orders";

const Wrapper = styled.div``;

const Content = styled.div`
  padding: 28px 20px;
  height: calc(100vh - 55px);
  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`;

const Header = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3c3c;
  @media (min-width: 767px) {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
  }
`;

const SuccessIcon = styled(SuccessOrderIcon)`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  @media (min-width: 768px) {
    width: 46px;
    height: 46px;
    margin-left: 20px;
  }
`;

const Gray = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  @media (min-width: 767px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #737373;
  }
`;

const Black = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  font-weight: bold;
  @media (min-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
    color: #3c3c3c;
  }
`;

const List = styled.div`
  margin-bottom: 35px;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (min-width: 767px) {
    display: none;
  }
`;

const Row = styled.div`
  & > :first-child {
    margin-right: 5px;
  }
`;

const ReturnButton = styled(Button).attrs({
  type: "primary",
  color: "yellow",
})`
  width: 100%;
  @media (min-width: 767px) {
    width: auto;
    text-align: center;
  }
`;

const DesktopHeadWrapper = styled.div`
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 5x;
  @media (max-width: 767px) {
    display: none;
  }
`;

const DesktopHead = styled.div`
  padding: 0 0 50px 0;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;

  & > :first-child {
    display: block;
    margin-bottom: 25px;
  }
`;

const DestopList = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  row-gap: 25px;
`;

const PaymentSuccess: React.FC<RouteComponentProps<{ number: string }>> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isLoading = useSelector(isOrdersLoading);

  React.useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(location.search));
    const number = params.get("paymentNumber");
    const args: NotifyOrderPayload = { number };
    dispatch(confirmPaymentRequest(args));
  }, []);

  const navigateTo = () => history.push("/orders");

  return (
    <Wrapper>
      {isLoading && <TuiLoader />}
      <DesktopHeadWrapper>
        <DesktopHead>
          <Header>{"price"}</Header>
          <DestopList>
            <Gray>Номер обращения</Gray>
            <Black>{"string"} </Black>
            <Gray>Описание</Gray>
            <Black>Оплата прошла успешно</Black>
          </DestopList>
        </DesktopHead>
      </DesktopHeadWrapper>
      <Content>
        <Head>
          <Header>Оплата прошла успешно</Header> <SuccessIcon />
        </Head>
        <List>
          <Row>
            <Gray>Сумма доплаты:</Gray>
            <Black>{"price"}</Black>
          </Row>
          <Row>
            <Gray>№ обращения:</Gray>
            <Black>{"string"} </Black>
          </Row>
        </List>
        <ReturnButton onClick={navigateTo}>Вернуться к заказам</ReturnButton>
      </Content>
    </Wrapper>
  );
};

export default PaymentSuccess;
