import { createAction, createReducer } from 'redux-act';
import {
  AgentBalance,
  AgentFullInfo,
  AgentNotPaidOrder,
  AgentOverPaymentOrder,
  AgentUserDataDto,
  CreditLimitHistory,
  GetAgentPaymentItem,
  GetAgentPaymentPayload,
  AquiringInfo,
  GeneratedAddRequestAgentRequest,
  GeneratedTuiTransportEngineSharedAgentsClientContractsGetAgentDepositsResponse,
  GeneratedTuiTransportEngineSharedAgentsClientContractsGetAgentDepositsResponseItem,
  AgentAcquiringCommissions,
  // GeneratedAddRequestAgentResponse,
} from '@modules/agents/agent-finance/types';
import {
  AGENT_LOADING_ERRORS,
  AGENT_LOADING_STATE,
} from '@modules/agents/agent-finance/constants';
import { AcquiringCommissions, showModalError } from '@modules/orders';

export const setAgentAuthInformation = createAction<AgentUserDataDto>(
  'agents/setAgentInformation'
);

export const getAgentMainInformation = createAction(
  'agents/getAgentMainInformation'
);

export const setAgentMainInfo = createAction<AgentFullInfo>(
  'agents/setAgentMainInfo'
);

export const getAgentInfo = createAction<{ PartnerId?: string }>(
  'agents/getAgentInfo'
);

export const getAgentInfoSuccess = createAction<any>(
  'agents/getAgentInfoSuccess'
);

export const setAgentBalance = createAction<AgentBalance>(
  'agents/setAgentBalance'
);
export const getAgentOverPaidOrders = createAction<{
  partnerId?: string;
  sortField?: string;
  sortAsc?: boolean;
}>('agents/getAgentsOverPaid');
export const setAgentOverPaidOrders = createAction<
  AgentOverPaymentOrder[] | null
>('agents/setAgentsOverPaid');

export const getAgentNotPaidOrders = createAction<{
  partnerId?: string;
  sortField?: string;
  sortAsc?: boolean;
}>('agents/getAgentNotPaidOrders');
export const setAgentNotPaidOrders = createAction<AgentNotPaidOrder[]>(
  'agents/setAgentNotPaidOrders'
);

export const getAgentCreditHistoryRequest = createAction<{
  agentId: string;
  date: string;
  sortField?: string;
  sortAsc?: boolean;
}>('agents/getAgentCreditHistoryRequest');
export const getAgentCreditHistorySuccess = createAction<any>(
  'agents/getAgentCreditHistorySuccess'
);
export const getAgentCreditHistoryFailure = createAction(
  'agents/getAgentCreditHistoryFailure'
);

export const getAgentBillByOrderIdRequest = createAction<string>(
  'agents/getAgentBillByOrderId'
);
export const getAgentBillByOrderIdSuccess = createAction(
  'agents/getAgentBillByOrderIdSuccess'
);

export const getAgentReport = createAction<{
  agentId: string;
  date: string | null;
  ReportType: string;
}>('agents/getAgentReport');

export const getAgentReportResponse = createAction(
  'agents/getAgentReportResponse'
);

export const getAgentReportFailure = createAction<boolean>(
  '@@agents/getAgentReportFail'
);

export const getAgentPaymentHistoryRequest =
  createAction<GetAgentPaymentPayload>('agents/getAgentPaymentHistory');

export const getAgentPaymentHistorySuccess = createAction<
  GetAgentPaymentItem[]
>('agents/setAgentPaymentHistory');
export const getAgentPaymentHistoryFailure = createAction(
  'agents/getAgentPaymentHistoryFailure'
);

export const getAgentBillBySamoNumberRequest = createAction<string>(
  'agents/getAgentBillBySamoNumberRequest'
);

export const getAgentBillBySamoNumberFail = createAction<string>(
  'agents/getAgentBillBySamoNumberRequest'
);

export const getAgentBillByTteNumberRequest = createAction<string>(
  'agents/getAgentBillBySamoNumberRequest'
);

export const getAgentBillByTteNumberFail = createAction<boolean>(
  'agents/getAgentBillBySamoNumberFAil'
);

export const getAgentBillSuccess = createAction('agents/getAgentBillSuccess');

export const getAquiringCommission = createAction<{ AgentId?: string | null }>(
  'agents/getAquiringCommission'
);
export const getAquiringCommissionSuccess = createAction<AquiringInfo>(
  'agents/getAquiringCommissionSuccess'
);

export const addAgentRequest = createAction<GeneratedAddRequestAgentRequest>(
  'agents/addAgentRequest'
);
export const addAgentSuccess = createAction<boolean>('agents/addAgentSuccess');
export const addAgentFailure = createAction<boolean>('agents/addAgentFailure');

export const getAgentDeposit = createAction<{
  AgentId?: string;
  Summ?: number;
}>('@@agents/getAgentDeposit');
export const getAgentDepositSuccess = createAction(
  '@@agents/getAgentDepositSuccess'
);

export const getAgentDepositList = createAction<{
  AgentId?: string;
  From?: string;
  To?: string;
}>('@@agents/getAgentDepositList');
export const getAgentDepositListSuccess =
  createAction<GeneratedTuiTransportEngineSharedAgentsClientContractsGetAgentDepositsResponse>(
    '@@agents/getAgentDepositListSuccess'
  );

const agentSigninInfo = createReducer<null | AgentUserDataDto>({}, null);
agentSigninInfo.on(setAgentAuthInformation, (_, payload) => payload);

const agent = createReducer<null | AgentFullInfo>({}, null);
agent.on(setAgentMainInfo, (_, payload) => payload);

const balance = createReducer<null | AgentBalance>({}, null);
balance.on(setAgentBalance, (_, payload) => payload);

const agentRequestsInfo = createReducer<null | any>({}, null);
agentRequestsInfo.on(getAgentInfoSuccess, (_, payload) => payload);

const overPaidOrders = createReducer<[] | AgentOverPaymentOrder[] | null>(
  {},
  []
);
overPaidOrders.on(setAgentOverPaidOrders, (_, payload) => payload);

const notPaidOrders = createReducer<[] | AgentNotPaidOrder[]>({}, []);
notPaidOrders.on(setAgentNotPaidOrders, (_, payload) => payload);

const agentsCreditLimitHistory = createReducer<CreditLimitHistory[] | []>(
  {},
  []
);
agentsCreditLimitHistory.on(
  getAgentCreditHistorySuccess,
  (_, payload) => payload
);

const agentPaymentHistory = createReducer<[] | GetAgentPaymentItem[]>({}, []);
agentPaymentHistory.on(getAgentPaymentHistorySuccess, (_, payload) => payload);

const loadings = createReducer<typeof AGENT_LOADING_STATE>(
  {},
  AGENT_LOADING_STATE
);
loadings.on(getAgentBillByOrderIdRequest, (state) => ({
  ...state,
  billByOrderId: true,
}));
loadings.on(getAgentBillByOrderIdSuccess, (state) => ({
  ...state,
  billByOrderId: false,
}));

loadings.on(getAgentCreditHistoryRequest, (state) => ({
  ...state,
  creditHistory: true,
}));
loadings.on(getAgentCreditHistorySuccess, (state) => ({
  ...state,
  creditHistory: false,
}));

loadings.on(getAgentCreditHistoryFailure, (state) => ({
  ...state,
  creditHistory: false,
}));

loadings.on(getAgentBillByTteNumberRequest, (state) => ({
  ...state,
  getBillByOrderOrTTE: true,
}));
loadings.on(getAgentBillBySamoNumberRequest, (state) => ({
  ...state,
  getBillByOrderOrTTE: true,
}));
loadings.on(getAgentBillSuccess, (state) => ({
  ...state,
  getBillByOrderOrTTE: false,
}));
loadings.on(showModalError, (state) => ({
  ...state,
  getBillByOrderOrTTE: false,
}));

loadings.on(getAgentReport, (state) => ({
  ...state,
  getAgentReport: true,
}));
loadings.on(getAgentReportResponse, (state) => ({
  ...state,
  getAgentReport: false,
}));
loadings.on(getAgentReportFailure, (state) => ({
  ...state,
  getAgentReport: false,
}));

loadings.on(getAgentPaymentHistoryRequest, (state) => ({
  ...state,
  paymentHistory: true,
}));
loadings.on(getAgentPaymentHistorySuccess, (state) => ({
  ...state,
  paymentHistory: false,
}));
loadings.on(getAgentPaymentHistoryFailure, (state) => ({
  ...state,
  paymentHistory: false,
}));

const errors = createReducer<typeof AGENT_LOADING_ERRORS>(
  {},
  AGENT_LOADING_ERRORS
);

export const addAgentError = createReducer<boolean | null>({}, null);
addAgentError.on(addAgentFailure, (_, payload) => payload);

export const addAgentSuccessDone = createReducer<boolean | null>({}, null);
addAgentSuccessDone.on(addAgentSuccess, (_, payload) => payload);

const errorModal = createReducer<boolean | null>({}, null);
errorModal.on(getAgentReportFailure, (_, payload) => payload);

const aquiringInfo = createReducer<AgentAcquiringCommissions[] | null>(
  {},
  null
);
aquiringInfo.on(
  getAquiringCommissionSuccess,
  (_, payload) => payload.acquiringCommissions
);

export const agentDeposits = createReducer<
  | GeneratedTuiTransportEngineSharedAgentsClientContractsGetAgentDepositsResponseItem[]
  | null
  | undefined
>({}, null);
agentDeposits.on(getAgentDepositListSuccess, (_, payload) => payload?.deposits);

const financeAgentsReducers = {
  agentSigninInfo,
  agent,
  balance,
  notPaidOrders,
  overPaidOrders,
  agentsCreditLimitHistory,
  agentPaymentHistory,
  loadings,
  errors,
  errorModal,
  aquiringInfo,
  addAgentError,
  addAgentSuccessDone,
  agentDeposits,
  agentRequestsInfo,
};

export default financeAgentsReducers;
