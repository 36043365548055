import React from 'react';
import { useAddictionalServicesModalState } from '@components/booking';
import {
  getSeatMapState,
  getBookingAncillaryServicesState,
  getBookingPassengersState,
  switchAncillaryService,
  getCheckedAncillaryServices,
} from '@modules/booking';
import { useSelector, useDispatch } from 'react-redux';
import OfferBlock from '@components/booking/AddictionalServices/OfferBlocks/OfferBlock';
import { Enums } from '@components/booking/AddictionalServices/types';

import { useModalState } from '../context';
import Modal from '../AviaServices/Modal';

export default function AviaOfferBlock() {
  const { showModal } = useAddictionalServicesModalState();
  const seatMapState = useSelector(getSeatMapState);
  const services = useSelector(getBookingAncillaryServicesState);
  const checkedServices = useSelector(getCheckedAncillaryServices);
  const { visibility, hideModal } = useModalState();
  const [index, setIndex] = React.useState(null as null | string);
  const passengers = useSelector(getBookingPassengersState);
  const dispatch = useDispatch();

  const isLuggageChecked = services.some((x) => x.type === 'Luggage')
    ? checkedServices.length !== 0 &&
      checkedServices.some((x) => x.luggageInfo !== null) &&
      services
        .find((x) => x.type === 'Luggage')
        ?.segments.every((x) => x.fulfilled === true)
    : true;

  const isMealChecked = services.some((x) => x.type === 'Meal')
    ? checkedServices.length !== 0 &&
      checkedServices.some((x) => x.luggageInfo === null) &&
      services
        .find((x) => x.type === 'Meal')
        ?.segments.every((x) => x.fulfilled === true)
    : true;

  const needHideOfferBlock =
    (!seatMapState.available && services.length === 0) ||
    (seatMapState.available &&
      seatMapState.availableSeatCount === 0 &&
      services.length === 0) ||
    (!seatMapState.available && isLuggageChecked && isMealChecked) ||
    (seatMapState.available &&
      seatMapState.availableSeatCount === 0 &&
      isLuggageChecked &&
      isMealChecked);

  const handleClose = () => {
    if (visibility !== Enums.AncillaryDetails) {
      setIndex(null);
      hideModal();
    }
  };

  const chosenService = services.find((x) => x.type === index);

  return (
    <>
      {!needHideOfferBlock && (
        <OfferBlock
          text={
            !(!seatMapState.available || seatMapState.availableSeatCount === 0)
              ? 'Не забудьте выбрать место в самолете, чтобы провести полёт в комфорте!'
              : (!seatMapState.available ||
                  seatMapState.availableSeatCount === 0) &&
                services.find((x) => x.type === 'Luggage') &&
                !isLuggageChecked
              ? 'Не забудьте добавить багаж, чтобы не тратить на это время в аэропорту!'
              : (!seatMapState.available ||
                  seatMapState.availableSeatCount === 0) &&
                services.find((x) => x.type === 'Meal') &&
                !isMealChecked
              ? 'Не забудьте добавить питание, чтобы в полной мере насладиться полетом!'
              : ''
          }
          dataCy={'chooseSeatPlaceInRightPanel'}
          type={
            !(!seatMapState.available || seatMapState.availableSeatCount === 0)
              ? 'seat'
              : services.find((x) => x.type === 'Luggage') && !isLuggageChecked
              ? 'luggage'
              : services.find((x) => x.type === 'Meal') && !isMealChecked
              ? 'meal'
              : 'meal'
          }
          onClick={() =>
            !(!seatMapState.available || seatMapState.availableSeatCount === 0)
              ? showModal(Enums.SeatMap)
              : (!seatMapState.available ||
                  seatMapState.availableSeatCount === 0) &&
                services.find((x) => x.type === 'Luggage') &&
                !isLuggageChecked
              ? (showModal(Enums.Ancillary), setIndex('Luggage'))
              : services.find((x) => x.type === 'Meal')
              ? (showModal(Enums.Ancillary), setIndex('Meal'))
              : ''
          }
        />
      )}

      {index !== null && visibility === Enums.Ancillary && (
        <Modal
          onClose={handleClose}
          data={chosenService ? chosenService : services[0]}
          passengers={passengers}
          onItemClick={({ checked, ...data }) => {
            dispatch(switchAncillaryService(data as any));
          }}
        />
      )}
    </>
  );
}
