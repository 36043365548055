import { createSelector } from 'reselect';
import { ApplicationState } from '@modules/index';
import { STATUS } from './types/types';

export const getAppealStatus = (state: ApplicationState) =>
  state.appeals.status;

export const appealListSelector = (state: ApplicationState) =>
  state.appeals.list.tickets;

export const getAppealDetail = (state: ApplicationState) =>
  state.appeals.detail;

export const isAppealLoading = createSelector(
  getAppealStatus,
  (status) => status === STATUS.loading
);
export const isAppealFailure = createSelector(
  getAppealStatus,
  (status) => status === STATUS.failure
);
export const isAppealSuccess = createSelector(
  getAppealStatus,
  (status) => status === STATUS.success
);

export const getMessagesSelector = (state: ApplicationState) =>
  state.appeals.messages;

export const getRefundInfo = createSelector(
  getAppealDetail,
  (state) => state.calculation
);

export const getAddAppealState = (state: ApplicationState) =>
  state.appeals.addAppeal;

export const getSupportTicketCalculation = (state: ApplicationState) =>
  state.appeals.supportTicketCalculation;

export const getRefundAmountState = (state: ApplicationState) =>
  state.appeals.refundAmount;
