import React from 'react';
import { useRefund } from '../../../useRefund';
import { formatTableRows } from '../../../utils';
import CalculateTemplate from '../CalculateTemplate';
import AdditionalServiceTable from './AdditionalServiceTable';

const AdditionalServiceCalculate: React.FC = () => {
  const props = useRefund();

  const passengersData = props?.selectedAncillaryServices
    ? props?.selectedAncillaryServices
        .map((x) => {
          const calculation = props?.supportTicketCalculationItems
            ? props?.supportTicketCalculationItems.find(
                (y) => y.ancillaryServiceId === x?.ancillaryServicetId
              )
            : {};

          return { selectedTicket: x, ...calculation, type: props.type };
        })
        .filter((x) => x !== undefined)
    : [];

  const rows = passengersData.map((x) => ({
    rows: formatTableRows(x),
    item: x,
  }));

  return (
    <CalculateTemplate>
      <>
        {!!rows?.length &&
          rows.map((x, i: number) =>
            x ? (
              <AdditionalServiceTable
                isLastElement={i === rows.length - 1}
                data={x as any}
                key={i}
                index={i}
              />
            ) : null
          )}
      </>
    </CalculateTemplate>
  );
};

export default AdditionalServiceCalculate;
