import React from 'react';
import FilterList from '../FilterList';
import styled from 'styled-components';
import ResetButton from './ResetButton';
import { useDispatch } from 'react-redux';
import { resetFilter } from '@modules/main';

const StyledFilterList = styled(FilterList)`
  width: 286px;
  margin-right: 26px;
`;

const Template = () => {
  const dispatch = useDispatch();

  const onResetButtonClick = () => {
    dispatch(resetFilter(null));
  };

  return (
    <StyledFilterList>
      <ResetButton
        onClick={() => {
          onResetButtonClick();
        }}
      />
    </StyledFilterList>
  );
};

export default Template;
