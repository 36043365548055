import React from 'react';
import TuiTemplate from './TuiTemplate';

const withTemplate = <T extends {}>(
  Component: React.FC<T>,
  mainPage?: boolean,
  withoutSearch?: boolean,
  staticMobileMenu?: boolean
) => (props: any) => {
  //ЭТО ПРИГОДИТСЯ ПОЗЖЕ НЕ УДАЛЯТЬ
  // const location = window.location.hostname;
  // if (process.env.NODE_ENV === 'development' || location === 'www.tui.ru') {
  // }

  return (
    <TuiTemplate
      withoutSearch={withoutSearch}
      mainPage={mainPage}
      staticMobileMenu={staticMobileMenu}
    >
      <Component {...props} />
    </TuiTemplate>
  );
};

export default withTemplate;
