import AdultPath from '@assets/images/passenger-type/adult.svg';
import ChildPath from '@assets/images/passenger-type/child.svg';
import InfantPath from '@assets/images/passenger-type/infant.svg';

export function getPassengerIconPath(type: string) {
  switch (type) {
    case 'ADULT':
      return AdultPath;
    case 'CHILD':
      return ChildPath;
    case 'INFANT':
      return InfantPath;
    default:
      return '';
  }
}
