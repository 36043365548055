import React from 'react';
import styled from 'styled-components';
import { CheckboxSubGroupProps } from './types';
import ScrollBar from './scroll-bar';

export const SubGroupContainer = styled.div`
  margin-top: 24px;
`;

export const SubGroupName = styled.span`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.blackGray};
  margin-bottom: 16px;
`;

const CheckboxContainer = styled.div`
  & label {
    display: block;
    margin-bottom: 15px;
  }
  & label:last-of-type {
    margin-bottom: 0;
  }
`;

const SubGroup: React.FC<CheckboxSubGroupProps & React.PropsWithChildren> = ({
  name,
  scrollContent = false,
  children,
}) => (
  <SubGroupContainer>
    <SubGroupName>{name}</SubGroupName>
    {scrollContent ? (
      <ScrollBar>{children}</ScrollBar>
    ) : (
      <CheckboxContainer>{children}</CheckboxContainer>
    )}
  </SubGroupContainer>
);

export default SubGroup;
