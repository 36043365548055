import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PriceDetail from '@components/booking/PriceDetail/PriceDetail';
import { ApplicationState } from '@modules/index';
import { useFormikContext } from 'formik';
import { FormikPassengerItemEntity } from '@modules/booking';
import { CarPlace } from '@modules/trainTickets';

const TrainPriceDetailContainer: React.FC = () => {
  const pricing = useSelector(
    (state: ApplicationState) => state.trainBooking.pricing
  );
  const placesForward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward.chosenCarPlaces
  );
  const placesBackward = useSelector(
    (state: ApplicationState) => state.trainTickets.trainBack.chosenCarPlaces
  );

  const userState = useSelector(
    (state: ApplicationState) => state?.user
  );
  const userAgentId = userState?.data?.agentId;
  const userCommissionStatus = userState?.userCommissionStatus;

  const [hasNotFullTariff, setHasNotFullTariff] = React.useState(false);

  const formik = useFormikContext<{
    passengers: FormikPassengerItemEntity[];
  }>();

  useEffect(() => {
    let hasNotFullTariff = false;
    const tempForward: CarPlace[] = [...placesForward];
    const tempBackward: CarPlace[] = [...placesBackward];
    const isNonRefundableForwardStates = formik.values.passengers.map(
      (pass, index) => {
        return pass.tariffsPerRoute[0].isNonRefundableTariff;
      }
    );
    const isNonRefundableBackStates = formik.values.passengers.map(
      (pass, index) => {
        return pass.tariffsPerRoute.length > 1 && pass.tariffsPerRoute[1]
          ? pass.tariffsPerRoute[1].isNonRefundableTariff
          : false;
      }
    );

    isNonRefundableForwardStates.forEach((state, index) => {
      if (state) {
        tempForward[index]._isNotRefundable = state;
      }
    });

    isNonRefundableBackStates.forEach((state, index) => {
      if (state) {
        tempBackward[index]._isNotRefundable = state;
      }
    });

    formik.values.passengers.forEach((pass, index) => {
      if (pass.tariffsPerRoute.some((tariff) => tariff.tariffType !== 'Full')) {
        hasNotFullTariff = true;
        return;
      }
    });

    setHasNotFullTariff(hasNotFullTariff);
  }, [formik.values.passengers]);

  const subAgentTotal = 0;

  return (
    <PriceDetail
      noTimes
      needShowDiscountNoty={hasNotFullTariff}
      total={pricing.total}
      subagentTotal={subAgentTotal}
      detailList={pricing.priceItems}
      userCommissionStatus={userCommissionStatus}
      userAgentId={userAgentId}
    />
  );
};

export default TrainPriceDetailContainer;
