import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { setLKLoadingStatus } from '@modules/personalArea';
import { useEffect, useState, RefObject } from 'react';

/**
 * React hook for pagination
 * @returns pagination params
 */
export function usePaginationParams() {
  const query = useLocation();
  const params = new URLSearchParams(query.search);
  const pageStr = params.get('page');
  const pageSizeStr = params.get('pageSize');
  const page = pageStr ? parseInt(pageStr) : 0;

  const pageSize = pageSizeStr ? parseInt(pageSizeStr) : 10;

  return {
    page,
    pageSize,
  };
}

export const useMediaSizes = () => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isRetina = useMediaQuery({ minResolution: '2dppx' });
  const isMobileM = useMediaQuery({ maxWidth: 375 });
  const isMobile = useMediaQuery({ maxWidth: 540 });
  const isMobileReg = useMediaQuery({ maxWidth: 767 });

  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isLaptopOrBigTablet = useMediaQuery({ maxWidth: 1024 });
  const isComputer = useMediaQuery({ maxWidth: 1440 });
  const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 });

  return {
    isPortrait,
    isRetina,
    isMobile,
    isMobileReg,
    isMobileM,
    isTablet,
    isLaptopOrBigTablet,
    isComputer,
    isBigScreen,
  };
};

/**
 * Block body x scroll
 */
export const useBlockBodyScroll = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
};

export const useLkLoading = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLKLoadingStatus(false));
    return () => {
      dispatch(setLKLoadingStatus(true));
    };
  }, []);
};

export function useOnScreen(ref: RefObject<HTMLDivElement> | null) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref?.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return isIntersecting;
}
