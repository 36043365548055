import React from 'react';
import { default as BlockComponent } from './Block';
import { default as BlockRow } from './BlockRow';
import { BlockProps } from './types';

interface CompoundedComponent extends React.ComponentClass<BlockProps> {
  Row: typeof BlockRow;
}

const InternalBlock = (BlockComponent as unknown) as CompoundedComponent;
InternalBlock.Row = BlockRow;

export { InternalBlock as Block, BlockComponent, BlockRow };
export * from './types';
export default InternalBlock;
