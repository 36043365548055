import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as CurveIcon } from '@assets/media/loader/curve.svg';
import { ReactComponent as Icon } from '@assets/media/redirect-payment/loader-icon.svg';

const Wrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  top: 40%;
`;

const spin = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  position: relative;
  animation: ${spin} 1s ease-in-out infinite;
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
  display: block;
  margin-top: 12px;
  text-align: center;
  width: 320px;
  top: 40%;
  position: relative;

  @media (min-width: 767px) {
    width: 360px;
  }
`;

const Curve = styled(CurveIcon)`
  position: absolute;
  top: 0;
  right: 0;
`;

export default function ({ title = 'Идет обработка оплаты' }) {
  return (
    <Wrapper id="noaderNotify">
      <LoaderWrapper>
        <Circle>
          <Curve />
        </Circle>
        <IconStyled />
      </LoaderWrapper>
      <Description>
        {title} <br />
        Пожалуйста, не перезагружайте страницу
      </Description>
    </Wrapper>
  );
}
