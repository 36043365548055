import React from 'react';
import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { BodyProps } from './types';
import moment from 'moment';
import { useCalendar } from '../../useCalendar';
import { Day as DayProps } from '../../types';
import 'moment/locale/ru';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  color: black;
`;

const DayDescription = styled(Text)`
  font-weight: 600;
  color: #9ea9b7;
  text-transform: uppercase;
  padding-bottom: 12px;
`;

const Day = styled(Text)`
  padding: 12px 0;
  border-radius: 4px;
`;

const weekDays = moment.weekdaysShort(true);

const Body: React.FC<BodyProps> = ({ days }) => {
  const { setDate, direction } = useCalendar();

  return (
    <Wrapper>
      {weekDays.map((value, key) => (
        <DayDescription key={key}>{value}</DayDescription>
      ))}

      {days.map((item, key) => (
        <Day
          data-cy={
            item.isToday
              ? 'dayToday'
              : !item.disabled
              ? 'dayInFuture'
              : undefined
          }
          onClick={() => {
            if (!item.disabled && item.isVisible) {
              setDate(item.dateStr, direction);
            }
          }}
          key={key}
          style={{
            background: createBackground(item),
            color: createFontColor(item),
            visibility: item.isVisible ? 'visible' : 'hidden',
          }}
        >
          {item.value}
        </Day>
      ))}
    </Wrapper>
  );
};

function createBackground(day: DayProps) {
  if (day.isToday) {
    return '#f2f2f2';
  }
  if (day.selected) {
    return '#4872f2';
  }
  if (day.isBetween) {
    return '#EDF1FE';
  }
  return 'white';
}

function createFontColor(day: DayProps) {
  if (day.disabled) return 'rgb(185, 185, 185)';
  return day.selected ? 'white' : 'black';
}

export default Body;
