import React from 'react';

const TicketBusIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0901 26.4737H22.1277V27.7369C22.1277 28.4316 22.6898 29 23.3908 29H24.654C25.3487 29 25.9171 28.4316 25.9171 27.7369V25.4885C26.6876 24.7937 27.1802 23.8021 27.1802 22.6842V10.0526C27.1803 5.63556 22.6663 5.00113 17.0901 5V10.0526H24.654V16.3684H17.0901V26.4737ZM20.8645 22.0526C20.8645 23.1011 21.7108 23.9474 22.7593 23.9474C23.8077 23.9474 24.654 23.1011 24.654 22.0526C24.654 21.0042 23.8077 20.1579 22.7593 20.1579C21.7108 20.1579 20.8645 21.0042 20.8645 22.0526Z"
        fill="#839FF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0901 26.4737H12.0525V27.7369C12.0525 28.4316 11.4904 29 10.7894 29H9.52622C8.8315 29 8.26308 28.4316 8.26308 27.7369V25.4885C7.49257 24.7937 6.99994 23.8021 6.99994 22.6842V10.0526C6.99988 5.63556 11.5139 5.00113 17.0901 5V10.0526H9.52616V16.3684H17.0901V26.4737ZM13.3156 22.0526C13.3156 23.1011 12.4693 23.9474 11.4209 23.9474C10.3725 23.9474 9.52616 23.1011 9.52616 22.0526C9.52616 21.0042 10.3725 20.1579 11.4209 20.1579C12.4693 20.1579 13.3156 21.0042 13.3156 22.0526Z"
        fill="#486AD4"
      />
    </svg>
  );
};

export default TicketBusIcon;
