import React, { FocusEvent } from 'react';
import styled from 'styled-components';
import { Field } from '@components/ui';
import { searchCountryByName } from '../../utils/helpers';
import ReactCountryFlag from 'react-country-flag';
import searchArrow from '@assets/media/searchArrow.svg';
import { CountryListItemType } from '@components/booking/PassengersBlock/types';
import { COUNTRIES_WITHOUT_FLAGS } from '@modules/trainBooking';

const emojiSupport = require('detect-emoji-support');

interface Props {
  error?: string;
  citizenship?: { value: string; label: string };
  handleCitizenshipChange: (value: CountryListItemType | null | '') => void;
  countriesList: CountryListItemType[];
  searchType?: 'avia' | 'train' | 'bus';
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
}

const StyledFieldDropDown = styled(Field)<{ paddingLeft: string }>`
  width: 100%;
  position: relative;
  cursor: pointer;
  padding-left: ${(props) => props.paddingLeft};
`;

const ReactCountryFlagElement = styled(ReactCountryFlag)`
  margin-right: 5px;
  width: 24px !important;
  height: 18px !important;
  border-radius: 30%;
`;

const CountryImage = styled.div`
  position: absolute;
  top: 7px;
  left: 10px;
  z-index: 100;
  cursor: pointer;
`;

const SearchArrow = styled.img.attrs({ src: searchArrow })`
  position: absolute;
  top: 17px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
`;

const CitizenDropDown = styled.div`
  position: absolute;
  box-shadow: 2px 6px 16px 0 #0000004a;
  width: 215px;
  max-height: 250px;
  overflow-x: hidden;
  z-index: 100;
  background: white;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #4872f2;
    min-height: 40px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const DropDownRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #3c3c3c;
  padding: 10px 10px;

  &:hover {
    cursor: pointer;
    background: #edf1fe;
  }
`;

const SubLabel = styled.div<{ display: string }>`
  display: ${(props) => props.display};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 0;
`;

const CountriesSelect: React.FC<Props> = (props) => {
  const { onBlur, error, citizenship, countriesList, searchType } = props;

  const [fakeCitizen, setFakeCitizen] = React.useState('');
  const [fakeCountryImage, setFakeCountryImage] = React.useState<any>('');
  const [fakeCountryCode, setFakeCountryCode] = React.useState<any>('');
  const [countrySearchData, setCountrySearchData] = React.useState<
    CountryListItemType[]
  >([]);
  const [isShowCountries, setIsShowCountries] = React.useState(false);

  React.useEffect(() => {
    if (citizenship && citizenship?.label && citizenship?.value) {
      setFakeCountryCode(citizenship.value);
      setFakeCitizen(citizenship.label);
    }
  }, [citizenship]);

  React.useEffect(() => {
    if (countrySearchData?.length !== 0) {
      setIsShowCountries(true);
    }
  }, [countrySearchData]);

  React.useEffect(() => {
    if (COUNTRIES_WITHOUT_FLAGS.includes(fakeCountryCode)) {
      setFakeCountryImage('');
    }
    if (fakeCountryCode && !COUNTRIES_WITHOUT_FLAGS.includes(fakeCountryCode)) {
      setFakeCountryImage(
        <ReactCountryFlagElement countryCode={fakeCountryCode} svg />,
      );
    }
  }, [fakeCountryCode]);

  const onClickOutsideListener = () => {
    setIsShowCountries(false);
    setCountrySearchData([]);
    document.removeEventListener('click', onClickOutsideListener);
  };

  const countriesSearchByNameList = async (str: string) => {
    const response = await searchCountryByName(str);
    if (
      (searchType === 'bus' || searchType === 'avia') &&
      response &&
      response?.data
    ) {
      response.data = response.data.filter(
        (country: any) => !COUNTRIES_WITHOUT_FLAGS.includes(country.isoCode),
      );
    }
    return response
      ? setCountrySearchData(
          response?.data?.map((city) => ({
            label: city.nameRus,
            value: city.isoCode,
            image: COUNTRIES_WITHOUT_FLAGS.includes(city.isoCode) ? null : (
              <ReactCountryFlagElement countryCode={city.isoCode} svg />
            ),
          })),
        )
      : [];
  };

  return (
    <>
      <StyledFieldDropDown
        paddingLeft={fakeCountryImage === '' ? '10px' : '40px'}
        error={error ? error : undefined}
        name="citizen"
        value={fakeCitizen}
        onClick={() => setIsShowCountries(!isShowCountries)}
        onBlur={onBlur}
        onChange={(e) => {
          setFakeCitizen(e.target.value);
          setFakeCountryImage('');
          props.handleCitizenshipChange('');

          countriesSearchByNameList(e.target.value);
        }}
        onMouseLeave={() => {
          document.addEventListener('click', onClickOutsideListener);
        }}
      >
        <CountryImage>{fakeCountryImage}</CountryImage>
        <SearchArrow onClick={() => setIsShowCountries(!isShowCountries)} />
      </StyledFieldDropDown>
      {isShowCountries &&
        (countrySearchData?.length === 0 ||
          countrySearchData === undefined) && (
          <CitizenDropDown>
            {countriesList.map((country, index) => (
              <DropDownRow
                key={index}
                onClick={() => {
                  setFakeCitizen(country.label);
                  setFakeCountryCode(country.value);
                  setIsShowCountries(false);
                  props.handleCitizenshipChange(country);
                }}
              >
                {country.image}
                {country.label}
              </DropDownRow>
            ))}
          </CitizenDropDown>
        )}
      <SubLabel display={error ? 'none' : 'block'}>
        Выберите из списка или введите
      </SubLabel>
      {isShowCountries && countrySearchData?.length !== 0 && (
        <CitizenDropDown>
          {countrySearchData?.map((country, index) => (
            <DropDownRow
              key={index}
              onClick={() => {
                setFakeCitizen(country.label);
                setFakeCountryCode(country.value);
                setIsShowCountries(false);
                props.handleCitizenshipChange(country);
              }}
            >
              {country.image}
              {country.label}
            </DropDownRow>
          ))}
        </CitizenDropDown>
      )}
    </>
  );
};

export default CountriesSelect;
