import React from 'react';
import { BusMainTicketInfo } from './BusMainTicketInfo';
import RouteDetailItem from '@components/common/RouteDetail/RouteDetailItem';

interface Props {
  bus: BusForRouteDetail;
  transportNumber: string;
  direction: 'forward' | 'backward';
  places?: string[];
  busAddresses?: {
    forward: {
      address: string | null;
      geolocation: string | null;
    };
    backward: {
      address: string | null;
      geolocation: string | null;
    };
  };
}

export interface BusForRouteDetail {
  fromStation: {
    code: string;
    name_ru: string;
    desctiption?: string;
  };
  toStation: {
    code: string;
    name_ru: string;
    desctiption?: string;
  };
  isTransit: boolean;
  carrierDisplayNames: string[];
  initialStationName: string;
  finalStationName: string;
  busName: string;
  busNumber: string;
  tripDuration: string;
  departureDateTimeUtc: string;
  arrivalDateTimeUtc: string;
  localDepartureDate: string;
  localArrivalDate: string;
  price: number;
  totalPlaceQuantity: number;
  index: number;
  raceId: string;
  freePlaces: string[] | null;
  address: string | null;
  geolocation: string | null;
  provider: string | null;
  place: string;
}

const RouteDetail: React.FC<Props> = ({
  bus,
  direction,
  transportNumber,
  places,
  busAddresses,
}) => {
  if (!bus) return null;

  return (
    <RouteDetailItem
      routeType={'bus'}
      direction={direction}
      fromStation={bus.fromStation.name_ru}
      toStation={bus.toStation.name_ru}
      transportNumber={transportNumber}
      seats={{
        numbers: places || [],
        needRenderAsCount: false,
      }}
      mainTicketInfo={<BusMainTicketInfo noCarServices noFeatures bus={bus} />}
      additionalOptions={{
        isRailwayTransit: bus.isTransit,
      }}
      busAddresses={busAddresses}
    />
  );
};
export default RouteDetail;
