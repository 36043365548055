import React from 'react';
import { useRefund } from '../../../useRefund';
import { formatTableRows } from '../../../utils';
import CalculateTemplate from '../CalculateTemplate';
import InsuranceTable from './InsuranceTable';

const InsuranceCalculate: React.FC = () => {
  const props = useRefund();

  const passengersData = props?.selectedInsurances
    ? props?.selectedInsurances
        .map((x) => {
          const calculation = props?.supportTicketCalculationItems
            ? props.supportTicketCalculationItems.find(
                (y) => y?.insurancePolicyId === x?.id
              )
            : {};

          return { selectedTicket: x, ...calculation, type: props.type };
        })
        .filter((x) => x !== undefined)
    : [];

  const rows = passengersData.map((x) => ({
    rows: formatTableRows(x),
    item: x,
  }));

  return (
    <CalculateTemplate>
      <div>
        {!!rows?.length &&
          rows.map((x, i: number) => (
            <InsuranceTable
              isLastElement={i === rows.length - 1}
              data={x as any}
              key={i}
              index={i}
            />
          ))}
      </div>
    </CalculateTemplate>
  );
};

export default InsuranceCalculate;
