import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import { Button } from '@components/ui';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import ItemBuses from './Item';
import { Bus, BUS_SEARCH_STEPS, setBusStep } from '@modules/busTickets';
import MobileBottomBus from './MobileBottomBus';
import { ApplicationState } from '@modules/index';

const shim = keyframes`
  0% {
    background-color: #C8C8C8;
  }

  99% {
    background-color: transparent;
  }

  100% {
    display: none;
  }
`;

const ItemContainer = styled.li`
  position: relative;
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }
  ${({ theme: { colors, fonts } }) => `
        background: ${colors.white};
        border: 1px solid ${colors.lightGray};
        box-sizing: border-box;
        border-radius: 10px;
        font-family: ${fonts.regular};
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        position: relative;
        @media screen and (max-width: 767px) {
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        }
    `}
`;

const Overlay = styled.div`
  position: absolute;
  animation: ${shim} 0.2s linear forwards;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
`;

const ItemLeftSide = styled.div`
  border-right: 1px solid ${({ theme: { colors } }) => colors.lightGray};
  padding: 24px 20px;
  text-align: left;
  & .segments {
    min-height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > :first-child > h6 {
      margin-top: 0;
    }
  }
  width: 70%;
  @media screen and (min-width: 1143px) {
    width: 600px;
  }
  @media screen and (max-width: 767px) {
    border-right: none;
    padding: 14px 0 9px 0;
    width: 100%;
  }
`;

const ItemRightSide = styled.div`
  width: 225px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .buy-button {
    padding: 8px 20px;
  }
`;

const FreePlaceQuantity = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
`;

interface Props {
  bus: Bus;
  isFastest: string;
  isCheapest: string;
  setSelectedBus?: (bus: Bus) => void;
}

const BusTicket: React.FC<Props> = (props: Props) => {
  const { bus, isFastest, isCheapest, setSelectedBus } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const busTickets = useSelector((state: ApplicationState) => state.busTickets);
  const busSearch = busTickets.busSearch;
  const hasBackwardRoute = busTickets.list?.busesPerRoutes?.length > 1;

  const isStepForward =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.TICKET_FORWARD;
  const isStepBackward =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.TICKET_BACKWARD;
  const isStepBooking =
    busSearch.currentSearchStep === BUS_SEARCH_STEPS.BOOKING;

  const [active, setActive] = useState(false);

  const ref = useRef(null as null | HTMLLIElement);

  const setStepHandler = () => {
    if (isStepBackward) {
      dispatch(setBusStep({ step: 'BOOKING', needClear: false }));
    } else {
      if (hasBackwardRoute) {
        dispatch(setBusStep({ step: 'TICKET_BACKWARD', needClear: false }));
      } else {
        dispatch(setBusStep({ step: 'BOOKING', needClear: false }));
      }
    }
  };

  // const handleBuyClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {

  //   history.push(
  //     `/booking/${props.searchId}/?${Api.createQueryParams({
  //       group: props.groupIndex,
  //       flights: flights.map((flight) => flight.index),
  //     })}`
  //   );
  //   e.preventDefault();
  // };

  const onSelectTicket = (): void => {
    setSelectedBus && setSelectedBus(bus);
    setStepHandler();
  };

  return (
    <ItemContainer ref={ref}>
      <Overlay
        onAnimationEnd={(e) => {
          (e.target as HTMLDivElement).style.display = 'none';
        }}
      />
      <ItemLeftSide>
        <ItemBuses
          bus={props.bus}
          isFastest={isFastest}
          isCheapest={isCheapest}
        />

        <MobileBottomBus bus={bus} />
      </ItemLeftSide>
      <ItemRightSide>
        <BodyForm>
          <FreePlaceQuantity>
            Доступно{' '}
            <span style={{ color: '#4872F2' }}>
              {' '}
              {bus.freePlaceQuantity} мест{' '}
            </span>
          </FreePlaceQuantity>
          <Button
            className="buy-button"
            color="yellow"
            htmlType="submit"
            style={{ fontWeight: 'normal' }}
            onClick={onSelectTicket}
          >
            Выбрать за {bus.price} ₽
          </Button>
        </BodyForm>
      </ItemRightSide>
    </ItemContainer>
  );
};

export default BusTicket;
