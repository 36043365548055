import React from 'react';
import styled from 'styled-components';
import { SliderItemProps } from './types';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import insurance7TabIcon from '@assets/media/insurances/insurance7TabIcon.svg';
import doctorCross from '@assets/media/insurances/doctorCross.svg';
import insuranceCheckMark from '@assets/media/insurances/insuranceCheckMark.svg';
import infoIcon from '@assets/media/info-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { stickyFooterHide } from '@modules/orders';
import { useDispatch } from 'react-redux';

interface LinkProps {
  color: string;
  isDetail?: boolean;
}

const Wrapper = styled.div<{ isActive: boolean; isDetail?: boolean }>`
  border: 1px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.main : '#dcdcdc')};
  box-sizing: border-box;
  padding: 14px 7px 9px 14px;
  border-radius: 10px;
  font-family: Open Sans;
  flex-shrink: 0;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ isDetail }) => (isDetail ? 'white' : '')};
  @media (max-width: 769px) {
    padding: 14px;
  }

  @media screen and (min-width: 768px) and (max-width: 1032px) {
    margin-top: 15px;
  }
`;

const Description = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  display: block;
  margin: 10px 0 15px 0;

  @media (max-width: 767px) {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const FooterDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  @media (max-width: 767px) {
    margin-top: 25px;
  }
`;

const Overlay = styled.div`
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #80808014;
`;

const StyledButton = styled(Button)`
  max-width: 92px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;
  display: block;
`;

const DesktopBr = styled.br`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin-right: 5px;
  }

  & > :last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    align-items: center;
  }
`;

const MobileName = styled(Text)`
  font-weight: 600;
`;

const MobilePrice = styled(MobileName)`
  color: #4872f2;
`;

const Title = styled.span`
  display: inline-block;
  margin-left: 10px;
  & > span {
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    margin-left: 5px;
  }
`;

const CovidTip = styled.div<{ background: string }>`
  position: absolute;
  top: -12px;
  right: 0;
  height: 24px;
  padding: 0 8px;
  border-radius: 8px;
  background: ${({ background }) => background};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CovidText = styled(Text)`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
  margin-left: 5px;
`;

const DoctorCross = styled.img.attrs({ src: doctorCross })`
  display: block;
  margin-right: 3px;
  width: 18px;
  height: 18px;
`;

const InsurIcon = styled.img.attrs({ src: insurance7TabIcon })`
  margin-right: 3px;
`;

const InsuranceCheckMark = styled.img.attrs({ src: insuranceCheckMark })`
  margin-right: 4px;
`;

const Link = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  color: #4872f2;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
`;

const PaymentBlock = styled.div<LinkProps>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  background-color: ${({ color }) => color};
  width: 189px;
  height: 38px;
  margin-top: 10px;
  font-weight: 600;
  font-family: Open Sans;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 7px;
  color: #3c3c3c;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 60px;
    align-items: flex-start;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;

  @media (min-width: 767px) {
    margin-top: 15px;
  }
`;

const FeaturesContainer = styled.div`
  @media (min-width: 768px) {
    height: 240px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoIcon = styled.img.attrs({ src: infoIcon })`
  cursor: pointer;
  margin-right: 5px;
`;

const Item: React.FC<SliderItemProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();

  const InsuranceDescriptions = [
    {
      name: 'Отмена поездки',
      text: [
        'Призыв или суд',
        'Хищение документов',
        'Госпитализация',
        'Отказ в визе',
      ],
    },
    {
      name: 'Полёт под защитой',
    },
    {
      name: 'Online-врач в поездку',
      text: [
        'Консультация терапевта или педиатра',
        'Защита при болезни и несчастном случае',
      ],
    },
    {
      name: 'Медицинские расходы',
      text: [
        'Оплата экстренного лечения и стоматологии',
        'Защита при потере документов',
        'Транспортные расходы',
        'Защита при активных видах отдыха',
      ],
    },
  ];

  const isProtection =
    props.product.name.includes('Защита на время полета') ||
    props.product.name.includes('Гарантия хорошей погоды') ||
    props.product.name.includes('Online-врач в поездку');

  const isInsuranceCancel = props.product.name.includes('Отмена');

  const handleClick = () => {
    props.onChange(props.product.id, props);
  };

  const isDetail = window.location.pathname.includes('/orders');

  return (
    <Wrapper
      data-cy={`insuranceBlock_${props.index}`}
      isActive={props.checked}
      isDetail={isDetail}
    >
      {props.disabled && <Overlay />}
      <MobileHead>
        {isMobile && (
          <>
            {' '}
            <Head>
              <img
                alt="icon"
                src={props.iconSrc}
                width={30}
                height={30}
                style={{ marginTop: 5 }}
              />
              <Title>
                <MobileName>{props.product.name}</MobileName>
                {!isProtection && <DesktopBr />}
              </Title>
            </Head>
            <div>
              <MobilePrice data-cy={`insurancePrice_${props.index}`}>
                {Helper.formatPrice(props.totalRate.value)}
              </MobilePrice>
            </div>
          </>
        )}
        {!isMobile && (
          <>
            {' '}
            <Head>
              <img
                alt="icon"
                src={props.iconSrc}
                width={30}
                height={30}
                style={{ marginTop: 5 }}
              />
              <Column>
                <div>
                  <Title>
                    <MobileName>{props.product.name}</MobileName>
                  </Title>
                </div>
                <div style={{ marginLeft: 10 }}>
                  <MobilePrice data-cy={`insurancePrice_${props.index}`}>
                    {Helper.formatPrice(props.totalRate.value)}
                  </MobilePrice>
                </div>
              </Column>
            </Head>
          </>
        )}
      </MobileHead>

      <PaymentBlock
        color={
          props.product.name === 'Отмена поездки'
            ? '#BFE8FF'
            : props.product.name === 'Полёт под защитой'
            ? '#FFEDBF'
            : props.product.name === 'Online-врач в поездку'
            ? '#DAF0A2'
            : props.product.name === 'Медицинские расходы'
            ? '#DAF0A2'
            : 'white'
        }
      >
        {props.product.name !== 'Online-врач в поездку' &&
          props.product.name !== 'Медицинские расходы' && (
            <>
              <InsurIcon />
              Выплата до{' '}
              {props.product.name === 'Отмена поездки'
                ? '5 000 ₽'
                : '500 000 ₽'}
            </>
          )}
        {props.product.name === 'Online-врач в поездку' && (
          <>
            <DoctorCross /> Помощь врача 24/7
          </>
        )}
        {props.product.name === 'Медицинские расходы' && (
          <>
            <DoctorCross /> Компенсация до 50 000 $
          </>
        )}
      </PaymentBlock>
      <FeaturesContainer>
        {props.product.name === 'Отмена поездки' && (
          <Row>19 причин отмены поездки, в том числе:</Row>
        )}
        {props.product.name === 'Отмена поездки' &&
          InsuranceDescriptions[0]?.text?.map((text, index) => (
            <Row key={index}>
              <InsuranceCheckMark /> {text}
            </Row>
          ))}
        {props.product.name === 'Полёт под защитой' && (
          <>
            <Row>
              <InsuranceCheckMark />{' '}
              <div>
                Моментальная выплата до{' '}
                <span style={{ fontWeight: 'bold' }}> 6 000 ₽ </span> на карту
                если рейс задержали
              </div>
            </Row>
            <Row>
              <InsuranceCheckMark />{' '}
              <div>
                Выплаты при утере багажа до{' '}
                <span style={{ fontWeight: 'bold' }}> 40 000 ₽ </span>
              </div>
            </Row>
            <Row>
              <InsuranceCheckMark />{' '}
              <div>
                Компенсация при несчастном случае <br /> до
                <span style={{ fontWeight: 'bold' }}> 500 000 ₽</span>{' '}
              </div>
            </Row>
          </>
        )}

        {props.product.name === 'Online-врач в поездку' &&
          InsuranceDescriptions[2]?.text?.map((text, index) => (
            <Row key={index}>
              <InsuranceCheckMark /> {text}
            </Row>
          ))}
        {props.product.name === 'Online-врач в поездку' && (
          <Row>
            <InsuranceCheckMark />{' '}
            <div>
              Максимальная выплата <br /> до&nbsp;
              <span style={{ fontWeight: 'bold' }}>1 000 000 ₽</span>
            </div>
          </Row>
        )}
        {props.product.name === 'Медицинские расходы' &&
          InsuranceDescriptions[3]?.text?.map((text, index) => (
            <Row key={index}>
              <InsuranceCheckMark /> {text}
            </Row>
          ))}
      </FeaturesContainer>
      {!isMobile && (
        <Head style={{ marginTop: 20, marginBottom: 10, height: 'auto' }}>
          <InfoIcon />
          <Link
            href="/404"
            onClick={(e) => {
              e.preventDefault();
              props.onClick(props.index);
            }}
            target="_blank"
            rel="noreferrer"
          >
            Подробные условия
          </Link>
        </Head>
      )}
      <FooterDescription>
        <StyledButton
          data-cy={`insuranceChoiceButton_${props.index}`}
          htmlType="button"
          isLoading={props.loading}
          color={props.checked ? 'gray' : undefined}
          onClick={handleClick}
        >
          {props.checked ? 'Отменить' : 'Выбрать'}
        </StyledButton>
        {isMobile && (
          <Head style={{ height: 'auto' }}>
            <InfoIcon />
            <Link
              href="/404"
              onClick={(e) => {
                e.preventDefault();
                props.onClick(props.index);
                dispatch(stickyFooterHide(true));
              }}
              target="_blank"
              rel="noreferrer"
            >
              Условия
            </Link>
          </Head>
        )}
      </FooterDescription>
      {!!props.label && (
        <CovidTip background={props.label.color}>
          <img alt="icon" src={props.label.icon} />
          <CovidText>{props.label.text}</CovidText>
        </CovidTip>
      )}
    </Wrapper>
  );
};

export default Item;
