import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { FocusFormProvider } from './useFormFocus';

const SearchForm: React.FC<{ className?: string }> = (props) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1154 });
  return (
    <FocusFormProvider>
      {isMobileOrTablet ? <Mobile {...props} /> : <Desktop {...props} />}
    </FocusFormProvider>
  );
};

export default SearchForm;
