import React from 'react';

export const RETURN_MODAL_DESC = (
  <span style={{ fontFamily: 'Open Sans' }}>
    <p>
      Услуга «Гарантия возврата билета» позволяет вернуть полную стоимость
      билета* и не оплачивать сервисный сбор за возврат из-за внезапной болезни
      пассажира. При этом сбор за возврат билета не будет взиматься со всех
      пассажиров, указанных в заказе.
    </p>

    <p>
      Стоимость билета за конкретного пассажира будет возвращена, если пассажир,
      указанный <br /> заказе, не в состоянии совершить перелет вследствие
      острого заболевания или травмы. Обязательным условием для вынужденного
      возврата средств по причине болезни является заблаговременное снятие места
      и медицинское заключение о том, что пассажир не может совершать перелет в
      указанные даты в билете.
    </p>

    <p>Для использования услуги необходимо:</p>

    <p>
      1. Снять место для пассажира(ов), который(ые) не могут совершать перелет
      по причине болезни, т.е. заявить о невозможности перелета.
    </p>

    <p>
      <strong>Внимание!</strong> Снятие мест должно быть осуществлено до
      окончания регистрации на рейс. Как правило регистрация заканчивается за 40
      минут до вылета, но авиакомпании вправе самостоятельно устанавливать
      время, после которого определяется факт неявки на рейс.
    </p>

    <p>
      Пожалуйста, свяжитесь с нами по горячей линии{' '}
      <a href="tel:+74957254083">+7 (495) 725 40 83</a> или обратитесь напрямую
      в авиакомпанию.
    </p>

    <p>
      Рекомендуем заявлять о необходимости снятия мест заблаговременно – не
      позднее, чем за 2 часа до вылета.
    </p>

    <p>
      2. Оформить Обращение на возврат, указав подходящую причину (болезнь
      пассажира).
    </p>

    <p>
      3. Направить на электронный адрес{' '}
      <a href="mailto:aviation@fstravel.com">aviation@fstravel.com</a> документ
      из медицинского учреждения, соответствующий следующим требованиям:
    </p>

    <p>
      Наличие в документе четкого и читабельного названия медицинского
      учреждения, выдавшего документ (наличие в документе штампа медицинской
      организации или оформленного на бланке медицинской организации (при
      наличии), кроме электронного листка нетрудоспособности);
    </p>

    <p>
      Наличие реквизитов медицинского учреждения, выдавшего медицинский документ
      (адрес, при наличии – контактные данные);
    </p>

    <p>
      Четко читаемая должность и фамилия лица, выдавшего медицинский документ;
    </p>

    <p>
      Соответствие дат болезни или дат противопоказаний к перевозке (о
      противопоказаниях к перелету должна быть сделана отдельная запись в
      выданном документе), указанных в документе, датам перелета по
      возвращаемому/обмениваемому перевозочному документу;
    </p>

    <p>
      Наличие диагноза, который может содержать название болезни/травмы или
      обозначаться как «заболевание». Для листка нетрудоспособности и
      электронного листка нетрудоспособности указание заболевания или диагноза
      не требуется;
    </p>

    <p>
      Наличие в документе печатей медицинского учреждения (за исключением
      медицинских документов, выданных за пределами РФ): для справки,
      медицинского заключения – наличие минимум двух печатей – личной печати
      врача (лечащего или главного – для справок, врачей-специалистов – для
      медицинских заключений) и печати медицинского учреждения, в оттиске
      которой должно быть полное наименование медицинского учреждения, выдавшего
      документ; o для иных документов (кроме электронного листка
      нетрудоспособности) – наличие печати медицинского учреждения, в оттиске
      которой должно быть полное название медицинского учреждения , выдавшего
      документ;
    </p>

    <p>
      Для мед. документов на иностранном языке-нотариально заверенный перевод на
      русский язык.
    </p>

    <p>Наличие даты выдачи.</p>

    <p>
      После обработки всех полученных данных, средства будут перечислены тем же
      способом, которым производилась оплата. Заказывая услугу «Гарантия
      возврата билета», вы соглашаетесь с Правилами использования и
      предоставления услуги «Гарантия возврата билета», указанными в правилах
      пользования сайтом.
    </p>

    <p>
      <strong>
        * Агент приложит максимум усилий для возврата покупателю услуги
        «Гарантия возврата билета» полной стоимости билета в случае болезни
        пассажира, но при этом окончательное решение о размере суммы к возврату
        всегда принимает авиакомпания.
      </strong>
    </p>
  </span>
);

export const SMS_MODAL_DESC = (
  <span style={{ fontFamily: 'Open Sans' }}>
    <p>
      Услуга смс-информирование подразумевает оперативное уведомление об
      изменениях в заказе, перелете, обращениях, возврате денежных средств при
      аннулировании бронирования путем отправки короткого смс-сообщения.
    </p>
    <p>
      СМС-сообщение направляется на контактный номер, указанных при оформлении
      заказа.
    </p>
    <p>
      <strong>Услуга считается оказанной</strong> с момента направления первого
      смс-сообщения на указанный пассажиром при оформлении заказа номер
      телефона.
    </p>
    <p>
      Компания не несет ответственности за некорректно указанный номер телефона
      при оформлении заказа, а также за проблемы со связью мобильных операторов.
    </p>
  </span>
);

export const PRINT_MODAL_DESC = (
  <span style={{ fontFamily: 'Open Sans' }}>
    <p>
      Справка может понадобиться бухгалтерии с вашего места работы для
      компенсации оплаты авиаперелета.
    </p>
    <p>
      Обращаем ваше внимание, что справка о стоимости будет доступна для
      скачивания после выписки авиабилетов.
    </p>
  </span>
);
