import React from 'react';
import styled from 'styled-components';
import { OrderPaymentEntity } from '@modules/orders';
import { Text } from '@components/ui';
import moment from 'moment';
import { Helper } from '@utils';

//

const Cell = styled(Text)`
  display: block;
  padding-left: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3c3c3c;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  white-space: nowrap;
`;

const DescriptionCell = styled(Cell)`
  background: #fafafa;
  font-weight: 600;
`;

const Wrapper = styled.div`
  background: white;
  padding: 20px 24px;
  width: 100%;
  margin-left: -24px;

  @media (max-width: 1140px) {
    padding: 20px 14px;
    margin-left: -14px;
    overflow-x: scroll;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.1fr;

  ${DescriptionCell} {
    &:after {
      position: absolute;
      content: ' ';
      height: 22px;
      width: 1px;
      top: 50%;
      background: rgba(0, 0, 0, 0.03);
      transform: translate(0, -50%);
      right: 0;
    }
  }

  & > :nth-child(4) {
    &:after {
      display: none;
    }
  }
`;

const GrayRow = styled.div`
  height: 1px;
  background: #dbe0e5;
`;

export default function (props: {
  items: OrderPaymentEntity[];
  open: boolean;
}) {
  return props.open ? (
    <Wrapper>
      <Container>
        <DescriptionCell>Дата платежа</DescriptionCell>
        <DescriptionCell>Сумма платежа</DescriptionCell>
        <DescriptionCell>Тип платежа</DescriptionCell>
        <DescriptionCell>Информация о платеже</DescriptionCell>
        {props.items.map((x, key) => (
          <React.Fragment key={key}>
            <Cell>{moment(x.paymentDate).format('DD.MM.YYYY HH:mm')}</Cell>
            <Cell>{Helper.formatPrice(x.paymentSum)}</Cell>
            <Cell>{x.paymentType}</Cell>
            <Cell>{x.paymentInfo === '' ? 'отсутствует' : x.paymentInfo}</Cell>
          </React.Fragment>
        ))}
      </Container>
    </Wrapper>
  ) : (
    <GrayRow />
  );
}
