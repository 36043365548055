import { createAction, createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { StatusPayload } from './types';
import { default as orderDetail } from './slices/detailOrderSlice';
import { default as orderList } from './slices/orderListSlice';

export const setLoadingStatus = createAction<StatusPayload>(
  '@orders/setLoadingStatus',
);

const status = createReducer({}, null as StatusPayload);
status.on(setLoadingStatus, (_, payload) => payload);

export * from './slices/detailOrderSlice';
export * from './slices/orderListSlice';
export default combineReducers({
  status,
  orderList,
  orderDetail,
});
