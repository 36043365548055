import { put, select, call } from 'typed-redux-saga';
import { Action } from 'redux-act';
import { AddictionalServiceTypes } from '../../types';
import { addPriceDetailItem, removePriceDetailItem } from '../../duck';
import { getTotalPrice, addictionalServicesSelector } from '../../selectors';
import * as Sentry from '@sentry/react';
import { updateFareData } from '../workers';

export function* switchAddictionalServiceFlow({
  payload,
}: Action<{
  index: number;
  value: boolean;
}>) {
  try {
    const total = yield* select(getTotalPrice);
    const addictionalService = yield* select(addictionalServicesSelector);
    const item = addictionalService[payload.index];

    if (item.checked) {
      let description = '';
      switch (item.type) {
        case AddictionalServiceTypes.SMS_Service:
          description = 'SMS-информирование';
          break;
        case AddictionalServiceTypes.Return_Guarantee:
          description = 'Гарантированный возврат билета';
          break;
        case AddictionalServiceTypes.Payment_Receipt:
          description = 'Справка о стоимости заказа';
          break;
      }
      yield* put(
        addPriceDetailItem({
          total,
          item: {
            description,
            cost: item.amount,
            type: item.type,
            quantity: 1,
            id: item.id,
          },
        })
      );
    } else {
      yield put(removePriceDetailItem({ total, id: item.id }));
    }
    yield call(updateFareData);
  } catch (e) {
    Sentry.captureException(e);
  }
}
