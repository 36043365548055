import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { ConfirmCancelationModalProps } from './types';
import { Text, Button as BaseButton } from '@components/ui';

const StyledPopup = styled(Popup)`
  &-content {
    width: 519px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 25px 16px;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: center;

    @media (max-width: 767px) {
      width: 100%;
      border-radius: 0px;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &-overlay {
    overflow: scroll;
    padding: 20px 0;

    @media (max-width: 767px) {
      z-index: 1000 !important;
      padding: 0;
    }
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 1170px) {
    font-size: 20px;
    line-height: 24px;
  }

  color: #3c3c3c;
  display: block;
  margin-bottom: 20px;
`;

const Question = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 30px;
  text-align: left;
  padding: 0 8px;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 767px) {
    display: block;

    & > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const Button = styled(BaseButton).attrs({
  type: 'outlined',
  htmlType: 'button',
})`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20px;
`;

export default function ConfirmCancelationModal({
  onConfirm,
  ...props
}: ConfirmCancelationModalProps) {
  return (
    <StyledPopup {...props}>
      <Title>Отмена обращения и расчета</Title>
      <Question>
        Вы уверены, что хотите отменить обращение и расчет по данному заказу?
      </Question>
      <ButtonBlock>
        <Button onClick={onConfirm}>Да, отменить расчет </Button>
        <Button
          onClick={() => {
            typeof props.onClose === 'function' && props.onClose();
          }}
        >
          Нет, вернуться к заказу
        </Button>
      </ButtonBlock>
    </StyledPopup>
  );
}
