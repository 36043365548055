import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { CarPlace } from '@modules/trainTickets';
import { Text } from '@components/ui/Typography';
import Button from '@components/ui/Button';
import { useSelector } from 'react-redux';
import { ApplicationState } from '@modules/index';
import moment from 'moment/moment';
import { revertConvertSeatPlaceWithALetter } from '@modules/trainTickets/utils';

interface Props {
  direction?: 'forward' | 'backward';
  open: boolean;

  onSave(): void;

  onChooseAnother(): void;
}

const StyledPopup = styled(Popup)`
  &-content {
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 0;
    border-radius: 8px;
  }
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  gap: 10px;
  padding: 30px;
  border-radius: 8px;

  @media (max-width: 767px) {
    padding: 20px 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled.h2`
  margin: 0;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const Places = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 0;
  gap: 20px;
`;

const OutlineButton = styled(Button)`
  height: 41px;
`;

const Point = styled.div`
  width: 8px;
  height: 8px;
  background-color: #4872f2;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
`;

const PointFromToStation = styled(Point)`
  border: 4px solid #e3eaff;
  position: relative;
  left: -4px;
  margin-right: 0;
`;

const Line = styled.div`
  background-color: #e3eaff;
  width: 2px;
  height: 40px;
  left: 3px;
  top: 10px;
  position: absolute;
  z-index: 1;
`;

const RouteBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;

const RouteItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Open Sans;

  &:first-child {
    margin-bottom: 20px;
  }
`;

export const AlreadyChosenTrainPanel: React.FC<Props> = (props: Props) => {
  const directionText = props.direction === 'forward' ? 'туда' : 'обратно';
  let train = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward.train
  );
  let chosenCar = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward.currentCar
  );
  let chosenCarPlaces = useSelector(
    (state: ApplicationState) => state.trainTickets.trainForward.chosenCarPlaces
  );
  if (props.direction === 'backward') {
    train = useSelector(
      (state: ApplicationState) => state.trainTickets.trainBack.train
    );
    chosenCarPlaces = useSelector(
      (state: ApplicationState) => state.trainTickets.trainBack.chosenCarPlaces
    );
    chosenCar = useSelector(
      (state: ApplicationState) => state.trainTickets.trainBack.currentCar
    );
  }

  if (!train || !chosenCar || !chosenCarPlaces?.length) {
    return null;
  }

  return (
    <StyledPopup onClose={props.onSave} open={props.open}>
      <Panel>
        <Header>
          <HeaderText>Поезд {directionText} уже выбран</HeaderText>
        </Header>
        <Content>
          <Text size={'big'}>
            Ранее вы уже выбрали поезд и места {directionText}:
          </Text>
          <Text>
            <b>
              {train?.trainName} {train?.displayTrainNumber}
            </b>
          </Text>
          <RouteBlock>
            <RouteItem>
              <PointFromToStation />
              <div>
                <Text fontWeight={'600'}>
                  {train?.fromStation.city.name_ru}
                </Text>
                ,{' '}
                <Text fontWeight={'600'} color={'blue'}>
                  {train?.fromStation.name_ru}
                </Text>
                ,{' '}
                <Text>
                  {moment(train?.localDepartureDate).format('DD MMMM YYYY, HH:mm')}
                </Text>
              </div>
            </RouteItem>
            <Line />
            <RouteItem>
              <PointFromToStation />
              <div>
                <Text fontWeight={'600'}>{train?.toStation.city.name_ru}</Text>,{' '}
                <Text fontWeight={'600'} color={'blue'}>
                  {train?.toStation.name_ru}
                </Text>
                ,{' '}
                <Text>
                  {moment(train?.localArrivalDate).format('DD MMMM YYYY, HH:mm')}
                </Text>
              </div>
            </RouteItem>
          </RouteBlock>
          <Places>
            <Text>
              Вагон №: <b>{chosenCar?.data?.carNumber}</b>
            </Text>
            <Text>
              Места №:{' '}
              {chosenCarPlaces.map((place: CarPlace, index) => {
                if (place.number.match(/[МЖЦС]/)) {
                  return <b key={index}>{place.number}{index === chosenCarPlaces.length - 1 ? '' : ', '}</b>;
                } else {
                  return (
                    <b key={index}>
                      {revertConvertSeatPlaceWithALetter(parseInt(place.number))}{index === chosenCarPlaces.length - 1 ? '' : ', '}
                    </b>
                  );
                }
              })}
            </Text>
          </Places>
        </Content>
        <Footer>
          <Button onClick={props.onSave} type={'primary'} template={'blue'}>
            Сохранить выбор
          </Button>
          <OutlineButton
            onClick={props.onChooseAnother}
            type={'outlined'}
            template={'blue'}
          >
            Я хочу выбрать другой
          </OutlineButton>
        </Footer>
      </Panel>
    </StyledPopup>
  );
};
