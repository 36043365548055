import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Text, Button } from '@components/ui';
import mobileIconPath from '../assets/images/404-mobile.svg';

const Wrapper = styled.div`
  height: calc(100vh - 85px);
  margin-top: 1px;
  width: 100%;
  padding: 0 0 0 63px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  @media screen and (min-width: 768px) and (max-width: 1439px) {
    padding: 0 0 0 20px;
  }
  @media (max-width: 1024px) {
    height: calc(100vh - 55px);
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 30px 25px 0 25px;
  }
  @media (max-width: 369px) {
    padding: 20px 10px 0 10px;
  }
  @media (max-height: 567px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const Content = styled.div`
  width: 535px;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    width: 70%;
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: 1024px) and (max-height: 768px) {
    width: 57%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const DesctopWideLogo = styled.img.attrs({
  src: '/images/backgoundImage404.png',
})`
  height: 100%;
  width: auto;
  @media (max-width: 1850px) {
    display: none;
  }
`;

const DesctopLogo = styled.img.attrs({
  src: '/images/backgoundImage404_843_951.png',
})`
  height: 100%;
  width: auto;
  display: none;
  @media (min-width: 1025px) and (max-width: 1849px) {
    display: block;
  }
`;

const MobileLogo = styled.img.attrs({ src: mobileIconPath as string })`
  display: none;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const Heading = styled(Text)`
  font-weight: 600;
  font-size: 70px;
  line-height: 78px;
  color: #092a5e;
  display: block;
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    & > br {
      display: none;
    }
  }

  @media (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`;

const Description = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  color: #092a5e;
  display: block;
  margin: 35px 0 40px 0;
  &.line-break {
    display: none;
  }
  @media (max-width: 1024px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 30px 0;
    & > br:not(.line-break) {
      display: none;
    }
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin: 14px 0 30px 0;
    & > br {
      display: none;
    }
  }
`;

const BackButton = styled(Button).attrs({ type: 'primary' })`
  width: 255px;
  background: #092a5e;
  padding: 10px 26px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Page404: React.FC = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Content>
        <MobileLogo />
        <Heading>
          Кажется что-то <br /> пошло не так
        </Heading>
        <Description>
          Страницы по этой ссылке не существует. <br className="line-break" />
          Но вы всегда можете вернуться к поиску <br /> авиабилетов :)
        </Description>
        <BackButton onClick={() => history.push('/')}>
          Вернуться на главную
        </BackButton>
      </Content>
      <DesctopLogo />
      <DesctopWideLogo />
    </Wrapper>
  );
};

export default Page404;
