import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.219 11.4691C15.2887 11.3993 15.3714 11.3439 15.4625 11.3061C15.5537 11.2682 15.6513 11.2488 15.75 11.2488C15.8486 11.2488 15.9463 11.2682 16.0374 11.3061C16.1286 11.3439 16.2113 11.3993 16.281 11.4691L20.781 15.9691C20.8508 16.0388 20.9063 16.1216 20.9441 16.2127C20.9819 16.3038 21.0013 16.4015 21.0013 16.5001C21.0013 16.5988 20.9819 16.6965 20.9441 16.7876C20.9063 16.8787 20.8508 16.9615 20.781 17.0311L16.281 21.5311C16.1402 21.672 15.9492 21.7511 15.75 21.7511C15.5508 21.7511 15.3598 21.672 15.219 21.5311C15.0782 21.3903 14.9991 21.1993 14.9991 21.0001C14.9991 20.801 15.0782 20.61 15.219 20.4691L19.1895 16.5001L15.219 12.5311C15.1492 12.4615 15.0937 12.3787 15.0559 12.2876C15.0181 12.1965 14.9987 12.0988 14.9987 12.0001C14.9987 11.9015 15.0181 11.8038 15.0559 11.7127C15.0937 11.6216 15.1492 11.5388 15.219 11.4691Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 16.5001C3 16.3012 3.07902 16.1104 3.21967 15.9698C3.36032 15.8291 3.55109 15.7501 3.75 15.7501H19.5C19.6989 15.7501 19.8897 15.8291 20.0303 15.9698C20.171 16.1104 20.25 16.3012 20.25 16.5001C20.25 16.699 20.171 16.8898 20.0303 17.0305C19.8897 17.1711 19.6989 17.2501 19.5 17.2501H3.75C3.55109 17.2501 3.36032 17.1711 3.21967 17.0305C3.07902 16.8898 3 16.699 3 16.5001ZM8.781 2.46912C8.85084 2.53879 8.90626 2.62155 8.94407 2.71267C8.98188 2.80379 9.00134 2.90147 9.00134 3.00012C9.00134 3.09877 8.98188 3.19645 8.94407 3.28757C8.90626 3.37869 8.85084 3.46145 8.781 3.53112L4.8105 7.50012L8.781 11.4691C8.85073 11.5389 8.90604 11.6216 8.94378 11.7127C8.98152 11.8039 9.00095 11.9015 9.00095 12.0001C9.00095 12.0987 8.98152 12.1964 8.94378 12.2875C8.90604 12.3786 8.85073 12.4614 8.781 12.5311C8.71127 12.6009 8.62848 12.6562 8.53737 12.6939C8.44626 12.7316 8.34861 12.7511 8.25 12.7511C8.15138 12.7511 8.05373 12.7316 7.96262 12.6939C7.87151 12.6562 7.78873 12.6009 7.719 12.5311L3.219 8.03112C3.14915 7.96145 3.09374 7.87869 3.05593 7.78757C3.01812 7.69645 2.99866 7.59877 2.99866 7.50012C2.99866 7.40147 3.01812 7.30379 3.05593 7.21267C3.09374 7.12155 3.14915 7.03879 3.219 6.96912L7.719 2.46912C7.78867 2.39928 7.87143 2.34386 7.96255 2.30605C8.05366 2.26824 8.15135 2.24878 8.25 2.24878C8.34865 2.24878 8.44633 2.26824 8.53745 2.30605C8.62857 2.34386 8.71133 2.39928 8.781 2.46912Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 7.5C3.75 7.30109 3.82902 7.11032 3.96967 6.96967C4.11032 6.82902 4.30109 6.75 4.5 6.75H20.25C20.4489 6.75 20.6397 6.82902 20.7803 6.96967C20.921 7.11032 21 7.30109 21 7.5C21 7.69891 20.921 7.88968 20.7803 8.03033C20.6397 8.17098 20.4489 8.25 20.25 8.25H4.5C4.30109 8.25 4.11032 8.17098 3.96967 8.03033C3.82902 7.88968 3.75 7.69891 3.75 7.5Z"
      />
    </svg>
  );
};

export default Logo;
