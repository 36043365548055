import React from 'react';
import styled from 'styled-components';
import { Text } from '@components/ui';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PaymentMethodIcon } from '@assets/media/main-page/payment-method.svg';
import { ReactComponent as TransferRulesIcon } from '@assets/media/main-page/transfer-rules.svg';
import { ReactComponent as BookingRulesIcon } from '@assets/media/main-page/booking-rules.svg';
import { ReactComponent as ExchangeAndRefundIcon } from '@assets/media/main-page/exchange-and-refund.svg';
import { ReactComponent as OrderHelpIcon } from '@assets/media/main-page/order-help.svg';
import { ReactComponent as UserAgreementIcon } from '@assets/media/main-page/user-agreement.svg';
import { ReactComponent as AviaContactsIcon } from '@assets/media/main-page/avia-contacts.svg';
import { ReactComponent as PartnershipIcon } from '@assets/media/main-page/partnership.svg';
import { ReactComponent as BookingRulesTrainIcon } from '@assets/media/main-page/booking-rules-train.svg';
import { ReactComponent as ChildrenRulesIcon } from '@assets/media/main-page/children-rules.svg';
import { ReactComponent as ReturnTicketIcon } from '@assets/media/main-page/return-ticket.svg';
import { ReactComponent as DiscountsIcon } from '@assets/media/main-page/discounts.svg';

interface Props {
  type: 'train' | 'avia' | 'bus';
}

const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  display: block;
  margin-bottom: 35px;

  @media (max-width: 1169px) {
    padding: 0 20px;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 24px;
  }
`;

const FlexWrapper = styled.div`
  overflow: inherit;
  @media (max-width: 1169px) {
    overflow: hidden;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > a {
    margin-right: 30px;
    margin-bottom: 30px;
  }

  & > :nth-child(4),
  > :last-child {
    margin-right: 0;
  }

  @media (max-width: 1169px) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 0 20px;
    overflow-x: scroll;
    padding-bottom: 30px;
    margin-bottom: -30px;

    & > a {
      margin-right: 16px;
      margin-bottom: 0;
    }

    & > :nth-child(4),
    > :last-child {
      margin-right: 16px;
    }
  }
`;

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 270px;
  text-decoration: none;
  height: 122px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 16px;
  padding-right: 4px;
  box-sizing: border-box;
  position: relative;
  transition: 0.2s;

  & > * {
    pointer-events: none;
  }

  & > svg {
    margin-right: 16px;
    flex-shrink: 0;
  }

  &:hover {
    box-shadow: 0 0 9px rgba(72, 114, 242, 0.64),
      0 5px 10px rgba(72, 114, 242, 0.05);
  }

  @media (max-width: 1169px) {
    padding: 0;
    box-shadow: none;
    width: auto;
    height: auto;
    display: block;
    text-align: center;

    &:hover {
      box-shadow: none;

      & > svg {
        border-radius: 20px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      }
    }

    & > svg {
      width: 112px;
      height: 112px;
      margin-right: 0;
    }
  }
`;

const LinkDescription = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 1169px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const WhatYouShouldKnow: React.FC<Props> = (props) => {
  const isTrain = props.type === 'train';
  const isAvia = props.type === 'avia';

  const AviaItems = () => {
    return (
      <Flex>
        <Link to="/questions/how-to-checkout">
          <PaymentMethodIcon />
          <LinkDescription>
            Оплата <br />
            заказа{' '}
          </LinkDescription>
        </Link>

        <Link to="/questions/how-to-book-ticket">
          <BookingRulesIcon />
          <LinkDescription>Правила бронирования</LinkDescription>
        </Link>
        <Link to="/questions/how-to-exchange-or-refund-order">
          <ExchangeAndRefundIcon />
          <LinkDescription>
            Обмен и <br /> возврат билета{' '}
          </LinkDescription>
        </Link>
        <Link to="/info/contacts">
          <AviaContactsIcon />
          <LinkDescription>Контакты авиаотдела </LinkDescription>
        </Link>
        <Link to="/questions/how-to-create-support-ticket">
          <OrderHelpIcon />
          <LinkDescription>Помощь по заказу </LinkDescription>
        </Link>
        <Link to="/questions">
          <TransferRulesIcon />
          <LinkDescription>Ответы на частые вопросы</LinkDescription>
        </Link>
        <Link to="/info/termsOfUse">
          <UserAgreementIcon />
          <LinkDescription>Соглашение пользователя </LinkDescription>
        </Link>
        <Link to="/info/agentsCooperation">
          <PartnershipIcon />
          <LinkDescription>Сотрудничество агентам </LinkDescription>
        </Link>
      </Flex>
    );
  };

  const TrainItems = () => {
    return (
      <Flex>
        <Link to="/questions-train/how-to-book-ticket-train">
          <BookingRulesTrainIcon />
          <LinkDescription>
            Бронирование <br />
            билетов
          </LinkDescription>
        </Link>

        <Link to="/questions-train/payment-methods">
          <PaymentMethodIcon />
          <LinkDescription>
            Оплата <br />
            заказа
          </LinkDescription>
        </Link>

        <Link to="/questions-train/open-sales-train-ticket">
          <ExchangeAndRefundIcon />
          <LinkDescription>
            Открытие <br />
            продажи
          </LinkDescription>
        </Link>

        <Link to="/questions-train/children-rules">
          <ChildrenRulesIcon />
          <LinkDescription>
            Правила <br /> проезда детей
          </LinkDescription>
        </Link>

        <Link to="/questions-train/return-policy">
          <ReturnTicketIcon />
          <LinkDescription>
            Возврат <br /> билетов
          </LinkDescription>
        </Link>

        <Link to="/questions-train/discounts">
          <DiscountsIcon />
          <LinkDescription>Скидки и акции</LinkDescription>
        </Link>

        <Link to="/info/contacts">
          <AviaContactsIcon />
          <LinkDescription>Контакты организации </LinkDescription>
        </Link>

        <Link to="/info/termsOfUse">
          <UserAgreementIcon />
          <LinkDescription>Соглашение пользователя </LinkDescription>
        </Link>
      </Flex>
    );
  };

  return (
    <Container>
      <Title>Всё, что нужно знать</Title>
      <FlexWrapper>
        {isAvia && <AviaItems />}
        {isTrain && <TrainItems />}
      </FlexWrapper>
    </Container>
  );
};
