import { TrainDirectionType } from '@modules/trainTickets';
import { Api } from '@utils';
import { normalizeTicketsList } from './normalizers';
import moment from 'moment';
import _ from 'lodash';
import {
  GetBusRoutePayload,
  BusRaceDetails,
  Bus,
  BusFilter,
  BusFilterItem,
  BusRoute,
  GetBusesTicketsListDto,
  Station,
} from './types';
import { CONTENT_TYPE, STRATEGY_TYPES } from '@app/utils/fetch';

const BUS_API_URL = process.env.REACT_APP_BUS_SERVICE_HOST as string;
const BUS_SEARCH_BUS = process.env.REACT_APP_BUS_SEARCH as string;
const BUS_ROUTE_DETAILS = process.env.REACT_APP_BUS_ROUTE_DETAILS as string;

export const getBusTicketsList = function (params: any): Promise<any> {
  let strSearchParams = `/${params.From}-${params.To}/${params.DepartureDate}/`;
  if (params.DepartureDateBack) {
    strSearchParams += `${params.To}-${params.From}/${params.DepartureDateBack}/`;
  }
  strSearchParams += `${params.Adults}-${params.Children}-${params.Infants}`;
  const getTicketsLink = Api.createFetchUrl(BUS_API_URL, BUS_SEARCH_BUS);
  const url = getTicketsLink(strSearchParams);
  return Api.request({
    url: url,
  })
    .then(Api.statuses)
    .then(Api.json)
    .then(normalizeTicketsList)
    .then((data) => data);
};

export function createBusFilter(data: GetBusesTicketsListDto): BusFilter[] {
  //
  const filter = [
    createBusFilterOnDestination(data.busesPerRoutes[0], 'forward'),
  ];
  if (data.busesPerRoutes?.length > 1) {
    filter.push(
      createBusFilterOnDestination(data.busesPerRoutes[1], 'backward'),
    );
  }

  return filter;
}

function createBusFilterOnDestination(
  busRoute: BusRoute,
  type: TrainDirectionType,
): BusFilter {
  let prices: number[] = [];
  const ticketPrices: number[][] = [];
  let busDurations: any = [
    {
      from: {
        city: busRoute?.stationFrom?.nameRu || '',
        label: busRoute?.stationFrom?.nameRu || '',
        min: 0,
        max: 0,
        tickets: [],
      },
      to: {
        city: busRoute?.stationTo?.nameRu,
        label: busRoute?.stationTo?.nameRu,
        min: 0,
        max: 0,
        tickets: [],
      },
      max: 0,
      min: 0,
      tickets: [],
    },
  ];
  let carriers: BusFilterItem[] = [];
  const carriersValues: { [key: string]: boolean } = {};
  const carriersTickets: string[][] = [];

  const trainServices: any[] = [];
  const trainServicesValues: { [key: string]: boolean } = {};
  const trainServicesTickets: string[][] = [];

  let discounts: any[] = [];
  const discountsValues: { [key: string]: boolean } = {};
  const discountsTickets: string[][] = [];

  let departureDates: any[] = [];
  let arrivalDates: number[] = [];
  const timeArr: any[] = [];

  timeArr.push({
    from: {
      city: busRoute?.stationFrom?.nameRu || '',
      label: busRoute?.stationFrom?.nameRu || '',
      min: 0,
      max: 0,
      tickets: [],
      values: [],
    },
    to: {
      city: busRoute?.stationTo?.nameRu,
      label: busRoute?.stationTo?.nameRu,
      min: 0,
      max: 0,
      tickets: [],
      values: [],
    },
  });

  const stations: {
    label: string;
    stations: { [key: string]: Station };
    tickets: string[];
  }[] = [];

  const filteredBusesOrigin = _(busRoute.buses)
    .groupBy('originStationName')
    .mapValues((x) => x.map((y) => y.raceId))
    .value();

  const filteredBusesDestination = _(busRoute.buses)
    .groupBy('destinationStationName')
    .mapValues((x) => x.map((y) => y.raceId))
    .value();

  const buses = busRoute.buses;
  buses.forEach((bus: Bus, key: number) => {
    // console.log(train, 'bus(train) in filter');
    busDurations[0].tickets[key] = bus.durationInMinutes;

    if (!discountsTickets[key]) {
      discountsTickets[key] = [];
    }
    if (!ticketPrices[key]) {
      ticketPrices[key] = [];
    }
    if (!carriersTickets[key]) {
      carriersTickets[key] = [];
      carriersTickets[key].push(bus.carrier);
    }

    const count = 0;

    timeArr[0].from.tickets.push(moment(bus.departureDateTime).unix());
    timeArr[0].to.tickets.push(moment(bus.arrivalDateTime).unix());

    if (!stations[count]) {
      stations[count] = {
        label: 'Выезд из ' + busRoute?.stationFrom?.nameRu,
        stations: {},
        tickets: [],
      };
      stations[count + 1] = {
        label: 'Прибытие в ' + busRoute?.stationTo?.nameRu,
        stations: {},
        tickets: [],
      };
    }

    stations[count].stations[bus.originStationCode] = {
      name: bus.originStationName,
      code: bus.originStationCode,
      checked: false,
      raceIds: filteredBusesOrigin[bus.originStationName],
    };

    stations[count].tickets.push(bus.raceId);

    stations[count + 1].stations[bus.destinationStationCode] = {
      name: bus.destinationStationName,
      code: bus.destinationStationCode,
      checked: false,
      raceIds: filteredBusesDestination[bus.destinationStationName],
    };
    stations[count + 1].tickets.push(bus.raceId);

    // stations[count + 1].tickets.push(to.code);
    // count += 2;
    // if (flight.stops.length > stopsCount) {
    //   stopsCount = flight.stops.length;
    // }

    prices.push(bus.price);
    ticketPrices[key].push(bus.price);

    carriers.push({
      value: bus.carrier,
      label: bus.carrier,
      checked: false,
    });
  });

  busDurations = busDurations.map((duration: any) => {
    return {
      from: duration.from,
      to: duration.to,
      min: Math.min(...duration.tickets),
      max: Math.max(...duration.tickets),
      tickets: duration.tickets,
    };
  });

  prices = _.uniq(prices);
  const updatedPrices = ticketPrices.map((trainPrice) => {
    return [Math.min(...trainPrice), Math.max(...trainPrice)];
  });
  busDurations = _.uniq(busDurations);

  carriers = _.uniqBy(carriers, (carrier) => carrier.value);

  discounts = _.uniqBy(discounts, (discount) => discount.value);
  departureDates = _.uniq(departureDates);
  arrivalDates = _.uniq(arrivalDates);

  trainServices.forEach((trainService) => {
    trainServicesValues[trainService.value] = false;
  });

  carriers.forEach((carrier) => {
    carriersValues[carrier.value] = false;
  });

  discounts.forEach((discount) => {
    discountsValues[discount.value] = false;
  });

  const times: any[] = timeArr.map((el) => {
    const fromMax = Math.max(...el.from.tickets);
    const fromMin = Math.min(...el.from.tickets);
    const toMax = Math.max(...el.to.tickets);
    const toMin = Math.min(...el.to.tickets);
    return {
      ...el,
      from: {
        ...el.from,
        min: fromMin,
        max: fromMax,
        values: [fromMin, fromMax],
      },
      to: {
        ...el.to,
        min: toMin,
        max: toMax,
        values: [toMin, toMax],
      },
    };
  });

  return {
    carriers: carriers,
    carriersTickets: carriersTickets,

    discounts: discounts,
    filterDestinationType: type,
    isFiltered: false,
    prices: {
      min: Math.min(...prices),
      max: Math.max(...prices),
      tickets: updatedPrices,
    },
    times: times,
    busDurationsList: busDurations,

    trainServices: trainServices,
    trainServicesTickets: trainServicesTickets,
    stations: stations.map((station) => {
      return {
        ...station,
        stations: Object.entries(station.stations).map(([_, val]) => val),
      };
    }),
    values: {
      stations: stations.map((station) => []),
      arrivalDates: [Math.min(...arrivalDates), Math.max(...arrivalDates)],
      carriers: carriersValues,
      departureDates: [
        moment(Math.min(...departureDates)).minutes(),
        moment(Math.max(...departureDates)).minutes(),
      ],
      discounts: discountsValues,
      prices: [Math.min(...prices), Math.max(...prices)],
      busServices: trainServicesValues,
      times: [
        Math.min(...busDurations[0].tickets),
        Math.max(...busDurations[0].tickets),
      ],
      busDurations: [
        Math.min(...busDurations[0].tickets),
        Math.max(...busDurations[0].tickets),
      ],
    },
    isDefault: {
      carriers: true,
      discounts: true,
      prices: true,
      times: true,
      busDurations: true,
      busServices: true,
      busTypes: true,
      stations: true,
    },
  };
}

export const getBusRouteDetails = (
  payload: GetBusRoutePayload,
): Promise<BusRaceDetails> => {
  const toQueryString = (data: GetBusRoutePayload): string => {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(data)) {
      params.append(key, value);
    }

    return params.toString();
  };

  const url = `${BUS_API_URL}${BUS_ROUTE_DETAILS}?${toQueryString(payload)}`;

  return Api.request(
    {
      url: url,
      method: 'GET',
      headers: { 'Content-Type': CONTENT_TYPE.JSON },
    },
    STRATEGY_TYPES.AUTH,
  )
    .then(Api.statuses)
    .then(Api.jsonBooking);
};
