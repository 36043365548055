import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.781 6.21897C17.8508 6.28864 17.9062 6.3714 17.944 6.46252C17.9818 6.55363 18.0013 6.65132 18.0013 6.74997C18.0013 6.84862 17.9818 6.9463 17.944 7.03742C17.9062 7.12854 17.8508 7.2113 17.781 7.28097L7.28097 17.781C7.14014 17.9218 6.94913 18.0009 6.74997 18.0009C6.55081 18.0009 6.3598 17.9218 6.21897 17.781C6.07814 17.6401 5.99902 17.4491 5.99902 17.25C5.99902 17.0508 6.07814 16.8598 6.21897 16.719L16.719 6.21897C16.7886 6.14912 16.8714 6.09371 16.9625 6.0559C17.0536 6.01809 17.1513 5.99863 17.25 5.99863C17.3486 5.99863 17.4463 6.01809 17.5374 6.0559C17.6285 6.09371 17.7113 6.14912 17.781 6.21897Z"
        fill="#737373"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.21912 6.21897C6.14928 6.28864 6.09386 6.3714 6.05605 6.46252C6.01824 6.55363 5.99878 6.65132 5.99878 6.74997C5.99878 6.84862 6.01824 6.9463 6.05605 7.03742C6.09386 7.12854 6.14928 7.2113 6.21912 7.28097L16.7191 17.781C16.8599 17.9218 17.051 18.0009 17.2501 18.0009C17.4493 18.0009 17.6403 17.9218 17.7811 17.781C17.9219 17.6401 18.0011 17.4491 18.0011 17.25C18.0011 17.0508 17.9219 16.8598 17.7811 16.719L7.28112 6.21897C7.21145 6.14912 7.12869 6.09371 7.03757 6.0559C6.94645 6.01809 6.84877 5.99863 6.75012 5.99863C6.65147 5.99863 6.55379 6.01809 6.46267 6.0559C6.37155 6.09371 6.28879 6.14912 6.21912 6.21897Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Logo;
