import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Category from '../../flightsSearch/Filter/Category';
import { CheckboxGroup, TimeRange, PriceRange } from '@components/ui';
import TimeRangeWithPlane from '@components/flightsSearch/Filter/TimeRangeWithPlane';
import DateRangeWithPlane from '@components/flightsSearch/Filter/DateRangeWithPlane';

import styled from 'styled-components';
import {
  setTimeOneWayFilter,
  setTransferDurationFilter,
  setPricesFilter,
  resetFilter,
  setAirportFilter,
  setAirlineFilter,
  setTransferFilter,
  setTimeFilter,
  setBaggageFilter,
  setFlightTypeFilter,
} from '@modules/main';
import { Helper } from '@utils';
import { ApplicationState } from '@modules/index';
import {
  BusFilter,
  setBusSort,
  setCarrierBusFilter,
  setDurationBusFilter,
  setPricesBusFilter,
  setStationBusFilter,
  setTimeBusFilter,
} from '@modules/busTickets';
import { TrainFilter } from '@modules/trainTickets';
import {
  busCurrentSearchStepSelector,
  busFilterSelector,
} from '@modules/busTickets/selectors';

const { pluralWord } = Helper;

const Wrapper = styled.ul`
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-right: 30px;

  & > :first-child {
    padding-top: 0;
  }

  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const FilterBuses: React.FC = () => {
  const currentStep = useSelector(busCurrentSearchStepSelector);

  const useCurrentBusFilter = (): BusFilter => {
    const filter = useSelector(busFilterSelector);
    const isForward = currentStep === 'TICKET_FORWARD';
    return isForward ? filter[0] : filter[1];
  };

  const activeFilters = useCurrentBusFilter();

  if (!activeFilters) return null;

  const memoizedStations: {
    name: string;
    values: { value: string; label: string; checked: boolean }[];
  }[] = useMemo(
    () =>
      activeFilters?.stations.map(({ label, stations }) => {
        return {
          name: label,
          values: stations.map((el) => {
            return { value: el.code, label: el.name, checked: el.checked };
          }),
        };
      }),
    [activeFilters?.stations],
  );

  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Category
        name="Цена билета"
        active
        // resetAvailable={!isDefault.prices}
        onReset={() => dispatch(resetFilter('prices'))}
      >
        <PriceRange
          min={activeFilters.prices.min}
          max={activeFilters.prices.max}
          values={activeFilters.values.prices}
          onAfterChange={(values) => {
            dispatch(
              setPricesBusFilter({
                values: values,
                type: activeFilters.filterDestinationType,
              }),
            );
            dispatch(setBusSort('TIME_ARRIVAL'));
          }}
        />
      </Category>

      <Category
        name="Перевозчик"
        // resetAvailable={!isDefault.airlines}
        onReset={() => dispatch(resetFilter('сarrier'))}
      >
        <CheckboxGroup
          onChange={(val) => {
            const values = val as { [key: string]: boolean };
            dispatch(
              setCarrierBusFilter({
                type: activeFilters.filterDestinationType,
                values: values,
              }),
            );
          }}
          groupType="default"
          type="squar"
          // scrollContent
          values={activeFilters.carriers}
        />
      </Category>

      <Category
        name="Время в пути"
        // resetAvailable={!isDefault.trainDurations}
        // onReset={() => {
        //   dispatch(
        //     resetTrainFilter({
        //       value: 'trainDuration',
        //       type: filterDestinationType,
        //     })
        //   );
        // }}
      >
        <TimeRangeWithPlane
          type={'bus'}
          from={activeFilters.busDurationsList[0].from}
          to={activeFilters.busDurationsList[0].to}
          min={activeFilters.busDurationsList[0].min}
          max={activeFilters.busDurationsList[0].max}
          values={activeFilters.values.busDurations}
          onAfterChange={(values) => {
            dispatch(
              setDurationBusFilter({
                values: values,
                type: activeFilters.filterDestinationType,
              }),
            );
          }}
        />
      </Category>
      <Category
        name="Время отправления и прибытия"
        // resetAvailable={!isDefault.times}
        onReset={() => {
          dispatch(resetFilter('times'));
        }}
      >
        <DateRangeWithPlane
          noIcon
          type={'bus'}
          from={activeFilters.times[0].from}
          to={activeFilters.times[0].to}
          onChange={(type, values) => {
            dispatch(
              setTimeBusFilter({
                values: values,
                timeType: type,
                type: activeFilters.filterDestinationType,
              }),
            );
          }}
        />
      </Category>
      <Category
        name="Станции"
        // resetAvailable={!isDefault.airport}
        onReset={() => dispatch(resetFilter('airport'))}
      >
        <CheckboxGroup
          onChange={(values) =>
            dispatch(
              setStationBusFilter({
                values: values,
                type: activeFilters.filterDestinationType,
              }),
            )
          }
          groupType="subgroups"
          type="squar"
          scrollSubGroup
          values={memoizedStations}
        />
      </Category>
    </Wrapper>
  );
};

export default FilterBuses;
