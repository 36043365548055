import { ApplicationState } from '..';

export const busBookingForwardSelector = (state: ApplicationState) =>
  state.busBooking.forward;

export const busBookingBackwardSelector = (state: ApplicationState) =>
  state.busBooking.backward;

export const busBookingStatusSelector = (state: ApplicationState) =>
  state.busBooking.currentStatus;

export const busBookingPlacesSelector = (state: ApplicationState) =>
  state.busBooking.places;
