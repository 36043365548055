import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 14px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const Heading = styled.h3`
  margin: 0 0 14px 0;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }
`;

export const Route = styled.h3`
  margin: 0 0 20px 0;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Open Sans';

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Ticket = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const RowContainer = styled.div`
  margin-bottom: 26px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 26px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Passenger = styled.p`
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Open Sans';

  svg {
    margin-right: 8px;
    width: 25px;
    height: 25px;
  }
  span {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;

    span {
      max-width: 100%;
    }
  }
`;

export const Info = styled.div`
  padding: 10px;
  background-color: #fff3cd;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 5px;

  @media (min-width: 768px) {
    padding: 14px;
  }

  svg {
    width: 33px;
    height: 33px;
    position: relative;
    top: -5px;

    path {
      stroke: #3c3c3c;
    }
  }
`;

export const Link = styled.span`
  color: #4872f2;
`;
