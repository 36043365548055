import styled from 'styled-components';
import Text from '@components/ui/Typography';
import { Field } from '@components/ui';
import cross from '@assets/media/booking/cross.svg';

interface LinkProps {
  isSamo: boolean;
}

export const Wrapper = styled.div`
  position: relative;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const SubLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 0;
`;

export const SubLabelMarginTop = styled(SubLabel)`
  margin-top: 6px;
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: contents;
`;

export const LabelSmallFontSize = styled(Label)`
  font-size: 12px;
`;

export const FormBlockNew = styled.div<LinkProps>`
  display: grid;
  grid-template-areas: ${({ isSamo }) =>
  isSamo
    ? `'lastName field-2 field-3'
  'field-69 . field-4'
  'field-5 field-6 . '
  'field-7 field-8 field-8 '
  'field-9 field-10 field-12  '`
    : `'lastName field-2 field-3'
  'field-69 . field-4'
  'field-5 field-6 . '
  'field-7 field-8 field-8 '
  ". . ."
  'field-9  field-12 . '
  'field-13  field-14 field-14 '`};
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;

  .custom-input {
    width: 100%;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 30px;
    grid-template-areas:
      'field-6'
      'lastName'
      'field-2'
      'field-3'
      'field-4'
      'field-5'
      'field-7'
      'field-8'
      'field-9'
      'field-10'
      'field-12'
      'field-13'
      'field-14';
  }

  & .lastName {
    grid-area: lastName;
  }

  & .field-2 {
    grid-area: field-2;
  }

  & .field-3 {
    grid-area: field-3;
  }

  & .field-4 {
    grid-area: field-4;
  }

  & .field-5 {
    grid-area: field-5;
  }

  & .field-6 {
    grid-area: field-6;
    @media (max-width: 767px) {
      height: 56px;
    }
  }

  & .field-7 {
    grid-area: field-7;
  }

  & .field-8 {
    grid-area: field-8;
  }

  & .field-9 {
    grid-area: field-9;
  }

  & .field-10 {
    grid-area: field-10;
  }

  & .field-12 {
      grid-area: field-12;
  }
`;

export const AddPassengers = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  text-align: right;
  color: #4872f2;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => `
    ${theme.max('780px')} {
      margin-left:0px;
      margin-top: 15px;
    }`}
`;

export const LoyaltyBlock = styled.div<{ checked: boolean }>`
  display: grid;
  grid-template-columns: 215px 256px;
  column-gap: 15px;
  row-gap: 30px;
  margin-top: 10px;

  @media (max-width: 1023px) {
    margin-top: 15px;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
    display: block;
    & > div {
      margin-bottom: 30px;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  & .lastName {
    grid-area: lastName;
  }

  & .field-2 {
    grid-area: field-2;
  }

  & .field-3 {
    grid-area: field-3;
  }
`;

export const StyledField = styled(Field)`
  width: 100%;
  position: relative;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

export const FioBLock = styled.div`
  & > :first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

export const AbsolutePassengersList = styled.div`
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #3c3c3c;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  max-height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;

  box-shadow: rgba(117, 122, 136, 0.19) 0px 4px -12px 5px;
  width: 100%;
`;

export const AbsoluteItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 58px;
  cursor: pointer;
  padding: 5px;

  :hover {
    background-color: #f1f4ff;
  }
`;

export const AbsoluteSubText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #737373;
  margin-bottom: 10px;
`;

export const CrossRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CrossBlock = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: #edf1fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  margin-left: 10px;
  cursor: pointer;
`;

export const HandleErrorText = styled.div`
  font-size: 11px;
  color: #e73c3e;
  line-height: 16px;
`;

export const MarginBottomBlock = styled.div`
  margin-bottom: 10px;
`;

export const MarginTopBlock = styled.div`
  margin-top: 5px;
`;


export const ColumnFlexBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionText = styled.div`
  font-size: 12px;
  color: #737373;
`;

export const DescriptionTextItalic = styled(DescriptionText)`
  font-style: italic;
  padding-top: 7px;
`;

export const BlueText = styled.span`
  color: #4872F2;
`

export const Cross = styled.img.attrs({ src: cross })``;