import React from 'react';
import styled from 'styled-components';

import { RoundIcon } from '@components/loader';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 100;
`;

const CreateFormPopup: React.FC = () => (
  <LoaderWrapper>
    <RoundIcon />
  </LoaderWrapper>
);

export default CreateFormPopup;
