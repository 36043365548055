import React from 'react';
import styled from 'styled-components';

interface LinkProps {
  isDesktop: boolean;
  isTablet: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Iframe = styled.iframe<LinkProps>`
  max-width: 800px;
  border: none;
  height: 100%;
  min-height: 600px;
  min-width: ${({ isDesktop, isTablet }) =>
    isDesktop ? '1200px' : isTablet ? '770px' : '425px'};
`;

const Button = styled.div`
  font-weight: bold;
  margin-right: 30px;
  cursor: pointer;
`;
const IframeTest: React.FC = () => {
  const [chosenScreen, setChosenScreen] = React.useState('Desktop');

  setTimeout(() => {
    (
      document.getElementById('IframeTest') as HTMLIFrameElement
    )?.contentWindow?.postMessage(
      { token: '28680b9c-29a0-478a-8c7a-cf86d5a77dd4' },
      '*'
    );
  }, 10000);

  return (
    <Wrapper>
      <Container>
        <Button onClick={() => setChosenScreen('Desktop')}>Декстоп</Button>
        <Button onClick={() => setChosenScreen('Tablet')}>Планшет</Button>
        <Button onClick={() => setChosenScreen('Mobile')}>Мобилка</Button>
      </Container>
      <Iframe
        id="IframeTest"
        isDesktop={chosenScreen === 'Desktop'}
        isTablet={chosenScreen === 'Tablet'}
        src={'http://whitelabel.tvm-tte-k8s.fstravel.com/?origin=MOW'}
        loading="lazy"
      />
      <Container></Container>
    </Wrapper>
  );
};

export default IframeTest;
