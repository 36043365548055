import { put, call, select } from 'typed-redux-saga';
import { Action } from 'redux-act';
import { BookFlightPayload, SignInPayload } from '../../types';
import { signInWithouRedirect } from '@modules/user';
import { showNotification } from '@modules/notification';
import { bookTicket } from '../workers';
import { setStatus, setAuthPopup } from '../../duck';
import { getIsMobileState, getTempData } from '../../selectors';
import * as Sentry from '@sentry/react';

/**
 * Sign in user and book order on success
 * @param param0 - data required for sign user
 */
export function* signInFlow({ payload }: Action<SignInPayload>) {
  yield* put(setAuthPopup(false));
  yield put(setStatus(true));
  try {
    const tempData = yield* select(getTempData);
    const isSuccess = yield* call(signInWithouRedirect, payload);

    if (isSuccess) {
      try {
        yield* put(setAuthPopup(false));

        yield* call(bookTicket, tempData as BookFlightPayload);
      } catch (e) {
        Sentry.captureException(e);
        yield* put(showNotification({ code: e.name, message: e.message }));
      }
    } else {
      yield* put(setAuthPopup(true));
      throw new Error('Ошибка');
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(setStatus(false));
    yield* put(setAuthPopup(true));
  }
  yield put(setStatus(false));
}
