import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3333 18.7773H1M21 18.7773H18.7778H21Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3335 18.7779C14.3335 20.0052 15.3284 21.0001 16.5557 21.0001C17.783 21.0001 18.7779 20.0052 18.7779 18.7779C18.7779 17.5506 17.783 16.5557 16.5557 16.5557C15.3284 16.5557 14.3335 17.5506 14.3335 18.7779Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11H3.22222H1ZM7.66667 11H21H7.66667Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.44439 13.2218C6.67169 13.2218 7.66661 12.2269 7.66661 10.9996C7.66661 9.77227 6.67169 8.77734 5.44439 8.77734C4.21709 8.77734 3.22217 9.77227 3.22217 10.9996C3.22217 12.2269 4.21709 13.2218 5.44439 13.2218Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 3.22266L1 3.22266M21 3.22266H18.7778H21Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5557 5.44444C17.783 5.44444 18.7779 4.44952 18.7779 3.22222C18.7779 1.99492 17.783 1 16.5557 1C15.3284 1 14.3335 1.99492 14.3335 3.22222C14.3335 4.44952 15.3284 5.44444 16.5557 5.44444Z"
        fill="white"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logo;
