import styled from 'styled-components';
import * as Styled from './Default.style';
import { Text } from '@components/ui';

export const Container = styled.div``;

export const Title = styled(Styled.Title)`
  font-size: 18px;
  line-height: 26px;
`;

export const SubTitle = styled(Styled.Title)`
  display: block;
  margin-top: 24px;
  font-size: 16px;
  line-height: 22px;
`;

export const Position = styled.div<{
  isFewPositions: boolean;
  isBorder: boolean;
}>`
  padding-top: ${({ isBorder, isFewPositions }) =>
    isFewPositions && !isBorder && `16px`};
  padding-bottom: ${({ isBorder }) => isBorder && `24px`};
  border-bottom: ${({ isBorder }) => isBorder && `1px solid #dbe0e5`};
`;

export const Info = styled.ul`
  margin: unset;
  padding: unset;
  list-style: none;
  margin-bottom: 18px;
`;

export const InfoItem = styled.li`
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const InfoLabel = styled(Text)`
  margin-right: 4px;
  color: #7e8389;
`;

export const InfoValue = styled(Text)`
  font-family: unset;
  font-size: 16px;
  color: #2e2e32;
`;

export const InfoValueStrong = styled(Text)`
  font-family: unset;
  font-size: 16px;
  font-weight: 600;
  color: #4872f2;
`;

export const Table = styled.div`
  background: #edf1fe;
  border-radius: 8px;
  padding: 16px;
`;

export const TableHeader = styled.div`
  margin-bottom: 8px;
`;
export const TableBody = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
  margin-bottom: 16px;
`;
export const TableFooter = styled.div`
  border-top: 1px solid #dbe0e5;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TableTitle = styled(Text)`
  font-size: clamp(14px, 4vw, 16px);
  font-weight: 600;
  color: #2e2e32;
`;

export const TableData = styled(Text)`
  font-family: unset;
  font-size: clamp(14px, 4vw, 16px);
`;

export const TableDataFooter = styled(TableTitle)`
  font-family: unset;
  margin-top: 16px;
`;

export const Total = styled(Text)`
  display: inline-block;
  margin-top: 40px;
  font-family: unset;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const CheckBoxWrapper = styled.div`
  margin-top: 21.7px;
  margin-bottom: 28px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Cost = styled.span`
  white-space: nowrap;
`;
