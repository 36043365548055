import React from 'react';
import styled, { css } from 'styled-components';
import { Text, Button } from '@components/ui';
import { AgentPayModal } from '@components/common/Modals';
import { HeadProps } from './types';
import { Helper } from '@utils';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  issueTicketsRequest,
  issueTicketsPurify,
  showModalError,
  issueOrderPositionRequest,
  getDetailOrder,
  toggleCanPay,
} from '@modules/orders';
import { getAgentOrderBillRequest } from '@modules/booking';
import { getAquiringCommission } from '@modules/agents/agent-finance/duck';
import { ApplicationState } from '@modules/index';
import AgentPayments from './AgentPayments';
import arrowSrc from '@assets/media/order-detail/arrow.png';
import IssueModal from '@components/common/Modals/AgentPayModal/IssueModal';
import ErrorModal from '@components/common/Modals/ErrorModal';
import clock from '@assets/media/order-detail/clock.svg';
import okIcon from '@assets/media/order-detail/okIcon.svg';
import commissionEye from '@assets/media/booking/commissionEye.svg';
import commissionEyeOpen from '@assets/media/booking/commissionEyeOpen.svg';

import { setCommissionStatus } from '@modules/user';
import { cancelOrderPopupToggle } from '@modules/trainOrders';

const gridStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 802px;

  .user {
    order: 4;
  }

  .email {
    order: 9;
  }

  .price {
    order: 1;
  }

  .debt {
    order: 6;
  }

  .status {
    order: 3;
  }

  .backoffice {
    order: 2;
  }

  .agentPrice {
    order: 11;
  }

  .status2 {
    order: 3;
  }
`;

const Wrapper = styled.div<{ yellow: boolean }>`
  background: ${({ yellow }) => (yellow ? '#EDF1FE' : '#EDF1FE')};
  color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid
    ${({ theme, yellow }) =>
      yellow ? theme.colors.lightGray : theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  @media (max-width: 767px) {
    padding: 14px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled.div`
  text-align: left;

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  @media (max-width: 1140px) {
    ${Text} {
      font-size: 14px;
      font-weight: normal;
    }

    & > :first-child {
      font-weight: 600;
    }

    margin-bottom: 5px;
  }
`;

const DescriptionBlock = styled.div<{ grid: boolean }>`
  text-align: left;

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  ${({ grid }) => grid && gridStyles};
    
  @media (max-width: 1140px) {
    ${Text} {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

const RightSide = styled.div`
  text-align: right;

  & > span {
    display: block;
  }

  @media (max-width: 1140px) {
    text-align: left;
    & > span {
      font-weight: normal;
      display: initial;
    }

    & > :last-child {
      color: #3c3c3c;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Row = styled(Text).attrs({ fontWeight: 600, color: '#737373' })`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > :first-child {
    font-weight: 600;
  }
`;

const MobileText = styled(Text)`
  display: none;
  @media (max-width: 1140px) {
    display: inline;
  }
`;

const PaymentButton = styled(Button).attrs({
  type: 'primary',
})`
  font-size: 16px;
  line-height: 24px;
  padding: 8px 25px;
  font-style: normal;
  font-weight: 600;
  display: block;
  min-width: 183px;
  align-self: flex-start;
`;

const AgentNewButton = styled.div`
  border: 1px solid #4872f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4872f2;
  padding: 10px 24px;
  cursor: pointer;
`;

const IssueButton = styled.div<LinkProps>`
  background: ${({ isActive }) => (isActive ? '#FFE100' : '#dedede')};
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  color: #3c3c3c;
  margin-left: 20px;
  padding: 10px 20px;
  cursor: pointer;
  max-width: 178px;
  max-height: 38px;
`;

const ExtraPaymentBlock = styled.div`
  border-top: 1px solid #dbe0e5;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PaymentNewBlock = styled.div`
  border-top: 1px solid #dbe0e5;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  align-items: center;
`;

const ExpirationText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }
`;

const ShowAgentPaymentButton = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  display: block;

  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  margin-bottom: 15px;
  cursor: pointer;

  position: relative;
`;

const ShowCommissionBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #4872f2;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const CommissionEye = styled.img.attrs({ src: commissionEye })`
  margin-right: 5px;
`;

const CommissionEyeOpen = styled.img.attrs({ src: commissionEyeOpen })`
  margin-right: 5px;
`;

const Arrow = styled.img.attrs({ src: arrowSrc, width: 10, height: 5 })`
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
`;

const ClockImage = styled.img.attrs({ src: clock })`
  margin-right: 5px;
  width: 20px;
`;

const OkImage = styled.img.attrs({ src: okIcon })`
  margin-right: 5px;
  width: 20px;
`;

const AgentPriceRow = styled.div`
  border: 1px solid #4872f2;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  margin: 7px 0 7px -10px;
`;

interface LinkProps {
  isActive: boolean;
}

const Head: React.FC<HeadProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const modalErrorVisible = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.modalErrorVisible,
  );
  const isShowCommission = useSelector(
    (state: ApplicationState) => state?.user?.userCommissionStatus,
  );
  const canPay = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.canPay,
  );
  const { totalDetalization } = useSelector(getDetailOrder);
  const agentDoPaymentProps = useSelector(
    (x: ApplicationState) =>
      x.orders.orderDetail.data.availableCreditPaymentMethods,
  );
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail);
  const issueStatus = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.issueStatus,
  );
  const totalAmount = useSelector(
    (x: ApplicationState) =>
      x.orders.orderDetail.activeCalculations.totalAmount,
  );

  const totalAmountWithSubAgentExtraCharge = useSelector(
    (x: ApplicationState) =>
      x.orders.orderDetail.activeCalculations
        .totalAmountWithSubAgentExtraCharge,
  );
  const user = useSelector((x: ApplicationState) => x.user);

  const [agentModal, setAgentModal] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [agentPaymentsOpen, setAgentPaymentsOpen] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [localCancellationTime, setLocalCancellationTime] = React.useState(
    dateToLocal(props.expiredTime),
  );

  const yellow = props.amountExtraPayment > 0;
  const extraPayment = totalAmount > 0;
  const duration = localCancellationTime.diff(moment(), 'minutes');
  const durationSeconds = localCancellationTime.diff(moment(), 'seconds');
  if (localCancellationTime && localCancellationTime.isValid() && durationSeconds === 0) {
    dispatch(toggleCanPay(false));
    window.location.reload();
  }

  const isTrainOrder = order?.data?.railwayOrderPositions?.length > 0;
  const isPaymentAvailable = props.availableActions.includes('PAYMENT');

  const payOrder = () => {
    if (user?.data?.agentId) {
      if (agentDoPaymentProps) {
        dispatch(getAquiringCommission({ AgentId: user?.data?.agentId }));
        setAgentModal(true);
      } else {
        history.push(`/redirect-payment?orderId=${props.orderNumber}`);
      }
    }
    if (!user?.data?.agentId) {
      const scrollElement = document.getElementById('PaymentDiv');
      scrollElement?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    if (props.expiredTime !== null) {
      const interval = setInterval(
        () => {
          setLocalCancellationTime(dateToLocal(props.expiredTime));
        },
        isTrainOrder ? 1000 : 60000,
      );
      return () => clearInterval(interval);
    }
  }, [props.expiredTime]);

  React.useEffect(() => {
    if (issueStatus !== null) {
      setIsModalOpen(true);
    }
  }, [issueStatus]);

  React.useEffect(() => {
    if (modalErrorVisible) {
      setShowErrorModal(true);
      dispatch(showModalError(false));
    }
  }, [modalErrorVisible]);

  const renderExpirationRow = (formattedDuration: string) => (
    <Row className="expiration" fontWeight="600" color="#737373">
      Необходимо оплатить заказ в течение:{' '}
      <strong style={{ color: '#4872F2' }}>{formattedDuration}</strong>
    </Row>
  );

  const renderPaymentButton = (canPay: boolean, onClick: () => void) => {
    return (
      canPay && (
        <PaymentButton color="yellow" onClick={onClick}>
          Оплатить заказ
        </PaymentButton>
      )
    );
  };

  return (
    <Wrapper yellow={yellow}>
      {showErrorModal && (
        <ErrorModal
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          issue={true}
        />
      )}
      <Flex>
        <LeftSide>
          <Text
            size={16}
            color="#3C3C3C"
            fontWeight="600"
            data-cy="orderNumberAndDateSpan"
          >
            Заказ № <span data-cy={'orderNumber'}>{props.orderNumber}</span> от{' '}
            {dateToLocal(props.createdDate).format('DD.MM.YYYY, HH:mm')}
          </Text>
          <DescriptionBlock grid={order?.data?.salesChannelType === 'b2b'}>
            {order?.data?.salesChannelType === 'b2b' && (
              <>
                <Row className="price" fontWeight="600" color="#737373">
                  Стоимость заказа:{' '}
                  <strong
                    data-cy={'orderPriceInOrderDetails'}
                    style={{ color: '#4872F2' }}
                  >
                    {Helper.formatPrice(
                      props.amount - totalAmountWithSubAgentExtraCharge,
                    )}
                  </strong>
                </Row>
                <Row className="debt" color="#737373">
                  Долг по заказу:{' '}
                  <strong
                    style={
                      order?.getOrderPayments?.orderDebt !== undefined &&
                      order?.getOrderPayments?.orderDebt > 0
                        ? { color: '#E73C3E' }
                        : {}
                    }
                  >
                    {Helper.formatPrice(
                      order?.getOrderPayments?.orderDebt || 0,
                    )}
                  </strong>
                </Row>
                <Row className="user" fontWeight="600" color="#737373">
                  Заказ создал(а):{' '}
                  <strong>
                    {order?.data?.customer.firstName
                      ? order?.data?.customer.firstName +
                        ' ' +
                        order?.data?.customer.lastName
                      : order?.data?.customer.email}
                  </strong>
                </Row>
              </>
            )}

            {!extraPayment && (
              <>
                <Row className="status2" fontWeight="600" color="#737373">
                  Статус оплаты:{' '}
                  <strong style={{ color: '#4872F2' }}>
                    {props.paymentStatus?.description}
                  </strong>
                </Row>
              </>
            )}

            {extraPayment && (
              <>
                {order?.data?.salesChannelType !== 'b2b' && (
                  <Row fontWeight="600" color="#737373">
                    Стоимость заказа:{' '}
                    <strong data-cy={'orderPriceInOrderDetails'}>
                      {Helper.formatPrice(props.amount - totalAmount)}
                    </strong>
                  </Row>
                )}
                <Row className="status" fontWeight="600" color="#737373">
                  Статус оплаты:{' '}
                  <strong>{props.paymentStatus?.description}</strong>
                </Row>

                <Row className="total-price" fontWeight="600" color="#737373">
                  Итоговая стоимость заказа после доплаты:{' '}
                  <strong>{Helper.formatPrice(props.amount)}</strong>
                </Row>
              </>
            )}
            {order?.data?.salesChannelType === 'b2b' &&
              order?.data?.customer && (
                <>
                  <Row className="email" fontWeight="600" color="#737373">
                    Email: <strong>{order?.data?.customer.email}</strong>
                  </Row>
                  <Row className="backoffice" fontWeight="600" color="#737373">
                    Номер для оплаты:{' '}
                    <strong data-cy={'orderDetailBackOfficeId'}>
                      {order?.data?.backOfficeId}
                    </strong>
                  </Row>
                </>
              )}
          </DescriptionBlock>
        </LeftSide>{' '}
        {!extraPayment && order?.data?.salesChannelType !== 'b2b' && (
          <RightSide>
            <Text fontWeight="600" color="#737373">
              Стоимость<MobileText> заказа</MobileText>:{' '}
            </Text>
            <Text size={18} fontWeight="600" color="blue">
              <span data-cy={'orderPriceInOrderDetails'}>
                {Helper.formatPrice(order?.data?.amount - totalAmount)}
              </span>
            </Text>
          </RightSide>
        )}
      </Flex>
      {isShowCommission && !isTrainOrder && user?.data?.agentId && (
        <AgentPriceRow className="agentPrice">
          <>
            <Row fontWeight="600" color="#737373" style={{ width: 401 }}>
              Стоимость для агента:{' '}
              <strong style={{ color: '#4872F2' }}>
                {totalDetalization?.agentTotal
                  ? Helper.formatPrice(totalDetalization?.agentTotal)
                  : ''}
              </strong>
            </Row>
            <Row fontWeight="600" color="#737373">
              Сбор агента:{' '}
              <strong style={{ color: '#4872F2' }}>
                {props?.agentPrice?.total
                  ? Helper.formatPrice(props?.agentPrice?.total)
                  : ''}
              </strong>
            </Row>
          </>
        </AgentPriceRow>
      )}
      {user?.data?.agentId && (
        <ShowCommissionBlock
          onClick={() => dispatch(setCommissionStatus(!isShowCommission))}
        >
          {!isShowCommission ? <CommissionEyeOpen /> : <CommissionEye />}{' '}
          <div>
            {!isShowCommission
              ? 'Открыть детали для агента'
              : 'Скрыть детали для агента'}{' '}
          </div>
        </ShowCommissionBlock>
      )}
      {!!order?.getOrderPayments &&
        order.getOrderPayments.payments !== null && (
          <>
            <ShowAgentPaymentButton
              onClick={() => setAgentPaymentsOpen((x) => !x)}
            >
              {agentPaymentsOpen ? 'Свернуть' : 'Посмотреть'} список платежей
              <Arrow />
            </ShowAgentPaymentButton>
            <AgentPayments
              open={agentPaymentsOpen}
              items={order.getOrderPayments.payments}
            />
          </>
        )}

      {(props.availableActions || []).includes('PAYMENT') &&
        order?.data?.orderStatus.code !== 'NotActive' &&
        !extraPayment &&
        order?.data?.salesChannel !== 'Собственная розница' &&
        order?.data?.salesChannel !== 'Самотур (GDS тур)' &&
        !props?.availableShipmentAllowed?.availableIssue && (
          <Flex style={{ marginTop: 20 }}>
            <div>
              {isPaymentAvailable && (
                <>
                  {isTrainOrder &&
                    canPay &&
                    renderExpirationRow(
                      Helper.formatDurationWithSeconds(durationSeconds),
                    )}
                  {!isTrainOrder &&
                    durationSeconds > 0 &&
                    renderExpirationRow(Helper.formatDuration(duration))}
                </>
              )}
            </div>
            <div>
              {isTrainOrder
                ? renderPaymentButton(canPay, payOrder)
                : renderPaymentButton(true, payOrder)}
            </div>
          </Flex>
        )}
      {isTrainOrder &&
        props.availableActions.includes('RAILWAY_ORDER_CANCEL') && (
          <Button
            type="outlined"
            onClick={() => dispatch(cancelOrderPopupToggle(true))}
          >
            Отменить заказ
          </Button>
        )}
      {(props.availableActions || []).includes('PAYMENT') &&
        !extraPayment &&
        order?.data?.salesChannel !== 'Собственная розница' &&
        props?.availableShipmentAllowed?.availableIssue &&
        user?.data?.agentId && (
          <PaymentNewBlock>
            <AgentNewButton onClick={payOrder}>
              Выбрать другой способ оплаты
            </AgentNewButton>
            <IssueButton
              onClick={() =>
                props?.availableShipmentAllowed?.ticketIssue === true
                  ? dispatch(
                      issueTicketsRequest({
                        orderId: props.orderId,
                        typePaid: 'credit',
                        userId: user?.data?.agentId,
                      }),
                    )
                  : null
              }
              isActive={props?.availableShipmentAllowed?.ticketIssue === true}
            >
              {props?.availableShipmentAllowed?.ticketIssue === false ? (
                <ClockImage />
              ) : (
                <OkImage />
              )}{' '}
              Выписать заказ
            </IssueButton>
          </PaymentNewBlock>
        )}
      {order?.data?.salesChannel == 'Самотур (GDS тур)' &&
        props?.availableShipmentAllowed?.ticketIssue === true &&
        order?.data?.orderStatus?.code === 'Active' && (
          <IssueButton
            style={{ marginLeft: 0, marginTop: 10 }}
            onClick={() =>
              props?.availableShipmentAllowed?.ticketIssue === true
                ? dispatch(
                    issueTicketsRequest({
                      orderId: props.orderId,
                      typePaid: 'credit',
                      userId: user?.data?.agentId,
                    }),
                  )
                : null
            }
            isActive={props?.availableShipmentAllowed?.ticketIssue === true}
          >
            <OkImage />
            Выписать заказ
          </IssueButton>
        )}
      {(props.availableActions || []).includes('PAYMENT') &&
        !extraPayment &&
        order?.data?.salesChannel === 'Собственная розница' && (
          <ExtraPaymentBlock>
            <PaymentButton
              color="yellow"
              onClick={() =>
                dispatch(
                  issueOrderPositionRequest({
                    orderNumber: order?.data.orderNumber,
                    orderPositionId: order?.data?.orderPositions[0].id,
                  }),
                )
              }
            >
              Выписать заказ
            </PaymentButton>
          </ExtraPaymentBlock>
        )}
      {extraPayment && (
        <ExtraPaymentBlock>
          {isTrainOrder
            ? !!durationSeconds &&
              canPay && (
                <ExpirationText>
                  Оплатите заказ в течении:{' '}
                  <strong>
                    {durationSeconds
                      ? Helper.formatDurationWithSeconds(durationSeconds)
                      : '-'}
                  </strong>
                </ExpirationText>
              )
            : !!durationSeconds && (
                <ExpirationText>
                  Оплатите заказ в течении:{' '}
                  <strong>
                    {durationSeconds ? Helper.formatDuration(duration) : '-'}
                  </strong>
                </ExpirationText>
              )}
          {/* <OnlyRusCardNotyBlue /> */}
          <PaymentButton
            color="yellow"
            onClick={payOrder}
            style={{ marginLeft: 20 }}
          >
            Доплатить {Helper.formatPrice(totalAmount)}
          </PaymentButton>
        </ExtraPaymentBlock>
      )}
      {isModalOpen && (
        <IssueModal
          open={isModalOpen}
          issueStatus={issueStatus}
          onClose={() => {
            setIsModalOpen(false);
            dispatch(issueTicketsPurify());
          }}
        />
      )}
      {!!agentDoPaymentProps && (
        <AgentPayModal
          open={agentModal}
          {...agentDoPaymentProps}
          onClose={() => setAgentModal(false)}
          setAgentModal={setAgentModal}
          onPay={() => {
            setAgentModal(false);
            history.push(`/redirect-payment?orderId=${props.orderNumber}`);
          }}
          onExtract={(byCredit) => {
            dispatch(
              issueTicketsRequest({
                orderId: props.orderId,
                typePaid: byCredit ? 'credit' : 'bill',
                userId: user?.data?.agentId,
              }),
            );
            setAgentModal(false);
          }}
          onCreateInvoice={(type) => {
            setAgentModal(false);
            dispatch(
              getAgentOrderBillRequest({ type: type, orderId: props.orderId }),
            );
          }}
        />
      )}
    </Wrapper>
  );
};

function dateToLocal(date: string) {
  const stillUtc = moment.utc(date).toDate();
  return moment(stillUtc).local();
}

export default Head;
