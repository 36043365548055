import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Contacts } from '@components/booking';

export const Wrapper = styled.div`
  margin-bottom: 44px;
`;

const ContactInformation = (): ReactElement => (
  <Wrapper>
    <Contacts type={'bus'} />
  </Wrapper>
);

export default ContactInformation;
