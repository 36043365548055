import React from 'react';
import styled from 'styled-components';
import promocodePercent from '@assets/media/order-detail/promocodePercent.svg';
import { useSelector } from 'react-redux';
import { getDetailOrder } from '@modules/orders';

const Container = styled.div`
  background: #edf1fe;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #3c3c3c;
  margin-top: 10px;
`;

const PromocodeApply: React.FC = () => {
  const { orderAmount } = useSelector(getDetailOrder);

  const promocodeDetails = orderAmount.detalization.find((x) =>
    x.description.includes('Применен промокод')
  );

  return (
    <Container>
      <img src={promocodePercent} style={{ marginRight: 5 }} />
      <div>
        {promocodeDetails?.description}. Скидка по промокоду составляет{' '}
        {promocodeDetails?.value} ₽
      </div>
    </Container>
  );
};

export default PromocodeApply;
