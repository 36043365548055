import * as H from 'history';
let _history: H.History<unknown>;

const setHistory = (history: H.History<unknown>): void => {
  _history = history;
};

const navigate = (path: string, state?: unknown): void => {
  _history.push(path, state);
};

const hasHistory = (): boolean => {
  return history ? true : false;
};

const onReload = (): void => {
  window.location.reload();
};

const NavigationService = {
  setHistory,
  navigate,
  hasHistory,
  onReload,
};

export default NavigationService;
