import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import BaseText from '../Typography';
import Button from '../Button';
import { ReactComponent as FileIcon } from '@assets/media/drag-and-drop-files/file.svg';
import { ReactComponent as CrossIcon } from '@assets/media/drag-and-drop-files/cross.svg';
import { DragAndDropProps } from './types';

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div<{ dragInside: boolean; }>`
  width: 100%;
  height: 122px;
  border: 1px solid;
  border-radius: 8px;
  border-color: #dcdcdc;
  transition: border-color .2s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;

  ${({ dragInside }) => dragInside ? `border-color: blue;` : ''}
`;

const Text = styled(BaseText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
  display: block;
  max-width: 210px;
  margin: 0 auto;
  margin-bottom: 14px;
`;

const AddFileButton = styled(Button).attrs({
  type: 'outlined',
})`
  display: block;
  margin: 0 auto;
`;

const AddFileHiddenInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

const FilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  & > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 3px;
  }

  & > :last-child {
    margin-left: 5px;
  }
`;

const DeleteButton = styled(CrossIcon)`
  & > * {
    pointer-events: none;
  }
  cursor: pointer;
`;

export default function (props: DragAndDropProps) {
  const dropRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [files, setFiles] = React.useState([] as File[]);
  const [isDragInside, setDragInside] = React.useState(false);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (files.length > 0) {
      setFiles((s) => [...s, ...files]);
    }
  };

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragInside(true);
  };
  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragInside(false);
  };
  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragInside(true);

    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      setFiles((s) => [...s, ...files]);
      e.dataTransfer.clearData();
    }
  };

  React.useEffect(() => {
    // console.log('start');
    const div = dropRef.current as HTMLDivElement;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, [dropRef]);

  React.useEffect(() => {
    props.onChange(files);
  }, [files]);

  return (
    <Wrapper className={props.className}>
      <Container ref={dropRef} dragInside={isDragInside}>
        <Text>Перетяните файл для загрузки или нажмите</Text>
        <AddFileButton onClick={handleButtonClick}>
          Загрузить документ
        </AddFileButton>
        <AddFileHiddenInput ref={inputRef} onChange={handleChange} />
      </Container>
      <FilesList>
        {files.map((x, key) => (
          <File key={key}>
            <FileIcon />
            <BaseText>{x.name}</BaseText>
            <DeleteButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFiles(files.filter((x, i) => i !== key));
              }}
            />
          </File>
        ))}
      </FilesList>
    </Wrapper>
  );
}
