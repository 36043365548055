import React from 'react';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { FlightSegmentProps } from '../types';
import styled, { css } from 'styled-components';
import { Point } from '@components/common/FlightSegment';
import { Helper } from '@utils';
import Text from '@components/ui/Typography';
import baggageSrc from '@assets/media/shared/baggage.svg';
import noBaggageSrc from '@assets/media/shared/no-baggage.svg';
import techInfoIcon from '@assets/media/flight-search/techInfoIcon.svg';
import { ApplicationState } from '@app/modules';
import { useSelector } from 'react-redux';

const Container = styled.div``;

const FlexBox = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Cities = styled.div`
  ${FlexBox}
`;

const PointWrapper = styled.div`
  position: relative;
  top: -8px;
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0.9;
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 2px;
  position: relative;
`;

const Airports = styled.div`
  ${FlexBox};
  margin-top: 4px;
  position: relative;
  align-items: flex-start;

  & > div {
    width: 33%;
  }

  & > div:nth-child(2) {
    text-align: center;
  }

  & > :last-child {
    text-align: right;
  }
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  & > div {
    width: 33%;
  }

  & > div:nth-child(2) {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  & > :last-child {
    text-align: right;
  }
`;

const AdditionalInfoItem = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-right: 7px;
  }

  @media (min-width: 1024px) {
    min-width: 250px;
  }
`;

const Duration = styled(Text)`
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
`;

const PointDescBlock = styled.div`
  & > span {
    display: block;
  }
`;

const GrayText = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;
const AirportCode = styled(GrayText)`
  font-weight: 600;
  color: #4872f2;
`;

const Time = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

const Airline = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`;

const AirlineNormalized = styled.img.attrs({ width: 25, height: 25 })`
  border-radius: 50%;
`;

const TechInfoIcon = styled.img.attrs({ src: techInfoIcon })`
  margin-right: 5px;
  margin-top: 1px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PointAbsolute = styled.div`
  position: absolute;
  left: 50%;
  top: -5px;
`;

const Segment: React.FC<FlightSegmentProps> = ({
  company,
  from,
  to,
  duration,
  companyName,
  active,
  stops,
  baggageDescr,
}) => {
  const fromTime = getFormattedDate(from?.date, 'time');
  const toTime = getFormattedDate(to?.date, 'time');
  const fromDate = getFormattedDate(from?.date, 'date');
  const toDate = getFormattedDate(to?.date, 'date');
  const formattedDuration = `В пути: ${Helper.formatDuration(duration)}`;
  const order = useSelector((x: ApplicationState) => x.orders.orderDetail.data);

  const isBookingSecondPage =
    window.location.pathname.includes('/bookingDetails');

  const techLandings = order?.orderPositions
    .map((x) =>
      x.segmentGroups.map((y) => y.segments.map((z) => z?.techLandings)).flat(),
    )
    .flat()
    .flat();

  //TODO - ТУДА - ОБРАТНО

  return (
    <Container>
      {/* <Dates>
        <Text> {fromDate}</Text>
        <AirlineLogo
          src={Helper.getAirlinesLogoUrl(airlineCode || '')}
          alt={airlineCode}
        />
        <Text> {toDate}</Text>
      </Dates> */}
      <Cities>
        <Time>{fromTime}</Time>
        <Line>
          <PointWrapper>
            <Point />
          </PointWrapper>

          {!active &&
            stops.length > 0 &&
            stops.map((x, key) => (
              <Tippy
                key={key}
                content={`Пересадка ${Helper.formatDuration(
                  x.duration,
                )}, аэропорт ${x.city.airport}, ${x.city.city}`}
                theme="light"
                maxWidth={190}
              >
                <PointWrapper>
                  <Point key={key} upperText={x.city.code} />
                </PointWrapper>
              </Tippy>
            ))}

          {company && active && !isBookingSecondPage && !techLandings[0] && (
            <Tippy theme={'light'} content={companyName}>
              <Airline
                src={Helper.getAirlinesLogoUrl(company, 50, 50)}
                alt={company}
              />
            </Tippy>
          )}
          {((from.techLandings && from.techLandings.length !== 0) ||
            (techLandings && techLandings.length !== 0)) && (
            <PointAbsolute>
              <Point
                color={'red'}
                techLanding={
                  from.techLandings && from.techLandings.length !== 0
                    ? from.techLandings[0]
                    : techLandings[0]
                }
              />
            </PointAbsolute>
          )}
          <PointWrapper>
            <Point />
          </PointWrapper>
        </Line>
        <Time>{toTime}</Time>
      </Cities>
      <Airports>
        <PointDescBlock>
          <GrayText>
            <AirportCode>{from.airport.code}</AirportCode> {from.city.name},{' '}
            {from.airport.name}
          </GrayText>
          <GrayText style={{ fontWeight: 600 }}>{fromDate}</GrayText>
        </PointDescBlock>
        <PointDescBlock>
          <Text size={'small'} color={'#737373'}>
            {formattedDuration}
          </Text>
          {((from.techLandings && from.techLandings.length !== 0) ||
            techLandings.length !== 0) && (
            <>
              {' '}
              <Tippy
                content={`Остановка, которая заложена в график рейса из-за возможной технологической необходимости. Произойдет посадка самолета в промежуточном аэропорту, при которой пассажиры и багаж остаются на борту самолета.`}
                theme="light"
                maxWidth={231}
              >
                <Flex>
                  <TechInfoIcon />
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: '#E73C3E',
                      fontSize: 10,
                    }}
                  >
                    тех. остановка
                  </Text>{' '}
                </Flex>
              </Tippy>
            </>
          )}
          {!active && stops.length > 0 && (
            <Text size={'small'} color={'#4872F2'}>
              {stops.length}{' '}
              {Helper.pluralWord(
                stops.length,
                'пересадка',
                'пересадки',
                'пересадок',
              )}
            </Text>
          )}
        </PointDescBlock>
        <PointDescBlock>
          <GrayText>
            <AirportCode>{to.airport.code}</AirportCode> {to.city.name},{' '}
            {to.airport.name}
          </GrayText>
          <GrayText style={{ fontWeight: 600 }}>{toDate}</GrayText>
        </PointDescBlock>
      </Airports>
      {!active && (
        <AdditionalInfoContainer>
          <AdditionalInfoItem>
            {baggageDescr === 'Без багажа' ? (
              <img src={noBaggageSrc} />
            ) : (
              <img src={baggageSrc} />
            )}
            <Text>{baggageDescr}</Text>
          </AdditionalInfoItem>
          {company && (
            <AdditionalInfoItem>
              <AirlineNormalized
                src={Helper.getAirlinesLogoUrl(company, 50, 50)}
                alt={company}
              />
              <span data-cy={'airlineSegmentCompanyName_0'}>{companyName}</span>
            </AdditionalInfoItem>
          )}
        </AdditionalInfoContainer>
      )}
    </Container>
  );
};

function getFormattedDate(date: string, type: 'time' | 'date') {
  return moment(date).format(type === 'time' ? 'HH:mm' : 'DD MMM YYYY, dd');
}

export default Segment;
