import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FilteredListEmpty from '@components/flightsSearch/Notifications/FilteredListEmpty';
import { ApplicationState } from '@modules/index';
import { resetFilter } from '@modules/main';

const AviaFilteredListEmptyContainer: React.FC = () => {
  const flightsList = useSelector((state: ApplicationState) => {
    return state.mainReducer.flightSearch.flightsList;
  });
  const isDefaultFilter = useSelector((state: ApplicationState) => {
    return state.mainReducer.flightSearch.filter.isDefault;
  });

  const dispatch = useDispatch();
  return (
    <FilteredListEmpty
      source={'avia'}
      isDefaultFilter={isDefaultFilter}
      itemsCount={flightsList.notFilteredItems.length}
      resetFilter={() => {
        dispatch(resetFilter(null));
      }}
      resetFilterItem={(key) => {
        dispatch(resetFilter(key));
      }}
    />
  );
};

export default AviaFilteredListEmptyContainer;
