import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Formik, Form, FormikValues } from 'formik';

import { PlaceOption } from '@components/ui/form/PlaceSelect/interfaces';

import YourTrip from './components/YourTrip';
import TripDetails from './components/TripDetails';
import AuthBlock from './components/AuthBlock';
import Passengers from './components/Passengers';
import { PASSENGERS } from './components/Passengers/formData';
import ContactInformation from './components/ContactInformation';
import FormCheckboxes from './components/FormCheckboxes';
import Places from './components/Places';

import { Wrapper, Left, Right, Heading, Container } from './styles';
import { Props } from './interfaces';
import {
  getInitialValues,
  getPassengerPlaces,
  prepareFormDataToRestRequest,
  transformDataFromBusToBusForRouteDetail,
} from './controller';
import { getValidationSchema } from './validationSchema';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { bookBusRequest, setBookingPlace } from '@modules/busBooking/duck';
import {
  busBookingForwardSelector,
  busBookingBackwardSelector,
} from '@modules/busBooking';
import { BusForRouteDetail } from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { ApplicationState } from '@app/modules';

const BusBooking = ({ selectedBuses }: Props): ReactElement | null => {
  if (selectedBuses === null) return null;

  const dispatch = useDispatch();
  const passengersCount = selectedBuses[0].ticketsCount;
  const passengerNames: Record<string, string> = {
    Adult: 'Взрослый',
    Child: 'Ребенок',
    BabyWithoutPlace: 'Младенец',
  };
  const ageInfo: string[] = selectedBuses![0].passengersInfo!.flatMap((item) =>
    Array(item.count).fill(passengerNames[item.passengerType]),
  );

  const isMobileReg = useMediaQuery({ maxWidth: 767 });
  const stickyContainer = useRef<HTMLDivElement | null>(null);
  const forwardDetails = useSelector(busBookingForwardSelector);
  const backwardDetails = useSelector(busBookingBackwardSelector);
  const isAuthorized = useSelector(
    (state: ApplicationState) => state.user.status.isAuthorized,
  );

  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState({});
  const formData = getInitialValues(passengersCount, PASSENGERS);

  const [forward, backward] =
    transformDataFromBusToBusForRouteDetail(selectedBuses);

  const [forwardOrderData, setForwardOrderData] =
    useState<BusForRouteDetail>(forward);
  const [backwardOrderData, setBackwardOrderData] =
    useState<BusForRouteDetail>(backward);

  const handleScroll = (): void => {
    if (isMobileReg) {
      setIsSticky(false);
      return;
    }

    if (!stickyContainer.current) return;

    const stickyTop = stickyContainer.current?.getBoundingClientRect().top;
    setIsSticky(stickyTop < 20);
  };

  const updateDetails = (direction: string): void => {
    const isForward = direction === 'forward';
    const freePlaces =
      (isForward
        ? forwardDetails?.bus.freePlaces
        : backwardDetails?.bus.freePlaces) || null;

    const updatedOrderDetailData = isForward
      ? { ...forwardOrderData, freePlaces }
      : { ...backwardOrderData, freePlaces };

    isForward
      ? setForwardOrderData(updatedOrderDetailData)
      : setBackwardOrderData(updatedOrderDetailData);
  };

  const handleSubmit = (values: FormikValues): void => {
    const requestData = prepareFormDataToRestRequest(values, selectedBuses);

    try {
      dispatch(bookBusRequest(requestData));
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const onInit = (): (() => void) => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  const handleUpdateDetails = (): void => {
    forwardDetails && updateDetails('forward');
    backwardDetails && updateDetails('backward');
  };

  useEffect(onInit, []);
  useEffect(handleUpdateDetails, [forwardDetails, backwardDetails]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        handleChange,
        values,
        setFieldValue,
        setFieldTouched,
      }) => {
        const handleChangePlace = (
          id: string | number,
          place: PlaceOption,
        ): void => {
          setFieldValue(String(id), place.value);
        };

        useEffect(() => {
          setValidationSchema(getValidationSchema(values));
        }, [values]);

        useEffect(() => {
          const places = getPassengerPlaces(values);
          places.forward &&
            dispatch(setBookingPlace({ forward: places.forward }));
          places.backward &&
            dispatch(setBookingPlace({ backward: places.backward }));
        }, [getPassengerPlaces(values)]);

        return (
          <Form autoComplete={'off'}>
            <Wrapper>
              <Heading>Бронирование</Heading>
              <Container>
                <Left>
                  <TripDetails
                    forward={forward}
                    backward={backward}
                    places={getPassengerPlaces(values)}
                  />
                  {isMobileReg && (
                    <YourTrip
                      forward={forward}
                      backward={backward}
                      ageInfo={ageInfo}
                    />
                  )}
                  {!isAuthorized && <AuthBlock />}
                  <Passengers
                    errors={errors}
                    touched={touched}
                    passengersCount={passengersCount}
                    ageInfo={ageInfo}
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    forward={forward}
                    backward={backward}
                    places={getPassengerPlaces(values)}
                  />
                  <Places
                    ageInfo={ageInfo}
                    setFieldValue={setFieldValue}
                    values={values}
                    forward={{
                      direction: `${forward.fromStation.name_ru} - ${forward.toStation.name_ru}`,
                      freePlaces: forwardOrderData.freePlaces,
                    }}
                    backward={
                      backward
                        ? {
                            direction: `${backward.fromStation.name_ru} - ${forward.fromStation.name_ru}`,
                            freePlaces: backwardOrderData.freePlaces,
                          }
                        : undefined
                    }
                    onChangePlace={handleChangePlace}
                  />
                  <ContactInformation />
                  <FormCheckboxes />
                </Left>
                <Right ref={stickyContainer} isSticky={isSticky}>
                  {!isMobileReg && (
                    <YourTrip
                      forward={forward}
                      backward={backward}
                      ageInfo={ageInfo}
                    />
                  )}
                </Right>
              </Container>
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BusBooking;
