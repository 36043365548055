import React from 'react';
import styled from 'styled-components';
import { BlockWithMaxWidth, Title, GrayText, Paragraph } from './shared';
import { ReactComponent as UsefulInfoPageImage } from '@assets/media/main-page/usefulInfoPageImage.svg';
import { ReactComponent as DownloadIcon } from '@assets/media/shared/download.svg';
import { Button } from '@components/ui';
import { useDispatch } from 'react-redux';
import { getUsefulInfoRequest } from '@modules/agents/agent-users/duck';
const Container = styled.div`
  background: #f5edff;
  border-radius: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 45px;
  height: 393px;
  overflow: hidden;
  margin-bottom: 20px;
  & > svg {
    align-self: flex-end;
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    position: relative;
    & > svg {
      width: 345.2px;
      height: 309.29px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    & > :first-child {
      z-index: 2;
    }
  }

  @media (max-width: 767px) {
    height: auto;
    display: block;
    padding-left: 0;
    border-radius: 16px;

    & > :first-child {
      margin-bottom: 24px;
    }

    & > svg {
      width: 100%;
      height: auto;
    }
  }
`;

const InfoContainer = styled.div`
  width: 350px;
  & > span {
    display: block;
  }

  ${GrayText} {
    margin-bottom: 8px;
  }

  ${Title} {
    margin-bottom: 24px;
    width: 474px;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      font-size: 20px;
      line-height: 26px;
      width: auto;
    }
  }

  ${Paragraph} {
    margin-bottom: 20px;
    width: 517px;

    @media (min-width: 768px) and (max-width: 1100px) {
      width: 350px;
    }
    @media screen and (min-width: 320px) and (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
      width: auto;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: auto;
    padding: 30px 21px 0 21px;
    ${GrayText} {
      margin-bottom: 5px;
    }
  }
`;
const StyledButton = styled(Button)`
  height: 40px;
  text-align: center;
  white-space: nowrap;
  min-width: 268px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    min-width: auto;
    white-space: inherit;
    justify-content: center;
    font-size: 13px;
  }
`;

export default () => {
  const dispatch = useDispatch();

  return (
    <BlockWithMaxWidth>
      <Container>
        <InfoContainer>
          <GrayText>Для удобной работы</GrayText>
          <Title>Полезная информация для Агентов</Title>
          <Paragraph>
            Мы стараемся создать удобный и доступный инструмент для комфортной
            работы наших Агентов. На данной странице мы собрали полезную
            информацию по бронированию, оплате заказов и разделам Личного
            кабинета Агента.
          </Paragraph>
          <Paragraph>
            Для удобства вы можете скачать инструкцию в PDF формате.
          </Paragraph>
          <StyledButton onClick={() => dispatch(getUsefulInfoRequest())}>
            <DownloadIcon style={{ marginRight: 5 }} /> Скачать PDF инструкцию
          </StyledButton>
        </InfoContainer>
        <UsefulInfoPageImage />
      </Container>
    </BlockWithMaxWidth>
  );
};
