import React from 'react';
import styled from 'styled-components';
import stickyInfo from '@assets/media/booking/stickyInfo.svg';
import stickyArrow from '@assets/media/booking/stickyArrow.svg';
import AnimatedNumber from 'animated-number-react';
import { Helper } from '@utils';
import { useSelector } from 'react-redux';
import Text from '@components/ui/Typography';
import { useModalState } from '@components/booking/AddictionalServices/context';
import { getDetailOrder } from '@modules/orders';
import arrowSrc from '@assets/media/order-detail/arrow1.svg';
import CheckPassangersModal from './CheckPassangersModal';
import { getUserStatusState } from '@modules/user';
import { ApplicationState } from '@modules/index';

interface LinkProps {
  isActive: boolean;
  isDisplay?: boolean;
}

const Wrapper = styled.div<LinkProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: ${({ isActive }) =>
    isActive ? 'null' : '0px -4px 12px 0px #757a8830'};
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 100000;

  @media (min-width: 767px) {
    display: none;
  }
`;

const GrayBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #f6f8fa;
  height: 45px;
  border-radius: 8px;
  margin-right: 15px;
  width: 26px;
`;

const PriceBlock = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e32;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const YellowButton = styled.div`
  width: 168px;
  height: 46px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  background-color: #ffe100;
  color: #2e2e32;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 20px;
`;

const Details = styled.div`
  position: fixed;
  bottom: 70px;
  background: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  min-height: 50px;
  width: 100%;
  right: 0px;
  z-index: 100000;
  border: 1px solid #dbe0e5;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
  box-shadow: 0px -4px 12px 0px #757a8830;
`;

const DetailContent = styled.div`
  margin-top: 24px;
  & > div {
    margin-bottom: 22px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 15px;
    & > div {
      margin-bottom: 12px;
    }
  }
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

const PositionNameWrapper = styled.div<{ interactable: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 18px;

  ${({ interactable }) => (interactable ? 'cursor: pointer;' : '')}
`;

const PositionName = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;
  display: block;
  position: relative;
`;

const SecondLevelList = styled(DetailContent)`
  margin-top: 24px;
  padding-left: 11px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

const Icon = styled.img<{ reverse: boolean }>`
  position: absolute;
  right: -13px;
  top: 7px;
  transition: transform 0.2s ease-in-out;

  ${({ reverse }) => (reverse ? 'transform: rotate(180deg);' : '')}
`;

const StickyInfo = styled.img.attrs({ src: stickyInfo })``;

const StickyArrow = styled.img.attrs({ src: stickyArrow })<LinkProps>`
  transform: ${({ isActive }) =>
    !isActive ? 'rotate (0deg)' : 'rotate(180deg)'};
`;

const CheckPassangerData = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #4872f2;
`;

interface Props {
  chosenScreen?: number;
  chosenPaymentMethod?: string;
}

const StickyFooterNew: React.FC<Props> = ({ chosenPaymentMethod }) => {
  const { totalDetalization, acquiringCommissions } =
    useSelector(getDetailOrder);

  const stickyHide = useSelector(
    (state: ApplicationState) => state.orders.orderDetail.stickyFooterStatus
  );

  const [isCheckPassangersModalOpen, setIsCheckPassangersModalOpen] =
    React.useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
  const { visibility } = useModalState();

  const [state, setState] = React.useState(
    totalDetalization.positionList.map((_, key) => false)
  );
  const stickyFooterStatus = useSelector(
    (state: ApplicationState) => state.orders.orderDetail?.stickyFooterStatus
  );
  const handleClick = (key: number) => {
    if (
      (totalDetalization.positionList[key].detalizationList || []).length > 0
    ) {
      setState((s) => s.map((x, i) => (i === key ? !x : x)));
    }
  };

  const onClickOutsideListener = () => {
    setIsDetailsOpen(false);
    document.removeEventListener('click', onClickOutsideListener);
  };

  return (
    <div
      onMouseLeave={() => {
        document.addEventListener('click', onClickOutsideListener);
      }}
    >
      {isCheckPassangersModalOpen && (
        <CheckPassangersModal
          isCheckPassangersModalOpen={isCheckPassangersModalOpen}
          setIsCheckPassangersModalOpen={setIsCheckPassangersModalOpen}
        />
      )}
      {isDetailsOpen && !visibility && (
        <Details
          onMouseLeave={() => {
            document.addEventListener('click', onClickOutsideListener);
          }}
        >
          <>
            <DetailContent>
              {totalDetalization.positionList.map((item, key) => (
                <div key={key}>
                  <PositionNameWrapper
                    interactable={(item.detalizationList || []).length > 0}
                    onClick={() => handleClick(key)}
                  >
                    <PositionName
                      style={{ fontWeight: item.isBold ? 600 : 400 }}
                    >
                      {item.positionName}
                      {(item.detalizationList || []).length > 0 && (
                        <Icon
                          reverse={!state[key]}
                          src={arrowSrc}
                          alt="arrow"
                        />
                      )}
                    </PositionName>
                    <PositionName
                      color="#3C3C3C"
                      style={{ fontWeight: item.isBold ? 600 : 400 }}
                    >
                      {Helper.formatPrice(item.total)}
                    </PositionName>
                  </PositionNameWrapper>

                  {state[key] && (item.detalizationList || []).length > 0 && (
                    <SecondLevelList>
                      {item.detalizationList.map((item, key) => (
                        <>
                          <DetailRow key={key}>
                            <StyledText color="dark-gray">
                              {item.type === 'FirstBag'
                                ? `Доп. багаж до ${item.name} кг`
                                : item.name}
                            </StyledText>

                            <StyledText color="dark-gray">
                              {item.quantity > 0 && item.type !== 'passenger'
                                ? `${item.quantity} x ${Helper.formatPrice(
                                    item.price
                                  )}`
                                : Helper.formatPrice(item.price)}
                            </StyledText>
                          </DetailRow>

                          {item?.detalization?.map((x: any, key) => (
                            <DetailRow key={key}>
                              <StyledText color="dark-gray">
                                {x.description}
                              </StyledText>
                              <StyledText color="dark-gray">
                                {item.quantity > 0
                                  ? `${item.quantity} x ${Helper.formatPrice(
                                      x.value
                                    )}`
                                  : Helper.formatPrice(x.value)}
                              </StyledText>
                            </DetailRow>
                          ))}
                        </>
                      ))}
                    </SecondLevelList>
                  )}
                </div>
              ))}
              {chosenPaymentMethod &&
                chosenPaymentMethod !== 'Российская карта' &&
                chosenPaymentMethod !== 'Оплатить через СБП' && (
                  <DetailRow style={{ marginTop: 5 }}>
                    <StyledText>Коммиссия за эквайринг</StyledText>
                    <StyledText>
                      {acquiringCommissions &&
                        Helper.formatPrice(
                          acquiringCommissions[1].acquiringAmount
                        )}
                    </StyledText>
                  </DetailRow>
                )}
            </DetailContent>
          </>
        </Details>
      )}
      {!visibility && !isCheckPassangersModalOpen && !stickyHide && (
        <Wrapper
          isActive={isDetailsOpen}
          isDisplay={visibility === null ? true : false}
        >
          <Row>
            <GrayBlock onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
              <StickyArrow isActive={isDetailsOpen} /> <StickyInfo />
            </GrayBlock>
            <PriceBlock>
              <Row>
                Итого&nbsp;
                <div style={{ color: '#4872F2' }}>
                  <AnimatedNumber
                    duration={500}
                    value={
                      chosenPaymentMethod !== 'Российская карта' &&
                      chosenPaymentMethod !== 'Оплатить через СБП'
                        ? totalDetalization.total +
                          acquiringCommissions[1].acquiringAmount
                        : totalDetalization.total
                    }
                    formatValue={(value: number) =>
                      value !== 0 ? Helper.formatPrice(value) : ''
                    }
                  />
                </div>
              </Row>
              <Row>
                <CheckPassangerData
                  onClick={() =>
                    setIsCheckPassangersModalOpen(!isCheckPassangersModalOpen)
                  }
                >
                  Данные пассажиров
                </CheckPassangerData>
              </Row>
            </PriceBlock>
          </Row>

          <YellowButton
            onClick={() => {
              const scrollElement = document.getElementById('PaymentDiv');
              scrollElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }}
          >
            Перейти к оплате
          </YellowButton>
        </Wrapper>
      )}
    </div>
  );
};

export default StickyFooterNew;
