import { useEffect, useRef, useState } from 'react';
import { createPaymentTinkoffRequest } from '@modules/orders';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

interface IProps {
  orderNumber: number;
  orderPositionIds: string[];
}

const usePaymentResponse = ({ orderNumber, orderPositionIds }: IProps) => {
  const functionRef = useRef<any>(recieveMessage);
  const [height, setHeight] = useState('0px');
  const [isNotifyLoader, setIsNotifyLoader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  function recieveMessage(event: any) {
    if (event.data === true) {
      if (!window.location.pathname.includes('/orders') && orderNumber !== 0) {
        history.push(`/orders/${orderNumber}`);
      } else if (window.location.pathname.includes('/orders')) {
        window.location.reload();
      } else if (!window.location.pathname.includes('/booking')) {
        window.location.reload();
      }
    } else if (event.data === '537') {
      setHeight('537px');
    } else if (event.data === 'error') {
      dispatch(
        createPaymentTinkoffRequest({
          correlationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          orderNumber: orderNumber,
          orderPositionIds: orderPositionIds,
          tinkoffSettingType: 0,
        }),
      );
      setIsNotifyLoader(false);
    } else if (event.data === 'notifyPaymentInProccess') {
      setIsNotifyLoader(true);
    }
  }

  window.addEventListener('message', functionRef.current, false);

  const clearFuncInstance = () => {
    window.removeEventListener('message', functionRef.current, false);
  };

  useEffect(() => {
    return () => {
      clearFuncInstance();
    };
  }, []);
  return {
    height,
    isNotifyLoader,
  };
};

export default usePaymentResponse;
