import React from 'react';

// import { useNotifications } from '../useNotification';

// import MultyPosition from './MultyPosition';
import SingleTemplate from './SingleTemplate';
// import SingleTemplate from './SingleTemplate';

export default function Notification() {
  // const _context = useNotifications();
  return <SingleTemplate />;
  // return (_context.notification.segmentEvents || []).length > 1 ? (
  //   <MultyPosition />
  // ) : (
  //   <SingleTemplate />
  // );
}
