import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FilteredListEmpty from '@components/flightsSearch/Notifications/FilteredListEmpty';
import { ApplicationState } from '@modules/index';
import {
  resetTrainFilter,
  TRAIN_SEARCH_STEPS,
  trainCurrentSearchStepSelector,
  TrainDefaultFilter,
} from '@modules/trainTickets';

const TrainFilteredListEmptyContainer: React.FC = () => {
  const currentStep = useSelector(trainCurrentSearchStepSelector);
  const isBackward = currentStep === TRAIN_SEARCH_STEPS.TICKET_BACKWARD;
  let trainTicketsCount = 0;
  let isDefaultFilter: TrainDefaultFilter;
  if (isBackward) {
    trainTicketsCount = useSelector((state: ApplicationState) => {
      return state.trainTickets.list.trainsPerRoutes[1].trains.length;
    });
    isDefaultFilter = useSelector((state: ApplicationState) => {
      return state.trainTickets.trainSearch.filter[1].isDefault;
    });
  } else {
    trainTicketsCount = useSelector((state: ApplicationState) => {
      return state.trainTickets.list.trainsPerRoutes[0].trains.length;
    });
    isDefaultFilter = useSelector((state: ApplicationState) => {
      return state.trainTickets.trainSearch.filter[0].isDefault;
    });
  }

  const dispatch = useDispatch();
  return (
    <FilteredListEmpty
      source={'train'}
      isDefaultFilter={isDefaultFilter}
      itemsCount={trainTicketsCount}
      resetFilter={() => {
        dispatch(resetTrainFilter({
          value: null,
          type: isBackward ? 'backward' : 'forward',
        }));
      }}
      resetFilterItem={(key) => {
        dispatch(resetTrainFilter({
          value: key,
          type: isBackward ? 'backward' : 'forward',
        }));
      }}
    />
  );
};

export default TrainFilteredListEmptyContainer;
