import React from 'react';
import styled from 'styled-components';
import { STEPS, useSteps } from '../useSteps';
import { useRefund } from '../useRefund';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

const NotificationContainer = styled.div<{
  borderColor: string;
  backgroundColor: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const RightSide = styled.div``;

const TitleWrapper = styled.div`
  display: inline-block;
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 22px;
  margin-left: 5px;
  width: 20px;
  vertical-align: bottom;

  & > svg {
    position: absolute;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const DescriptionsList = styled.div`
  & > * {
    display: block;
    margin-bottom: 7px;
  }
`;

const DescriptionItem = styled(Text)`
  color: #3c3c3c;
  & > strong {
    font-weight: 600;
  }
`;

const DesktopButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileButton = styled(Button).attrs({
  type: 'primary',
  htmlType: 'button',
})`
  display: none;
  width: 100%;
  margin-top: 25px;
  @media (max-width: 767px) {
    display: block;
  }
`;

const WellIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#21A038" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1 7.0304L9.17298 17.8232L2.5005 12.0004L4.28326 10.0298L9.14876 14.2758L18.2916 5.24524L20.1 7.0304Z"
      fill="#ECFFE8"
    />
  </svg>
);

const BadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM17.781 7.281C17.9218 7.14017 18.0009 6.94916 18.0009 6.75C18.0009 6.55084 17.9218 6.35983 17.781 6.219C17.6402 6.07817 17.4492 5.99905 17.25 5.99905C17.0508 5.99905 16.8598 6.07817 16.719 6.219L12 10.9395L7.281 6.219C7.21127 6.14927 7.12848 6.09395 7.03738 6.05621C6.94627 6.01848 6.84862 5.99905 6.75 5.99905C6.65138 5.99905 6.55373 6.01848 6.46262 6.05621C6.37152 6.09395 6.28873 6.14927 6.219 6.219C6.14927 6.28873 6.09395 6.37152 6.05621 6.46262C6.01848 6.55373 5.99905 6.65138 5.99905 6.75C5.99905 6.84862 6.01848 6.94627 6.05621 7.03738C6.09395 7.12848 6.14927 7.21127 6.219 7.281L10.9395 12L6.219 16.719C6.07817 16.8598 5.99905 17.0508 5.99905 17.25C5.99905 17.4492 6.07817 17.6402 6.219 17.781C6.35983 17.9218 6.55084 18.0009 6.75 18.0009C6.94916 18.0009 7.14017 17.9218 7.281 17.781L12 13.0605L16.719 17.781C16.7887 17.8507 16.8715 17.906 16.9626 17.9438C17.0537 17.9815 17.1514 18.0009 17.25 18.0009C17.3486 18.0009 17.4463 17.9815 17.5374 17.9438C17.6285 17.906 17.7113 17.8507 17.781 17.781C17.8507 17.7113 17.906 17.6285 17.9438 17.5374C17.9815 17.4463 18.0009 17.3486 18.0009 17.25C18.0009 17.1514 17.9815 17.0537 17.9438 16.9626C17.906 16.8715 17.8507 16.7887 17.781 16.719L13.0605 12L17.781 7.281Z"
      fill="#3C3C3C"
    />
  </svg>
);

const Suggestion = styled(Text)``;

const LinkButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  outline: none;
  background: transparent;
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4872f2;
  text-decoration: underline;
  margin-top: 15px;
  &:hover,
  &:focus {
    color: #7597fe;
  }
`;

const Default: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const data = useRefund();
  const [, setStep] = useSteps();

  const serializeObject = (obj: Record<string, any>): Record<string, any> => {
    if (obj === null || typeof obj !== 'object') {
      return obj; // Возвращаем примитивные значения и null
    }

    if (Array.isArray(obj)) {
      return obj.map(serializeObject); // Рекурсивно обрабатываем массивы
    }

    return Object.entries(obj).reduce((acc: Record<string, any>, [key, value]) => {
      if (typeof value !== 'function' && value !== undefined) {
        acc[key as keyof typeof acc] = serializeObject(value); // Рекурсивно обрабатываем свойства объекта
      }
      return acc;
    }, {});
  };

  const onPay = () => {
    history.push({
      pathname: `${location.pathname}/payment`,
      state: serializeObject(data)
    });
  };

  const stillUtc = moment.utc(data.timelimit).toDate();
  const dateTo = moment(stillUtc).local().format('DD MMMM HH:mm');

  const colors = getColors(data.status.code || '');
  const isButton =
    data.status.code === 'WaitingForApproval' ||
    data.status.code === 'ApprovedByClient';

  const isDetailButton =
    data.status.code === 'ApprovedByClient' || data.status.code === 'Paid';

  return (
    <>
      <NotificationContainer
        backgroundColor={colors.background}
        borderColor={colors.border}
      >
        <Head>
          <RightSide>
            <TitleWrapper style={{ marginBottom: isButton ? 15 : 0 }}>
              <Title>
                {getTitle(data.status.code, data.subject?.toLowerCase())}
              </Title>
              {getIcon(data.status.code)}
            </TitleWrapper>
            {isButton && (
              <DescriptionsList>
                <DescriptionItem>
                  Сумма доплаты:{' '}
                  <strong>{Helper.formatPrice(data.total)}</strong>
                </DescriptionItem>
                {data.timelimit && (
                  <DescriptionItem>
                    Время подтверждения: <strong>{dateTo}</strong>
                  </DescriptionItem>
                )}
              </DescriptionsList>
            )}
          </RightSide>
          {isButton && (
            <DesktopButton
              onClick={
                data.status.code === 'CanceledByClient' ||
                data.status.code === 'Paid'
                  ? undefined
                  : data.status.code === 'WaitingForApproval'
                    ? () => setStep(STEPS.CALCULATE)
                    : onPay
              }
            >
              {getButtonText(data.status.code)}
            </DesktopButton>
          )}
        </Head>
        <Suggestion>
          {getSuggestionText(data.status.code, data.subject?.toLowerCase())}
        </Suggestion>
        {isDetailButton && (
          <LinkButton
            onClick={() => {
              setStep(STEPS.CALCULATE, 'SHOW_MODE');
            }}
          >
            Открыть детали расчета
          </LinkButton>
        )}
      </NotificationContainer>
      {isButton && (
        <MobileButton
          onClick={
            data.status.code === 'CanceledByClient' ||
            data.status.code === 'Paid'
              ? undefined
              : data.status.code === 'WaitingForApproval'
                ? () => setStep(STEPS.CALCULATE)
                : onPay
          }
        >
          {getButtonText(data.status.code)}
        </MobileButton>
      )}
    </>
  );
};

function getButtonText(status?: string | null) {
  switch (status) {
    case 'WaitingForApproval':
      return 'Детали расчета';
    case 'ApprovedByClient':
      return 'Оплатить счет';
    default:
      return '';
  }
}

function getSuggestionText(status?: string | null, subject?: string) {
  switch (status) {
    case 'WaitingForApproval':
      return (
        <>
          Вам направлен счет на {subject}, откройте детали расчета для
          согласования. После одобрения расчета нажмите кнопку{' '}
          <Text color="blue">“Оплатить”</Text>
        </>
      );
    case 'ApprovedByClient':
      return (
        <>
          Вами одобрен счет на {subject} с доплатой денежных средств. Нажмите
          кнопку <Text color="blue">“Оплатить счет”</Text> для завершения
          процедуры на {subject}.
        </>
      );
    case 'Paid':
      return (
        <>
          Счет на доплату денежных средств успешно оплачен. Перейдите в детали
          заказа, чтобы ознакомиться с изменениями.
        </>
      );
    default:
      return (
        <>
          Вы отклонили счет на {subject} с доплатой денежных средств.
          Пожалуйста, обратитесь в чат к операционисту для создания нового
          расчета.
        </>
      );
  }
}

function getColors(status?: string | null) {
  switch (status) {
    case 'WaitingForApproval':
      return { background: '#EDF1FE', border: '#4872F2' };
    case 'Paid':
    case 'ApprovedByClient':
      return { background: '#ECFFE8', border: '#21A038' };
    default:
      return { background: '#FFE2E3', border: '#F64C50' };
  }
}

function getTitle(status?: string | null, subject?: string) {
  switch (status) {
    case 'WaitingForApproval':
      return `Расчет на ${subject}`;
    case 'ApprovedByClient':
      return `Расчет на ${subject} одобрен`;
    case 'Paid':
      return `Счет на ${subject} успешно оплачен`;
    default:
      return `Расчет на ${subject} отклонен`;
  }
}
function getIcon(status?: string | null) {
  switch (status) {
    case 'WaitingForApproval':
      return null;
    case 'ApprovedByClient':
    case 'Paid':
      return (
        <IconContainer>
          <WellIcon />
        </IconContainer>
      );
    default:
      return (
        <IconContainer>
          <BadIcon />
        </IconContainer>
      );
  }
}

export default Default;
