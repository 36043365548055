import { DecksEntity } from './types';

export function getSeatColor(
  price: number,
  isAvailable: boolean,
  selected: boolean,
  priceArr: number[]
) {
  const FIRST_PRICE = priceArr[priceArr.length - 2] || 300;
  const SECOND_PRICE = priceArr[priceArr.length - 1] || 700;

  if (!isAvailable) {
    return { background: '#C4C4C4', border: '#737373' };
  } else if (selected) {
    return { background: '#F57269', border: '#F57269' };
  } else if (price < FIRST_PRICE) {
    return { background: '#9484F6', border: '#6E5CE0' };
  } else if (price < SECOND_PRICE) {
    return { background: '#96DBDB', border: '#50A9A9' };
  } else {
    return { background: '#CA96DB', border: '#9C5EB0' };
  }
}

export function getSeatDataCyAttr(
  price: number,
  isAvailable: boolean,
  selected: boolean,
  priceArr: number[]
) {
  const FIRST_PRICE = priceArr[priceArr.length - 2] || 300;
  const SECOND_PRICE = priceArr[priceArr.length - 1] || 700;

  if (!isAvailable) {
    return 'seatUnavailable';
  } else if (selected) {
    return 'seatSelected';
  } else if (price < FIRST_PRICE) {
    return 'seatCheapPrice';
  } else if (price < SECOND_PRICE) {
    return 'seatMediumPrice';
  } else {
    return 'seatExpensivePrice';
  }
}

export function transformDecksToSelectedSeatsObject(decks: DecksEntity[]) {
  const result = {} as { [key: string]: { [key: string]: boolean } };

  for (const deck of decks) {
    result[deck.type] = {};
  }

  return result;
}
