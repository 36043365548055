import React, { useState, useEffect, useCallback } from 'react';
import { CheckboxProps, CheckboxLabelProps } from './types';
import Arrow from '../../../../assets/images/arrow';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;
const RoundedLabel = styled.div<CheckboxLabelProps>`
  ${({ theme: { colors }, checked, blueBorder }) => `
    display: inline-flex;
    border: 1px solid ${
      checked ? colors.main : blueBorder ? '#4872F2' : colors.gray
    };
    box-sizing: border-box;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: ${colors.white};
    transition: all 150ms;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    & > div {
        transition: all 150ms;
        display: block;
        background: ${colors.main};
        transform: scale(${checked ? '1' : '0'});
    }
    `}
`;
const CheckMarkLabel = styled.div<CheckboxLabelProps>`
  ${({ theme: { colors }, checked, disabled, blueBorder }) => `
        display: inline-flex;
        border: 1px solid ${
          checked ? colors.main : blueBorder ? '#4872F2' : colors.gray
        };
        box-sizing: border-box;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background: ${checked ? colors.main : 'transparent'};
        transition: all 150ms;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        & > svg {
            display: block;
            fill: ${colors.white};
        }

        ${
          disabled
            ? `background: #F9F9F9;
            border: 1px solid ${colors.gray};  
            & > svg {
          fill: #F9F9F9;
      }`
            : ''
        }
  
        
    `}
`;

const SquarCheckbox = styled.div<CheckboxLabelProps>`
  ${({ theme: { colors }, checked, disabled }) => `
        display: inline-flex;
        flex-shrink: 0;
        border: 1px solid ${checked ? colors.main : colors.gray};
        box-sizing: border-box;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        background: transparent;
        transition: all 150ms;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        & > svg {
            transition: all 150ms;
            display: block;
            transform: scale(${checked ? '1' : '0'});
            fill: ${colors.main};
        }
        ${
          disabled
            ? `background: #F9F9F9;
            border: 1px solid ${colors.gray};  
            & > svg {
            fill: #b9b9b9;
            cursor: default;
      }`
            : ''
        }
    `}
`;

const SquarCheckboxBlue = styled.div<CheckboxLabelProps>`
  ${({ theme: { colors }, checked }) => `
        display: inline-flex;
        flex-shrink: 0;
        border: 1px solid ${checked ? colors.main : colors.gray};
        background-color: ${checked ? colors.main : 'transparent'};
        box-sizing: border-box;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        transition: all 150ms;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        transition: background-color 150ms;
        & > svg {
            transition: transform 150ms;
            display: block;
            transform: scale(${checked ? '1' : '0'});
            fill: white;
        }
    `}
`;

const Description = styled.span`
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.blackGray};
  cursor: pointer;
  margin-left: 8px;
  user-select: none;
`;

const Round = styled.div`
  width: 14.7px;
  height: 14.7px;
  border-radius: 50%;
  pointer-events: none;
`;

const InputCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;
const Label = styled.label`
  padding-bottom: 1px;

  &.error {
    ${SquarCheckbox} {
      border: 1px solid ${({ theme }) => theme.colors.red};
    }

    ${Description} {
      color: ${({ theme: { colors } }) => colors.red};
    }
  }
`;

const Icon = styled.img`
  border-radius: 50%;
  margin-left: 10px;
`;

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  error = false,
  type,
  label,
  onChange,
  icon,
  ...props
}) => {
  const [isChecked, setChecked] = useState(
    typeof checked !== 'undefined' ? checked : false
  );

  useEffect(() => {
    if (typeof checked !== 'undefined' && checked !== isChecked) {
      setChecked(checked);
    }
  }, [checked]);

  const handleChekboxChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (typeof checked === 'undefined') {
          setChecked((isChecked) => !isChecked);
        }
        if (typeof onChange === 'function') {
          onChange(e);
        }
      },
      [onChange]
    );

  const checkboxView = () => {
    switch (type) {
      case 'squar': {
        return (
          <SquarCheckbox {...props} checked={isChecked}>
            <Arrow width={15} height={10} />
          </SquarCheckbox>
        );
      }
      case 'squar-blue': {
        return (
          <SquarCheckboxBlue {...props} checked={isChecked}>
            <Arrow width={15} height={10} />
          </SquarCheckboxBlue>
        );
      }
      case 'round': {
        return (
          <RoundedLabel {...props} checked={isChecked}>
            <Round />
          </RoundedLabel>
        );
      }
      case 'roundWithArrow': {
        return (
          <CheckMarkLabel {...props} checked={isChecked}>
            <Arrow width={15} height={10} />
          </CheckMarkLabel>
        );
      }
    }
  };
  const labelClassName = error ? 'error' : '';
  return (
    <Label className={labelClassName}>
      <Container>
        <InputCheckbox
          checked={isChecked}
          onChange={handleChekboxChange}
          {...props}
        />
        {checkboxView()}
        {icon && <Icon src={icon} width={24} height={24} />}
        {label && <Description>{label}</Description>}
      </Container>
    </Label>
  );
};

export default Checkbox;
