import React from 'react';
import Text, { Header } from '@components/ui/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAddAppealState } from '@modules/appeals';
import checkIconSrc from '../../../../assets/images/appeal-forms/check.svg';

const Container = styled.div`
  ${({ theme }) => `
      ${theme.max('767px')} {
        display:flex;
        flex-direction:column;
      }
  `}
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.main};
  text-decoration: none;
`;

const AppealNumber = styled.div`
  margin-top: 15px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    transform: translateY(-4px);
    margin-right: 9px;
  }

  ${({ theme }) => `
      ${theme.max('767px')} {
        justify-content: flex-start;
        margin-bottom:10px;
      }
  `}
`;

const BottomText = styled(Text)`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3c3c3c;
`;

const Check = styled.img`
  margin-left: 5px;
  width: 32px;
  height: 32px;
  margin-bottom: 7px;
`;

const FirstString = styled.span`
  display: flex;
  justify-content: center;
`;

const CreateFormSuccess: React.FC = () => {
  const { response } = useSelector(getAddAppealState);
  return (
    response && (
      <Container>
        <Header size="h3">Ваше обращение принято в обработку</Header>
        <AppealNumber>
          <Text size="big" color="#737373">
            № обращения:
          </Text>{' '}
          <Header size="h3">{response?.number || 'неизвестно'}</Header>
          <Check src={checkIconSrc as any} alt="" />
        </AppealNumber>
        <BottomText>
          <FirstString>Мы в ближайшее время ответим на ваш запрос.</FirstString>
          Следить за статусом обращения можно в разделе{' '}
          <StyledLink to="/tickets">Мои обращения</StyledLink>
        </BottomText>
      </Container>
    )
  );
};

export default CreateFormSuccess;
