import React from 'react';
import ReactPopup from 'reactjs-popup';
import styled from 'styled-components';

import { PopupProps } from '@components/flightsSearch/Filter/Mobile/Popup/types';
import { PopupProvider } from '@components/flightsSearch/Filter/Mobile/Popup/usePopup';
import TemplateTrain from '@components/flightsSearch/Filter/Desktop/TemplateTrain';
import Head from '@components/train/TrainSearch/Filter/Head';
import Footer from '@components/train/TrainSearch/Filter/Footer';

const StyledPopup = styled(ReactPopup)`
  &-content {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    border: none;
    border-radius: 0;
    overflow-x: scroll;
    position: relative;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const Popup: React.FC<PopupProps> = (props: PopupProps) => {
  return (
    <StyledPopup {...props}>
      <PopupProvider onClose={props.onClose}>
        <Head />
        <TemplateTrain />
        <Footer />
      </PopupProvider>
    </StyledPopup>
  );
};

export default Popup;
