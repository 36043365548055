import { combineReducers } from 'redux';
import financeAgentsReducers from '@modules/agents/agent-finance/duck';
import feesAgentsReducer from '@modules/agents/agent-fees/duck';
import userAgentsReducer from '@modules/agents/agent-users/duck';

export default combineReducers({
  ...financeAgentsReducers,
  ...feesAgentsReducer,
  ...userAgentsReducer,
});
