import React from 'react';
import { PopupContext } from './types';

const Steps = React.createContext<PopupContext>(null as any);

export const usePopup = () => React.useContext(Steps);

export const PopupProvider: React.FC<PopupContext & React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <Steps.Provider value={props}>{children}</Steps.Provider>;
};
