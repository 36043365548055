import {
  OrderDetailState,
  SegmentGroup,
  Segment,
  InsuranceEntity,
} from '@modules/orders';
import flatten from 'lodash/flatten';
import _ from 'lodash';
import { RefundFormData } from './types';
import { OptionTypeBase } from 'react-select';
import { initialSelect } from './utils/createFormDefault';
import { RefundSelectOption } from './model/useCreateFormRefund';
import { RefundTypeCode } from './CreateFormRefund';

type HashMap<K extends string, V> = { [key in K]: V };

export type InnerSegment = {
  value: string;
  label: string;
  checked: boolean;
  selectedAncillaryServices: string[];
  ancillaryServices: { value: string; label: string }[];
};
export interface ReducerState {
  activePassenger: string;
  showPassengersList: boolean;
  mealNamesArr: { [key: string]: string };
  selectedPassengers: string[];
  selectedSegments: string[];
  availableAncillaries: {
    label: string;
    value: string;
  }[];
  passengers: {
    [key: string]: {
      activeSegment: string;
      value: string;
      label: string;
      selected: boolean;
      selectedSegments: HashMap<string, InnerSegment>;
      segmentList: { value: string; label: string; checked: boolean }[];
      insurances: {
        value: string;
        label: string;
        price: number;
        checked: boolean;
      }[];
      showPassengerInList: boolean;
    };
  };
  passengerList: { value: string; label: string; checked: boolean }[];
}

export interface ReducerStateRefund {
  refundReason: OptionTypeBase;
  refundType: RefundTypeCode;
  tickets: string[];
  services: string[];
}

export const passengersNormalizer = (
  orderPosition: OrderDetailState['orderPositions'][number],
  insurances?: InsuranceEntity
) => {
  const insurancesGroupedByPassengers = _.groupBy(
    _(insurances?.products).reduce(
      (acc, x) => [
        ...acc,
        ...x.policies.map((y) => ({
          value: y.id,
          label: x.name,
          price: y.price,
          checked: false,
          passengerId: y.passenger.aviaPassengerId,
        })),
      ],
      [] as {
        value: string;
        label: string;
        price: number;
        checked: boolean;
        passengerId: string;
      }[]
    ),
    (x) => x.passengerId
  );

  return orderPosition.passengers
    .map((value, index) => ({
      label: `${value.firstName} ${value.lastName}`,
      value: value.passengerId,
      index,
      insurances: insurancesGroupedByPassengers[value.passengerId] || [],
      segments: orderPosition.segmentGroups
        .reduce(segmentGroupFlatten, [] as Segment[])
        .map((segment, index) => ({
          label: `${segment.departureCity.name} - ${segment.arrivalCity.name} (${segment.departureAirport.code}-${segment.arrivalAirport.code})`,
          value: extractId(segment),
          checked: false,
          index,
          selectedAncillaryServices: [],
          ancillaryServices: extractAncillarryServices(
            orderPosition,
            value.passengerId,
            segment.id
          ).map((value, index) => ({ ...value, index })),
        })),
    }))
    .reduce(
      (acc, x) => ({
        ...acc,
        passengers: {
          ...acc.passengers,
          [x.value]: {
            seatPlaces: [],
            activeSegment: '',
            value: x.value,
            label: x.label,
            selected: false,
            selectedSegments: {},
            insurances: x.insurances,
            segmentList: x.segments,
            showPassengerInList: false,
          },
        },
        passengerList: [
          ...acc.passengerList,
          { value: x.value, label: x.label, checked: false },
        ],
      }),
      {
        selectedSegments: [],
        selectedPassengers: [],
        mealNamesArr: {},
        availableAncillaries: [],
        activePassenger: '',
        passengers: {},
        passengerList: [],
        showPassengersList: false,
      } as ReducerState
    );
};

export const refundNormalizer = (
  tickets: RefundSelectOption[],
  services: RefundSelectOption[]
): RefundFormData => {
  return {
    refundReason: initialSelect,
    refundType: undefined,
    tickets: tickets,
    services: services,
  };
};

function segmentGroupFlatten(acc: Segment[], x: SegmentGroup) {
  return [...acc, ...x.segments];
}

function extractId<T extends { id: string }>(args: T) {
  return args.id;
}

function extractAncillarryServices(
  orderPosition: OrderDetailState['orderPositions'][number],
  passengerId: string,
  segmentId: string
) {
  const isSamePassengerIdPartial = isSamePassengerId(passengerId);
  const isSameSegmentIdPartial = isSameSegmentId(segmentId);

  const seatsAssignment = orderPosition.seatsAssignment || [];
  return flatten([
    (orderPosition.meals || [])
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter((x) => x.status.code !== 'Refunded')
      .map((data) => ({
        label: data.name,
        value: data.id,
        segmentId,
        name: data.name,
      })),

    (orderPosition.luggages || [])
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter((x) => x.status.code !== 'Refunded')
      .map((data) => ({
        label: `Доп. багаж до ${data.value} кг`,
        value: data.id,
        segmentId,
        name: data.name,
      })),
    (orderPosition.otherAncillaryServices || [])
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter((x) => x.status.code !== 'Refunded')
      .map((data) => ({
        label: data.name,
        value: data.id,
        segmentId,
        name: data.name,
      })),
    seatsAssignment
      .find(isSamePassengerIdPartial)
      ?.items.filter(isSameSegmentIdPartial)
      .filter((x) => x.status.code !== 'Refunded')
      .map((data) => ({
        label: `Место ${
          data.seatNumber ||
          data.name?.split(' ')[data.name?.split(' ').length - 1]
        } `,
        value: data.id,
        segmentId,
        name: data.name,
      })),
  ]).filter((value) => value !== undefined) as {
    label: string;
    value: string;
    segmentId: string;
    name: string;
  }[];
}

const isSamePassengerId =
  (passengerId: string) =>
  <T extends { passengerId: string }>(value: T) =>
    value.passengerId === passengerId;

const isSameSegmentId =
  (segmentId: string) =>
  <T extends { aviaSegmentIds?: string[] | null }>(value: T) => {
    return (value.aviaSegmentIds || []).includes(segmentId);
  };
