import React from 'react';
import { Separator, InfoBlock } from '@components/common';
import styled from 'styled-components';
import Head from '../Head';
import Tariff from '../Tarrif';
import { useOrderPosition } from '../useItemProps';
import DetailButtonWrapper from '@components/booking/FlightDetail/Desktop/DetailButtonWrapper';
import { useSelector } from 'react-redux';
import { transformOrderToBusForRouteDetail } from '@modules/orders/normalizer';
import { getOrderDetailsCleaned } from '@modules/orders';
import RouteDetail from '@components/bus/Booking/components/TripDetails/components/RouteDetail';
import { Divider } from '@components/bus/Booking/components/TripDetails/styles';
import { TariffProps } from '@components/order/Detail/OrderPositions/Item/types';

const BlockWithPadding = styled.div`
  padding: 0 24px;
`;

const TripDetailsContainer = styled.div`
  padding: 24px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled(InfoBlock)`
  padding: 24px 0;
`;

export interface Props {
  disabled: boolean;
  isOpen: boolean;
  isTrainOrder: boolean;
  isBusOrder: boolean;
  tariff: TariffProps;
  setOpen: (isOpen: boolean) => void;
}

const Item: React.FC = () => {
  const props = useOrderPosition() as Props;
  const orderDetails = useSelector(getOrderDetailsCleaned);
  const [forward, backward] = transformOrderToBusForRouteDetail(orderDetails);

  return (
    <Wrapper style={{ background: props.disabled ? '#FAFAFA' : 'transparent' }}>
      <Container>
        <Head />
        {props.isBusOrder && (
          <TripDetailsContainer>
            {forward !== undefined && (
              <RouteDetail
                bus={forward}
                direction="forward"
                transportNumber={forward.busNumber}
                places={[forward.place]}
                busAddresses={{
                  forward: {
                    address: forward.address,
                    geolocation: forward.geolocation,
                  },
                  backward: {
                    address: backward?.address || null,
                    geolocation: backward?.geolocation || null,
                  },
                }}
              />
            )}
            {backward !== undefined && (
              <>
                <Divider />
                <RouteDetail
                  bus={backward}
                  direction="backward"
                  transportNumber={backward.busNumber}
                  places={backward?.place ? [backward.place] : ['']}
                  busAddresses={{
                    forward: {
                      address: forward.address,
                      geolocation: forward.geolocation,
                    },
                    backward: {
                      address: backward.address,
                      geolocation: backward.geolocation,
                    },
                  }}
                />
              </>
            )}
          </TripDetailsContainer>
        )}
        {props.isOpen && (
          <>
            <Separator />
            <BlockWithPadding>
              <Tariff {...props.tariff} />
            </BlockWithPadding>
          </>
        )}
      </Container>
      {!props.isTrainOrder && !props.isBusOrder && (
        <DetailButtonWrapper
          onClick={() => props.setOpen(!props.isOpen)}
          isOpen={props.isOpen}
        />
      )}
    </Wrapper>
  );
};

export default Item;
