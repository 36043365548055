import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@components/ui';
import { Helper } from '@utils';
import { ItemProps } from './types';
import { ReactComponent as Cellphone } from '@assets/media/addictional-services/cellphone.svg';
import { ReactComponent as BestSeller } from '@assets/media/addictional-services/bestseller.svg';
import { useModalState } from './context';

interface LinkProps {
  isActive: boolean;
}

const Wrapper = styled.div<LinkProps>`
  background: ${({ isActive }) => (isActive ? '#F1F4FF' : '#ffffff')};
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  position: relative;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-left: 7px;
  padding-top: 7px;
  @media (max-width: 767px) {
    margin-left: 0;
    padding-top: 0;
  }
`;

const Name = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  position: relative;
`;

const Price = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4872f2;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Name} {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5px;
  }

  ${Price} {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileHeadLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const DesktopIcon = styled(Cellphone)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const Description = styled(Text)`
  display: block;
  margin-bottom: 5px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

const Rule = styled(Text)`
  color: #4872f2;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;

  @media (max-width: 767px) {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #3c3c3c;
    margin-top: 15px;
  }
`;

const Action = styled(Button).attrs({ htmlType: 'button' })<LinkProps>`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 16px;
  min-width: 153px;
  margin-top: 15px;
  color: ${({ isActive }) => (isActive ? '#4872F2' : '#ffffff')};
`;

const Label = styled(BestSeller)`
  position: absolute;
  right: -114px;
  top: -3px;
`;

const DesktopLabel = styled(Label)`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileLabel = styled(Label)`
  top: -12px;
  right: -4px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

export default function (props: ItemProps) {
  const handleChange = () => {
    props.onClick(props.index, !props.checked);
  };
  const { showModal } = useModalState();

  return (
    <Wrapper data-cy={'additionalServiceWrapperSms'} isActive={props.checked}>
      <MobileLabel />
      <DesktopIcon />
      <Content>
        <Head>
          <Name>
            SMS-информирование <DesktopLabel />
          </Name>
          <Price data-cy={`additionalServicePriceSms`}>
            {Helper.formatPrice(props.amount)}
          </Price>
        </Head>
        <MobileHead>
          <MobileHeadLeftSide>
            <Cellphone /> <Name>SMS-информирование</Name>
          </MobileHeadLeftSide>
          <Price data-cy={`additionalServicePriceSms`}>
            {Helper.formatPrice(props.amount)}
          </Price>
        </MobileHead>
        <Description>
          Будьте вкурсе всей информации о перелете: изменение расписания,
          сведения об оплате др.{' '}
        </Description>
        <Rule onClick={() => showModal(props.type)}>
          Подробнее <span style={{ color: '#4872F2' }}>об условиях</span>
        </Rule>
        <Action
          data-cy={`additionalService_${props.index}`}
          onClick={handleChange}
          color={props.checked ? '#DEE5FA' : undefined}
          isActive={props.checked}
        >
          {!props.checked ? 'Добавить услугу' : 'Убрать услугу'}
        </Action>
      </Content>
    </Wrapper>
  );
}
