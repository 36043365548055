import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  padding: none;
  text-align: center;
  width: 30px;
  margin: 0 1px;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.blackGray};
`;

export const ButtonBase = css`
  background: ${({ theme: { colors } }) => colors.main};
  width: 24px;
  height: 24px;
  position: relative;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  &:disabled {
    background: ${({ theme: { colors } }) => colors.gray};
  }
`;

interface ButtonProps {
  outlined: boolean;
}

export const Plus = styled.button<ButtonProps>`
  ${ButtonBase}
  &:before, &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    width: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 1px;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  ${({ outlined, theme: { colors } }) =>
    outlined
      ? `
      background: ${colors.white};
      &:before, &:after {
        background-color: ${colors.main};
      }
      border: 1px solid ${colors.main};
      &:disabled {
        &:before, &:after {
          background-color: ${colors.gray};
        }
        border: 1px solid ${colors.gray};
        background: ${colors.white};
      }
  `
      : ''}
`;

export const Minus = styled.button<ButtonProps>`
  ${ButtonBase}
  &:before {
    position: absolute;
    content: ' ';
    width: 14px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 1px;
  }
  ${({ outlined, theme: { colors } }) =>
    outlined
      ? `
      background: ${colors.white};
      &:before, &:after {
        background-color: ${colors.main};
      }
      border: 1px solid ${colors.main};
      &:disabled {
        &:before {
          background-color: ${colors.gray};
        }
        border: 1px solid ${colors.gray};
        background: ${colors.white};
      }
  `
      : ''}
`;
