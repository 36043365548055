import { FormatTableRowProps } from '../../../../modules/appeals/types/types';
import flow from 'lodash/flow';
import flatten from 'lodash/flatten';

type Row = {
  label: string;
  cost: number;
};

type HashMap<K extends string, V> = { [key in K]: V };

export const formatTableRows = flow(
  (item: FormatTableRowProps) => {
    return [createParamsRows(item), createTaxRows(item)];
  },
  flatten,
  (data) => data.filter((x) => !!x.cost)
);

export function createParamsRows({
  type,
  supportTicketCalculationParameter,
}: FormatTableRowProps) {
  return [
    {
      label:
        type?.code === 'ExtraPayment' ? 'Сбор за обмен' : 'Сбор за возврат',
      cost: supportTicketCalculationParameter?.refundTax,
    },
    {
      label: 'Сбор субагента',
      cost: supportTicketCalculationParameter?.subAgencyTax,
    },
    {
      label: 'Добор по тарифу',
      cost: supportTicketCalculationParameter?.tariff,
    },
    {
      label:
        type?.code === 'ExtraPayment'
          ? 'Сбор авиакомпании за обмен'
          : 'Сбор авиакомпании за возврат',
      cost: supportTicketCalculationParameter?.aviaCompanyRefundTax,
    },
    {
      label: 'Сбор за аннулирование',
      cost: supportTicketCalculationParameter?.aviaCompanyCancellationTax,
    },
    {
      label: 'Сбор агенства',
      cost: supportTicketCalculationParameter?.agencyTax,
    },
    {
      label: 'Удержание за тариф',
      cost: supportTicketCalculationParameter?.usedRouteTax,
    },
    {
      label: 'Сбор платежной системы',
      cost: supportTicketCalculationParameter?.paySystemTax,
    },
    {
      label: 'Возвращено клиенту',
      cost: supportTicketCalculationParameter?.customerRefund,
    },
  ];
}

export function createTaxRows({
  supportTicketCalculationTax,
}: FormatTableRowProps) {
  const hashMap = supportTicketCalculationTax
    ? Object.entries(supportTicketCalculationTax).reduce(
        (acc, [key, value]) => {
          if (key === 'additionalTax') {
            return {
              ...acc,
              additionalTax: {
                label: 'Дополнительные таксы',
                cost: value as number,
              },
            };
          }

          const isCost = key.includes('tax');
          const index = (key.match(/\d/) || [])[0] || 'undefined';
          const defaultObject = { ...acc[index] } || {
            label: '',
            cost: 0,
          };
          if (isCost) {
            defaultObject.cost = value as number;
          } else {
            defaultObject.label = value as string;
          }

          return {
            ...acc,
            [index]: defaultObject,
          };
        },
        {} as HashMap<string, Row>
      )
    : {};

  return Object.entries(hashMap).map(([key, value]) => value);
}
