import React from 'react';
import {
  CityItemTitle,
  CityItemText,
  CityItemDescription,
  CityCode,
} from './styles';
import { City } from '@modules/simpleSearch';
import { FocusType } from '../useFormFocus';
import styled from 'styled-components';

const CityItem = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  cursor: pointer;
  ${({ active }) => (active ? 'background-color: #f2f2f2;' : '')}
  padding: 0 16px 0 12px;
`;

interface SuggestionItemProps {
  data: City;

  onSelect(data: City, focus: FocusType): void;

  focus: FocusType;
  active: boolean;

  setSuggestionIndex(index: number | null): void;

  indexNumber: number;
  searchPanelType?: 'avia' | 'train' | 'bus';
}

export const SuggestionItem: React.FC<SuggestionItemProps> = ({
  data,
  onSelect,
  focus,
  active,
  setSuggestionIndex,
  indexNumber,
  searchPanelType,
}) => {
  const handleOnEnter = () => {
    setSuggestionIndex(indexNumber);
  };

  const handleOnLeave = () => {
    setSuggestionIndex(null);
  };

  const handleClick = () => {
    onSelect(data, focus);
  };

  return (
    <CityItem
      active={active}
      onMouseEnter={handleOnEnter}
      onMouseLeave={handleOnLeave}
      onClick={handleClick}
    >
      <CityItemText>
        <CityItemTitle>{data.nameRus}</CityItemTitle>
        <CityItemDescription>{data?.country?.nameRus}</CityItemDescription>
      </CityItemText>
      {searchPanelType === 'avia' && <CityCode>{data.iataCode}</CityCode>}
    </CityItem>
  );
};
