import React from 'react';
import styled from 'styled-components';
import { Tariff } from '@components/train/Tariffs/Tarif';
import { TrainTariff } from '@components/train/Panels/TariffsPanel';
import { PupilNoty } from '@components/train/Booking/DiscountNoty';

interface Props {
  tariffs: TrainTariff[];
  handleChangeTariff: (tariffType: string) => void;
}

const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 0;
  flex-wrap: wrap;

  & > li > div {
    min-height: 140px;
  }
`;

const ListItem = styled.li`
  list-style: none;
  flex-basis: 32%;

  @media (max-width: 768px) {
    flex-basis: 49%;
  }

  @media (max-width: 560px) {
    flex-basis: 100%;
  }
`;

export const TariffsList = (props: Props) => {
  const memoizedActiveTariff = React.useMemo(() => {
    return props.tariffs.find((tariff) => tariff.active);
  }, [props.tariffs]);

  return (
    <div>
      <List>
        {props.tariffs.map((tariff, index) => {
          return (
            <ListItem key={index}>
              <Tariff
                type={tariff.type}
                active={tariff.active}
                disabled={tariff.disabled}
                name={tariff.name}
                value={tariff.value}
                text={tariff.text}
                fullDescription={tariff.fullDescription}
                fullDiscountDescription={tariff.fullDiscountDescription}
                handleChangeTariff={props.handleChangeTariff}
              />
            </ListItem>
          );
        })}
      </List>
      {memoizedActiveTariff && memoizedActiveTariff.type === 'Pupil' && (
        <PupilNoty />
      )}
    </div>
  );
};
