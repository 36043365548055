import { createSelector } from 'reselect';
import { ApplicationState } from '@modules/index';

const selectFlightSearch = (state: ApplicationState) => state.mainReducer.flightSearch;
const selectFlightSearchIntervals = (state: ApplicationState) => state.mainReducer.flightSearchIntervals;

export const selectFlightSearchData = createSelector(
  [selectFlightSearch, selectFlightSearchIntervals],
  (flightSearch, flightSearchIntervals) => ({
    flightSearch,
    flightSearchIntervals,
  })
);