import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@components/ui/form';
import Text, { Link } from '@components/ui/Typography';
import { useSelector } from 'react-redux';
import {
  getUpt,
  getSelectedFare,
  bookingInsuranceSelector,
} from '@modules/booking';
import { FareCondition } from '@components/common';
import { useFormikContext } from 'formik';
import CharterTariffsModal from '@components/common/Modals/CharterTariffsModal';
import HealthDeclorationModal from '@components/common/Modals/HealthDeclorationModal';
import PaymentButton from '@components/booking/Payment/PaymentButton';
import { Hooks } from '@utils';
import { FormikFields } from '../PassengersBlock/types';

const Wrapper = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 48px;

  & > :first-child {
    margin-right: 8px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    width: 230px;
  }

  @media (max-width: 767px) {
    display: block;
    & > button {
      width: 100%;
    }
  }
`;

const StyledLink = styled(Link)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const PromoContainer = styled.div`
  display: flex;
`;

const PaymentBlock: React.FC<{ mobileSubmitCount: number }> = ({
  mobileSubmitCount,
}) => {
  const [popup, setPopup] = React.useState(false);
  const upt = useSelector(getUpt);
  const { fareFamilies } = useSelector(getSelectedFare);
  const name = fareFamilies[0].name;
  const fare = useSelector(getSelectedFare);
  const [popupHealth, setPopupHealth] = React.useState(false);

  const insurance = useSelector(bookingInsuranceSelector);

  const formikContext = useFormikContext<FormikFields>();

  const { isMobile } = Hooks.useMediaSizes();

  const { handleChange, errors, values, submitCount, setFieldValue } =
    useFormikContext<{
      offerta: boolean;
      emailOfferta: boolean;
      isInsurancesChecked: boolean;
      offertaWithInsurances: boolean;
      charterTariffs: boolean;
      isCharter: boolean;
      healthDeclaration: boolean;
    }>();

  const isInsurancesChecked = insurance.list.offers.some(
    (item) => item.checked
  );

  React.useEffect(() => {
    setFieldValue('isInsurancesChecked', isInsurancesChecked);
  }, [isInsurancesChecked]);

  return (
    <Wrapper>
      <CheckboxWrapper style={{ marginBottom: 10 }} id="offerta">
        <Checkbox
          type="squar"
          checked={values.offerta}
          name="offerta"
          error={
            (errors.offerta && mobileSubmitCount > 0) ||
            (errors.offerta && submitCount > 0)
              ? true
              : false
          }
          onChange={handleChange}
        />{' '}
        <StyledText
          color={
            (errors.offerta && mobileSubmitCount > 0) ||
            (errors.offerta && submitCount > 0)
              ? 'dark-gray'
              : 'dark-gray'
          }
        >
          Я ознакомлен и согласен с{' '}
          <StyledLink to="/docs/oferta.pdf" target="_blank">
            договором публичной оферты
          </StyledLink>
          {!values.isCharter && (
            <>
              <StyledText
                color="blue"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setPopup(true);
                }}
              >
                , условиями применения тарифов
              </StyledText>{' '}
            </>
          )}{' '}
          и{' '}
          <StyledLink to="/docs/police.pdf" target="_blank">
            политикой в отношении обработки персональных данных
          </StyledLink>
          .
        </StyledText>
      </CheckboxWrapper>
      {fare?.isHealthDeclarationChecked && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.healthDeclaration}
            name="healthDeclaration"
            error={errors.healthDeclaration ? true : false}
            onChange={handleChange}
          />{' '}
          <StyledText
            color={
              errors.offerta && submitCount > 0 ? 'dark-gray' : 'dark-gray'
            }
          >
            Я подтверждаю, что все пассажиры в моем бронировании соответствуют
            требованиям &nbsp;
            <StyledText
              color="blue"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPopupHealth(true);
              }}
            >
              “Заявление о состоянии здоровья в связи с COVID-19”
            </StyledText>
            &nbsp; на дату оформления. Я понимаю, что при нарушении любых
            пунктов этого заявления пассажирам будет отказано в поездке.
          </StyledText>
        </CheckboxWrapper>
      )}
      {isInsurancesChecked && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.offertaWithInsurances}
            name="offertaWithInsurances"
            error={
              errors.offertaWithInsurances && submitCount > 0 ? true : false
            }
            onChange={handleChange}
          />{' '}
          <StyledText style={{ color: '#737373' }}>
            Я ознакомлен(а) и согласен(а) с договором публичной оферты и
            правилами выбранного страхового продукта.
          </StyledText>
        </CheckboxWrapper>
      )}

      {values.isCharter && (
        <CheckboxWrapper style={{ marginBottom: 10 }}>
          <Checkbox
            type="squar"
            checked={values.charterTariffs}
            name="charterTariffs"
            error={errors.charterTariffs && submitCount > 0 ? true : false}
            onChange={handleChange}
          />{' '}
          <StyledText>
            Я согласен(а) с{' '}
            <StyledText
              color={'blue'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPopup(true);
              }}
            >
              условиями применения тарифов перевозки
            </StyledText>{' '}
            . Я предупреждён, что в исключительных случаях возможна замена
            перевозчика, типа воздушного судна на аналогичные услуги без
            взимания какой-либо доплаты.
          </StyledText>
        </CheckboxWrapper>
      )}

      <CheckboxWrapper>
        <Checkbox
          type="squar"
          checked={values.emailOfferta}
          name="emailOfferta"
          error={errors.emailOfferta && submitCount > 0 ? true : false}
          onChange={handleChange}
        />{' '}
        <StyledText
          color={
            errors.emailOfferta && submitCount > 0 ? 'dark-gray' : 'dark-gray'
          }
        >
          Я согласен(а) на получение email-рассылки.
        </StyledText>
      </CheckboxWrapper>
      <Container>
        <PromoContainer>
          {/* <PromoField placeholder="Введите промокод" />
        <OutlinedButton type="button">Активировать </OutlinedButton> */}
        </PromoContainer>
        {!isMobile && <PaymentButton />}
      </Container>

      <HealthDeclorationModal
        open={popupHealth}
        onClose={() => setPopupHealth(false)}
      />

      {values.isCharter ? (
        <CharterTariffsModal open={popup} onClose={() => setPopup(false)} />
      ) : (
        <FareCondition
          open={popup}
          onClose={() => {
            setPopup(false);
          }}
          routes={upt}
          fare={name}
        />
      )}
    </Wrapper>
  );
};

export default PaymentBlock;
