import React from 'react';
import { CreateFormProps } from './types';

const Steps = React.createContext<CreateFormProps>(null as any);

export const useProps = () => React.useContext(Steps);

export const CreateFormProvider: React.FC<CreateFormProps & React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  return <Steps.Provider value={props}>{children}</Steps.Provider>;
};
