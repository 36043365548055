import React from 'react';
import styled from 'styled-components';
import { Paragraph } from './shared';
import { Text, Button } from '@components/ui';
import { ReactComponent as InfoCircle } from '@assets/media/main-page/info-circle.svg';

const Wrapper = styled.div`
  background: #edfaf9;
  border-radius: 28px;
  padding: 45px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1169px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
    border-radius: 16px;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #3c3c3c;

  @media (max-width: 1169px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const Input = styled.input.attrs({ type: 'email', placeholder: 'Ваш email' })`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  padding: 15.5px 20px;
  width: 362px;
  border: 1px solid #d8f0ef;
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.2s;

  &:focus {
    border-color: #b3dddb;
  }

  @media (max-width: 1169px) {
    width: 100%;
  }
`;

const SubmitButton = styled(Button).attrs({ type: 'primary' })`
  padding: 12px 39.5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1169px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1169px) {
    display: block;

    & > :first-child {
      display: block;
      margin-bottom: 24px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
  & > span {
    display: block;
  }

  ${Title} {
    margin-bottom: 24px;
  }

  ${Paragraph} {
    margin-bottom: 20px;
  }

  ${Form} {
    margin-bottom: 24px;
  }
`;

const Round = styled.div`
  background: #49c8c0;
  border-radius: 500px;
  opacity: 0.1;
  transform: matrix(-0.71, -0.71, -0.71, 0.71, 0, 0);
  width: 263.89px;
  height: 331.41px;
  position: absolute;
  top: -138px;
  right: -101px;
  z-index: 1;
`;

const Round2 = styled.div`
  background: #49c8c0;
  opacity: 0.1;
  border-radius: 500px;
  transform: matrix(-0.71, -0.71, -0.71, 0.71, 0, 0);
  width: 309.87px;
  height: 412.5px;
  position: absolute;
  bottom: -205px;
  left: -71px;
  z-index: 1;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }

  @media (max-width: 1169px) {
    align-items: flex-start;
  }
`;

const GrayText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
`;

export default () => (
  <Wrapper>
    <Round />
    <Round2 />
    <Container>
      <Title>Будьте в курсе лучшего!</Title>
      <Paragraph>
        Подпишитесь на нашу рассылку и получайте самые горячие предложения
        раньше всех.
      </Paragraph>
      <Form>
        <Input />
        <SubmitButton>Подписаться</SubmitButton>
      </Form>
      <Bottom>
        <InfoCircle />
        <GrayText>
          Нажимая «Подписаться», я даю согласие на обработку персональных данных
        </GrayText>
      </Bottom>
    </Container>
  </Wrapper>
);
