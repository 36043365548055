import React from 'react';
import styled from 'styled-components';
import { getSelectedFare } from '@modules/booking';
import { useSelector } from 'react-redux';
import { Text } from '@components/ui';
import { ReactComponent as Clock } from '@assets/media/shared/orange-clock.svg';

const Container = styled.div`
  padding: 14px 24px;
  background: #fff3cd;
  border: 1px solid #ffb800;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 12.5px;
    flex-shrink: 0;
  }

  & > span > span {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 14px;

    & > svg {
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }

    & > span > span {
      font-weight: 600;
    }
  }
`;

export default function TimeLimit() {
  const { timeLimitDuration } = useSelector(getSelectedFare);

  return (
    <Container>
      <Clock />
      <Text>
        После оформления бронирования у вас будет{' '}
        <span>{timeLimitDuration}</span> на оплату
      </Text>
    </Container>
  );
}
