import React from 'react';
import styled from 'styled-components';
import { InfoBlock } from '@components/common';
import { Text } from '@components/ui';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { signIn } from '@modules/user';
import { useHistory } from 'react-router-dom';
import { AUTH_MODAL_STATUSES, openAuthModal } from '@modules/ui';
import SignInForm from './SignInForm';
import BottomAuthOrTinkoffPart from '@components/authModals/SignIn/BottomAuthOrTinkoffPart';

const { Header } = Text;

const StyledHeader = styled(Header)`
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 28px;
  line-height: 40px;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 55px;
  }
`;

const Container = styled(InfoBlock)`
  width: 468px;
  align-self: center;

  @media (max-width: 767px) {
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15% 20px;
    width: 100%;
    box-shadow: none;
  }
`;

const SignIn: React.FC<{ disableRedirect?: boolean; notModal?: boolean }> = (
  props
) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const history = useHistory<{ from?: string }>();
  const initialValues = {
    password: '',
    login: '',
    remember: false,
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tteAuthId = params.get('tteAuthId');

  const urlParams = new URLSearchParams(window.location.search);
  const agentId = urlParams.get('agentId');

  const onSubmit = (data: typeof initialValues) => {
    if (!tteAuthId) {
      dispatch(
        signIn({
          ...data,
          redirect: history.location.state?.from,

          resolve: () => {
            dispatch(openAuthModal(AUTH_MODAL_STATUSES.EMPTY));
          },
        })
      );
    }
    if (tteAuthId) {
      dispatch(
        signIn({
          ...data,
          redirect: history.location.state?.from,
          isAgent: true,
          resolve: () => {
            dispatch(openAuthModal(AUTH_MODAL_STATUSES.EMPTY));
          },
        })
      );
    }
  };

  return (
    <Container ref={ref}>
      <StyledHeader>Вход в Личный кабинет</StyledHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          password: yup.string().required('Заполните'),
          login: yup.string().required('Заполните'),
        })}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <SignInForm
            {...formikProps}
            notModal={props.notModal}
            tteAuthId={tteAuthId}
          />
        )}
      </Formik>
      <BottomAuthOrTinkoffPart
        onSignUp={() => {
          if (props.notModal) {
            history.push('/sign-up');
          } else {
            dispatch(openAuthModal(AUTH_MODAL_STATUSES.Registration));
          }
        }}
      />
    </Container>
  );
};

export default SignIn;
