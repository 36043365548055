import React from 'react';
import styled from 'styled-components';
import { FlightItemPassiveProps } from '@components/flightsSearch';
import { Helper } from '@utils';
import Text from '@components/ui/Typography';
import Place from '../../flightsSearch/TicketList/Place';
import TechLanding from '@components/common/FlightSegment/TechLanding';
import AviaInfoRow from '@components/common/FlightSegment/AviaInfoRow';
import Timeline from '@components/common/FlightSegment/Timeline';

const ItemContainer = styled.li`
  list-style-type: none;
  position: relative;
  font-family: ${({ theme: { fonts } }) => fonts.regular};
`;

const FlightTime = styled.span<{ blue?: boolean }>`
  display: inline;
  font-size: 10px;
  line-height: 14px;
  color: ${({ blue, theme: { colors } }) => (blue ? colors.main : '#737373')};
  text-align: center;
  @media (max-width: 767px) {
    display: none;
  }
`;

const PlaceDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > :last-child {
    text-align: right;
  }
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12.5px;

  & > :first-child {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const LogoDescription = styled(Text)`
  font-size: 12px;
  line-height: 16px;
  color: #3c3c3c;
`;

const Image = styled.img.attrs({ width: 21.45, height: 21.45 })`
  border-radius: 50%;
  background: #9097ce;
`;

const AviaMainSegment: React.FC<FlightItemPassiveProps> = (props) => {
  const {
    from,
    to,
    fromDate,
    toDate,
    flightNumbers,
    stops,
    techLanding,
    duration,
    airlines,
    baggageDescr,
  } = props;

  const travelTime = Helper.formatDuration(duration);
  const hasStops = stops.length > 0;
  const hasTechLanding = techLanding.length > 0;
  const stopString = hasStops
    ? `${stops.length} ${Helper.pluralWord(
        stops.length,
        'пересадка',
        'пересадки',
        'пересадок',
      )}`
    : 'прямой';

  return (
    <div>
      <ItemContainer>
        <Timeline
          fromDate={fromDate.format('HH:mm')}
          toDate={toDate.format('HH:mm')}
          stops={stops}
          techLanding={techLanding}
          duration={duration}
        />
        <PlaceDateWrapper>
          <Place {...from} date={fromDate} />
          <FlightTime>
            В пути: {travelTime},{' '}
            <FlightTime blue={hasStops}>{stopString}</FlightTime>{' '}
            {hasTechLanding && <TechLanding />}
          </FlightTime>
          <Place {...to} date={toDate} />
        </PlaceDateWrapper>
      </ItemContainer>
      {airlines.map((x, key) => (
        <CompanyLogoMobile key={key}>
          <Image
            src={Helper.getAirlinesLogoUrl(x.operatingAirlineCode, 65, 65)}
            alt={x.operatingAirlineName}
          />{' '}
          <LogoDescription>{x.operatingAirlineName}</LogoDescription>
        </CompanyLogoMobile>
      ))}
      {baggageDescr && (
        <AviaInfoRow
          type={'luggage'}
          title={''}
          noBaggage={baggageDescr === 'Без багажа'}
          baseItems={[baggageDescr]}
        />
      )}
      <AviaInfoRow type={'aircraft'} title={'Рейс'} baseItems={flightNumbers} />
    </div>
  );
};

export default AviaMainSegment;
