import _ from 'lodash';
import { ReducerState, InnerSegment } from '../normalizer';
import { refundReasons } from '../static/Refund';

export const initialSelect = { label: '', value: '', code: '' };

export const getInitialReason = () => {
  const params = window.location.search;
  const reason = params.includes('reason') ? params.slice(8) : undefined;
  const initialReason = reason
    ? refundReasons.find((el) => el.value === reason)!
    : initialSelect;

  return initialReason;
};

export function reducer(state: ReducerState, action: any): ReducerState {
  const Stater = new StateUpdater();
  switch (action.type) {
    case 'addPassenger': {
      return Stater.consumeState(state)
        .setActivePassenger(action.payload)
        .setSelectedSPassengers(
          _.uniq([...state.selectedPassengers, action.payload])
        )
        .updatePassengersList()
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue();
    }
    case 'removePassenger': {
      const SELECTED_PASSENGERS = _.without(
        state.selectedPassengers,
        action.payload
      );
      const mealNamesArr =
        SELECTED_PASSENGERS.length > 0 ? state.mealNamesArr : {};

      const lastPassenger =
        SELECTED_PASSENGERS.length > 0
          ? SELECTED_PASSENGERS[SELECTED_PASSENGERS.length - 1]
          : '';

      const newState = {
        ...state,
        activePassenger: lastPassenger,
        mealNamesArr,
      };

      return Stater.consumeState(newState)
        .setActivePassenger(lastPassenger)
        .setSelectedSPassengers(SELECTED_PASSENGERS)
        .updatePassengersList()
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue();
    }

    case 'addAllPassengers': {
      return Stater.consumeState(state)
        .setActivePassenger(state.passengerList[0].value)
        .setSelectedSPassengers(_.uniq(state.passengerList.map((x) => x.value)))
        .updatePassengersList()
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue();
    }

    case 'addSegment': {
      const SELECTED_SEGMENTS_IDS = _.uniq([
        ..._(state.passengers)
          .map((x) =>
            _(x.selectedSegments)
              .map((x) => x.value)
              .value()
          )
          .flatten()
          .value(),
        action.payload,
      ]);

      return Stater.consumeState(state)
        .setSelectedSegments(SELECTED_SEGMENTS_IDS as any)
        .updateSegments(action.payload)
        .updateAvailableAncillaries()
        .getValue();
    }

    case 'addAllSegment': {
      const SELECTED_SEGMENTS_IDS = _.uniq([
        ..._(state.passengers)
          .map((x) =>
            _(x.segmentList)
              .map((x) => x.value)
              .value()
          )
          .flatten()
          .value(),
      ]);

      return Stater.consumeState(state)
        .setSelectedSegments(SELECTED_SEGMENTS_IDS as any)
        .updateSegments(SELECTED_SEGMENTS_IDS[0])
        .updateAvailableAncillaries()
        .getValue();
    }
    case 'switchInsurance': {
      const newInsurances = state.passengers[
        action.payload.passenger
      ].insurances.map((item) => ({
        ...item,
        checked:
          item.value === action.payload.id ? !item.checked : item.checked,
      }));

      const newPassengers: any = {
        ...state.passengers,
        [action.payload.passenger]: {
          ...state.passengers[action.payload.passenger],
          insurances: newInsurances,
          showPassengerInList: _(newInsurances).find((item) => item.checked)
            ? true
            : false,
        },
      };
      return {
        ...state,
        activePassenger: '',
        showPassengersList: _(newPassengers).find(
          (item) => item.showPassengerInList
        )
          ? true
          : false,
        passengers: newPassengers,
      };
    }
    case 'removeSegment': {
      const SELECTED_SEGMENTS = _.without(
        state.selectedSegments,
        action.payload as string
      );

      return Stater.consumeState(state)
        .setSelectedSegments(SELECTED_SEGMENTS)
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue();
    }
    case 'addAncillary': {
      const SELECTED_MEALS = {
        ...state.mealNamesArr,
        [action.payload]: action.payload,
      } as { [key: string]: string };

      return Stater.consumeState(state)
        .setAncillaries(SELECTED_MEALS)
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue();
    }

    case 'removeAncillar': {
      const SELECTED_MEALS = {
        ...state.mealNamesArr,
        [action.payload]: undefined,
      } as { [key: string]: string };

      return Stater.consumeState(state)
        .setAncillaries(SELECTED_MEALS)
        .updateSegments()
        .updateAvailableAncillaries()
        .getValue();
    }
    default:
      throw new Error();
  }
}

class StateUpdater {
  private state: ReducerState = null as any;

  consumeState(state: ReducerState) {
    this.state = { ...state };
    return this;
  }

  setSelectedSegments(args: string[]) {
    this.state.selectedSegments = args;
    return this;
  }

  setSelectedSPassengers(args: string[]) {
    this.state.selectedPassengers = args;
    return this;
  }

  setAncillaries(args: { [key: string]: string }) {
    this.state.mealNamesArr = args;
    return this;
  }

  setActivePassenger(value: string) {
    this.state.activePassenger = value;
    return this;
  }

  updatePassengersList() {
    this.state.passengers = _(this.state.passengers)
      .mapValues((x) => ({
        ...x,
        selected: !!this.state.selectedPassengers.find((y) => y === x.value),
      }))
      .value();

    this.state.passengerList = this.state.passengerList.map((x) => ({
      ...x,
      checked: !!this.state.selectedPassengers.find((y) => y === x.value),
    }));

    return this;
  }

  updateSegments(activeSegment?: string) {
    this.state.passengers = _(this.state.passengers)
      .map((x) => {
        if (!this.state.selectedPassengers.includes(x.value)) {
          return x;
        }

        return {
          ...x,
          activeSegment: activeSegment || '',
          selectedSegments: _(x.segmentList)
            .filter((x) => this.state.selectedSegments.includes(x.value))
            .map((x) => x as InnerSegment)
            .map((x) => ({
              ...x,
              selectedAncillaryServices: x.ancillaryServices
                .filter((x) => this.state.mealNamesArr[x.label] !== undefined)
                .map((x) => x.value),
            }))
            .keyBy((x) => x.value)
            .value(),
        };
      })
      .map((x) => ({
        ...x,
        segmentList: x.segmentList.map((y) => ({
          ...y,
          checked: !!x.selectedSegments[y.value],
        })),
      }))
      .keyBy((x) => x.value)
      .value();
    return this;
  }

  updateAvailableAncillaries() {
    this.state.availableAncillaries = _(this.state.passengers)
      .map((x) => {
        return _(x.selectedSegments)
          .map((x) => x.ancillaryServices)
          .flatten()
          .uniqBy((x) => x.label)
          .value();
      })
      .flatten()
      .uniqBy((x) => x.label)
      .map((x) => ({ ...x, value: x.label, id: x.value }))
      .value();

    return this;
  }

  getValue() {
    return this.state;
  }
}
