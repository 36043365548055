import styled from 'styled-components';

export const Info = styled.div`
  padding: 10px;
  background-color: #fff3cd;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  line-height: 18px;

  @media (min-width: 768px) {
    padding: 14px;
  }

  svg {
    width: 17px;
    height: 17px;
    position: relative;

    path {
      stroke: #3c3c3c;
    }
  }
`;
