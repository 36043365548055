import React from 'react';
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import packageJson from '../../../../package.json';
import { ReactComponent as PersonIcon } from '@assets/media/shared/person.svg';
import { ReactComponent as OutdoorIcon } from '@assets/media/header/outdoor.svg';
import { ReactComponent as InDoorIcon } from '@assets/media/header/indoor.svg';
import { ReactComponent as PassengersIcon } from '@assets/media/header/passengers.svg';
import { ReactComponent as MessageIcon } from '@assets/media/header/message.svg';
import { ReactComponent as CartIcon } from '@assets/media/header/cart.svg';

import { ReactComponent as DzenIcon } from '@assets/media/footer/icon-dzen.svg';
import { ReactComponent as TelegramIcon } from '@assets/media/footer/icon-telegram.svg';
import { ReactComponent as VkIcon } from '@assets/media/footer/icon-vk.svg';
import { ReactComponent as YoutubeIcon } from '@assets/media/footer/icon-youtube.svg';
import { ReactComponent as EmailIcon } from '@assets/media/header/email.svg';
import Cross from '../../../assets/images/head/mobile-menu/cross';
import { CSSTransition } from 'react-transition-group';
import { logout, getAuthStatus } from '@modules/user';
import { useDispatch, useSelector } from 'react-redux';

import { AUTH_MODAL_STATUSES, openAuthModal } from '@modules/ui';
import { ApplicationState } from '@modules/index';

const modalRoot = document.querySelector('#mobile-menu') as Element;
const TRANSITION_CLASS = 'mobile-nav';

const fullScreenStyles = css`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
`;

const Container = styled.div`
  ${fullScreenStyles};
  position: fixed;
  z-index: 9999;
  background: transparent;
  &.${TRANSITION_CLASS}-exited {
    display: hidden;
    transform: translateY(100%);
  }
`;

const Overlay = styled.div`
  ${fullScreenStyles};
  background: #00000085;
  z-index: 1;
  transition: background 0.3s ease-in-out;

  &.${TRANSITION_CLASS}-exiting {
    background: transparent;
  }
  &.${TRANSITION_CLASS}-exited {
    background: transparent;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  padding: 32px 0 40px 0;
  width: 219px;
  transition: transform 0.3s ease-in-out;
  transform-origin: 219px;
  &.${TRANSITION_CLASS}-entering {
    transform: translateX(0);
  }
  &.${TRANSITION_CLASS}-entered {
    transform: translateX(0);
  }
  &.${TRANSITION_CLASS}-exiting {
    transform: translateX(219px);
  }
  &.${TRANSITION_CLASS}-exited {
    transform: translateX(219px);
  }
`;

const Nav = styled.nav`
  display: block;
  min-width: 219px;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  height: 40px;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #2e2e32;
  text-decoration: none;
  padding: 0 12px;
  &.active {
    background: #edf1fe;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #2e2e32;
  text-decoration: none;
  padding: 0 12px;
  &.active {
    background: #edf1fe;
  }
`;

const IconWrapper = styled.div`
  width: 23.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
`;

const Footer = styled.div`
  padding: 0 13px;
`;

const SocialMedia = styled.div`
  & > a {
    margin-right: 12px;
  }
`;

const CloseButton = styled(Cross)`
  position: absolute;
  top: 8px;
  right: 8px;
  & > path {
    pointer-events: none;
  }
`;

const ContactsList = styled.div`
  & > div {
    margin-bottom: 13px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  margin-bottom: 14px;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 8px;
  }
`;

const Link = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2e2e32;
  text-decoration: none;
`;

const SocialLink = styled.a.attrs({ target: '_blank' })`
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.3s;
  & > svg {
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
`;

const VersionContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
  line-height: 16px;
  color: #737373;
`;

const Modal: React.FC<React.PropsWithChildren> = ({ children }) => {
  return ReactDOM.createPortal(children, modalRoot);
};

const MobileMenu: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isAutorized = useSelector(getAuthStatus);
  const user = useSelector((x: ApplicationState) => x.user);

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  return (
    <Modal>
      <CSSTransition in={open} timeout={300} classNames="mobile-nav">
        {(state) => (
          <Container className={`${TRANSITION_CLASS}-${state}`}>
            <Overlay
              className={`${TRANSITION_CLASS}-${state}`}
              onClick={onClose}
            />

            <Menu className={`${TRANSITION_CLASS}-${state}`}>
              <CloseButton onClick={onClose} />
              {!isAutorized && (
                <Nav>
                  <StyledDiv
                    onClick={(e) => {
                      dispatch(openAuthModal(AUTH_MODAL_STATUSES.AUTH));
                      onClose();
                    }}
                  >
                    <IconWrapper>
                      <InDoorIcon height={25} width={25} />
                    </IconWrapper>
                    Войти
                  </StyledDiv>

                  <StyledDiv
                    onClick={(e) => {
                      dispatch(openAuthModal(AUTH_MODAL_STATUSES.Registration));
                      onClose();
                    }}
                  >
                    <IconWrapper>
                      <PersonIcon height={25} width={25} />
                    </IconWrapper>
                    Зарегистрироваться
                  </StyledDiv>
                </Nav>
              )}
              {isAutorized && (
                <Nav>
                  <StyledNavLink onClick={onClose} to="/profile/contacts">
                    <IconWrapper>
                      <PersonIcon height={25} width={25} />
                    </IconWrapper>
                    Учетные данные
                  </StyledNavLink>
                  <StyledNavLink onClick={onClose} to="/profile/passengers">
                    <IconWrapper>
                      <PassengersIcon height={25} width={25} />
                    </IconWrapper>
                    Мои пассажиры
                  </StyledNavLink>
                  <StyledNavLink onClick={onClose} to="/orders">
                    <IconWrapper>
                      <CartIcon height={25} width={25} />
                    </IconWrapper>
                    Мои заказы
                  </StyledNavLink>

                  <StyledNavLink onClick={onClose} to="/tickets">
                    <IconWrapper>
                      <MessageIcon height={25} width={25} />
                    </IconWrapper>
                    Обращения
                  </StyledNavLink>
                  {user?.data?.agentId && (
                    <StyledNavLink onClick={onClose} to="/profile/agent">
                      <IconWrapper>
                        <PersonIcon height={25} width={25} />
                      </IconWrapper>
                      Финансы и отчеты агента
                    </StyledNavLink>
                  )}

                  <StyledNavLink
                    to="/sign-in"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(logout());
                      onClose();
                    }}
                  >
                    <IconWrapper>
                      <OutdoorIcon />
                    </IconWrapper>
                    Выйти
                  </StyledNavLink>
                </Nav>
              )}
              <Footer>
                <ContactsList>
                  {/* <div>
                    <Contact>
                      <PhoneIcon />
                      <Link href="tel:+74957254083">8 495 725 40 83</Link>
                    </Contact>
                    <SmallText>Для звонков по России</SmallText>
                  </div> */}
                  <Contact>
                    <EmailIcon />
                    <Link href="mailto:aviation@fstravel.com">
                      aviation@fstravel.com
                    </Link>
                  </Contact>
                </ContactsList>

                <SocialMedia>
                  <SocialLink href="https://vk.com/funsun_travel">
                    <VkIcon />
                  </SocialLink>
                  <SocialLink href="https://t.me/funsuntoday">
                    <TelegramIcon />
                  </SocialLink>
                  <SocialLink href="https://www.youtube.com/channel/UCzhyD3w6McLE_JyCkFAG-Ow">
                    <YoutubeIcon />
                  </SocialLink>
                  <SocialLink href="https://dzen.ru/fstravel">
                    <DzenIcon />
                  </SocialLink>
                </SocialMedia>
                <VersionContainer>v{packageJson.version}</VersionContainer>
              </Footer>
            </Menu>
          </Container>
        )}
      </CSSTransition>
    </Modal>
  );
};

export default MobileMenu;
