import styled from 'styled-components';
import ArrowIcon from '../../../../assets/images/left-right-arrow';

export const Code = styled.span`
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme: { colors } }) => colors.gray};
  pointer-events: none;

  &.offset {
    right: 12px;
  }

  right: 35px !important;
  bottom: 32% !important;
`;

export const Wrapper = styled.div`
  display: flex;
  ${({ theme: { borderRadius } }) => `
        position: relative;
        
        & input {
            width: 218px;
            border-radius: ${borderRadius} 0 0 ${borderRadius} ;
            padding-right: 58px;
            border-right: 1px solid #DCDCDC;
            @media (max-width: 1024px){
              border-radius: ${borderRadius} ;
            }
        }
        & > :last-child {
            & input {
              border-radius: 0 !important;
              @media (max-width: 1024px){
                border-radius: ${borderRadius} ;
              }
              @media (min-width: 767px){
                padding-left: 24px;

              }
                border-radius: 0 ${borderRadius} ${borderRadius} 0;
            }
        }
    `}

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 767px) {
    width: 100%;

    display: flex;
    flex-direction: column;
  }
`;

export const Arrow = styled(ArrowIcon)`
  width: 23px;
  height: 28px;
  position: absolute;
  z-index: 4;
  left: 50%;
  top: 50%;
  transform: translate(-62%, -50%);
  & path,
  rect {
    pointer-events: none;
  }
  & path {
    transition: fill 0.2s ease-in-out;
    fill: ${({ theme: { colors } }) => colors.lightGray};
  }
  &.active {
    cursor: pointer;
    & path {
      fill: ${({ theme: { colors } }) => colors.main};
    }
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

interface SuggestionWrapperProps {
  position: 'to' | 'from' | '';
}

export const SuggestionWrapper = styled.div<SuggestionWrapperProps>`
  ${({ position }) => {
    switch (position) {
      case 'from':
        return 'left: 0;';
      case 'to':
        return 'right: 0;';
      default:
        return 'display: none;';
    }
  }}
  position: absolute;
  top: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  z-index: 100;
  width: 330px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;

  @media (max-width: 1024px) {
    width: 347px;
    top: 50px;
  }

  @media (max-width: 425px) {
    width: 347px;

    top: ${({ position }) => (position === 'from' ? 53 : 133)}px;
    left: 0;
  }
`;

export const CitySelector = styled.ul`
  border-bottom: 1px solid #f2f2f2;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
export const CityItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  cursor: pointer;
  &.focus {
    background-color: #f2f2f2;
  }
  padding: 0 16px 0 12px;
`;

export const CityItemText = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CityItemTitle = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme: { colors } }) => colors.blackGray};
`;
export const CityItemDescription = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme: { colors } }) => colors.gray};
`;

export const CityCode = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme: { colors } }) => colors.gray};
`;
