import styled from 'styled-components';

export const Block = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  @media (max-width: 767px) {
    padding: 14px;
  }
`;
