import React from 'react';

const FailureOrderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 23C46 29.1 43.5768 34.9501 39.2635 39.2635C34.9501 43.5768 29.1 46 23 46C16.9 46 11.0499 43.5768 6.73654 39.2635C2.42321 34.9501 0 29.1 0 23C0 16.9 2.42321 11.0499 6.73654 6.73654C11.0499 2.42321 16.9 0 23 0C29.1 0 34.9501 2.42321 39.2635 6.73654C43.5768 11.0499 46 16.9 46 23ZM34.0802 13.9552C34.3502 13.6853 34.5018 13.3192 34.5018 12.9375C34.5018 12.5558 34.3502 12.1897 34.0802 11.9198C33.8103 11.6498 33.4442 11.4982 33.0625 11.4982C32.6808 11.4982 32.3147 11.6498 32.0448 11.9198L23 20.9674L13.9552 11.9198C13.8216 11.7861 13.6629 11.6801 13.4883 11.6077C13.3137 11.5354 13.1265 11.4982 12.9375 11.4982C12.7485 11.4982 12.5613 11.5354 12.3867 11.6077C12.2121 11.6801 12.0534 11.7861 11.9198 11.9198C11.7861 12.0534 11.6801 12.2121 11.6077 12.3867C11.5354 12.5613 11.4982 12.7485 11.4982 12.9375C11.4982 13.1265 11.5354 13.3137 11.6077 13.4883C11.6801 13.6629 11.7861 13.8216 11.9198 13.9552L20.9674 23L11.9198 32.0448C11.6498 32.3147 11.4982 32.6808 11.4982 33.0625C11.4982 33.4442 11.6498 33.8103 11.9198 34.0802C12.1897 34.3502 12.5558 34.5018 12.9375 34.5018C13.3192 34.5018 13.6853 34.3502 13.9552 34.0802L23 25.0326L32.0448 34.0802C32.1784 34.2139 32.3371 34.3199 32.5117 34.3923C32.6863 34.4646 32.8735 34.5018 33.0625 34.5018C33.2515 34.5018 33.4387 34.4646 33.6133 34.3923C33.7879 34.3199 33.9466 34.2139 34.0802 34.0802C34.2139 33.9466 34.3199 33.7879 34.3923 33.6133C34.4646 33.4387 34.5018 33.2515 34.5018 33.0625C34.5018 32.8735 34.4646 32.6863 34.3923 32.5117C34.3199 32.3371 34.2139 32.1784 34.0802 32.0448L25.0326 23L34.0802 13.9552Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default FailureOrderIcon;
