import { Checkbox, Text } from '@components/ui';
import Tippy from '@tippyjs/react';
import React from 'react';
import styled from 'styled-components';

interface IProps {
  isAgree: boolean;
  isDisable: boolean;
  setIsAgree: (value: boolean) => void;
  orderNumber: number;
  totalRefund: string;
}

const RefundAgreement = ({
  isAgree,
  isDisable,
  setIsAgree,
  orderNumber,
  totalRefund,
}: IProps) => {
  return (
    <Text fontWeight={'600'} size={'big'}>
      <CheckBoxWrapper>
        <Checkbox
          type="squar"
          checked={isAgree}
          onChange={(e) => {
            setIsAgree(e.target.checked);
          }}
          disabled={isDisable}
        />
        <Text style={{ marginTop: '4px' }}>
          Я ознакомлен и согласен с договором{' '}
          <a
            style={{ color: '#4872F2', textDecoration: 'none' }}
            href="/docs/oferta.pdf"
            target="_blank"
          >
            публичной оферты
          </a>{' '}
          и{' '}
          <Tippy
            content={`Подтверждаю возврат по заказу ${orderNumber}. С возвратом в размере ${totalRefund} согласен.`}
          >
            <Text color="blue" style={{ cursor: 'pointer' }}>
              текстом заявления{' '}
            </Text>
          </Tippy>{' '}
        </Text>
      </CheckBoxWrapper>
    </Text>
  );
};

const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default RefundAgreement;
