import React from 'react';
import { SeatsMapProps, SeatMapContextProps } from './types';
import { transformDecksToSelectedSeatsObject } from './utils';

const SeatsMapContext = React.createContext<SeatMapContextProps>(null as any);

export const useSeatsMap = () => React.useContext(SeatsMapContext);

export function SeatMapProvider({
  children,
  ...props
}: React.PropsWithChildren<SeatsMapProps>) {
  const [selectedSeats, setSelectedSeats] = React.useState(
    transformDecksToSelectedSeatsObject(props.decks)
  );
  const [selectedDeckIndex, setSelectedDeckIndex] = React.useState(0);

  const handlePlaceClick = (number: string) => {
    if (selectedSeats[props.decks[selectedDeckIndex].type][number]) {
      selectedSeats[props.decks[selectedDeckIndex].type][number] = false;
    } else {
      selectedSeats[props.decks[selectedDeckIndex].type][number] = true;
    }
    setSelectedSeats({ ...selectedSeats });
  };

  return (
    <SeatsMapContext.Provider
      value={{
        selectedSeats: props.selectedSeats,
        selectedDeckIndex,
        setSelectedDeckIndex,
        handlePlaceClick,
        onPlaceSelect: props.onPlaceSelect,
        pricesArr: props.pricesArr,
      }}
    >
      {
        // @ts-ignore
        (children as React.FC<SeatMapContextProps>)({
          selectedSeats: props.selectedSeats,
          selectedDeckIndex,
          setSelectedDeckIndex,
          handlePlaceClick,
          onPlaceSelect: props.onPlaceSelect,
          pricesArr: props.pricesArr,
        }) as any as React.ExoticComponent<SeatMapContextProps>
      }
    </SeatsMapContext.Provider>
  );
}
