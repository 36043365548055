import { put, call, select } from 'typed-redux-saga';
import { Action } from 'redux-act';
import { BookFlightPayload, InsuranceLoadStatus } from '../../types';
import { getUserStatusState } from '@modules/user';
import { showNotification } from '@modules/notification';
import { bookTicket, signUp } from '../workers';
import { setStatus, setAuthPopup, showInsuranceNotification } from '../../duck';
import {
  bookingInsuranceSelector,
  getBookingTicketState,
  getIsMobileState,
} from '../../selectors';
import * as Sentry from '@sentry/react';

//ANCHOR bookFlightSaga используется когда пользователь заполняет данные о пассажирах, опциях на странице /booking и хочет его забронировать
export function* bookFlightRequestFlow({ payload }: Action<BookFlightPayload>) {
  yield put(setStatus(true));
  try {
    const { isAuthorized } = yield* select(getUserStatusState);
    const insurances = yield* select(bookingInsuranceSelector);
    const ticketState = yield* select(getBookingTicketState);
    if (
      ticketState.insuranceNotification !== false &&
      insurances.status === InsuranceLoadStatus.Success &&
      insurances.list.offers.filter((x) => x.checked).length === 0
    ) {
      yield* put(showInsuranceNotification());
    } else {
      if (!isAuthorized) {
        yield* call(signUp, payload);
      } else {
        yield* call(bookTicket, payload);
      }
    }
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    yield put(setStatus(false));

    yield* put(
      showNotification({
        code: e.name,
        message: e.message,
        data: e.data,
        booking: payload,
      })
    );
    yield* put(setAuthPopup(false));
  }
  yield put(setStatus(false));
}
