import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { ApplicationState } from '@modules/index';
import { getDetailOrder } from '@modules/orders';
import { generateIconPath } from '@components/aviaPassengers/utils';
import arrowSrc from '@assets/media/order-detail/arrow1.svg';
import moment from 'moment/moment';
import man1 from '@assets/media/avia-passengers/man-1.svg';
import woman1 from '@assets/media/avia-passengers/woman-1.svg';
import boy1 from '@assets/media/avia-passengers/boy-1.svg';
import girl1 from '@assets/media/avia-passengers/girl-1.svg';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    overflow: scroll;
  }

  &-content {
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0;
    border-radius: 8px;
    // height: calc(var(--vh, 1vh) * 95);
    display: flex;
    width: auto;
    overflow: hidden;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: fit-content;

    @media (min-height: 878px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      display: block;
      overflow: scroll;
      height: calc(var(--vh, 1vh) * 100);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
`;
const Title = styled.div`
  font-family: 'Open Sans', serif;
  margin-left: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px !important;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  margin-top: 15px;
`;

const SubTitle = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;
  margin-bottom: 5px;
`;

const SubText = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c3c3c;
`;

const Divider = styled.div`
  width: 90%;
  height: 1px;
  border-radius: 8px;
  background-color: #f2f2f2;
  margin: 20px 0 0 20px;
`;

const InsideDivider = styled.div`
  width: 90%;
  height: 1px;
  border-radius: 8px;
  background-color: #dcdcdc;
  margin-top: 7px;
`;

const Icon = styled.img<{ reverse: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-left: 5px;
  ${({ reverse }) => (reverse ? '' : 'transform: rotate(180deg);')}
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  outline: none;
  z-index: 100;

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    position: fixed;
  }
`;

interface Props {
  isCheckPassangersModalOpen: boolean;
  setIsCheckPassangersModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CheckPassangersModal: React.FC<Props> = ({
  isCheckPassangersModalOpen,
  setIsCheckPassangersModalOpen,
}) => {
  const { orderPositions } = useSelector(getDetailOrder);
  const [open, setOpen] = React.useState<number[]>([]);

  function getPassengerTypeDescription(str: string) {
    switch (str) {
      case 'Adult':
        return 'взрослый';
      case 'Child':
        return 'ребенок';
      default:
        return 'младенец';
    }
  }

  function getImage(gender: string, birthDate: string) {
    const birth = moment(birthDate);
    const duration = moment.duration(moment().diff(birth));
    const age = duration.years();
    if (gender === 'Male' && age > 18) {
      return man1;
    } else if (gender === 'Male' && age < 18) {
      return boy1;
    } else if (gender !== 'Male' && age < 18) {
      return girl1;
    } else return woman1;
  }

  function getPassportDescription(type: string) {
    switch (type) {
      case 'RussianFederationPassport':
        return 'Паспорт РФ';
      case 'BirthCertificate':
        return 'Свидетельство о рождении';
      case 'InternationalPassport':
        return 'Загран. паспорт';
      case 'NationalPassport':
        return 'Нац. паспорт';
      default:
        return 'Другой документ';
    }
  }

  function getGenderDescription(type: string) {
    return type === 'Male' ? 'Мужской' : 'Женский';
  }

  const toggleOpen = (i: number) => {
    if (open.includes(i)) {
      setOpen(open.filter((x: any) => x !== i));
    } else {
      const newOpen = [...open];
      newOpen.push(i);
      setOpen(newOpen);
    }
  };

  const passangers = orderPositions
    .map((x) => x.passengers.map((pas) => pas))
    .flat();

  return (
    <StyledPopup
      onClose={() => {
        setIsCheckPassangersModalOpen(false);
      }}
      open={isCheckPassangersModalOpen}
    >
      <Container>
        <Title>Данные пассажиров</Title>
        <Cross
          onClick={() => {
            setIsCheckPassangersModalOpen(false);
          }}
        />

        {passangers.map((pas, i) => (
          <>
            <Row onClick={() => toggleOpen(i)}>
              <img
                width={40}
                height={40}
                style={{ borderRadius: 8, marginRight: 7 }}
                src={getImage(pas.gender, pas.birthDate)}
              />
              {pas.lastName +
                ' ' +
                pas.firstName +
                ' ' +
                (pas.patronymic ? pas.patronymic : '') +
                ', '}
              {getPassengerTypeDescription(pas.passengerType)}
              <Icon reverse={open.includes(i)} src={arrowSrc} alt="arrow" />
            </Row>
            {i !== passangers.length - 1 && !open.includes(i) && <Divider />}
            {open.includes(i) && (
              <>
                {/* <Column>
                  <SubTitle>Фамилия</SubTitle>
                  <SubText>{pas.lastName}</SubText>
                  <InsideDivider />
                </Column>
                <Column>
                  <SubTitle>Имя</SubTitle>
                  <SubText>{pas.firstName}</SubText>
                  <InsideDivider />
                </Column>
                {pas.patronymic && (
                  <Column>
                    <SubTitle>Отчество</SubTitle>
                    <SubText>{pas.patronymic}</SubText>
                    <InsideDivider />
                  </Column>
                )} */}
                <Column>
                  <SubTitle>Пол</SubTitle>
                  <SubText>{getGenderDescription(pas.gender)}</SubText>
                  <InsideDivider />
                </Column>
                <Column>
                  <SubTitle>Дата рождения</SubTitle>
                  <SubText>
                    {moment(pas.birthDate).format('DD.MM.YYYY')}
                  </SubText>
                  <InsideDivider />
                </Column>
                <Column>
                  <SubTitle>Гражданство</SubTitle>
                  <SubText>{pas.citizen}</SubText>
                  <InsideDivider />
                </Column>{' '}
                <Column>
                  <SubTitle>Документ</SubTitle>
                  <SubText>{getPassportDescription(pas.document.type)}</SubText>
                  <InsideDivider />
                </Column>
                <Column>
                  <SubTitle>Серия и номер документа</SubTitle>
                  <SubText>{pas.document.number}</SubText>
                  <InsideDivider />
                </Column>
              </>
            )}
          </>
        ))}
      </Container>
    </StyledPopup>
  );
};

export default CheckPassangersModal;
