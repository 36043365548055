import { GetTicketsListDto } from './types';

export function normalizeTicketsList(
  args: GetTicketsListDto
): GetTicketsListDto {
  return {
    ...args,
    trainsPerRoutes: args.trainsPerRoutes.map((x) => {
      return { ...x, trains: x.trains.map((x, index) => ({ ...x, index })) };
    }),
  };
}
