import React from 'react';

const AirplaneUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.48185 13.0286H15.4211V14.47H1.48185V13.0286ZM15.8513 6.34104C15.9316 6.62038 15.8972 6.88855 15.748 7.14555C15.5989 7.40255 15.3752 7.57574 15.0769 7.66513L11.1532 8.67078L7.98681 9.50882L4.06318 10.5145L2.89298 10.8497L2.34229 9.87755L1 7.63161L2.06695 7.32992L3.5125 8.43613L7.16079 7.49753L4.13202 2.36874L5.54315 2L10.6026 6.59245L14.4918 5.5868C14.7901 5.49741 15.0711 5.53093 15.335 5.68737C15.5989 5.8438 15.771 6.06169 15.8513 6.34104Z"
        fill="#737373"
      />
    </svg>
  );
};

export default AirplaneUpIcon;
