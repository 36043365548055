import React from 'react';

export type FocusType =
  | 'origin'
  | 'destination'
  | 'forward'
  | 'backward'
  | 'passengers'
  | null;

interface FocusContext {
  focus: FocusType;
  setFocus(value: FocusType): void;
}

const Steps = React.createContext<FocusContext>(null as any);

export const useFocus = () => React.useContext(Steps);

export const FocusFormProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [focus, setFocus] = React.useState(null as FocusType);

  return (
    <Steps.Provider value={{ focus, setFocus }}>{children}</Steps.Provider>
  );
};
