import {
  Tariff,
  getMainPosition,
  InsuranceEntity,
  OrderPosition,
  OrderDetailState,
} from '@modules/orders';
import { PassengerProps } from '../../types';

export type ItemProps = ReturnType<typeof getMainPosition> & {
  orderId: string;
  defaultOpen?: boolean;
  disabled?: boolean;
  mobileIndex: number;
  insurances: InsuranceEntity;
  isMobile?: boolean;
  item: OrderDetailState;
};

export interface FlightsListProps {
  items: ItemProps['segmentGroups'];
}

export interface PositionContextProviderProps
  extends Omit<ItemProps, 'defaultOpen'> {
  isOpen: boolean;
  setOpen(value: boolean): void;
}

export interface PositionContextProps extends PositionContextProviderProps {}

export interface TariffProps extends Tariff {
  className?: string;
  isBookingSecondModal?: boolean;
  position?: OrderPosition;
}

export interface PassengersProps {
  items: Omit<PassengerProps, 'passengerNo'>[];
}

export enum PositionAppealCode {
  FUll_EXCHANGE = 'FullExchange',
  FULL_REFUND = 'FullRefund',
  OTHER = 'Other',
  REFUND_ADDITIONAL_SERVICE = 'RefundAdditionalService',
}

export enum PositionAwailableReason {
  FULL_REFUND = 'Full Refund',
  FULL_REFUND_INVOLUNTARY = 'Full Refund/Involuntary',
  FULL_REFUND_SPLIT = 'Split / Full Refund',
  PARTIAL_REFUND = 'Partial Refund',
  PARTIAL_REFUND_INVOLUNTARY = 'Partial Refund/Involuntary',
}
