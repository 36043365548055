import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Text } from '@components/ui';
import { Helper } from '@utils';
import { EnhancedOfferByProductsEntity } from '@modules/booking';
import CheckIconSrc from '@assets/images/booking/check.svg';
import insurance7TabIcon from '@assets/media/insurances/insurance7TabIcon.svg';
import doctorCross from '@assets/media/insurances/doctorCross.svg';
import insuranceWoman from '@assets/media/insurances/insuranceWoman.svg';
import { Scrollbars } from 'react-custom-scrollbars';

import alphaStrah from '@assets/media/insurances/alphaStrah.svg';
import { useDispatch } from 'react-redux';

import smallArrow from '@assets/media/insurances/vector.svg';
import { SliderItemProps } from './types';
import { stickyFooterHide } from '@modules/orders';
import { useMediaQuery } from 'react-responsive';

interface Props {
  open: boolean;
  onClose: () => void;
  data: EnhancedOfferByProductsEntity | null;
  onChange(
    code: string,
    payload: Omit<
      SliderItemProps,
      'onChange' | 'disabled' | 'onClick' | 'index'
    >
  ): void;
}

const Icon = styled.img.attrs({ alt: 'icon' })`
  width: 35px;
  height: 35px;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  background: white;
  width: 493px;
  height: 80px;
  z-index: 10000;
  border-radius: 8px;
  ${Icon} {
    margin-right: 8px;
  }
  padding-left: 30px;

  @media (max-width: 767px) {
    width: 100%;
    padding-left: 20px;
  }
`;

const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3c3c3c;

  & > strong {
    font-weight: 600;
    color: #4872f2;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const BaseText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3c3c3c;

  & > a {
    color: #4872f2;
    text-decoration: none;
  }

  & > strong {
    font-weight: 600;
  }
`;

const Paragraph = styled(BaseText)`
  display: block;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 10px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  margin-bottom: 19px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const Feature = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 5px;
  }
`;

const CheckIcon = styled.img.attrs({ src: CheckIconSrc, height: 9, width: 13 })`
  margin-top: 5px;
  margin-right: 5px;
`;

const Agreement = styled(Paragraph)`
  // white-space: nowrap;

  // @media (max-width: 767px) {
  //   white-space: inherit;
  // }
`;

const Cross = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;
  outline: none;
  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme: { colors } }) => colors.darkGray};
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 17px;
    width: 2px;
    right: 3.5px;
    bottom: 0.5px;
    background-color: ${({ theme: { colors } }) => colors.main};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const Bottom = styled.div<LinkProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  position: relative;
  margin-bottom: ${({ isMobile }) => (isMobile ? '50px' : '50px')};
`;

const StyledPopup = styled(Popup)`
  position: absolute;
  &-content {
    position: absolute;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border-radius: 8px;
    width: 518px;
    padding-top: 0;
    ${Header} {
      margin-bottom: 24px;
    }

    ${Paragraph} {
      margin: 20px 0;
    }

    ${FeaturesContainer} {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    ${Agreement} {
      margin-bottom: 15px;
    }

    ${Cross} {
      position: absolute;
      top: 21px;
      right: 30px;
    }

    @media (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;

      overflow-x: hidden;

      border-radius: 0;
      border: none;

      ${Header} {
        margin-bottom: 15px;
      }

      .first {
        margin-top: 15px;
      }

      .second {
        margin-bottom: 10px;
      }

      ${FeaturesContainer} {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }

  &-overlay {
    z-index: 1001 !important;
  }
`;

const PaymentBlock = styled.div<LinkProps>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  background-color: ${({ color }) => color};
  width: fit-content;
  height: 38px;
  margin-top: 10px;
  font-weight: 600;
  font-family: Open Sans;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 7px;
  color: #3c3c3c;
  margin-top: 90px;
`;

const DoctorCross = styled.img.attrs({ src: doctorCross })`
  display: block;
  margin-right: 3px;
`;

const InsurIcon = styled.img.attrs({ src: insurance7TabIcon })`
  margin-right: 3px;
`;

const InsuranceWoman = styled.img.attrs({ src: insuranceWoman })<LinkProps>`
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 100;

  @media (max-width: 767px) {
    right: ${({ insurance }) =>
      insurance === 'Online-врач в поездку'
        ? '-40px'
        : insurance === 'Отмена поездки'
        ? '-25px'
        : '10px'};
    bottom: ${({ insurance }) =>
      insurance === 'Online-врач в поездку' ? '-40px' : '0'};
  }
`;

const AlphaStrah = styled.img.attrs({ src: alphaStrah })``;

const Arrow = styled.img.attrs({ src: smallArrow })<LinkProps>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};

  margin-left: 5px;
`;

const GrayBlock = styled.div<LinkProps>`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #edfaf9;
  border-radius: 100px;
  width: 381px;

  padding: ${({ insurance }) =>
    insurance === 'Online-врач в поездку'
      ? '20px 45px'
      : insurance === 'Отмена поездки'
      ? '25px 35px'
      : '15px 60px'};
  box-sizing: border-box;
  position: absolute;
  @media (max-width: 767px) {
    font-size: 13px;
    width: 300px;
  }
`;

const InfoText = styled.div<LinkProps>`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4872f2;
  cursor: pointer;
  margin-top: ${({ insurance }) =>
    insurance === 'Online-врач в поездку' ? '50px' : '20px'};
`;

const AbsoluteBlock = styled.div<LinkProps>`
  position: ${({ isMobile }) => (isMobile ? 'relative' : 'absolute')};
  bottom: 1px;
  width: 520px;
  height: 40px;
  background: white;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;

const ButtonContainer = styled.div<LinkProps>`
  position: fixed;
  z-index: 10000;
  bottom: ${({ isMobile }) => (isMobile ? '1px' : 'none')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '200px')};
  height: fit-content;
  left: ${({ isMobile }) => (isMobile ? '0' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddButton = styled.div<LinkProps>`
  padding: 8px 20px 8px 20px;
  border-radius: 4px;
  color: white;
  background-color: #4872f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '80%' : '100%')};
`;

const Container = styled.div<LinkProps>`
  height: 350px;
  & > svg {
    align-self: flex-end;
  }

  & > svg {
    width: 214.18px;
    height: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  & > :first-child {
    z-index: 2;
  }

  @media (max-width: 767px) {
    height: ${({ insurance }) =>
      insurance === 'Online-врач в поездку' ? '390px' : '350px'};
  }
`;

const Bubble = styled.div<LinkProps>`
  width: 18px;
  height: 18px;
  background: #ddf1f0;
  border-radius: 100px;
  position: absolute;
  right: 150px;
  bottom: ${({ insurance }) =>
    insurance === 'Отмена поездки'
      ? '130px'
      : insurance === 'Online-врач в поездку'
      ? '100px'
      : '185px'};

  @media (max-width: 767px) {
    bottom: ${({ insurance }) =>
      insurance === 'Отмена поездки'
        ? '95px'
        : insurance === 'Online-врач в поездку'
        ? '80px'
        : '170px'};
    right: ${({ insurance }) =>
      insurance === 'Отмена поездки'
        ? '130px'
        : insurance === 'Online-врач в поездку'
        ? '100px'
        : '145px'};
  }
`;

const Bubble2 = styled.div<LinkProps>`
  width: 11px;
  height: 11px;
  background: #ddf1f0;
  border-radius: 100px;
  position: absolute;
  right: 145px;
  bottom: ${({ insurance }) =>
    insurance === 'Отмена поездки'
      ? '105px'
      : insurance === 'Online-врач в поездку'
      ? '80px'
      : '165px'};
  @media (max-width: 767px) {
    bottom: ${({ insurance }) =>
      insurance === 'Отмена поездки'
        ? '85px'
        : insurance === 'Online-врач в поездку'
        ? '65px'
        : '160px'};
    right: ${({ insurance }) =>
      insurance === 'Отмена поездки'
        ? '120px'
        : insurance === 'Online-врач в поездку'
        ? '95px'
        : '135px'};
  }
`;

const PaddingBlock = styled.div`
  padding: 0 30px 0 30px;
  @media (max-width: 767px) {
    padding: 0 20px 44px 20px;
  }
`;

const Thumb = styled.div`
  width: 1px;
  background-color: ${({ theme: { colors } }) => colors.main};
`;
const Track = styled.div`
  height: 100%;
  top: 0;
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.lightGray};
`;

interface LinkProps {
  color?: string;
  isOpen?: boolean;
  insurance?: string;
  isMobile?: boolean;
}

export default function ({ open, onClose, data, onChange }: Props) {
  const [info, setInfoOpen] = React.useState(false);
  const dispatch = useDispatch();
  const ref = React.useRef(null as null | Scrollbars);
  const [refVisible, setRefVisible] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const risks = [
    [
      'Госпитализация',
      'Инфекционные заболевания',
      'Травма',
      'Уход из жизни',
      'Вакцинация',
      'Отказ в визе',
      'Ущерб имуществу',
      'Сокращение',
      'Хищение документов',
      'Авария общественного транспорта',
      'ДТП',
      'Стихийные бедствия',
      'Судебное разбирательство',
      'Следственные действия',
      'Содействие представителям органов власти',
      'Призыв',
      'Метеоусловия',
      'Респираторные инфекционные заболевания',
      'Обсервация в связи с диагностированным COVID-19',
    ],
    [
      'Защита багажа',
      'Компенсация при отмене рейса',
      'Выплаты при несчастном случае',
    ],
    [
      '3 консультации врача в случае заболевания или травмы в период поездки.',
      'Защита при несчастном случае',
      'Защиты при острых заболеваниях',
    ],
    [
      'Оплата медицинской помощи при получении травмы, отравлении, внезапном остром заболевании',
      'Оплата экстренной стоматологической помощи',
      'Оплата медицинской транспортировки или эвакуации',
      'Защита при активных видах отдыха',
      'Транспортные расходы',
      'Расходы при потере или похищении документов',
      'Оплата срочных сообщений',
      'Получение юридической помощи',
    ],
  ];

  const handleClickCross = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(stickyFooterHide(false));
    onClose();
  };

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollToTop();
    }
  }, [ref.current, refVisible]);

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <StyledPopup
      open={open}
      onClose={() => {
        onClose();
        dispatch(stickyFooterHide(false));
        setRefVisible(false);
      }}
    >
      {!!data && (
        <>
          <Scrollbars
            autoHeight
            ref={(sliderRef) => {
              ref.current = sliderRef;
              setRefVisible(true);
            }}
            hideTracksWhenNotNeeded
            autoHeightMax={isMobile ? '100vh' : 720}
            autoHeightMin={isMobile ? '100vh' : 720}
            renderThumbVertical={({ style, ...props }) => (
              <Thumb {...props} style={{ ...style, width: 2, right: 1 }} />
            )}
            renderTrackVertical={({ style, ...props }) => (
              <Track {...props} style={{ ...style, width: 1 }} />
            )}
          >
            <Header>
              <Icon src={data.iconSrc} />
              <HeaderText>
                {data.product.name}{' '}
                <strong>{Helper.formatPrice(data.totalRate.value)}</strong>
              </HeaderText>
              <Cross onClick={handleClickCross} />
            </Header>
            <PaddingBlock id="scrollHead">
              <PaymentBlock
                color={
                  data.product.name === 'Отмена поездки'
                    ? '#BFE8FF'
                    : data.product.name === 'Полёт под защитой'
                    ? '#FFEDBF'
                    : data.product.name === 'Online-врач в поездку'
                    ? '#DAF0A2'
                    : data.product.name === 'Медицинские расходы'
                    ? '#DAF0A2'
                    : 'white'
                }
              >
                {data.product.name !== 'Online-врач в поездку' &&
                  data.product.name !== 'Медицинские расходы' && (
                    <>
                      <InsurIcon />
                      Выплата до{' '}
                      {data.product.name === 'Отмена поездки'
                        ? '5 000 ₽'
                        : '500 000 ₽'}
                    </>
                  )}
                {data.product.name === 'Online-врач в поездку' && (
                  <>
                    <DoctorCross /> Помощь врача 24/7
                  </>
                )}
                {data.product.name === 'Медицинские расходы' && (
                  <>
                    <DoctorCross /> Компенсация до 50 000$
                  </>
                )}
              </PaymentBlock>
              <Paragraph className="first">
                {data.product.description}
              </Paragraph>

              <FeaturesContainer>
                {data.product.name === 'Отмена поездки' &&
                  risks[0].map((text, key) => (
                    <Feature key={key}>
                      <CheckIcon />

                      <BaseText>{text}</BaseText>
                    </Feature>
                  ))}
                {data.product.name === 'Полёт под защитой' && (
                  <>
                    <Feature>
                      <CheckIcon />

                      <BaseText>
                        <strong>
                          Моментальная выплата на карту до 6000 ₽ если рейс
                          задержали!
                        </strong>
                      </BaseText>
                    </Feature>
                    <Feature>
                      <CheckIcon />

                      <BaseText>
                        Выплаты при отмене рейса до <strong>4 800 ₽</strong>
                      </BaseText>
                    </Feature>
                    <Feature>
                      <CheckIcon />

                      <BaseText>
                        Выплата в случае, если самолёт перенаправили в другой
                        аэропорт до <strong>5 000 ₽</strong>
                      </BaseText>
                    </Feature>
                    <Feature>
                      <CheckIcon />

                      <BaseText>
                        Выплаты при несчастном случае в аэропорту или самолёте
                        до <strong> 500 000 ₽ </strong>
                      </BaseText>
                    </Feature>
                  </>
                )}

                {data.product.name === 'Online-врач в поездку' &&
                  risks[2].map((text, key) => (
                    <Feature key={key}>
                      <CheckIcon />

                      <BaseText>{text}</BaseText>
                    </Feature>
                  ))}
                {data.product.name === 'Медицинские расходы' && (
                  <Feature style={{ marginBottom: 15 }}>
                    <BaseText>
                      Будьте уверены, что, находясь в поездке, вы всегда сможете
                      получить неотложную медицинскую помощь.
                    </BaseText>
                  </Feature>
                )}
                {data.product.name === 'Медицинские расходы' &&
                  risks[3].map((text, key) => (
                    <Feature key={key}>
                      <CheckIcon />

                      <BaseText>{text}</BaseText>
                    </Feature>
                  ))}
                {data.product.name === 'Online-врач в поездку' && (
                  <Feature>
                    <CheckIcon />

                    <BaseText>
                      Максимальная выплата до <strong> 1 000 000 ₽ </strong>{' '}
                    </BaseText>
                  </Feature>
                )}
              </FeaturesContainer>
              <Container insurance={data.product.name}>
                <GrayBlock insurance={data.product.name}>
                  {data.product.name === 'Отмена поездки' && (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Случай клиента:
                      </span>{' '}
                      Маша собиралась поехать в Париж, заранее купила билеты по
                      невозвратному тарифу и оформила страховку “Отмена
                      поездки”. <br />
                      <br /> Перед полетом Маше отказали в визе, но благодаря
                      страховке она смогла возместить расходы на покупку
                      билетов.
                    </div>
                  )}
                  {data.product.name === 'Полёт под защитой' && (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Случай клиента:
                      </span>{' '}
                      Перед полетом Иван оформил страховку “Защита на время
                      полета”.
                      <br />
                      <br />В аэропорту багаж Ивана был утерян, но благодаря
                      страховке он смог получить компенсацию.
                    </div>
                  )}
                  {data.product.name === 'Online-врач в поездку' && (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Случай клиента:
                      </span>{' '}
                      Перед полетом в <br />
                      отпуск Маша оформила страховку <br /> “Online-врач в
                      поездку”.
                      <br />
                      <br /> Во время пребывания на отдыхе у Маши внезапно
                      поднялась высокая температура. Благодаря страховке Маша
                      смогла в ночное время связаться с врачом через приложение
                      и получить квалифицированную помощь.
                    </div>
                  )}
                  {data.product.name === 'Медицинские расходы' && (
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Случай клиента:
                      </span>{' '}
                      Перед полетом в <br /> отпуск Маша оформила Медицинскую{' '}
                      <br /> страховку.
                      <br />
                      <br />
                      Во время пребывания на отдыхе у Маши внезапно заболел зуб,
                      и ей понадобилась экстренно обратиться к стоматологу.
                      Благодаря страховке Маша смогла возместить расходы на
                      получение квалифицированной стоматологической помощи.
                    </div>
                  )}
                </GrayBlock>
                <InsuranceWoman insurance={data.product.name} />
                <Bubble insurance={data.product.name} />
                <Bubble2 insurance={data.product.name} />
              </Container>

              <InfoText
                onClick={() => setInfoOpen(!info)}
                insurance={data.product.name}
              >
                {data.product.name === 'Отмена поездки' ||
                  (data.product.name === 'Медицинские расходы' && (
                    <>
                      Как получить выплату? <Arrow isOpen={info} />
                    </>
                  ))}
                {data.product.name === 'Полёт под защитой' && (
                  <>
                    Как получить моментальную выплату? <Arrow isOpen={info} />
                  </>
                )}
                {data.product.name === 'Online-врач в поездку' && (
                  <>
                    Как получить консультацию Online-врача?{' '}
                    <Arrow isOpen={info} />
                  </>
                )}
              </InfoText>
              {info && data.product.name === 'Отмена поездки' && (
                <>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>1.</span> Обратитесь в
                    Службу поддержки по заказу и оформите возврат или обмен
                    авиабилетов на новую дату по одной из 19 причин.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>2.</span> После
                    возврата денег за авиабилеты{' '}
                    <a
                      rel="noreferrer"
                      href="https://www.alfastrah.ru/accident/insurance_of_passengers/"
                      target="_blank"
                    >
                      {' '}
                      отправьте заявление
                    </a>{' '}
                    в “Альфа Cтрахование”.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>3.</span> Приложите
                    подтверждающие и удостоверяющие документы (полис, паспорт,
                    маршрутная квитанция, справки, полученные от Службы
                    поддержки с указанием суммы возврата / доплаты денежных
                    средств, документы, подтверждающие наступление страхового
                    случая, реквизиты для получения выплаты). После получения
                    заявления “Альфа Страхование” может запросить дополнительные
                    документы согласно правилам страхования.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>4.</span> Получите
                    деньги - обычно это занимает не более 10 дней.
                  </Paragraph>
                </>
              )}
              {info && data.product.name === 'Медицинские расходы' && (
                <>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>1.</span> Свяжитесь с
                    оператором сервисной службы по контактам, указанным в
                    полисе.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>2.</span> Назовите
                    оператору номер вашего полиса и коротко опишите, какая
                    помощь вам необходима. Специалист вызовет врача или назовёт
                    адрес ближайшей клиники.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>3.</span> Следуйте
                    указаниям оператора и не оплачивайте счета самостоятельно -
                    расходы по страховым случаям возместит страховая компания.
                    Если вы обратились в клинику без звонка в сервисную службу,
                    внимательно оформляйте финансовые документы. В течении 30
                    дней предоставьте подтверждение расходов в страховую
                    компанию.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>4.</span>{' '}
                    “АльфаСтрахование” примет решение о компенсации затрат в
                    течение нескольких дней после вашего обращения.
                  </Paragraph>
                </>
              )}
              {info && data.product.name === 'Полёт под защитой' && (
                <>
                  <Paragraph>
                    При задержке или отмене рейса вам автоматически придет смс
                    от “Альфа Страхования”. Перейдите по ссылке из смс,
                    проверьте свои данные , введите данные карты и получите
                    выплату.
                  </Paragraph>
                  <Paragraph style={{ fontWeight: 'bold' }}>
                    Если вы не получили смс или у вас страховое событие по
                    другим рискам:
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>1.</span>{' '}
                    <a
                      rel="noreferrer"
                      href="https://www.alfastrah.ru/accident/insurance_of_passengers/"
                      target="_blank"
                    >
                      {' '}
                      Отправьте заявление
                    </a>{' '}
                    в “Альфа Cтрахование”.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>2.</span> Приложите
                    подтверждающие и удостоверяющие документы (полис, паспорт,
                    маршрутная квитанция, справки подтверждающие задержку /
                    отмену рейса или неприятности со здоровьем, реквизиты для
                    получения выплаты). После получения заявления “Альфа
                    Страхование” может запросить дополнительные документы
                    согласно правилам страхования.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>3.</span> Получите
                    деньги - обычно это занимает не более 10 дней.
                  </Paragraph>
                </>
              )}
              {info && data.product.name === 'Online-врач в поездку' && (
                <>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>1.</span> Заранее
                    установите мобильное приложение Доктор Рядом 24/7 (с помощью
                    QR-кода из памятки к полису) и зарегистрируйтесь в нём.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>2.</span> В личном
                    приложении Доктор Рядом 24/7, на вкладке “Записаться”
                    выберите подходящего специалиста - терапевта или педиатра.
                    Убедитесь, что выбор врача происходит бесплатно и
                    подтвердите запись. При обращении сообщите название
                    страховой компании, номер полиса и сайт, на котором он был
                    приобретён, фамилию и имя застрахованного пассажира.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>3.</span> При получении
                    травмы во время поездки зафиксируйте документально факт и
                    обстоятельства наступления несчастного случая в медицинском
                    учреждении.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>4.</span>{' '}
                    <a
                      rel="noreferrer"
                      href="https://www.alfastrah.ru/accident/insurance_of_passengers/"
                      target="_blank"
                    >
                      {' '}
                      Отправьте заявление
                    </a>{' '}
                    в “Альфа Cтрахование”. Приложите подтверждающие и
                    удостоверяющие документы (полис, паспорт, маршрутная
                    квитанция, справки подтверждающие задержку / отмену рейса
                    или неприятности со здоровьем, реквизиты для получения
                    выплаты). После получения заявления “Альфа Страхование”
                    может запросить дополнительные документы согласно правилам
                    страхования.
                  </Paragraph>
                  <Paragraph>
                    <span style={{ fontWeight: 'bold' }}>5.</span> Получите
                    деньги - обычно это занимает не более 10 дней.
                  </Paragraph>
                </>
              )}
              <div style={{ marginTop: 40 }}>
                <Agreement>
                  Читать подробные&nbsp;
                  <a
                    href={data.specificationUri}
                    target="_blank"
                    rel="noreferrer"
                  >
                    условия страхования
                  </a>
                </Agreement>
              </div>
              <Bottom isMobile={isMobile}>
                <BaseText>
                  Номер страховщика: <br />
                  <strong>8-499-785-0999</strong>
                </BaseText>
                <AlphaStrah />
              </Bottom>
            </PaddingBlock>
          </Scrollbars>
          <AbsoluteBlock isMobile={isMobile}>
            <ButtonContainer isMobile={isMobile}>
              <AddButton
                isMobile={isMobile}
                onClick={() => {
                  onChange(data.product.id, data);
                  dispatch(stickyFooterHide(false));
                  onClose();
                }}
              >
                {data.checked ? 'Убрать страховку' : 'Добавить страховку'}
              </AddButton>
            </ButtonContainer>
          </AbsoluteBlock>
        </>
      )}
    </StyledPopup>
  );
}
